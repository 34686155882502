import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  fileUploadLoading: false,
  allDepartmentList: [],
  linkList: {},
  allLink: [],
  uploadedLogo: '',
  addLinkLoading: false,
  editLinkLoading: false,
};
/**
 * Redux slice for the link feature
 */
export const linkSlice = createSlice({
  name: 'feature/links',
  initialState,
  reducers: {
    addLink: (state) => ({
      ...state,
      addLinkLoading: true,
    }),
    addLinkSucceeded: (state, action) => ({
      ...state,
      addLinkLoading: false,
      linkList: {
        ...state.linkList,
        docs: [...state.linkList.docs, action?.payload],
        totalDocs: state.linkList.totalDocs + 1,
      },
    }),
    addLinkFailed: (state) => ({
      ...state,
      addLinkLoading: false,
    }),
    getAllDepartment: (state) => ({
      ...state,
      loading: true,
    }),
    getAllDepartmentSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allDepartmentList: action?.payload,
    }),
    getAllDepartmentFailed: (state) => ({
      ...state,
      loading: false,
    }),
    uploadLogo: (state) => ({
      ...state,
      fileUploadLoading: true,
    }),
    uploadLogoSucceeded: (state, action) => ({
      ...state,
      fileUploadLoading: false,
      uploadedLogo: action?.payload,
    }),
    uploadLogoFailed: (state) => ({
      ...state,
      fileUploadLoading: false,
    }),
    clearLogo: (state) => ({
      ...state,
      uploadedLogo: '',
    }),
    getAllLinks: (state) => ({
      ...state,
      loading: true,
    }),
    getAllLinksSucceeded: (state, action) => ({
      ...state,
      loading: false,
      linkList: action?.payload,
    }),
    getAllLinksFailed: (state) => ({
      ...state,
      loading: false,
    }),
    editLink: (state) => ({
      ...state,
      editLinkLoading: true,
    }),
    editLinkSucceeded: (state, action) => ({
      ...state,
      editLinkLoading: false,
      linkList: {
        ...state.linkList,
        docs: state.linkList.docs?.map((link) => {
          if (link.id === action?.payload?.id) {
            return action?.payload;
          }
          return link;
        }),
      },
    }),
    editLinkFailed: (state) => ({
      ...state,
      editLinkLoading: false,
    }),
    patchLink: (state) => ({
      ...state,
      editLinkLoading: true,
    }),
    patchLinkSucceeded: (state, action) => ({
      ...state,
      editLinkLoading: false,
      // replace the link with the soft deleted one
      linkList: {
        ...state.linkList,
        docs: state.linkList?.docs.map((link) => {
          if (link.id === action?.payload?.id) {
            return action?.payload;
          }
          return link;
        }),
      },
    }),
    patchLinkFailed: (state) => ({
      ...state,
      editLinkLoading: false,
    }),
    getLinksById: (state) => ({
      ...state,
      loading: true,
    }),
    getAllLinksByIdSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allLink: action?.payload,
    }),
    getAllLinksByIdFailed: (state) => ({
      ...state,
      loading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: linkActions } = linkSlice;
