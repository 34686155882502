import { TextField, Typography, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//
import './index.scss';
import { useState } from 'react';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
/**
 * Defines a function that returns a common DatePicker element
 * @prop {String} label - Label of the date picker
 * @prop {String} value - Data picker value prop
 * @prop {Function} onChange - onChange prop function
 * @prop {Boolean} hide Boolean value that hides the component if true
 * @prop {Boolean} error - Boolean value that indicates whether a validation error is present
 * @prop {String} errorMsg - Error description to b displayed
 * @prop {Date} minDate - Minimum selectable date
 * @prop {Date} maxDate - Maximum selectable date
 */
const Date_Picker = ({
  label,
  value,
  defaultValue,
  onChange,
  hide,
  errorMsg,
  error,
  minDate,
  maxDate,
  disabled,
  views,
  onKeyDown,
  sx,
  placeholder,
  size
}) => {
  if (hide) return null;
  const [open, setOpen] = useState(false);
  //
  return (
    <>
      <Typography fontSize="16px" className="field-label">
        {label}
      </Typography>
      <FormControl
        fullWidth
        sx={{
          fieldset: {
            borderRadius: '0px',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            views={views}
            disabled={disabled}
            inputFormat={DATE_FORMAT_SLASH}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            renderInput={(params) => (
              <TextField
                size={size}
                onKeyDown={onKeyDown}
                name="startDate"
                className="date-box"
                sx={sx}
                {...params}
                error={error}
                onClick={() => setOpen(true)}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
              />
            )}
          />
        </LocalizationProvider>
        <p className="error-feedback">{errorMsg}</p>
      </FormControl>
    </>
  );
};
//
export default Date_Picker;
