/**
 * Styles object for button styles
 */
const styles = {
  button: {
    borderRadius: '0%',
    boxShadow: 'none',
    padding: '0 2.5rem',
  },
};
//
export default styles;
