import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
/**
 * Checks if a given token is valid or expired
 * @returns
 */
const useIsTokenValid = () => {
  const isValid = useCallback((token) => {
    if (token) {
      const { exp } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return exp >= currentTime;
    }
    return false;
  }, []);
  //
  return { isValid };
};
//
export default useIsTokenValid;
