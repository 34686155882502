import { useCallback } from 'react';
import useIsTokenValid from './use-token-validity';

/**
 * Custom hook to check the token status
 * @returns {isAuthenticated}
 */
const useIsAuthenticated = () => {
  const { isValid } = useIsTokenValid();
  const isAuthenticated = useCallback((refreshToken) => {
    if (refreshToken) {
      return isValid(refreshToken);
    }
    return false;
  }, []);
  //
  return { isAuthenticated };
};
//
export default useIsAuthenticated;
