import { RATE_PER_HOUR_RANGES } from 'features/base/constants/amount-types';
import calculations from 'features/base/helpers/calculations';
import { getDollarFormattedValueBilling } from 'features/base/helpers/dollar-formatter';

export const getHourlyRateError = (input) => {
  if (input < RATE_PER_HOUR_RANGES.MIN_RATE_PER_HOUR) {
    return `Should be at least  ${RATE_PER_HOUR_RANGES.MIN_RATE_PER_HOUR}`;
  }
  if (input >= RATE_PER_HOUR_RANGES.MAX_RATE_PER_HOUR) {
    return `Should be less than ${RATE_PER_HOUR_RANGES.MAX_RATE_PER_HOUR}`;
  }
  return '';
};
/**
 * Function to obtain the table row data of fixed bid projects
 */
export const tableRowsFixedBid = (workAllocations, project) => {
  let totalRevenue = 0;
  const totalHoursAllocations = calculations.getTotalHoursOfUsers(workAllocations);
  const rows = workAllocations?.docs?.map((allocation) => {
    // Calculating the total hours per person
    const totalHrsPerPerson = allocation.monthlyAllocations?.reduce(
      (acc, curr) => acc + curr.allocatedHours,
      0
    );
    // Calculation of revenue per user
    const revenuePerPerson = (
      (project.projectValue / totalHoursAllocations) *
      totalHrsPerPerson
    ).toFixed(2);
    // Total Revenue of the project
    totalRevenue += Number(revenuePerPerson);
    //
    return {
      id: allocation?.id,
      profile: {
        value: `${allocation?.userId?.firstName} ${allocation?.userId?.lastName}`,
        img: allocation?.userId?.profileImage,
      },
      department:
        `${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.departmentId?.name || '-'
        }, ${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.designationId?.name || '-'
        }` === '-, -'
          ? '-'
          : `${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.name}, ${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name}`,
      totalHours: totalHrsPerPerson,
      allocation: allocation.allocatedPercentage,
      revenue: {
        valueWithDollarSymbol: getDollarFormattedValueBilling(revenuePerPerson),
        value: revenuePerPerson,
      },
    };
  });
  rows.push({
    id: 'totalRow',
    profile: {
      value: 'Total',
    },
    totalHours: totalHoursAllocations,
    revenue: {
      valueWithDollarSymbol: getDollarFormattedValueBilling(Math.round(totalRevenue.toFixed(2))),
      value: Math.round(totalRevenue.toFixed(2)),
    },
  });
  //
  return rows;
};
/**
 * Function to obtain the table row data of monthly fixed projects
 */
export const tableRowsMonthlyFixed = (workAllocationsBilling, handleOnChange, ratePerHourRefs) =>
  workAllocationsBilling?.docs?.map((allocation, index) => {
    // Since values are fixed for every month in monthly fixed, we can access 0-index values
    const hourlyRate = allocation?.monthlyAllocations?.[0]?.hourlyRate ?? 0;
    const revenuePerMonth = allocation?.monthlyAllocations?.[0]?.revenue ?? 0;
    const hoursPerMonth = allocation?.monthlyAllocations?.[0]?.allocatedHours ?? 0;
    const totalRevenue = revenuePerMonth * (allocation?.monthlyAllocations?.length ?? 0);
    //
    return {
      id: allocation?.id,
      profile: {
        value: `${allocation?.userId?.firstName} ${allocation?.userId?.lastName}`,
        img: allocation?.userId?.profileImage,
      },
      department:
        `${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.departmentId?.name || '-'
        }, ${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.designationId?.name || '-'
        }` === '-, -'
          ? '-'
          : `${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.name}, ${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name}`,
      hourlyRate: {
        value: hourlyRate,
        onChange: (event) => {
          const { value } = event.target;
          handleOnChange(value, allocation);
        },
        ratePerHourRef: ratePerHourRefs[index],
        helperText: getHourlyRateError(hourlyRate),
        error:
          hourlyRate < RATE_PER_HOUR_RANGES.MIN_RATE_PER_HOUR ||
          hourlyRate >= RATE_PER_HOUR_RANGES.MAX_RATE_PER_HOUR,
      },
      hoursPerMonth: hoursPerMonth.toFixed(2),
      allocation: allocation.allocatedPercentage,
      revenuePerMonth: {
        value: revenuePerMonth.toFixed(2),
        valueWithDollarSymbol: getDollarFormattedValueBilling(revenuePerMonth.toFixed(2)),
      },
      totalRevenue: {
        valueWithDollarSymbol: getDollarFormattedValueBilling(totalRevenue.toFixed(2)),
        value: totalRevenue.toFixed(2),
      },
    };
  });
/**
 * Function to obtain the table row data of time and material projects
 */
export const tableRowsTimeAndMaterial = (workAllocationsBilling, handleUpdate) => {
  const rows = workAllocationsBilling?.docs?.map((allocation) => {
    // Calculating the total hours per person
    const totalHrsPerPerson = allocation?.monthlyAllocations?.reduce(
      (acc, curr) => acc + curr.allocatedHours,
      0
    );
    const hourlyRate = allocation?.monthlyAllocations?.[0]?.hourlyRate;
    //
    return {
      id: allocation?.id,
      profile: {
        value: `${allocation?.userId?.firstName} ${allocation?.userId?.lastName}`,
        img: allocation?.userId?.profileImage,
      },
      department:
        `${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.departmentId?.name || '-'
        }, ${
          allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
            ?.designationId?.name || '-'
        }` === '-, -'
          ? '-'
          : `${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.name}, ${allocation?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name}`,
      hourlyRate: {
        value: hourlyRate,
        onChange: (event) => {
          const { value } = event.target;
          handleUpdate(value, allocation);
        },
        helperText: getHourlyRateError(hourlyRate),
        error:
          hourlyRate < RATE_PER_HOUR_RANGES.MIN_RATE_PER_HOUR ||
          hourlyRate >= RATE_PER_HOUR_RANGES.MAX_RATE_PER_HOUR,
      },
      totalHours: totalHrsPerPerson,
      allocation: allocation.allocatedPercentage || 0,
      revenue: {
        value: hourlyRate <= 0 ? 0 : ((hourlyRate || 0) * totalHrsPerPerson).toFixed(2),
        valueWithDollarSymbol: getDollarFormattedValueBilling(
          hourlyRate <= 0 ? 0 : ((hourlyRate || 0) * totalHrsPerPerson).toFixed(2)
        ),
      },
    };
  });
  //
  return rows;
};
/**
 * Function to obtain the table row data of non client projects
 */
export const tableRowsNonClient = (workAllocations) => {
  const totalHoursAllocations = calculations.getTotalHoursOfUsers(workAllocations);
  const rows = workAllocations?.docs?.map((entity) => {
    // Calculating the total hours per person
    const totalHrsPerPerson = entity?.monthlyAllocations?.reduce(
      (acc, curr) => acc + curr.allocatedHours,
      0
    );
    //
    return {
      id: entity?.id,
      profile: {
        value: `${entity?.userId?.firstName} ${entity?.userId?.lastName}`,
        img: entity?.userId?.profileImage,
      },
      department:
        `${
          entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId
            ?.name || '-'
        }, ${
          entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId
            ?.name || '-'
        }` === '-, -'
          ? '-'
          : `${entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.name}, ${entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name}`,
      totalHours: totalHrsPerPerson,
      allocation: entity.allocatedPercentage,
    };
  });
  rows?.push({
    id: 'totalRow',
    profile: {
      value: 'Total',
    },
    totalHours: totalHoursAllocations,
  });
  //
  return rows;
};
