import { Box } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import createFormattedString from 'features/base/helpers/param-formatter';
import ERROR_TYPES from 'features/base/constants/error-types';
import { notificationActions } from 'features/base/notifications/slice';
import { MONTHS } from 'features/base/constants/date-formatting';
import { downloadCSVFile } from 'features/base/helpers/file';
import { Button } from 'features/base/components';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  selectAggregatedAllocations,
  selectAggregatedAllocationsLoading,
  selectProject,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { AllocatedActualHoursTable, ProfileTable, TableFilters } from './components';
//
const LoggedHoursReport = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const allocationsLoading = useSelector(selectAggregatedAllocationsLoading);
  //
  const allocations = useSelector(selectAggregatedAllocations);
  const projectDetails = useSelector(selectProject);
  //
  const [selectedUsers, setSelectedUsers] = useState(['All']);
  //
  const allMonths = useMemo(() => {
    const start = moment(projectDetails?.startDate);
    const end = moment(projectDetails?.endDate);
    const months = [];
    while (start.isBefore(end) || start.isSame(end)) {
      months.push(start.format('YYYY-MM'));
      start.add(1, 'month');
    }
    return months;
  }, [projectDetails?.startDate, projectDetails?.endDate]);
  //
  const params = useMemo(
    () => ({
      aggregated: 'true',
      projectIds: [projectId],
      limit: WORK_ALLOCATION_PAGINATION_LIMIT,
      sortBy: 'projectId.name:asc',
      pagination: 'false',
    }),
    [projectId]
  );
  //
  useEffect(() => {
    if (projectId) {
      const formattedParamString = createFormattedString(params);
      dispatch(projectActions.getAggregatedAllocations({ query: formattedParamString }));
    }
  }, [projectId, params]);
  //
  const handleUsersChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  //
  const filteredAllocations = useMemo(
    () =>
      selectedUsers.findIndex((id) => id === 'All') > -1
        ? allocations?.docs
        : allocations?.docs?.filter(
            (currentAllocation) =>
              selectedUsers.findIndex((id) => id === currentAllocation?.userId?.id) > -1
          ),
    [selectedUsers, allocations]
  );
  //
  const allocationsByViewPeriod = useMemo(
    () =>
      allocations?.docs?.map((allocation) => {
        const allocationsDateLookup = {};
        allocation?.monthlyAllocations?.forEach((monthlyAllocation) => {
          const key = `${monthlyAllocation.year}-${monthlyAllocation.month}`;
          allocationsDateLookup[key] = monthlyAllocation;
        });
        const viewPeriodData = {};
        allMonths.forEach((yearMonth) => {
          const record = allocationsDateLookup[yearMonth];
          viewPeriodData[yearMonth] = record;
        });
        //
        return {
          id: allocation?.id,
          projectId: allocation?.projectId,
          userId: allocation?.userId,
          ...viewPeriodData,
        };
      }),
    [allocations, allMonths]
  );
  //
  const handleExport = () => {
    if (!allocationsByViewPeriod?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No data to export',
          type: ERROR_TYPES.INFO,
        })
      );
      return;
    }
    //
    const data = allocationsByViewPeriod?.map((item) => {
      const { userId, id, ...rest } = item;
      // Generating the required structure for the CSV file
      const formatted = {};
      Object.keys(rest).forEach((key) => {
        const { allocatedHours, loggedHours } = rest?.[key] ?? '';
        const splitted = key?.split('-');
        const year = splitted?.[0];
        const monthNumber = parseInt(splitted?.[1], 10);
        const monthName = MONTHS[monthNumber - 1]?.slice(0, 3);
        const allocatedHoursKey = `Allocated Hours (${year} ${monthName})`;
        const actualHoursKey = `Actual Hours (${year} ${monthName})`;
        formatted[allocatedHoursKey] = allocatedHours ?? '-';
        formatted[actualHoursKey] = loggedHours ?? '-';
      });
      //
      return {
        Project: projectDetails?.name ?? '-',
        Profile:
          `${userId?.firstName} ${userId?.lastName} (${userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name})` ??
          '-',
        ...formatted,
      };
    });
    //
    const filename = `allocated_vs_actual_hours_${projectDetails?.name}`;
    downloadCSVFile(Object.keys(data?.[0]), data, `${filename}.csv`);
  };
  //
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <TableFilters handleUsersChange={handleUsersChange} selectedUsers={selectedUsers} />
        <Button
          onClick={handleExport}
          disabled={!allocationsByViewPeriod?.length || allocationsLoading}
          sx={{ width: 'auto', marginRight: '0.75rem' }}
        >
          <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
          Export
        </Button>
      </Box>
      {allocationsLoading ? (
        <ShimmerTable row={4} col={6} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <ProfileTable filteredAllocations={filteredAllocations} />
          <AllocatedActualHoursTable
            allMonths={allMonths}
            filteredAllocations={filteredAllocations}
          />
        </Box>
      )}
    </Box>
  );
};
//
export default LoggedHoursReport;
