import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/designation'] || initialState;
/**
 * Function to retrieve all designation from the designation slice
 */
export const selectAllDesignation = createSelector(
  [selectDomain],
  (designationState) => designationState.designationList
);
/**
 * Selector for getting loader state
 */
export const selectLoader = createSelector(
  [selectDomain],
  (designationState) => designationState.loading
);
/**
 * Function to retrieve one designation state
 */
export const selectDesignation = createSelector(
  [selectDomain],
  (designationState) => designationState.designation
);
/**
 * Function to retrieve is designation fetching state
 */
export const selectIsDesignationFetching = createSelector(
  [selectDomain],
  (designationState) => designationState.isDesignationFetching
);
/**
 * Function to retrieve search word of designation from the department slice
 */
export const selectSearch = createSelector(
  [selectDomain],
  (designationState) => designationState.search
);
/**
 * Selector for getting loader state
 */
export const selectEditDesignationLoader = createSelector(
  [selectDomain],
  (designationState) => designationState.editDesignationLoading
);
