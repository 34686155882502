import { Alert, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
//
import { clientActions } from 'features/clients/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectAllProjectsList,
  selectUploadedImage,
  selectAddClientLoader,
  selectFileUploadLoader,
} from 'features/clients/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { TextField, Popup, FileUpload } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  getAllowedExtensionsString,
  verifyExtensions,
} from 'features/base/helpers/file';
import {
  CLIENT_LOGO_ALLOWED_FILE_TYPES,
  CLIENT_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import PhoneInput from 'features/base/components/phone-input';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import addClientFormValidation from 'features/clients/validations/add-client-validation';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import LOGICAL_OPERATIONS from 'features/base/constants/logical-operations';
import COLORS from 'features/base/constants/colors';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddClientsPopup = ({ addModalOpen, setAddModalOpen }) => {
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedImage);
  const allProjects = useSelector(selectAllProjectsList);
  const loading = useSelector(selectAddClientLoader);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  //
  const dispatch = useDispatch();
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(clientActions.clearLogo());
    setFileTypeError('');
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), CLIENT_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      formData.append('file', logo[0]);
      if (logo.length > 0) {
        dispatch(clientActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.USER,
          action: PERMISSION_ACTIONS.CREATE,
        },
        {
          domain: PERMISSION_DOMAINS.CLIENT,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
      operation={LOGICAL_OPERATIONS.AND}
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add client"
        mediumSize="660px"
        height="700px"
      >
        <Formik
          initialValues={{
            company: '',
            brNumber: '',
            address: '',
            companyPhoneNumber: '',
            projectIds: [],
            firstName: '',
            lastName: '',
            email: '',
            primaryUserPhoneNumber: '',
          }}
          validationSchema={addClientFormValidation}
          onSubmit={(values) => {
            const { projectIds, ...data } = values;
            data.projectIds = projectIds.map((project) => project?.id);
            if (uploadedLogo) {
              data.logo = { ...uploadedLogo, url: encodeURI(uploadedLogo?.url) };
            }
            Object.keys(data).forEach((key) => {
              if (!data[key]) delete data[key];
            });
            dispatch(clientActions.addClient(data));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Logo"
                value={values.logo}
                acceptedTypes={CLIENT_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  CLIENT_LOGO_ALLOWED_EXTENSIONS
                )}`}
                inputLabel={uploadedLogo ? `${uploadedLogo?.name}` : `Choose logo`}
                loading={fileUploadLoading}
              />
              <TextField
                type="text"
                name="company"
                value={values.company}
                error={Boolean(touched?.company && errors?.company)}
                helperText={touched?.company && errors?.company}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Company"
              />
              <PhoneInput
                name="companyPhoneNumber"
                id="companyPhoneNumber"
                country="lk"
                inputStyle={{
                  fontSize: '16px',
                  width: '100%',
                  height: '40px',
                  borderRadius: '0px',
                  lineHeight: '20px',
                }}
                buttonStyle={{ backgroundColor: COLORS.WHITE }}
                fullWidth="true"
                containerStyle={{ width: '100%' }}
                onBlur={handleBlur}
                countryCodeEditable="true"
                placeholder=""
                label="Phone number"
                error={Boolean(touched.companyPhoneNumber && errors.companyPhoneNumber)}
                errorMsg={touched?.companyPhoneNumber && errors?.companyPhoneNumber}
              />
              <TextField
                type="text"
                name="brNumber"
                value={values.brNumber}
                error={Boolean(touched?.brNumber && errors?.brNumber)}
                helperText={touched?.brNumber && errors?.brNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Business registration number"
              />
              <TextField
                type="text"
                name="address"
                value={values.address}
                error={Boolean(touched?.address && errors?.address)}
                helperText={touched?.address && errors?.address}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Address"
                fullWidth
                my={2}
                height={120}
                multiline
                rows={4}
              />
              <MultiSelectAutoComplete
                id="projectIds"
                name="projectIds"
                options={allProjects}
                selectedOptions={values.projectIds}
                setSelectedOptions={setFieldValue}
                label="Projects"
                formikKey="projectIds"
                placeholder="Select project"
                error={Boolean(touched.projectIds && errors.projectIds)}
                errorMsg={touched.projectIds && errors.projectIds}
                onClose={() => setFieldTouched('projectIds', true)}
              />
              <Typography variant="h6" sx={{ mb: 2, mt: 4, fontWeight: 600 }}>
                Primary details
              </Typography>
              <TextField
                type="name"
                name="firstName"
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="First name"
              />
              <TextField
                type="name"
                name="lastName"
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Last name"
              />
              <TextField
                type="email"
                name="email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Email"
              />
              <PhoneInput
                name="primaryUserPhoneNumber"
                id="primaryUserPhoneNumber"
                country="lk"
                inputStyle={{
                  fontSize: '16px',
                  width: '100%',
                  height: '40px',
                  borderRadius: '0px',
                  lineHeight: '20px',
                }}
                buttonStyle={{ backgroundColor: COLORS.WHITE }}
                fullWidth="true"
                containerStyle={{ width: '100%' }}
                onBlur={handleBlur}
                countryCodeEditable="true"
                placeholder=""
                label="Phone number"
                error={Boolean(touched.primaryUserPhoneNumber && errors.primaryUserPhoneNumber)}
                errorMsg={touched?.primaryUserPhoneNumber && errors?.primaryUserPhoneNumber}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={loading || fileUploadLoading}
                rightIcon={loading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddClientsPopup;
