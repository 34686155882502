import { Typography, Grid, Paper, Link, Box } from '@mui/material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
//
import { ENVIRONMENT } from 'config';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { Button, Popup } from 'features/base/components';
import { removeUUIDv4Prefix } from 'features/base/helpers/url-parser';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { benefitsActions } from 'features/benefits/slice';
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import { BENEFIT_CAMPAIGN_TYPES } from 'features/base/constants/benefit-types';
import {
  selectRedeemBenefitLoader,
  selectRedeemedBenefit,
  selectBenefitById,
} from 'features/benefits/selectors';
/**
 * Component that defines the grid item
 * @returns MUI Grid with the grid item
 * @param {*} children
 * @returns MUI Grid
 */
const GridItem = ({ children, sx }) => (
  <Grid item xs={12} sm={12} md={6}>
    <Paper
      sx={{
        ...sx,
        backgroundColor: COLORS.SKY_BLUE,
        m: 0.5,
        display: 'flex',
      }}
      elevation={0}
    >
      {children}
    </Paper>
  </Grid>
);

const SingleBenefitViewPopup = ({ viewModalOpen, setViewModalOpen, benefitId, isMyBenefits }) => {
  const dispatch = useDispatch();
  //
  const redeemBenefitLoader = useSelector(selectRedeemBenefitLoader);
  const redeemedBenefit = useSelector(selectRedeemedBenefit);
  //
  const benefit = useSelector((state) => selectBenefitById(state, benefitId));
  //
  const handleOnClose = () => {
    setViewModalOpen(false);
    dispatch(benefitsActions.clearRedeemedBenefit());
  };

  return (
    <Popup
      open={viewModalOpen}
      onClose={handleOnClose}
      title={benefit?.title}
      mediumSize="888px"
      closeOnBlur
    >
      <Typography variant="h6" color={COLORS.GRAYISH_PURPLE} mb={3}>
        {benefit?.description}
      </Typography>
      <Typography variant="h6" color="textPrimary" fontWeight={800} mb={3}>
        General Details
      </Typography>
      <Grid container rowSpacing={0} mt={3}>
        {!isMyBenefits && (
          <>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}>Departments</Typography>
            </GridItem>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}>
                {' '}
                {benefit?.departments?.map((department) => department?.name).join(', ')}
              </Typography>
            </GridItem>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}>No of Users</Typography>
            </GridItem>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}> {benefit?.usersCount}</Typography>
            </GridItem>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}>Employee levels</Typography>
            </GridItem>
            <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Typography sx={{ paddingLeft: 1 }}> {benefit?.levels?.join(', ')}</Typography>
            </GridItem>
          </>
        )}

        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>Created date</Typography>
        </GridItem>
        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>
            {new Date(benefit?.createdAt).toLocaleDateString(
              DATE_LANGUAGE.LANGUAGE,
              DATE_FORMAT_SLASH
            )}{' '}
          </Typography>
          <Typography sx={{ paddingLeft: '3rem', opacity: 0.6 }} color={COLORS.GREY}>
            {moment(benefit?.createdAt).fromNow()}
          </Typography>
        </GridItem>

        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>Expiry date</Typography>
        </GridItem>
        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          {benefit?.expiryDate && benefit?.showExpiryDate ? (
            <>
              <Typography sx={{ paddingLeft: 1 }}>
                {new Date(benefit?.expiryDate).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                )}{' '}
              </Typography>
              <Typography sx={{ paddingLeft: '3rem', opacity: 0.6 }} color={COLORS.GREY}>
                {moment(benefit?.expiryDate).isAfter(moment().toDate())
                  ? moment(benefit?.expiryDate).fromNow()
                  : moment(benefit?.expiryDate).toNow()}
              </Typography>
            </>
          ) : (
            <Typography sx={{ paddingLeft: 1 }}>-</Typography>
          )}
        </GridItem>

        <GridItem
          sx={{
            height: {
              md:
                benefit?.attachments?.length > 0
                  ? `${2 * (benefit?.attachments?.length ?? 1)}rem`
                  : '3rem',
              sm: '3rem',
            },
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ paddingLeft: 1 }}>Attachments</Typography>
        </GridItem>
        <GridItem
          sx={{
            flexDirection: 'column',
            height:
              benefit?.attachments?.length > 0
                ? `${2 * (benefit?.attachments?.length ?? 1)}rem`
                : '3rem',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {/*  show blue colour chip hyper links */}
          {benefit?.attachments?.length ? (
            benefit?.attachments?.map((attachment) => (
              <Link
                href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${attachment?.url}`}
                underline="hover"
                sx={{
                  fontSize: '0.875rem',
                  ml: 1,
                  mb: 1,
                  whiteSpace: 'nowrap',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '95%',
                }}
                target="_blank"
                rel="noreferrer"
              >
                {removeUUIDv4Prefix(attachment?.name)}
              </Link>
            ))
          ) : (
            <Typography sx={{ paddingLeft: 1 }}>{'-'}</Typography>
          )}
        </GridItem>

        <GridItem
          sx={{
            height: '3rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ paddingLeft: 1 }}>Link</Typography>
        </GridItem>
        <GridItem
          sx={{
            height: '3rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {benefit?.link?.length ? (
            <Link
              href={benefit?.link}
              underline="hover"
              sx={{
                fontSize: '0.85rem',
                paddingX: 1,
                wordWrap: 'break-word',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
              target="_blank"
              rel="noreferrer"
            >
              {benefit?.link}
            </Link>
          ) : (
            <Typography sx={{ paddingLeft: 1 }}>{'-'}</Typography>
          )}
        </GridItem>

        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>Vendor</Typography>
        </GridItem>
        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>{benefit?.vendorId?.name ?? '-'}</Typography>
        </GridItem>

        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}>Status</Typography>
        </GridItem>
        <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
            <Box
              sx={{
                bgcolor:
                  benefit?.dataStatus === DATA_STATUS_TYPES.ACTIVE
                    ? COLORS.PALE_GREEN
                    : COLORS.LAVENDER_MIST,
                p: '7px 16px',
                mr: '16px',
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color:
                    benefit?.dataStatus === DATA_STATUS_TYPES.ACTIVE
                      ? COLORS.SUCCESS_MAIN
                      : COLORS.DEEP_INDIGO,
                }}
              >
                {benefit?.dataStatus === DATA_STATUS_TYPES.ACTIVE
                  ? capitalizeFirstLetter(DATA_STATUS_TYPES.ACTIVE)
                  : capitalizeFirstLetter(DATA_STATUS_TYPES.INACTIVE)}
              </Typography>
            </Box>
          </Box>
        </GridItem>

        {isMyBenefits && (
          <>
            {benefit?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN && (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" color="textPrimary" fontWeight={800} mb={2} mt={3}>
                  Promotion details
                </Typography>
                {!redeemedBenefit?.promotion?.id && (
                  <Typography variant="body1" color={COLORS.SHADOW_BLUE} mb={2}>
                    Click the button below to acquire your promotion for the benefit.
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(benefitsActions.redeemBenefit({ benefitId }));
                  }}
                  disabled={redeemBenefitLoader || redeemedBenefit?.promotion === null}
                  icon={redeemBenefitLoader ? loaderIcon : null}
                  iconAlignment="right"
                  sx={{ display: redeemedBenefit?.promotion?.id ? 'none' : 'block' }}
                >
                  Redeem Promotion
                </Button>
              </Grid>
            )}

            {redeemedBenefit?.promotion?.id && (
              <Grid container rowSpacing={0} mt={3}>
                <GridItem
                  sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }}>Type</Typography>
                </GridItem>
                <GridItem
                  sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }}>
                    {formattedString(capitalizeFirstLetter(benefit?.promotionCodeType))}
                  </Typography>
                </GridItem>

                <GridItem
                  sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }}>Promo Code/Resource</Typography>
                </GridItem>
                <GridItem
                  sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }} fontWeight={800}>
                    {redeemedBenefit?.promotion?.resource}
                  </Typography>
                </GridItem>

                <Typography variant="body1" color={COLORS.SHADOW_BLUE} mb={2} mt={2}>
                  {redeemedBenefit?.message}
                </Typography>
              </Grid>
            )}

            {redeemedBenefit?.promotion === null && (
              <Typography variant="body1" color={COLORS.ERROR_MAIN} mb={2} mt={2}>
                {redeemedBenefit?.message}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Popup>
  );
};

export default SingleBenefitViewPopup;
