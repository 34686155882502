import { Alert, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
//
import { benefitsActions } from 'features/benefits/slice';
import {
  selectAddBenefitsLoader,
  selectDepartments,
  selectRefineUsers,
} from 'features/benefits/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { MultiSelectAutoComplete, Button, DataGrid } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import benefitEmployeeValidation from 'features/benefits/validations/benefit-employee-validation';
import { USER_LEVELS, USER_TYPES } from 'features/base/constants/user-types';
import { BENEFIT_ADD_EMPLOYEES_TABLE_COLUMNS } from 'features/base/utils/tables';
/**
 * @returns {ReactNode} AddEmployeesStep
 */
const AddEmployeesStep = ({
  stepProgress,
  setStepProgress,
  setActiveStep,
  formResults,
  setFormResults,
}) => {
  const dispatch = useDispatch();
  //
  const allDepartments = useSelector(selectDepartments);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddBenefitsLoader);
  const refineUsers = useSelector(selectRefineUsers);
  //
  useEffect(() => {
    dispatch(
      benefitsActions.getRefineUsers({
        query: `pagination=false&sortBy=name:asc&type=${USER_TYPES.INTERNAL}`,
      })
    );
  }, []);
  //
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '70%', lg: '70%' },
        margin: 'auto',
      }}
    >
      <Formik
        initialValues={{
          departments: [],
          levels: [],
          excludedUsers: [],
          additionalUsers: [],
        }}
        validationSchema={benefitEmployeeValidation}
        onSubmit={(values) => {
          setStepProgress(
            stepProgress.map((step) => {
              if (step.step === 2) {
                return { ...step, completed: true };
              }
              return step;
            })
          );
          setActiveStep(3);
          //
          setFormResults(
            formResults.map((formResult) => {
              if (formResult.step === 2) {
                return {
                  ...formResult,
                  data: {
                    ...values,
                    // remove 'all' option if exists
                    departments: values.departments.filter((department) => department.id !== 'all'),
                  },
                };
              }
              return formResult;
            })
          );
        }}
      >
        {({ errors, handleSubmit, touched, values, setFieldValue, setFieldTouched }) => {
          const [excludeUsersOptions, setExcludeUsersOptions] = useState([]);
          //
          useEffect(() => {
            if (!values.departments.length || !values.levels.length) {
              setExcludeUsersOptions([]);
              return;
            }
            setExcludeUsersOptions(
              refineUsers
                ?.map((user) => {
                  const departmentId =
                    user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId
                      ?.id;
                  const level = user?.level;
                  if (!departmentId || !level) return null;
                  if (
                    values.departments.find((department) => department.id === departmentId) &&
                    values.levels.find((userLevel) => userLevel.id === level)
                  ) {
                    return {
                      id: user.id,
                      name: user.email,
                      email: user.email,
                      profileImage: user.profileImage,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      department:
                        user?.currentUserDepartmentDesignationId?.departmentDesignationId
                          ?.departmentId?.name,
                      level: user.level,
                    };
                  }
                  return null;
                })
                .filter((user) => user) || []
            );
          }, [values?.departments, values?.levels]);
          //
          return (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Typography fontSize="16px" className="field-label">
                Departments
              </Typography>
              <MultiSelectAutoComplete
                id="departments"
                name="departments"
                options={[{ id: 'all', name: 'All Departments' }].concat(
                  allDepartments?.map((department) => ({
                    id: department.id,
                    name: department.name,
                  })) || []
                )}
                setSelectedOptions={(field, value) => {
                  //
                  if (value.find((val) => val.id === 'all')) {
                    if (value.length !== allDepartments.length) {
                      setFieldValue(
                        'departments',
                        allDepartments.concat({ id: 'all', name: 'All Departments' })
                      );
                    } else if (value.length === allDepartments.length) {
                      const missingDepartments = allDepartments.filter(
                        (department) => !value.find((val) => val.id === department.id)
                      );
                      setFieldValue(
                        'departments',
                        value.filter(
                          (val) =>
                            val.id !== 'all' && !missingDepartments.find((dep) => dep.id === val.id)
                        )
                      );
                    }
                  } else if (value.length === allDepartments.length) {
                    setFieldValue('departments', []);
                  } else {
                    setFieldValue('departments', value);
                  }
                }}
                selectedOptions={values.departments}
                formikKey="departments"
                placeholder="Select department"
                error={Boolean(touched.departments && errors.departments)}
                errorMsg={touched.departments && errors.departments}
                onClose={() => setFieldTouched('departments', true)}
              />

              <Typography fontSize="16px" className="field-label">
                Employee levels
              </Typography>
              <MultiSelectAutoComplete
                id="levels"
                name="levels"
                options={[
                  ...Object.values(USER_LEVELS).map((level) => ({ id: level, name: level })),
                ]}
                selectedOptions={values.levels}
                setSelectedOptions={setFieldValue}
                formikKey="levels"
                placeholder="Select level"
                error={Boolean(touched.levels && errors.levels)}
                errorMsg={touched.levels && errors.levels}
                onClose={() => setFieldTouched('levels', true)}
                optionsFormatter={(value) => value}
              />

              <Typography fontSize="16px" className="field-label">
                Excluded Users
              </Typography>
              <MultiSelectAutoComplete
                id="excludedUsers"
                name="excludedUsers"
                options={excludeUsersOptions}
                selectedOptions={values.excludedUsers}
                setSelectedOptions={setFieldValue}
                formikKey="excludedUsers"
                placeholder="Select excluded users"
                error={Boolean(touched.excludedUsers && errors.excludedUsers)}
                errorMsg={touched.excludedUsers && errors.excludedUsers}
                onClose={() => setFieldTouched('excludedUsers', true)}
                disabled={!values.departments.length || !values.levels.length}
              />

              <Typography fontSize="16px" className="field-label">
                Additional Users
              </Typography>
              <MultiSelectAutoComplete
                id="additionalUsers"
                name="additionalUsers"
                options={(() => {
                  if (!values.departments.length || !values.levels.length) return [];
                  const includedUsers =
                    refineUsers
                      ?.map((user) => {
                        const departmentId =
                          user?.currentUserDepartmentDesignationId?.departmentDesignationId
                            ?.departmentId?.id;
                        const level = user?.level;
                        if (!departmentId || !level) return null;
                        if (
                          values.departments.find((department) => department.id === departmentId) &&
                          values.levels.find((userLevel) => userLevel.id === level)
                        ) {
                          return {
                            id: user.id,
                            name: user.email,
                            email: user.email,
                            profileImage: user.profileImage,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            department:
                              user?.currentUserDepartmentDesignationId?.departmentDesignationId
                                ?.departmentId?.name,
                            level: user.level,
                          };
                        }
                        return null;
                      })
                      .filter((user) => user) || [];
                  // remove the included users from the refine users list and return
                  return (
                    refineUsers
                      ?.filter((user) => !includedUsers.find((incUser) => incUser.id === user.id))
                      ?.map((user) => ({
                        id: user.id,
                        name: user.email,
                        email: user.email,
                        profileImage: user.profileImage,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        department:
                          user?.currentUserDepartmentDesignationId?.departmentDesignationId
                            ?.departmentId?.name,
                        level: user.level,
                      })) || []
                  );
                })()}
                selectedOptions={values.additionalUsers}
                setSelectedOptions={setFieldValue}
                formikKey="additionalUsers"
                placeholder="Select additional users"
                error={Boolean(touched.additionalUsers && errors.additionalUsers)}
                errorMsg={touched.additionalUsers && errors.additionalUsers}
                onClose={() => setFieldTouched('additionalUsers', true)}
                disabled={!values.departments.length || !values.levels.length}
              />

              <Typography fontWeight={800} variant="h6" sx={{ mt: 4 }}>
                Final List of Employees
              </Typography>
              <DataGrid
                columns={BENEFIT_ADD_EMPLOYEES_TABLE_COLUMNS}
                rows={(() => {
                  let users = excludeUsersOptions.map((user) => ({
                    id: user.id,
                    profile: {
                      value: user?.email,
                      logo: user?.profileImage,
                      name: `${user?.firstName} ${user?.lastName}`,
                    },
                    email: {
                      value: user?.email,
                      onClick: (event) => {
                        event.stopPropagation();
                      },
                    },
                    department: { value: user?.department },
                    level: { value: user?.level },
                    action: {
                      value: 'Remove',
                      handleRemoveEmployee: () => {
                        // check if user in additional users first, if so remove from additional users
                        if (values.additionalUsers.find((u) => u.id === user.id)) {
                          setFieldValue(
                            'additionalUsers',
                            values.additionalUsers.filter((u) => u.id !== user.id)
                          );
                        } else {
                          setFieldValue('excludedUsers', [...values.excludedUsers, user]);
                        }
                      },
                    },
                  }));
                  users.push(
                    ...values.additionalUsers.map((user) => ({
                      id: user.id,
                      profile: {
                        value: user?.email,
                        logo: user?.profileImage,
                        name: `${user?.firstName} ${user?.lastName}`,
                      },
                      email: {
                        value: user?.email,
                        onClick: (event) => {
                          event.stopPropagation();
                        },
                      },
                      department: { value: user?.department },
                      level: { value: user?.level },
                      action: {
                        value: 'Add',
                        handleRemoveEmployee: () => {
                          // check if user in additional users first, if so remove from additional users
                          if (values.additionalUsers.find((u) => u.id === user.id)) {
                            setFieldValue(
                              'additionalUsers',
                              values.additionalUsers.filter((u) => u.id !== user.id)
                            );
                          } else {
                            setFieldValue('excludedUsers', [...values.excludedUsers, user]);
                          }
                        },
                      },
                    }))
                  );
                  // remove excluded users from the additional users list
                  users = users.filter(
                    (user) => !values.excludedUsers.find((u) => u.id === user.id)
                  );
                  return users;
                })()}
                totalPages={1}
                handleChangePage={() => {}}
                page={1}
                totalDocs={excludeUsersOptions.length}
                limit={excludeUsersOptions.length}
                loading={loading}
                pageSizeOptions={[excludeUsersOptions.length]}
                rowHeight={excludeUsersOptions.length > 0 ? 52 : 200}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ mt: 4 }}
              >
                Save & Continue
              </Button>
              <Box sx={{ height: 50 }} />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
//
export default AddEmployeesStep;
