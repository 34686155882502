import { Email as EmailIcon } from '@mui/icons-material';
import { Avatar, Box, Typography, Checkbox } from '@mui/material';
import COLORS from 'features/base/constants/colors';
import groupIcon from 'features/base/assets/images/svg/group-icon.svg';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';

/**
 * Defines the table header details for work allocation table in the project dashboard
 */
const WORK_ALLOCATION_TABLE_HEADERS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 380,
    valueGetter: (params) => params.row.profile.name,
    renderCell: (params) => {
      const { img, name, designation } = params.row.profile;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {img ? (
            <img
              alt="profile"
              src={img}
              style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Avatar sx={{ height: '32px', width: '32px', marginRight: '16px' }}>
              {name.charAt(0)}
            </Avatar>
          )}
          <Box>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              {name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', color: COLORS.SILVER_LILAC }}>
              {designation || '-'}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    valueGetter: (params) => params.row.email.value,
    renderCell: (params) => {
      const { value } = params.row.email;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 110,
    valueGetter: (params) => params.row.type.value,
    renderCell: (params) => {
      const { value } = params.row.type;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    width: 110,
    valueGetter: (params) => params.row.startDate.value,
    renderCell: (params) => {
      const { value } = params.row.startDate;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    width: 110,
    valueGetter: (params) => params.row.endDate.value,
    renderCell: (params) => {
      const { value } = params.row.endDate;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'updatedDate',
    headerName: 'Last updated',
    width: 130,
    valueGetter: (params) => params.row.updatedDate.value,
    renderCell: (params) => {
      const { value } = params.row.updatedDate;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    width: 120,
    valueGetter: (params) => params.row.allocation.value,
    renderCell: (params) => {
      const { value } = params.row.allocation;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
    valueGetter: (params) => params.row.status.status,
    renderCell: (params) => {
      const { status } = params.row.status;
      return (
        <Box
          sx={{
            bgcolor: status === DATA_STATUS_TYPES.ACTIVE ? COLORS.PALE_GREEN : COLORS.LAVENDER_MIST,
            p: '5px 21px',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: status === DATA_STATUS_TYPES.ACTIVE ? COLORS.SUCCESS_MAIN : COLORS.DEEP_INDIGO,
              fontSize: '0.875rem',
              fontWeight: '600',
            }}
          >
            {status === DATA_STATUS_TYPES.ACTIVE
              ? DATA_STATUS_TYPES.ACTIVE
              : DATA_STATUS_TYPES.INACTIVE}
          </Typography>
        </Box>
      );
    },
  },
  // bell icon
  {
    field: 'ticketAssignee',
    headerName: 'Assignee',
    sortable: false,
    renderHeader: () => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={groupIcon} alt="Update Icon" />
      </Box>
    ),
    width: 75,
    renderCell: ({ value: { defaultChecked, onChange, isWorkAllocationLoading, disabled } }) => (
      <Checkbox
        defaultChecked={defaultChecked}
        disabled={isWorkAllocationLoading || disabled}
        onChange={onChange}
      />
    ),
  },
  {
    field: 'ticketNotifiers',
    headerName: 'Notifiers',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto' }}
      >
        <EmailIcon />
      </Box>
    ),
    width: 75,
    renderCell: ({ value: { defaultChecked, onChange, isWorkAllocationLoading, disabled } }) => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Checkbox
          defaultChecked={defaultChecked}
          disabled={isWorkAllocationLoading || disabled}
          onChange={onChange}
        />
      </Box>
    ),
  },
];
//
export default WORK_ALLOCATION_TABLE_HEADERS;
