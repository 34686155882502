import { useDispatch, useSelector } from 'react-redux';
import { Alert, Grid, Box, IconButton, Button, TextField as MuiTextField } from '@mui/material';
import { FieldArray, Formik } from 'formik';
//
import { designationActions } from 'features/designations/slice';
import CloseIcon from 'features/base/assets/images/svg/close-icon.svg';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { Button as AddButton } from 'features/base/components';
import designationFormValidations from 'features/designations/validations/designation-validations';
/**
 * Table component to render Designations
 * @returns {Box}
 */
const AddDesignationForm = () => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    dispatch(notificationActions.resetNotification());
  };
  //
  return (
    <Grid item xs={12} md={6} lg mb={2}>
      <Formik
        initialValues={{
          designations: [{ name: '' }],
        }}
        validationSchema={designationFormValidations}
        onSubmit={(values, { resetForm }) => {
          dispatch(designationActions.addDesignation(values));
          resetForm();
          handleOnClose();
        }}
      >
        {({ errors, handleChange, handleSubmit, values, touched }) => (
          <form noValidate onSubmit={handleSubmit} className="add-workArea-form">
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <FieldArray name="designations">
              {({ remove, push }) => (
                <div>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      style={{ textTransform: 'none' }}
                      type="button"
                      onClick={() => push({ name: '' })}
                    >
                      + Add
                    </Button>
                  </Box>
                  {errors.designations &&
                    values?.designations?.length === 1 &&
                    touched.designations?.some((skill) => skill.name) && (
                      <Alert severity="error">Add at least one designation</Alert>
                    )}
                  {errors.designations &&
                    values?.designations?.some((skill) => !skill.name) &&
                    values?.designations?.length > 1 &&
                    values?.designations?.length === touched.designations?.length && (
                      <Alert severity="error">
                        Please fill all the designation fields or remove unnecessary ones
                      </Alert>
                    )}
                  {notification?.isEnabled && notification?.type === ERROR_TYPES.WARNING && (
                    <Alert severity={notification?.type}>{notification?.message}</Alert>
                  )}
                  {values?.designations.length > 0 &&
                    values?.designations.map((_, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        key={`key${index.toString()}`}
                      >
                        <MuiTextField
                          type="text"
                          name={`designations.${index}.name`}
                          value={values?.designations[index].name}
                          onChange={handleChange}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          placeholder={`Designation ${index + 1}`}
                          sx={{
                            mb: 2,
                            fieldset: {
                              borderRadius: '0px',
                            },
                            width: '100%',
                            '& .MuiOutlinedInput-root input': {
                              padding: 1,
                              pl: 2,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                disabled={index < 1}
                                onClick={() => remove(index)}
                                sx={{
                                  padding: '20px',
                                  width: '22px',
                                  height: '20px',
                                  margin: '4px',
                                  opacity: index < 1 ? 0.3 : 1,
                                }}
                              >
                                <img src={CloseIcon} alt="close-icon" />
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>
                    ))}
                </div>
              )}
            </FieldArray>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
              {/* Button for Desktop View */}
              <AddButton
                type="submit"
                sx={{
                  width: {
                    xs: '100%',
                    sm: '199px',
                  },
                  textTransform: 'none',
                }}
                size="medium"
              >
                Add designation
              </AddButton>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};
//
export default AddDesignationForm;
