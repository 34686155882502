/**
 * Defines the API paths for role
 */
const API = {
  ADD_ROLE: {
    path: '/roles',
    method: 'POST',
  },
  GET_ROLE: {
    path: '/roles?:query',
    method: 'GET',
  },
  EDIT_ROLE: {
    path: '/roles/:id',
    method: 'PATCH',
  },
};
//
export default API;
