/* eslint-disable no-template-curly-in-string */
import moment from 'moment';
import * as yup from 'yup';
//
import { AMOUNT } from 'features/base/constants/amount-types';
/**
 * Function for salary form validation
 */
const salaryFromValidation = (previousDates) => {
  const schema = yup.object().shape({
    amountType: yup.string().required('Please select a salary type '),
    paymentType: yup.string().required('Please select a payment type'),
    amount: yup
      .number()
      .required('Please enter an amount')
      .typeError('Amount must be a number')
      .min(AMOUNT, `Amount must be greater than or equal to ${AMOUNT}`),
    startDate: yup
      .date()
      .typeError('Please select a start date in the valid format (DD-MM-YYYY)')
      .required('Please select a project start date'),
  });
  if (previousDates.length) {
    return schema.shape({
      startDate: schema.fields.startDate.test(
        'previousStartDateValidation',
        'System does not allow to add salaries for a date before the active salary. Please update Active record to enter details of past salary for the selected user',
        (value) => {
          const invalidDates = [];
          previousDates.forEach((pDate) => {
            const prevDate = moment(pDate);
            const date = moment(value);
            const isAfter = date.isAfter(prevDate, 'month') && date.isSameOrAfter(prevDate, 'year');
            if (!isAfter) {
              invalidDates.push(pDate);
            }
          });
          return !invalidDates.length;
        }
      ),
    });
  }
  return schema;
};
//
export default salaryFromValidation;
