import { Alert, Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectUploadedLogo,
  selectAddToolsLoader,
  selectUploadFileLoader,
  selectAllDepartment,
} from 'features/tools/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import FileUpload from 'features/base/components/file-upload';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import Select from 'features/base/components/select';
import { TOOL_TYPE_ARRAY } from 'features/base/constants/tool-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import toolFormValidation from 'features/tools/validation/tool-validation';
import {
  TOOL_LOGO_ALLOWED_FILE_TYPES,
  TOOL_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import { getAllowedExtensionsString, verifyExtensions } from 'features/base/helpers/file';
import { toolActions } from 'features/tools/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { NumericInputField } from 'features/base/components';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';

/**
 * Function that defines the popup form for adding a new tool
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddToolsPopup = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectAddToolsLoader);
  const logoLoading = useSelector(selectUploadFileLoader);
  const allDepartments = useSelector(selectAllDepartment);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(toolActions.clearLogo());
  };
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), TOOL_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      Object.keys(logo).forEach((key) => formData.append('file', logo[key]));
      if (logo.length > 0) {
        dispatch(toolActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.TOOL,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add new tool"
        mediumSize="660px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            paymentType: '',
            price: '',
            departments: [],
          }}
          validationSchema={toolFormValidation}
          onSubmit={(values) => {
            const { departments, ...tool } = values;
            tool.departments = departments.map((department) => department?.id);
            tool.logo = uploadedLogo;
            tool.paymentType = tool.paymentType.toUpperCase();
            dispatch(toolActions.addTool(tool));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="add-tools-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Logo"
                value={values.logo}
                acceptedTypes={TOOL_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  TOOL_LOGO_ALLOWED_EXTENSIONS
                )}`}
                inputLabel={
                  uploadedLogo.length > 0
                    ? `${uploadedLogo[0].name}`
                    : uploadedLogo.length === 0 && `Choose logo`
                }
                loading={logoLoading}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Description"
                height={120}
                multiline
                rows={4}
              />
              <Select
                id="paymentType"
                value={values.paymentType}
                selectLabel="paymentType"
                onChange={(event) => {
                  setFieldValue('paymentType', event.target.value);
                }}
                items={TOOL_TYPE_ARRAY.map((item) => ({
                  key: item.key,
                  value: capitalizeFirstLetter(item.value),
                }))}
                textLabel="Type"
                stringFormat={capitalizeFirstLetter}
                error={Boolean(touched.paymentType && errors.paymentType)}
                errorMsg={touched?.paymentType && errors?.paymentType}
                placeholder={<Typography sx={{ fontWeight: '600' }}>Select Type</Typography>}
              />
              <NumericInputField
                type="text"
                name="price"
                label="Amount"
                value={values.price}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                onChange={(v) => setFieldValue('price', v.floatValue)}
                onBlur={handleBlur}
                thousandSeparator=","
                prefix="$"
                placeholder="$"
              />
              <Typography fontSize="16px" className="field-label">
                Departments
              </Typography>
              <MultiSelectAutoComplete
                id="departments"
                name="departments"
                options={allDepartments}
                selectedOptions={values.departments}
                setSelectedOptions={setFieldValue}
                formikKey="departments"
                placeholder="Select department"
                error={Boolean(touched.departments && errors.departments)}
                errorMsg={touched.departments && errors.departments}
                onClose={() => setFieldTouched('departments', true)}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddToolsPopup;
