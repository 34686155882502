const API = {
  GET_DEPARTMENTS: {
    path: '/departments',
    method: 'GET',
  },
  GET_BENEFITS: {
    path: '/benefit?:query',
    method: 'GET',
  },
  GET_VENDORS: {
    path: '/vendors?:query',
    method: 'GET',
  },
  GET_USERS: {
    path: '/users?:query',
    method: 'GET',
  },
  ADD_BENEFITS: {
    path: '/benefit',
    method: 'POST',
  },
  ADD_PROMOTIONS_BULK: {
    path: '/benefit/:benefitId/promotions',
    method: 'POST',
  },
  REDEEM_BENEFIT: {
    path: '/benefit/:benefitId/redeem',
    method: 'POST',
  },
  EDIT_BENEFITS: {
    path: '/benefit/:id',
    method: 'PATCH',
  },
  DELETE_BENEFITS: {
    path: '/benefit/:id',
    method: 'DELETE',
  },
  GET_BENEFIT: {
    path: '/benefit/:id',
    method: 'GET',
  },
  GET_PROMOTIONS: {
    path: '/benefit/:benefitId/promotions',
    method: 'GET',
  },
  EDIT_PROMOTIONS_BULK: {
    path: '/benefit/:benefitId/promotions',
    method: 'PUT',
  },
  DELETE_PROMOTION: {
    path: '/benefit/:benefitId/promotions/:promotionId',
    method: 'DELETE',
  },
  POST_ATTACHMENTS: {
    path: '/benefit/upload',
    method: 'POST',
  },
};

export default API;
