import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//
import COLORS from './features/base/constants/colors';
import { store } from './store/index';
import Router from './routes';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import './index.scss';
/**
 * Application theme
 */
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY_MAIN,
      light: COLORS.PRIMARY_LIGHT,
    },
    secondary: {
      main: COLORS.SECONDARY_MAIN,
      light: COLORS.SECONDARY_LIGHT,
    },
    success: {
      main: COLORS.SUCCESS_MAIN,
      light: COLORS.SUCCESS_LIGHT,
    },
    error: {
      main: COLORS.ERROR_MAIN,
      light: COLORS.ERROR_LIGHT,
    },
    info: {
      main: COLORS.INFO_MAIN,
      light: COLORS.INFO_LIGHT,
    },
    warning: {
      main: COLORS.WARNING_MAIN,
      light: COLORS.WARNING_LIGHT,
    },
  },
  typography: {
    fontFamily: 'Lexend, sans-serif',
  },
});
/**
 * Entry point in the react application. And also register the providers
 * store-provider :-The single Redux store in your application.
 * BrowserRouter :-  BrowserRouter is a router implementation that uses the HTML5 history API(pushState,
 * replaceState and the popstate event) to keep your UI in sync with the URL
 * ThemeProvider :- Common theme context for entire application
 * MuiThemeProvider :- It makes the theme available down the React tree thanks to React context
 * Router :- Entire application protected and public routes
 * ToastContainer :- The ToastContainer is just a simple component, which you can write text or even custom
 * JSX elements in to customize the toast even more
 * @returns Entry point in the react application. And also register the providers
 */
const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Router />
        <ToastContainer />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
//
export default App;
