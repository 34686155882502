/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  roleList: [],
  addRoleLoading: false,
  editRoleLoading: false,
};
/**
 * Redux slice for the role feature
 */
export const roleSlice = createSlice({
  name: 'feature/role',
  initialState,
  reducers: {
    getAllRoles: (state) => ({
      ...state,
      loading: true,
    }),
    getAllRoleSucceeded: (state, action) => ({
      ...state,
      loading: false,
      roleList: action?.payload,
    }),
    getAllRoleFailed: (state) => ({
      ...state,
      loading: false,
      roleList: [],
    }),
    addRole: (state) => ({
      ...state,
      addRoleLoading: true,
    }),
    addRoleSucceeded: (state, action) => ({
      ...state,
      addRoleLoading: false,
      roleList: [...state.roleList, action?.payload],
    }),
    addRoleFailed(state) {
      state.addRoleLoading = false;
      return state;
    },
    editRole: (state) => ({
      ...state,
      editRoleLoading: true,
    }),
    editRoleSucceeded: (state, action) => ({
      ...state,
      editRoleLoading: false,
      roleList: state.roleList.map((role) => {
        if (role.id === action?.payload?.id) {
          return action?.payload;
        }
        return role;
      }),
    }),
    editRoleFailed: (state) => ({
      ...state,
      editRoleLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: roleActions } = roleSlice;
