import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Container, Typography } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
//
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import { Autocomplete, DataGrid, SearchBar, DatePicker } from 'features/base/components';
import { BENEFITS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { benefitsActions } from 'features/benefits/slice';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import { selectAuthUser } from 'features/base/auth/selectors';
import { selectAllBenefits, selectLoader } from 'features/benefits/selectors';
import createFormattedString from 'features/base/helpers/param-formatter';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { MY_BENEFITS_TABLE_COLUMNS } from 'features/base/utils/tables';
import { DATE_FORMAT_SLASH, ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import SingleBenefitViewPopup from '../single-benefit-view-modal';

const MyBenefitsView = () => {
  const dispatch = useDispatch();
  //
  const user = useSelector(selectAuthUser);
  const benefits = useSelector(selectAllBenefits);
  const loading = useSelector(selectLoader);
  //
  const [dataStatusFilter, setDataStatusFilter] = useState({
    id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`,
    label: 'All',
  });
  const [searchData, setSearchData] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'title:asc',
    rowsPerPage: BENEFITS_PAGINATION_LIMIT,
  });
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [benefitId, setBenefitId] = useState('');
  //
  const isFirst = useIsInitialize(benefits);
  //
  const params = useMemo(
    () => ({
      page: pageController.page,
      limit: pageController.rowsPerPage,
      sortBy: pageController.sortBy,
      expiryDate,
      dataStatus: dataStatusFilter.id,
      departmentIds:
        user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.id,
      level: user?.level,
      beneficiaryId: user?.id,
    }),
    [pageController, dataStatusFilter]
  );
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleOnDataStatusChange = (e, autocompleteValue) => {
    setDataStatusFilter(
      autocompleteValue || {
        id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`,
        label: 'All',
      }
    );
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleViewModalOpen = (id) => {
    setViewModalOpen(true);
    setBenefitId(id);
  };
  //
  const handleOnExpiryDateChange = (e) => {
    setExpiryDate(moment(e?.$d).format(ISO_WITHOUT_TIME));
    setPageController({ ...pageController, page: 1 });
  };
  //
  const searchBenefits = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchBenefits, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  useEffect(() => {
    dispatch(benefitsActions.getDepartments());
  }, []);
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      benefitsActions.getBenefits({
        query: searchData.length
          ? `${formattedParamString}&search=${searchData}`
          : formattedParamString,
      })
    );
  }, [params]);
  //
  useEffect(
    () => () => {
      dispatch(benefitsActions.resetBenefits());
    },
    []
  );
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Grid display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
        <Typography variant="h4" sx={{ fontWeight: 'bold !important' }}>
          My benefits
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={{ xs: 1, sm: 1, md: 2, lg: 3 }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <Grid item xs={12} md={12} lg={3} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <Box
            sx={{
              height: '60px',
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              mt: '10px',
            }}
          >
            <SearchBar
              placeholder="Search title"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={2.25} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <Box sx={{ height: '60px', width: '100% !important' }}>
            <Autocomplete
              id="dataStatusFilter"
              name="dataStatusFilter"
              options={[
                { id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`, label: 'All' },
                { id: DATA_STATUS_TYPES.ACTIVE, label: `${DATA_STATUS_TYPES.ACTIVE}` },
                { id: DATA_STATUS_TYPES.INACTIVE, label: `${DATA_STATUS_TYPES.INACTIVE}` },
              ]}
              label="Select status"
              controlled
              value={dataStatusFilter}
              onChange={handleOnDataStatusChange}
              getOptionLabel={(option) => capitalizeFirstLetter(option?.label)}
              disableClearable={dataStatusFilter.label === 'All'}
            />
          </Box>
        </Grid>
        <Grid item mb={{ xs: 2, sm: 2, md: 1, lg: 1 }} xs={12} lg={2.25} md={12}>
          <Box sx={{ height: '60px', width: '100% !important' }}>
            <Typography fontSize="16px" className="field-label">
              Expiry date
            </Typography>
            <DatePicker
              size="small"
              onChange={handleOnExpiryDateChange}
              value={expiryDate}
              placeholder={'Select date'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container display="block">
        <SingleBenefitViewPopup
          viewModalOpen={viewModalOpen}
          setViewModalOpen={setViewModalOpen}
          benefitId={benefitId}
          isMyBenefits
        />
        {isFirst ? (
          <ShimmerTable rows={10} cols={6} />
        ) : (
          <DataGrid
            columns={MY_BENEFITS_TABLE_COLUMNS}
            rows={benefits?.docs?.map((benefit) => ({
              ...benefit,
              id: benefit?.id,
              title: {
                value: benefit?.title,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              createdAt: {
                value: moment(benefit?.createdAt).format(DATE_FORMAT_SLASH),
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              expiryDate: {
                value: moment(benefit?.expiryDate).format(DATE_FORMAT_SLASH),
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
                showExpiryDate: benefit?.showExpiryDate,
              },
              link: { value: benefit?.link },
              status: {
                value: benefit?.dataStatus,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
            }))}
            totalPages={benefits?.totalPages ?? 0}
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalDocs={benefits?.totalDocs ?? 0}
            limit={pageController.rowsPerPage ?? 0}
            loading={loading}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={benefits?.docs?.length > 0 ? 52 : 200}
            clickable
          />
        )}
      </Grid>
    </Container>
  );
};

export default MyBenefitsView;
