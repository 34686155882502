/**
 * Defines the API paths for opex
 */
const API = {
  GET_OPEX: {
    path: '/opex?:query',
    method: 'GET',
  },
  ADD_OPEX: {
    path: '/opex',
    method: 'POST',
  },
  PATCH_OPEX: {
    path: '/opex/:id',
    method: 'PATCH',
  },
  DELETE_OPEX: {
    path: '/opex/:id',
    method: 'DELETE',
  },
  GET_ONE_OPEX: {
    path: '/opex/:id',
    method: 'GET',
  },
};
//
export default API;
