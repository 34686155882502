import { Avatar, Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
//
import COLORS from 'features/base/constants/colors';
import { selectCreateTeamProject } from 'features/projects/selectors';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import { ENVIRONMENT } from 'config';
/**
 * Function that renders the project details in the create team page
 */
const ProjectDetails = () => {
  const project = useSelector(selectCreateTeamProject);
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {project?.logo?.length > 0 ? (
        <img
          src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${project.logo[0].url}`}
          alt="project-logo"
          style={{ height: '48px', width: '48px', borderRadius: '50%' }}
        />
      ) : (
        <Avatar sx={{ height: '48px', width: '48px' }}>{project?.name?.charAt(0)}</Avatar>
      )}
      <Typography
        variant="h1"
        sx={{
          fontSize: '2.25rem',
          fontWeight: 600,
          mb: { xs: '15px', md: 0 },
          mr: '24px',
          ml: '10px',
        }}
      >
        {`${project.name}`}
      </Typography>
      {project?.billingType && (
        <Box
          sx={{
            bgcolor: COLORS.CELESTIAL_BLUE,
            minHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            p: '6px 18px',
            mr: { xs: 0, sm: '16px' },
            mt: { xs: '10px', md: 0 },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Typography variant="body1" sx={{ color: '#2684ff' }}>
            {billingTypeFormatter(project?.billingType)}
          </Typography>
        </Box>
      )}
      {project?.projectValue && (
        <Box
          sx={{
            bgcolor: COLORS.LAVENDER_GRAY,
            minHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            p: '6px 18px',
            mt: { xs: '10px', md: 0 },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Typography variant="body1" sx={{ color: COLORS.BLUE_MAGENTA, display: 'flex' }}>
            Project Value:&nbsp;
          </Typography>
          <Typography sx={{ fontWeight: 600 }}>
            {getDollarFormattedValue(project?.projectValue)}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
//
export default ProjectDetails;
