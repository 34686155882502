import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
//
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import {
  Popup,
  TextFieldDataList,
  DragAndDropZone,
  RadioButtonSet,
} from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { processFiles } from 'features/base/helpers/file';
import {
  PROJECT_DOCUMENTS_ALLOWED_EXTENSIONS,
  PROJECT_DOCUMENTS_ALLOWED_FILE_TYPES,
  PROJECT_DOCUMENTS_MAX_SIZE,
} from 'features/base/constants/file-upload';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { PROJECT_DOCUMENT_TAGS } from 'features/base/constants/project-tags';
import { projectActions } from 'features/projects/slice';
import {
  selectAddProjectDocumentsLoading,
  selectUploadedDocuments,
  selectUploadFileLoader,
} from 'features/projects/selectors';
import { addDocumentsValidation } from 'features/projects/validation/project-validation';
import { PROJECT_FILE_TYPES } from 'features/base/constants/project-file-types';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} open - boolean to show/hide the popup
 * @prop {function} setOpen - function to set the popup state
 * @returns {Popup}
 */
const AddDocumentsPopup = ({ open, setOpen }) => {
  const notification = useSelector(selectNotification);
  const uploadLoading = useSelector(selectUploadFileLoader);
  const uploadedDocuments = useSelector(selectUploadedDocuments);
  const loading = useSelector(selectAddProjectDocumentsLoading);
  //
  const { projectId } = useParams();
  //
  const formikRef = useRef();
  //
  const dispatch = useDispatch();
  //
  const PROJECT_DOCUMENT_TYPES = {
    UPLOAD: 'Upload file',
    LINK: 'Enter link',
  };
  //
  const [documentType, setDocumentType] = useState(PROJECT_DOCUMENT_TYPES.UPLOAD);
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(projectActions.setDocuments([]));
  };
  //
  useEffect(() => {
    // Lookup structure for optimization
    const lookup = {};
    formikRef.current?.values?.documentFiles?.forEach((documentFile) => {
      lookup[documentFile?.name] = documentFile;
    });
    const newFiles = uploadedDocuments?.map((uploadedDocument) =>
      lookup[uploadedDocument?.name] ? lookup[uploadedDocument?.name] : uploadedDocument
    );
    formikRef.current?.setFieldValue('documentFiles', newFiles);
  }, [uploadedDocuments]);
  //
  useEffect(() => {
    if (
      open &&
      notification?.isEnabled &&
      (notification?.type === ERROR_TYPES.SUCCESS || notification?.type === ERROR_TYPES.INFO)
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  const handleOnDeleteFile = (fileToRemove) => {
    dispatch(
      projectActions.setDocuments(
        uploadedDocuments?.filter(
          (uploadedDocument) => uploadedDocument?.name !== fileToRemove?.name
        )
      )
    );
    dispatch(notificationActions.resetNotification());
  };
  //
  const handleOnAddFiles = (filesToAdd) => {
    if (filesToAdd?.length > 0) {
      const formData = processFiles(filesToAdd);
      formData.append('fileType', PROJECT_FILE_TYPES.DOCUMENT)
      dispatch(projectActions.uploadDocuments({ files: formData }));
    }
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={open}
        onClose={handleOnClose}
        title="Add documents"
        mediumSize="660px"
        height="700px"
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            documentFiles: [],
            documentLinks: [],
            linkInput: '',
          }}
          validationSchema={addDocumentsValidation}
          onSubmit={(values) => {
            const allDocuments = [...values.documentLinks, ...values.documentFiles];
            const documents = allDocuments.map((document) => ({
              ...document,
              tags: document?.tags?.map((tag) => tag?.name),
              url: encodeURI(document.url),
            }));
            dispatch(projectActions.addProjectDocuments({ projectId, documents }));
          }}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, setFieldTouched }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <RadioButtonSet
                value={documentType}
                onChange={(event) => setDocumentType(event.target.value)}
                options={Object.values(PROJECT_DOCUMENT_TYPES)}
                disabled={uploadLoading}
              />
              {documentType === PROJECT_DOCUMENT_TYPES.UPLOAD && (
                <DragAndDropZone
                  allowedExtensions={PROJECT_DOCUMENTS_ALLOWED_EXTENSIONS}
                  allowedMimetypes={PROJECT_DOCUMENTS_ALLOWED_FILE_TYPES}
                  maxSize={PROJECT_DOCUMENTS_MAX_SIZE}
                  uploadedFiles={values.documentFiles}
                  loading={uploadLoading}
                  onAdd={handleOnAddFiles}
                  onDelete={handleOnDeleteFile}
                  disabled={uploadLoading}
                  listSx={{ maxHeight: 300 }}
                  renderInternalSelect
                  internalSelectProps={{
                    options: Object.keys(PROJECT_DOCUMENT_TAGS).map((key) => ({
                      id: key,
                      name: PROJECT_DOCUMENT_TAGS[key],
                    })),
                    setSelectedOptions: (document, selectedTags) => {
                      const updatedDocumentFiles = values.documentFiles?.map((documentFile) =>
                        documentFile?.name === document?.name
                          ? { ...documentFile, tags: selectedTags }
                          : documentFile
                      );
                      setFieldValue('documentFiles', updatedDocumentFiles);
                    },
                    placeholder: 'Select Tags',
                    disabled: uploadLoading,
                  }}
                  internalSelectOptionsKey="tags"
                />
              )}
              {documentType === PROJECT_DOCUMENT_TYPES.LINK && (
                <TextFieldDataList
                  name="linkInput"
                  text={values?.linkInput}
                  setText={(data) => setFieldValue('linkInput', data)}
                  textFormatter={(text) => ({ name: text, url: text, tags: [], external: true })}
                  items={values?.documentLinks}
                  setItems={(data) => {
                    setFieldValue('documentLinks', data);
                  }}
                  textError={errors.linkInput}
                  // Setting touched to true on submit, display the error when submitting data through the textfield
                  onAdd={() => setFieldTouched('linkInput', true)}
                  onRemove={() => dispatch(notificationActions.resetNotification())}
                  listSx={{ maxHeight: 300 }}
                  renderInternalSelect
                  internalSelectProps={{
                    options: Object.keys(PROJECT_DOCUMENT_TAGS).map((key) => ({
                      id: key,
                      name: PROJECT_DOCUMENT_TAGS[key],
                    })),
                    setSelectedOptions: (document, selectedTags) => {
                      const updatedDocumentLinks = values?.documentLinks?.map((documentLink) =>
                        documentLink?.name === document?.name
                          ? { ...documentLink, tags: selectedTags }
                          : documentLink
                      );
                      setFieldValue('documentLinks', updatedDocumentLinks);
                    },
                    placeholder: 'Select Tags',
                    formatOptions: false
                  }}
                  internalSelectOptionsKey="tags"
                />
              )}
              {touched?.documentLinks && errors?.documentLinks && (
                <p className="error-feedback">{errors?.documentLinks}</p>
              )}
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading || uploadLoading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddDocumentsPopup;
