import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
//
import { clientActions } from 'features/clients/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectClientUserById,
  selectUploadedImage,
  selectLoader,
  selectFileUploadLoader,
} from 'features/clients/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { TextField, Popup, FileUpload } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  getAllowedExtensionsString,
  verifyExtensions,
} from 'features/base/helpers/file';
import {
  CLIENT_LOGO_ALLOWED_FILE_TYPES,
  CLIENT_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import PhoneInput from 'features/base/components/phone-input';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import editClientUserFormValidation from 'features/clients/validations/edit-client-user-validation';
import COLORS from 'features/base/constants/colors';
import { removeUnchanged } from 'features/base/helpers/object';

/**
 * Function that defines the popup form for editing an existing client user
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @returns {Popup}
 */
const EditClientUsersModal = ({ editModalOpen, setEditModalOpen, selectedUserId }) => {
  const notification = useSelector(selectNotification);
  const uploadedProfilePicture = useSelector(selectUploadedImage);
  const loading = useSelector(selectLoader);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  const selectedUser = useSelector((state) => selectClientUserById(state, selectedUserId));
  //
  const { id } = useParams();
  //
  const dispatch = useDispatch();
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(clientActions.clearLogo());
    setFileTypeError('');
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), CLIENT_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      formData.append('file', logo[0]);
      if (logo.length > 0) {
        dispatch(clientActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    firstName: selectedUser?.userId?.firstName || '',
    lastName: selectedUser?.userId?.lastName || '',
    phoneNumber: selectedUser?.userId?.phoneNumber || '',
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.CLIENT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup open={editModalOpen} onClose={handleOnClose} title="Edit user" mediumSize="660px">
        <Formik
          initialValues={initialValues}
          validationSchema={editClientUserFormValidation}
          onSubmit={(values) => {
            let data = values;
            data = removeUnchanged(initialValues, data);
            if (uploadedProfilePicture) {
              data.profileImage = encodeURI(uploadedProfilePicture?.url);
            }
            dispatch(clientActions.editClientUser({ ...data, id, userId: selectedUserId }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Profile picture"
                value={values.logo}
                acceptedTypes={CLIENT_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  CLIENT_LOGO_ALLOWED_EXTENSIONS
                )}`}
                inputLabel={uploadedProfilePicture ? `${uploadedProfilePicture?.name}` : 'Select'}
                loading={fileUploadLoading}
              />
              <TextField
                type="name"
                name="firstName"
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="First name"
              />
              <TextField
                type="name"
                name="lastName"
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Last name"
              />
              <PhoneInput
                name="phoneNumber"
                id="phoneNumber"
                country="lk"
                inputStyle={{
                  fontSize: '16px',
                  width: '100%',
                  height: '40px',
                  borderRadius: '0px',
                  lineHeight: '20px',
                }}
                buttonStyle={{ backgroundColor: COLORS.WHITE }}
                fullWidth="true"
                containerStyle={{ width: '100%' }}
                onBlur={handleBlur}
                countryCodeEditable="true"
                placeholder=""
                label="Phone number"
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                errorMsg={touched?.phoneNumber && errors?.phoneNumber}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={
                  loading ||
                  fileUploadLoading ||
                  (JSON.stringify(initialValues) === JSON.stringify(values) &&
                    !uploadedProfilePicture)
                }
                rightIcon={loading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditClientUsersModal;
