import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import Popup from 'features/base/components/modal';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import EditSkillsForm from 'features/skills/components/edit-skills/edit-skills-form';
/**
 * Function that defines the popup form for editing skills
 * @returns {Popup}
 */
const EditSkillsPopup = ({ editSkillsModalOpen, setEditSkillsOpen, skillIdToEdit }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setEditSkillsOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (
      editSkillsModalOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SKILL,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editSkillsModalOpen}
        onClose={handleOnClose}
        title="Edit skill"
        mediumSize="660px"
        titleSx={{ pl: '0.8rem' }}
      >
        <EditSkillsForm openSetter={setEditSkillsOpen} skillIdToEdit={skillIdToEdit} />
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditSkillsPopup;
