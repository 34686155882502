import { Box, IconButton } from '@mui/material';
import eyeIcon from 'features/base/assets/images/svg/eye.svg';
/**
 * View user salary table columns - basic details
 */
const USERS_TABLE_SALARY_COLUMN = {
  field: 'salary',
  headerName: 'Salary',
  headerClassName: 'super-app-theme--header',
  width: 130,
  valueGetter: (params) => params.row.salary.value,
  renderCell: (params) => {
    const {
      value,
      salaryViewPermissionsVerified,
      salaryUpdatePermissionsVerified,
      handleViewSalaryHistoryOnClick,
    } = params.row.salary;
    return salaryViewPermissionsVerified ? (
      <Box display="flex" alignItems="center">
        {salaryUpdatePermissionsVerified && (
          <IconButton onClick={handleViewSalaryHistoryOnClick}>
            <img src={eyeIcon} alt="eye" />
          </IconButton>
        )}
        {value}
      </Box>
    ) : undefined;
  },
};
//
export default USERS_TABLE_SALARY_COLUMN;
