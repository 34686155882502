import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectIsWorkAllocationFetching,
  selectWorkAllocations,
  selectProject,
  selectLoader,
  selectChangeTicketAssigneeLoader,
  selectChangeTicketNotifierLoader,
  selectIsProjectFetching,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { DataGrid } from 'features/base/components';
import WORK_ALLOCATION_TABLE_HEADERS from 'features/base/utils/tables/work-allocation-table';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { notificationActions } from 'features/base/notifications/slice';
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
/**
 * Table component to render work allocations of a project
 * @returns {Box}
 */
const WorkAllocationTable = () => {
  const dispatch = useDispatch();
  const { projectId } = useOutletContext();
  //
  const workAllocations = useSelector(selectWorkAllocations);
  const isWorkAllocationLoading = useSelector(selectIsWorkAllocationFetching);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const project = useSelector(selectProject);
  const isLoading = useSelector(selectLoader);
  const isChangeTicketAssigneeLoading = useSelector(selectChangeTicketAssigneeLoader);
  const isChangeTicketNotifierLoading = useSelector(selectChangeTicketNotifierLoader);
  //
  const isFirst = useIsInitialize(project);
  //
  useEffect(() => {
    dispatch(
      projectActions.getWorkAllocation({
        projectId,
        query: `pagination=false&projectIds=${projectId}`,
      })
    );
    return () => {
      dispatch(notificationActions.resetNotification());
    };
  }, []);
  return (
    <Box>
      {isFirst ? (
        <ShimmerTable row={3} col={8} />
      ) : (
        <DataGrid
          columns={WORK_ALLOCATION_TABLE_HEADERS}
          rows={
            (project &&
              workAllocations &&
              workAllocations?.docs
                ?.toSorted((firstAllocation, secondAllocation) =>
                  firstAllocation?.userId?.firstName?.localeCompare(
                    secondAllocation?.userId?.firstName
                  )
                )
                ?.map((allocation) => ({
                  ...allocation,
                  id: allocation?.id,
                  profile: {
                    img: allocation?.userId?.profileImage,
                    name: `${allocation?.userId?.firstName} ${allocation.userId?.lastName}`,
                    designation:
                      allocation?.userId?.currentUserDepartmentDesignationId
                        ?.departmentDesignationId?.designationId?.name,
                  },
                  email: {
                    value: `${allocation?.userId?.email}`,
                  },
                  type: {
                    value: `${allocation?.userId?.type}`,
                  },
                  startDate: {
                    value: new Date(allocation?.startDate).toLocaleDateString(
                      DATE_LANGUAGE.LANGUAGE,
                      DATE_FORMAT_SLASH
                    ),
                  },
                  endDate: {
                    value: new Date(allocation?.endDate).toLocaleDateString(
                      DATE_LANGUAGE.LANGUAGE,
                      DATE_FORMAT_SLASH
                    ),
                  },
                  updatedDate: {
                    value: new Date(allocation?.updatedAt).toLocaleDateString(
                      DATE_LANGUAGE.LANGUAGE,
                      DATE_FORMAT_SLASH
                    ),
                  },
                  allocation: {
                    value: `${allocation?.allocatedPercentage}%`,
                  },
                  status: {
                    status: allocation?.dataStatus,
                  },
                  ticketAssignee: {
                    defaultChecked:
                      project?.ticketAssignees?.find(
                        (assignee) => assignee?.id === allocation?.userId?.id
                      ) !== undefined,
                    onChange: () => {
                      if (
                        project?.ticketAssignees?.find(
                          (assignee) => assignee?.id === allocation?.userId?.id
                        ) !== undefined
                      ) {
                        // if the user is already in the list, remove it
                        dispatch(
                          projectActions.changeTicketAssignees({
                            id: projectId,
                            ticketAssignees: (() => {
                              const ids =
                                project.ticketAssignees?.map((assignee) => assignee?.id) || [];
                              const edited =
                                ids?.filter((id) => id !== allocation?.userId?.id) || [];
                              return edited;
                            })(),
                          })
                        );
                      } else {
                        // if the user is not in the list, add it
                        dispatch(
                          projectActions.changeTicketAssignees({
                            id: projectId,
                            ticketAssignees: (() => {
                              const ids =
                                project.ticketAssignees?.map((assignee) => assignee?.id) || [];
                              const edited = [...ids, allocation?.userId?.id];
                              return edited;
                            })(),
                          })
                        );
                      }
                    },
                    isWorkAllocationLoading,
                    // disable if ticketNotifiers is checked
                    disabled:
                      isLoading ||
                      project?.ticketNotifiers?.find(
                        (notifier) => notifier?.id === allocation?.userId?.id
                      ),
                  },
                  ticketNotifiers: {
                    defaultChecked:
                      project?.ticketNotifiers?.find(
                        (notifier) => notifier?.id === allocation?.userId?.id
                      ) !== undefined,
                    onChange: () => {
                      if (
                        project?.ticketNotifiers?.find(
                          (notifier) => notifier?.id === allocation?.userId?.id
                        ) !== undefined
                      ) {
                        // if the user is already in the list, remove it
                        dispatch(
                          projectActions.changeTicketNotifiers({
                            id: projectId,
                            ticketNotifiers: (() => {
                              const ids =
                                project.ticketNotifiers?.map((notifier) => notifier?.id) || [];
                              const edited =
                                ids?.filter((id) => id !== allocation?.userId?.id) || [];
                              return edited;
                            })(),
                          })
                        );
                      } else {
                        // if the user is not in the list, add it
                        dispatch(
                          projectActions.changeTicketNotifiers({
                            id: projectId,
                            ticketNotifiers: (() => {
                              const ids =
                                project.ticketNotifiers?.map((notifier) => notifier?.id) || [];
                              const edited = [...ids, allocation?.userId?.id];
                              return edited;
                            })(),
                          })
                        );
                      }
                    },
                    isWorkAllocationLoading,
                    disabled:
                      isLoading ||
                      // disable if ticketAssignee is unchecked
                      !project?.ticketAssignees?.find(
                        (assignee) => assignee.id === allocation?.userId?.id
                      ),
                  },
                }))) ||
            []
          }
          loading={
            isProjectFetching ||
            isWorkAllocationLoading ||
            isChangeTicketAssigneeLoading ||
            isChangeTicketNotifierLoading
          }
          serverSidePagination={false}
          limit={WORK_ALLOCATION_PAGINATION_LIMIT}
          rowHeight={workAllocations?.docs?.length ? 52 : 200}
        />
      )}
    </Box>
  );
};
//
export default WorkAllocationTable;
