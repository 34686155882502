/* eslint-disable import/prefer-default-export */

/**
 * Remove UUIDv4 prefix from filename
 * @param {string} filename
 * @returns {string}
 * @example
 * removeUUIDv4Prefix('123e4567-e89b-12d3-a456-426655440000-foo.jpg')
 * // => 'foo.jpg'
 */
export const removeUUIDv4Prefix = (filename) => {
  if (typeof filename !== 'string') {
    return filename;
  }
  const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-/i;
  return filename.replace(uuidv4Regex, '');
};
