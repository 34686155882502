import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
//
import COLORS from 'features/base/constants/colors';
import MultiSelectAutoComplete from '../multi-select-auto-complete';
/**
 * Function that renders a given data list as clearable cards
 * @prop {Array} items - The list of items (if this is an object array, displayKey is a must)
 * @prop {Function} handleRemove - Function to run when the clear icon is clicked
 * @prop {String} displayKey - Required when items are a list of objects, Specifies which value to display
 * @prop {Object} sx - Custom sx
 * @prop {Boolean} renderInternalSelect - Renders a multi select autocomplete complete within each list item if true
 * @prop {Object} internalSelectProps - The props for the internal multi select component (renderInternalSelect must be true and options, setSelectedItems is required)
 * @prop {String} internalSelectOptionsKey - Indicates the key in each item to use as the selectedOptions of the multi select component. The key must be present in each item
 * @prop {Boolean} isInternalSelectMultiple - If true, the internal select is multiple, if false its a single select. Default is true
 * @prop {String} internalOptionsPlaceholder - Placeholder for the internal options list
 * @returns {DataList}
 */
const DataList = ({
  items,
  handleRemove,
  displayKey,
  sx,
  disabled,
  renderInternalSelect,
  internalSelectProps,
  internalSelectOptionsKey,
  isInternalSelectMultiple = true,
  internalOptionsPlaceholder,
}) => (
  <Box sx={{ maxHeight: 125, ...sx }} overflow="auto">
    <List>
      {items?.map((item) => (
        <ListItem
          key={displayKey ? item?.[displayKey] : item}
          sx={{
            backgroundColor: COLORS.ALICE_BLUE,
            marginBottom: '0.25rem',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <ListItemText
                primary={displayKey ? item?.[displayKey] : item}
                sx={{
                  wordBreak: 'break-word',
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => handleRemove(item)}
                sx={{
                  color: COLORS.FADED_BLACK,
                }}
                disabled={disabled}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
            {renderInternalSelect && (
              <Grid item xs={12}>
                {isInternalSelectMultiple ? (
                  <MultiSelectAutoComplete
                    {...internalSelectProps}
                    // Setting the selectedOptions based on the provided key
                    selectedOptions={item?.[internalSelectOptionsKey] ?? []}
                    setSelectedOptions={(newValues) =>
                      internalSelectProps?.setSelectedOptions(item, newValues)
                    }
                  />
                ) : (
                  <Autocomplete
                    {...internalSelectProps}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          fieldset: {
                            borderRadius: '0px',
                          },
                        }}
                        size="small"
                        label=""
                        placeholder={internalOptionsPlaceholder}
                      />
                    )}
                    onChange={(_, newValue) => {
                      internalSelectProps?.onChange(item, newValue);
                    }}
                    value={item?.[internalSelectOptionsKey] ?? ''}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </ListItem>
      ))}
    </List>
  </Box>
);
//
export default DataList;
