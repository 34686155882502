/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  Chip,
  IconButton,
  CircularProgress,
  Grid,
  Alert,
  Button as MuiButton,
} from '@mui/material';
import { DeleteForever, Add } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
//
import { benefitsActions } from 'features/benefits/slice';
import { notificationActions } from 'features/base/notifications/slice';
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import {
  selectBenefitById,
  selectEditBenefitPromotionsLoader,
  selectEditBenefitPromotions,
  selectDeletePromotionLoader,
  selectDeletePromotionResponse,
  selectAddPromotionLoader,
  selectAddPromotionResponse,
} from 'features/benefits/selectors';
import { TextField, Popup } from 'features/base/components';
import { PermissionWrapper } from 'features/base/auth/components';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { BENEFIT_PROMOTION_CODE_TYPES } from 'features/base/constants/benefit-types';
/**
 * Function that defines the popup form for editing promotions
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @returns {Popup}
 */
const EditPromotionsPopup = ({ promoEditModalOpen, setPromoEditModalOpen, benefitId }) => {
  const dispatch = useDispatch();
  //
  const benefitToEdit = useSelector((state) => selectBenefitById(state, benefitId));

  const promotions = useSelector(selectEditBenefitPromotions);
  const promotionsLoader = useSelector(selectEditBenefitPromotionsLoader);
  //
  const deletePromotionLoader = useSelector(selectDeletePromotionLoader);
  const deletePromotionResponse = useSelector(selectDeletePromotionResponse);
  //
  const addPromotionLoader = useSelector(selectAddPromotionLoader);
  const addPromotionResponse = useSelector(selectAddPromotionResponse);
  //
  const [value, setValue] = useState('');
  const [existingPromotions, setExistingPromotions] = useState([]);
  const [deletePromoPopupOpen, setDeletePromoPopupOpen] = useState(false);
  const [promoToDelete, setPromoToDelete] = useState(null);
  //
  const handleOnClose = () => {
    setPromoEditModalOpen(false);
    setExistingPromotions([]);
    dispatch(notificationActions.resetNotification());
    setValue('');
  };
  //
  const handleAddPromotion = (promoCode) => {
    const obj = {
      benefitId,
      resource: promoCode,
    };
    dispatch(benefitsActions.addPromotion({ benefitId, body: [obj] }));
  };
  //
  useEffect(() => {
    setExistingPromotions(promotions);
  }, [promotions]);
  //
  const deletePromotion = () => {
    dispatch(benefitsActions.deletePromotion({ benefitId, promotionId: promoToDelete.id }));
  };
  //

  useEffect(() => {
    if (addPromotionResponse && benefitToEdit?.id) {
      dispatch(benefitsActions.getPromotionsToEdit({ benefitId: benefitToEdit?.id }));
    }
  }, [addPromotionResponse]);
  //
  useEffect(() => {
    if (Object.keys(deletePromotionResponse).length) {
      setDeletePromoPopupOpen(false);
      const updatedPromotions = existingPromotions.filter(
        (promo) => promo?.id !== promoToDelete?.id
      );
      setExistingPromotions(updatedPromotions);
      setPromoToDelete(null);
    }
  }, [deletePromotionResponse]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={promoEditModalOpen}
        title="Edit Promo Codes"
        mediumSize="888px"
        onClose={handleOnClose}
      >
        <ConfirmationPopup
          open={deletePromoPopupOpen}
          displayMessage={`Do you really want to delete this promotion? (${promoToDelete?.resource})`}
          handleOnClose={() => setDeletePromoPopupOpen(false)}
          handleOnConfirmation={deletePromotion}
          loading={deletePromotionLoader}
        />
        <Box>
          <Typography variant="h6" gutterBottom>
            {'Add promotion'}
          </Typography>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={8}>
              <TextField
                type="text"
                name="Promo Code"
                label="Promo Code"
                value={value}
                error={value?.length < 2 || value?.length > 15}
                showErrorAfterBlur
                helperText="Promo code must be between 2 and 15 characters"
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && value) {
                    setValue('');
                    handleAddPromotion(value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <MuiButton
                variant="filled"
                endIcon={<Add />}
                onClick={() => {
                  if (value) {
                    setValue('');
                    handleAddPromotion(value);
                  }
                }}
                sx={{ marginLeft: '1.5rem' }}
                disabled={
                  addPromotionLoader ||
                  !value ||
                  value?.length < 2 ||
                  value?.length > 15 ||
                  (benefitToEdit?.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE &&
                    existingPromotions.length > 0)
                }
              >
                Insert
              </MuiButton>
            </Grid>
          </Grid>
          {benefitToEdit?.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE &&
            existingPromotions.length > 0 &&
            value && (
              <Alert variant="filled" severity="warning">
                Benefits with multiple use promotion codes can only have one promotion code at a
                time. Deleting the existing promotion code will allow you to replace it with a new
                one.
              </Alert>
            )}

          <Typography variant="h6" gutterBottom mt={2}>
            {'Existing promotions'}
          </Typography>

          {existingPromotions?.length === 0 && (
            <Typography variant="body2" gutterBottom>
              No promotions added yet
            </Typography>
          )}

          {(promotionsLoader || addPromotionLoader) && (
            <Box sx={{ display: 'flex' }} mt={2} justifyContent="center">
              <CircularProgress />
            </Box>
          )}

          <Stack spacing={2}>
            {existingPromotions?.map((promo, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ marginRight: '0.5rem', width: '12rem' }}>
                  <Chip
                    label={promo?.resource}
                    variant="filled"
                    sx={{
                      marginRight: '0.5rem',
                      minWidth: '12rem',
                    }}
                    color="primary"
                  />
                </Box>

                <PermissionWrapper
                  requiredPermissions={[
                    {
                      domain: PERMISSION_DOMAINS.BENEFIT,
                      action: PERMISSION_ACTIONS.DELETE,
                    },
                  ]}
                  hide
                >
                  <IconButton
                    disabled={deletePromotionLoader || promo?.userId}
                    onClick={() => {
                      setDeletePromoPopupOpen(true);
                      setPromoToDelete(promo);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </PermissionWrapper>

                {benefitToEdit?.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME && (
                  <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
                    {promo?.userId ? (
                      <>
                        Redeemed by{' '}
                        <span style={{ fontWeight: 'bold', color: 'blue' }}>
                          {promo?.userId?.email}
                        </span>
                      </>
                    ) : (
                      'Not redeemed'
                    )}
                  </Typography>
                )}
              </Box>
            ))}
          </Stack>
        </Box>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditPromotionsPopup;
