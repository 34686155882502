import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
//
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Button, DataGrid, SearchBar } from 'features/base/components';
import { vendorActions } from 'features/vendors/slice';
import { selectAllVendors, selectLoader } from 'features/vendors/selectors';
import { VENDORS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { DATE_FORMAT_SLASH, DATE_LANGUAGE } from 'features/base/constants/date-formatting';
import TIME_OUTS from 'features/base/constants/time-outs';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import createFormattedString from 'features/base/helpers/param-formatter';
import {
  VENDORS_TABLE_ACTION_COLUMNS,
  VENDORS_TABLE_COLUMNS_BASIC,
} from 'features/base/utils/tables/vendor-table';
import AddVendorPopup from '../add-vendor-modal';
import EditVendorPopup from '../edit-vendor-modal';
import DeleteVendorPopup from '../delete-vendor-modal';

/**
 * Function that defines the tool view
 * @returns {Grid}
 */
const VendorView = () => {
  //
  const dispatch = useDispatch();
  //
  const [searchData, setSearchData] = useState('');
  //
  const isLoading = useSelector(selectLoader);
  const allVendors = useSelector(selectAllVendors);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'name:asc',
    rowsPerPage: VENDORS_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [vendorId, setVendorId] = useState('');
  //
  const params = useMemo(
    () => ({
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
      dataStatus: DATA_STATUS_TYPES.ACTIVE,
    }),
    [pageController]
  );
  //
  const isFirst = useIsInitialize(allVendors);
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleVendorDelete = (id) => {
    setDeleteModalOpen(true);
    setVendorId(id);
  };
  //
  const searchVendors = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleOnClick = () => {
    setAddModalOpen(true);
  };
  //
  const debounced = useDebouncedCallback(searchVendors, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  const handleVendorEdit = (id) => {
    setEditModalOpen(true);
    setVendorId(id);
  };
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params, searchData);
    dispatch(
      vendorActions.getAllVendors({
        query: formattedParamString,
      })
    );
  }, [params]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 8 }} px={{ xs: 0, lg: 2 }}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
        }}
      >
        <Grid item sx={{ width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' } }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: '1.938rem',
              fontWeight: 600,
              mr: '48px',
            }}
          >
            Manage vendors
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            backgroundColor: { xs: 'white', sm: 'unset' },
            position: { xs: 'fixed', sm: 'unset' },
            bottom: { xs: '0px', sm: 'unset' },
            zIndex: { xs: 1, sm: 'unset' },
            width: { xs: '100%', sm: 'unset' },
            py: { xs: '10px', sm: 'unset' },
            px: { xs: '20px', sm: 'unset' },
            left: { xs: '0px', sm: 'unset' },
          }}
        >
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.TOOL,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            <Button sx={{ width: { lg: 'auto', xs: '100%' } }} onClick={handleOnClick}>
              Add Vendors <AddIcon />
            </Button>
          </PermissionWrapper>
        </Grid>
      </Grid>
      {/* Search Bar */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: {
            xs: 'space-between',
            sm: 'unset',
          },
          gap: { xs: 'unset', sm: '10px' },
          alignItems: 'center',
          my: '20px',
        }}
      >
        <Grid
          item
          sx={{
            width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
            gap: '10px',
          }}
        >
          <SearchBar
            placeholder="Search vendors by name or description"
            value={searchData}
            handleSearchInput={handleSearchInput}
          />
        </Grid>
      </Box>
      {isFirst ? (
        <ShimmerTable row={5} col={7} />
      ) : (
        <DataGrid
          columns={[...VENDORS_TABLE_COLUMNS_BASIC, ...VENDORS_TABLE_ACTION_COLUMNS]}
          rows={
            allVendors?.docs?.map((vendor) => ({
              ...vendor,
              vendor: {
                vendorName: vendor?.name,
                vendorLogo: vendor?.logo,
                vendorId: vendor?.id,
                hashId: vendor?.hashId,
              },
              description: { value: vendor?.description },
              email: { value: vendor?.email },
              createdDate: {
                value: new Date(vendor?.createdAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              lastUpdate: {
                value: new Date(vendor?.updatedAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              action: {
                isLoading,
                handleVendorEdit: () => handleVendorEdit(vendor?.id),
                handleVendorDelete: () => handleVendorDelete(vendor?.id),
                hashId: vendor?.hashId,
                vendorId: vendor?.id,
              },
            })) ?? []
          }
          handleChangePage={handleChangePage}
          page={pageController.page - 1}
          totalPages={allVendors?.totalPages ?? 0}
          totalDocs={allVendors?.totalDocs ?? 0}
          limit={allVendors?.limit ?? pageController.rowsPerPage}
          loading={isLoading && isFirst !== true}
          pageSizeOptions={[pageController.rowsPerPage]}
          rowHeight={allVendors?.docs?.length ? 52 : 200}
        />
      )}
      <AddVendorPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditVendorPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        vendorId={vendorId}
      />
      <DeleteVendorPopup
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        vendorId={vendorId}
      />
    </Container>
  );
};
//
export default VendorView;
