import { useState, useEffect } from 'react';
import isEmpty from '../helpers/object';
/**
 * Custom hook to check whether it is the first time the react component is rendered
 * @returns {Boolean}
 */
const useIsInitialize = (props) => {
  const [init, setInit] = useState(true);
  //
  useEffect(() => {
    if (!isEmpty(props)) {
      setInit(false);
    }
  }, [props]);
  //
  return init;
};
//
export default useIsInitialize;
