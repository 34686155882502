import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import COLORS from 'features/base/constants/colors';
import { isInteger } from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';
//
const AllocatedActualHoursTable = ({ allMonths, filteredAllocations }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {allMonths?.map((yearMonth) => (
            <TableCell
              size="small"
              colSpan={2}
              key={`${yearMonth}-month-titles`}
              sx={{
                bgcolor: COLORS.SKY_BLUE,
                margin: 0,
                padding: 0,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
            >
              <Typography
                textAlign={'center'}
                sx={{
                  width: 284,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {moment(yearMonth?.split('-')[1], 'MM').format('MMMM')} - {yearMonth?.split('-')[0]}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {allMonths?.map((yearMonth) => (
            <Fragment key={`${yearMonth}-hour-titles`}>
              <TableCell
                size="small"
                key={`${yearMonth}-hour-allocation-title`}
                sx={{
                  margin: 0,
                  padding: 0,
                  bgcolor: COLORS.SKY_BLUE,
                  borderBottom: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                  borderInline: `2px solid ${COLORS.WHITE}`,
                }}
              >
                <Typography
                  sx={{
                    width: 142,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  textAlign={'center'}
                  fontWeight={600}
                  fontSize={16}
                >
                  Allocated hrs
                </Typography>
              </TableCell>
              <TableCell
                size="small"
                key={`${yearMonth}-hour-logged-title`}
                sx={{
                  margin: 0,
                  padding: 0,
                  bgcolor: COLORS.SKY_BLUE,
                  borderBottom: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                }}
              >
                <Typography
                  sx={{
                    width: 142,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  textAlign={'center'}
                  fontWeight={600}
                  fontSize={16}
                >
                  Actual hrs
                </Typography>
              </TableCell>
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredAllocations?.map((currentAllocation) => (
          <TableRow key={`${currentAllocation.id}`}>
            {allMonths.map((yearMonth) => {
              const [year, month] = yearMonth.split('-');
              const allocationForThis = currentAllocation?.monthlyAllocations?.find(
                (allocationItem) =>
                  allocationItem?.month === parseInt(month, 10) &&
                  allocationItem?.year === parseInt(year, 10)
              );
              const cells = [allocationForThis?.allocatedHours, allocationForThis?.loggedHours];
              return (
                <Fragment key={`${yearMonth}-time-info`}>
                  {cells?.map((cellValue, index) => (
                    <TableCell
                      size="small"
                      key={`${yearMonth}-time-hours-${index + 1}`}
                      sx={{
                        bgcolor: index === 0 ? COLORS.MAGNOLIA_WHITE : COLORS.DARK_ALICE_BLUE,
                        borderLeft: `2px solid ${COLORS.WHITE}`,
                        borderBottom: `2px solid ${COLORS.WHITE}`,
                      }}
                    >
                      <Typography
                        textAlign={'center'}
                        sx={{
                          height: 68,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {(cellValue && !isInteger(cellValue) ? cellValue?.toFixed(2) : cellValue) ||
                          0}
                      </Typography>
                    </TableCell>
                  ))}
                </Fragment>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
//
export default AllocatedActualHoursTable;
