/**
 * Defines the data status types used in the application
 * ( soft delete )
 * @type {Object}
 * @property {string} ACTIVE - The data is active
 * @property {string} DELETED - The data is deleted
 * @property {string} INACTIVE - The data is inactive
 */
const DATA_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  INACTIVE: 'INACTIVE',
};
//
export default DATA_STATUS_TYPES;
