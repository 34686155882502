import { Alert } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import TextField from 'features/base/components/text-field';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { skillActions } from 'features/skills/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectEditSkillGroupLoader,
  selectSkillGroupById,
  selectSkills,
} from 'features/skills/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { editSkillGroupValidation } from 'features/skills/validation/skill-groups-validation';
import { removeUnchanged } from 'features/base/helpers/object';
/**
 * Function that defines the form for adding new skills
 * @returns {Form}
 * */
const EditSkillGroupsForm = ({ openSetter, skillGroupIdToEdit }) => {
  const dispatch = useDispatch();
  //
  const skillGroupToEdit = useSelector((state) => selectSkillGroupById(state, skillGroupIdToEdit));
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectEditSkillGroupLoader);
  const skills = useSelector(selectSkills);
  //
  const handleOnClose = () => {
    openSetter(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const initialValues = {
    name: skillGroupToEdit?.name,
    skills: skillGroupToEdit?.skills,
  };
  //
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editSkillGroupValidation}
      onSubmit={(values) => {
        let skillGroupData = { ...values };
        // Only send the attributes that have been changed
        skillGroupData = removeUnchanged(initialValues, skillGroupData);
        // Adding the 'skillIds' key and removing the 'skills' key
        if (skillGroupData?.skills) {
          skillGroupData = {
            ...skillGroupData,
            skillIds: values?.skills?.map((skill) => skill?.id),
          };
          delete skillGroupData.skills;
        }
        //
        dispatch(skillActions.editSkillGroup({ ...skillGroupData, id: skillGroupIdToEdit }));
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form noValidate onSubmit={handleSubmit} className="add-skills-form">
          {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
            <Alert sx={{ mb: 3 }} severity={notification?.type}>
              {notification?.message}
            </Alert>
          )}
          <TextField
            type="text"
            name="name"
            value={values.name}
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            onBlur={handleBlur}
            onChange={handleChange}
            label="Group Name"
          />
          <MultiSelectAutoComplete
            id="skills"
            name="skills"
            options={skills?.docs ?? []}
            selectedOptions={values?.skills ?? []}
            setSelectedOptions={setFieldValue}
            label="Skills"
            formikKey="skills"
            placeholder="Select skill"
            error={Boolean(touched.skills && errors.skills)}
            errorMsg={touched.skills && errors.skills}
            onClose={() => setFieldTouched('skills', true)}
            removeConfirmationPopup
            confirmationMessage="Do you really want to remove the skill from this group?"
          />
          <ButtonGrid
            leftButtonText="Cancel"
            rightButtonText={loading ? 'Submitting' : 'Submit'}
            leftOnClick={handleOnClose}
            rightOnClick={null}
            rightIcon={loading ? loaderIcon : null}
            submitDisabled={loading}
          />
        </form>
      )}
    </Formik>
  );
};
//
export default EditSkillGroupsForm;
