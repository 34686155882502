import { DATE_FORMAT_SLASH, DATE_LANGUAGE } from 'features/base/constants/date-formatting';
import moment from 'moment';

/**
 * Function for obtaining the data and time in the following format:
 * day/month/year - hours:minutes {am/pm}
 * @param {Date} date - Date to be formatted
 * @returns {String}
 */
export const getYearMonthHourMinuteFormat = (date) => {
  const newDate = new Date(date);
  const formattedDate = newDate.toLocaleDateString(DATE_LANGUAGE.LANGUAGE, DATE_FORMAT_SLASH);
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  // Convert to 12-hour format
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  // Pad the minutes and seconds with leading zeros if necessary
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  //
  return `${formattedDate} - ${hours}:${minutes} ${ampm}`;
};
/**
 * Function to extract the year from an ISO date string
 * @param {String} isoDateString
 * @returns {Number}
 */
export const getYear = (isoDateString) => {
  const date = new Date(isoDateString);
  return date.getUTCFullYear();
};
/**
 * Function to extract the montj from an ISO date string
 * @param {String} isoDateString
 * @returns {Number}
 */
export const getMonth = (isoDateString) => {
  const date = new Date(isoDateString);
  return date.getUTCMonth() + 1;
};
/**
 * Function to obtain the inclusive difference in months between two dates in ISO date string format
 * @param {String} startDate
 * @param {String} endDate
 * @returns {Number}
 */
export const getDifferenceInMonths = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'months') + 1;
};
/**
 * Function to obtain the number of working days between the provided date period
 * @param {String} startDateString
 * @param {String} endDateString
 * @returns {Number}
 */
export const getNumberOfWorkingDays = (startDateString, endDateString) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  //
  let workingDays = 0;
  //
  while (startDate <= endDate) {
    const dayOfWeek = startDate.getUTCDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      workingDays += 1;
    }
    startDate.setDate(startDate.getDate() + 1);
  }
  //
  return workingDays;
};
/**
 * Function to obtain a ISO string of the day of the provided month - year
 * @param {Number} year
 * @param {Number} month
 * @returns {String}
 */
export const getLastDayOfMonthISO = (year, month) => {
  // Create a Date object for the first day of the next month (Date takes month as 0-indexed, so no need to +1)
  const nextMonth = new Date(year, month, 1);
  // Subtract 1 day from the first day of the next month to get the last day of the provided month
  const lastDayOfMonth = new Date(nextMonth - 1);
  // Return the ISO format time string of the last day of the month
  return lastDayOfMonth.toISOString();
};
/**
 * A function that checks if the current month and year is within the range of two provided ISO date strings
 * @param {String} startDateStr
 * @param {String} endDateStr
 * @returns {Boolean}
 */
export const isCurrentMonthYearInRange = (startDateStr, endDateStr) => {
  if (!startDateStr || !endDateStr){
    return false
  }
  //
  const currentDate = new Date();
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  //
  return currentDate >= startDate && currentDate <= endDate;
};
