/**
 * Available priority types
 */
const JIRA_TICKET_SEVERITY_LEVELS = {
  LOWEST: 'Lowest',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  HIGHEST: 'Highest',
};

export default JIRA_TICKET_SEVERITY_LEVELS;
