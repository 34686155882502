import {
  Box,
  Link,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
//
import { getCommaSeparatedString } from 'features/base/helpers/array';
import { ARSENAL_ITEM_TYPES } from 'features/base/constants/arsenal-item-types';
import bitbucketIcon from 'features/base/assets/images/svg/bitbucket.svg';
import googleDriveIcon from 'features/base/assets/images/svg/google-drive.svg';
import confluenceIcon from 'features/base/assets/images/svg/confluence.svg';
import './index.scss';

/**
 * Function that returns a component with the info page of a certain arsenal item
 * @prop {Object} arsenalItem - Arsenal item containing the data to be displayed in the page
 * @returns {ArsenalInfoPage}
 */
const ArsenalInfoPage = ({ arsenalItem }) => {
  /**
   * Obtaining the categories of the links in the selected arsenal item including the
   * respective links for each category (lookup is used for optimization)
   */
  const categoryLookup = {};
  arsenalItem?.links?.forEach((link) => {
    if (Array.isArray(categoryLookup[link?.category])) {
      categoryLookup[link?.category].push(link);
    } else {
      categoryLookup[link?.category] = [link];
    }
  });
  //
  const getIcon = (category) => {
    if (category === ARSENAL_ITEM_TYPES.BITBUCKET) return bitbucketIcon;
    if (category === ARSENAL_ITEM_TYPES.CONFLUENCE) return confluenceIcon;
    if (category === ARSENAL_ITEM_TYPES.GOOGLE_DRIVE) return googleDriveIcon;
    return '';
  };
  //
  return (
    <>
      <Box className="description-container">
        <Typography>{arsenalItem?.description}</Typography>
      </Box>
      <Box className="users-container">
        <Table data-testid="arsenal-member-table">
          <TableBody>
            <TableRow key={1} className="greyRow" data-testid="arsenal-members-row">
              <TableCell className="leftValue">Team members</TableCell>
              <TableCell className="rightValue">
                {arsenalItem?.teamMembers?.length &&
                  getCommaSeparatedString(arsenalItem?.teamMembers?.map((member) => member?.email))}
              </TableCell>
            </TableRow>
            <TableRow key={2} className="whiteRow" data-testid="arsenal-owners-row">
              <TableCell className="leftValue">Product owners</TableCell>
              <TableCell className="rightValue">
                {arsenalItem?.productOwners?.length &&
                  getCommaSeparatedString(arsenalItem?.productOwners?.map((owner) => owner?.email))}
              </TableCell>
            </TableRow>
            <TableRow key={3} className="greyRow" data-testid="arsenal-leads-row">
              <TableCell className="leftValue">Tech leads</TableCell>
              <TableCell className="rightValue">
                {arsenalItem?.techLeads?.length &&
                  getCommaSeparatedString(arsenalItem?.techLeads?.map((lead) => lead?.email))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box data-testid="arsenal-category-info">
        {Object.keys(categoryLookup).map((category) => (
          <Box key={category} className="categories-container">
            <Stack direction="row">
              <div className="category-icon-svg-container">
                <img src={getIcon(category)} alt="SVG Icon" className="category-icon-svg" />
              </div>
              <Typography variant="h6" className="category-heading">
                {category}
              </Typography>
            </Stack>
            <List>
              {categoryLookup[category].map((link) => (
                <ListItem key={link?.url} sx={{ pt: '2px', pb: '2px' }}>
                  <Link href={link?.url} underline="none" target="_blank" className="link-text">
                    {link?.url}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </>
  );
};
//
export default ArsenalInfoPage;
