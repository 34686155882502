import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import { PROJECT_TYPES } from 'features/base/constants/project-types';

/**
 * Check if the revenue is editable for the given project billing type and project type
 * @summary Revenue is editable if the project type is client and billing type is not fixed bid
 * @param {String} projectBillingType - Project billing type
 * @param {String} projectType - Project type
 * @returns {Boolean} - Returns true if the revenue is editable, otherwise returns false
 */
const isRevenueEditable = (project) => {
  const { billingType, type } = project;
  return type === PROJECT_TYPES.CLIENT && billingType !== PROJECT_BILLING_TYPES.FIXED_BID;
};
/**
 * Check if the billing type has hourly rate or not
 * @param {String} billingType - Project billing type [FIXED_BID, ...]
 * @returns {Boolean} - Returns true if the billing type has hourly rate, otherwise returns false
 * @example
 * hasHourlyRate('FIXED_BID') // false
 * hasHourlyRate('TIME_AND_MATERIAL') // true
 */
const hasHourlyRate = (billingType) =>
  billingType === PROJECT_BILLING_TYPES.RETAINER ||
  billingType === PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE ||
  billingType === PROJECT_BILLING_TYPES.STAFF_AUGMENTATION ||
  billingType === PROJECT_BILLING_TYPES.TIME_AND_MATERIAL;
/**
 * Function to obtain the total hours of users
 */
const getTotalHoursOfUsers = (workAllocations) =>
  workAllocations?.docs
    ?.reduce(
      (acc, allocation) =>
        acc +
        (allocation?.monthlyAllocations?.reduce(
          (monthlyAcc, curr) => monthlyAcc + curr.allocatedHours,
          0
        ) ?? 0),
      0
    )
    ?.toFixed(2);
/**
 * Function to obtain the total revenue of a project
 */
const totalRevenuePerProject = (workAllocationsBilling) =>
  workAllocationsBilling?.docs?.reduce((acc, allocation) => {
    // Calculating the total hours per person
    const totalHrsPerPerson = allocation?.monthlyAllocations?.reduce(
      (monthlyAcc, monthlyAllocation) => monthlyAcc + monthlyAllocation.allocatedHours,
      0
    );
    const hourlyRate = allocation?.monthlyAllocations?.[0]?.hourlyRate;
    const revenue = Number(
      hourlyRate <= 0 ? 0 : ((hourlyRate ?? 0) * totalHrsPerPerson).toFixed(2)
    );
    return acc + revenue;
  }, 0);

/**
 * Function to get total revenue per month for monthly fixed projects
 */
const totalRevenuePerMonth = (workAllocationsBilling) =>
  workAllocationsBilling?.docs?.reduce((acc, allocation) => 
     acc + (allocation?.monthlyAllocations?.[0]?.revenue ?? 0)
  , 0);
//
export default {
  isRevenueEditable,
  hasHourlyRate,
  getTotalHoursOfUsers,
  totalRevenuePerProject,
  totalRevenuePerMonth,
};
