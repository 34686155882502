import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import {
  Container,
  Box,
  Grid,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  TablePagination,
  TableFooter,
  CircularProgress,
} from '@mui/material';
import { Sync as SyncIcon, Download as DownloadIcon } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce';
import { toast } from 'react-toastify';
//
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import createFormattedString from 'features/base/helpers/param-formatter';
import TIME_OUTS from 'features/base/constants/time-outs';
import { SearchBar, Button } from 'features/base/components';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import { downloadCSVFile } from 'features/base/helpers/file';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { selectNotification } from 'features/base/notifications/selectors';
import TableHeader from './components/table-header';
import { selectSkillStats, selectSkillStatsLoading } from '../selectors';
import { reportActions } from '../slice';

const SkillReportView = () => {
  const dispatch = useDispatch();
  //
  const skillStats = useSelector(selectSkillStats);
  const loading = useSelector(selectSkillStatsLoading);
  const [searchData, setSearchData] = useState('');
  const notification = useSelector(selectNotification);
  //
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  //
  const isFirst = useIsInitialize(skillStats);
  //
  const params = useMemo(
    () => ({
      aggregated: true,
      sortBy: 'skillGroup.name:asc',
      limit: rowsPerPage,
      page,
    }),
    [page, rowsPerPage]
  );
  //
  const searchSkill = () => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getSkillStats({ query: `${formattedParamString}&search=${searchData}` })
    );
  };
  //
  const debounced = useDebouncedCallback(searchSkill, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (event) => {
    setSearchData(event.target.value);
    debounced();
  };
  //
  const handleChangePage = (e, newPage) => {
    setPage(newPage + 1);
  };
  //
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  //
  const initilialize = () => {
    dispatch(reportActions.setSkillReportIsInitial());
  };
  //
  const handleExport = () => {
    if (!skillStats?.docs?.length) {
      dispatch(notificationActions.setNotification({
        message: 'No data to export',
        type: ERROR_TYPES.INFO,
      }))
      return;
    }
    //
    const data = skillStats?.docs?.flatMap((skillStat) =>
      skillStat?.skills?.map((skill) => ({
        'Skill Group': skillStat?.skillGroup?.name ?? '-',
        'Skill Name': skill?.skill?.name ?? '-',
        Total: skill?.total ?? '',
        L3: skill?.l3 ?? 0,
        L2: skill?.l2 ?? 0,
        L1: skill?.l1 ?? 0,
        Intern: skill?.intern ?? 0,
        Unassigned:
          (skill?.total ?? 0) -
            ((skill?.intern ?? 0) + (skill?.l1 ?? 0) + (skill?.l2 ?? 0) + (skill?.l3 ?? 0) ?? 0) ??
          0,
      }))
    );
    //
    downloadCSVFile(Object.keys(data?.[0]), data, 'skill_report.csv');
  };
  //
  const handleSync = () => {
    initilialize();
    const formattedParamString = createFormattedString(params);
    dispatch(reportActions.getSkillStats({ query: `${formattedParamString}&runAggregation=true` }));
  };
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(reportActions.getSkillStats({ query: `${formattedParamString}` }));
  }, [params]);
  // Cleanup function to reduce lag
  useEffect(
    () => () => {
      dispatch(reportActions.resetSkillReport());
    },
    []
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 8 }} px={{ xs: 0, lg: 2 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Skill Report
      </Typography>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: 3,
          mb: 2,
          py: 1,
        }}
      >
        <Grid item xs={12} lg={6} sm={12} md={6} sx={{ mb: { xs: 2 } }}>
          <Box sx={{ width: { sm: '50%', xs: '100%' }, pt: 1 }}>
            <SearchBar
              placeholder="Search skill groups or skills"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} display="flex" justifyContent={{ lg: 'flex-end', md: 'flex-end' }}>
          <Button
            onClick={handleExport}
            disabled={loading}
            sx={{ width: 'auto', marginRight: '0.75rem' }}
          >
            <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
            Export
          </Button>
          <Button onClick={handleSync} disabled={loading} sx={{ width: 'auto' }}>
            <SyncIcon sx={{ marginLeft: '0.25rem' }} />
            Sync
          </Button>
        </Grid>
      </Grid>
      {(isFirst && <ShimmerTable row={6} col={7} />) || (
        <TableContainer sx={{ mt: 4 }}>
          <Table>
            <TableHeader />
            <TableBody>
              {loading ? (
                <TableCell colSpan={7}>
                  {' '}
                  <Box sx={{ textAlign: 'center', minWidth: '100%', minHeight: '100%', m: 10 }}>
                    <CircularProgress size={80} />
                  </Box>
                </TableCell>
              ) : (
                (skillStats?.docs?.length &&
                  skillStats?.docs?.map((skillStat) => (
                    <>
                      <TableRow key={skillStat?.skillGroup?.id}>
                        <TableCell rowSpan={(skillStat?.skills?.length ?? 0) + 1}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {skillStat?.skillGroup?.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {skillStat?.skills?.map((skill) => (
                        <TableRow key={skill?.id}>
                          <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {skill?.skill?.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>{skill?.total}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>{skill?.l3}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>{skill?.l2}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>{skill?.l1}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>{skill?.intern}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {(skill?.total ?? 0) -
                                ((skill?.intern ?? 0) +
                                  (skill?.l1 ?? 0) +
                                  (skill?.l2 ?? 0) +
                                  (skill?.l3 ?? 0) ?? 0) ?? 0}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))) || (
                  <TableCell colSpan={7}>
                    <CustomNoRowsOverlay />
                  </TableCell>
                )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3, 6, 9, 12]}
                  count={skillStats?.totalDocs ?? 0}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default SkillReportView;
