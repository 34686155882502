import { Alert, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
//
import departmentFormValidation from 'features/departments/validations/department-validation';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectDepartmentById, selectEditDepartmentsLoader } from 'features/departments/selectors';
import { departmentActions } from 'features/departments/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { selectAllDesignation } from 'features/designations/selectors';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { removeUnchanged } from 'features/base/helpers/object';

/**
 * Function that defines the popup form for adding a new department
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} departmentId - id of the department to be edited
 * @returns {Popup}
 */
const EditDepartmentPopup = ({ editModalOpen, setEditModalOpen, departmentId }) => {
  const dispatch = useDispatch();
  //
  const designations = useSelector(selectAllDesignation);
  const notification = useSelector(selectNotification);
  const departmentToEdit = useSelector((state) => selectDepartmentById(state, departmentId));
  const loading = useSelector(selectEditDepartmentsLoader);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    id: departmentToEdit?.department?.id,
    name: departmentToEdit?.department?.name,
    designations: departmentToEdit?.designations,
  };
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit Department and Designation"
        mediumSize="860px"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={departmentFormValidation}
          onSubmit={(values) => {
            let departmentData = { ...values };
            departmentData = removeUnchanged(initialValues, departmentData);
            if (departmentData?.designations) {
              departmentData.designations = departmentData.designations.map(
                (designation) => designation.id
              );
            }
            //
            dispatch(departmentActions.editDepartment({ ...departmentData, id: values?.id }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 2 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid container item direction="column">
                  <TextField
                    type="text"
                    name="name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    my={2}
                    label="Department Name"
                  />
                  <Grid>
                    <MultiSelectAutoComplete
                      options={designations?.docs || []}
                      selectedOptions={values.designations}
                      setSelectedOptions={setFieldValue}
                      label="Designations"
                      formikKey="designations"
                      placeholder="Select Designations"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditDepartmentPopup;
