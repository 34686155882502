/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { departmentActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a department
 * @param {Object} payload
 */
export function* addDepartmentGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_DEPARTMENT, payload);
    yield put(departmentActions.addDepartmentSucceeded(response));
  } catch (error) {
    yield put(departmentActions.addDepartmentFailed(error?.message));
  }
}
/**
 * Generator function for adding a workArea
 * @param {Object} payload
 */
export function* addWorkAreaGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_WORK_AREA, payload);
    yield put(
      departmentActions.addWorkAreaSucceeded({
        results: response,
        workAreas: payload.workArea,
      })
    );
  } catch (error) {
    yield put(departmentActions.addWorkAreaFailed(error?.message));
  }
}
/**
 * Generator function for getting all departments
 * @param {Object} payload
 */
export function* getDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENT, payload);
    yield put(departmentActions.getAllDepartmentSucceeded(response));
  } catch (error) {
    yield put(departmentActions.getAllDepartmentFailed(error?.message));
  }
}
/**
 * Generator function for getting all work areas
 * @param {Object} payload
 */
export function* getWorkAreasGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_AREA, payload);
    yield put(departmentActions.getAllWorkAreaSucceeded(response));
  } catch (error) {
    yield put(departmentActions.getAllWorkAreaFailed(error?.message));
  }
}
/**
 * Generator function for editing a department
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_DEPARTMENT, payload);
    yield put(departmentActions.editDepartmentSucceeded(response));
  } catch (error) {
    yield put(departmentActions.editDepartmentFailed(error?.message));
  }
}
/**
 * Generator function for editing a work area
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editworkAreasGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_WORK_AREA, payload);
    yield put(departmentActions.editWorkAreaSucceeded(response));
  } catch (error) {
    yield put(departmentActions.editWorkAreaFailed(error?.message));
  }
}
/**
 * Redux saga that triggers functions
 */
export function* departmentSaga() {
  yield takeLatest(departmentActions.addDepartment.type, addDepartmentGenerator);
  yield takeLatest(departmentActions.addWorkArea.type, addWorkAreaGenerator);
  yield takeLatest(departmentActions.getAllDepartment.type, getDepartmentsGenerator);
  yield takeLatest(departmentActions.getAllWorkArea.type, getWorkAreasGenerator);
  yield takeLatest(departmentActions.editDepartment.type, editDepartmentsGenerator);
  yield takeLatest(departmentActions.editWorkArea.type, editworkAreasGenerator);
}
//
export default departmentSaga;
