import { Paper, TableContainer } from '@mui/material';
import { Gantt, ViewMode } from 'gantt-task-react-pro';
import 'gantt-task-react-pro/dist/index.css';

const TimelineTable = ({ tasks, handleChange, editPermissions }) => (
  <TableContainer component={Paper}>
    <Gantt
      tasks={tasks}
      listCellWidth=""
      headerHeight={80}
      viewMode={ViewMode.Day}
      rowHeight={52}
      onDateChange={editPermissions ? handleChange : null}
    />
  </TableContainer>
);

export default TimelineTable;
