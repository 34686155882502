import * as yup from 'yup';
/**
 * Function for add skill form validation
 */
export const addSkillValidation = yup.object().shape({
  skills: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Please Enter a valid name'),
      })
    )
    .min(1)
    .test('unique', 'Skill names must be unique', (value) => {
      const skillNames = value?.map((skill) => skill.name) ?? [];
      const uniqueSkillNames = new Set(skillNames);
      return uniqueSkillNames.size === skillNames?.length;
    }),
});
/**
 * Function for edit skill form validation
 */
export const editSkillValidation = yup.object().shape({
  name: yup.string().required('Please enter a valid skill name'),
});
