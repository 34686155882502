/**
 * Available user types
 * @type {{INTERNAL: string, CLIENT: string, INTERN: string, CONTRACT: string, ADMIN: string}}
 */
export const USER_TYPES = {
  CLIENT: 'CLIENT',
  CONTRACT: 'CONTRACT',
  INTERNAL: 'INTERNAL',
  INTERN: 'INTERN',
};
/**
 * Surge user types list
 */
export const INTERNAL_USER_TYPES = [
  { key: 1, value: USER_TYPES.INTERNAL },
  { key: 2, value: USER_TYPES.INTERN },
  { key: 3, value: USER_TYPES.CONTRACT },
];
/**
 * Available internal and contract user level types
 */
export const USER_LEVELS = {
  LEVEL_0: 'L0',
  LEVEL_1: 'L1',
  LEVEL_2: 'L2',
  LEVEL_3: 'L3',
  LEVEL_4: 'L4',
  GENERAL_ADMINISTRATION: 'GA',
  GENERAL_ADMINISTRATION_LEVEL_1: 'GA L1',
  GENERAL_ADMINISTRATION_LEVEL_2: 'GA L2',
  GENERAL_ADMINISTRATION_HEAD_OF_TEAM: 'GA Head of Team',
  C_LEVEL: 'C Level',
};
