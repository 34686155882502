import { Avatar, Box, Typography, Checkbox } from '@mui/material';
import removeIcon from 'features/base/assets/images/png/remove-icon.png';
import { DatePicker, TextField } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
/**
 * Defines the table header details for selected table
 */
const SELECTED_TEAM_MEMBER_HEADERS = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'super-app-theme--header',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const {
        user,
        onSelectedUserCheckboxClick,
        multipleSelectedUsers,
        onRemoveIconClick,
        isSelectedUserMultipleSelectEnable,
      } = params.row.icon;
      return (
        !user.isAllocatedPercentageLocked &&
        (isSelectedUserMultipleSelectEnable ? (
          <Checkbox
            sx={{
              p: 0,
            }}
            onChange={() => onSelectedUserCheckboxClick(user)}
            value={user?.userId?.id}
            checked={Boolean(multipleSelectedUsers.find((selectedUser) => selectedUser?.userId?.id === user?.userId?.id))}
          />
        ) : (
          <Box
            onClick={() => onRemoveIconClick(user)}
            sx={{
              width: '20px',
              height: '20px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <img src={removeIcon} alt="remove-icon" />
          </Box>
        ))
      );
    },
  },
  {
    field: 'profile',
    headerName: 'Profile',
    headerClassName: 'super-app-theme--header',
    flex: 1.25,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { value, profileImage, designation } = params.row.profile;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '30ch' }}>
          {profileImage ? (
            <img
              alt="profile"
              src={profileImage}
              style={{ height: '40px', width: '40px', borderRadius: '50%' }}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Avatar sx={{ height: '40px', width: '40px' }}>{value.charAt(0)}</Avatar>
          )}
          <Box sx={{ ml: '8px' }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                maxWidth: '25ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '14px',
                color: COLORS.SILVER_LILAC,
                maxWidth: '25ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {designation}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    type: 'date',
    valueGetter: (params) => new Date(params.row.startDate.startDate),
    renderCell: (params) => {
      const {
        onChange,
        startDate,
        projectEndDate,
        projectStartDate,
        getDateErrorMessage,
        getIsDateErrorExist,
      } = params.row.startDate;
      return (
        <DatePicker
          onChange={onChange}
          value={startDate}
          minDate={projectStartDate}
          maxDate={projectEndDate}
          errorMsg={getDateErrorMessage}
          error={getIsDateErrorExist}
          sx={{
            width: '18ch',
          }}
        />
      );
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    type: 'date',
    valueGetter: (params) => new Date(params.row.endDate.endDate),
    renderCell: (params) => {
      const {
        onChange,
        endDate,
        projectEndDate,
        projectStartDate,
        getDateErrorMessage,
        getIsDateErrorExist,
      } = params.row.endDate;
      return (
        <DatePicker
          onChange={onChange}
          value={endDate}
          minDate={projectStartDate}
          maxDate={projectEndDate}
          errorMsg={getDateErrorMessage}
          error={getIsDateErrorExist}
          sx={{
            width: '18ch',
          }}
        />
      );
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    type: 'number',
    headerAlign: 'left',
    valueGetter: (params) => params.row.allocation.allocatedPercentage,
    renderCell: (params) => {
      const { onChange, allocatedPercentage, isAllocatedPercentageLocked, helperText, error } =
        params.row.allocation;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            value={allocatedPercentage}
            onChange={onChange}
            helperText={helperText}
            error={error}
            disabled={isAllocatedPercentageLocked}
          />
        </Box>
      );
    },
  },
];
//
export default SELECTED_TEAM_MEMBER_HEADERS;
