import { createSlice } from '@reduxjs/toolkit';
import {
  OAUTH_WINDOW_HEIGHT,
  OAUTH_WINDOW_LEFT,
  OAUTH_WINDOW_TOP,
  OAUTH_WINDOW_WIDTH,
} from 'features/base/constants/oauth-window-styling';

export const initialState = {
  loading: false,
  errorCode: '',
};
/**
 * Redux slice for the sign in
 */
export const signInSlice = createSlice({
  name: 'feature/sign-in',
  initialState,
  reducers: {
    signIn(state) {
      return {
        ...state,
        loading: true,
      };
    },
    signInSucceeded(state, action) {
      return {
        ...state,
        loading: false,
        errorCode: action?.payload?.errorCode,
      };
    },
    signInFailed(state) {
      return {
        ...state,
        loading: false,
      };
    },
    // This is defined in the slice since there might arise a need to dispatch this action from mulitple components
    openOAuthWindow(state, action) {
      window.open(
        action.payload.authenticationUrl,
        'myWindow',
        `width=${OAUTH_WINDOW_WIDTH},height=${OAUTH_WINDOW_HEIGHT},top=${OAUTH_WINDOW_TOP},left=${OAUTH_WINDOW_LEFT}`
      );
    },
  },
});
//
export const { actions: signInActions } = signInSlice;
