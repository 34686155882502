import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/arsenal'] || initialState;
/**
 * Selector for obtaining users
 */
export const selectUsers = createSelector([selectDomain], (arsenalState) => arsenalState.users);
/**
 * Selector for getting the arsenal
 */
export const selectArsenal = createSelector([selectDomain], (arsenalState) => arsenalState.arsenal);
/**
 * Selector for obtaining addArsenalItemLoading state
 */
export const selectAddArsenalItemLoading = createSelector(
  [selectDomain],
  (arsenalState) => arsenalState.addArsenalItemLoading
);
/**
 * Selector for obtaining getArsenalLoading state
 */
export const selectGetArsenalLoading = createSelector(
  [selectDomain],
  (arsenalState) => arsenalState.getArsenalLoading
);
/**
 * Selector for obtaining editArsenalItemLoading state
 */
export const selectEditArsenalItemLoading = createSelector(
  [selectDomain],
  (arsenalState) => arsenalState.editArsenalItemLoading
);
/**
 * Selector for obtaining getUsersLoading state
 */
export const selectGetUsersLoading = createSelector(
  [selectDomain],
  (arsenalState) => arsenalState.getUsersLoading
);
