/**
 * Defines the API paths for allocations
 */
const API = {
  GET_ALLOCATIONS: {
    path: '/allocations?:query',
    method: 'GET',
  },
  PATCH_ALLOCATION: {
    path: '/allocations/:allocationId',
    method: 'PATCH',
  },
  GET_ALLOCATION: {
    path: '/allocations/:allocationId',
    method: 'GET',
  },
  GET_USERS: {
    path: '/users?:query',
    method: 'GET',
  },
  GET_PROJECTS: {
    path: '/projects?:query',
    method: 'GET',
  },
  GET_SKILL_STATS: {
    path: '/skill-groups?:query',
    method: 'GET',
  },
  GET_DESIGNATIONS: {
    path: '/designations?:query',
    method: 'GET',
  },
  GET_WORKAREAS: {
    path: '/work-areas?:query',
    method: 'GET',
  },
  GET_DEPARTMENTS: {
    path: '/departments/department-designations?:query',
    method: 'GET',
  },
};
//
export default API;
