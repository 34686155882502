/**
 * Defines the API paths for client email verification
 */
const API = {
  PATCH_USER: {
    path: '/users/:userId',
    method: 'PATCH',
  },
  GET_DEPARTMENT_DESIGNATIONS: {
    path: '/departments/department-designations',
    method: 'GET',
  },
};
//
export default API;
