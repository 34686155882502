import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//
import { selectSelectedTeamMembers } from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';

/**
 * Function that handles the update of work allocations
 */
const useOnUpdateClick = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  //
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  //
  const onUpdateClick = () => {
    const hasDuplicates = selectedUsers?.docs?.some(
      (sUser, index) =>
        selectedUsers?.docs?.filter(
          (obj, i) => obj?.userId?.id === sUser?.userId?.id && i !== index
        ).length > 0
    );
    if (!hasDuplicates) {
      dispatch(projectActions.createTeam({ id: projectId, selectedUsers: selectedUsers?.docs }));
    }
  };
  //
  return { onUpdateClick };
};
//
export default useOnUpdateClick;
