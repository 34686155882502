import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { selectAllOpex, selectLoader } from 'features/opex/selectors';
import { Button, DataGrid } from 'features/base/components';
import { opexActions } from 'features/opex/slice';
import {
  OPEXES_TABLE_COLUMNS_BASIC,
  OPEXES_TABLE_ACTION_COLUMNS,
} from 'features/base/utils/tables';
import { OPEX_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import AddNewOpexDrawer from '../add-opex-drawer';
import AddNewOpexPopup from '../add-opex';
import EditOpexPopup from '../edit-opex';

/**
 * Component that defines the entire opex view
 * @returns MUI Grid with the opex view
 */
const OpexView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const opexPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.OPEX,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const isLoading = useSelector(selectLoader);
  const opexes = useSelector(selectAllOpex);
  //
  const isFirst = useIsInitialize(opexes);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy:
      'createdAt:desc' /* As for now no sorting field is defined in the view, so added the sortBy key with a default value for future usage. */,
    rowsPerPage: OPEX_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [opexId, setOpexId] = useState('');
  //
  const handleAdd = () => {
    setAddModalOpen(true);
  };
  //
  const handleAddDrawer = () => {
    setAddDrawerOpen(true);
  };
  //
  const handleEdit = (id) => {
    setEditModalOpen(true);
    setOpexId(id);
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  useEffect(() => {
    dispatch(
      opexActions.getAllOpex({
        query: `limit=${OPEX_PAGINATION_LIMIT}&sortBy=createdAt:desc&page=${pageController.page}`,
      })
    );
  }, [pageController]);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <AddNewOpexPopup
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        previousStartDate={opexes?.docs?.length > 0 ? opexes.docs[0].startDate : null}
      />
      <AddNewOpexDrawer
        addDrawerOpen={addDrawerOpen}
        setAddDrawerOpen={setAddDrawerOpen}
        previousStartDate={opexes?.docs?.length > 0 ? opexes.docs[0].startDate : null}
      />
      <EditOpexPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        opexId={opexId}
        previousStartDate={opexes?.docs?.length > 1 ? opexes.docs[1].startDate : null}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 8 }} px={{ xs: 0, lg: 2 }}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Overhead
          </Typography>
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.OPEX,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            {/* This will open popup on desktop */}
            <Button onClick={() => handleAdd()} sx={{ display: { xs: 'none', sm: 'flex' } }}>
              Add Overhead <AddIcon />
            </Button>

            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
                backgroundColor: { xs: 'white', sm: 'unset' },
                position: { xs: 'fixed', sm: 'unset' },
                bottom: { xs: '0px', sm: 'unset' },
                zIndex: { xs: 1, sm: 'unset' },
                width: { xs: '100%', sm: 'unset' },
                py: { xs: '10px', sm: 'unset' },
                px: { xs: '20px', sm: 'unset' },
                left: { xs: '0px', sm: 'unset' },
              }}
            >
              {/* This will open drawer on mobile */}
              <Button
                onClick={() => handleAddDrawer()}
                sx={{
                  width: { lg: 'auto', xs: '100%' },
                }}
              >
                Add Overhead <AddIcon />
              </Button>
            </Box>
          </PermissionWrapper>
        </Grid>
        {isFirst ? (
          <ShimmerTable row={6} col={8} />
        ) : (
          <DataGrid
            columns={
              opexPermissionsVerified
                ? [...OPEXES_TABLE_COLUMNS_BASIC, ...OPEXES_TABLE_ACTION_COLUMNS]
                : OPEXES_TABLE_COLUMNS_BASIC
            }
            rows={
              opexes?.docs?.length &&
              opexes?.docs?.map((opex) => ({
                id: opex?.id,
                name: {
                  opexId: opex?.id,
                  opexName: opex?.name,
                },
                description: {
                  value: opex?.description,
                },
                startDate: {
                  value: new Date(opex?.startDate).toLocaleDateString(
                    DATE_LANGUAGE.LANGUAGE,
                    DATE_FORMAT_SLASH
                  ),
                },
                endDate: {
                  value: !opex?.endDate
                    ? null
                    : new Date(opex.endDate).toLocaleDateString(
                        DATE_LANGUAGE.LANGUAGE,
                        DATE_FORMAT_SLASH
                      ),
                },
                lastUpdated: {
                  value: new Date(opex.updatedAt).toLocaleDateString(
                    DATE_LANGUAGE.LANGUAGE,
                    DATE_FORMAT_SLASH
                  ),
                },
                amount: {
                  value: getDollarFormattedValue(opex?.amount) || '-',
                },
                dataStatus: {
                  value: capitalizeFirstLetter(opex.dataStatus),
                },
                action: {
                  opexPermissionsVerified,
                  isLoading,
                  handleEdit: () => handleEdit(opex?.id),
                },
              }))
            }
            page={pageController.page - 1}
            handleChangePage={handleChangePage}
            totalPages={opexes?.totalPages ?? 0}
            totalDocs={opexes?.totalDocs ?? 0}
            limit={opexes?.limit ?? pageController.rowsPerPage}
            loading={isLoading && isFirst !== true}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={opexes?.docs?.length ? 52 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default OpexView;
