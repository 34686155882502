import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';
import { SKILL_PAGINATION_LIMIT } from 'features/base/constants/pagination';

export const initialState = {
  addDrawerOpen: false,
  skills: {},
  skillGroups: {},
  // The below two arrays are used to store all records seperately to not affect paginated tables
  allSkills: [],
  allSkillGroups: [],
  addSkillLoading: false,
  getSkillsLoading: false,
  editSkillLoading: false,
  deleteSkillLoading: false,
  addSkillGroupLoading: false,
  getSkillGroupsLoading: false,
  editSkillGroupLoading: false,
  deleteSkillGroupsLoading: false,
  search: '',
  // The below falgs are used to implement on-demand pagination for deletion
  deleteSkillFlag: false,
  deleteSkillGroupFlag: false,
};
/**
 * Redux slice for the skills feature
 */
export const skillsSlice = createSlice({
  name: 'feature/skills',
  initialState,
  reducers: {
    addSkills: (state) => ({
      ...state,
      addSkillLoading: true,
    }),
    addSkillsSucceeded: (state, action) => ({
      ...state,
      addSkillLoading: false,
      skills: {
        ...state.skills,
        docs:
          [...(action.payload?.successFullyAddedSkills ?? []), ...(state.skills?.docs ?? [])].length <= SKILL_PAGINATION_LIMIT
            ? [...(action.payload?.successFullyAddedSkills ?? []), ...(state.skills?.docs ?? [])]
            : [...(action.payload?.successFullyAddedSkills ?? []), ...(state.skills?.docs ?? [])].slice(0, SKILL_PAGINATION_LIMIT),
        totalDocs: state.skills.totalDocs + (action.payload?.successFullyAddedSkills?.length ?? 0),
      },
    }),
    addSkillsFailed: (state) => ({
      ...state,
      addSkillLoading: false,
    }),
    getSkills: (state) => ({
      ...state,
      getSkillsLoading: true,
    }),
    getSkillsSucceeded: (state, action) => ({
      ...state,
      getSkillsLoading: false,
      skills: action?.payload,
    }),
    getSkillsFailed: (state) => ({
      ...state,
      getSkillsLoading: false,
      skills: {
        ...state.skills,
        docs: []
      }
    }),
    editSkill: (state) => ({
      ...state,
      editSkillLoading: true,
    }),
    editSkillSucceeded: (state, action) => ({
      ...state,
      editSkillLoading: false,
      skills: {
        ...state.skills,
        docs: state.skills?.docs?.map((skill) =>
          skill?.id === action?.payload?.id ? action?.payload : skill
        ),
      },
      skillGroups: {
        ...state.skillGroups,
        docs: state.skillGroups?.docs?.map((skillGroup) =>
          skillGroup?.skills.find((skill) => skill?.id === action?.payload?.id)
            ? {
                ...skillGroup,
                skills: skillGroup?.skills.map((skill) =>
                  skill?.id === action?.payload?.id ? action?.payload : skill
                ),
              }
            : skillGroup
        ),
      },
    }),
    editSkillFailed: (state) => ({
      ...state,
      editSkillLoading: false,
    }),
    deleteSkill: (state) => ({
      ...state,
      deleteSkillLoading: true,
    }),
    deleteSkillSucceeded: (state, action) => ({
      ...state,
      deleteSkillLoading: false,
      deleteSkillFlag: !state.deleteSkillFlag,
      skills: {
        ...state.skills,
        docs: state.skills?.docs?.filter((skill) => skill?.id !== action?.payload?.id),
        totalDocs: state.skills.totalDocs - 1,
      },
      skillGroups: {
        ...state.skillGroups,
        docs: state.skillGroups?.docs?.map((skillGroup) =>
          skillGroup?.skills.find((skill) => skill?.id === action?.payload?.id)
            ? {
                ...skillGroup,
                skills: skillGroup?.skills.filter((skill) => skill?.id !== action?.payload?.id),
              }
            : skillGroup
        ),
        totalDocs: state.skillGroups.totalDocs - 1,
      },
    }),
    deleteSkillFailed: (state) => ({
      ...state,
      deleteSkillLoading: false,
    }),
    getAllSkills: (state) => ({
      ...state,
      getSkillsLoading: true,
    }),
    getAllSkillsSucceeded: (state, action) => ({
      ...state,
      getSkillsLoading: false,
      allSkills: action?.payload?.docs,
    }),
    getAllSkillsFailed: (state) => ({
      ...state,
      getSkillsLoading: false,
    }),
    addSkillGroup: (state) => ({
      ...state,
      addSkillGroupLoading: true,
    }),
    addSkillGroupSucceeded: (state, action) => ({
      ...state,
      addSkillGroupLoading: false,
      skillGroups: {
        ...state.skillGroups,
        docs: [action.payload, ...(state.skillGroups?.docs ?? [])],
        totalDocs: state.skillGroups.totalDocs + 1,
      },
    }),
    addSkillGroupFailed: (state) => ({
      ...state,
      addSkillGroupLoading: false,
    }),
    getSkillGroups: (state) => ({
      ...state,
      getSkillGroupsLoading: true,
    }),
    getSkillGroupsSucceeded: (state, action) => ({
      ...state,
      getSkillGroupsLoading: false,
      skillGroups: action?.payload,
    }),
    getSkillGroupsFailed: (state) => ({
      ...state,
      getSkillGroupsLoading: false,
      skillGroups: {
        ...state.skillGroups,
        docs: []
      }
    }),
    editSkillGroup: (state) => ({
      ...state,
      editSkillGroupLoading: true,
    }),
    editSkillGroupSucceeded: (state, action) => ({
      ...state,
      editSkillGroupLoading: false,
      skillGroups: {
        ...state.skillGroups,
        docs: state.skillGroups?.docs?.map((skillGroup) =>
          skillGroup?.id === action?.payload?.id ? action?.payload : skillGroup
        ),
      },
    }),
    editSkillGroupFailed: (state) => ({
      ...state,
      editSkillGroupLoading: false,
    }),
    deleteSkillGroup: (state) => ({
      ...state,
      deleteSkillGroupsLoading: true,
    }),
    deleteSkillGroupSucceeded: (state, action) => ({
      ...state,
      deleteSkillGroupsLoading: false,
      deleteSkillGroupFlag: !state.deleteSkillGroupFlag,
      skillGroups: {
        ...state.skillGroups,
        docs: state.skillGroups?.docs?.filter(
          (skillGroup) => skillGroup?.id !== action?.payload?.id
        ),
        totalDocs: state.skillGroups.totalDocs - 1,
      },
    }),
    deleteSkillGroupFailed: (state) => ({
      ...state,
      deleteSkillGroupsLoading: false,
    }),
    getAllSkillGroups: (state) => ({
      ...state,
      getSkillGroupsLoading: true,
    }),
    getAllSkillGroupsSucceeded: (state, action) => ({
      ...state,
      getSkillGroupsLoading: false,
      allSkillGroups: action?.payload?.docs,
    }),
    getAllSkillGroupsFailed: (state) => ({
      ...state,
      getSkillGroupsLoading: false,
    }),
    handleToggleAddDrawer: (state) => ({
      ...state,
      addDrawerOpen: !state.addDrawerOpen,
    }),
    setSearch: (state, action) => ({
      ...state,
      search: action?.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: skillActions } = skillsSlice;
