import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
//
import ROUTES from 'features/base/constants/routes';
import { signInActions } from 'features/sign-in/slice';
import { generateCodeChallenge, generateCodeVerifier } from 'features/base/helpers/pkce';
import { authenticationUrl } from 'features/base/constants/authentication';
import {
  selectOnboarding,
  selectUserEmail,
} from 'features/base/auth/selectors';
import useIsAuthenticated from 'features/base/hooks/use-is-authenticated';
import { getRefreshToken } from 'features/base/helpers/token';
import { selectSignInErrorCode } from 'features/sign-in/selectors';
import EMAIL_SUFFIXES from 'features/base/constants/email-suffixes';
import { ERROR_CODES } from 'features/base/constants/errors';
import { CommonBackground } from 'features/base/components';

/**
 * Component that gets rendered on the callback URL
 * This function obtains the auth code or any error and sends that data back to the parent window,
 * and if this component was rendered as a parent (reset password flow), it re-initiates the auth flow and performs the sign-in
 */
const AuthWindow = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refreshToken = getRefreshToken();
  //
  const email = useSelector(selectUserEmail);
  const onboarding = useSelector(selectOnboarding);
  const errorCode = useSelector(selectSignInErrorCode);
  //
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const data = {
    code,
    error,
  };
  /**
   * This is used as a flag for re-initiating the authentication, in the reset password flow
   * Note: localStorage had to be used since the page reload cause by re-initiation causes 
   * the flag to be false for a short period, which leads to an infinite loop
   */
  const passwordResetTriggered = Boolean(localStorage.getItem('passwordResetTriggered'))
  /**
   * The codeVerifier here is only used for the reset password flow and not for the normal auth flow
   * Note: localStorage had to be used since the page reload cause by re-initiation causes 
   * the verifier to be reset
   */
  const resetPasswordCodeVerifier = localStorage.getItem('resetPasswordCodeVerifier')
  //
  useEffect(() => {
    // Function to re-initiate the auth process after the reset password flow
    const reInitiateAuth = async () => {
      const newCodeVerifier = generateCodeVerifier();
      localStorage.setItem('resetPasswordCodeVerifier', newCodeVerifier)
      const codeChallenge = await generateCodeChallenge(newCodeVerifier);
      const authUrlWithChallenge = `${authenticationUrl}&code_challenge=${codeChallenge}`;
      window.location.replace(authUrlWithChallenge);
    };
    //
    const handleCodeExtract = async () => {
      if (window?.opener) {
        // If this is a child-window (normal auth flow), simply send the code to the parent
        window.opener.postMessage(data);
        window.close();
      } else if (!passwordResetTriggered) {
        // If this is a normal window (reset password flow), trigger a one-time re-initialize by setting a flag
        localStorage.setItem('passwordResetTriggered', true)
        await reInitiateAuth();
      } else if (passwordResetTriggered) {
        // If this is a normal window (reset password flow) AND re-initialization of the auth process has already happenned once, trigger the login process
        if (code && resetPasswordCodeVerifier) {
          dispatch(signInActions.signIn({ code, codeVerifier: resetPasswordCodeVerifier }));
        }
        if (error) {
          navigate(ROUTES.NOT_AUTHORIZED);
        }
      }
    };
    handleCodeExtract();
  }, [error, code, resetPasswordCodeVerifier, passwordResetTriggered]);
  //
  useEffect(() => {
    // This is the same as the one in the welcome page component (for handling the re-direction after login)
    if (isAuthenticated(refreshToken)) {
      // Reset localStorage
      localStorage.removeItem('passwordResetTriggered');
      localStorage.removeItem('resetPasswordCodeVerifier');
      if (email?.endsWith(EMAIL_SUFFIXES.INTERNAL)) {
        if (onboarding === false) {
          navigate(ROUTES.INTERNAL_ONBOARD);
        } else if (onboarding === true) {
          navigate(ROUTES.LINK_HUB);
        }
      } else {
        navigate(ROUTES.SUPPORT_TICKETS);
      }
    } else if (errorCode?.toString() === ERROR_CODES.UNAUTHORIZED) {
      // Reset localStorage
      localStorage.removeItem('passwordResetTriggered');
      localStorage.removeItem('resetPasswordCodeVerifier');
      navigate(ROUTES.NOT_AUTHORIZED);
    }
  }, [isAuthenticated, onboarding, email, errorCode]);
  //
  return (
    // Renders a loader with the common background in the reset password flow
    !window?.opener && <CommonBackground>
      <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    </CommonBackground>
  );
};
//
export default AuthWindow;
