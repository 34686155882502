import moment from 'moment';
import * as yup from 'yup';
//
import { AMOUNT_RANGES } from 'features/base/constants/amount-types';
import { OPEX_STATUS } from 'features/base/constants/opex-status-types';

/**
 * Function for opex form validation
 */
export const opexFormValidation = (previousStartDate) => {
  const schema = yup.object().shape({
    name: yup.string().required('Please enter valid overhead name'),
    description: yup
      .string()
      .required('Please enter valid overhead description')
      .min(15, ({ min }) => `Description must be at least ${min} characters`)
      .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
    amount: yup
      .number()
      .min(AMOUNT_RANGES.MIN_AMOUNT, `Minimum amount is $${AMOUNT_RANGES.MIN_AMOUNT}`)
      .max(AMOUNT_RANGES.MAX_AMOUNT, `Maximum amount is $${AMOUNT_RANGES.MAX_AMOUNT}`)
      .required('Please enter an amount')
      .typeError('Amount must be a number'),
    startDate: yup
      .date()
      .required('Please select an overhead start date')
      .typeError('Should be in format DD-MM-YYYY'),
  });
  if (previousStartDate) {
    return schema.shape({
      startDate: schema.fields.startDate.test(
        'previousStartDateValidation',
        'System does not allow to add overhead for a date before the active overhead record. Please Update Active record to enter past overhead details',
        (value) => {
          const date = moment(value);
          const prevDate = moment(previousStartDate);
          return date.isAfter(prevDate, 'month') && date.isSameOrAfter(prevDate, 'year');
        }
      ),
    });
  }
  return schema;
};
/**
 * Function for edit opex form validation
 */
export const editOpexFormValidation = (previousStartDate, status) => {
  const schema = yup.object().shape({
    name: yup.string().required('Please enter valid overhead name'),
    description: yup
      .string()
      .required('Please enter valid overhead description')
      .min(15, ({ min }) => `Description must be at least ${min} characters`)
      .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
    amount: yup
      .number()
      .min(AMOUNT_RANGES.MIN_AMOUNT, `Minimum amount is $${AMOUNT_RANGES.MIN_AMOUNT}`)
      .max(AMOUNT_RANGES.MAX_AMOUNT, `Maximum amount is $${AMOUNT_RANGES.MAX_AMOUNT}`)
      .required('Please enter an amount')
      .typeError('Amount must be a number'),
    startDate: yup
      .date()
      .required('Please select an overhead start date')
      .nullable()
      .typeError('Should be in format DD-MM-YYYY'),
    endDate: yup
      .date()
      .optional('Please select an overhead end date')
      .nullable()
      .typeError('Should be in format DD-MM-YYYY'),
  });
  if (previousStartDate && status === OPEX_STATUS.ACTIVE) {
    return schema.shape({
      startDate: schema.fields.startDate.test(
        'previousStartDateValidation',
        'System does not allow to add overhead for a date before the active overhead record. Please Update Active record to enter past overhead details',
        (value) => {
          const date = moment(value);
          const prevDate = moment(previousStartDate);
          return date.isAfter(prevDate, 'month') && date.isSameOrAfter(prevDate, 'year');
        }
      ),
    });
  }
  return schema;
};
