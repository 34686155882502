/**
 * Defines the API paths for designation
 */
const API = {
  GET_DESIGNATIONS: {
    path: '/designations?:query',
    method: 'GET',
  },
  ADD_DESIGNATIONS: {
    path: '/designations',
    method: 'POST',
  },
  EDIT_DESIGNATION: {
    path: '/designations/:id',
    method: 'PATCH',
  },
  GET_ONE_DESIGNATION: {
    path: '/designation/:id',
    method: 'GET',
  },
};
//
export default API;
