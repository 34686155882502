import { Alert } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import TextField from 'features/base/components/text-field';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { skillActions } from 'features/skills/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectEditSkillLoader, selectSkillById } from 'features/skills/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { removeUnchanged } from 'features/base/helpers/object';
import { editSkillValidation } from 'features/skills/validation/skills-validation';
/**
 * Function that defines the form for editing skills
 * @returns {Form}
 * */
const EditSkillsForm = ({ openSetter, skillIdToEdit }) => {
  const dispatch = useDispatch();
  //
  const skillToEdit = useSelector((state) => selectSkillById(state, skillIdToEdit));
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectEditSkillLoader);
  //
  const handleOnClose = () => {
    openSetter(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const initialValues = {
    name: skillToEdit?.name,
  };
  //
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editSkillValidation}
      onSubmit={(values) => {
        let skillData = { ...values };
        // Only send the attributes that have been changed
        skillData = removeUnchanged(initialValues, skillData);
        //
        dispatch(skillActions.editSkill({ ...skillData, id: skillIdToEdit }));
      }}
    >
      {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} className="add-skills-form">
          {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
            <Alert sx={{ mb: 3 }} severity={notification?.type}>
              {notification?.message}
            </Alert>
          )}
          <TextField
            type="text"
            name="name"
            value={values.name}
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            onBlur={handleBlur}
            onChange={handleChange}
            label="Skill Name"
          />
          <ButtonGrid
            leftButtonText="Cancel"
            rightButtonText={loading ? 'Submitting' : 'Submit'}
            leftOnClick={handleOnClose}
            rightOnClick={null}
            rightIcon={loading ? loaderIcon : null}
            submitDisabled={loading}
          />
        </form>
      )}
    </Formik>
  );
};
//
export default EditSkillsForm;
