import { Box, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import PhoneInputField from 'react-phone-input-2';
//
import './index.scss';
/**
 * Defines a common phone input component
 * @param {string} label - The label for the phone input field.
 * @param {string} name - The name attribute for the phone input field.
 * @param {string} id - The ID attribute for the phone input field.
 * @param {boolean} hide - Determines whether the phone input should be hidden or not.
 * @param {string} error - The error message for the phone input field.
 * @param {string} errorMsg - The error message to display when there's an error.
 * @returns {JSX.Element} PhoneInput component
 */
const PhoneInput = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  if (props.hide) return null;
  //
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 2 }}>
      <Typography fontSize="16px" className="field-label">
        {props.label}
      </Typography>
      <PhoneInputField
        inputProps={{
          name: props.name,
          id: props.id,
          required: true,
          autoFocus: false,
        }}
        {...field}
        {...props}
        enableSearch
        value={field.value}
        onChange={(_, country, e, formattedValue) => {
          setFieldValue(field.name, formattedValue);
        }}
      />
      <p className="error-feedback">{props.error && props.errorMsg}</p>
    </Box>
  );
};
//
export default PhoneInput;
