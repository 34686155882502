import * as yup from 'yup';

/**
 * Function for add benefit employee form validation
 */
const benefitEmployeeValidation = yup.object().shape({
  departments: yup.array().min(1, 'Please select at least one department'),
  levels: yup.array().min(1, 'Please select at least one level'),
  //
  excludedUsers: yup.array(),
  additionalUsers: yup.array(),
});
//
export default benefitEmployeeValidation;
