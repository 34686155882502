import { Avatar, Box, Grid, Typography } from '@mui/material';
/**
 * Defines the table header details for my users table in my profile section
 */
const MY_USERS_TABLE_HEADERS = [
  {
    field: 'profile',
    headerName: 'Profile',
    flex: 0.6,
    valueGetter: (params) => params.row.profile.name,
    renderCell: (params) => {
      const { logo, name } = params.row.profile;
      return (
        <Grid container alignItems="center">
          <Grid item sm={3}>
            {logo ? (
              <img
                src={logo}
                alt="logo"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  height: '40px',
                  width: '40px',
                }}
              >
                {name?.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Typography>{name}</Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 0.5,
    valueGetter: (params) => params.row.email.value,
    renderCell: (params) => {
      const { value } = params.row.email;
      return <Typography variant="body1">{value}</Typography>;
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 0.5,
    valueGetter: (params) => params.row.phone.value,
    renderCell: (params) => {
      const { value } = params.row.phone;
      return <Typography variant="body1">{value}</Typography>;
    },
  },
  {
    field: 'onboardingStatus',
    headerName: 'Onboarding status',
    flex: 0.5,
    valueGetter: (params) => params.row.onboardingStatus.value,
    renderCell: (params) => {
      const { value, bgColour, textColour } = params.row.onboardingStatus;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: bgColour,
              p: '7px 16px',
              mr: '16px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: textColour,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];

export default MY_USERS_TABLE_HEADERS;
