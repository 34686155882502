import moment from 'moment';
import { Typography, Grid, Paper, Box, Link, Select, MenuItem } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import lowIcon from 'features/base/assets/images/svg/low.svg';
import mediumIcon from 'features/base/assets/images/svg/medium.svg';
import highIcon from 'features/base/assets/images/svg/high.svg';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import COLORS from 'features/base/constants/colors';
import { ENVIRONMENT } from 'config';
import { removeUUIDv4Prefix } from 'features/base/helpers/url-parser';

/**
 * Component that defines the grid item
 * @returns MUI Grid with the grid item
 * @param {*} children
 * @param {*} sx
 * @returns MUI Grid
 */
const GridItem = ({ children, sx }) => (
  <Grid item xs={6}>
    <Paper
      sx={{
        ...sx,
        backgroundColor: COLORS.SKY_BLUE,
        m: 0.5,
        display: 'flex',
      }}
      elevation={0}
    >
      {children}
    </Paper>
  </Grid>
);
const SupportTicketDetailView = ({
  supportTicket,
  handleOnSeverityChange,
  handleOnResolveClick,
  supportTicketLoading,
}) =>
  supportTicketLoading ? (
    <ShimmerTable row={5} col={2} />
  ) : (
    <Grid container rowSpacing={0} mt={3}>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Project name</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}> {supportTicket?.projectId?.name}</Typography>
      </GridItem>

      <GridItem
        sx={{
          height:
            supportTicket?.description?.length > 95
              ? `${Math.ceil((supportTicket?.description?.length ?? 95) / 95) * 2}rem`
              : '3rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography sx={{ paddingLeft: 1 }}>Description</Typography>
      </GridItem>
      <GridItem
        sx={{
          height:
            supportTicket?.description?.length > 95
              ? `${Math.ceil((supportTicket?.description?.length ?? 95) / 95) * 2}rem`
              : '3rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography sx={{ paddingLeft: 1 }}> {supportTicket?.description}</Typography>
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Severity</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Select
          value={supportTicket?.severity}
          onChange={handleOnSeverityChange}
          className="select-box"
        >
          <MenuItem value={SEVERITY_TYPES.LOW}>
            <Box>
              <img src={lowIcon} className="severity-icon" alt="Low Icon" />
              Low
            </Box>
          </MenuItem>
          <MenuItem value={SEVERITY_TYPES.MEDIUM}>
            <Box>
              <img src={mediumIcon} className="severity-icon" alt="Medium Icon" />
              Medium
            </Box>
          </MenuItem>
          <MenuItem value={SEVERITY_TYPES.HIGH}>
            <Box>
              <img src={highIcon} className="severity-icon" alt="High Icon" />
              High
            </Box>
          </MenuItem>
        </Select>
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Type</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}> {supportTicket?.type ?? 'N/A'}</Typography>
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Created date</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>
          {new Date(supportTicket?.createdAt).toLocaleDateString(
            DATE_LANGUAGE.LANGUAGE,
            DATE_FORMAT_SLASH
          )}{' '}
        </Typography>
        <Typography sx={{ paddingLeft: '3rem', opacity: 0.6 }} color={COLORS.GREY}>
          {moment(supportTicket?.createdAt).fromNow()}
        </Typography>
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Last updated</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>
          {new Date(supportTicket?.updatedAt).toLocaleDateString(
            DATE_LANGUAGE.LANGUAGE,
            DATE_FORMAT_SLASH
          )}{' '}
        </Typography>
        <Typography sx={{ paddingLeft: '3rem', opacity: 0.6 }} color={COLORS.GREY}>
          {moment(supportTicket?.updatedAt).fromNow()}
        </Typography>
      </GridItem>

      <GridItem
        sx={{
          height:
            supportTicket?.attachments?.length > 0
              ? `${2 * (supportTicket?.attachments?.length ?? 1)}rem`
              : '3rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography sx={{ paddingLeft: 1 }}>Attachments</Typography>
      </GridItem>
      <GridItem
        sx={{
          flexDirection: 'column',
          height:
            supportTicket?.attachments?.length > 0
              ? `${2 * (supportTicket?.attachments?.length ?? 1)}rem`
              : '3rem',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {/*  show blue colour chip hyper links */}
        {supportTicket?.attachments?.map((attachment) => (
          <Link
            href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${attachment?.url}`}
            underline="hover"
            sx={{
              fontSize: '0.875rem',
              ml: 1,
              mb: 1,
              whiteSpace: 'nowrap',
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '95%',
            }}
            target="_blank"
            rel="noreferrer"
          >
            {removeUUIDv4Prefix(attachment?.name)}
          </Link>
        ))}
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>JIRA Issue ID</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>
          {supportTicket?.jiraIssueId ? (
            <Link
              href={`https://${ENVIRONMENT.JIRA_ORG_NAME}.atlassian.net/browse/${supportTicket?.jiraIssueId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {supportTicket.jiraIssueId}
            </Link>
          ) : (
            'N/A'
          )}
        </Typography>
      </GridItem>

      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography sx={{ paddingLeft: 1 }}>Status</Typography>
      </GridItem>
      <GridItem sx={{ height: '3rem', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' }, paddingLeft: '1' }}>
          <Box
            sx={{
              bgcolor: supportTicket?.acknowledged?.isAcknowledged
                ? COLORS.PALE_GREEN
                : COLORS.LIGHT_PINK,
              p: '7px 16px',
              mr: '16px',
              cursor: `${supportTicket?.acknowledged?.isAcknowledged ? 'default' : 'pointer'}`,
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={handleOnResolveClick}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: supportTicket?.acknowledged?.isAcknowledged
                  ? COLORS.SUCCESS_MAIN
                  : COLORS.SECONDARY_MAIN,
              }}
            >
              {supportTicket?.acknowledged?.isAcknowledged ? 'Resolved' : 'Open'}
            </Typography>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );

export default SupportTicketDetailView;
