import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Drawer } from 'features/base/components';
import AddWorkAreaForm from '../add-workarea-form';

/**
 * Drawer component to render Add Work Area
 * @prop {boolean} addWorkAreaDrawerOpen - boolean to show/hide the drawer
 * @prop {function} setAddWorkAreaDrawerOpen - function to set the drawer state
 * @returns {Drawer}
 */
const AddNewWorkAreaDrawer = ({ addWorkAreaDrawerOpen, setAddWorkAreaDrawerOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setAddWorkAreaDrawerOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (
      addWorkAreaDrawerOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Drawer open={addWorkAreaDrawerOpen} onClose={handleOnClose} title="Add work area">
        <AddWorkAreaForm />
      </Drawer>
    </PermissionWrapper>
  );
};
//
export default AddNewWorkAreaDrawer;
