import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectLoader,
  selectAllDesignation,
  selectSearch,
} from 'features/designations/selectors';
import { designationActions } from 'features/designations/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { DataGrid } from 'features/base/components';
import EditDesignationPopup from 'features/designations/components/edit-designation';
import { DESIGNATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import {
  DESIGNATION_TABLE_ACTION_COLUMNS,
  DESIGNATION_TABLE_BASIC_COLUMNS,
} from 'features/base/utils/tables';
import AddDesignationForm from '../add-designation-form';

/**
 * Table component to render Designations
 * @returns {Box}
 */
const Designation = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const organizationUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ORGANIZATION,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const designations = useSelector(selectAllDesignation);
  const isLoading = useSelector(selectLoader);
  const notification = useSelector(selectNotification);
  const search = useSelector(selectSearch);
  //
  const isFirst = useIsInitialize(designations);
  //
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [designationId, setDesignationId] = useState('');
  //
  useEffect(() => {
    dispatch(
      designationActions.getAllDesignation({
        query: `limit=${DESIGNATION_PAGINATION_LIMIT}&sortBy=name:asc&page=1&pagination=true&search=${search}`,
      })
    );
  }, [search]);
  //
  const handleEdit = (id) => {
    setDesignationId(id);
    setEditModalOpen(true);
    dispatch(notificationActions.resetNotification());
  };
  //
  const handleOnClose = () => {
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <>
      <EditDesignationPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        designationId={designationId}
      />
      <Grid
        container
        spacing={6}
        rowSpacing={3}
        display="flex"
        justifyContent="space-around"
        flexDirection="row"
      >
        <Grid item xs={12} md={6} lg mb={2}>
          <Box>
            {isFirst ? (
              <ShimmerTable row={5} col={2} />
            ) : (
              <DataGrid
                columns={
                  organizationUpdatePermissionsVerified
                    ? [...DESIGNATION_TABLE_BASIC_COLUMNS, ...DESIGNATION_TABLE_ACTION_COLUMNS]
                    : DESIGNATION_TABLE_BASIC_COLUMNS
                }
                rows={
                  designations?.docs?.length &&
                  designations?.docs?.map((designation) => ({
                    ...designation,
                    name: designation.name,
                    action: {
                      organizationUpdatePermissionsVerified,
                      handleEdit: (event) => {
                        event.stopPropagation();
                        handleEdit(designation.id);
                      },
                    },
                  }))
                }
                handleChangePage={({ page }) => {
                  dispatch(
                    designationActions.getAllDesignation({
                      query: `limit=${DESIGNATION_PAGINATION_LIMIT}&sortBy=name:asc&page=${
                        page + 1
                      }&pagination=true&search=${search}`,
                    })
                  );
                }}
                page={designations.page - 1 ?? 0}
                totalDocs={designations?.totalDocs ?? 0}
                limit={designations?.limit ?? 10}
                loading={isLoading && isFirst !== true}
                pageSizeOptions={[10]}
                headSx={{ pt: 1, pb: 1, pr: 0 }}
                cellSx={{ pt: 1, pb: 1, pr: 0 }}
                rowHeight={designations?.docs?.length ? 52 : 200}
              />
            )}
          </Box>
        </Grid>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <Grid
            item
            xs={12}
            md={6}
            lg
            mb={2}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <AddDesignationForm />
          </Grid>
        </PermissionWrapper>
      </Grid>
    </>
  );
};
//
export default Designation;
