import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Drawer } from 'features/base/components';
import AddArsenalItemForm from '../add-arsenal-item-form';
/**
 * Function that defines the drawer for adding new arsenal items
 * @prop {boolean} open - boolean to show/hide the drawer
 * @prop {function} setOpen - function to set the drawer state
 * @returns {Popup}
 */
const AddArsenalItemDrawer = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (open && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ARSENAL,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Drawer open={open} onClose={handleOnClose} title="Add item">
        <AddArsenalItemForm handleOnClose={handleOnClose} />
      </Drawer>
    </PermissionWrapper>
  );
};
//
export default AddArsenalItemDrawer;
