import { inverseObject } from '../helpers/object';

/**
 * Defines the constant project domains - used for permissions
 */
export const PERMISSION_DOMAINS = {
  USER: 'user',
  USER_ORGANIZATION: 'user-organization',
  USER_PERMISSION: 'user-permission',
  ROLE: 'role',
  SALARY: 'salary',
  PROJECT: 'project',
  PROJECT_EXTERNAL_USERS: 'project-external-users',
  PROJECT_ALLOCATION: 'project-allocation',
  PROJECT_REVENUE_REPORT: 'revenue-report',
  OPEX: 'opex',
  REPOSITORY: 'repository',
  DEPLOYMENT: 'deployment',
  TICKET_HANDLING: 'ticket-handle',
  ORGANIZATION: 'organization',
  TOOL: 'tool',
  SKILL: 'skill',
  CONFIG: 'config',
  CLIENT: 'client',
  LINK: 'link',
  ARSENAL: 'arsenal',
  BENEFIT: 'benefit',
};
/**
 * Defines the constant permission actions - used for permissions
 */
export const PERMISSION_ACTIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
};
/**
 * Defines an array of all permission actions (in order)
 */
export const PERMISSION_ACTIONS_LIST = [
  PERMISSION_ACTIONS.READ,
  PERMISSION_ACTIONS.CREATE,
  PERMISSION_ACTIONS.UPDATE,
  PERMISSION_ACTIONS.DELETE,
];
/**
 * Defines the constant permission action indexes
 */
export const PERMISSION_MATRIX_ACTION_INDEXES = {
  ALL: 0,
  READ: 1,
  CREATE: 2,
  UPDATE: 3,
  DELETE: 4,
};
/**
 * Defines the constant permission action indexes (created dynamically using the permission domains constant above)
 */
export const PERMISSION_MATRIX_DOMAIN_INDEXES = Object.keys(PERMISSION_DOMAINS).reduce(
  (acc, key, index) => {
    acc[key] = index;
    return acc;
  },
  {}
);
/**
 * Gives the description of each permission domain
 */
export const PERMISSION_DOMAIN_DESCRIPTIONS = {
  USER: "Permissions for creating new users, updating existing users' information and viewing existing users in the system",
  USER_ORGANIZATION:
    "Permissions for updating and viewing users' departments and designations in the system",
  USER_PERMISSION: "Permissions for viewing and changing existing user's permissions in the system",
  ROLE: 'Permissions for creating new roles, updating existing roles and viewing existing roles in the system',
  SALARY:
    'Permissions for adding new salaries for a user or multiple users, updating existing salaries of users and viewing current and past salaries of users in the system',
  PROJECT:
    'Permissions for creating new projects, updating existing projects and viewing existing projects in the system',
  PROJECT_EXTERNAL_USERS:
    'Permissions for adding new external users to a project, updating & removing existing external users of a project and viewing existing external users of a project in the system',
  PROJECT_ALLOCATION: 'Permissions for creating, updating and viewing allocations in a project',
  PROJECT_REVENUE_REPORT:
    'Permissions for viewing and updating revenue reports of projects in the system',
  OPEX: 'Permissions for creating new operational expense records, updating past operational expense records and viewing past operational expense records in the system',
  REPOSITORY:
    'Permissions for creating new repositories, updating existing repositories and viewing existing repositories in the system',
  DEPLOYMENT:
    'Permissions for creating new deployments, updating existing deployments and viewing existing deployments in the system',
  TICKET_HANDLING:
    'Permissions for handling support tickets which have been raised in projects which the user is a project notifier in the system',
  ORGANIZATION:
    'Permissions for creating new departments, designations and workareas, updating existing ones and viewing existing departments, designations and workareas in the system',
  TOOL: 'Permissions for creating new tools, updating existing tools and viewing and removing existing tools in the system',
  SKILL:
    'Permissions for creating new skills or skill groups, updating existing skills or skill groups and viewing and removing existing skills or skill groups in the system',
  CONFIG: 'Permissions for updating and viewing configuration tokens in the system',
  CLIENT:
    'Permissions for creating new clients, updating existing clients and viewing existing clients in the system',
  LINK: 'Permissions for creating new links, updating existing links and viewing existing links in the system',
  ARSENAL:
    'Permissions for creating new arsenal items, updating existing arsenal items and viewing existing arsenal items in the system',
  BENEFIT:
    'Permissions for creating new benefits, updating, removing and viewing existing benefits in the system',
};
/**
 * Inverted version of the PERMISSION_DOMAINS object above
 */
export const PERMISSION_DOMAINS_INVERSED = inverseObject(PERMISSION_DOMAINS);
/**
 * Inverted version of the PERMISSION_ACTIONS object above
 */
export const PERMISSION_ACTIONS_INVERSED = inverseObject(PERMISSION_ACTIONS);
/**
 * Dependency matrix
 */
export const DEPENDENCY_MATRIX = {
  [PERMISSION_DOMAINS.USER]: {
    [PERMISSION_ACTIONS.CREATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.CLIENT,
        ACTION: PERMISSION_ACTIONS.CREATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.READ]: [
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
        ACTION: PERMISSION_ACTIONS.READ,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.UPDATE]: [],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.USER_ORGANIZATION]: {
    [PERMISSION_ACTIONS.CREATE]: [],
    [PERMISSION_ACTIONS.READ]: [],
    [PERMISSION_ACTIONS.UPDATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.ORGANIZATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.ORGANIZATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.ROLE]: {
    [PERMISSION_ACTIONS.CREATE]: [],
    [PERMISSION_ACTIONS.READ]: [],
    [PERMISSION_ACTIONS.UPDATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.USER_PERMISSION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.USER_PERMISSION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.PROJECT]: {
    [PERMISSION_ACTIONS.CREATE]: [],
    [PERMISSION_ACTIONS.READ]: [
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
        ACTION: PERMISSION_ACTIONS.READ,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.UPDATE]: [],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.PROJECT_ALLOCATION]: {
    [PERMISSION_ACTIONS.CREATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.READ]: [],
    [PERMISSION_ACTIONS.UPDATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
        ACTION: PERMISSION_ACTIONS.CREATE,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
        ACTION: PERMISSION_ACTIONS.CREATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT]: {
    [PERMISSION_ACTIONS.CREATE]: [],
    [PERMISSION_ACTIONS.READ]: [
      {
        DOMAIN: PERMISSION_DOMAINS.USER,
        ACTION: PERMISSION_ACTIONS.READ,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.PROJECT,
        ACTION: PERMISSION_ACTIONS.READ,
        SELECTED: true,
        RESULT: true,
      },
    ],
    [PERMISSION_ACTIONS.UPDATE]: [],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.ORGANIZATION]: {
    [PERMISSION_ACTIONS.CREATE]: [],
    [PERMISSION_ACTIONS.READ]: [],
    [PERMISSION_ACTIONS.UPDATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.USER_ORGANIZATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: true,
        RESULT: true,
      },
      {
        DOMAIN: PERMISSION_DOMAINS.USER_ORGANIZATION,
        ACTION: PERMISSION_ACTIONS.UPDATE,
        SELECTED: false,
        RESULT: false,
      },
    ],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
  [PERMISSION_DOMAINS.CLIENT]: {
    [PERMISSION_ACTIONS.CREATE]: [
      {
        DOMAIN: PERMISSION_DOMAINS.USER,
        ACTION: PERMISSION_ACTIONS.CREATE,
        SELECTED: true,
        RESULT: true,
      },
    ],
    [PERMISSION_ACTIONS.READ]: [],
    [PERMISSION_ACTIONS.UPDATE]: [],
    [PERMISSION_ACTIONS.DELETE]: [],
  },
};
