import * as yup from 'yup';
//
import { nameRegex } from 'features/base/constants/regex';
import { isValidNumber } from 'features/base/helpers/phone-numbers';
/**
 * Function for add client form validation
 */
const addClientFormValidation = yup.object().shape({
  company: yup
    .string()
    .required('Company is required')
    .max(50, ({ max }) => `Company name must be at maximum of ${max} characters`),
  brNumber: yup
    .string()
    .max(15, ({ max }) => `Business registration number must be at maximum of ${max} characters`),
  address: yup
    .string()
    .required('Address is required')
    .min(10, ({ min }) => `Address must be at least ${min} characters`)
    .max(100, ({ max }) => `Address must be at maximum of ${max} characters`),
  companyPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
  projectIds: yup.array().min(1, 'Please select at least one project'),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed')
    .max(50, ({ max }) => `First name must be at maximum of ${max} characters`),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed')
    .max(50, ({ max }) => `Last name must be at maximum of ${max} characters`),
  email: yup.string().required('Email is required').email('Please enter a valid email address'),
  primaryUserPhoneNumber: yup
    .string()
    .required('Phone number is required')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
});
//
export default addClientFormValidation;
