import { useDispatch, useSelector } from 'react-redux';
//
import {
  selectCreateTeamAvailableUsers,
  selectSelectedTeamMembers,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';

/**
 * Function that handles the add users on click
 */
const useOnAddUsersClick = () => {
  const dispatch = useDispatch();
  //
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  const availableUsers = useSelector(selectCreateTeamAvailableUsers);
  //
  const onAddUsersClick = (multipleAvailableUsers, onAvailableUserBulkIconClick) => {
    const availableUsersCopy = availableUsers?.docs?.map((aUser) => {
      const found = multipleAvailableUsers.find((mUser) => mUser?.userId?.id === aUser?.id);
      if (!found) {
        return aUser;
      }
      return {
        ...aUser,
        disabled: true,
      };
    });
    const multipleAvailableUsersCopy = multipleAvailableUsers.filter(
      (mUser) => !selectedUsers?.docs?.some((sUser) => sUser?.userId?.id === mUser?.userId?.id)
    );
    const availableUsersToBeAdded = [...selectedUsers.docs, ...multipleAvailableUsersCopy];
    dispatch(projectActions.setCreateTeamAvailableUsers(availableUsersCopy));
    dispatch(projectActions.setCreateTeamSelectedUsers(availableUsersToBeAdded));
    onAvailableUserBulkIconClick();
  };
  //
  return { onAddUsersClick };
};
//
export default useOnAddUsersClick;
