import { isValidPhoneNumber } from 'libphonenumber-js';
/**
 * A formatter that formats phone numbers for presentation
 * @param {String} number - A phone number as initially formatted by the react-phone-input-2 library
 * @returns {String} Formatted number
 */
export const phoneNumberFormatter = (number) => {
  if (!number) return '';
  const countryCode = number?.split(' ')[0];
  const rest = number?.slice(countryCode.length);
  return `(${countryCode})${rest}`;
};
//
export const isValidNumber = (number, countryCode) => isValidPhoneNumber(number, countryCode);
