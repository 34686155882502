/**
 * Defines the API paths for support tickets
 */
const API = {
  GET_SUPPORT_TICKETS: {
    path: '/support-tickets?:query',
    method: 'GET',
  },
  GET_SUPPORT_TICKET_BY_ID: {
    path: '/support-tickets/:id',
    method: 'GET',
  },
  POST_SUPPORT_TICKETS: {
    path: '/support-tickets',
    method: 'POST',
  },
  POST_ATTACHMENTS: {
    path: '/support-tickets/upload',
    method: 'POST',
  },
  GET_COMMENTS: {
    path: '/support-tickets/:id/comments',
    method: 'GET',
  },
  POST_COMMENTS: {
    path: '/support-tickets/:id/comments',
    method: 'POST',
  },
  POST_COMMENTS_ATTACHMENT: {
    path: '/support-tickets/:id/comments/upload',
    method: 'POST',
  },
  PATCH_SUPPORT_TICKET: {
    path: '/support-tickets/:id',
    method: 'PATCH',
  },
  GET_CLIENTS: {
    path: '/clients?:query',
    method: 'GET',
  },
  GET_PROJECTS: {
    path: '/projects?:query',
    method: 'GET',
  },
};
//
export default API;
