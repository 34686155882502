/**
 * Allocation financial year start month
 * @type {{SALARY: string, ALLOWANCE: string }}
 */
export const FINANCIAL_YEAR_START_MONTH = 4;
/**
 * Allocation financial year end month
 * @type {{SALARY: string, ALLOWANCE: string }}
 */
export const FINANCIAL_YEAR_END_MONTH = 3;
