import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  allTools: {},
  uploadedLogo: [],
  allDepartmentList: [],
  toolToEdit: '',
  toolToDelete: '',
  uploadFileLoading: false,
  addToolLoading: false,
  editToolLoading: false,
  deleteToolLoading: false,
};
/**
 * Redux slice for the tool feature
 */
export const toolSlice = createSlice({
  name: 'feature/tools',
  initialState,
  reducers: {
    getAllTools: (state) => ({
      ...state,
      loading: true,
    }),
    getAllToolsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allTools: action?.payload,
    }),
    getAllToolsFailed: (state) => ({
      ...state,
      loading: false,
      allTools: {
        ...state.allTools,
        docs: [],
      },
    }),
    getAllDepartment: (state) => ({
      ...state,
      loading: true,
    }),
    getAllDepartmentSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allDepartmentList: action?.payload,
    }),
    getAllDepartmentFailed: (state) => ({
      ...state,
      loading: false,
    }),
    addTool: (state) => ({
      ...state,
      addToolLoading: true,
    }),
    addToolSucceeded: (state, action) => ({
      ...state,
      addToolLoading: false,
      allTools: {
        ...state.allTools,
        docs: [...state.allTools.docs, action?.payload],
        totalDocs: state.allTools.totalDocs + 1,
      },
    }),
    addToolFailed: (state) => ({
      ...state,
      addToolLoading: false,
    }),
    clearEditTool: (state) => ({
      ...state,
      toolToEdit: '',
    }),
    editTool: (state) => ({
      ...state,
      editToolLoading: true,
    }),
    editToolSucceeded: (state, action) => ({
      ...state,
      editToolLoading: false,
      allTools: {
        ...state.allTools,
        docs: state.allTools.docs.map((tool) => {
          if (tool.id === action?.payload?.id) {
            return action?.payload;
          }
          return tool;
        }),
      },
    }),
    editToolFailed: (state) => ({
      ...state,
      editToolLoading: false,
    }),
    deleteTool: (state) => ({
      ...state,
      deleteToolLoading: true,
    }),
    deleteToolSucceeded: (state, action) => ({
      ...state,
      deleteToolLoading: false,
      allTools: {
        ...state.allTools,
        docs: state.allTools.docs.filter((tool) => tool.id !== action?.payload),
        totalDocs: state.allTools.totalDocs - 1,
      },
    }),
    deleteToolFailed: (state) => ({
      ...state,
      deleteToolLoading: false,
    }),
    clearDeleteTool: (state) => ({
      ...state,
      toolToDelete: '',
    }),
    uploadLogo: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadLogoSucceeded: (state, action) => ({
      ...state,
      uploadFileLoading: false,
      uploadedLogo: action?.payload,
    }),
    uploadLogoFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    clearLogo: (state) => ({
      ...state,
      uploadedLogo: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: toolActions } = toolSlice;
