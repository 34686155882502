import * as yup from 'yup';
//
import { isValidNumber } from 'features/base/helpers/phone-numbers';

/**
 * internal Onboard form validation
 */
const internalOnboardValidation = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
});
//
export default internalOnboardValidation;
