import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
//
import TOAST_TYPES from 'features/base/constants/toast-types';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import AddDepartmentForm from '../add-department-form';

/**
 * Function that defines the popup form for adding a new department
 * @prop {boolean} addDepartmentModalOpen - boolean to show/hide the popup
 * @prop {function} setAddDepartmentModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddNewDepartmentPopup = ({ addDepartmentModalOpen, setAddDepartmentModalOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setAddDepartmentModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addDepartmentModalOpen}
        onClose={handleOnClose}
        title="Add Department and Designation"
        mediumSize="860px"
      >
        <AddDepartmentForm setAddDepartmentModalOpen={setAddDepartmentModalOpen} />
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddNewDepartmentPopup;
