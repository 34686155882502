import { useDispatch, useSelector } from 'react-redux';
//
import {
  selectCreateTeamAvailableUsers,
  selectCreateTeamProject,
  selectSelectedTeamMembers,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { getDisconnectionSet } from 'features/base/helpers/array';
import createFormattedString from 'features/base/helpers/param-formatter';
import { getIdList } from 'features/base/helpers/object';
import moment from 'moment';

/**
 * Function that handles remove click
 */
const useOnRemoveMultipleUsersClick = () => {
  const dispatch = useDispatch();
  //
  const selectedUserAllocations = useSelector(selectSelectedTeamMembers);
  const availableUsers = useSelector(selectCreateTeamAvailableUsers);
  const project = useSelector(selectCreateTeamProject);
  //
  const onRemoveMultipleUsersClick = (
    multipleSelectedUserAllocations,
    onSelectUserBulkIconClick
  ) => {
    const updatedSelectedTeamUsers = getDisconnectionSet(
      multipleSelectedUserAllocations,
      selectedUserAllocations?.docs,
      'userId.id'
    );
    dispatch(projectActions.setCreateTeamSelectedUsers(updatedSelectedTeamUsers));
    const userAllocationsNotInAvailableList = multipleSelectedUserAllocations.filter(
      (userAllocation) =>
        !availableUsers?.docs?.some((user) => user?.id === userAllocation?.userId?.id)
    );
    if (userAllocationsNotInAvailableList?.length) {
      const userIds = getIdList(
        userAllocationsNotInAvailableList?.map((userAllocation) => userAllocation?.userId)
      );
      // Get the removed users from the backend
      const formattedParamString = createFormattedString({
        userIds,
        aggregated: true,
        runAggregation: true,
        utilizationStartDate: moment(project?.startDate).format('YYYY-MM-DD'),
        utilizationEndDate: moment(project?.endDate).format('YYYY-MM-DD'),
      });
      dispatch(
        projectActions.getCreateTeamRemovedTeamMembers({
          data: {
            id: project?.id,
            query: formattedParamString,
          },
          currentAllocations: userAllocationsNotInAvailableList?.map((userAllocation) => ({
            id: userAllocation?.userId?.id,
            allocatedPercentage: userAllocation?.allocatedPercentage,
          })),
        })
      );
    }
    // Creating a structure for an optimized lookup
    const selectedUserIds = {};
    multipleSelectedUserAllocations.forEach((userAllocation) => {
      selectedUserIds[userAllocation?.userId?.id] = true;
    });
    //
    const updatedAvailableUsers = availableUsers?.docs?.map((availableUser) => {
      if (selectedUserIds[availableUser?.id]) {
        return {
          ...availableUser,
          disabled: false,
        };
      }
      return availableUser;
    });
    dispatch(projectActions.setCreateTeamAvailableUsers(updatedAvailableUsers));
    onSelectUserBulkIconClick();
  };
  //
  return { onRemoveMultipleUsersClick };
};
//
export default useOnRemoveMultipleUsersClick;
