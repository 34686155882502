import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import Grid from '@mui/material/Grid';
import { Card, CardContent, CardMedia, Container, Typography, Avatar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
//
import { selectAuthUser } from 'features/base/auth/selectors';
import { toolActions } from 'features/tools/slice';
import { selectLoader, selectAllTools } from 'features/tools/selectors';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import COLORS from 'features/base/constants/colors';
import { ENVIRONMENT } from 'config';

const ToolHub = () => {
  const dispatch = useDispatch();
  //
  const allTools = useSelector(selectAllTools);
  const user = useSelector(selectAuthUser);
  const isLoading = useSelector(selectLoader);
  //
  useEffect(() => {
    if (user?.id) {
      const userDepartmentId =
        user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.id;
      dispatch(
        toolActions.getAllTools({
          query: `departmentId=${userDepartmentId}&pagination=false`,
        })
      );
    }
  }, []);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Box>
        <Typography
          sx={{
            fontSize: 31,
            fontWeight: 'bold',
            marginBottom: 3,
          }}
        >
          Tool hub
        </Typography>
        {/* Better if we can move this into the separate component. Not the common one */}
        {isLoading ? (
          <Grid container mx={4} direction="row">
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
            <Grid
              item
              sx={{
                width: { sm: '100%', lg: '45%' },
                height: 'auto',
                mx: 2,
                mb: 2,
              }}
              xs={12}
              lg={5}
            >
              <ShimmerThumbnail height={130} />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            {!!allTools?.docs?.length &&
              allTools?.docs
                ?.filter((tool) => tool.dataStatus === DATA_STATUS_TYPES.ACTIVE)
                .map((tool) => (
                  <Grid
                    item
                    sx={{
                      width: { xs: '100%', md: '50%' },
                      height: 'auto',
                    }}
                    key={tool?.id}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        m: 1,
                      }}
                      elevation={14}
                    >
                      {tool?.logo?.[0]?.url ? (
                        <CardMedia
                          component="img"
                          sx={{
                            width: { xs: 64, sm: 128 },
                            height: { xs: 64, sm: 128 },
                            bgcolor: COLORS.LIGHT_BLUE,
                            m: 1,
                            p: { xs: 1, sm: 4 },
                            objectFit: 'contain',
                          }}
                          src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${tool?.logo?.[0]?.url}`}
                          alt={tool?.name}
                        />
                      ) : (
                        <Avatar
                          component={CardMedia}
                          sx={{
                            height: { xs: '64px', sm: '128px' },
                            width: { xs: '64px', sm: '128px' },
                            bgcolor: COLORS.LIGHT_BLUE,
                            fontSize: { xs: '32px', sm: '64px' },
                            borderRadius: '0px',
                            margin: '0.5rem',
                          }}
                        >
                          {tool?.name?.charAt(0)?.toUpperCase() || '-'}
                        </Avatar>
                      )}
                      <CardContent>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: COLORS.NAVY_BLUE }}>
                          {tool.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: COLORS.MEDIUM_GRAY,
                            marginTop: '5px',
                            textAlign: 'left',
                            wordBreak: 'break-word',
                          }}
                        >
                          {tool.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            {!allTools?.docs?.filter((tool) => tool.dataStatus === DATA_STATUS_TYPES.ACTIVE)
              .length && (
              <Grid item sx={{ marginTop: 20 }} xs={12}>
                <CustomNoRowsOverlay message="No tools found!" size />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Container>
  );
};
//
export default ToolHub;
