/* eslint-disable import/no-cycle */
import { all, fork } from 'redux-saga/effects';
//
import { supportTicketSaga } from 'features/support-tickets/saga';
import { signInSaga } from 'features/sign-in/saga';
import { projectSaga } from 'features/projects/saga';
import { userSaga } from 'features/users/saga';
import { opexSaga } from 'features/opex/saga';
import { authSaga } from 'features/base/auth/saga';
import { profileSaga } from 'features/profile/saga';
import { toolSaga } from 'features/tools/saga';
import { internalOnboardingSaga } from 'features/internal-onboarding/saga';
import { skillsSaga } from 'features/skills/saga';
import { departmentSaga } from 'features/departments/saga';
import { roleSaga } from 'features/roles/saga';
import { designationSaga } from 'features/designations/saga';
import { clientSaga } from 'features/clients/saga';
import { linkSaga } from 'features/links/saga';
import { reportSaga } from 'features/reports/saga';
import { arsenalSaga } from 'features/arsenal/saga';
import { benefitsSaga } from 'features/benefits/saga';
import { vendorSaga } from 'features/vendors/saga';

/**
 * Register all the saga functions into the root saga
 */
export default function* rootSaga() {
  yield all([
    fork(supportTicketSaga),
    fork(signInSaga),
    fork(projectSaga),
    fork(userSaga),
    fork(opexSaga),
    fork(authSaga),
    fork(profileSaga),
    fork(toolSaga),
    fork(internalOnboardingSaga),
    fork(skillsSaga),
    fork(departmentSaga),
    fork(roleSaga),
    fork(designationSaga),
    fork(clientSaga),
    fork(linkSaga),
    fork(reportSaga),
    fork(arsenalSaga),
    fork(benefitsSaga),
    fork(vendorSaga),
  ]);
}
