import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
//
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectUser,
  selectDepartmentDesignations,
  selectUpdateUserLoader,
} from 'features/profile/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { selectWorkAreas } from 'features/users/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { Autocomplete, Select } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { USER_TYPES, INTERNAL_USER_TYPES, USER_LEVELS } from 'features/base/constants/user-types';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import editProfileFormValidation from 'features/profile/validation/profile-validation';
import { profileActions } from 'features/profile/slice';
import { userActions } from 'features/users/slice';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import DatePicker from 'features/base/components/date-picker';
import PhoneInput from 'features/base/components/phone-input';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_DOMAINS, PERMISSION_ACTIONS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { selectUserId } from 'features/base/auth/selectors';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import { removeUnchanged } from 'features/base/helpers/object';

/**
 * Function that defines the popup form for editing a users profile
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @returns {Popup}
 */
const EditProfilePopup = ({ editModalOpen, setEditModalOpen }) => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const userUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const clientUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.CLIENT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const user = useSelector(selectUser);
  const authUserId = useSelector(selectUserId);
  const departmentDesignations = useSelector(selectDepartmentDesignations);
  const allWorkAreas = useSelector(selectWorkAreas);
  const loading = useSelector(selectUpdateUserLoader);
  //
  const { id } = useParams();
  const currentUserVerified = id === authUserId;
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    type: user?.type,
    department:
      user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.name || null,
    designation:
      user?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name ||
      null,
    departmentId:
      user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.id || '',
    designationId:
      user?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.id || '',
    workArea: user?.workAreaId?.name || '',
    workAreaId: user?.workAreaId?.id || '',
    capacity: user?.capacity || '',
    company: user?.client?.company || '',
    businessRegistrationNumber: user?.client?.brNumber || '',
    address: user?.client?.address || '',
    joinedDate: user?.joinedDate || '',
    level: user?.level || '',
    nic: user?.nic || '',
    employeeId: user?.employeeId || '',
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    dispatch(userActions.getWorkAreas({ query: 'pagination=false' }));
  }, []);
  //
  return (
    <Popup
      open={editModalOpen}
      onClose={handleOnClose}
      title="Edit Profile"
      mediumSize="660px"
      titleSx={{ pl: '0.8rem' }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={editProfileFormValidation}
        onSubmit={(values) => {
          let profileData = { ...values };
          delete profileData.department;
          delete profileData.designation;
          delete profileData.workArea;
          // Only send the attributes that have been changed
          profileData = removeUnchanged(initialValues, profileData);
          //
          dispatch(profileActions.updateUser({ userId: user.id, ...profileData }));
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <TextField
              type="text"
              name="firstName"
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              label="First name"
              disabled={!userUpdatePermissionsVerified && !currentUserVerified}
            />
            <TextField
              type="text"
              name="lastName"
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Last name"
              disabled={!userUpdatePermissionsVerified && !currentUserVerified}
            />
            <PhoneInput
              name="phoneNumber"
              id="phoneNumber"
              country="lk"
              inputStyle={{
                fontSize: '16px',
                width: '100%',
                height: '40px',
                borderRadius: '0px',
                lineHeight: '20px',
              }}
              buttonStyle={{ backgroundColor: COLORS.WHITE }}
              fullWidth="true"
              containerStyle={{ width: '100%' }}
              onBlur={handleBlur}
              countryCodeEditable="true"
              placeholder=""
              label="Phone number"
              disabled={!userUpdatePermissionsVerified && !currentUserVerified}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              errorMsg={touched?.phoneNumber && errors?.phoneNumber}
            />
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.USER,
                  action: PERMISSION_ACTIONS.UPDATE,
                },
              ]}
            >
              <Select
                name="type"
                id="type"
                value={values.type}
                selectLabel="type"
                onChange={(event) => {
                  setFieldValue('type', event.target.value);
                  setFieldValue('level', '');
                }}
                items={INTERNAL_USER_TYPES}
                textLabel="Type"
                stringFormat={capitalizeFirstLetter}
                error={Boolean(touched.type && errors.type)}
                errorMsg={touched?.type && errors?.type}
                hide={values.type === USER_TYPES.CLIENT}
              />
              <Select
                name="level"
                id="level"
                value={values.level}
                selectLabel="level"
                onChange={(event) => {
                  setFieldValue('level', event.target.value);
                }}
                items={
                  values.type === USER_TYPES.INTERN
                    ? [{ key: 1, value: USER_LEVELS.LEVEL_0 }]
                    : Object.values(USER_LEVELS).map((item, index) => ({
                        key: index,
                        value: item,
                      }))
                }
                textLabel="Level"
                error={Boolean(touched.level && errors.level)}
                errorMsg={touched?.level && errors?.level}
                placeholder="Select User Level"
                hide={
                  values.type !== USER_TYPES.INTERNAL &&
                  values.type !== USER_TYPES.CONTRACT &&
                  values.type !== USER_TYPES.INTERN
                }
              />
              <DatePicker
                name="joinedDate"
                label="Date of Appointment"
                value={values.joinedDate}
                onChange={(event) => {
                  setFieldValue('joinedDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                }}
                onKeyDown={(e) => e.preventDefault()}
                error={Boolean(touched.joinedDate && errors.joinedDate)}
                errorMsg={touched?.joinedDate && errors?.joinedDate}
                sx={{ pb: 1 }}
                placeholder="Select joined date"
                hide={
                  values.type !== USER_TYPES.CONTRACT &&
                  values.type !== USER_TYPES.INTERN &&
                  values.type !== USER_TYPES.INTERNAL
                }
                maxDate={moment().toDate()}
                minDate={moment().year(2015)}
              />
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.USER_ORGANIZATION,
                  action: PERMISSION_ACTIONS.READ,
                },
              ]}
              hide
            >
              <PermissionWrapper
                requiredPermissions={[
                  {
                    domain: PERMISSION_DOMAINS.USER_ORGANIZATION,
                    action: PERMISSION_ACTIONS.UPDATE,
                  },
                ]}
              >
                <Autocomplete
                  id="department"
                  name="department"
                  options={departmentDesignations.map((depDes) => ({
                    id: depDes.department?.id,
                    label: depDes.department?.name,
                  }))}
                  label="Department"
                  onChange={(_, newValue) => {
                    setFieldValue('designation', null);
                    setFieldValue('department', newValue?.label || null);
                    setFieldValue('departmentId', newValue?.id || '');
                  }}
                  // Comparison function is customized since the value is a string, null is also a valid option since values can be cleared
                  isOptionEqualToValue={(option, autoValue) =>
                    option.label === autoValue || autoValue === null
                  }
                  controlled
                  value={values.department}
                  error={Boolean(touched.department && errors.department)}
                  errorMsg={touched?.department && errors?.department}
                  onClose={() => setFieldTouched('department', false)}
                  sx={{
                    pb: 1,
                  }}
                  hide={
                    values.type !== USER_TYPES.CONTRACT &&
                    values.type !== USER_TYPES.INTERN &&
                    values.type !== USER_TYPES.INTERNAL
                  }
                />
                <Autocomplete
                  id="designation"
                  name="designation"
                  options={departmentDesignations
                    .find((depDes) => depDes.department?.name === values.department)
                    ?.designations?.map((designation) => ({
                      id: designation?.id,
                      label: designation?.name,
                    }))}
                  label="Designation"
                  onChange={(_, newValue) => {
                    setFieldValue('designation', newValue?.label || null);
                    setFieldValue('designationId', newValue?.id || '');
                  }}
                  // Comparison function is customized since the value is a string, null is also a valid option since values can be cleared
                  isOptionEqualToValue={(option, autoValue) =>
                    option.label === autoValue || autoValue === null
                  }
                  controlled
                  value={values.designation}
                  error={Boolean(touched.designation && errors.designation)}
                  errorMsg={touched?.designation && errors?.designation}
                  disabled={Boolean(!values.department)}
                  disabledMsg="Please select a department above"
                  onClose={() => setFieldTouched('designation', false)}
                  sx={{
                    pb: 1,
                  }}
                  hide={
                    values.type !== USER_TYPES.CONTRACT &&
                    values.type !== USER_TYPES.INTERN &&
                    values.type !== USER_TYPES.INTERNAL
                  }
                />
                <Autocomplete
                  name="workArea"
                  id="workArea"
                  options={allWorkAreas.map((workArea) => ({
                    id: workArea?.id,
                    label: workArea?.name,
                  }))}
                  selectLabel="workArea"
                  onChange={(_, newValue) => {
                    setFieldValue('workArea', newValue?.label || null);
                    setFieldValue('workAreaId', newValue?.id || '');
                  }}
                  label="Work area"
                  isOptionEqualToValue={(option, autoValue) =>
                    option.label === autoValue || autoValue === null
                  }
                  controlled
                  value={values.workArea}
                  error={Boolean(touched.workArea && errors.workArea)}
                  errorMsg={touched?.workArea && errors?.workArea}
                  sx={{
                    pb: 1,
                  }}
                  hide={
                    values.type !== USER_TYPES.CONTRACT &&
                    values.type !== USER_TYPES.INTERN &&
                    values.type !== USER_TYPES.INTERNAL
                  }
                />
              </PermissionWrapper>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.USER,
                  action: PERMISSION_ACTIONS.UPDATE,
                },
              ]}
            >
              <TextField
                type="text"
                name="capacity"
                value={values.capacity}
                error={Boolean(touched?.capacity && errors?.capacity)}
                helperText={touched?.capacity && errors?.capacity}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Capacity(hrs)"
                hide={
                  values.type !== USER_TYPES.CONTRACT &&
                  values.type !== USER_TYPES.INTERN &&
                  values.type !== USER_TYPES.INTERNAL
                }
              />
            </PermissionWrapper>
            <TextField
              type="text"
              name="businessRegistrationNumber"
              value={values.businessRegistrationNumber}
              error={Boolean(
                touched?.businessRegistrationNumber && errors?.businessRegistrationNumber
              )}
              helperText={touched?.businessRegistrationNumber && errors?.businessRegistrationNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              label="Business registration number"
              hide={values.type !== USER_TYPES.CLIENT}
              disabled={!clientUpdatePermissionsVerified && !currentUserVerified}
            />
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.CLIENT,
                  action: PERMISSION_ACTIONS.UPDATE,
                },
              ]}
            >
              <TextField
                type="text"
                name="company"
                value={values.company}
                error={Boolean(touched?.company && errors?.company)}
                helperText={touched?.company && errors?.company}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Company"
                hide={values.type !== USER_TYPES.CLIENT}
              />
              <TextField
                type="text"
                name="address"
                value={values.address}
                error={Boolean(touched?.address && errors?.address)}
                helperText={touched?.address && errors?.address}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Address"
                fullWidth
                my={2}
                height={120}
                multiline
                rows={4}
                hide={values.type !== USER_TYPES.CLIENT}
              />
            </PermissionWrapper>

            <TextField
              type="text"
              name="nic"
              value={values.nic}
              error={Boolean(touched.nic && errors.nic)}
              helperText={touched.nic && errors.nic}
              onChange={handleChange}
              onBlur={handleBlur}
              label="NIC Number"
              disabled={!userUpdatePermissionsVerified && !currentUserVerified}
            />

            <TextField
              type="text"
              name="employeeId"
              value={values.employeeId}
              error={Boolean(touched.employeeId && errors.employeeId)}
              helperText={touched.employeeId && errors.employeeId}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Employee ID"
              disabled={!userUpdatePermissionsVerified && !currentUserVerified}
            />

            <ButtonGrid
              leftButtonText="Cancel"
              rightButtonText={loading ? 'Submitting' : 'Submit'}
              leftOnClick={handleOnClose}
              rightOnClick={null}
              rightIcon={loading ? loaderIcon : null}
              submitDisabled={JSON.stringify(initialValues) === JSON.stringify(values) || loading}
            />
          </form>
        )}
      </Formik>
    </Popup>
  );
};
//
export default EditProfilePopup;
