import * as yup from 'yup';
import { BENEFIT_CAMPAIGN_TYPES } from 'features/base/constants/benefit-types';

/**
 * Function for add benefit form validation
 */
const benefitValidation = yup.object().shape({
  title: yup
    .string()
    .required('Please Enter valid title')
    .min(5, ({ min }) => `Title must be at least ${min} characters`)
    .max(200, ({ max }) => `Title cannot exceed ${max} characters`),
  description: yup
    .string()
    .required('Please Enter valid description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(750, ({ max }) => `Description cannot exceed ${max} characters`),
  vendorId: yup.string().when('campaignType', {
    is: BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN,
    then: yup.string().required('Please select a vendor'),
  }),
  expiryDate: yup.date().optional().nullable(),
  campaignType: yup.string().required('Please select campaign type'),
  link: yup
    .string()
    .url('Please provide a valid link')
    .min(7, ({ min }) => `Link must be at least ${min} characters`)
    .max(300, ({ max }) => `Link cannot exceed ${max} characters`)
    .nullable(true),
  excludedUsers: yup.array().optional(),
  additionalUsers: yup.array().optional(),
});
//
export default benefitValidation;
