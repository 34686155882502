import { Slider, SliderThumb } from '@mui/material';
import SKILL_TYPES from 'features/base/constants/skill-types';

/**
 * Function that renders the slider component for each skill
 * @param {Object} userSkill - User skill record
 * @param {Boolean} editMode - Indicates if the edit mode button is clicked
 * @param {Function} handleUserSkillOnChange - On change gunction
 * @returns
 */
const SkillSlider = ({ userSkill, editMode, handleUserSkillOnChange }) => {
  const thumbComponent = ({ children, ...other }) => (
    <SliderThumb {...other}>
      {children}
      <span>{other?.ownerState?.value}</span>
    </SliderThumb>
  );
  //
  return (
    <Slider
      id={userSkill?.id}
      max={10}
      value={userSkill?.rating}
      defaultValue={userSkill?.rating}
      slots={{ thumb: thumbComponent }}
      onChange={(_, newValue) => handleUserSkillOnChange(userSkill?.skillId, 'rating', newValue)}
      aria-label="SkillRating"
      disabled={!editMode || userSkill?.learningType === SKILL_TYPES.NOT_APPLICABLE}
      sx={{
        color: '#2684FF',
        height: '12px',
        width: { lg: '100%', sm: '510px', md: '100%', xs: '230px' },
        ml: { md: '100px' },
        '& .MuiSlider-thumb': {
          height: 27,
          width: 27,
          backgroundColor: '#2684FF',
          color: 'white',
          border: '1px solid currentColor',
          '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
          },
        },
        '& .MuiSlider-track': {
          height: 10,
          backgroundColor: '#2684FF',
        },
        '& .MuiSlider-rail': {
          height: 10,
          backgroundColor: '#2684FF',
        },
      }}
    />
  );
};
//
export default SkillSlider;
