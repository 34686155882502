import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  MenuList,
  ListItemText,
  ListItemIcon,
  Avatar,
  Grid,
  IconButton,
} from '@mui/material';
//
import { selectAuthUser } from 'features/base/auth/selectors';
import ROUTES from 'features/base/constants/routes';
import { USER_TYPES } from 'features/base/constants/user-types';
import { authActions } from 'features/base/auth/slice';
import COLORS from 'features/base/constants/colors';
/**
 * display user profile and details area with logout
 */
const UserMenuMobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  const user = useSelector(selectAuthUser);
  //
  const [anchorElUser, setAnchorElUser] = useState(null);
  //
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  //
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigateToProfile = () => {
    if (user?.type === USER_TYPES.CLIENT) {
      navigate(ROUTES.PROFILE.replace(':id', user?.id).replace('/*', `/${ROUTES.CLIENT_PROFILE_USERS}`));
    } else {
      navigate(ROUTES.PROFILE.replace(':id', user?.id).replace('/*', `/${ROUTES.ALLOCATIONS}`));
    }
    setAnchorElUser(null);
  };
  //
  return (
    <Box sx={{ p: 1, display: { xs: 'flex', md: 'none' } }}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
        <Grid item>
          {user && user.profileImage && (
            <Avatar
              alt={`${user?.firstName}`}
              src={user?.profileImage}
              imgProps={{ referrerPolicy: 'no-referrer' }}
            />
          )}
        </Grid>
        <Grid item xs={8} sm container>
          <Grid item xs container direction="column">
            <Grid item xs>
              <Typography
                sx={{
                  color: 'white',
                  ':hover': { color: COLORS.ELECTRIC_BLUE },
                  textTransform: 'capitalize',
                }}
              >
                <Button
                  onClick={navigateToProfile}
                  sx={{
                    pl: 'inherit',
                    textAlign: 'left',
                    color: 'white',
                    ':hover': { color: COLORS.ELECTRIC_BLUE },
                    textTransform: 'capitalize',
                    fontSize: '1rem',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {' '}
                  {`${user?.firstName} ${user?.lastName}` || 'Name Not Found'}
                </Button>
              </Typography>
              <Typography sx={{ color: 'white', textTransform: 'lowercase' }}>
                {' '}
                {user?.email}{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              onClick={handleOpenUserMenu}
              sx={{ p: 1 }}
              endIcon={
                <ArrowDropDownIcon
                  sx={{ color: 'white', ':hover': { color: COLORS.ELECTRIC_BLUE } }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            position: {
              top: '0 !important',
              right: '0 !important',
              left: '0 !important',
              bottom: '0 !important',
            },
          },
          '& .MuiMenu-paper': {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            textAlign: 'center',
            zIndex: 999,
            backgroundColor: COLORS.NAVY_BLUE,
            color: 'white',
            ':hover': {
              cursor: 'pointer',
            },
          },
          display: { xs: 'flex', md: 'none' },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClick={handleCloseUserMenu}
      >
        <MenuList
          sx={{
            mt: 0,
            width: '100%',
            bgcolor: COLORS.NAVY_BLUE,
            color: 'white',
            textAlign: 'left',
            p: 3,
            '.MuiMenuItem-root:hover': {
              transition: 'padding-left 0.5s ease',
              color: COLORS.ELECTRIC_BLUE,
              pl: 6,
              '.MuiListItemIcon-root': {
                color: COLORS.ELECTRIC_BLUE,
              },
            },
          }}
        >
          <MenuItem sx={{ justifyContent: 'right' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleCloseUserMenu}
              sx={{ color: 'white', ':hover': { color: 'red' } }}
            >
              <CloseIcon />
            </IconButton>
          </MenuItem>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-evenly"
            sx={{ p: 3, cursor: 'default' }}
          >
            <Grid item>
              {user && user.profileImage && (
                <Avatar
                  sx={{ width: '4rem', height: '4rem' }}
                  alt={`${user?.firstName}`}
                  src={user?.profileImage}
                  imgProps={{ referrerPolicy: 'no-referrer' }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography
                    sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      fontSize: '1.3rem',
                    }}
                  >
                    {' '}
                    {`${user?.firstName} ${user?.lastName}` || 'Name Not Found'}
                  </Typography>
                  <Typography
                    sx={{ color: 'white', textTransform: 'lowercase', fontSize: '1.3rem' }}
                  >
                    {' '}
                    {user?.email}{' '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <MenuItem onClick={navigateToProfile} sx={{ p: 3 }}>
            <ListItemIcon sx={{ color: 'white' }}>
              <AccountCircleTwoToneIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(authActions.logout());
            }}
            sx={{ p: 3 }}
          >
            <ListItemIcon
              sx={{
                color: 'white',
              }}
            >
              <LogoutTwoToneIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Sign out</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
//
export default UserMenuMobile;
