import { Box, Typography, Link, IconButton, Switch, Avatar, Grid, Tooltip } from '@mui/material';
import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import COLORS from 'features/base/constants/colors';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
/**
 * View Support tickets table columns - basic details
 */
export const ALL_BENEFITS_TABLE_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    valueGetter: (params) => params.row.title.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.title;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'departments',
    headerName: 'Departments',
    width: 300,
    valueGetter: (params) => params.row.departments.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.departments;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            style={{ wordWrap: 'break-word' }}
            onClick={onClick}
          >
            {(value?.length > 0 && value.map((val) => val?.name).join(', ')) || '-'}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'noOfUsers',
    headerName: 'No of users',
    width: 120,
    valueGetter: (params) => params.row.noOfUsers.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.noOfUsers;
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          width={'100%'}
          onClick={onClick}
        >
          <Typography align="center" onClick={onClick}>
            {value || '-'}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'empLevel',
    headerName: 'Emp level',
    width: 120,
    valueGetter: (params) => params.row?.empLevel?.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.empLevel;
      return (
        <Box display={'flex'} flexDirection={'row'} width={'100%'} onClick={onClick}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value?.map((val) => val).join(', ')}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    width: 140,
    valueGetter: (params) => params.row.createdAt.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.createdAt;
      return <Typography onClick={onClick}>{value}</Typography>;
    },
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry date',
    width: 140,
    valueGetter: (params) => params.row.expiryDate.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.expiryDate;
      return <Typography onClick={onClick}>{value || '-'}</Typography>;
    },
  },
  {
    field: 'link',
    headerName: 'Link',
    width: 150,
    sortable: false,
    renderCell: ({ value: { value } }) =>
      (value && (
        <Link
          href={value}
          underline="hover"
          sx={{
            fontSize: '0.875rem',
            maxWidth: '40ch',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </Link>
      )) ||
      '-',
  },
];

/**
 * View benefits table columns - action columns
 */
export const ALL_BENEFITS_TABLE_ACTION_COLUMNS = [
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    valueGetter: (params) => params.row.status.checked,
    renderCell: (params) => {
      const { onChange, checked, isLoading } = params.row.status;
      return <Switch checked={checked} color="success" readOnly={isLoading} onClick={onChange} />;
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: ({
      value: {
        benefitsUpdatePermissionVerified,
        benefitsDeletePermissionVerified,
        isLoading,
        handleBenefitEdit,
        handleBenefitDelete,
      },
    }) => (
      <Box display="flex">
        {benefitsUpdatePermissionVerified && (
          <IconButton
            sx={{ '&:hover': { color: 'blue' } }}
            onClick={handleBenefitEdit}
            readOnly={isLoading}
          >
            <DriveFileRenameOutline />
          </IconButton>
        )}
        {benefitsDeletePermissionVerified && (
          <IconButton
            sx={{ '&:hover': { color: 'red' } }}
            onClick={handleBenefitDelete}
            readOnly={isLoading}
          >
            <DeleteForever />
          </IconButton>
        )}
      </Box>
    ),
  },
];
/**
 * View support ticket table columns - action details
 */
export const MY_BENEFITS_TABLE_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    width: 440,
    valueGetter: (params) => params.row.title.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.title;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    width: 180,
    valueGetter: (params) => params.row.createdAt.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.createdAt;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography onClick={onClick}>{value}</Typography>
        </Box>
      );
    },
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry date',
    width: 180,
    valueGetter: (params) => params.row.expiryDate.value,
    renderCell: (params) => {
      const { value, showExpiryDate, onClick } = params.row.expiryDate;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography onClick={onClick}>{showExpiryDate ? value : '-'}</Typography>
        </Box>
      );
    },
  },
  {
    field: 'link',
    headerName: 'Link',
    width: 500,
    sortable: false,
    renderCell: ({ value: { value } }) =>
      (value && (
        <Link
          href={value}
          underline="hover"
          sx={{
            fontSize: '0.875rem',
            maxWidth: 500,
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </Link>
      )) ||
      '-',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    valueGetter: (params) => params.row.status.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.status;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }} onClick={onClick}>
          <Box
            sx={{
              bgcolor:
                value === DATA_STATUS_TYPES.ACTIVE ? COLORS.PALE_GREEN : COLORS.LAVENDER_MIST,
              p: '7px 16px',
              mr: '16px',
              width: '150px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color:
                  value === DATA_STATUS_TYPES.ACTIVE ? COLORS.SUCCESS_MAIN : COLORS.DEEP_INDIGO,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];

/**
 * Benefit Add Employees View table columns
 */
export const BENEFIT_ADD_EMPLOYEES_TABLE_COLUMNS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 350,
    valueGetter: (params) => params.row.profile.name,
    renderCell: (params) => {
      const { logo, name } = params.row.profile;
      return (
        <Grid container alignItems="center">
          <Grid item sm={3}>
            {logo ? (
              <img
                src={logo}
                referrerPolicy="no-referrer"
                alt="logo"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  height: '40px',
                  width: '40px',
                }}
              >
                {name?.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={name}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                style={{ wordWrap: 'break-word' }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    valueGetter: (params) => params.row.email.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.email;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 240,
    valueGetter: (params) => params.row.department.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.department;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'level',
    headerName: 'Level',
    width: 120,
    valueGetter: (params) => params.row.level.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.level;
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  // delete icon
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: (params) => {
      const { handleRemoveEmployee } = params.row.action;
      return (
        <Box display="flex">
          <IconButton sx={{ '&:hover': { color: 'red' } }} onClick={handleRemoveEmployee}>
            <DeleteForever />
          </IconButton>
        </Box>
      );
    },
  },
];
