/**
 * Define all the .env file related keys
 */
const ENVIRONMENT = {
  REACT_APP_ENVIRONMENT: process.env?.REACT_APP_ENVIRONMENT,
  BACKEND_API: process.env?.REACT_APP_BACKEND_API,
  APP_NAME: process.env?.REACT_APP_APP_NAME,
  PERSIST_ENCRYPTED_SECRET_KEY: process.env?.REACT_APP_PERSIST_ENCRYPTED_SECRET_KEY,
  PERSIST_VERSION: process.env?.REACT_APP_PERSIST_VERSION,
  COGNITO_GOOGLE_CLIENT_ID: process.env?.REACT_APP_COGNITO_GOOGLE_CLIENT_ID,
  COGNITO_GOOGLE_AUTH0_REDIRECT_URI: process.env?.REACT_APP_COGNITO_GOOGLE_AUTH0_REDIRECT_URI,
  COGNITO_GOOGLE_AUTH0_SCOPE: process.env?.REACT_APP_COGNITO_GOOGLE_AUTH0_SCOPE,
  COGNITO_DOMAIN: process.env?.REACT_APP_COGNITO_DOMAIN,
  COGNITO_LOGOUT_REDIRECT_URI: process.env?.REACT_APP_COGNITO_LOGOUT_REDIRECT_URI,
  PERSIST_CONFIG_KEY: process.env?.REACT_APP_PERSIST_CONFIG_KEY,
  KC_DOMAIN: process.env?.REACT_APP_KC_DOMAIN,
  KC_REALM: process.env?.REACT_APP_KC_REALM,
  KC_AUTH_URL: process.env?.REACT_APP_KC_AUTH_URL,
  KC_CLIENT_ID: process.env?.REACT_APP_KC_CLIENT_ID,
  KC_RESPONSE_TYPE: process.env?.REACT_APP_KC_RESPONSE_TYPE,
  KC_REDIRECT_URI: process.env?.REACT_APP_KC_REDIRECT_URI,
  KC_SCOPES: process.env?.REACT_APP_KC_SCOPES,
  KC_PKCE_CHALLENGE_METHOD: process.env?.REACT_APP_KC_PKCE_CHALLENGE_METHOD,
  KC_PKCE_VERIFIER_LENGTH: parseInt(process.env?.REACT_APP_KC_PKCE_VERIFIER_LENGTH, 10),
  KC_LOGOUT_URL: process.env?.REACT_APP_KC_LOGOUT_URL,
  KC_LOGOUT_REDIRECT_URI: process.env?.REACT_APP_KC_LOGOUT_URI,
  STORAGE_BUCKET_PREFIX: process.env?.REACT_APP_STORAGE_BUCKET_PREFIX,
  JIRA_ORG_NAME: process.env?.REACT_APP_JIRA_ORG_NAME,
  FIREBASE_API_KEY: process.env?.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env?.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env?.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env?.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env?.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env?.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
//
export default ENVIRONMENT;
