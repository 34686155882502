import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { skillActions } from 'features/skills/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { selectDeleteSkillLoader } from 'features/skills/selectors';
/**
 * Function that defines the popup form for deleting skills
 * @returns {Popup}
 */
const DeleteSkillPopup = ({ deleteSkillsModalOpen, setDeleteSkillsModalOpen, skillIdToDelete }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectDeleteSkillLoader);
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setDeleteSkillsModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const handleOnConfirmationSubmit = () => {
    dispatch(
      skillActions.deleteSkill({
        id: skillIdToDelete,
      })
    );
  };
  //
  useEffect(() => {
    if (
      deleteSkillsModalOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SKILL,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <ConfirmationPopup
        open={deleteSkillsModalOpen}
        displayMessage="Do you really want to delete this skill?"
        handleOnClose={handleOnClose}
        handleOnConfirmation={handleOnConfirmationSubmit}
        loading={loading}
      />
    </PermissionWrapper>
  );
};
//
export default DeleteSkillPopup;
