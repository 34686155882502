import { useSelector } from 'react-redux';
//
import { selectIdToken, selectRefreshToken, selectAccessToken } from 'features/base/auth/selectors';

/**
 * Function to retrieve the access token from the auth slice
 */
export const getAccessToken = () => useSelector(selectAccessToken);
/**
 * Function to retrieve the id token from the auth slice
 */
export const getIdToken = () => useSelector(selectIdToken);
/**
 * Function to retrieve the Refresh token from the auth slice
 */
export const getRefreshToken = () => useSelector(selectRefreshToken);
