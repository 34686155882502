import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
/**
 * Skill table columns - basic columns
 */
export const SKILL_TABLE_BASIC_COLUMNS = [
  {
    field: 'name',
    headerName: 'Skill',
    width: 600,
  },
];
/**
 * Designation table columns - action columns
 */
export const SKILL_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: (params) => {
      const {
        skillUpdatePermissionsVerified,
        skillDeletePermissionsVerified,
        isLoading,
        handleSkillEdit,
        handleSkillDelete,
      } = params.row.action;
      return (
        <Grid container marginLeft={-1}>
          <Grid sx={{ display: 'flex' }}>
            {skillUpdatePermissionsVerified && (
              <IconButton onClick={handleSkillEdit} disabled={isLoading} size="small">
                <DriveFileRenameOutline size="small" />
              </IconButton>
            )}
            {skillDeletePermissionsVerified && (
              <IconButton
                onClick={handleSkillDelete}
                disabled={isLoading}
                size="small"
                style={{ marginLeft: '-10px' }}
              >
                <DeleteForever size="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      );
    },
  },
];
