/* eslint-disable react/jsx-no-useless-fragment */
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Container, Breadcrumbs, Link, Divider } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
//
import TIME_OUTS from 'features/base/constants/time-outs';
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import { supportTicketActions } from 'features/support-tickets/slice';
import { selectUserId, selectAuthUser } from 'features/base/auth/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectIndividualSupportTicket,
  selectIndividualSupportTicketLoader,
  selectIndividualSupportTicketProcessing,
  selectAllComments,
  selectCommentLoading,
  selectIsCommentAdding,
  selectIsCommentAttachmentAdding,
} from 'features/support-tickets/selectors';
import { SUPPORT_TICKET_ALLOWED_EXTENSIONS } from 'features/base/constants/file-upload';
import { verifyExtensions } from 'features/base/helpers/file';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { selectNotification } from 'features/base/notifications/selectors';
import './index.scss';
import CommentBox from './components/comment-box';
import CommentView from './components/comment-view';
import SupportTicketDetailView from './components/support-ticket-detail-view';

/**
 * Component that defines the single support ticket view
 * @returns MUI Grid with the single support ticket view
 */
const SingleSupportTicketView = () => {
  const dispatch = useDispatch();
  const { id: ticketId } = useParams();
  //
  const commentInterval = useRef(null);
  //
  const notification = useSelector(selectNotification);
  const userId = useSelector(selectUserId);
  const authUser = useSelector(selectAuthUser);
  const supportTicket = useSelector(selectIndividualSupportTicket);
  const supportTicketLoading = useSelector(selectIndividualSupportTicketLoader);
  const supportTicketProcessing = useSelector(selectIndividualSupportTicketProcessing);
  const commentLoading = useSelector(selectCommentLoading);
  const commentList = useSelector(selectAllComments);
  const isCommentAdding = useSelector(selectIsCommentAdding);
  const isCommentAttachmentAdding = useSelector(selectIsCommentAttachmentAdding);
  //
  const isFirst = useIsInitialize(supportTicket);
  //
  const [newComment, setNewComment] = useState('');
  const [attachmentFile, setAttachmentFile] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [fileTypeError, setFileTypeError] = useState(true);
  //
  const [isResolvePopupOpen, setIsResolvePopupOpen] = useState(false);
  //
  const handleOnSeverityChange = (event) => {
    event.stopPropagation();
    dispatch(
      supportTicketActions.updateIndividualSupportTicket({
        id: ticketId,
        severity: event.target.value,
      })
    );
  };
  //
  const handleOnResolveClick = () => {
    if (supportTicket?.acknowledged?.isAcknowledged || supportTicket.raisedBy.id === userId) {
      return;
    }
    setIsResolvePopupOpen(true);
  };
  //
  useEffect(() => {
    dispatch(supportTicketActions.getSupportTicketById({ id: ticketId }));
    dispatch(supportTicketActions.getComments({ id: ticketId }));
    // clear notification on cleanup
    return () => {
      dispatch(notificationActions.resetNotification());
    };
  }, []);
  //
  useEffect(() => {
    if (notification?.isEnabled) {
      toast(notification?.message, { type: notification?.type });
    }
  }, [notification]);
  //
  const handleOnConfirmationSubmit = () => {
    dispatch(
      supportTicketActions.updateIndividualSupportTicket({
        id: ticketId,
        acknowledged: {
          isAcknowledged: true,
        },
      })
    );
  };
  //
  useEffect(() => {
    if (supportTicketProcessing === false) {
      setIsResolvePopupOpen(false);
    }
  }, [supportTicketProcessing]);
  //
  const onCommentSendClick = () => {
    if (newComment === '') {
      return;
    }
    dispatch(
      supportTicketActions.addComments({
        id: ticketId,
        message: newComment,
      })
    );
    setNewComment('');
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(
      Object.values(attachmentFile),
      SUPPORT_TICKET_ALLOWED_EXTENSIONS
    );
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      Object.keys(attachmentFile).forEach((key) => formData.append('file', attachmentFile[key]));
      if (attachmentFile.length > 0) {
        dispatch(
          supportTicketActions.addCommentsAttachments({
            id: ticketId,
            files: formData,
          })
        );
      }
    } else {
      setFileTypeError(true);
    }
  }, [attachmentFile]);
  //
  useEffect(() => {
    commentInterval.current = setInterval(
      () => dispatch(supportTicketActions.getComments({ id: ticketId })),
      TIME_OUTS.COMMENT_REFETCH
    );
    return () => clearInterval(commentInterval.current);
  }, [ticketId]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <ConfirmationPopup
        open={isResolvePopupOpen}
        displayMessage="Do you really want to Resolve this item?"
        handleOnClose={() => setIsResolvePopupOpen(false)}
        handleOnConfirmation={handleOnConfirmationSubmit}
        loading={supportTicketProcessing}
      />
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/support-tickets">
          Support tickets
        </Link>
        <Typography color="primary">Individual View</Typography>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }} mt={2}>
        {supportTicket?.title}
      </Typography>
      <Box mb={6}>
        <SupportTicketDetailView
          supportTicket={supportTicket}
          supportTicketLoading={supportTicketLoading}
          supportTicketProcessing={supportTicketProcessing}
          handleOnSeverityChange={handleOnSeverityChange}
          handleOnResolveClick={handleOnResolveClick}
        />

        <Divider sx={{ mt: 3, mb: 3 }} />

        <CommentBox
          commentLoading={commentLoading}
          isCommentAdding={isCommentAdding}
          isCommentAttachmentAdding={isCommentAttachmentAdding}
          newComment={newComment}
          onCommentSendClick={onCommentSendClick}
          setNewComment={setNewComment}
          setAttachmentFile={setAttachmentFile}
          authUser={authUser}
          isFirst={isFirst}
        />

        <Divider sx={{ mt: 3, mb: 3 }} />

        <CommentView
          commentLoading={commentLoading}
          commentList={commentList}
          authUser={authUser}
          isFirst={isFirst}
        />
      </Box>
    </Container>
  );
};
//
export default SingleSupportTicketView;
