import { Grid, Typography } from '@mui/material';
//
import infoIcon from 'features/base/assets/images/svg/info-icon.svg';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import Popup from '../modal';
import ButtonGrid from '../left-right-btn-grid';

/**
 * Function that defines a confirmation popup
 * @param {Boolean} open - Specifies whether the modal is open
 * @param {Function} handleOnClose - On close handler
 * @param {Function} handleOnConfirmation - On submit handler
 * @param {Boolean} loading - Indicates loading state
 * @param {String} displayMessage - Message to be displayed
 * @returns {Popup}
 */
const ConfirmationPopup = ({
  open,
  handleOnClose,
  handleOnConfirmation,
  loading,
  displayMessage,
}) => (
  <Popup open={open ?? false} onClose={handleOnClose} mediumSize="660px" height="400px">
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      spacing={0.5}
    >
      <Grid item>
        <img
          src={infoIcon}
          alt="info-icon"
          style={{
            height: '5rem',
            width: '5rem',
            borderRadius: '50%',
          }}
        />
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '31px', fontWeight: '800' }}>Are you sure?</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '16px' }}>{displayMessage}</Typography>
      </Grid>
      <Grid item>
        <ButtonGrid
          leftButtonText="Cancel"
          rightButtonText={loading ? 'Confirming' : 'Confirm'}
          leftOnClick={handleOnClose}
          rightOnClick={handleOnConfirmation}
          submitDisabled={loading}
          rightIcon={loading ? loaderIcon : null}
          buttonSpacing={2}
        />
      </Grid>
    </Grid>
  </Popup>
);
//
export default ConfirmationPopup;
