import * as yup from 'yup';
/**
 * Function for add Role form validation
 */
export const addRoleFormValidation = yup.object().shape({
  name: yup.string().required('Please enter valid role name'),
  permissions: yup.object().required('Required'),
});
/**
 * Function for edit role form validation
 */
export const editRoleFormValidation = yup.object().shape({
  name: yup.string().required('Please enter valid role name'),
});
