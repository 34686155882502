import React from 'react';
import moment from 'moment';
import { Avatar, Box, Divider, Link, Stack, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';
import { ShimmerTable } from 'react-shimmer-effects';
import { ENVIRONMENT } from 'config';
import { COMMENT_TYPES } from 'features/base/constants/comment-type';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { FileIcon, defaultStyles } from 'react-file-icon';

const CommentView = ({ commentList, commentLoading, isFirst, authUser }) =>
  isFirst && commentLoading ? (
    <ShimmerTable row={5} col={2} />
  ) : (
    Array.isArray(commentList) &&
    [...commentList].reverse()?.map((comment, index) => (
      <React.Fragment key={comment?.id}>
        {index % 5 === 0 && (
          <Box m={2}>
            <Divider sx={{ width: 'auto' }}>
              <Typography sx={{ fontSize: '0.875rem', opacity: 0.6 }} color={COLORS.GREY}>
                {moment(comment?.timestamp).fromNow()}
              </Typography>
            </Divider>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            backgroundColor:
              comment?.addedBy?.id === authUser?.id ? COLORS.PRIMARY_LIGHT : COLORS.SKY_BLUE,
            mt: 2,
            borderRadius: '0.3rem',
          }}
          className="commentBox"
        >
          <Avatar alt="User" src={comment?.addedBy?.profileImage} sx={{ ml: 2, mr: 2 }} />
          <Stack spacing={2}>
            <Box sx={{ ml: 2 }} flexDirection="row" display="flex" alignItems="center">
              <Typography
                ml={2}
                sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
              >{`${comment?.addedBy?.firstName} ${comment?.addedBy?.lastName} `}</Typography>
              <Typography ml={3} sx={{ fontSize: '0.875rem' }}>
                {new Date(comment?.timestamp).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                )}{' '}
              </Typography>
              <Typography ml={3} sx={{ fontSize: '0.875rem', opacity: 0.6 }} color={COLORS.GREY}>
                {moment(comment?.timestamp).fromNow()}
              </Typography>
            </Box>
            <Box>
              {comment?.type === COMMENT_TYPES.TEXT && (
                <Typography ml={2} sx={{ fontWeight: 'bold' }}>
                  {comment?.message}
                </Typography>
              )}
              {comment?.type === COMMENT_TYPES.ATTACHMENT && (
                <Link
                  href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${comment?.message}`}
                  underline="hover"
                  sx={{
                    fontSize: '0.875rem',
                    ml: 1,
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    ml={2}
                    mb={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.2)',
                        transition: 'transform 0.2s',
                      },
                    }}
                    height={40}
                    width={40}
                  >
                    <FileIcon
                      extension={comment?.message?.split('.').pop()}
                      {...defaultStyles}
                      type="3d"
                    />
                  </Box>
                </Link>
              )}
            </Box>
          </Stack>
        </Box>
      </React.Fragment>
    ))
  );

export default CommentView;
