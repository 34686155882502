import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, IconButton, Typography, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { toast } from 'react-toastify';
import { ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
//
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { formattedString } from 'features/base/helpers/strings';
import TIME_OUTS from 'features/base/constants/time-outs';
import CustomNoResultsOverlay from 'features/base/components/no-results';
import { selectLoader, selectAllRoles } from 'features/roles/selectors';
import { PERMISSION_DOMAINS, PERMISSION_ACTIONS } from 'features/base/constants/permissions';
import { Button, SearchBar } from 'features/base/components';
import { roleActions } from 'features/roles/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { notificationActions } from 'features/base/notifications/slice';
import AddNewRolePopup from '../add-roles-modal';
import EditRolePopup from '../edit-role';

/**
 * Component that defines the entire roles view
 * @returns MUI Grid with the roles view
 */
const RolesView = () => {
  const dispatch = useDispatch();
  //
  const [searchData, setSearchData] = useState('');
  const roles = useSelector(selectAllRoles);
  const isLoading = useSelector(selectLoader);
  const notification = useSelector(selectNotification);
  //
  const permission = Object.keys(PERMISSION_DOMAINS);
  const permissionList = Object.keys(PERMISSION_ACTIONS);
  const permissionValues = Object.values(PERMISSION_ACTIONS);
  //
  const isFirst = useIsInitialize(roles);
  //
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [roleDetails, setRoleDetails] = useState({});
  //
  const searchRoles = () => {
    dispatch(
      roleActions.getAllRoles({
        query: `&search=${searchData}&sortBy=name:asc`,
      })
    );
  };
  //
  const debounced = useDebouncedCallback(searchRoles, TIME_OUTS.DEBOUNCE);
  //
  const handleOnClick = () => {
    setAddModalOpen(true);
  };
  //
  const handleEdit = (role) => {
    setRoleDetails(role);
    setEditModalOpen(true);
  };
  //
  const handleSearchOnChange = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  /*
  Return  icon for the specific permission
  */
  const returnIcon = (value, permissionValue) =>
    value?.includes(permissionValue) ? <CheckIcon color="success" /> : <ClearIcon color="error" />;
  //
  const setIcons = (role) => {
    const permissionObject = role?.permissions;
    return Object.values(PERMISSION_DOMAINS).map((key) => (
      <TableCell align="center" sx={{ minWidth: 100 }}>
        {permissionValues.map((permissionValue) => (
          <TableCell align="center" sx={{ minWidth: 100 }}>
            {returnIcon(permissionObject?.[key], permissionValue)}
          </TableCell>
        ))}
      </TableCell>
    ));
  };
  //
  useEffect(() => {
    dispatch(roleActions.getAllRoles({ query: 'sortBy=name:asc' }));
  }, []);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
        }}
      >
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ROLE,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <Grid item sx={{ width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' } }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: '1.938rem',
                fontWeight: 600,
                mr: '48px',
              }}
            >
              Role and Permission
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
              pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
            }}
          >
            <SearchBar
              placeholder="Search Role"
              value={searchData}
              handleSearchInput={handleSearchOnChange}
            />
          </Grid>
          <Grid item sx={{ pt: { xs: '20px', lg: '0', md: '20px', sm: '20px' } }}>
            <Button onClick={() => handleOnClick()} sx={{ width: { lg: 'auto', xs: '100%' } }}>
              Add Roles <AddIcon />
            </Button>
          </Grid>
        </PermissionWrapper>
      </Grid>
      <AddNewRolePopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditRolePopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        roleDetails={roleDetails}
      />
      <Box>
        {isFirst && isLoading ? (
          <>
            <ShimmerTable row={1} col={5} />
            <ShimmerTable row={5} col={14} />
          </>
        ) : (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: 0,
                      backgroundColor: 'white',
                      fontWeight: 600,
                    }}
                  >
                    Role Name
                  </TableCell>
                  {permission.map((value) => (
                    <TableCell align="center" sx={{ fontWeight: 600 }}>
                      {formattedString(value?.toUpperCase())}
                      <TableRow>
                        {permissionList.map((val) => (
                          <TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
                            {val}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableCell>
                  ))}
                  <PermissionWrapper
                    requiredPermissions={[
                      {
                        domain: PERMISSION_DOMAINS.ROLE,
                        action: PERMISSION_ACTIONS.UPDATE,
                      },
                    ]}
                    hide
                  >
                    <TableCell
                      style={{
                        position: 'sticky',
                        right: 0,
                        backgroundColor: 'white',
                        fontWeight: 600,
                      }}
                    >
                      Actions
                    </TableCell>
                  </PermissionWrapper>
                </TableRow>
              </TableHead>
              {(roles.length && (
                <TableBody>
                  {roles.map((role) => (
                    <TableRow>
                      <TableCell
                        style={{
                          position: 'sticky',
                          left: 0,
                          backgroundColor: 'white',
                          fontWeight: 600,
                        }}
                      >
                        {formattedString(role.name)}
                      </TableCell>
                      {setIcons(role)}
                      <TableCell style={{ position: 'sticky', right: 0, backgroundColor: 'white' }}>
                        <PermissionWrapper
                          requiredPermissions={[
                            {
                              domain: PERMISSION_DOMAINS.ROLE,
                              action: PERMISSION_ACTIONS.UPDATE,
                            },
                          ]}
                          hide
                        >
                          <IconButton
                            onClick={() => handleEdit(role)}
                            readOnly={isLoading}
                            sx={{ '&:hover': { color: 'blue' }, alignItems: 'center' }}
                          >
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </PermissionWrapper>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )) || (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <CustomNoResultsOverlay />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};
//
export default RolesView;
