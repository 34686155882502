/**
 * Available payment types
 * @type {{ HOURLY: string, MONTHLY: string, ONETIME:string }}
 */
export const PAYMENT_TYPE = {
  HOURLY: 'HOURLY',
  MONTHLY: 'MONTHLY',
  ONETIME: 'ONETIME',
};
/**
 *  Array for payment types
 */
export const PAYMENT_TYPE_ARRAY = [
  { key: 1, value: PAYMENT_TYPE.HOURLY },
  { key: 2, value: PAYMENT_TYPE.MONTHLY },
  { key: 3, value: PAYMENT_TYPE.ONETIME },
];
