import { TextField, Autocomplete as MuiAutocomplete, Typography, Box } from '@mui/material';
/**
 * Autocomplete input field component
 * @prop {String} id Component id
 * @prop {List[Object]} options List of available options
 * @prop {String} label Display label
 * @prop {Boolean} hide Indicates whether to hide the component
 * @prop {String} name Component name
 * @prop {Function} onChange Function to update the value of the component
 * @prop {Boolean} error Indicates whether a error is present
 * @prop {String} errorMsg Error message
 * @prop {String} defaultOption default/initial option
 * @prop {Boolean} controlled Indicates whether the component is controlled
 * @prop {String} value The rendered value (used when the component is controlled)
 * @prop {String} size Size of the component small | medium | large
 * @prop {Boolean} disableClearable Indicates whether the clearable option is disabled
 * @prop {Object} sx Custom styling
 * @prop {Function} isOptionEqualToValue Custom comparison function between options and values
 * @prop {Boolean} disabled Indicates whether to disable the component
 * @prop {String} disabledMsg - Description as to why the component is disabled
 * @prop {Function} onOpen - onOpen prop function
 * @prop {Function} onClose - onClose prop function
 * @prop {Function} getOptionDisabled - getOptionDisabled prop function
 */
const Autocomplete = ({
  id,
  options,
  label,
  hide,
  name,
  onChange,
  error,
  errorMsg,
  defaultOption,
  size = 'small',
  disableClearable,
  controlled,
  value,
  isOptionEqualToValue,
  disabled,
  disabledMsg,
  sx,
  onOpen,
  onClose,
  multiple,
  disableCloseOnSelect,
  getOptionLabel,
  renderOption,
  renderTags,
  clearOnBlur,
  inputValue,
  onInputChange,
  clearOnEscape,
  renderInput,
  placeholder,
  getOptionDisabled,
  readOnly,
}) => {
  if (hide) return null;
  return (
    <Box sx={sx}>
      <Typography fontSize="16px" className="field-label">
        {label}
      </Typography>
      {!controlled ? (
        <MuiAutocomplete
          id={id}
          name={name}
          options={options}
          autoComplete
          isOptionEqualToValue={
            isOptionEqualToValue || ((option, autoValue) => option.id === autoValue.id)
          }
          onChange={onChange}
          defaultValue={defaultOption || undefined}
          sx={sx}
          disableClearable={disableClearable}
          size={size}
          disabled={disabled}
          onOpen={onOpen}
          onClose={onClose}
          renderInput={
            renderInput ||
            ((params) => (
              <TextField
                size={size}
                fullWidth
                error={error && !disabled}
                {...params}
                sx={{
                  fieldset: {
                    borderRadius: '0px',
                  },
                }}
              />
            ))
          }
          multiple={multiple}
          disableCloseOnSelect={disableCloseOnSelect}
          renderOption={renderOption}
          renderTags={renderTags}
          clearOnBlur={clearOnBlur}
          getOptionLabel={getOptionLabel}
          inputValue={inputValue}
          onInputChange={onInputChange}
          clearOnEscape={clearOnEscape}
          getOptionDisabled={getOptionDisabled}
          readOnly={readOnly}
        />
      ) : (
        <MuiAutocomplete
          id={id}
          name={name}
          options={options}
          value={value || null}
          onChange={onChange}
          sx={sx}
          disableClearable={disableClearable}
          size={size}
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={disabled}
          onOpen={onOpen}
          onClose={onClose}
          renderInput={
            renderInput ||
            ((params) => (
              <TextField
                size={size}
                fullWidth
                error={error && !disabled}
                {...params}
                sx={{
                  fieldset: {
                    borderRadius: '0px',
                  },
                }}
                placeholder={placeholder}
              />
            ))
          }
          multiple={multiple}
          disableCloseOnSelect={disableCloseOnSelect}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderTags={renderTags}
          clearOnBlur={clearOnBlur}
          inputValue={inputValue}
          onInputChange={onInputChange}
          clearOnEscape={clearOnEscape}
          getOptionDisabled={getOptionDisabled}
          readOnly={readOnly}
        />
      )}
      {disabled && <p className="disabled-feedback">{disabledMsg}</p>}
      {error && !disabled && <p className="error-feedback">{errorMsg}</p>}
    </Box>
  );
};
//
export default Autocomplete;
