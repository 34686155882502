/**
 * Available types for Benefit Promotion Types
 * @type {{ PROMO_CODE: string, E_VOUCHER: string, NOT_APPLICABLE: string }}
 */
export const BENEFIT_PROMOTION_TYPES = {
  PROMO_CODE: 'PROMO_CODE',
  E_VOUCHER: 'E_VOUCHER',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};
/**
 * Available types for Benefit Promotion Code Types
 * @type {{ ONE_TIME: string, MULTIPLE_USE: string, NOT_APPLICABLE: string }}
 */
export const BENEFIT_PROMOTION_CODE_TYPES = {
  ONE_TIME: 'ONE_TIME',
  MULTIPLE_USE: 'MULTIPLE_USE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};
/**
 * Available types for Benefit campaign types
 * @type {{ PROMOTION_CAMPAIGN: string, NON_PROMOTION_CAMPAIGN: string }}
 */
export const BENEFIT_CAMPAIGN_TYPES = {
  PROMOTION_CAMPAIGN: 'PROMOTION_CAMPAIGN',
  NON_PROMOTION_CAMPAIGN: 'NON_PROMOTION_CAMPAIGN',
};