import { useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Container, Grid, Box, Divider } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerThumbnail, ShimmerCircularImage, ShimmerButton } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
//
import { projectActions } from 'features/projects/slice';
import {
  selectCreateTeamAvailableUsers,
  selectCreateTeamIsAvailableUsersFetching,
  selectCreateTeamIsProjectFetching,
  selectCreateTeamProject,
  selectIsTeamCreating,
  selectIsTeamCreatingSucceeded,
  selectSelectedTeamMembers,
} from 'features/projects/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { Button } from 'features/base/components';
import { notificationActions } from 'features/base/notifications/slice';
import { userActions } from 'features/users/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ROUTES from 'features/base/constants/routes';
import TIME_OUTS from 'features/base/constants/time-outs';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import createFormattedString from 'features/base/helpers/param-formatter';
import useOnPlusIconClick from './hooks/use-on-plus-icon-click';
import AvailableUserTableSection from './components/available-user-table-section';
import SelectedUserTableSection from './components/selected-user-table-section';
import DateRange from './components/date-range';
import SearchAndFilters from './components/search-and-filters';
import ProjectDetails from './components/project-details';
import AllocationStepper from '../stepper';
import useOnRemoveIconClick from './hooks/use-on-remove-icon-click';
import useOnAvailableUserCheckboxClick from './hooks/use-on-available-user-checkbox-click';
import useOnAddUsersClick from './hooks/use-on-add-users-click';
import useOnUpdateClick from './hooks/use-on-update-click';
import useSetPercentage from './hooks/use-set-percentage';
import useSetStartDate from './hooks/use-set-start-date';
import useSetEndDate from './hooks/use-set-end-date';
import useOnRemoveMultipleUsersClick from './hooks/use-on-multiple-remove-click';

/**
 * Defines the UI view for create team feature
 * @returns {Container}
 */
const CreateTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const filterContainerRef = useRef();
  //
  const project = useSelector(selectCreateTeamProject);
  const availableUsers = useSelector(selectCreateTeamAvailableUsers);
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  const isProjectLoading = useSelector(selectCreateTeamIsProjectFetching);
  const notification = useSelector(selectNotification);
  const isTeamCreating = useSelector(selectIsTeamCreating);
  const isTeamCreatingSucceeded = useSelector(selectIsTeamCreatingSucceeded);
  const isAvailableUserLoading = useSelector(selectCreateTeamIsAvailableUsersFetching);
  //
  const { onPlusIconClick } = useOnPlusIconClick();
  const { onRemoveIconClick } = useOnRemoveIconClick();
  const { onRemoveMultipleUsersClick } = useOnRemoveMultipleUsersClick();
  const { onAvailableUserCheckboxClick } = useOnAvailableUserCheckboxClick();
  const { onAddUsersClick } = useOnAddUsersClick();
  const { onUpdateClick } = useOnUpdateClick();
  const { setPercentage } = useSetPercentage();
  const { setStartDate } = useSetStartDate();
  const { setEndDate } = useSetEndDate();
  //
  const [searchData, setSearchData] = useState('');
  const [isAvailableUserMultipleSelectEnable, setIsAvailableUserMultipleSelectEnable] =
    useState(false);
  const [isSelectedUserMultipleSelectEnable, setIsSelectedUserMultipleSelectEnable] =
    useState(false);
  const [multipleAvailableUsers, setMultipleAvailableUsers] = useState([]);
  const [multipleSelectedUsers, setMultipleSelectedUsers] = useState([]);
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [departmentFilter, setDepartmentFilter] = useState('All');
  const [designationFilter, setDesignationFilter] = useState('All');
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'firstName:asc',
    rowsPerPage: 10,
  });
  //
  const params = useMemo(
    () => ({
      designation: designationFilter,
      department: departmentFilter,
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
      aggregated: true,
      runAggregation: true,
      projectId,
      utilizationStartDate: project?.startDate
        ? moment(project?.startDate).format('YYYY-MM-DD')
        : '',
      utilizationEndDate: project?.endDate ? moment(project?.endDate).format('YYYY-MM-DD') : '',
    }),
    [designationFilter, departmentFilter, pageController, project?.startDate, project?.endDate]
  );
  //
  useEffect(() => {
    if (project?.startDate && project?.endDate) {
      const formattedParamString = createFormattedString(
        params,
        '',
        'excluded=true&type=INTERNAL&'
      );
      dispatch(
        projectActions.getCreateTeamAvailableUsers({
          id: projectId,
          query: searchData.length
            ? `${formattedParamString}&search=${searchData}`
            : formattedParamString,
        })
      );
    }
  }, [params]);
  //
  useEffect(() => {
    dispatch(projectActions.getCreateTeamProject({ projectId }));
    dispatch(userActions.getDesignations({ query: `pagination=false` }));
    dispatch(userActions.getDepartments());
    dispatch(
      projectActions.getCreateTeamSelectedTeamMembers({
        query: `projectIds=${projectId}&pagination=false`,
      })
    );
  }, []);
  //
  useEffect(() => {
    if (
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      selectedUsers?.docs?.length > 0
    ) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    } else if (notification?.isEnabled && selectedUsers?.docs?.length <= 0) {
      toast('Please select at least one user.', { type: TOAST_TYPES.ERROR });
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isTeamCreating && isTeamCreatingSucceeded && selectedUsers?.docs?.length > 0) {
      dispatch(notificationActions.resetNotification());
      dispatch(projectActions.setIsTeamCreatingSucceeded());
      navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION_UPDATE, { projectId }));
    } else if (!isTeamCreating && isTeamCreatingSucceeded && selectedUsers?.docs?.length <= 0) {
      dispatch(notificationActions.resetNotification());
      dispatch(projectActions.setIsTeamCreatingSucceeded());
    }
  }, [isTeamCreating, isTeamCreatingSucceeded]);
  //
  useEffect(() => {
    if (!isAvailableUserLoading && !!availableUsers?.docs?.length) {
      const availableUsersCopy = availableUsers?.docs?.map((aUser) => {
        const found = selectedUsers?.docs?.find((sUser) => sUser?.userId?.id === aUser?.id);
        if (found) {
          if (!aUser?.disabled) {
            return {
              ...aUser,
              disabled: true,
            };
          }
        }
        return aUser;
      });
      dispatch(projectActions.setCreateTeamAvailableUsers(availableUsersCopy));
    }
  }, [isAvailableUserLoading]);
  //
  useEffect(
    () => () => {
      dispatch(userActions.onCreateTeamUnmount());
    },
    []
  );
  //
  const searchUsers = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchUsers, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  const onFilterIconClick = () => {
    if (filterContainerRef.current.style.display === 'none') {
      filterContainerRef.current.style.display = 'flex';
    } else {
      filterContainerRef.current.style.display = 'none';
    }
  };
  //
  const onSelectedUserCheckboxClick = (user) => {
    const selected = multipleSelectedUsers.find((mUser) => mUser?.userId?.id === user?.userId?.id);
    if (selected) {
      setMultipleSelectedUsers(multipleSelectedUsers.filter((mUser) => mUser?.userId?.id !== user?.userId?.id));
    } else {
      setMultipleSelectedUsers([...multipleSelectedUsers, user]);
    }
  };
  //
  const onAvailableUserBulkIconClick = () => {
    setIsAvailableUserMultipleSelectEnable(!isAvailableUserMultipleSelectEnable);
    setMultipleAvailableUsers([]);
  };
  //
  const onSelectUserBulkIconClick = () => {
    setIsSelectedUserMultipleSelectEnable(!isSelectedUserMultipleSelectEnable);
    setMultipleSelectedUsers([]);
  };
  //
  const handleSelectAll = (clicked) => {
    if (clicked) {
      const usersToBeChecked = selectedUsers?.docs?.filter((selectedUser) => !selectedUser?.isAllocatedPercentageLocked)
      setMultipleSelectedUsers(usersToBeChecked);
    } else {
      setMultipleSelectedUsers([]);
    }
    setIsSelectAllClicked(!isSelectAllClicked);
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Grid container>
        {isProjectLoading ? (
          <Grid item sx={{ width: '100%', mb: 4 }}>
            <ShimmerThumbnail height={75} />
          </Grid>
        ) : (
          <>
            <ProjectDetails />
            <AllocationStepper activeStep={0} />
          </>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          {isProjectLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ShimmerCircularImage size={48} />
              <Box sx={{ mr: { xs: 0, sm: '16px' } }} />
              <ShimmerThumbnail height={31} width={300} />
            </Box>
          ) : (
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.75rem',
                  fontWeight: 600,
                  mr: '32px',
                  ml: { xs: 0, sm: '16px' },
                  mt: { xs: '15px', md: 0 },
                }}
              >
                Create team
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ m: '28px 0' }}>
          <SearchAndFilters
            searchData={searchData}
            handleSearchInput={handleSearchInput}
            onFilterIconClick={onFilterIconClick}
            filterContainerRef={filterContainerRef}
            designationFilter={designationFilter}
            setDesignationFilter={setDesignationFilter}
            departmentFilter={departmentFilter}
            setDepartmentFilter={setDepartmentFilter}
            pageController={pageController}
            setPageController={setPageController}
          />
          <DateRange />
        </Grid>
        <Grid container columnSpacing={3} sx={{ justifyContent: 'space-evenly' }}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <AvailableUserTableSection
              onAvailableUserBulkIconClick={onAvailableUserBulkIconClick}
              availableUsers={availableUsers}
              isAvailableUserMultipleSelectEnable={isAvailableUserMultipleSelectEnable}
              onAvailableUserCheckboxClick={(user) => {
                onAvailableUserCheckboxClick(
                  user,
                  multipleAvailableUsers,
                  setMultipleAvailableUsers
                );
              }}
              isAvailableUserChecked={(id) =>
                multipleAvailableUsers.find((user) => user?.userId?.id === id)
              }
              onPlusIconClick={onPlusIconClick}
              multipleAvailableUsers={multipleAvailableUsers}
              onAddUsersClick={() => {
                onAddUsersClick(multipleAvailableUsers, onAvailableUserBulkIconClick);
              }}
              handleChangePage={(newPage) => {
                setPageController({ ...pageController, page: newPage + 1 });
              }}
              page={pageController.page - 1}
              totalPages={availableUsers?.totalPages ?? 0}
              totalDocs={availableUsers?.totalDocs ?? 0}
              limit={availableUsers?.limit ?? pageController.rowsPerPage}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ width: '40px', borderRightWidth: '5px', borderRightColor: COLORS.PRIMARY_MAIN }}
          />
          <Grid item xs={7} sm={8} md={8} lg={8} xl={8}>
            <SelectedUserTableSection
              isSelectedUserMultipleSelectEnable={isSelectedUserMultipleSelectEnable}
              isSelectAllClicked={isSelectAllClicked}
              onRemoveClick={() => {
                onRemoveMultipleUsersClick(multipleSelectedUsers, onSelectUserBulkIconClick);
              }}
              onSelectUserBulkIconClick={onSelectUserBulkIconClick}
              selectedUsers={selectedUsers}
              onSelectedUserCheckboxClick={onSelectedUserCheckboxClick}
              multipleSelectedUsers={multipleSelectedUsers}
              onRemoveIconClick={onRemoveIconClick}
              handleSelectAll={handleSelectAll}
              setStartDate={(userId, date) => {
                setStartDate(userId, date);
              }}
              setEndDate={(userId, date) => {
                setEndDate(userId, date);
              }}
              setPercentage={(userId, percentage) => {
                setPercentage(userId, percentage);
              }}
              projectStartDate={project?.startDate}
              projectEndDate={project?.endDate}
              setHasErrors={setHasErrors}
            />
            <Box sx={{ pt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              {isProjectLoading ? (
                <ShimmerButton size="md" />
              ) : (
                <Button
                  disabled={hasErrors || isTeamCreating}
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  onClick={onUpdateClick}
                  iconAlignment="right"
                  icon={isTeamCreating ? loaderIcon : null}
                >
                  Update team allocations
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
//
export default CreateTeam;
