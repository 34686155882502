import { DriveFileRenameOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
/**
 * Designation table columns - basic columns
 */
export const DESIGNATION_TABLE_BASIC_COLUMNS = [
  {
    field: 'name',
    headerName: 'Designation',
    width: 600,
  },
];
/**
 * Designation table columns - action column
 */
export const DESIGNATION_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: (params) => {
      const { organizationUpdatePermissionsVerified, isLoading, handleEdit } = params.row.action;
      return organizationUpdatePermissionsVerified ? (
        <IconButton readOnly={isLoading} onClick={handleEdit}>
          <DriveFileRenameOutline />
        </IconButton>
      ) : undefined;
    },
  },
];
