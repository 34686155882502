import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { CLIENTS_USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import {
  selectClient,
  selectLoader,
  selectProjectAllocationsList,
} from 'features/clients/selectors';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import CLIENT_PROJECTS_TABLE_COLUMNS_BASIC from 'features/base/utils/tables/client-table/client-project-table';
import { DataGrid } from 'features/base/components';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { notificationActions } from 'features/base/notifications/slice';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { ENVIRONMENT } from 'config';

/**
 * Component that defines the client view
 * @returns MUI Grid with the client view
 */
const ClientProjectsTable = () => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const client = useSelector(selectClient);
  const notification = useSelector(selectNotification);
  const projectAllocationsList = useSelector(selectProjectAllocationsList);
  //
  const isFirst = useIsInitialize(client);
  //
  const [confirmationModalOpen, setConfirmationModalOpen] = useState();
  const [projectsList, setProjectsList] = useState([]);
  //
  useEffect(() => {
    if (
      confirmationModalOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      setConfirmationModalOpen(false);
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);

  useEffect(() => {
    const result = {};
    projectAllocationsList?.forEach((item) => {
      item.monthlyAllocations?.forEach((allocation) => {
        if (result[item.projectId.id]) {
          result[item.projectId.id].revenue += allocation.revenue;
          if (allocation.cogsoh != null) {
            result[item.projectId.id].cogsoh += allocation.cogsoh;
          }
        } else {
          // if client's projects is/are not active, then don't show it in the table
          if (
            client?.projects?.find((prj) => prj.projectId.id === item.projectId.id)?.dataStatus !==
            DATA_STATUS_TYPES.ACTIVE
          ) {
            return;
          }
          result[item.projectId.id] = {};
          result[item.projectId.id].revenue = allocation.revenue;
          result[item.projectId.id].name = item.projectId.name;
          if (allocation.cogsoh != null) {
            result[item.projectId.id].cogsoh = allocation.cogsoh;
          } else {
            result[item.projectId.id].cogsoh = 0;
          }
        }
      });
    });
    /* If result is missing a project (means have zero allocation records) 
    that are in client.projects, then add it to result with 0 revenue and cogsoh
    */
    client?.projects?.forEach((prj) => {
      if (prj.dataStatus !== DATA_STATUS_TYPES.ACTIVE) {
        return;
      }
      if (!result[prj.projectId.id]) {
        result[prj.projectId.id] = {
          name: `${prj.projectId.name}`,
          revenue: 0,
          cogsoh: 0,
        };
      }
    });

    setProjectsList(
      Object.entries(result).map(([id, { name, revenue, cogsoh }]) => ({
        project: {
          name,
          logo: (() => {
            const logo = client?.projects?.find((prj) => prj.projectId.name === name)?.projectId
              ?.logo?.[0];
            if (logo?.url) {
              return `${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${logo.url}`;
            }
            return null;
          })(),
        },
        revenue: {
          value: revenue ? getDollarFormattedValue(revenue) : '-',
        },
        cost: {
          value: cogsoh ? getDollarFormattedValue(cogsoh) : '-',
        },
        margin: {
          value: (() => {
            if (revenue && cogsoh) {
              return getDollarFormattedValue(revenue - cogsoh);
            }
            return '-';
          })(),
        },
        id: `${id}-${name}`,
      }))
    );
  }, [projectAllocationsList, client]);
  return (
    <Box sx={{ width: '100%' }}>
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        {isFirst ? (
          <ShimmerTable row={4} col={6} />
        ) : (
          <DataGrid
            columns={CLIENT_PROJECTS_TABLE_COLUMNS_BASIC}
            rows={projectsList || []}
            loading={loading && isFirst !== true}
            disableRowSelectionOnClick
            limit={CLIENTS_USERS_PAGINATION_LIMIT}
            pageSizeOptions={[CLIENTS_USERS_PAGINATION_LIMIT]}
            serverSidePagination={false}
            rowHeight={projectsList?.length ? 52 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default ClientProjectsTable;
