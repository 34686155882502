import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, CircularProgress } from '@mui/material';
//
import useIsAuthenticated from 'features/base/hooks/use-is-authenticated';
import { selectUserEmail, selectOnboarding } from 'features/base/auth/selectors';
import { Button, CommonBackground } from 'features/base/components';
import EMAIL_SUFFIXES from 'features/base/constants/email-suffixes';
import ROUTES from 'features/base/constants/routes';
import { getRefreshToken } from 'features/base/helpers/token';
import { signInActions } from 'features/sign-in/slice';
import { authenticationUrl } from 'features/base/constants/authentication';
import { generateCodeChallenge, generateCodeVerifier } from 'features/base/helpers/pkce';
import { selectSignInErrorCode, selectLoader } from 'features/sign-in/selectors';
import { ERROR_CODES } from 'features/base/constants/errors';
import COLORS from 'features/base/constants/colors';
/**
 * Welcome page component for the Surge Hub application.
 */
const WelcomePage = () => {
  const { isAuthenticated } = useIsAuthenticated();
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const email = useSelector(selectUserEmail);
  const onboarding = useSelector(selectOnboarding);
  const errorCode = useSelector(selectSignInErrorCode);
  const loader = useSelector(selectLoader);
  //
  const refreshToken = getRefreshToken();
  const codeVerifier = generateCodeVerifier();
  //
  const openAuthWindow = async () => {
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const authUrlWithChallenge = `${authenticationUrl}&code_challenge=${codeChallenge}`;
    dispatch(signInActions.openOAuthWindow({ authenticationUrl: authUrlWithChallenge }));
  };
  //
  const receiveMessage = (event) => {
    if (event?.data?.code) {
      dispatch(signInActions.signIn({ code: event?.data?.code, codeVerifier }));
    }
    if (event?.data?.error) {
      navigate(ROUTES.NOT_AUTHORIZED);
    }
  };
  //
  useEffect(() => {
    if (isAuthenticated(refreshToken)) {
      if (email?.endsWith(EMAIL_SUFFIXES.INTERNAL)) {
        if (onboarding === false) {
          navigate(ROUTES.INTERNAL_ONBOARD);
        } else if (onboarding === true) {
          navigate(ROUTES.LINK_HUB);
        }
      } else {
        navigate(ROUTES.SUPPORT_TICKETS);
      }
    } else if (errorCode?.toString() === ERROR_CODES.UNAUTHORIZED) {
      navigate(ROUTES.NOT_AUTHORIZED);
    }
  }, [isAuthenticated, onboarding, email, errorCode]);
  //
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage, false);
  }, []);
  //
  return (
    <CommonBackground>
      <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              color: COLORS.WHITE,
              fontWeight: '600',
              fontSize: { xs: '2.5rem', sm: '3.2rem', md: '5rem' },
              mr: { xs: '8px', sm: '13px', md: '20px' },
            }}
          >
            Welcome to
          </Typography>
          <Typography
            sx={{
              background: `transparent linear-gradient(259deg, ${COLORS.SECONDARY_MAIN} 0%,${COLORS.ELECTRIC_BLUE} 60%) 50% 50% no-repeat padding-box;`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: '600',
              fontSize: { xs: '2.5rem', sm: '3.2rem', md: '5rem' },
            }}
          >
            Surge Hub
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: 20,
            color: 'white',
            mt: 4,
            mb: 4,
          }}
        >
          Surge Hub lets you report your support queries directly to our Product Managers
        </Typography>
        {loader ? (
          <CircularProgress />
        ) : (
          <Button
            style={{ background: '#FF3841' }}
            sx={{
              mt: 7,
              mb: 7,
              textTransform: 'none',
              alignItems: 'center',
              width: 150,
              padding: 1,
              margin: 2,
              ':hover': {
                background: '#FF5252 0% 0% no-repeat padding-box !important',
              },
            }}
            type="submit"
            pattern="primary"
            onClick={openAuthWindow}
          >
            Login
          </Button>
        )}
      </Box>
    </CommonBackground>
  );
};
//
export default WelcomePage;
