/**
 * Defines the API paths for authentication
 */
const API = {
  POST_REFRESH_TOKENS: {
    path: '/auth/refresh-tokens',
    method: 'POST',
  },
};
//
export default API;
