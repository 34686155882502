import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Drawer } from 'features/base/components';
import EditArsenalForm from '../edit-arsenal-item-form';

/**
 * Function that defines the drawer for editing arsenal items
 * @prop {Boolean} open - Boolean to show/hide the drawer
 * @prop {Function} setOpen - Function to set the drawer state
 * @prop {Object} arsenalItemToBeEdited - Arsenal item to be edited
 * @returns {Popup}
 */
const EditArsenalItemDrawer = ({ open, setOpen, arsenalItemToBeEdited }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (open && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ARSENAL,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Drawer open={open} onClose={handleOnClose} title="Edit item">
        <EditArsenalForm arsenalItemToBeEdited={arsenalItemToBeEdited} handleOnClose={handleOnClose} />
      </Drawer>
    </PermissionWrapper>
  );
};
//
export default EditArsenalItemDrawer;
