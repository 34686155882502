/* eslint-disable no-unused-expressions */
import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import * as moment from 'moment';
//
import { notificationActions } from 'features/base/notifications/slice';
import { reportActions } from 'features/reports/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { DataGrid, Popup, Button } from 'features/base/components';
import { StyledStepIcon } from 'features/projects/components/style';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { MONTHS, ISO_WITHOUT_TIME, DATE_FORMAT } from 'features/base/constants/date-formatting';
import { createTeamPercentagePattern } from 'features/base/constants/regex';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { REALLOCATION_TEAM_MEMBER_HEADERS } from 'features/base/utils/tables';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import calculations from 'features/base/helpers/calculations';
import { getWorkingDaysByMonth } from 'features/projects/components/update-work-allocations/helpers/allocation-dates';
import {
  selectToBeReallocatedAllocation,
  selectToBeReallocatedAllocationLoading,
  selectReallocationTableAllocations,
  selectReallocationTableAllocationsLoading,
  selectAllocationReportPatchAllocationsLoading,
} from 'features/reports/selectors';
import UpdateWorkAllocationsTable from './components/update-hours';
/**
 * Function that defines the popup form for re-allocation of hours for a project
 * @prop {boolean} reallocateModalOpen - boolean to show/hide the popup
 * @prop {function} setReallocateModalOpen - function to set the popup state
 * @returns {Popup} Popup component
 */
const ReallocateHoursPopup = ({
  reallocateModalOpen,
  setReallocateModalOpen,
  selectedAllocation,
}) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectToBeReallocatedAllocationLoading);
  const toBeReallocatedAllocation = useSelector(selectToBeReallocatedAllocation);
  const reallocationTableAllocations = useSelector(selectReallocationTableAllocations);
  const reallocationTableAllocationsLoading = useSelector(
    selectReallocationTableAllocationsLoading
  );
  const isSubmitting = useSelector(selectAllocationReportPatchAllocationsLoading);
  //
  const [endDate, setEndDate] = useState(new Date());
  const [percentage, setPercentage] = useState('0');
  const [mainProjectPeriod, setMainProjectPeriod] = useState([]);
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [rows, setRows] = useState([]);
  //
  const steps = ['Reallocate User', 'Update Hours'];
  //
  const handleOnClose = () => {
    setReallocateModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const getPercentageError = (input) => {
    if (!createTeamPercentagePattern.test(input) || input % 5 !== 0) {
      return 'Should be a multiple of 5';
    }
    return '';
  };
  //
  const getIsPercentageErrorExist = (input) =>
    !createTeamPercentagePattern.test(input) || input % 5 !== 0;
  //
  const getLastRatePerHour = () => {
    const { hourlyRate } = toBeReallocatedAllocation?.allocation?.[0] ?? {};
    return hourlyRate;
  };
  //
  const handleSave = () => {
    if (endDate && percentage && toBeReallocatedAllocation?.startDate) {
      const startDateMoment = moment(
        `${selectedAllocation.month} ${selectedAllocation.year}`,
        'M YYYY'
      );
      const endDateMoment = moment(endDate);
      const newArray = [];
      while (startDateMoment.isSameOrBefore(endDateMoment)) {
        newArray.push({
          year: startDateMoment.format('YYYY'),
          month: startDateMoment.format('MMMM'),
          hours: (toBeReallocatedAllocation.userId.capacity * percentage) / 100,
          // only add hourlyRate if calculations.hasHourlyRate(selectedAllocation?.projectId?.billingType) is true
          ...(calculations.hasHourlyRate(selectedAllocation.projectId.billingType) && {
            hourlyRate: getLastRatePerHour(),
          }),
        });
        startDateMoment.add(1, 'months');
      }
      dispatch(
        reportActions.patchAllocationReportAllocation({
          allocation: [...toBeReallocatedAllocation.allocation, ...newArray],
          isAllocatedPercentageLocked: true,
          allocationId: toBeReallocatedAllocation.id,
          endDate: moment(endDate).format(ISO_WITHOUT_TIME),
        })
      );
    }
  };
  //
  const computeTableRowsAndPercentage = () => {
    /* 
    returns an object with the following structure
    {
      rows: [
        { id: 1, ProjectName: 'Project 1', January: '30', February: '30', March: '30', April: '30', May: '30', June: '30' },
      ],
      percentage: 80, // percentage of total hours allocated
      available: 20 // percentage of total hours available
    }
    */
    const totalHours = {};
    const trows = reallocationTableAllocations?.docs?.map((entity) => {
      const monthlyAllocationLookup = {};
      entity?.monthlyAllocations?.forEach((monthlyAllocation) => {
        monthlyAllocationLookup[
          `${MONTHS[monthlyAllocation.month - 1]}${monthlyAllocation?.year}`
        ] = monthlyAllocation;
      });
      const row = {
        id: entity.id,
        // bold the project name if it is the main project
        ProjectName: {
          projectId: entity?.projectId?.id,
          value: entity?.projectId?.name,
        },
      };
      mainProjectPeriod?.forEach((period) => {
        const { year, month } = period;
        const monthlyAllocation = monthlyAllocationLookup[`${month}${year}`];
        totalHours[`${month}${year}`] = {
          id: 'totalPerMonth',
          allocationHours:
            (totalHours[`${month}${year}`]?.allocationHours ?? 0) +
            (monthlyAllocation?.allocatedHours ?? 0),
        };
        //
        if (monthlyAllocation) {
          if (entity?.projectId?.id === selectedAllocation.projectId.id) {
            const workingDaysByMonth = getWorkingDaysByMonth(
              entity?.allocationStartDate,
              entity?.allocationEndDate
            );
            row[`${month}${year}`] = {
              id: 'currentProject',
              allocationHours: monthlyAllocation?.allocatedHours,
              onChange: (e) => {
                const { value } = e.target;
                // Get entity.allocation object array and modify the hours value of the object that matches the year and month
                const updatedMonthlyAllocations = entity?.monthlyAllocations?.map(
                  (workAllocation) => {
                    /** If the project is a retainer, staff augmentation or support and maintenance project,
                     * hours entered for each month will be reflected to all months
                     * */
                    if (
                      selectedAllocation.projectId.billingType === PROJECT_BILLING_TYPES.RETAINER ||
                      selectedAllocation.projectId.billingType ===
                        PROJECT_BILLING_TYPES.STAFF_AUGMENTATION ||
                      selectedAllocation.projectId.billingType ===
                        PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE
                    ) {
                      return {
                        ...workAllocation,
                        allocatedHours: Number.isNaN(parseInt(value, 10))
                          ? ''
                          : parseInt(value, 10),
                      };
                    }
                    // If the project is a fixed bid or time and material project, allow the user to enter hours for each month
                    if (
                      workAllocation.year.toString() === year &&
                      MONTHS[workAllocation.month - 1] === month
                    ) {
                      return {
                        ...workAllocation,
                        allocatedHours: Number.isNaN(parseInt(value, 10))
                          ? ''
                          : parseInt(value, 10),
                      };
                    }
                    return workAllocation;
                  }
                );
                dispatch(
                  reportActions.patchReallocationTableAllocationsInStore({
                    projectId: selectedAllocation.projectId.id,
                    userId: entity?.userId?.id,
                    updatedMonthlyAllocations,
                  })
                );
                // Ensure that the total hours entered divisible by 8 and at least one month has greater than 0 hours
                const isDivisibleByEight = updatedMonthlyAllocations?.some(
                  (workAllocation) =>
                    workAllocation.allocatedHours % 8 !== 0 || workAllocation.allocatedHours === ''
                );
                const everyMonthEqualToZero = updatedMonthlyAllocations?.every(
                  (workAllocation) => workAllocation.allocatedHours === 0
                );
                const hasNegatives = updatedMonthlyAllocations?.some(
                  (workAllocation) => workAllocation.allocatedHours < 0
                );
                const err = [];
                isDivisibleByEight && err.push('Hours must be divisible by 8');
                everyMonthEqualToZero &&
                  err.push('At least one month must have greater than 0 hours');
                hasNegatives &&
                  err.push('Hours must be greater than 0. Negative values are not allowed');
                setErrors(err);
                setHasUnsavedChanges(true);
              },
              availability: (
                workingDaysByMonth[`${month}${year}`] *
                // Divide by 4 since to get weekly capacity, then divide by 5 (working days per week) to get daily capacity
                ((selectedAllocation.userId.capacity ?? 160) / 4 / 5)
              ).toFixed(2),
            };
          } else {
            row[`${month}${year}`] = {
              allocationHours: monthlyAllocation?.allocatedHours,
            };
          }
        } else {
          row[`${month}${year}`] = {
            allocationHours: '-',
          };
        }
      });
      return row;
    });
    // Finally add the total hours row at the bottom
    trows.push({
      id: 'total',
      ProjectName: { value: 'Total Hours' },
      ...totalHours,
    });
    const ret = {
      rows: trows,
      percentage:
        ((Object.values(totalHours)?.reduce(
          (acc, value) => acc + (value?.allocationHours ?? 0),
          0
        ) || 0) /
          ((selectedAllocation.userId.capacity || 160) * (mainProjectPeriod?.length || 1))) *
        100,
    };
    return ret;
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      if (step === 0) {
        setStep(1);
      } else if (step === 1) {
        handleOnClose();
      }
    }
  }, [notification]);
  //
  useEffect(() => {
    if (reallocateModalOpen) {
      dispatch(reportActions.getAllocationToBeReallocated({ allocationId: selectedAllocation.id }));
    } else {
      dispatch(reportActions.resetAllocationToBeReallocated());
    }
  }, [reallocateModalOpen]);
  //
  useEffect(() => {
    if (toBeReallocatedAllocation?.id && reallocateModalOpen && selectedAllocation?.projectId) {
      setEndDate(selectedAllocation?.projectId?.endDate);
      setPercentage(toBeReallocatedAllocation?.allocatedPercentage);
    }
  }, [toBeReallocatedAllocation, reallocateModalOpen, selectedAllocation]);
  //
  useEffect(() => {
    if (
      toBeReallocatedAllocation.projectId?.startDate &&
      toBeReallocatedAllocation.projectId?.endDate
    ) {
      const start = moment(toBeReallocatedAllocation.projectId.startDate);
      const end = moment(toBeReallocatedAllocation.projectId.endDate);
      const months = [];
      while (start.isSameOrBefore(end, 'month')) {
        months.push({
          year: start.format(`YYYY`),
          month: start.format(`MMMM`),
        });
        start.add(1, 'months');
      }
      setMainProjectPeriod(months);
    }
  }, [toBeReallocatedAllocation.projectId]);
  //
  useEffect(() => {
    if (
      reallocationTableAllocations?.docs?.length > 0 &&
      mainProjectPeriod?.length > 0 &&
      reallocateModalOpen
    ) {
      setRows(computeTableRowsAndPercentage().rows);
    }
  }, [reallocationTableAllocations, reallocationTableAllocationsLoading, mainProjectPeriod]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      {reallocateModalOpen && (
        <Popup
          open={reallocateModalOpen}
          onClose={handleOnClose}
          title={`Reallocate Hours for ${selectedAllocation?.userId?.firstName} ${selectedAllocation?.userId?.lastName}`}
          fullWidth
          maxWidth="xl"
          height={'70vh'}
        >
          <Box sx={{ width: '100%' }} mb={4} mt={4}>
            <Stepper activeStep={step} alternativeLabel sx={{ backgroundColor: 'transparent' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StyledStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {step === 1 && (
            <Box sx={{ textAlign: 'left' }}>
              <p> Step 1</p>
              <UpdateWorkAllocationsTable
                selectedUser={toBeReallocatedAllocation.userId}
                mainProjectPeriod={mainProjectPeriod}
                rows={Array.isArray(rows) ? rows : []}
                setRows={setRows}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                errors={errors}
                tableLoading={reallocationTableAllocationsLoading}
                projectDetails={toBeReallocatedAllocation.projectId}
                allocationId={toBeReallocatedAllocation?.id}
                setReallocateModalOpen={setReallocateModalOpen}
              />
            </Box>
          )}
          {step === 0 && (
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                {' '}
                Step 2
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {/* ${user} already has an allocation from ${startDate} to ${endDate} and this step will extend the allocation to ${newEndDate} */}
                {toBeReallocatedAllocation?.userId?.firstName}{' '}
                {toBeReallocatedAllocation?.userId?.lastName} already has an allocation from{' '}
                {new Date(toBeReallocatedAllocation?.startDate).toLocaleString(
                  DATE_FORMAT.LANGUAGE,
                  DATE_FORMAT
                )}{' '}
                to{' '}
                {new Date(toBeReallocatedAllocation?.endDate).toLocaleString(
                  DATE_FORMAT.LANGUAGE,
                  DATE_FORMAT
                )}{' '}
                and this step will extend the allocation to{' '}
                {new Date(endDate).toLocaleString(DATE_FORMAT.LANGUAGE, DATE_FORMAT)} {'.'}
              </Typography>
              <DataGrid
                rowHeight={50}
                columns={REALLOCATION_TEAM_MEMBER_HEADERS}
                limit={10}
                loading={loading}
                rows={
                  toBeReallocatedAllocation.id
                    ? [
                        {
                          id: toBeReallocatedAllocation.id,
                          icon: {
                            user: {
                              isAllocatedPercentageLocked: true,
                            },
                          },
                          profile: {
                            value: `${toBeReallocatedAllocation.userId?.firstName} ${toBeReallocatedAllocation.userId?.lastName}`,
                            profileImage: toBeReallocatedAllocation?.userId?.profileImage,
                            designation: toBeReallocatedAllocation.userId
                              ?.currentUserDepartmentDesignationId?.departmentDesignationId
                              ?.designationId?.name
                              ? toBeReallocatedAllocation.userId?.currentUserDepartmentDesignationId
                                  ?.departmentDesignationId?.designationId?.name
                              : '-',
                          },
                          startDate: {
                            startDate: moment(
                              `${selectedAllocation.month} ${selectedAllocation.year}`,
                              'M YYYY'
                            ),
                          },
                          endDate: {
                            value: endDate,
                            minDate: moment(
                              `${selectedAllocation.month} ${selectedAllocation.year}`,
                              'M YYYY'
                            ),
                            maxDate: selectedAllocation?.projectId?.endDate,
                            onChange: (event) => {
                              setEndDate(event?.$d);
                            },
                          },
                          allocation: {
                            value: percentage,
                            isAllocatedPercentageLocked:
                              toBeReallocatedAllocation?.isAllocatedPercentageLocked,
                            onChange: (event) => {
                              setPercentage(event?.target?.value);
                            },
                            isDisabled: [
                              PROJECT_BILLING_TYPES.RETAINER,
                              PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE,
                              PROJECT_BILLING_TYPES.STAFF_AUGMENTATION,
                            ].includes(selectedAllocation.projectId.billingType),
                            helperText: getPercentageError(percentage),
                            error: getIsPercentageErrorExist(percentage),
                          },
                        },
                      ]
                    : []
                }
                cellSx={{
                  pt: 1,
                  pb: 1,
                }}
                serverSidePagination={false}
              />
              {/*  next button */}
              <Box sx={{ textAlign: 'right', mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || loading}
                  onClick={handleSave}
                  loading={isSubmitting}
                  icon={isSubmitting ? loaderIcon : null}
                  iconAlignment={isSubmitting ? 'left' : null}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
        </Popup>
      )}
    </PermissionWrapper>
  );
};
//
export default ReallocateHoursPopup;
