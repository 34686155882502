import commentSendIcon from 'features/base/assets/images/svg/comment-send.svg';
import commentAttachmentIcon from 'features/base/assets/images/svg/comment-attachment.svg';
import { Avatar, Box, TextField, IconButton } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
//
import COLORS from 'features/base/constants/colors';
import { SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES } from 'features/base/constants/file-upload';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

//
const CommentBox = ({
  commentLoading,
  isCommentAdding,
  isCommentAttachmentAdding,
  newComment,
  onCommentSendClick,
  setNewComment,
  setAttachmentFile,
  authUser,
  isFirst,
}) =>
  isFirst && commentLoading ? (
    <Skeleton height={40} width={'100%'} style={{ marginTop: '1rem', marginBottom: '1rem' }} />
  ) : (
    <Box display="flex" alignItems="center" padding={1} mt={3} bgcolor={COLORS.SKY_BLUE}>
      <Avatar alt="User" src={authUser?.profileImage} />
      <TextField
        variant="outlined"
        placeholder="Add a comment"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        fullWidth
        sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}
        size="small"
        multiline
        minRows={1}
        maxRows={6}
      />

      <label htmlFor="upload-input">
        <Box margin="auto" sx={{ pr: 1, pl: 2 }}>
          <img src={commentAttachmentIcon} alt="commentIcon" className="commentIcon" />
        </Box>
      </label>
      <input
        accept={SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES.join(', ')}
        style={{ display: 'none' }}
        id="upload-input"
        multiple
        type="file"
        onChange={(e) => setAttachmentFile(e.target.files)}
      />
      {isCommentAdding || isCommentAttachmentAdding ? (
        <img
          src={loaderIcon}
          alt="loaderIcon"
          style={{ height: '1.2rem', width: '1.2rem', marginLeft: '1rem' }}
        />
      ) : (
        <IconButton color="primary" aria-label="comment-send" onClick={onCommentSendClick}>
          <img src={commentSendIcon} alt="commentIcon" />
        </IconButton>
      )}
    </Box>
  );

export default CommentBox;
