import { PROJECT_BILLING_TYPES } from '../constants/project-billing-types';
/**
 * @description Formats the billing type to be displayed in the UI
 * @summary
 * Checks a given billing type value and converts and returns it in requested format
 * FIXED_BID -> FIXED BID
 * TIME_AND_MATERIAL -> TIME AND MATERIAL
 * RETAINER_FIXED_MONTHLY -> FIXED MONTHLY - RETAINER
 * SUPPORT_AND_MAINTENANCE_MONTHLY -> FIXED MONTHLY - SUPPORT AND MAINTENANCE
 * STAFF_AUGMENTATION_MONTHLY -> FIXED MONTHLY - STAFF AUGMENTATION
 * @param {string} value
 * @returns {string}
 */
const billingTypeFormatter = (value) => {
  if (!value) return '';
  //
  if (typeof value === 'string') {
    const billingType = value.replace('_', ' ');
    switch (value) {
      case PROJECT_BILLING_TYPES.FIXED_BID:
        return `${billingType}`;
      case PROJECT_BILLING_TYPES.TIME_AND_MATERIAL:
        return `${billingType.replace('_', ' ')}`;
      case PROJECT_BILLING_TYPES.RETAINER:
        return `${billingType.replace('_', ' - ')}`;
      case PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE:
        return `${billingType.replace('_', ' - ').replace('_', ' ').replace('_', ' ')}`;
      case PROJECT_BILLING_TYPES.STAFF_AUGMENTATION:
        return `${billingType.replace('_', ' - ').replace('_', ' ')}`;
      default:
        return '';
    }
  }
  //
  return '';
};
//
export default billingTypeFormatter;
