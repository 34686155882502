import { Button as MUIButton } from '@mui/material';
import PropTypes from 'prop-types';
//
import COLORS from 'features/base/constants/colors';
import styles from './styles';
/**
 * Defines a common button component
 * @param {ReactNode} children
 * @param {String} size
 * @param {String} pattern
 * @param {Object} style
 * @param {String} icon
 * @param {String} iconAlignment
 * @param {Object} sx
 * @returns {MUIButton}
 */
const Button = ({ children, size, pattern, style, icon, iconAlignment, sx, disabled, ...rest }) => {
  /**
   * Set the button size according to the size prop
   * @param {string} size
   * @returns {Object} style object
   */
  const getSize = () => {
    switch (size) {
      case 'small':
        return {
          height: '2.5rem',
        };
      case 'medium':
        return {
          height: '2.75rem',
        };
      case 'large':
        return {
          height: '3.5rem',
        };
      default:
        return {};
    }
  };
  /**
   * Set the button color according to the pattern prop
   * @param {string} pattern
   * @returns {Object} style object
   */
  const getColor = () => {
    switch (pattern) {
      case 'primary':
        return {
          background: `transparent linear-gradient(256deg, ${COLORS.ELECTRIC_BLUE} 0%, ${COLORS.PRIMARY_MAIN} 100%) 0% 0% no-repeat padding-box`,
        };
      case 'secondary':
        return {
          border: `0.063rem solid  ${COLORS.PRIMARY_MAIN}`,
        };
      default:
        return {};
    }
  };
  /**
   * Set the button hover styles according to the pattern prop
   * @param {string} pattern
   * @returns {Object} style object
   */
  const getHoverStyles = () => {
    switch (pattern) {
      case 'primary':
        return {
          background: `transparent linear-gradient(254deg, ${COLORS.ROYAL_BLUE} 0%, ${COLORS.DEEP_PURPLE} 100%) 0% 0% no-repeat padding-box !important`,
        };
      case 'secondary':
        return {
          background: `${COLORS.LAVENDER_SHADE} 0% 0% no-repeat padding-box !important`,
          boxShadow: `4px 16px 16px ${COLORS.PALE_LILAC} !important`,
        };
      default:
        return {};
    }
  };
  /**
   * Set the button active styles according to the pattern prop
   * @param {string} pattern
   * @returns {Object} style object
   */
  const getActiveStyles = () => {
    switch (pattern) {
      case 'primary':
        return {
          background: `${COLORS.NAVY_BLUE} 0% 0% no-repeat padding-box !important`,
        };
      case 'secondary':
        return {
          background: `${COLORS.PRIMARY_MAIN}  0% 0% no-repeat padding-box !important`,
          boxShadow: `4px 16px 16px ${COLORS.PALE_LILAC} !important`,
          color: COLORS.WHITE,
        };
      default:
        return {};
    }
  };
  /**
   * Set the button disable styles according to the pattern prop
   * @param {string} pattern
   * @returns {Object} style object
   */
  const getDisableStyles = () => {
    switch (pattern) {
      case 'primary':
        return {
          background: `transparent linear-gradient(251deg, ${COLORS.PALE_BLUE} 0%, ${COLORS.LILAC} 100%) 0% 0% no-repeat padding-box !important`,
          color: COLORS.WHITE,
        };
      case 'secondary':
        return {
          background: `${COLORS.LAVENDER_MIST} 0% 0% no-repeat padding-box !important`,
          color: COLORS.SILVER_LILAC,
          border: 'none !important',
        };
      default:
        return {};
    }
  };
  /**
   * Set the button's MUI variant according to the pattern prop
   * @param {string} pattern
   * @returns {string} MUI button variant type
   */
  const getVariant = () => {
    switch (pattern) {
      case 'primary':
        return 'contained';
      case 'secondary':
        return 'outlined';
      default:
        return {};
    }
  };
  /**
   * Set the button's text and icon alignments according to the iconAlignment prop
   * @param {string} iconAlignment
   * @returns {Object} style object
   */
  const getIconAlignment = () => {
    switch (iconAlignment) {
      case 'left':
        return {
          flexDirection: 'row-reverse',
        };
      case 'right':
        return {
          flexDirection: 'row',
        };
      default:
        return {};
    }
  };
  /**
   * Set the button's icon margin according to the iconAlignment prop
   * @param {string} iconAlignment
   * @returns {Object} style object
   */
  const getIconStyle = () => {
    switch (iconAlignment) {
      case 'left':
        return {
          marginRight: '0.5rem',
        };
      case 'right':
        return {
          marginLeft: '0.5rem',
        };
      default:
        return {};
    }
  };
  //
  return (
    <MUIButton
      variant={getVariant()}
      style={{
        ...getSize(),
        ...getColor(),
        ...getIconAlignment(),
        ...styles.button,
        ...style,
      }}
      disabled={disabled}
      sx={{
        ':hover': {
          ...getHoverStyles(),
        },
        ':active': {
          ...getActiveStyles(),
        },
        ':disabled': {
          ...getDisableStyles(),
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
      {icon && <img src={icon} alt="button-icon" style={{ ...getIconStyle() }} />}
    </MUIButton>
  );
};
//
Button.defaultProps = {
  size: 'small',
  pattern: 'primary',
  iconAlignment: 'left',
  children: 'submit',
  icon: null,
};
//
Button.propTypes = {
  size: PropTypes.string,
  pattern: PropTypes.string,
  iconAlignment: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
//
export default Button;
