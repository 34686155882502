import { Box, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
//
import TextField from 'features/base/components/text-field';
import CURRENCY_RANGE from 'features/base/constants/currency-range';
/**
 * @prop {String} type MUI Textfield type prop
 * @prop {String} name MUI Textfield name prop
 * @prop {String} value MUI Textfield value prop
 * @prop {String} error MUI Textfield error prop
 * @prop {String} helperText MUI Textfield helper text prop
 * @prop {Function} onBlur MUI Textfield onBlur function
 * @prop {Function} onChange MUI Textfield onChange function
 * @prop {String} label Textfield label
 * @prop {Boolean} hide Boolean value that hides the component if true
 * @prop {Boolean} disabled Boolean value that disables the component if true
 * @prop {String} placeholder Textfield placeholder
 * @prop {String} direction Input component direction
 * @returns {Box}
 */
const NumericInputField = ({
  type,
  name,
  value,
  error,
  helperText,
  onBlur,
  onChange,
  onKeyDown,
  label,
  hide,
  disabled,
  placeholder,
  direction,
  ...rest
}) => {
  if (hide) return null;
  const withValueCap = (inputObj) => {
    // eslint-disable-next-line no-shadow
    const { value } = inputObj;
    if (value <= CURRENCY_RANGE.MAX_VALUE && !value.startsWith('0')) return true;
    return false;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        width: '100%',
      }}
    >
      <Typography fontSize="16px" className="field-label">
        {label}
      </Typography>
      <NumericFormat
        customInput={TextField}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        error={error}
        decimalScale={2}
        helperText={helperText}
        onBlur={onBlur}
        onValueChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        isAllowed={withValueCap}
        allowNegative={false}
        {...rest}
      />
    </Box>
  );
};
//
export default NumericInputField;
