import { createSlice } from '@reduxjs/toolkit';
import * as moment from 'moment';
//
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  opexList: {},
  isOpexFetching: false,
  opex: {},
  opexToEdit: '',
  addOpexLoading: false,
  editOpexLoading: false,
};
/**
 * Redux slice for the opex feature
 */
export const opexSlice = createSlice({
  name: 'feature/opex',
  initialState,
  reducers: {
    getAllOpex: (state) => ({
      ...state,
      loading: true,
    }),
    getAllOpexSucceeded: (state, action) => ({
      ...state,
      loading: false,
      opexList: action?.payload,
    }),
    getAllOpexFailed: (state) => ({
      ...state,
      loading: false,
    }),
    addOpex: (state) => ({
      ...state,
      addOpexLoading: true,
    }),
    addOpexSucceeded(state, action) {
      const newOpexList = state.opexList.docs.map((opex) => {
        if (opex.dataStatus === DATA_STATUS_TYPES.ACTIVE) {
          return {
            ...opex,
            endDate: moment(action?.payload.startDate).subtract(1, 'days').format(),
            dataStatus: DATA_STATUS_TYPES.INACTIVE,
          };
        }
        return {
          ...opex,
          dataStatus: DATA_STATUS_TYPES.INACTIVE,
        };
      });
      newOpexList.unshift(action?.payload);
      return {
        ...state,
        addOpexLoading: false,
        opexList: {
          ...state.opexList,
          docs: newOpexList,
          totalDocs: state.opexList.totalDocs + 1,
        },
      };
    },
    addOpexFailed: (state) => ({
      ...state,
      addOpexLoading: false,
    }),
    editOpex: (state) => ({
      ...state,
      editOpexLoading: true,
    }),
    editOpexSucceeded: (state, action) => ({
      ...state,
      editOpexLoading: false,
      opexList: {
        ...state.opexList,
        docs: state.opexList.docs.map((opex) => {
          if (opex.id === action?.payload?.id) {
            return action?.payload;
          }
          return opex;
        }),
      },
    }),
    editOpexFailed: (state) => ({
      ...state,
      editOpexLoading: false,
    }),
    clearEditOpex: (state) => ({
      ...state,
      opexToEdit: '',
    }),
    deleteOpex: (state) => ({
      ...state,
      loading: true,
    }),
    deleteOpexSucceeded: (state, action) => ({
      ...state,
      loading: false,
      opexList: {
        ...state.opexList,
        docs: state.opexList.docs.map((opex) => {
          if (opex.id === action?.payload?.id) {
            return action?.payload;
          }
          return opex;
        }),
      },
    }),
    deleteOpexFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getOneOpex: (state) => ({
      ...state,
      isOpexFetching: true,
    }),
    getOneOpexSucceeded: (state, action) => ({
      ...state,
      isOpexFetching: false,
      opex: action?.payload,
    }),
    getOneOpexFailed: (state) => ({
      ...state,
      isOpexFetching: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: opexActions } = opexSlice;
