import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectIsWorkAllocationFetching,
  selectProject,
  selectUpdateWorkAllocation,
} from 'features/projects/selectors';
import { Button, DataGrid } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
import ROUTES from 'features/base/constants/routes';
/**
 * Function that renders the update work allocations table
 */
const UpdateWorkAllocationsTable = ({
  selectedUser,
  mainProjectPeriod,
  rows,
  errors,
  handleSave,
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  //
  const projectDetails = useSelector(selectProject);
  const {
    workAllocation: workAllocationByUser,
    loading: tableLoading,
    hasUnsavedChanges,
  } = useSelector(selectUpdateWorkAllocation);
  const isWorkAllocationFetching = useSelector(selectIsWorkAllocationFetching);
  //
  const computeTableHeader = () => {
    const columns = [
      {
        field: 'ProjectName',
        width: 300,
        headerName: <Box sx={{ minWidth: '12rem' }}>Project name</Box>,
        valueGetter: (params) => params.row.ProjectName.value,
        renderCell: (params) => {
          const { projectId: currentProjectId, value } = params.row.ProjectName;
          return (
            <Typography
              fontWeight={currentProjectId === projectDetails.id ? 'bold' : 'normal'}
              variant="body2"
            >
              {value}
            </Typography>
          );
        },
      },
    ];
    mainProjectPeriod.forEach((period) => {
      columns.push({
        field: `${period.month}${period.year}`,
        width: 225,
        headerName: (
          <Box sx={{ minWidth: '6rem', maxWidth: '6rem' }}>
            <Stack spacing={0}>
              <Typography variant="body1" fontWeight="bold">
                {period.month}
              </Typography>
              <Typography variant="caption">{period.year}</Typography>
            </Stack>
          </Box>
        ),
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        valueGetter: (params) => params.row[`${period.month}${period.year}`].allocationHours,
        renderCell: (params) => {
          const { id, allocationHours, onChange, availability } =
            params.row[`${period.month}${period.year}`];
          if (id === 'currentProject') {
            // current project input fields
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', width: '150px' }}>
                <div style={{ display: 'flex', gap: 0 }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        type="number"
                        size="small"
                        variant="outlined"
                        required
                        error={allocationHours > selectedUser?.capacity || allocationHours < 0}
                        value={allocationHours}
                        // Only increment or decrement by 8 multipliers (0, 8, 16, 24, 32, 40, 48, 56, 64)
                        inputProps={{
                          step: 8,
                          min: 0,
                          max: 720,
                        }}
                        sx={{ flexShrink: 0, width: '100%' }}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography
                        variant="body2"
                        color={COLORS.SUCCESS_MAIN}
                        sx={{
                          fontSize: '0.879rem',
                          fontWeight: 'bold',
                          border: '1px solid',
                          borderColor: (theme) => theme.palette.grey[400],
                          borderRadius: '4px',
                          backgroundColor: COLORS.TRANSPARENT_DARK_GREEN,
                          px: 1,
                          py: '9px',
                          textAlign: 'left',
                          flexShrink: 0,
                        }}
                      >
                        {availability}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            );
          }
          if (id === 'totalPerMonth') {
            return (
              <Box
                display="flex"
                alignItems="center"
                fontWeight="bold"
                color={allocationHours > selectedUser?.capacity ? 'error.main' : 'text.primary'}
              >
                {allocationHours}
                {allocationHours > selectedUser?.capacity && (
                  <Tooltip title="Allocation exceeds capacity">
                    <IconButton>
                      <ReportProblemIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          }
          return <Typography variant="subtitle2">{allocationHours}</Typography>;
        },
      });
    });
    return columns;
  };
  //
  return (
    <Container sx={{ mt: 2 }} disableGutters>
      {!tableLoading && selectedUser && workAllocationByUser?.docs?.length > 0 && (
        <>
          <DataGrid
            serverSidePagination={false}
            limit={workAllocationByUser?.docs?.length ? workAllocationByUser.docs.length + 1 : 0}
            columns={computeTableHeader()}
            rows={rows}
            rowHeight={73}
            pageSizeOptions={[
              workAllocationByUser?.docs?.length ? workAllocationByUser.docs.length + 1 : 0,
            ]}
          />
          {errors?.length > 0 && (
            <Alert severity="warning" variant="filled" sx={{ mt: 2 }} hidden={!hasUnsavedChanges}>
              <AlertTitle> Your configuration has errors </AlertTitle>
              {errors.map((error) => (
                <Typography key={error} sx={{ fontSize: '0.8rem' }}>
                  {error}
                </Typography>
              ))}
            </Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pr: 3 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={tableLoading || errors?.length > 0}
              startIcon={tableLoading && <CircularProgress size="1rem" />}
              onClick={() => {
                if (isWorkAllocationFetching) {
                  return;
                }
                if (hasUnsavedChanges) {
                  handleSave();
                } else {
                  navigate(ROUTES.PROJECT_BILLING.replace(':projectId', projectId));
                }
              }}
            >
              {hasUnsavedChanges ? 'Save Changes' : 'Next'}
            </Button>
          </Box>
        </>
      )}
      {!tableLoading && selectedUser && workAllocationByUser?.length === 0 && (
        <Box sx={{ mt: 6 }}>
          <Typography sx={{ fontSize: '1rem' }} align="center">
            No Work Allocation Found for Selected User
          </Typography>
        </Box>
      )}
      {!tableLoading && !selectedUser && (
        <Box sx={{ mt: 6 }}>
          <Typography sx={{ fontSize: '1rem' }} align="center">
            Select User to View/Edit Work Allocation
          </Typography>
        </Box>
      )}
      {tableLoading && <ShimmerTable row={3} col={8} size="sm" />}
      {!selectedUser && (
        <Box sx={{ mt: '8rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              navigate(ROUTES.PROJECT_BILLING.replace(':projectId', projectId));
            }}
          >
            Next
          </Button>
        </Box>
      )}
    </Container>
  );
};
//
export default UpdateWorkAllocationsTable;
