import * as yup from 'yup';
/**
 * Function for link form validation
 */
const linkFormValidation = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup
    .string()
    .required('Description is required')
    .min(10, ({ min }) => `Description must be at least ${min} characters`)
    .max(100, ({ max }) => `Description must be at maximum of ${max} characters`),
  link: yup.string().url('Link should be a valid URL').required('Link is required'),
  departments: yup.array().min(1, 'Please select at least one department'),
});
//
export default linkFormValidation;
