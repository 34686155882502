/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
//
import { internalOnboardingSliceActions } from 'features/internal-onboarding/slice';
import request from 'features/base/utils/request';
import API from './constants';
/**
 * Function for sending the token that contains client's email
 * @param {Object} payload
 */
export function* internalOnboarding({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER, payload);
    yield put(internalOnboardingSliceActions.internalOnboardingSuccess(response));
  } catch (error) {
    yield put(internalOnboardingSliceActions.internalOnboardingFailure(error?.message));
  }
}
/**
 * Generator function for getting department designation ids
 * @param {Object} payload
 */
export function* getDepartmentDesignationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENT_DESIGNATIONS, payload);
    yield put(internalOnboardingSliceActions.getDepartmentDesignationsSucceeded(response));
  } catch (error) {
    yield put(internalOnboardingSliceActions.getDepartmentDesignationsFailed(error?.message));
  }
}
/**
 * Redux saga that triggers clientEmailVerification function
 */
export function* internalOnboardingSaga() {
  yield takeLatest(internalOnboardingSliceActions.internalOnboarding.type, internalOnboarding);
  yield takeLatest(
    internalOnboardingSliceActions.getDepartmentDesignations.type,
    getDepartmentDesignationsGenerator
  );
}
//
export default internalOnboardingSaga;
