/**
 * Page names
 */
const PAGES = {
  LINK_HUB: 'Link Hub',
  TOOL_HUB: 'Tool Hub',
  PROJECTS: 'Projects',
  SUPPORT_TICKETS: 'Support Tickets',
  BENEFITS: 'Benefits',
  MANAGE_BENEFITS: 'Manage Benefits',
  CREATE_BENEFIT: 'Create Benefit',
  MANAGE_TOOLS: 'Manage Tools',
  MANAGE_OPEX: 'Manage Overhead',
  MANAGE_ROLES: 'Manage Roles',
  MANAGE_SKILLS: 'Manage Skills',
  MANAGE_ORGANIZATION: 'Manage Organization',
  MANAGE_CLIENTS: 'Manage Clients',
  MANAGE_USERS: 'Manage Users',
  MANAGE_LINKS: 'Manage Links',
  MANAGE_VENDORS: 'Manage Vendors',
  TEAM_REPORT: 'Team Report',
  ALLOCATION_REPORT: 'Allocation Report',
  ALLOCATED_VS_ACTUAL_HOURS: 'Allocated vs Actual hours',
  SKILL_REPORT: 'Skill Report',
  CAPACITY_REPORT: 'Capacity Report',
  SALARY_REPORT: 'Salary Report',
};
export default PAGES;
