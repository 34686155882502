import { DriveFileRenameOutline } from '@mui/icons-material';
import { IconButton, Switch, Typography } from '@mui/material';
//
import { ENVIRONMENT } from 'config';
/**
 * View link table columns - basic details
 */
export const LINKS_TABLE_COLUMNS_BASIC = [
  {
    field: 'linkName',
    headerName: 'Name',
    width: 70,
    sortable: false,
    renderCell: ({ value: { linkLogo } }) => (
      <img
        src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${linkLogo?.url}`}
        alt="logo"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
    ),
  },
  {
    field: 'name',
    headerName: '',
    width: 250,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => (
      <Typography
        noWrap
        sx={{
          maxWidth: '15ch',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 300,
    sortComparator: (firstValue, secondValue) =>
      firstValue.description.localeCompare(secondValue.description),
    renderCell: ({ value: { description } }) => (
      <Typography
        noWrap
        sx={{
          maxWidth: '20ch',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {description}
      </Typography>
    ),
  },
  {
    field: 'departments',
    headerName: 'Departments',
    width: 240,
    sortComparator: (firstValue, secondValue) =>
      firstValue.linkDepartment.localeCompare(secondValue.linkDepartment),
    renderCell: ({ value: { linkDepartment } }) => (
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {[...new Set(linkDepartment?.map((department) => department?.departmentId?.name))].join(
          ', '
        )}
      </Typography>
    ),
  },
  {
    field: 'link',
    headerName: 'Link',
    width: 220,
    sortComparator: (firstValue, secondValue) => firstValue.link.localeCompare(secondValue.link),
    renderCell: ({ value: { link } }) => (
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {link}
      </Typography>
    ),
  },
  {
    field: 'startDate',
    headerName: 'Created date',
    width: 120,
    sortComparator: (firstValue, secondValue) =>
      firstValue.linkStartDate.localeCompare(secondValue.linkStartDate),
    renderCell: ({ value: { linkStartDate } }) => (
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
        {linkStartDate}
      </Typography>
    ),
  },
  {
    field: 'endDate',
    headerName: 'Last updated',
    width: 120,
    sortComparator: (firstValue, secondValue) =>
      firstValue.linkEndDate.localeCompare(secondValue.linkEndDate),
    renderCell: ({ value: { linkEndDate } }) => (
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
        {linkEndDate}
      </Typography>
    ),
  },
];
/**
 * View link table columns - action columns
 */
export const LINKS_TABLE_ACTION_COLUMNS = [
  {
    field: 'status',
    headerName: 'Status',
    width: 75,
    valueGetter: (params) => params.row.status.checked,
    renderCell: (params) => {
      const { onChange, checked, isLoading } = params.row.status;
      return <Switch checked={checked} color="success" readOnly={isLoading} onClick={onChange} />;
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 75,
    sortable: false,
    renderCell: ({ value: { handleEdit, isLoading } }) => (
      <IconButton onClick={handleEdit} readOnly={isLoading}>
        <DriveFileRenameOutline />
      </IconButton>
    ),
  },
];
