import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';
//
import { benefitsActions } from 'features/benefits/slice';
import GeneralDetailsStep from './general-details';
import AddEmployeesStep from './add-employees';
import AddPromotionDetailsStep from './promotion-details';
import SummaryViewStep from './summery-view';
//
const tabsNames = ['General Details', 'Promotion Details', 'Add Employees', 'Summary'];
//
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  //
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ display: value === index ? 'block' : 'none' }}
      {...other}
    >
      {children}
    </div>
  );
}
//
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//
const CreateBenefitMultiStepForm = () => {
  const dispatch = useDispatch();
  //
  useEffect(() => {
    dispatch(
      benefitsActions.getVendorsMultiStepForm({
        query: `pagination=false&sortBy=name:asc`,
      })
    );
    dispatch(benefitsActions.getDepartments());
    return () => {
      dispatch(benefitsActions.resetMultiStepForm());
    };
  }, []);
  //
  const [activeStep, setActiveStep] = useState(0);
  const [stepProgress, setStepProgress] = useState(
    tabsNames.map((_, index) => ({ step: index, completed: false, disabled: false }))
  );
  const [formResults, setFormResults] = useState(
    tabsNames.map((_, index) => ({ step: index, data: {} }))
  );
  //
  const handleTabChange = (event, newValue) => {
    setActiveStep(newValue);
    // if user navigates to previous step, set the progress of the ahead steps to false
    if (newValue < activeStep) {
      setStepProgress(
        stepProgress.map((step, index) => {
          if (index > newValue) {
            return { ...step, completed: false };
          }
          return step;
        })
      );
    }
  };
  //
  return (
    <Box sx={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeStep} onChange={handleTabChange} centered variant="fullWidth">
          {tabsNames.map((tabName, index) => (
            <Tab
              label={`${index + 1}. ${tabName}`}
              {...a11yProps(index)}
              disabled={!stepProgress[index].completed || stepProgress[index].disabled}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ padding: 2, paddingTop: 4 }}> </Box>
      <CustomTabPanel value={activeStep} index={0}>
        <GeneralDetailsStep
          setStepProgress={setStepProgress}
          stepProgress={stepProgress}
          setActiveStep={setActiveStep}
          formResults={formResults}
          setFormResults={setFormResults}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeStep} index={1}>
        <AddPromotionDetailsStep
          setStepProgress={setStepProgress}
          stepProgress={stepProgress}
          setActiveStep={setActiveStep}
          formResults={formResults}
          setFormResults={setFormResults}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeStep} index={2}>
        <AddEmployeesStep
          setStepProgress={setStepProgress}
          stepProgress={stepProgress}
          setActiveStep={setActiveStep}
          formResults={formResults}
          setFormResults={setFormResults}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeStep} index={3}>
        <SummaryViewStep formResults={formResults} />
      </CustomTabPanel>
    </Box>
  );
};

export default CreateBenefitMultiStepForm;
