import { Button } from '@mui/material';

/**
 * Function that renders the skill action button (I/L or N/A)
 * @param {Object} userSkill - User skill record
 * @param {Boolean} editMode - Indicates if the edit mode button is clicked
 * @param {Function} handleUserSkillOnChange - On change gunction
 * @param {String} displayName - Name of the button
 * @param {String} skillLearningType - Skill type
 * @returns {<Button>}
 */
const SkillActionButton = ({
  userSkill,
  editMode,
  handleUserSkillOnChange,
  displayName,
  skillLearningType,
}) => (
  <Button
    className="skillActionButton"
    value={userSkill?.learningType}
    disabled={!editMode}
    style={{
      backgroundColor: userSkill.learningType === skillLearningType ? '#d9a7ff' : 'inherit',
      color: '#B04AFF',
    }}
    onClick={() =>
      handleUserSkillOnChange(
        userSkill?.skillId,
        'learningType',
        userSkill?.learningType === skillLearningType ? '' : skillLearningType
      )
    }
  >
    {displayName}
  </Button>
);
//
export default SkillActionButton;
