import { Alert, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectAllVendors,
  selectUploadedLogo,
  selectEditVendorLoader,
  selectUploadFileLoader,
} from 'features/vendors/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import FileUpload from 'features/base/components/file-upload';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { vendorActions } from 'features/vendors/slice';
import vendorFormValidation from 'features/vendors/validations/vendor-validation';
import {
  VENDOR_LOGO_ALLOWED_EXTENSIONS,
  VENDOR_LOGO_ALLOWED_FILE_TYPES,
} from 'features/base/constants/file-upload';
import { getAllowedExtensionsString, verifyExtensions } from 'features/base/helpers/file';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { removeUnchanged } from 'features/base/helpers/object';
import './index.scss';

/**
 * Function that defines the popup form for edit vendor
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} vendorId - id of the vendor to be edited
 * @returns {Popup}
 */
const EditVendorPopup = ({ editModalOpen, setEditModalOpen, vendorId }) => {
  const dispatch = useDispatch();
  //
  const allVendors = useSelector(selectAllVendors);
  const vendorToEdit = allVendors?.docs?.find((vendor) => vendor?.id === vendorId);
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectEditVendorLoader);
  const logoLoading = useSelector(selectUploadFileLoader);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(vendorActions.clearLogo());
  };
  //
  const getInputLabel = () => {
    if (uploadedLogo?.length > 0) {
      return uploadedLogo[0].name;
    }
    if (vendorToEdit) {
      return vendorToEdit?.logo[0]?.name;
    }
    return 'Choose logo';
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), VENDOR_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      Object.keys(logo).forEach((k) => formData.append('file', logo[k]));
      if (logo.length > 0) {
        dispatch(vendorActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  const initialValues = {
    name: vendorToEdit?.name,
    description: vendorToEdit?.description,
    email: vendorToEdit?.email,
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.CREATE,
        },
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.READ,
        },
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit vendor"
        mediumSize="660px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={vendorFormValidation}
          onSubmit={(values) => {
            let vendorData = { ...values };
            vendorData = removeUnchanged(initialValues, vendorData);
            if (uploadedLogo?.length) {
              vendorData.logo = uploadedLogo;
            }
            dispatch(vendorActions.editVendor({ ...vendorData, vendorId }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="edit-vendors-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Company logo"
                value={values.logo}
                acceptedTypes={VENDOR_LOGO_ALLOWED_FILE_TYPES}
                inputLabel={getInputLabel()}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  VENDOR_LOGO_ALLOWED_EXTENSIONS
                )}`}
                loading={logoLoading}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Company name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Description"
                height={120}
                multiline
                rows={4}
              />
              <TextField
                type="email"
                name="email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Email"
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditVendorPopup;
