import { utils } from 'xlsx';
import { saveAs } from 'file-saver';
/**
 * Function that gets the extension of a given filename
 * @param {String} filename - The name of the file
 */
export const getExtension = (filename) => {
  const splitted = filename.split('.');
  return splitted[splitted.length - 1];
};
/**
 * Function that converts a list of allowed extension to a comma separated string
 * @param {List} extensionList - List of allowed extensions
 */
export const getAllowedExtensionsString = (extensionList) =>
  extensionList?.reduce((acc, currentItem) => `${acc}, ${currentItem}`);
/**
 * Function that verifies a list of attachments by checking if each attachment is of a valid extension type
 * @param {List} attachments - List of attachments
 * @param {List} allowedExtensions - List of allowed extensions
 */
export const verifyExtensions = (attachments, allowedExtensions) =>
  attachments.every((attachment) =>
    allowedExtensions.includes(getExtension(attachment.name)?.toLowerCase())
  );
/**
 * Function that converts a list of files to FormData
 * @param {List<File>} - List of files to add to form data
 * @returns {FormData}
 */
export const processFiles = (filesToUpload) => {
  const formData = new FormData();
  Object.keys(filesToUpload).forEach((file) => formData.append('file', filesToUpload[file]));
  return formData;
};
/**
 * Function that generates a CSV file based on the given parameters and triggers a download
 * @param {List<String>} header - A list of strings representing the headers of the file
 * @param {List<Object>} data - A list of objects where each object represents on row in the file (keys must match the provided header)
 * @param {String} filename - Name of the downloaded file
 */
export const downloadCSVFile = (header, data, filename) => {
  const worksheet = utils.json_to_sheet(data, { header });
  const csvData = utils.sheet_to_csv(worksheet);
  const fullFileName = filename.split('.').join(`_${new Date().toISOString()}.`);
  const blob = new Blob([csvData], {
    type: 'text/csv',
  });
  saveAs(blob, fullFileName);
};
