/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { userActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a user
 * @param {Object} payload
 */
export function* addUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_USER, payload);
    yield put(userActions.addUserSucceeded(response));
  } catch (error) {
    yield put(userActions.addUserFailed(error?.message));
  }
}
/**
 * Generator function for updating a user
 * @param {Object} payload
 */
export function* patchUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER, payload);
    yield put(userActions.patchUserSucceeded(response));
  } catch (error) {
    yield put(userActions.patchUserFailed(error?.message));
  }
}
/**
 * Generator function for getting users
 * @param {Object} payload
 */
export function* getUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(userActions.getUsersSucceeded(response));
  } catch (error) {
    yield put(userActions.getUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting users without pagination
 * @param {Object} payload
 */
export function* getSalaryUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(userActions.getSalaryUsersSucceeded(response));
  } catch (error) {
    yield put(userActions.getSalaryUsersFailed(error?.message));
  }
}
/**
 * Generator function for adding a salary to users
 * @param {Object} payload
 */
export function* addSalaryGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SALARY, payload);
    yield put(userActions.addSalarySucceeded(response));
  } catch (error) {
    yield put(userActions.addSalaryFailed(error?.message));
  }
}
/**
 * Generator function for uploading bulk salary file
 * @param {Object} payload
 */
export function* uploadBulkSalaryFileGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_BULK_SALARY_FILE, payload, true);
    yield put(userActions.uploadBulkSalaryFileSucceeded(response));
  } catch (error) {
    yield put(userActions.uploadBulkSalaryFileFailed(error?.message));
  }
}
/**
 * Generator function for getting the Designations
 * @param {Object} payload
 */
export function* getDesignationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DESIGNATIONS, payload);
    yield put(userActions.getDesignationsSucceeded(response));
  } catch (error) {
    yield put(userActions.getDesignationsFailed(error?.message));
  }
}
/**
 * Generator function for getting the Departments
 * @param {Object} payload
 */
export function* getDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS, payload);
    yield put(userActions.getDepartmentsSucceeded(response));
  } catch (error) {
    yield put(userActions.getDepartmentsFailed(error?.message));
  }
}
/**
 * Generator function for getting the work area
 * @param {Object} payload
 */
export function* getWorkAreaGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORK_AREA, payload);
    yield put(userActions.getWorkAreasSucceeded(response));
  } catch (error) {
    yield put(userActions.getWorkAreasFailed(error?.message));
  }
}
/**
 * Generator function for getting roles
 * @param {Object} payload
 */
export function* getRolesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROLES, payload);
    yield put(userActions.getRolesSucceeded(response));
  } catch (error) {
    yield put(userActions.getRolesFailed(error?.message));
  }
}
/**
 * Generator function for updating the user's role
 * @param {Object} payload
 */
export function* updateUserRoleGenerator({ payload }) {
  try {
    const response = yield call(request, API.UPDATE_ROLE, payload);
    yield put(userActions.updateUserRoleSucceeded(response));
  } catch (error) {
    yield put(userActions.updateUserRoleFailed(error?.message));
  }
}
/**
 * Generator function for getting the selected user's permissions
 * @param {Object} payload
 */
export function* getSelectedUsersPermissionsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS_PERMISSIONS, payload);
    yield put(userActions.getSelectedUserPermissionsSucceeded(response));
  } catch (error) {
    yield put(userActions.getSelectedUserPermissionsFailed(error?.message));
  }
}
/**
 * Generator function for getting the salary history of a user with user_id
 * @param {Object} payload
 */
export function* getSalaryHistoryGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SALARY_HISTORY_OF_USER, payload);
    yield put(userActions.getSalaryHistorySucceed(response));
  } catch (error) {
    yield put(userActions.getSalaryHistoryFailed(error?.message));
  }
}
/**
 * Generator function for Update the current salary of a user
 * @param {Object} payload
 */
export function* updateCurrentSalaryGenerator({ payload }) {
  try {
    const response = yield call(request, API.UPDATE_CURRENT_SALARY, payload);
    yield put(userActions.updateCurrentSalarySucceed(response));
  } catch (error) {
    yield put(userActions.updateCurrentSalaryFailed(error?.message));
  }
}
/**
 * Generator function for uploading bulk user data file
 * @param {Object} payload
 * @returns {Generator}
 */
export function* uploadBulkUserDataFileGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_BULK_USER_DATA_FILE, payload, true);
    yield put(userActions.uploadBulkUserDataFileSucceeded(response));
  } catch (error) {
    yield put(userActions.uploadBulkUserDataFileFailed(error?.message));
  }
}
/**
 * Generator function for getting users without pagination
 * @param {Object} payload
 * @returns {Generator}
 */
export function* getBulkUserDataUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(userActions.getBulkUserDataUsersSucceeded(response));
  } catch (error) {
    yield put(userActions.getBulkUserDataUsersFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined generator function
 */
export function* userSaga() {
  yield takeLatest(userActions.getUsers.type, getUsersGenerator);
  yield takeLatest(userActions.addUser.type, addUserGenerator);
  yield takeLatest(userActions.patchUser.type, patchUserGenerator);
  yield takeLatest(userActions.getDesignations.type, getDesignationsGenerator);
  yield takeLatest(userActions.getDepartments.type, getDepartmentsGenerator);
  yield takeLatest(userActions.getWorkAreas.type, getWorkAreaGenerator);
  yield takeLatest(userActions.getRoles.type, getRolesGenerator);
  yield takeLatest(userActions.addSalary.type, addSalaryGenerator);
  yield takeLatest(userActions.getSalaryHistory.type, getSalaryHistoryGenerator);
  yield takeLatest(userActions.getSalaryUsers.type, getSalaryUsersGenerator);
  yield takeLatest(userActions.updateUserRole.type, updateUserRoleGenerator);
  yield takeLatest(userActions.updateCurrentSalary.type, updateCurrentSalaryGenerator);
  yield takeLatest(
    userActions.getSelectedUserPermissions.type,
    getSelectedUsersPermissionsGenerator
  );
  yield takeLatest(userActions.uploadBulkSalaryFile.type, uploadBulkSalaryFileGenerator);
  yield takeLatest(userActions.uploadBulkUserDataFile.type, uploadBulkUserDataFileGenerator);
  yield takeLatest(userActions.getBulkUserDataUsers.type, getBulkUserDataUsersGenerator);
}
//
export default userSaga;
