import { Box } from '@mui/material';
//
import logo from 'features/base/assets/images/svg/logo.svg';
import redEclipse from 'features/base/assets/images/svg/red-eclipse-blurred.svg';
import blueEclipse from 'features/base/assets/images/svg/blue-eclipse-blurred.svg';
import backgroundGrid from 'features/base/assets/images/png/background-grid.png';
import './index.scss';
/**
 * Defines the background for Surge Hub that contains the Surge logo and its primary color
 * @param {Object} childrenStyles
 * @param {ReactNode} children
 * @returns {Box}
 */
const CommonBackground = ({ childrenStyles, children }) => (
  <Box className="common-background-container">
    <img src={logo} alt="logo" className="common-background-logo" />
    <img src={backgroundGrid} alt="background-grid" className="common-background-grid" />
    <img
      src={blueEclipse}
      alt="background-blue-eclipse"
      className="common-background-blue-eclipse"
    />
    <img src={redEclipse} alt="background-red-eclipse" className="common-background-red-eclipse" />
    <Box style={{ ...childrenStyles }} className="common-layout-center-container">
      {children}
    </Box>
  </Box>
);
//
export default CommonBackground;
