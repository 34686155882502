/* eslint-disable import/prefer-default-export */
/**
 * Available support ticket sortby fields mappping
 * @type {{ [key: string, value: string] }}
 */
export const SUPPORT_TICKET_SORTBY_FIELDS = [
  { key: 'createdAt', value: 'Created At' },
  { key: 'updatedAt', value: 'Updated At' },
  { key: 'title', value: 'Title' },
  { key: 'acknowledged.isAcknowledged', value: 'Status' },
];
