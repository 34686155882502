import { useDispatch, useSelector } from 'react-redux';
import { Alert, Grid, Box, IconButton, Button, TextField as MuiTextField } from '@mui/material';
import { FieldArray, Formik } from 'formik';
//
import { departmentActions } from 'features/departments/slice';
import { selectAddWorkAreaLoader } from 'features/departments/selectors';
import CloseIcon from 'features/base/assets/images/svg/close-icon.svg';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { Button as AddButton } from 'features/base/components';
import workAreaFormValidation from 'features/departments/validations/work-area-validation';
import { specialCharsRegExp } from 'features/base/constants/regex';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Form component to add work area
 * @returns {Form}
 */
const AddWorkAreaForm = () => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddWorkAreaLoader);
  //
  const handleOnClose = () => {
    dispatch(notificationActions.resetNotification());
  };
  //
  const checkSpecialChar = (event) => {
    if (!specialCharsRegExp.test(event.key)) {
      event.preventDefault();
    }
  };
  //
  return (
    <Grid item xs={12} md={6} lg mb={2}>
      <Formik
        initialValues={{
          workArea: [{ name: '' }],
        }}
        validationSchema={workAreaFormValidation}
        onSubmit={(values, { resetForm }) => {
          dispatch(departmentActions.addWorkArea(values));
          resetForm();
          handleOnClose();
        }}
      >
        {({ errors, handleChange, handleSubmit, handleBlur, values, touched }) => (
          <form noValidate onSubmit={handleSubmit} className="add-workArea-form">
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <FieldArray name="workArea">
              {({ remove, push }) => (
                <div>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      style={{ textTransform: 'none' }}
                      type="button"
                      onClick={() => push({ name: '' })}
                    >
                      + Add
                    </Button>
                  </Box>
                  {errors.workArea &&
                    values?.workArea?.length === 1 &&
                    touched.workArea?.some((skill) => skill.name) && (
                      <Alert severity="error">Add at least one work area</Alert>
                    )}
                  {errors.workArea &&
                    values?.workArea?.some((skill) => !skill.name) &&
                    values?.workArea?.length > 1 &&
                    values?.workArea?.length === touched.workArea?.length && (
                      <Alert severity="error">
                        Please fill all the work area fields or remove unnecessary ones
                      </Alert>
                    )}
                  {notification?.isEnabled && notification?.type === ERROR_TYPES.WARNING && (
                    <Alert severity={notification?.type}>{notification?.message}</Alert>
                  )}
                  {values?.workArea.length > 0 &&
                    values?.workArea.map((_, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        key={`key${index.toString()}`}
                      >
                        <MuiTextField
                          type="text"
                          name={`workArea.${index}.name`}
                          value={values?.workArea[index].name}
                          onChange={handleChange}
                          onKeyDown={(event) => checkSpecialChar(event)}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          placeholder={`Workarea ${index + 1}`}
                          sx={{
                            mb: 2,
                            fieldset: {
                              borderRadius: '0px',
                            },
                            width: '100%',
                            '& .MuiOutlinedInput-root input': {
                              padding: 1,
                              pl: 2,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                disabled={index < 1}
                                onClick={() => remove(index)}
                                sx={{
                                  padding: '20px',
                                  width: '22px',
                                  height: '20px',
                                  margin: '4px',
                                  opacity: index < 1 ? 0.3 : 1,
                                }}
                              >
                                <img src={CloseIcon} alt="close-icon" />
                              </IconButton>
                            ),
                          }}
                        />
                      </Box>
                    ))}
                </div>
              )}
            </FieldArray>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
              <AddButton
                type="submit"
                sx={{
                  width: {
                    xs: '100%',
                    sm: loading ? '250px' : '199px',
                  },
                  textTransform: 'none',
                }}
                size="medium"
                icon={loading ? loaderIcon : null}
                iconAlignment="right"
              >
                {loading ? 'Adding Work Area' : 'Add work area'}
              </AddButton>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};
//
export default AddWorkAreaForm;
