import { Grid } from '@mui/material';
import { ShimmerThumbnail } from 'react-shimmer-effects';

/**
 * Component that renders the shimmer for a card view
 * @returns {Grid}
 */
const ShimmerCards = () => (
  <Grid container mx={4} direction="row">
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
    <Grid
      item
      sx={{
        width: { sm: '100%', lg: '45%' },
        height: 'auto',
        mx: 2,
        mb: 2,
      }}
      xs={12}
      lg={5}
    >
      <ShimmerThumbnail height={130} />
    </Grid>
  </Grid>
);
//
export default ShimmerCards;
