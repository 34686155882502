/* eslint-disable import/prefer-default-export */
import { MONTHS } from 'features/base/constants/date-formatting';
import { getLastDayOfMonthISO, getNumberOfWorkingDays } from 'features/base/helpers/date';

/**
 * Function that generates and object with keys as month-year with the value being the number of working days for each month
 * For the last month, its the number of working days upto the date provided, for other months, its the working days from the date provided
 * @param {String} startDateString
 * @param {String} endDateString
 * @returns {Object}
 * @example 
 * console.log(getWorkingDaysByMonth("2023-08-07T00:00:00.000Z", "2023-09-07T00:00:00.000Z"))
 * { August2023: 19, September2023: 5 }
 */
export const getWorkingDaysByMonth = (startDateString, endDateString) => {
  const start = new Date(startDateString);
  const end = new Date(endDateString);
  const workingDaysByMonth = {};

  while (start <= end) {
    const year = start.getUTCFullYear();
    const month = MONTHS[start.getUTCMonth()];
    const key = `${month}${year}`;
    const lastDayOfMonth = getLastDayOfMonthISO(2023, start.getUTCMonth() + 1)
    //
    if (start.getMonth() === end.getMonth()) {
      workingDaysByMonth[key] = getNumberOfWorkingDays(start.toISOString(), end.toISOString());
      break;
    } else {
      workingDaysByMonth[key] = getNumberOfWorkingDays(
        start.toISOString(),
        lastDayOfMonth
      );
      start.setMonth(start.getUTCMonth() + 1, 1);
    }
  }
  //
  return workingDaysByMonth;
};
