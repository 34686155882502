import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

/**
 * Radio button set component
 * @prop {String} label - Label for the upload box
 * @prop {List} items - List of options for the radio buttons
 * @prop {String} value - Selected value
 * @prop {Function} onChange - Radio button on change handler function
 * @prop {Boolean} disabled - Buttons are disabled if true
 * @returns {RadioButtonSet}
 */
const RadioButtonSet = ({ label, options, value, onChange, disabled, ...rest }) => (
  //
  <FormControl disabled={disabled}>
    <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
    <RadioGroup
      row
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={onChange}
      {...rest}
    >
      {options?.map((item) => (
        <FormControlLabel value={item} control={<Radio />} label={item} key={item} />
      ))}
    </RadioGroup>
  </FormControl>
);
//
export default RadioButtonSet;
