import { useEffect, useState } from 'react';
import { Grid, Box, Typography, Container } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import PlusIcon from '@mui/icons-material/Add';
//
import { Button, SearchBar, TabBar } from 'features/base/components';
import { departmentActions } from 'features/departments/slice';
import { designationActions } from 'features/designations/slice';
import NESTED_ROUTES from 'features/base/constants/departments-view-tab-routes';
import AddNewDepartmentPopup from 'features/departments/components/add-department';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import TIME_OUTS from 'features/base/constants/time-outs';
import { AddNewDesignationDrawer } from 'features/designations/components';
import AddNewDepartmentDrawer from '../add-department-drawer';
import AddNewWorkAreaDrawer from '../add-workarea-drawer';

/**
 * Layout component for departments view
 * @returns {Box}
 */
const DepartmentsView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  //
  const [searchValue, setSearchValue] = useState('');
  const [pathName, setPathName] = useState(NESTED_ROUTES[1].path);
  const [addDepartmentModalOpen, setAddDepartmentModalOpen] = useState(false);
  const [addDepartmentDrawerOpen, setAddDepartmentDrawerOpen] = useState(false);
  const [addWorkAreaDrawerOpen, setAddWorkAreaDrawerOpen] = useState(false);
  const [addDesignationDrawerOpen, setAddDesignationDrawerOpen] = useState(false);
  //
  const handleAdd = () => {
    dispatch(
      designationActions.getAllDesignation({
        query: 'pagination=false&sortBy=name:asc',
      })
    );
    setAddDepartmentModalOpen(true);
  };
  //
  useEffect(() => {
    const URLPath = location.pathname.split('/')[2];
    if (URLPath) {
      setPathName(URLPath);
    } else {
      setPathName(NESTED_ROUTES[1].path);
    }
    return () => {
      dispatch(departmentActions.setSearch(''));
      dispatch(designationActions.setSearch(''));
      dispatch(departmentActions.setSearch(''));
      setSearchValue('');
    };
  }, [location.pathname]);
  //
  const searchWorkAreas = () => {
    dispatch(departmentActions.setSearch(searchValue));
  };
  //
  const debouncedWorkArea = useDebouncedCallback(searchWorkAreas, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchWorkAreaOnChange = (event) => {
    setSearchValue(event.target.value);
    debouncedWorkArea();
  };
  //
  const searchDesignations = () => {
    dispatch(designationActions.setSearch(searchValue));
  };
  //
  const debouncedDesignation = useDebouncedCallback(searchDesignations, TIME_OUTS.DEBOUNCE);
  //
  const handleDesignationSearchOnChange = (event) => {
    setSearchValue(event.target.value);
    debouncedDesignation();
  };
  //
  const searchDepartments = () => {
    dispatch(departmentActions.setSearch(searchValue));
  };
  //
  const debounced = useDebouncedCallback(searchDepartments, TIME_OUTS.DEBOUNCE);
  //
  const handleDepartmentSearchOnChange = (event) => {
    setSearchValue(event.target.value);
    debounced();
  };
  //
  const handleAddDesignationDrawer = () => {
    setAddDesignationDrawerOpen(true);
  };
  //
  const handleAddWorkAreaDrawer = () => {
    setAddWorkAreaDrawerOpen(true);
  };
  //
  const handleAddDepartmentDrawer = () => {
    dispatch(
      designationActions.getAllDesignation({
        query: 'pagination=false&sortBy=name:asc',
      })
    );
    setAddDepartmentDrawerOpen(true);
  };
  //
  const renderHeaders = () => {
    switch (pathName) {
      case NESTED_ROUTES[0].path:
        return (
          <Box>
            <AddNewDesignationDrawer
              addDesignationDrawerOpen={addDesignationDrawerOpen}
              setAddDesignationDrawerOpen={setAddDesignationDrawerOpen}
            />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
                pb: 4,
              }}
            >
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '24px', sm: '32px' },
                    fontWeight: 600,
                    mr: '48px',
                  }}
                >
                  Designation
                </Typography>
                <PermissionWrapper
                  requiredPermissions={[
                    {
                      domain: PERMISSION_DOMAINS.ORGANIZATION,
                      action: PERMISSION_ACTIONS.CREATE,
                    },
                  ]}
                  hide
                >
                  <PlusIcon
                    onClick={() => handleAddDesignationDrawer()}
                    sx={{
                      width: '32px',
                      height: '32px',
                      cursor: 'pointer',
                      color: 'primary.main',
                      display: { xs: 'block', sm: 'none' },
                    }}
                  />
                </PermissionWrapper>
              </Grid>
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
                }}
              >
                <SearchBar
                  placeholder="Search designation"
                  value={searchValue}
                  handleSearchInput={handleDesignationSearchOnChange}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case NESTED_ROUTES[2].path:
        return (
          <Box>
            <AddNewWorkAreaDrawer
              addWorkAreaDrawerOpen={addWorkAreaDrawerOpen}
              setAddWorkAreaDrawerOpen={setAddWorkAreaDrawerOpen}
            />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
                pb: 4,
              }}
            >
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '24px', sm: '32px' },
                    fontWeight: 600,
                    mr: '48px',
                  }}
                >
                  Workarea
                </Typography>
                <PermissionWrapper
                  requiredPermissions={[
                    {
                      domain: PERMISSION_DOMAINS.ORGANIZATION,
                      action: PERMISSION_ACTIONS.CREATE,
                    },
                  ]}
                  hide
                >
                  <PlusIcon
                    onClick={() => handleAddWorkAreaDrawer()}
                    sx={{
                      width: '32px',
                      height: '32px',
                      cursor: 'pointer',
                      color: 'primary.main',
                      display: { xs: 'block', sm: 'none' },
                    }}
                  />
                </PermissionWrapper>
              </Grid>
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
                }}
              >
                <SearchBar
                  placeholder="Search workarea"
                  value={searchValue}
                  handleSearchInput={handleSearchWorkAreaOnChange}
                />
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return (
          <Box>
            <AddNewDepartmentPopup
              addDepartmentModalOpen={addDepartmentModalOpen}
              setAddDepartmentModalOpen={setAddDepartmentModalOpen}
            />
            <AddNewDepartmentDrawer
              addDepartmentDrawerOpen={addDepartmentDrawerOpen}
              setAddDepartmentDrawerOpen={setAddDepartmentDrawerOpen}
            />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
                pb: 4,
              }}
            >
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '24px', sm: '32px' },
                    fontWeight: 600,
                    mr: '48px',
                  }}
                >
                  Departments
                </Typography>
                <PermissionWrapper
                  requiredPermissions={[
                    {
                      domain: PERMISSION_DOMAINS.ORGANIZATION,
                      action: PERMISSION_ACTIONS.CREATE,
                    },
                  ]}
                  hide
                >
                  <PlusIcon
                    onClick={() => handleAddDepartmentDrawer()}
                    sx={{
                      width: '32px',
                      height: '32px',
                      cursor: 'pointer',
                      color: 'primary.main',
                      display: { xs: 'block', sm: 'none' },
                    }}
                  />
                </PermissionWrapper>
              </Grid>
              <Grid
                item
                sx={{
                  width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
                  pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
                }}
              >
                <SearchBar
                  placeholder="Search Dept & Designation"
                  value={searchValue}
                  handleSearchInput={handleDepartmentSearchOnChange}
                />
              </Grid>
              <Grid
                item
                sx={{
                  pt: { xs: '20px', lg: '0', md: '20px', sm: '20px' },
                  display: { xs: 'none', sm: 'flex' },
                }}
              >
                <PermissionWrapper
                  requiredPermissions={[
                    {
                      domain: PERMISSION_DOMAINS.ORGANIZATION,
                      action: PERMISSION_ACTIONS.CREATE,
                    },
                  ]}
                  hide
                >
                  <Button onClick={() => handleAdd()} sx={{ width: { lg: 'auto', xs: '100%' } }}>
                    Add department <PlusIcon />
                  </Button>
                </PermissionWrapper>
              </Grid>
            </Grid>
          </Box>
        );
    }
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Box>
        {renderHeaders()}
        <Box sx={{ mt: '12px', mb: '48px' }}>
          <TabBar nestedRoutes={NESTED_ROUTES} pathName={pathName} />
        </Box>
        <Outlet />
      </Box>
    </Container>
  );
};
//
export default DepartmentsView;
