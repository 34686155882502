import { Drawer as MUIDrawer } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIconDrawer from '@mui/icons-material/Close';
//
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';

/**
 * Function that defines a common drawer component
 * @prop {boolean} open - Boolean to show/hide the drawer
 * @prop {function} onClose - Function to handle the onClose of the drawer
 * @prop {function} children - Components to render within the drawer
 * @prop {function} title - Title of the drawer
 * @returns {Drawer}
 */
const Drawer = ({ open, onClose, children, title }) => (
  <PermissionWrapper
    requiredPermissions={[
      {
        domain: PERMISSION_DOMAINS.SKILL,
        action: PERMISSION_ACTIONS.CREATE,
      },
    ]}
    hide
  >
    <MUIDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{
        height: '70vh',
        padding: '16px',
        display: { xs: 'block', sm: 'none' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: '16px',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
          }}
        >
          <CloseIconDrawer />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            mx: '16px',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', ml: 1, mb: 2 }}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
    </MUIDrawer>
  </PermissionWrapper>
);
//
export default Drawer;
