import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
//
import {
  selectAddUserSkillLoader,
  selectDeleteUserSkillLoader,
  selectUpdateUserLoader,
  selectUser,
  selectUserSkills,
} from 'features/profile/selectors';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import SKILL_TYPES from 'features/base/constants/skill-types';
import { getUniqueObjects, removeUnchanged } from 'features/base/helpers/object';
import { removeUnchangedArray } from 'features/base/helpers/array';
import { profileActions } from 'features/profile/slice';
import COLORS from 'features/base/constants/colors';
import SkillSlider from '../slider';
import SkillActionButton from '../action-button';

/**
 * Function that renders the current skill groups component on the profile skill page
 * @returns {<Grid>}
 */
const CurrentSkillGroups = ({
  userId,
  customizedSkillGroups,
  setCustomizedSkillGroups,
  getCustomizedSkillGroups,
  editMode,
  sliderUpdated,
  setSliderUpdated,
  setEditMode,
}) => {
  const dispatch = useDispatch();
  //
  const user = useSelector(selectUser);
  const userSkills = useSelector(selectUserSkills);
  const addUserSkillLoading = useSelector(selectAddUserSkillLoader);
  const deleteUserSkillLoading = useSelector(selectDeleteUserSkillLoader);
  const updateUserLoading = useSelector(selectUpdateUserLoader);
  //
  const loading = addUserSkillLoading || deleteUserSkillLoading || updateUserLoading;
  //
  const handleSave = () => {
    // Getting the new ratings and learning type values
    const newUserSkills = customizedSkillGroups.flatMap((skillGroup) => skillGroup?.userSkills);
    const uniqueNewUserSkills = getUniqueObjects(newUserSkills, 'id');
    // Getting the existing ratings and learning type values
    const existingUserSkills = userSkills.map((userSkill) => ({
      id: userSkill?.id,
      name: userSkill?.skillId?.name,
      rating: userSkill?.rating,
      learningType: userSkill?.learningType,
      skillId: userSkill?.skillId?.id,
    }));
    // Getting the user skill records that have been updated (improves performance by reducing the number of requests to be sent)
    const updatedUserSkills = removeUnchangedArray(existingUserSkills, uniqueNewUserSkills);
    // Getting the updated attribute of each changed user skill record (following the principle of PATCH requests)
    const structuredUserSkills = updatedUserSkills.map((updatedUserSkill) => {
      const initialUserSkill = existingUserSkills.find(
        (existingUserSkill) => existingUserSkill?.id === updatedUserSkill?.id
      );
      const modifiedUserSkill = removeUnchanged(
        { rating: initialUserSkill?.rating, learningType: initialUserSkill?.learningType },
        { rating: updatedUserSkill?.rating, learningType: updatedUserSkill?.learningType }
      );
      return { ...modifiedUserSkill, id: updatedUserSkill?.id };
    });
    dispatch(
      profileActions.updateUserSkills({
        userId,
        userSkills: structuredUserSkills,
      })
    );
    setEditMode(false);
  };
  //
  const handleCancelEdit = () => {
    setCustomizedSkillGroups(getCustomizedSkillGroups());
    setEditMode(false);
  };
  //
  const handleUserSkillOnChange = (skillId, updateKey, updateValue) => {
    const updatedCustomizedSkillGroups = customizedSkillGroups.map((skillGroup) => {
      const updatedSkills = skillGroup?.userSkills?.map((userSkill) =>
        userSkill?.skillId === skillId
          ? {
              ...userSkill,
              // Setting the rating to 0 when the N/A button is clicked
              rating: updateValue === SKILL_TYPES.NOT_APPLICABLE ? 0 : userSkill?.rating,
              [updateKey]: updateValue,
            }
          : userSkill
      );
      return { ...skillGroup, userSkills: updatedSkills };
    });
    setCustomizedSkillGroups(updatedCustomizedSkillGroups);
    setSliderUpdated(true);
  };
  //
  return (
    <Grid item xs={12} sm={12} lg={7} md={12} className="rightGrid">
      <Box sx={{ bgcolor: COLORS.SKY_BLUE, pb: { lg: '35px' }, pt: '15px', pl: '15px', pr: '15px' }}>
        <Typography variant="h5" className="typographyTitle">
          Skill Groups
          <Divider sx={{ pt: '10px' }} />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {customizedSkillGroups?.length > 0 &&
            (editMode ? (
              <Box sx={{ display: 'flex', pb: { xs: 2 } }}>
                <Box>
                  <Button
                    color="info"
                    sx={{ mr: 5, borderRadius: 0 }}
                    disabled={!sliderUpdated}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="info"
                    sx={{ mr: 5, borderRadius: 0 }}
                    variant="outlined"
                    onClick={handleCancelEdit}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                onClick={() => {
                  if (!loading) {
                    setEditMode(true);
                  }
                }}
                sx={{
                  cursor: loading ? 'not-allowed' : 'pointer',
                  pb: 2,
                }}
              >
                <BorderColorIcon
                  sx={{
                    color: loading ? 'lightgray' : (theme) => theme.palette.primary.main,
                  }}
                  style={{ fontSize: '1.2rem' }}
                />
                <Typography
                  variant="body2"
                  color={loading ? 'lightgray' : 'primary'}
                  sx={{ fontWeight: 'bold' }}
                >
                  Edit
                </Typography>
              </Stack>
            ))}
        </Box>
        {loading && (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading &&
          (user?.skillGroups?.length ? (
            <Box sx={{ overflow: 'auto', maxHeight: '230px' }}>
              {customizedSkillGroups?.map((skillGroup) => (
                <Box key={skillGroup?.id} sx={{ mb: 3 }}>
                  <Typography variant="body2" className="sliderTitle">
                    {skillGroup?.name}
                  </Typography>
                  {skillGroup?.userSkills?.map((userSkill) => (
                    <Box
                      key={userSkill?.id}
                      sx={{
                        width: { xs: '100%' },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', lg: 'row', md: 'row' },
                        pr: 1.5,
                      }}
                    >
                      <Typography
                        maxWidth={30}
                        variant="body1"
                        sx={{
                          width: { xs: '100%', lg: '100px', md: '100px' },
                          ml: { md: '100px', lg: '10px', sm: '20px', xs: '30px' },
                          mb: { xs: '5px' },
                        }}
                      >
                        {userSkill?.name}
                      </Typography>
                      <SkillSlider
                        userSkill={userSkill}
                        editMode={editMode}
                        handleUserSkillOnChange={handleUserSkillOnChange}
                      />
                      <SkillActionButton
                        userSkill={userSkill}
                        editMode={editMode}
                        handleUserSkillOnChange={handleUserSkillOnChange}
                        displayName="N/A"
                        skillLearningType={SKILL_TYPES.NOT_APPLICABLE}
                      />
                      <SkillActionButton
                        userSkill={userSkill}
                        editMode={editMode}
                        handleUserSkillOnChange={handleUserSkillOnChange}
                        displayName="I/L"
                        skillLearningType={SKILL_TYPES.INTERESTED_TO_LEARN}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          ) : (
            <Grid item sx={{ marginTop: 20 }} xs={12}>
              <CustomNoRowsOverlay message="No skill groups selected!" size />
            </Grid>
          ))}
      </Box>
    </Grid>
  );
};
//
export default CurrentSkillGroups;
