import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
//
import { notificationActions } from 'features/base/notifications/slice';
import { selectFileUploadLoader } from 'features/clients/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { Popup, MultiSelectAutoComplete } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { PROJECT_DOCUMENT_TAGS } from 'features/base/constants/project-tags';
import { selectEditProjectDocumentsLoading } from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { editDocumentValidation } from 'features/projects/validation/project-validation';

/**
 * Function that defines the popup form for editing a project document
 * @prop {Boolean} open - Boolean to show/hide the popup
 * @prop {Function} setOpen - Function to set the popup state
 * @prop {Object} selectedDocument - The document that the user is attempting to edit
 * @returns {Popup}
 */
const EditDocumentsModal = ({ open, setOpen, selectedDocument }) => {
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectEditProjectDocumentsLoading);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  //
  const { projectId } = useParams();
  //
  const dispatch = useDispatch();
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (open && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    tags: selectedDocument?.tags?.map((tag) => ({ id: tag, name: tag })),
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup open={open} onClose={handleOnClose} title="Edit document" mediumSize="660px">
        <Formik
          initialValues={initialValues}
          validationSchema={editDocumentValidation}
          onSubmit={(values) => {
            if (!isEqual(values, initialValues)) {
              dispatch(
                projectActions.editProjectDocument({
                  ...values,
                  tags: values?.tags?.map((tag) => tag?.name),
                  projectId,
                  documentId: selectedDocument?.id,
                })
              );
            }
          }}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, setFieldTouched }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <MultiSelectAutoComplete
                options={Object.values(PROJECT_DOCUMENT_TAGS).map((value) => ({
                  id: value,
                  name: value,
                }))}
                selectedOptions={values.tags}
                setSelectedOptions={setFieldValue}
                label="Tags"
                formikKey="tags"
                placeholder="Select Tags"
                error={Boolean(touched.tags && errors.tags)}
                errorMsg={touched.tags && errors.tags}
                onClose={() => setFieldTouched('tags', true)}
                optionsFormatter={(val) => val}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={loading || fileUploadLoading}
                rightIcon={loading || fileUploadLoading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditDocumentsModal;
