import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectWorkAreaLoader,
  selectAllWorkArea,
  selectSearch,
} from 'features/departments/selectors';
import { departmentActions } from 'features/departments/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { DataGrid } from 'features/base/components';
import { WORK_AREA_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import EditWorkAreaPopup from 'features/departments/components/edit-work-area';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  WORK_AREA_TABLE_ACTION_COLUMNS,
  WORK_AREA_TABLE_BASIC_COLUMNS,
} from 'features/base/utils/tables';
import AddWorkAreaForm from 'features/departments/components/add-workarea-form';

/**
 * Table component to render Work Area
 * @returns {Grid}
 */
const WorkArea = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const organizationUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ORGANIZATION,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const workAreas = useSelector(selectAllWorkArea);
  const isLoading = useSelector(selectWorkAreaLoader);
  const notification = useSelector(selectNotification);
  const search = useSelector(selectSearch);
  //
  const isFirst = useIsInitialize(workAreas);
  //
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [workAreaId, setWorkAreaId] = useState('');
  //
  useEffect(() => {
    dispatch(
      departmentActions.getAllWorkArea({
        query: `limit=${WORK_AREA_PAGINATION_LIMIT}&sortBy=name:asc&page=1&search=${search}`,
      })
    );
  }, [search]);
  //
  const handleEdit = (id) => {
    setEditModalOpen(true);
    setWorkAreaId(id);
    dispatch(notificationActions.resetNotification());
  };
  //
  const handleOnClose = () => {
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <>
      <EditWorkAreaPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        workAreaId={workAreaId}
      />
      <Grid
        container
        spacing={6}
        rowSpacing={3}
        display="flex"
        justifyContent="space-around"
        flexDirection="row"
      >
        <Grid item xs={12} md={6} lg mb={2}>
          <Box>
            {isFirst ? (
              <ShimmerTable row={4} col={2} />
            ) : (
              <DataGrid
                columns={
                  organizationUpdatePermissionsVerified
                    ? [...WORK_AREA_TABLE_BASIC_COLUMNS, ...WORK_AREA_TABLE_ACTION_COLUMNS]
                    : WORK_AREA_TABLE_BASIC_COLUMNS
                }
                rows={
                  workAreas?.docs?.length &&
                  workAreas?.docs?.map((workArea) => ({
                    ...workArea,
                    name: {
                      value: workArea?.name,
                    },
                    action: {
                      organizationUpdatePermissionsVerified,
                      isLoading,
                      handleEdit: (event) => {
                        event.stopPropagation();
                        handleEdit(workArea?.id);
                      },
                    },
                  }))
                }
                //
                handleChangePage={({ page }) => {
                  dispatch(
                    departmentActions.getAllWorkArea({
                      query: `limit=${WORK_AREA_PAGINATION_LIMIT}&sortBy=name:asc&page=${
                        page + 1
                      }&search=${search}`,
                    })
                  );
                }}
                page={workAreas.page - 1 ?? 0}
                totalDocs={workAreas?.totalDocs ?? 0}
                limit={workAreas?.limit ?? 10}
                loading={isLoading && isFirst !== true}
                pageSizeOptions={[10]}
                headSx={{ pt: 1, pb: 1, pr: 0 }}
                cellSx={{ pt: 1, pb: 1, pr: 0 }}
                rowHeight={workAreas?.docs?.length ? 52 : 200}
              />
            )}
          </Box>
        </Grid>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <Grid
            item
            xs={12}
            md={6}
            lg
            mb={2}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <AddWorkAreaForm />
          </Grid>
        </PermissionWrapper>
      </Grid>
    </>
  );
};
//
export default WorkArea;
