/**
 * Defines the API paths for Arsenal
 */
const API = {
  POST_ARSENAL_ITEM: {
    path: '/arsenal',
    method: 'POST',
  },
  GET_ARSENAL: {
    path: '/arsenal?:query',
    method: 'GET',
  },
  PATCH_ARSENAL_ITEM: {
    path: '/arsenal/:id',
    method: 'PATCH',
  },
  GET_USERS: {
    path: '/users?:query',
    method: 'GET',
  }
};
//
export default API;
