import { omitBy, isEqual as lodashIsEqual, uniqBy } from 'lodash';

/**
 * Get the key of a JSON object by its value
 * @param {Object} obj
 * @returns {String}
 */
export const getKeyByValue = (object, value) => {
  const foundKey = Object.keys(object).find((key) => object?.[key] === value);
  return foundKey || null;
};
/**
 * Check object is empty or not
 * @param {*} obj
 * @returns
 */
const isEmpty = (obj) => obj && !Object.keys(obj).length;
/**
 * Take in two objects and remove the attributes in the second object which are unchanged from the first object
 * @returns {Object}
 */
export const removeUnchanged = (initialObj, obj) =>
  omitBy(obj, (value, key) => lodashIsEqual(value, initialObj[key]));
/**
 * Take an object array and a key, and return all the unique objects based on the provided key
 * @param {Array[Object]} objects - The list of objects
 * @param {String} key - The key with which to check for object uniqueness (example: id)
 * @returns {Object}
 */
export const getUniqueObjects = (objects, key) => uniqBy(objects, key);
/**
 * Inverse a provided object
 * @param {Object} obj - Object to be inverted
 * @returns {Object}
 */
export const inverseObject = (obj) => {
  const inverted = {};
  Object.keys(obj).forEach((key) => {
    inverted[obj[key]] = key;
  });
  return inverted;
};
/**
 * Converts an object array, where each object contains an id key, to a comma seperated list of ids
 * @param {Array[Object]} objectList
 * @returns {String}
 */
export const getIdList = (objectList) =>
  // Use reduce() to concatenate 'id' values into a string
  objectList?.reduce(
    (accumulator, item) =>
      // Append a comma and the 'id' to the accumulator
      accumulator + (accumulator.length > 0 ? ',' : '') + (item?.id ?? ''),
    ''
  );
/**
 * Generates a lookup structure based on a provided key and value
 * @param {Array[Object]} objectList
 * @example
 * console.log(generateLookup([{id: 1, label: 'A'}, {id: 2, label: 'B'}], 'id', 'label'))
 * {
 *  '1': 'A',
 *  '2': 'B',
 * }
 * @returns {Object}
 */
export const generateLookup = (objectList, key, value) =>
  // Use reduce() to concatenate 'id' values into a string
  objectList?.reduce((acc, projectObj) => {
    acc[projectObj?.[key]] = projectObj?.[value];
    return acc;
  }, {});
/**
 * Function that takes in two objects and checks if they are equal
 * @param {Object} firstObj
 * @param {Object} secondObj
 * @returns {Boolean}
 */
export const isEqual = (firstObj, secondObj) => lodashIsEqual(firstObj, secondObj);
//
export default isEmpty;
