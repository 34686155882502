import { useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
//
import Logo from 'features/base/assets/images/svg/logo.svg';
import ROUTES from 'features/base/constants/routes';
import COLORS from 'features/base/constants/colors';
import UserMenu from './user-menu';
import AppMenu from './menu';
import UserMenuMobile from './user-menu-mobile';

/**
 * Navigation Bar component
 */
const ResponsiveAppBar = () => {
  //
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  //
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  //
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  //
  const onLogoClick = () => {
    navigate(ROUTES.LINK_HUB);
  };
  //
  return (
    <AppBar sx={{ bgcolor: COLORS.NAVY_BLUE }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              display: { xs: 'none', md: 'flex' },
              height: 25,
              width: 80,
              ':hover': {
                cursor: 'pointer',
              },
            }}
            alt="Your logo."
            src={Logo}
            onClick={onLogoClick}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                '& .MuiMenu-paper': {
                  mt: 2,
                  ml: 2,
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  maxWidth: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  textAlign: 'center',
                  zIndex: 999,
                  backgroundColor: COLORS.NAVY_BLUE,
                  color: 'white',
                  ':hover': {
                    cursor: 'pointer',
                  },
                },
              }}
            >
              <MenuItem sx={{ justifyContent: 'right' }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleCloseNavMenu}
                  sx={{ color: 'white', ':hover': { color: 'red' } }}
                >
                  <CloseIcon />
                </IconButton>
              </MenuItem>
              <AppMenu handleCloseNavMenu={handleCloseNavMenu} anchorElNav={anchorElNav} />
            </Menu>
          </Box>
          <Typography
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                display: { xs: 'flex', md: 'none' },
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              alt="logo"
              src={Logo}
              onClick={onLogoClick}
            />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              textTransform: 'none',
            }}
          >
            <AppMenu />
          </Box>
          <UserMenu />
          <UserMenuMobile />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
//
export default ResponsiveAppBar;
