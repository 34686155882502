/* eslint-disable import/prefer-default-export */
/**
 * Available tags for projects
 * @type {{ SOW: string, SRS: string, BRS: string, EMAIL: string, INVOICE: string, CREDIT_NOTE: string }}
 */
export const PROJECT_DOCUMENT_TAGS = {
  SOW: 'SOW',
  SRS: 'SRS',
  BRS: 'BRS',
  EMAIL: 'Email',
  INVOICE: 'Invoice',
  CREDIT_NOTE: 'Credit Note',
};
