import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
//
import { selectAllWorkArea, selectEditWorkAreaLoader } from 'features/departments/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { departmentActions } from 'features/departments/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { editWorkAreaFormValidation } from 'features/departments/validations/work-area-validation';
import { specialCharsRegExp } from 'features/base/constants/regex';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Function that defines the popup form for editing a work area
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} workAreaId - id of the work area to be edited
 * @returns {Popup}
 */
const EditWorkAreaPopup = ({ editModalOpen, setEditModalOpen, workAreaId }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const allWorkAreas = useSelector(selectAllWorkArea);
  const workAreaToEdit = allWorkAreas?.docs?.find((workArea) => workArea.id === workAreaId);
  const loading = useSelector(selectEditWorkAreaLoader);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const checkSpecialChar = (event) => {
    if (!specialCharsRegExp.test(event.key)) {
      event.preventDefault();
    }
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    id: workAreaToEdit?.id,
    name: workAreaToEdit?.name,
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
    >
      <Popup open={editModalOpen} onClose={handleOnClose} title="Edit Work Area" mediumSize="660px">
        <Formik
          initialValues={initialValues}
          validationSchema={editWorkAreaFormValidation}
          onSubmit={(values) => {
            dispatch(departmentActions.editWorkArea({ ...values }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="edit-workArea-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onChange={handleChange}
                onKeyDown={(event) => checkSpecialChar(event)}
                onBlur={handleBlur}
                my={2}
                label="Work Area"
                multiline
                rows={1}
                placeholder={'Line1\nLine2'}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading || workAreaToEdit?.name === values?.name}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditWorkAreaPopup;
