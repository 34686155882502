/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { linkActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a link
 * @param {Object} payload
 */
export function* addLinksGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_LINK, payload);
    yield put(linkActions.addLinkSucceeded(response));
  } catch (error) {
    yield put(linkActions.addLinkFailed(error?.message));
  }
}
/**
 * Generator function for uploading logo
 * @param {Object} payload
 */
function* uploadLogoGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_LOGO, payload, true);
    yield put(linkActions.uploadLogoSucceeded(response));
  } catch (error) {
    yield put(linkActions.uploadLogoFailed(error?.message));
  }
}
/**
 * Generator function for getting all departments
 * @param {Object} payload
 */
export function* getAllDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS, payload);
    yield put(linkActions.getAllDepartmentSucceeded(response));
  } catch (error) {
    yield put(linkActions.getAllDepartmentFailed(error?.message));
  }
}
/**
 * Generator function for getting all links
 * @param {Object} payload
 */
export function* getLinksGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_LINKS, payload);
    yield put(linkActions.getAllLinksSucceeded(response));
  } catch (error) {
    yield put(linkActions.getAllLinksFailed(error?.message));
  }
}
/**
 * Generator function for editing a link
 * @param {Object} payload
 */
export function* editLinkGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_LINK, payload);
    yield put(linkActions.editLinkSucceeded(response));
  } catch (error) {
    yield put(linkActions.editLinkFailed(error?.message));
  }
}
/**
 * Generator function for patching a link status
 * @param {Object} payload
 * @param {Object} payload.id
 */
export function* patchLinksGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_LINK, payload);
    yield put(linkActions.patchLinkSucceeded(response));
  } catch (error) {
    yield put(linkActions.patchLinkFailed(error?.message));
  }
}
/**
 * Redux saga that triggers above generated functions
 */
export function* linkSaga() {
  yield takeLatest(linkActions.addLink.type, addLinksGenerator);
  yield takeLatest(linkActions.uploadLogo.type, uploadLogoGenerator);
  yield takeLatest(linkActions.getAllDepartment.type, getAllDepartmentsGenerator);
  yield takeLatest(linkActions.getAllLinks.type, getLinksGenerator);
  yield takeLatest(linkActions.editLink.type, editLinkGenerator);
  yield takeLatest(linkActions.patchLink.type, patchLinksGenerator);
}
//
export default linkSaga;
