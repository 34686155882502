import { Grid, IconButton, Stepper, Step, StepLabel, Typography } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
//
import STEPS from 'features/base/constants/create-team-steps';
import ROUTES from 'features/base/constants/routes';
import COLORS from 'features/base/constants/colors';
import { StyledStepIcon } from 'features/projects/components/style';
/**
 * Function that renders the stepper for the allocations process
 */
const AllocationStepper = ({ activeStep }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  return (
    <Grid
      item
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 4,
        mt: 4,
      }}
    >
      {activeStep === 1 && (
        <IconButton
          onClick={() =>
            navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION_CREATE, { projectId }))
          }
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {activeStep === 2 && (
        <IconButton
          onClick={() =>
            navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION_UPDATE, { projectId }))
          }
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StyledStepIcon}>
              {STEPS.indexOf(label) <= activeStep ? (
                <Typography sx={{ color: COLORS.DODGER_BLUE }}>{label}</Typography>
              ) : (
                label
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};
//
export default AllocationStepper;
