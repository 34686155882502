import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ShimmerThumbnail } from 'react-shimmer-effects';
//
import {
  selectCreateTeamIsProjectFetching,
  selectCreateTeamProject,
} from 'features/projects/selectors';
import COLORS from 'features/base/constants/colors';
import greenCalenderIcon from 'features/base/assets/images/svg/green-calender.svg';
import redCalenderIcon from 'features/base/assets/images/svg/red-calender.svg';
/**
 * Function that renders the start date and end date in the create team screen
 */
const DateRange = () => {
  const isProjectLoading = useSelector(selectCreateTeamIsProjectFetching);
  const project = useSelector(selectCreateTeamProject);
  //
  return (
    <Grid item>
      {isProjectLoading ? (
        <ShimmerThumbnail height={16} width={400} />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: COLORS.SUCCESS_MAIN,
              fontWeight: 400,
              mr: '32px',
            }}
          >
            Start <img src={greenCalenderIcon} alt="Calender-Icon" />{' '}
            {new Date(project?.startDate).toLocaleString('en-UK', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: COLORS.SECONDARY_MAIN,
              fontWeight: 400,
              ml: '10px',
            }}
          >
            End <img src={redCalenderIcon} alt="Calender-Icon" />{' '}
            {new Date(project?.endDate).toLocaleString('en-UK', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
//
export default DateRange;
