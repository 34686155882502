import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state) => state['feature/reports'] || initialState;
/**
 * Selector to retrieve allocations from the allocation report section of the report slice
 */
export const selectAllocationReportAllocations = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.allocations
);
/**
 * Selector to retrieve allocations from the allocation report section of the team slice
 */
export const selectTeamReportAllocations = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.allocations
);
/**
 * Selector to retrieve allocations from the allocation report section of the allocated vs actual slice
 */
export const selectAllocatedActualReportAllocations = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.allocations
);
/**
 * Selector to retrieve allocations from the allocation report section of the allocated vs actual export slice
 */
export const selectAllocatedActualReportExportAllocations = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReportExport?.allocations
);
/**
 * Selector to retrieve users list from the report slice
 */
export const selectUserList = createSelector([selectDomain], (reportState) => reportState.userList);
/**
 * Selector to retrieve users list from the allocation report section of the report slice
 */
export const selectAllocationReportUserList = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.userList
);
/**
 * Selector to retrieve users list from the team report section of the report slice
 */
export const selectTeamReportUserList = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.userList
);
/**
 * Selector to retrieve users list from the actual vs allocated report section of the report slice
 */
export const selectAllocatedActualReportUserList = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.userList
);
/**
 * Selector to retrieve users list from the capacity report section of the report slice
 */
export const selectCapacityReportUserList = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.userList
);
/**
 * Selector to retrieve projects list from the capacity report section of the report slice
 */
export const selectCapacityReportProjectList = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.projectList
);
/**
 * Selector to retrieve loading state of the project list from the capacity report section of the report slice
 */
export const selectCapacityReportProjectsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.projectsLoading
);
/**
 * Selector to retrieve users list from the salary report section of the report slice
 */
export const selectSalaryReportUserList = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.userList
);
/**
 * Selector to retrieve projects list from the allocation report section of the report slice
 */
export const selectAllocationReportProjectList = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.projectList
);
/**
 * Selector to retrieve projects list from the team report section of the report slice
 */
export const selectTeamReportProjectList = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.projectList
);
/**
 * Selector to retrieve projects list from the allocated vs actual report section of the report slice
 */
export const selectAllocatedActualReportProjectList = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.projectList
);
/**
 * Selector for getting allocations loading state of the allocation report section
 */
export const selectAllocationReportAllocationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.allocationsLoading
);
/**
 * Selector for getting allocations loading state of the team report section
 */
export const selectTeamReportAllocationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.allocationsLoading
);
/**
 * Selector for getting allocations loading state of the allocated vs actual report section
 */
export const selectAllocatedActualReportAllocationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.allocationsLoading
);
/**
 * Selector for getting patch allocations loading state of the allocation report section
 */
export const selectAllocationReportPatchAllocationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.patchAllocationsLoading
);
/**
 * Selector for getting users list loading state of the allocation report section
 */
export const selectAllocationReportUsersLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.usersLoading
);
/**
 * Selector for getting users list loading state of the team report section
 */
export const selectTeamReportUsersLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.usersLoading
);
/**
 * Selector for getting users list loading state of the allocated vs actual report section
 */
export const selectAllocatedActualReportUsersLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.usersLoading
);
/**
 * Selector for getting users list loading state of the capacity report section
 */
export const selectCapacityReportUsersListLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.usersLoading
);
/**
 * Selector for getting users list loading state of the salary report section
 */
export const selectSalaryReportUsersListLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.usersLoading
);
/**
 * Selector for getting projects list loading state of the allocation report section
 */
export const selectAllocationReportProjectsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.projectsLoading
);
/**
 * Selector for getting projects list loading state of the team report section
 */
export const selectTeamReportProjectsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.projectsLoading
);
/**
 * Selector for getting projects list loading state of the allocated vs actual report section
 */
export const selectAllocatedActualReportProjectsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.projectsLoading
);
/**
 * Selector for getting is initial call state of the allocation report section
 */
export const selectAllocationReportIsInitialCall = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.isInitial
);
/**
 * Selector for getting is initial call state of the allocation report section
 */
export const selectTeamReportIsInitialCall = createSelector(
  [selectDomain],
  (reportState) => reportState.teamReport?.isInitial
);
/**
 * Selector for getting is initial call state of the allocation report section
 */
export const selectAllocatedActualReportIsInitialCall = createSelector(
  [selectDomain],
  (reportState) => reportState.allocatedActualReport?.isInitial
);
/**
 * Selector for getting is initial call state of the capacity report section
 */
export const selectCapacityReportIsInitialCall = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.isInitial
);
/**
 * Selector for getting is initial call state of the salary report section
 */
export const selectSalaryReportIsInitialCall = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.isInitial
);
/**
 * Selector for getting skill stats from the skill report of reports slice
 */
export const selectSkillStats = createSelector(
  [selectDomain],
  (reportState) => reportState.skillReport?.skillStats
);
/**
 * Selector for getting skill stats loading state of the skill report
 */
export const selectSkillStatsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.skillReport?.skillStatsLoading
);
/**
 * Selector for getting designations list from reports slice
 * @param {Object} state
 * @returns {Array} designationsList
 */
export const selectCapacityReportDesignationsList = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.designationsList
);
/**
 * Selector for getting designations loading state
 * @param {Object} state
 * @returns {Boolean} designationsLoading
 */
export const selectCapacityReportDesignationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.designationsLoading
);
/**
 * Selector for getting work areas list from reports slice
 * @param {Object} state
 * @returns {Array} workAreasList
 */
export const selectCapacityReportWorkAreasList = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.workAreasList
);
/**
 * Selector for getting work areas loading state
 * @param {Object} state
 * @returns {Boolean} workAreasLoading
 * */
export const selectCapacityReportWorkAreasLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.workAreasLoading
);
/**
 * Selector for getting capacity report users
 * @param {Object} state
 * @returns {Array} capacityReportUsers
 * */
export const selectCapacityReportUsers = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.capacityReportUsers
);
/**
 * Selector for getting capacity report users loading state
 * @param {Object} state
 * @returns {Boolean} capacityReportUsersLoading
 * */
export const selectCapacityReportUsersLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.capacityReport?.capacityReportUsersLoading
);
/**
 * Selector for getting salary report departments from reports slice
 */
export const selectSalaryReportDepartments = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.departments
);
/**
 * Selector for getting salary report departments loading state
 */
export const selectSalaryReportDepartmentsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.departmentsLoading
);
/**
 * Selector for getting users for salary report
 */
export const selectSalaryReportUsers = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.salaryReportUsers
);
/**
 * Selector for getting loading state of salary report users
 */
export const selectSalaryReportUsersLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.salaryReport?.salaryReportUsersLoading
);
/**
 * Selector for getting toBe reallocated allocation
 */
export const selectToBeReallocatedAllocation = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.toBeReallocatedAllocation
);
/**
 * Selector for getting reallocation loading state
 */
export const selectToBeReallocatedAllocationLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.toBeReallocatedAllocationLoading
);
/**
 * Selector for getting reallocation table allocations
 */
export const selectReallocationTableAllocations = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.reallocationTableAllocations
);
/**
 * Selector for getting reallocation table allocations loading state
 */
export const selectReallocationTableAllocationsLoading = createSelector(
  [selectDomain],
  (reportState) => reportState.allocationReport?.reallocationTableAllocationsLoading
);
