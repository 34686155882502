import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { useParams } from 'react-router-dom';
//
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import { clientActions } from 'features/clients/slice';
import { CLIENTS_USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import {
  selectClient,
  selectLoader,
  selectEditClientLoader,
  selectInviteClientUserLoading,
} from 'features/clients/selectors';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  CLIENT_USERS_TABLE_ACTION_COLUMNS,
  CLIENT_USERS_TABLE_COLUMNS_BASIC,
} from 'features/base/utils/tables';
import { DataGrid } from 'features/base/components';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { notificationActions } from 'features/base/notifications/slice';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { phoneNumberFormatter } from 'features/base/helpers/phone-numbers';
import COLORS from 'features/base/constants/colors';
import EditClientUsersModal from '../edit-client-users-modal';
import DeleteClientUserPopup from '../delete-client-users-modal';

/**
 * Component that defines the client view
 * @returns MUI Grid with the client view
 */
const ClientUsersTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const clientUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.CLIENT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const inviteClientUserLoading = useSelector(selectInviteClientUserLoading);
  const client = useSelector(selectClient);
  const notification = useSelector(selectNotification);
  const editClientLoader = useSelector(selectEditClientLoader);
  //
  const { id } = useParams();
  //
  const isFirst = useIsInitialize(client);
  //
  const [makePrimaryConfirmationModalOpen, setMakePrimaryConfirmationModalOpen] = useState();
  const [inviteConfirmationModalOpen, setInviteConfirmationModalOpen] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  //
  const handleOpenMakePrimaryConfirmationModal = (userId) => {
    setSelectedUserId(userId);
    setMakePrimaryConfirmationModalOpen(true);
  };
  //
  const handleOpenInvitationConfirmationModal = (userId) => {
    setSelectedUserId(userId);
    setInviteConfirmationModalOpen(true);
  };
  //
  const handlePrimaryUserOnChange = () => {
    dispatch(clientActions.editClient({ id, primaryUser: selectedUserId }));
  };
  //
  const handleInvitationOnChange = () => {
    dispatch(clientActions.inviteClientUser({ id: selectedUserId }));
  };
  //
  const handleEditModalOpen = (userId) => {
    setSelectedUserId(userId);
    setEditModalOpen(true);
  };
  //
  const handleDeleteModalOpen = (userId) => {
    setSelectedUserId(userId);
    setDeleteModalOpen(true);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      if (makePrimaryConfirmationModalOpen) {
        setMakePrimaryConfirmationModalOpen(false);
      }
      if (inviteConfirmationModalOpen) {
        setInviteConfirmationModalOpen(false);
      }
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <ConfirmationPopup
        open={makePrimaryConfirmationModalOpen}
        displayMessage="Do you really want to change the Primary user of the client?"
        handleOnClose={() => setMakePrimaryConfirmationModalOpen(false)}
        handleOnConfirmation={handlePrimaryUserOnChange}
        loading={editClientLoader}
      />
      <ConfirmationPopup
        open={inviteConfirmationModalOpen}
        displayMessage="Do you want to send an email invitation to this user?"
        handleOnClose={() => setInviteConfirmationModalOpen(false)}
        handleOnConfirmation={handleInvitationOnChange}
        loading={inviteClientUserLoading}
      />
      <EditClientUsersModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedUserId={selectedUserId}
      />
      <DeleteClientUserPopup
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        id={id}
        userId={selectedUserId}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        {isFirst ? (
          <ShimmerTable row={4} col={6} />
        ) : (
          <DataGrid
            columns={
              clientUpdatePermissionsVerified
                ? [...CLIENT_USERS_TABLE_COLUMNS_BASIC, ...CLIENT_USERS_TABLE_ACTION_COLUMNS]
                : CLIENT_USERS_TABLE_COLUMNS_BASIC
            }
            rows={
              client?.users
                ?.toSorted((firstUser, secondUser) =>
                  firstUser?.userId?.firstName?.localeCompare(secondUser?.userId?.firstName)
                )
                .map((user) => ({
                  id: user.userId?.id,
                  profile: {
                    logo: user.userId?.profileImage,
                    name: `${user.userId?.firstName} ${user.userId?.lastName}`,
                  },
                  email: {
                    value: user.userId?.email || '-',
                  },
                  phone: {
                    value: user.userId?.phoneNumber
                      ? phoneNumberFormatter(user.userId?.phoneNumber)
                      : '-',
                  },
                  onboardingStatus: {
                    value: user?.userId?.onboarding ? 'Onboarded' : 'Pending',
                    bgColour: user?.userId?.onboarding ? COLORS.PALE_GREEN : COLORS.LAVENDER_MIST,
                    textColour: user?.userId?.onboarding ? COLORS.SUCCESS_MAIN : COLORS.NAVY_BLUE,
                  },
                  makePrimary: {
                    handleClick: () => {
                      handleOpenMakePrimaryConfirmationModal(user.userId?.id);
                    },
                    checked: user?.userId?.id === client?.primaryUser?.id,
                    loading,
                  },
                  invitation: {
                    handleClick: () => {
                      handleOpenInvitationConfirmationModal(user.userId?.id);
                    },
                    value: user?.userId?.invitationTimeStamp ? 'Invitation sent' : 'Send invite',
                    timeStamp: user?.userId?.invitationTimeStamp,
                    bgColour: user?.userId?.invitationTimeStamp
                      ? COLORS.LAVENDER_MIST
                      : COLORS.LIGHT_DODGER_BLUE,
                    textColour: user?.userId?.invitationTimeStamp
                      ? COLORS.NAVY_BLUE
                      : COLORS.DODGER_BLUE,
                    invitationTimeStamp: user?.userId?.invitationTimeStamp,
                    loading,
                  },
                  actions: {
                    handleClick: () => {
                      handleEditModalOpen(user?.userId?.id);
                    },
                    handleDelete: () => {
                      handleDeleteModalOpen(user?.userId?.id);
                    },
                  },
                })) ?? []
            }
            loading={loading && isFirst !== true}
            disableRowSelectionOnClick
            limit={CLIENTS_USERS_PAGINATION_LIMIT}
            pageSizeOptions={[CLIENTS_USERS_PAGINATION_LIMIT]}
            serverSidePagination={false}
            rowHeight={client?.users?.length ? 54 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default ClientUsersTable;
