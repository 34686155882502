import { DriveFileRenameOutline } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
/**
 * Department table columns - basic columns
 */
export const DEPARTMENT_TABLE_BASIC_COLUMNS = [
  {
    field: 'name',
    headerName: 'Department name',
    width: 220,
    valueGetter: (params) => params.row.name.value,
    renderCell: (params) => {
      const { value } = params.row.name;
      return (
        <Grid item xs={4} lg>
          {value}
        </Grid>
      );
    },
  },
  {
    field: 'designation',
    headerName: 'Designation',
    width: 760,
    valueGetter: (params) => params.row.designation.value,
    renderCell: (params) => {
      const { value } = params.row.designation;
      return (
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxLines: 2,
          }}
          lg
        >
          <Box
            sx={{
              maxWidth: '110ch',
              wordWrap: 'break-word',
              overflow: 'hidden',
              overflowWrap: 'anywhere',
              display: '-webkit-box',
              textOverflow: 'ellipsis',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {value}
          </Box>
        </Grid>
      );
    },
  },
  {
    field: 'tools',
    headerName: 'Tools',
    width: 400,
    valueGetter: (params) => params.row.tools.value,
    renderCell: (params) => {
      const { value } = params.row.tools;
      return (
        <Grid item xs={1} lg>
          {value}
        </Grid>
      );
    },
  },
];
/**
 * Department table columns - action column
 */
export const DEPARTMENT_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      const { organizationUpdatePermissionsVerified, isLoading, handleEdit } = params.row.action;
      return organizationUpdatePermissionsVerified ? (
        <Grid item xs={1} lg>
          <IconButton
            readOnly={isLoading}
            sx={{ '&:hover': { color: 'blue' } }}
            onClick={handleEdit}
          >
            <DriveFileRenameOutline />
          </IconButton>
        </Grid>
      ) : undefined;
    },
  },
];
