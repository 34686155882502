/* eslint-disable no-param-reassign */
import {
  PERMISSION_DOMAINS,
  PERMISSION_ACTIONS,
  PERMISSION_MATRIX_ACTION_INDEXES,
  PERMISSION_MATRIX_DOMAIN_INDEXES,
  PERMISSION_ACTIONS_LIST,
  DEPENDENCY_MATRIX,
  PERMISSION_DOMAINS_INVERSED,
  PERMISSION_ACTIONS_INVERSED,
} from '../constants/permissions';
import { getKeyByValue } from './object';
/**
 * Defines a function that generates a permission matrix based on the permissions listed in the constants file
 * When the constant file is updated, this is also updated
 * (first letter uppercase, rest lowercase)
 * @param {String} str Input string
 * @returns {String} Formatted string
 */
export const getDefaultPermissionMatrix = () =>
  Object.keys(PERMISSION_DOMAINS).map((domain) => {
    let result = {};
    const values = [false, false, false, false, false];
    const formattedDomain = PERMISSION_DOMAINS[domain];
    if (
      formattedDomain === PERMISSION_DOMAINS.USER_ORGANIZATION ||
      formattedDomain === PERMISSION_DOMAINS.USER_PERMISSION ||
      formattedDomain === PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT ||
      formattedDomain === PERMISSION_DOMAINS.TICKET_HANDLING ||
      formattedDomain === PERMISSION_DOMAINS.CONFIG
    ) {
      values[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
      result = {
        key: domain,
        values,
      };
    } else if (
      formattedDomain === PERMISSION_DOMAINS.REPOSITORY ||
      formattedDomain === PERMISSION_DOMAINS.DEPLOYMENT
    ) {
      values[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] = null;
      result = {
        key: domain,
        values,
      };
    } else {
      result = {
        key: domain,
        values,
      };
    }
    return result;
  });
/**
 * Function that checks if a given permission list contains all possible operations
 * @param {List} actions - Permission action list
 */
export const includesAllActions = (actions) =>
  actions?.includes(PERMISSION_ACTIONS.CREATE) &&
  actions?.includes(PERMISSION_ACTIONS.READ) &&
  actions?.includes(PERMISSION_ACTIONS.UPDATE) &&
  actions?.includes(PERMISSION_ACTIONS.DELETE);
/**
 * Takes in the users current permission object array and creates the data structure required to build the corresponding checkbox matrix
 * @param {List[Object]} permissionsToBuild - Object array with the permission data to be converted to a matrix
 * @returns Object array in the following format: [{key: domainName, values: [false, true, false, false, true]}]
 */
export const buildPermissionMatrix = (permissionsToBuild) => {
  const currentPermissionDomains = Object.keys(PERMISSION_DOMAINS);
  const matrix = currentPermissionDomains.map((domain) => {
    const row = [false, false, false, false, false];
    const formattedDomain = PERMISSION_DOMAINS[domain];
    if (permissionsToBuild) {
      if (permissionsToBuild[formattedDomain]?.includes(PERMISSION_ACTIONS.READ)) {
        row[PERMISSION_MATRIX_ACTION_INDEXES.READ] = true;
      }
      if (permissionsToBuild[formattedDomain]?.includes(PERMISSION_ACTIONS.CREATE)) {
        row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = true;
      }
      if (permissionsToBuild[formattedDomain]?.includes(PERMISSION_ACTIONS.UPDATE)) {
        row[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] = true;
      }
      if (permissionsToBuild[formattedDomain]?.includes(PERMISSION_ACTIONS.DELETE)) {
        row[PERMISSION_MATRIX_ACTION_INDEXES.DELETE] = true;
      }
    }
    // Disabling checkboxes of required domains
    if (formattedDomain === PERMISSION_DOMAINS.USER_ORGANIZATION) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.USER_PERMISSION) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.REPOSITORY) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.DEPLOYMENT) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.TICKET_HANDLING) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
    }
    if (formattedDomain === PERMISSION_DOMAINS.CONFIG) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = null;
    }
    // If every action is either true or null (disabled), it means all possible actions are checked
    if (
      row.every((value, index) => {
        if (index === PERMISSION_MATRIX_ACTION_INDEXES.ALL) {
          return true;
        }
        return value === true || value === null;
      })
    ) {
      row[PERMISSION_MATRIX_ACTION_INDEXES.ALL] = true;
    }
    //
    return {
      key: domain,
      values: row,
    };
  });
  return matrix;
};
/**
 * Recursive function to handle matrix click dependencies
 * @param {Number} domainIndex - Index of the domain that is currently being traversed
 * @param {Number} actionIndex - Index of the action that is currently being traversed
 * @param {Boolean} selected - Boolean that indicates if the current action is a select or a deselect
 * @param {Array} permissions - Array indicating the current permissions
 * @param {Array} newPermissions - Array that gets updated with the new permissions with each traversal
 */
export const updatePermissionsMatrix = (
  domainIndex,
  actionIndex,
  selected,
  permissions,
  newPermissions
) => {
  const domainKey = getKeyByValue(PERMISSION_MATRIX_DOMAIN_INDEXES, domainIndex);
  const domainValue = PERMISSION_DOMAINS[domainKey];
  const actionKey = getKeyByValue(PERMISSION_MATRIX_ACTION_INDEXES, actionIndex);
  const actionValue = PERMISSION_ACTIONS[actionKey];
  const permissionValuesForDomain = permissions[domainIndex]?.values;
  // For all domains, if create, update, or delete permission is checked, auto check the view permission
  if (
    (actionIndex === PERMISSION_MATRIX_ACTION_INDEXES.CREATE ||
      actionIndex === PERMISSION_MATRIX_ACTION_INDEXES.UPDATE ||
      actionIndex === PERMISSION_MATRIX_ACTION_INDEXES.DELETE) &&
    selected
  ) {
    if (permissionValuesForDomain[PERMISSION_MATRIX_ACTION_INDEXES.READ] !== null) {
      newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.READ] = true;
    }
  }
  // For all domains, if view permission is removed, auto remove create, update and delete permissions
  if (actionIndex === PERMISSION_MATRIX_ACTION_INDEXES.READ && !selected) {
    // If condition prevents disabled checkboxes becoming enabled again
    if (permissionValuesForDomain[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] !== null) {
      newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.CREATE] = false;
    }
    if (permissionValuesForDomain[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] !== null) {
      newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.UPDATE] = false;
    }
    if (permissionValuesForDomain[PERMISSION_MATRIX_ACTION_INDEXES.DELETE] !== null) {
      newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.DELETE] = false;
    }
  }
  //
  const allChecked = permissionValuesForDomain[PERMISSION_MATRIX_ACTION_INDEXES.ALL];
  // If un tick any from CRUD, while all select is ticked
  if (allChecked && !selected)
    newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.ALL] = false;
  // If click on remaining checkbox, while all select is not ticked
  if (!allChecked && selected) {
    // Check if all the other checkboxes aside from the one that was clicked newly were already checked, and update the all checkbox
    const temp = permissionValuesForDomain.filter(
      (value, arrayIndex) =>
        arrayIndex !== actionIndex &&
        arrayIndex !== PERMISSION_MATRIX_ACTION_INDEXES.ALL &&
        value !== null
    );
    const allSelected = temp.every((value) => value);
    if (allSelected)
      newPermissions[domainIndex].values[PERMISSION_MATRIX_ACTION_INDEXES.ALL] = true;
  }
  // Update the checkboxes for current domain and action
  const tempValues = permissions[PERMISSION_MATRIX_DOMAIN_INDEXES[domainKey]]?.values;
  tempValues[PERMISSION_MATRIX_ACTION_INDEXES[actionKey]] = selected;
  newPermissions[PERMISSION_MATRIX_DOMAIN_INDEXES[domainKey]].values = tempValues;
  // Get the current dependencies for the given domain and action
  const dependencies = DEPENDENCY_MATRIX[domainValue]?.[actionValue];
  // Recursively update permissions for dependent domains and actions
  dependencies?.forEach((dependency) => {
    const { DOMAIN, ACTION, SELECTED, RESULT } = dependency;
    if (selected === SELECTED) {
      // Since DOMAIN and ACTION are actually values, use the inverse of the matrixes to obtain a 
      // direct lookup instead of searching by value to find key
      const dependencyDomainKey = PERMISSION_DOMAINS_INVERSED[DOMAIN]
      const dependencyActionKey = PERMISSION_ACTIONS_INVERSED[ACTION]
      // Make the recursive call with each dependency
      updatePermissionsMatrix(
        PERMISSION_MATRIX_DOMAIN_INDEXES[dependencyDomainKey],
        PERMISSION_MATRIX_ACTION_INDEXES[dependencyActionKey],
        RESULT,
        permissions,
        newPermissions
      );
    }
  });
};
/**
 * A general function that handles the permission matrix on click actions, including the syncing
 * @param {Object} permissions - Permissions object that has been rendered by the matrix
 * @param {Number} domainIndex - Domain index of the matrix (Y-axis)
 * @param {Number} actionIndex - Action index of the matrix (X-axis)
 * @returns An updated permission matrix after a click
 */
export const handleMatrixClick = (permissions, domainIndex, actionIndex) => {
  const permissionValues = permissions[domainIndex].values;
  // Get whether the box is going to be ticked or unticked with this click
  const selected = !permissionValues[actionIndex];
  let updatedPermissionValues = permissionValues;
  // Click on 'all' checkbox (true or false)
  if (actionIndex === PERMISSION_MATRIX_ACTION_INDEXES.ALL) {
    updatedPermissionValues = permissionValues.map((value) => (value !== null ? selected : value));
    const newPermissions = [...permissions];
    newPermissions[domainIndex].values = updatedPermissionValues;
    // If 'all' is clicked, call the recursive update function for all actions
    Object.keys(PERMISSION_MATRIX_ACTION_INDEXES).forEach((key) => {
      if (PERMISSION_MATRIX_ACTION_INDEXES[key] !== PERMISSION_MATRIX_ACTION_INDEXES.ALL) {
        updatePermissionsMatrix(
          domainIndex,
          PERMISSION_MATRIX_ACTION_INDEXES[key],
          selected,
          permissions,
          newPermissions
        );
      }
    });
    return newPermissions;
  }
  const newPermissions = [...permissions];
  newPermissions[domainIndex].values = updatedPermissionValues;
  // If another action aside from 'all' is clicked, recursively update the box along with its dependencies
  updatePermissionsMatrix(domainIndex, actionIndex, selected, permissions, newPermissions);
  //
  return newPermissions;
};
/**
 * A general function that handles the formatting of the permission object to the required form before submitting ==> domain: [actions]
 * @param {Object} permissions - Permissions object to be converted before sending the request to backend
 * @returns Formatted permission object
 */
export const onSubmitFormatter = (permissions) => {
  const result = {};
  // Set values as create, read, update, delete
  permissions.forEach(({ key, values }) => {
    const tempPermissions = [];
    const tempValues = [...values];
    // Remove the first element since it represents the 'all' option
    tempValues.shift();
    tempValues.forEach(
      (value, index) => value && tempPermissions.push(PERMISSION_ACTIONS_LIST[index])
    );
    const newKey = PERMISSION_DOMAINS[key];
    result[newKey] = tempPermissions;
  });
  //
  return result;
};
/**
 * Function that takes a decoded access token and provides the permissions formatted as a JSON object
 * @param {Object} decodedToken - Decoded access token
 * @returns {Object}
 * @example
 * {
 *    user: ['create']
 *    project: ['create', 'read']
 * }
 */
export const getFormattedPermissions = (decodedToken) => {
  const permissions = decodedToken?.authorization?.permissions;
  if (permissions) {
    const formattedPermissions = permissions.reduce((acc, permission) => {
      acc[permission.rsname] = permission.scopes;
      return acc;
    }, {});
    return formattedPermissions;
  }
  return {};
};
