import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/vendors'] || initialState;
/**
 * Selector for getting loader state
 */
export const selectLoader = createSelector([selectDomain], (vendorState) => vendorState.loading);
/**
 * Selector for getting all vendors
 */
export const selectAllVendors = createSelector(
  [selectDomain],
  (vendorState) => vendorState.allVendors
);
/**
 * Selector for getting add vendor loader state
 */
export const selectAddVendorLoader = createSelector(
  [selectDomain],
  (vendorState) => vendorState.addVendorLoading
);
/**
 * Selector for getting edit vendor loader state
 */
export const selectEditVendorLoader = createSelector(
  [selectDomain],
  (vendorState) => vendorState.editVendorLoading
);
/**
 * Selector for getting delete vendor loader state
 */
export const selectDeleteVendorLoader = createSelector(
  [selectDomain],
  (vendorState) => vendorState.deleteVendorLoading
);
/**
 * Selector for getting uploaded logo
 */
export const selectUploadedLogo = createSelector(
  [selectDomain],
  (vendorState) => vendorState.uploadedLogo
);
/**
 * Selector for getting upload file loader state
 */
export const selectUploadFileLoader = createSelector(
  [selectDomain],
  (vendorState) => vendorState.uploadFileLoading
);
/**
 * Selector for getting verify employee loader state
 */
export const selectVerifyEmployeeLoader = createSelector(
  [selectDomain],
  (vendorState) => vendorState.verifyEmployeeLoading
);
/**
 * Selector for getting verify employee data
 */
export const selectVerifyEmployeeData = createSelector(
  [selectDomain],
  (vendorState) => vendorState.verifyEmployeeData
);
