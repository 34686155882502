import { Box, Typography, Grid } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
//
import { SearchBar } from 'features/base/components';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';

/**
 * Renders the skills header
 */
const SkillsHeader = ({ setAddSkillsDrawerOpen, searchValue, handleSearch }) => (
  <Box>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
        pb: 4,
      }}
    >
      <Grid
        item
        sx={{
          width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '24px', sm: '32px' },
            fontWeight: 600,
            mr: '48px',
          }}
        >
          Skills
        </Typography>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <PlusIcon
            onClick={() => setAddSkillsDrawerOpen(true)}
            sx={{
              width: '32px',
              height: '32px',
              cursor: 'pointer',
              color: 'primary.main',
              display: { xs: 'block', sm: 'none' },
            }}
          />
        </PermissionWrapper>
      </Grid>
      <Grid
        item
        sx={{
          width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
          pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
        }}
      >
        <SearchBar
          placeholder="Search skills"
          value={searchValue}
          handleSearchInput={handleSearch}
        />
      </Grid>
    </Grid>
  </Box>
);
//
export default SkillsHeader;
