/* eslint-disable import/prefer-default-export */
/**
 * Available severity types
 * @type {{LOW: string, MEDIUM: string, HIGH: string}}
 */
export const SEVERITY_TYPES = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};
