import { Avatar, Grid, Typography } from '@mui/material';
/**
 * View client users table columns - basic details
 */
const CLIENT_PROJECTS_TABLE_COLUMNS_BASIC = [
  {
    field: 'project',
    headerName: 'Project',
    width: 530,
    sortComparator: (firstValue, secondValue) => firstValue.name.localeCompare(secondValue.name),
    renderCell: ({ value: { logo, name } }) => (
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={1} md={1}>
          {logo ? (
            <img
              src={logo}
              alt="logo"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Avatar
              sx={{
                height: '40px',
                width: '40px',
              }}
            >
              {name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Grid>
        <Grid item xs={11} md={11}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    width: 300,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    width: 300,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'margin',
    headerName: 'Margin',
    width: 300,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
];
//
export default CLIENT_PROJECTS_TABLE_COLUMNS_BASIC;
