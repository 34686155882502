import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from 'features/sign-in/slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/sign-in'] || initialState;
/**
 * Function to retrieve the entire sign in slice
 */
export const selectSignInState = createSelector([selectDomain], (signInState) => signInState);
/**
 * Function to retrieve the loader state from the sign in slice
 */
export const selectLoader = createSelector([selectDomain], (signInState) => signInState.loading);
/**
 * Obtain sign in error code from sign in slice
 */
export const selectSignInErrorCode = createSelector(
  [selectDomain],
  (signInState) => signInState.errorCode
);
