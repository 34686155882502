import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, CircularProgress, TableContainer, Paper, Table } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import { reportActions } from 'features/reports/slice';
import {
  selectAllocationReportAllocations,
  selectAllocationReportAllocationsLoading,
  selectAllocationReportIsInitialCall,
  selectAllocationReportProjectList,
  selectAllocationReportProjectsLoading,
  selectAllocationReportUserList,
  selectAllocationReportUsersLoading,
} from 'features/reports/selectors';
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import createFormattedString from 'features/base/helpers/param-formatter';
import {
  FINANCIAL_YEAR_START_MONTH,
  FINANCIAL_YEAR_END_MONTH,
} from 'features/base/constants/report-data';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import ROUTES from 'features/base/constants/routes';
import { generateLookup, getIdList } from 'features/base/helpers/object';
import TIME_OUTS from 'features/base/constants/time-outs';
import {
  EditHoursPopup,
  DeleteHoursPopup,
  ReallocateHoursPopup,
  Filters,
  ProjectInfo,
  TableHeader,
  TableBody,
} from './components';
import './index.scss';

/**
 * Defines the allocation report section
 * @returns {Grid}
 */
const AllocationReport = () => {
  const dispatch = useDispatch();
  //
  const location = useLocation();
  const navigate = useNavigate();
  // Function to generates a view period array specifying the period the allocation report is generated for
  const generateViewPeriod = (startYear, startMonth, endYear, endMonth) => {
    const months = [];
    for (let year = startYear; year <= endYear; year += 1) {
      const startMonthOfYear = year === startYear ? startMonth : 1;
      const endMonthOfYear = year === endYear ? endMonth : 12;
      for (let month = startMonthOfYear; month <= endMonthOfYear; month += 1) {
        months.push({
          month,
          year,
        });
      }
    }
    return months;
  };
  // Function to parse query parameters from the URL
  const getQueryParameter = (name) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(name);
  };
  //
  const allocations = useSelector(selectAllocationReportAllocations);
  const userList = useSelector(selectAllocationReportUserList);
  const projectList = useSelector(selectAllocationReportProjectList);
  const allocationsLoading = useSelector(selectAllocationReportAllocationsLoading);
  const usersLoading = useSelector(selectAllocationReportUsersLoading);
  const projectsLoading = useSelector(selectAllocationReportProjectsLoading);
  const isInitialCall = useSelector(selectAllocationReportIsInitialCall);
  //
  // Note: viewPeriod is maintained instead of selectedYear for the implementation of the project duration toggle feature
  const [viewPeriod, setViewPeriod] = useState(
    generateViewPeriod(
      moment().year(),
      FINANCIAL_YEAR_START_MONTH,
      moment().year() + 1,
      FINANCIAL_YEAR_END_MONTH
    )
  );
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedProjects, setSelectedProjects] = useState();
  const [selectedYear, setSelectedYear] = useState({
    label: `${moment().year()} - ${moment().year() + 1}`,
    value: moment().year(),
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [reallocateModalOpen, setReallocateModalOpen] = useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState({});
  const [page, setPage] = useState(1);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [debounceLoading, setDebounceLoading] = useState(true);
  const [lastParams, setLastParams] = useState(null);
  const [isProjectDurationEnabled, setIsProjectDurationEnabled] = useState(false);
  //
  const loading = allocationsLoading || usersLoading || projectsLoading;
  //
  const userMap = useMemo(() => generateLookup(userList, 'id', 'email'), [userList]);
  const projectMap = useMemo(() => generateLookup(projectList, 'id', 'name'), [projectList]);
  // Function to obtain the start and end date from the selected view period
  const getDateFromViewPeriod = () => {
    const start = viewPeriod?.[0];
    const end = viewPeriod?.[viewPeriod.length - 1];
    const formattedStartMonth = start?.month < 10 ? `0${start?.month}` : start?.month;
    const formattedEndMonth = end?.month < 10 ? `0${end?.month}` : end?.month;
    return {
      startDate: `${start?.year}-${formattedStartMonth}`,
      endDate: `${end?.year}-${formattedEndMonth}`,
    };
  };
  // Memoized query parameters
  const params = useMemo(
    () => ({
      aggregated: 'true',
      // For userIds and projectIds, if all are selected, a small optimization is done by sending null instead of all ids
      userIds:
        selectedUsers?.length >= userList?.length && userList?.length
          ? ''
          : getIdList(selectedUsers),
      projectIds:
        selectedProjects?.length >= projectList?.length && projectList?.length
          ? ''
          : getIdList(selectedProjects),
      limit: WORK_ALLOCATION_PAGINATION_LIMIT,
      sortBy: 'projectId.name:asc',
      page,
      startDate: getDateFromViewPeriod()?.startDate,
      endDate: getDateFromViewPeriod()?.endDate,
    }),
    [selectedUsers, selectedProjects, viewPeriod, userList, projectList, page]
  );
  /**
   * This is used as a central point of logged hours calculation, used for both rendering the report and generating the data for the excel file
   * Data structure (lookup structure that optimizes the code by reducing loops):
   * [{ projectId: 'id1', userId: 'id1', '01-2023': { allocatedHours: 20, cogsoh: 30, revenue: 1000, ...rest } },]
   */
  const allocationsByViewPeriod = useMemo(
    () =>
      allocations?.docs?.map((allocation) => {
        const allocationsDateLookup = {};
        allocation?.monthlyAllocations?.forEach((monthlyAllocation) => {
          const key = `${monthlyAllocation.year}-${monthlyAllocation.month}`;
          allocationsDateLookup[key] = monthlyAllocation;
        });
        const viewPeriodData = {};
        viewPeriod.forEach((period) => {
          const { year, month } = period;
          const key = `${year}-${month}`;
          const record = allocationsDateLookup[key];
          viewPeriodData[key] = record || {
            year,
            month,
            disabled: true,
            allocatedHours: 0,
            revenue: 0,
            cogsoh: 0,
          };
        });
        //
        return {
          ...viewPeriodData,
          id: allocation?.id,
          projectId: allocation?.projectId,
          userId: allocation?.userId,
          monthlyAllocations: allocation?.monthlyAllocations,
        };
      }),
    [allocations, viewPeriod]
  );
  // Function used by the infinite scrolling component to fetch the next set of data
  const fetchNextPage = () => {
    setPage(allocations?.page ? allocations.page + 1 : 0);
  };
  // Function to handle the edit event of editable cells
  const handleEdit = (allocation) => {
    setSelectedAllocation(allocation);
    setEditModalOpen(true);
  };
  // Function to handle the delete event of editable cells
  const handleDelete = (allocation) => {
    setSelectedAllocation(allocation);
    setRemoveModalOpen(true);
  };
  // Function to handle the reallocate event of editable cells
  const handleReallocate = (allocation) => {
    setSelectedAllocation(allocation);
    setReallocateModalOpen(true);
  };
  // Function to set page to first page and initialize the allocation slice
  const initilialize = () => {
    setPage(1);
    dispatch(reportActions.setAllocationReportIsInitial());
  };
  // This function is for fetching allocations with the current query params
  const fetchAllocations = () => {
    const formattedParamString = createFormattedString(params);
    dispatch(reportActions.getAllocationReportAllocations({ query: formattedParamString }));
    setDebounceLoading(false);
  };
  // This callback function is for fetching allocations with a specified debounce
  const debouncedFetch = useDebouncedCallback(
    fetchAllocations,
    TIME_OUTS.ALLOCATION_FETCH_DEBOUNCE
  );
  // This function handles the onChange event on the users filter
  const handleSelectedUsersChange = (newUsers) => {
    let formattedParams = '';
    const projectIdsFromUrl = getQueryParameter('project');
    const allSelected = newUsers.find((newUser) => newUser?.id === 'All');
    const allPreviouslySelected = selectedUsers?.find((user) => user?.id === 'All');
    let updatedUsers;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedUsers = getIdList(newUsers.filter((newUser) => newUser?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show 'All' for users
        updatedUsers = getIdList(userList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other users
      updatedUsers = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for users
      updatedUsers = getIdList(newUsers);
    }
    if (
      JSON.stringify({
        ...params,
        userIds:
          updatedUsers?.split(',')?.length === userList?.length
            ? ''
            : updatedUsers ?? params?.userIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if userIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: updatedUsers,
      project: projectIdsFromUrl,
    });
    navigate(`${ROUTES.ALLOCATION_REPORT}?${formattedParams}`);
  };
  // This function handles the onChange event on the projects filter
  const handleSelectedProjectsChange = (newProjects) => {
    let formattedParams = '';
    const userIdsFromUrl = getQueryParameter('user');
    const allSelected = newProjects.find((newProject) => newProject?.id === 'All');
    const allPreviouslySelected = selectedProjects?.find((project) => project?.id === 'All');
    let updatedProjects;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedProjects = getIdList(newProjects.filter((newProject) => newProject?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show all project ids
        updatedProjects = getIdList(projectList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other projects
      updatedProjects = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for projects
      updatedProjects = getIdList(newProjects);
    }
    if (
      JSON.stringify({
        ...params,
        projectIds:
          updatedProjects?.split(',')?.length === projectList?.length
            ? ''
            : updatedProjects ?? params?.projectIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if projectIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: userIdsFromUrl,
      project: updatedProjects,
    });
    navigate(`${ROUTES.ALLOCATION_REPORT}?${formattedParams}`);
  };
  //
  useEffect(() => {
    dispatch(
      reportActions.getAllocationReportUsers({ query: 'pagination=false&sortBy=email:asc' })
    );
    dispatch(
      reportActions.getAllocationReportProjects({ query: 'pagination=false&sortBy=name:asc' })
    );
  }, []);
  //
  useEffect(() => {
    // This check is used to prevent redundant api calls being sent due to the complex side effects in this file
    if (JSON.stringify(params) !== JSON.stringify(lastParams)) {
      if (!lastParams) {
        // In the initial call, send a debounced fetch after all the required processing has been completed
        debouncedFetch();
        setLastParams(params);
        return;
      }
      if (
        JSON.stringify({ userIds: params?.userIds, projectIds: params?.projectIds }) ===
        JSON.stringify({ userIds: lastParams?.userIds, projectIds: lastParams?.projectIds })
      ) {
        // If anything other than the filters has changed (ie: page), call the fetch api directly without a debounce
        fetchAllocations();
      } else {
        // If the filters has been updated, instead of sending an api call for each click,
        // use a debounced fetch to send a single request with multiple filters
        debouncedFetch();
      }
      setLastParams(params);
    }
  }, [params]);
  // This useEffect updates the project details when a single project is selected
  useEffect(() => {
    if (selectedProjects?.length === 1) {
      const currentSelectedProject = projectList?.find(
        (projectRecord) => projectRecord?.id === selectedProjects[0]?.id
      );
      setSelectedProjectDetails(currentSelectedProject);
    } else if (
      selectedProjects?.length === 2 &&
      selectedProjects.find((proj) => proj.id === 'All')
    ) {
      // If 'All' option is selected in addition to the one available project
      const currentSelectedProject = projectList?.find(
        (projectRecord) => projectRecord?.id !== 'All'
      );
      setSelectedProjectDetails(currentSelectedProject);
    } else {
      setIsProjectDurationEnabled(false);
      setSelectedProjectDetails(null);
    }
  }, [selectedProjects]);
  // This useEffect handles the changes for the 'project' query param in the url
  useEffect(() => {
    const usersFromUrl = getQueryParameter('user')?.split(',');
    const usersFromUrlFormatted = usersFromUrl?.map((userId) => ({
      id: userId,
      label: userMap?.[userId],
    }));
    if (usersFromUrl?.length === userList?.length) {
      setSelectedUsers(usersFromUrl ? [{ id: 'All', label: 'All' }, ...usersFromUrlFormatted] : []);
    } else {
      setSelectedUsers(usersFromUrl ? usersFromUrlFormatted : []);
    }
  }, [location.search, userList, userMap]);
  // This useEffect handles the changes for the 'user' query param in the url
  useEffect(() => {
    const projectsFromUrl = getQueryParameter('project')?.split(',');
    const projectsFromUrlFormatted = projectsFromUrl?.map((projectId) => ({
      id: projectId,
      label: projectMap?.[projectId],
    }));
    if (projectsFromUrl?.length === projectList?.length) {
      setSelectedProjects(
        projectsFromUrl ? [{ id: 'All', label: 'All' }, ...projectsFromUrlFormatted] : []
      );
    } else {
      setSelectedProjects(projectsFromUrl ? projectsFromUrlFormatted : []);
    }
  }, [location.search, projectList, projectMap]);
  // Cleanup function to improve performance
  useEffect(
    () => () => {
      dispatch(reportActions.resetAllocationReport());
    },
    []
  );
  //
  return (
    <>
      <EditHoursPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        selectedAllocation={selectedAllocation}
      />
      <DeleteHoursPopup
        removeModalOpen={removeModalOpen}
        setRemoveModalOpen={setRemoveModalOpen}
        selectedAllocation={selectedAllocation}
      />
      <ReallocateHoursPopup
        reallocateModalOpen={reallocateModalOpen}
        setReallocateModalOpen={setReallocateModalOpen}
        selectedAllocation={selectedAllocation}
      />
      <ProjectInfo
        selectedProjectDetails={selectedProjectDetails}
        initilialize={initilialize}
        params={params}
        loading={allocationsLoading || debounceLoading}
        allocationsByViewPeriod={allocationsByViewPeriod}
        selectedUsers={selectedUsers}
      />
      <Filters
        userList={userList}
        projectList={projectList}
        selectedUsers={selectedUsers}
        selectedProjects={selectedProjects}
        handleSelectedUsersChange={handleSelectedUsersChange}
        handleSelectedProjectsChange={handleSelectedProjectsChange}
        initilialize={initilialize}
        selectedProjectDetails={selectedProjectDetails}
        generateViewPeriod={generateViewPeriod}
        setViewPeriod={setViewPeriod}
        loading={loading}
        isProjectDurationEnabled={isProjectDurationEnabled}
        setIsProjectDurationEnabled={setIsProjectDurationEnabled}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
      <Grid container spacing={2} sx={{ pr: '1rem', pl: '1rem', mt: '1rem' }}>
        <Grid item xs={12}>
          {isInitialCall && (loading || debounceLoading) ? (
            <ShimmerTable row={4} col={8} />
          ) : (
            allocations?.docs?.length > 0 && (
              <InfiniteScroll
                dataLength={allocations?.docs?.length ?? 0}
                next={fetchNextPage}
                hasMore={allocations?.hasNextPage}
                loader={
                  <Box textAlign="center" margin="1.125rem">
                    <CircularProgress size={30} />
                  </Box>
                }
              >
                <TableContainer component={Paper} className="wrapper">
                  <Table sx={{ minWidth: 650, mb: '1.5rem', mt: '1rem' }}>
                    <TableHeader viewPeriod={viewPeriod} />
                    <TableBody
                      allocationsByViewPeriod={allocationsByViewPeriod}
                      viewPeriod={viewPeriod}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleReallocate={handleReallocate}
                    />
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            )
          )}
          {!allocations?.docs?.length && !loading && !debounceLoading && (
            <Grid item sx={{ marginTop: 20 }} xs={12}>
              <CustomNoRowsOverlay message="No allocations found!" size />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
//
export default AllocationReport;
