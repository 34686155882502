import * as yup from 'yup';
//
import { nameRegex, surgeEmailRegExp } from 'features/base/constants/regex';
import USER_CAPACITY from 'features/base/constants/capacity';
import { USER_TYPES, USER_LEVELS } from 'features/base/constants/user-types';
/**
 * Function for user form validation
 */
const userFormValidation = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed')
    .max(50, ({ max }) => `FirstName must be at maximum of ${max} characters`),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed')
    .max(50, ({ max }) => `LastName must be at maximum of ${max} characters`),
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email address')
    .matches(
      surgeEmailRegExp,
      'Internal/contract/intern users must add an email address under the surge domain'
    ),
  type: yup.string().required('Type is required'),
  workArea: yup.string().required('Work area is required').nullable(),
  capacity: yup
    .number()
    .typeError('Capacity must be a number')
    .required('Capacity is required')
    .min(
      USER_CAPACITY.MIN_CAPACITY,
      `Capacity must be greater than or equal to ${USER_CAPACITY.MIN_CAPACITY} `
    )
    .max(
      USER_CAPACITY.MAX_CAPACITY,
      `Capacity must be less than or equal to ${USER_CAPACITY.MAX_CAPACITY} `
    ),
  joinedDate: yup.date().when('type', {
    is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT, USER_TYPES.INTERN].includes(val),
    then: yup.date().required('Please enter date of appointment'),
  }),
  level: yup
    .string()
    .when('type', {
      is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT].includes(val),
      then: yup.string().required('Please assign a user level'),
    })
    .when('type', {
      is: USER_TYPES.INTERN,
      then: yup
        .string()
        .required('Please assign a user level')
        .test(
          'is-valid',
          `Interns can only be assigned ${USER_LEVELS.LEVEL_0}`,
          (value) => value === USER_LEVELS.LEVEL_0
        ),
    }),
});
//
export default userFormValidation;
