import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state) => state['feature/base-auth'] || initialState;
/**
 * Obtain authenticated user from auth slice
 */
export const selectAuthUser = createSelector([selectDomain], (authState) => authState.user);
/**
 * Obtain saved tokens from auth slice
 */
export const selectTokens = createSelector([selectDomain], (authState) => authState.tokens);
/**
 * Obtain access token from auth slice
 */
export const selectAccessToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.accessToken
);
/**
 * Obtain id token from auth slice
 */
export const selectIdToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.idToken
);
/**
 * Obtain refresh token from auth slice
 */
export const selectRefreshToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.refreshToken
);
/**
 * Obtain user email from auth slice
 */
export const selectUserEmail = createSelector(
  [selectDomain],
  (authState) => authState?.user?.email
);
/**
 * Obtain user id from auth slice
 */
export const selectUserId = createSelector([selectDomain], (authState) => authState?.user?.id);
/**
 * Obtain user type from auth slice
 */
export const selectUserType = createSelector([selectDomain], (authState) => authState?.user?.type);
/**
 * Obtain user permissions from slice
 */
export const selectUserPermissions = createSelector(
  [selectDomain],
  (authState) => authState.permissions
);
/**
 * Function to retrieve the onboarding state of the auth slice
 */
export const selectOnboarding = createSelector(
  [selectDomain],
  (authState) => authState.user?.onboarding
);
/**
 * Getting is session timed out state for dashboard component
 */
export const selectIsSessionTimedOut = createSelector(
  [selectDomain],
  (authState) => authState.isSessionTimedOut
);
/**
 * Function to retrieve the loading from the auth slice
 */
export const selectLLoading = createSelector([selectDomain], (authState) => authState.loading);
