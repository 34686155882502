import { Email as EmailIcon } from '@mui/icons-material';
import { Box, Checkbox } from '@mui/material';
import noProfileImage from 'features/base/assets/images/png/no-profile.png';
/**
 * External user table columns
 */
const EXTERNAL_USER_TABLE_HEADERS = [
  {
    field: 'profile',
    headerName: 'Profile',
    headerClassName: 'super-app-theme--header',
    width: 475,
    renderCell: ({ value: { img, name } }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
          src={img || noProfileImage}
          alt="profile-pic"
          referrerPolicy="no-referrer"
        />
        {name}
      </Box>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    headerClassName: 'super-app-theme--header',
    width: 450,
  },
  {
    field: 'companyName',
    headerName: 'Company name',
    headerClassName: 'super-app-theme--header',
    width: 475,
  },
  {
    field: 'externalNotifiers',
    headerName: 'External Notifiers',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto' }}
      >
        <EmailIcon />
      </Box>
    ),
    width: 75,
    renderCell: ({ value: { defaultChecked, onChange, isExternalUsersLoading, disabled } }) => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Checkbox
          defaultChecked={defaultChecked}
          disabled={isExternalUsersLoading || disabled}
          onChange={onChange}
        />
      </Box>
    ),
  },
];
//
export default EXTERNAL_USER_TABLE_HEADERS;
