import { createSlice } from '@reduxjs/toolkit';
//
import { userActions } from 'features/users/slice';
import { projectActions } from 'features/projects/slice';
import { supportTicketActions } from 'features/support-tickets/slice';
import { signInActions } from 'features/sign-in/slice';
import { opexActions } from 'features/opex/slice';
import { roleActions } from 'features/roles/slice';
import { toolActions } from 'features/tools/slice';
import { profileActions } from 'features/profile/slice';
import { internalOnboardingSliceActions } from 'features/internal-onboarding/slice';
import { skillActions } from 'features/skills/slice';
import { departmentActions } from 'features/departments/slice';
import { designationActions } from 'features/designations/slice';
import { clientActions } from 'features/clients/slice';
import { linkActions } from 'features/links/slice';
import { reportActions } from 'features/reports/slice';
import { arsenalActions } from 'features/arsenal/slice';
import { benefitsActions } from 'features/benefits/slice';
import { vendorActions } from 'features/vendors/slice';
import ERROR_TYPES from '../constants/error-types';

export const initialState = {
  isEnabled: false,
  message: '',
  type: ERROR_TYPES.SUCCESS,
  // The following attribute was added to differentiate whether to show a toast for the
  // ERROR type or not (both are needed in certain features)
  fixed: false,
};
/**
 * Redux slice for the notification
 */
export const notificationSlice = createSlice({
  name: 'feature/notification',
  initialState,
  reducers: {
    setNotification(state, action) {
      return {
        ...state,
        isEnabled: true,
        message: action.payload?.message,
        type: action.payload?.type,
      };
    },
    resetNotification(state) {
      return {
        ...state,
        isEnabled: false,
        message: '',
        type: ERROR_TYPES.SUCCESS,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userActions.addUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(userActions.addUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully added',
        isEnabled: true,
      }))
      .addCase(userActions.patchUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully updated',
        isEnabled: true,
      }))
      .addCase(userActions.patchUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(projectActions.addProjectFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(projectActions.addProjectSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Project successfully added',
        isEnabled: true,
      }))
      .addCase(projectActions.getOneProjectFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(projectActions.editProjectSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Project successfully updated',
        isEnabled: true,
      }))
      .addCase(projectActions.editProjectFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(projectActions.patchJiraTicketSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Jira ticket successfully updated',
        isEnabled: true,
      }))
      .addCase(projectActions.patchJiraTicketFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(toolActions.addToolSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Tool successfully added',
        isEnabled: true,
      }))
      .addCase(toolActions.addToolFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(toolActions.editToolSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Tool successfully updated',
        isEnabled: true,
      }))
      .addCase(toolActions.editToolFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(toolActions.deleteToolSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Tool deleted successfully',
        isEnabled: true,
      }))
      .addCase(toolActions.deleteToolFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(toolActions.uploadLogoFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.addSkillsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.addSkillsSucceeded, (state, action) => {
        const { successFullyAddedSkills, allSkills } = action.payload;
        const notAddedSkills = allSkills.filter(
          (skill) => !successFullyAddedSkills.some((result) => result.name === skill.name)
        );
        if (notAddedSkills.length > 0) {
          return {
            ...state,
            type: ERROR_TYPES.WARNING,
            message: `Some skills (${notAddedSkills
              .map((skill) => skill.name)
              .join(
                ', '
              )}) were already present in the database. The others have been added successfully`,
            isEnabled: true,
          };
        }
        return {
          ...state,
          type: ERROR_TYPES.SUCCESS,
          message: 'Skills successfully added',
          isEnabled: true,
        };
      })
      .addCase(skillActions.editSkillFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.editSkillSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Skill successfully edited',
        isEnabled: true,
      }))
      .addCase(skillActions.deleteSkillFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.deleteSkillSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Skill successfully deleted',
        isEnabled: true,
      }))
      .addCase(skillActions.addSkillGroupFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.addSkillGroupSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Skill group successfully added',
        isEnabled: true,
      }))
      .addCase(skillActions.editSkillGroupFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.editSkillGroupSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Skill group successfully edited',
        isEnabled: true,
      }))
      .addCase(skillActions.deleteSkillGroupFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(skillActions.deleteSkillGroupSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Skill group successfully deleted',
        isEnabled: true,
      }))
      .addCase(projectActions.uploadLogoFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(signInActions.signInFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.uploadAttachmentsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.addSupportTicketsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.addCommentsAttachmentsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.addCommentsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.addSupportTicketsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Support ticket successfully added',
        isEnabled: true,
      }))
      .addCase(opexActions.addOpexFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(opexActions.addOpexSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Overhead successfully added',
        isEnabled: true,
      }))
      .addCase(opexActions.editOpexSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Overhead successfully updated',
        isEnabled: true,
      }))
      .addCase(profileActions.updateUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(profileActions.updateUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User details successfully updated',
        isEnabled: true,
      }))
      .addCase(roleActions.addRoleSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Role successfully added',
        isEnabled: true,
      }))
      .addCase(roleActions.addRoleFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(roleActions.editRoleSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Role Update successfully ',
        isEnabled: true,
      }))
      .addCase(roleActions.editRoleFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(internalOnboardingSliceActions.internalOnboardingFailure, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(departmentActions.addDepartmentSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Department successfully added',
        isEnabled: true,
      }))
      .addCase(departmentActions.addDepartmentFailed, (state) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: 'Department Name is already available',
        isEnabled: true,
      }))
      .addCase(departmentActions.editDepartmentSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Department successfully updated',
        isEnabled: true,
      }))
      .addCase(departmentActions.editWorkAreaSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Work Area successfully updated',
        isEnabled: true,
      }))
      .addCase(departmentActions.addWorkAreaSucceeded, (state, action) => {
        const { results, workAreas } = action.payload;
        const notAddedWorkAreas = workAreas.filter(
          (workArea) => !results.some((result) => result.name === workArea.name)
        );
        if (notAddedWorkAreas.length > 0) {
          return {
            ...state,
            type: ERROR_TYPES.WARNING,
            message: `Some Work Areas (${notAddedWorkAreas
              .map((workArea) => workArea.name)
              .join(
                ', '
              )}) were already present in the database. The others have been added successfully`,
            isEnabled: true,
          };
        }
        return {
          ...state,
          type: ERROR_TYPES.SUCCESS,
          message: 'Work Areas successfully added',
          isEnabled: true,
        };
      })
      .addCase(userActions.addSalaryFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(userActions.addSalarySucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Salaries successfully added',
        isEnabled: true,
      }))
      .addCase(projectActions.patchProjectWorkAllocationSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Work allocation successfully updated',
        isEnabled: true,
      }))
      .addCase(projectActions.patchProjectWorkAllocationFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(userActions.updateUserRoleSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User role and permissions successfully updated',
        isEnabled: true,
      }))
      .addCase(userActions.updateUserRoleFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(profileActions.addUserSkillsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(userActions.updateCurrentSalarySucceed, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Salary successfully updated',
        isEnabled: true,
      }))
      .addCase(userActions.updateCurrentSalaryFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(projectActions.createTeamSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Team successfully created',
        isEnabled: true,
      }))
      .addCase(projectActions.createTeamFailed, (state) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: 'Team was not created selected',
        isEnabled: true,
      }))
      .addCase(designationActions.addDesignationSucceeded, (state, action) => {
        const { results, designations } = action.payload;
        const notAddedDesignations = designations.filter(
          (designation) => !results.some((result) => result.name === designation.name)
        );
        if (notAddedDesignations.length > 0) {
          return {
            ...state,
            type: ERROR_TYPES.WARNING,
            message: `Some Designations (${notAddedDesignations
              .map((designation) => designation.name)
              .join(
                ', '
              )}) were already present in the database. The others have been added successfully`,
            isEnabled: true,
          };
        }
        return {
          ...state,
          type: ERROR_TYPES.SUCCESS,
          message: 'Designation successfully Added',
          isEnabled: true,
        };
      })
      .addCase(designationActions.editDesignationSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Designation successfully Updated',
        isEnabled: true,
      }))
      .addCase(projectActions.changeTicketAssigneesSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Assignees updated successfully',
        isEnabled: true,
      }))
      .addCase(projectActions.changeTicketAssigneesFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(projectActions.changeTicketNotifiersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Notifiers updated successfully',
        isEnabled: true,
      }))
      .addCase(projectActions.changeTicketNotifiersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(projectActions.changeExternalNotifiersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'External notifiers updated successfully',
        isEnabled: true,
      }))
      .addCase(projectActions.changeExternalNotifiersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateResolvedStatusSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Ticket status successfully updated',
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateResolvedStatusFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateSeveritySucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Ticket severity successfully updated',
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateIndividualSupportTicketSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Ticket successfully updated',
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateIndividualSupportTicketFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(supportTicketActions.updateSeverityFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.addClientSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Client successfully added',
        isEnabled: true,
      }))
      .addCase(clientActions.addClientFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.editClientSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Client successfully edited',
        isEnabled: true,
      }))
      .addCase(clientActions.editClientFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.inviteClientUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully invited',
        isEnabled: true,
      }))
      .addCase(clientActions.inviteClientUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.addClientUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully added to client',
        isEnabled: true,
      }))
      .addCase(clientActions.addClientUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.editClientUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully updated',
        isEnabled: true,
      }))
      .addCase(clientActions.editClientUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.deleteClientUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Client User successfully deleted',
        isEnabled: true,
      }))
      .addCase(clientActions.deleteClientUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(reportActions.patchAllocationReportAllocationSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Allocation successfully updated',
        isEnabled: true,
      }))
      .addCase(reportActions.patchAllocationReportAllocationFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(clientActions.uploadLogoFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(linkActions.addLinkSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Link successfully added',
        isEnabled: true,
      }))
      .addCase(linkActions.addLinkFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(linkActions.editLinkSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Link successfully Updated',
        isEnabled: true,
      }))
      .addCase(linkActions.editLinkFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(linkActions.uploadLogoFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(projectActions.addProjectDocumentsSucceeded, (state, action) => {
        const { successfullyAddedDocuments, allDocumentsToBeAdded } = action.payload;
        const notAddedDocuments = allDocumentsToBeAdded.filter(
          (document) => !successfullyAddedDocuments.some((result) => result.name === document.name)
        );
        if (notAddedDocuments.length > 0) {
          return {
            ...state,
            type: ERROR_TYPES.INFO,
            message:
              'Some documents were already present in the database. Any new documents have been successfully submitted',
            isEnabled: true,
          };
        }
        return {
          ...state,
          type: ERROR_TYPES.SUCCESS,
          message: 'Documents successfully added',
          isEnabled: true,
        };
      })
      .addCase(projectActions.addProjectDocumentsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
        fixed: true,
      }))
      .addCase(projectActions.editProjectDocumentSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Document successfully updated',
        isEnabled: true,
      }))
      .addCase(projectActions.editProjectDocumentFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(projectActions.uploadDocumentsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
        fixed: true,
      }))
      .addCase(userActions.uploadBulkSalaryFileSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'File successfully uploaded',
        isEnabled: true,
      }))
      .addCase(userActions.uploadBulkSalaryFileFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(userActions.uploadBulkUserDataFileSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'File successfully uploaded',
        isEnabled: true,
      }))
      .addCase(userActions.uploadBulkUserDataFileFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(arsenalActions.addArsenalItemSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Item successfully added',
        isEnabled: true,
      }))
      .addCase(arsenalActions.addArsenalItemFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(arsenalActions.editArsenalItemSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Item successfully updated',
        isEnabled: true,
      }))
      .addCase(arsenalActions.editArsenalItemFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(benefitsActions.addBenefitsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Benefit successfully added',
        isEnabled: true,
      }))
      .addCase(benefitsActions.addBenefitsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(benefitsActions.editBenefitsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Benefit successfully updated',
        isEnabled: true,
      }))
      .addCase(benefitsActions.editBenefitsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(benefitsActions.deleteBenefitsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Benefit successfully deleted',
        isEnabled: true,
      }))
      .addCase(benefitsActions.deleteBenefitsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(vendorActions.addVendorSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Vendor successfully added',
        isEnabled: true,
      }))
      .addCase(vendorActions.addVendorFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(vendorActions.editVendorSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Vendor successfully updated',
        isEnabled: true,
      }))
      .addCase(vendorActions.editVendorFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(vendorActions.deleteVendorSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Vendor successfully deleted',
        isEnabled: true,
      }))
      .addCase(vendorActions.deleteVendorFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(benefitsActions.addPromotionsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Promotion successfully added',
        isEnabled: true,
      }))
      .addCase(benefitsActions.addPromotionsFailed, (state) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: 'Promotion failed to add',
        isEnabled: true,
      }))
      .addCase(vendorActions.verifyEmployeeFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action.payload.message,
        isEnabled: true,
      }))
      .addCase(vendorActions.verifyEmployeeSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Employee verified successfully',
        isEnabled: true,
      }));
  },
});
//
export const { actions: notificationActions } = notificationSlice;
