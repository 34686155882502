import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/opex'] || initialState;
/**
 * Selector for opex list state
 */
export const selectAllOpex = createSelector([selectDomain], (opexState) => opexState.opexList);
/**
 * Selector for getting loader state
 */
export const selectLoader = createSelector([selectDomain], (opexState) => opexState.loading);
/**
 * Function to retrieve one opex state
 */
export const selectOpex = createSelector([selectDomain], (opexState) => opexState.opex);
/**
 * Function to retrieve is opex fetching state
 */
export const selectIsOpexFetching = createSelector(
  [selectDomain],
  (opexState) => opexState.isOpexFetching
);
/**
 * Selector to get the loading status of the add tool action
 */
export const selectAddOpexLoader = createSelector(
  [selectDomain],
  (userState) => userState?.addOpexLoading
);
/**
 * Selector to get the loading status of the edit tool action
 */
export const selectEditOpexLoader = createSelector(
  [selectDomain],
  (userState) => userState?.editOpexLoading
);
