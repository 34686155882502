import { Divider as MUIDivider, Typography as MUITypography } from '@mui/material';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import COLORS from 'features/base/constants/colors';

/**
 * Divider component
 * @param {boolean} display
 * @returns {JSX.Element} Divider component
 */
const Divider = ({ display } = { display: true }) => {
  if (display === false) return null;
  return <MUIDivider sx={{ my: '0.1rem', display: 'block' }} />;
};
/**
 * Typography component
 * @param {boolean} display
 * @param {JSX.Element} children
 * @returns {JSX.Element} Typography component
 */
const Typography = ({ display, children } = { display: true, children: null }) => {
  if (display === false) return null;
  return (
    <MUITypography variant="caption" sx={{ fontWeight: 'bold', display: 'block' }}>
      {children}
    </MUITypography>
  );
};
/**
 * Compute tooltip color
 * @param {Object} allocation - { opex : number, salary : number , hourlyRate : number, disabled : boolean }
 * @param {Object} projectDetails - project object
 * @param {boolean} opexViewPermission - permission to view opex
 * @param {boolean} salaryViewPermission - permission to view salary
 * @returns {string} - color
 */
export const computeTooltipColor = (
  allocation,
  projectDetails,
  opexViewPermission,
  salaryViewPermission
) => {
  if (allocation?.disabled) {
    return COLORS.LILAC_GRAY;
  }
  if (opexViewPermission && allocation?.opex === 0) {
    return COLORS.GOLDEN_YELLOW;
  }
  if (salaryViewPermission && allocation?.salary === 0) {
    return COLORS.GOLDEN_YELLOW;
  }
  if (projectDetails?.billingType !== PROJECT_BILLING_TYPES.FIXED_BID) {
    if (allocation?.hourlyRate === 0) {
      return COLORS.GOLDEN_YELLOW;
    }
  }
  return COLORS.LILAC_GRAY;
};
/**
 * InfoToolTip component
 * @param {object} allocation
 * @param {object} projectDetails - project object
 * @param {boolean} salaryViewPermission - permission to view salary
 * @param {boolean} opexViewPermission - permission to view opex
 * @returns {JSX.Element} InfoToolTip component
 */
const InfoToolTip = ({ monthlyAllocation, project, salaryViewPermission, opexViewPermission }) => (
  <>
    <Typography display={opexViewPermission}>
      Applied Overhead - ${monthlyAllocation?.opex ?? 0}
    </Typography>
    <Divider display={opexViewPermission} />
    <Typography display={salaryViewPermission}>
      Applied Salary - ${monthlyAllocation?.salary ?? 0}
    </Typography>
    <Divider display={salaryViewPermission} />
    {project?.billingType !== PROJECT_BILLING_TYPES.FIXED_BID && (
      <Typography>Applied Hourly Rate - ${monthlyAllocation?.hourlyRate ?? 0}</Typography>
    )}
  </>
);

export default InfoToolTip;
