import { useMemo } from 'react';
import { Box, Grid, Switch, Typography } from '@mui/material';
import { SquareRounded as SquareRoundedIcon } from '@mui/icons-material';
import moment from 'moment';
//
import { Autocomplete } from 'features/base/components';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import COLORS from 'features/base/constants/colors';
import {
  FINANCIAL_YEAR_END_MONTH,
  FINANCIAL_YEAR_START_MONTH,
} from 'features/base/constants/report-data';
import { USER_TYPES } from 'features/base/constants/user-types';
/**
 * Function that returns a component that displays the set of filters for the allocation report
 * @param {List<Object>} userList - A ist of all avalable users
 * @param {List<Object>} projectList - A list of all avalable projects
 * @param {List<Object>} selectedUsers - A list of the selected users
 * @param {List<Object>} selectedProjects - A list of the selected projects
 * @param {Function} handleSelectedUsersChange - Function that triggers when users filter is updated
 * @param {Function} handleSelectedProjectsChange - Function that triggers when projects filter is updated
 * @param {Function} initilialize - Function to clear allocations and initialize
 * @param {Object} selectedProjectDetails - Object containing the details of the selected project, if a single project is seleted
 * @param {Function} generateViewPeriod - Function that generates a view period based on provided parameters
 * @param {Function} setViewPeriod - Function to set the current viewPeriod
 * @param {Boolean} loading - Boolean indicating the loading status
 * @returns {Grid}
 */
const Filters = ({
  userList,
  projectList,
  selectedUsers,
  selectedProjects,
  handleSelectedUsersChange,
  handleSelectedProjectsChange,
  initilialize,
  selectedProjectDetails,
  generateViewPeriod,
  setViewPeriod,
  loading,
  isProjectDurationEnabled,
  setIsProjectDurationEnabled,
  selectedYear,
  setSelectedYear
}) => {
  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = 2022; i < moment().year() + 10; i += 1) {
      years.push({ label: `${i} - ${i + 1}`, value: i });
    }
    return years;
  });
  //
  const handleToggleProjectDuration = () => {
    if (!isProjectDurationEnabled) {
      const start = moment(selectedProjectDetails?.startDate);
      const end = moment(selectedProjectDetails?.endDate);
      // 1 is added to the start month and end month because it's 0 indexed
      const months = generateViewPeriod(
        start.year(),
        start.month() + 1,
        end.year(),
        end.month() + 1
      );
      setViewPeriod(months);
    } else {
      const months = generateViewPeriod(
        moment().year(),
        FINANCIAL_YEAR_START_MONTH,
        moment().year() + 1,
        FINANCIAL_YEAR_END_MONTH
      );
      setViewPeriod(months);
    }
    initilialize()
    setIsProjectDurationEnabled(!isProjectDurationEnabled);
  };
  return (
    <Grid container spacing={2} sx={{ pr: '1rem', pl: '1rem', mt: '1.5rem' }}>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <MultiSelectAutoComplete
            id="user"
            name="user"
            options={[
              { id: 'All', label: 'All' },
              ...(userList
                ?.filter((user) => user.type !== USER_TYPES.CLIENT)
                .map((userRecord) => ({
                  id: userRecord?.id,
                  label: userRecord?.email,
                })) ?? []),
            ]}
            selectedOptions={selectedUsers}
            setSelectedOptions={handleSelectedUsersChange}
            label="User"
            isOptionEqualToValue={(option, autoValue) =>
              option.label === autoValue.label || autoValue === null
            }
            displayAttribute="label"
            displayCards={false}
            placeholder="Select users"
            disabled={loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <MultiSelectAutoComplete
            id="project"
            name="project"
            options={[
              { id: 'All', label: 'All' },
              ...(projectList?.map((projectRecord) => ({
                id: projectRecord?.id,
                label: projectRecord?.name,
              })) ?? []),
            ]}
            selectedOptions={selectedProjects}
            setSelectedOptions={handleSelectedProjectsChange}
            label="Project"
            isOptionEqualToValue={(option, autoValue) =>
              option.label === autoValue.label || autoValue === null
            }
            displayAttribute="label"
            displayCards={false}
            placeholder="Select projects"
            disabled={loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={2} md={2}>
        <Box>
          <Autocomplete
            id="year"
            options={yearOptions}
            disableClearable
            onChange={(_, value) => {
              if (JSON.stringify(selectedYear) === JSON.stringify(value)) return;
              setSelectedYear(value);
              initilialize();
              const months = generateViewPeriod(
                Number(value?.value),
                FINANCIAL_YEAR_START_MONTH,
                Number(value?.value) + 1,
                FINANCIAL_YEAR_END_MONTH
              );
              setViewPeriod(months);
            }}
            label="Year"
            disabled={isProjectDurationEnabled || loading}
            controlled
            value={selectedYear}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={2} md={2}>
        <Box display="flex" alignItems="center" marginTop="18px">
          <Typography variant="body2" sx={{ fontSize: '1rem', color: COLORS.DEEP_NAVY_BLUE }}>
            Project Duration
          </Typography>
          <Switch
            checked={isProjectDurationEnabled}
            onChange={() => {
              handleToggleProjectDuration();
            }}
            color="success"
            disabled={selectedProjects?.length !== 1 || loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={2} md={2}>
        <Box display="flex" alignItems="left" marginTop="22px">
          <SquareRoundedIcon sx={{ color: 'rgba(75, 181, 67, 0.56)' }} />
          <Typography sx={{ marginLeft: '8px', marginRight: '15px' }}>Active</Typography>
          <SquareRoundedIcon sx={{ color: 'rgba(159, 156, 171, 0.56)' }} />
          <Typography sx={{ marginLeft: '8px' }}>Inactive</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
//
export default Filters;
