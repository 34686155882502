import { Link } from 'react-router-dom';
import { Button as MuiButton, Typography, Box } from '@mui/material';
import { CommonBackground } from 'features/base/components';
/**
 * Component that defines the 404 error
 * @returns 404 error
 */
const Page404 = () => (
  <CommonBackground>
    <Box sx={{ display: 'grid' }}>
      <Typography sx={{ color: 'white' }} component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography sx={{ color: 'white' }} component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography
        sx={{ color: 'white' }}
        component="h2"
        variant="body1"
        align="center"
        gutterBottom
      >
        The page you are looking for might have been removed.
      </Typography>
      <MuiButton sx={{ mt: '1rem' }} component={Link} to="/" variant="contained" color="secondary">
        Return to website
      </MuiButton>
    </Box>
  </CommonBackground>
);
//
export default Page404;
