/**
 * JIRA issue types
 * @type {{ TASK: string, STORY: string, BUG: string, EPIC: string }}
 */
const JIRA_ISSUE_TYPES = {
  TASK: 'Task',
  STORY: 'Story',
  BUG: 'Bug',
  EPIC: 'Epic',
};
//
export default JIRA_ISSUE_TYPES;
