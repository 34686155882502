import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
//
import CloseIcon from 'features/base/assets/images/svg/close-icon.svg';
import './index.scss';
/**
 * @prop {String} title Modal title
 * @prop {String} title Modal subtitle
 * @prop {String} children The content that should be shown within the modal
 * @prop {Boolean} open Indicates whether the modal is currently open
 * @prop {Function} onClose Handler function for closing the Modal
 * @prop {Boolean} closeOnBlur If true modal closes when user clicks outside the modal
 * @prop {String} mediumSize Modal body medium size
 */
const Popup = (props) => {
  const {
    title,
    subTitle,
    children,
    open,
    onClose,
    closeOnBlur,
    mediumSize,
    titleSx,
    infoMessage,
    sx,
    height,
    fullWidth,
    fullScreen,
    ...rest
  } = props;
  return (
    <Dialog
      open={open}
      onClose={closeOnBlur ? onClose : null}
      maxWidth={rest.maxWidth || 'lg'}
      sx={sx}
      PaperProps={{
        style: {
          height,
          display: 'flex',
        },
      }}
      fullScreen={fullScreen || false}
      fullWidth={fullWidth || false}
      {...rest}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: mediumSize,
          },
        }}
      >
        <DialogTitle sx={{ padding: 0, ...titleSx }}>
          <div className="modal-title-container">
            <p className="modal-title">{title}</p>
            <IconButton
              onClick={onClose}
              sx={{
                padding: '20px',
                width: '22px',
                height: '20px',
                margin: '12px',
              }}
            >
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          </div>
          {subTitle ? (
            <div className="modal-subtitle-container">
              <Box>
                <Typography className="modal-sub-title">{subTitle}</Typography>
              </Box>
            </div>
          ) : null}
          {infoMessage ? (
            <div className="modal-info-container">
              <Grid container direction="row" alignItems="center" spacing={0.5}>
                <Grid item>
                  <InfoIcon
                    color="primary"
                    fontSize="small"
                    style={{ position: 'relative', top: '3.5px' }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {infoMessage}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : null}
        </DialogTitle>
        <DialogContent
          sx={{
            mt: 2.5,
          }}
        >
          <div className="modal-body">{children}</div>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
//
export default Popup;
