import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/skills'] || initialState;
/**
 * Function to get skills from the skill slice
 */
export const selectSkills = createSelector([selectDomain], (skillsState) => skillsState.skills);
/**
 * Function to get skill groups from the skill slice
 */
export const selectSkillGroups = createSelector(
  [selectDomain],
  (skillsState) => skillsState.skillGroups
);
/**
 * Function to get all skills from the skill slice
 */
export const selectAllSkills = createSelector(
  [selectDomain],
  (skillsState) => skillsState.allSkills
);
/**
 * Function to get all skill groups from the skill slice
 */
export const selectAllSkillGroups = createSelector(
  [selectDomain],
  (skillsState) => skillsState.allSkillGroups
);
/**
 * Selector for getting a skill by id
 */
export const selectSkillById = createSelector(
  [selectSkills, (_, skillId) => skillId],
  (skills, skillId) => skills?.docs?.find((skill) => skill.id === skillId) || null
);
/**
 * Selector for getting a skill group by id
 */
export const selectSkillGroupById = createSelector(
  [selectSkillGroups, (_, skillGroupId) => skillGroupId],
  (skillGroups, skillGroupId) =>
    skillGroups?.docs?.find((skillGroup) => skillGroup.id === skillGroupId) || null
);
/**
 * Selector for getting the open/close status of the add skills drawer
 */
export const selectAddDrawerOpen = createSelector(
  [selectDomain],
  (skillsState) => skillsState.addDrawerOpen
);

/**
 * Selector to get the loading status of the add skill action
 */
export const selectAddSkillLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.addSkillLoading
);
/**
 * Selector to get the loading status of the get skill action
 */
export const selectGetSkillLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.getSkillsLoading
);
/**
 * Selector to get the loading status of the edit skill action
 */
export const selectEditSkillLoader = createSelector(
  [selectDomain],
  (skillState) => skillState?.editSkillLoading
);
/**
 * Selector to get the loading status of the delete skill action
 */
export const selectDeleteSkillLoader = createSelector(
  [selectDomain],
  (skillState) => skillState?.deleteSkillLoading
);
/**
 * Selector to get the loading status of the add skill group action
 */
export const selectAddSkillGroupLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.addSkillGroupLoading
);
/**
 * Selector to get the loading status of the get skill group action
 */
export const selectGetSkillGroupLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.getSkillGroupsLoading
);
/**
 * Selector to get the loading status of the edit skill group action
 */
export const selectEditSkillGroupLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.editSkillGroupLoading
);
/**
 * Selector to get the loading status of the delete skill group action
 */
export const selectDeleteSkillGroupLoader = createSelector(
  [selectDomain],
  (skillsState) => skillsState?.deleteSkillGroupsLoading
);
/**
 * Selector to get the loading status of the delete skill action
 */
export const selectSearch = createSelector([selectDomain], (skillState) => skillState?.search);
/**
 * Selector to get the delete skill flag
 */
export const selectDeleteSkillFlag = createSelector(
  [selectDomain],
  (skillState) => skillState?.deleteSkillFlag
);
/**
 * Selector to get the delete skill group flag
 */
export const selectDeleteSkillGroupFlag = createSelector(
  [selectDomain],
  (skillState) => skillState?.deleteSkillGroupFlag
);
