/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
import { profileActions } from './slice';
import API from './constants';

/**
 * Generator function for get user by id
 */
function* workGetUserByIdGenerator({ payload }) {
  try {
    const data = yield call(request, API.GET_USER_BY_ID, payload);
    yield put(profileActions.fetchUserByIdSuccess(data));
  } catch (error) {
    yield put(profileActions.fetchUserByIdFailure(error.message));
  }
}
/**
 * Generator function for getting department designation relation ids
 * @param {Object} payload
 */
export function* getDepartmentDesignationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENT_DESIGNATIONS, payload);
    yield put(profileActions.getDepartmentDesignationsSucceeded(response));
  } catch (error) {
    yield put(profileActions.getDepartmentDesignationsFailed(error?.message));
  }
}
/**
 * Generator function for updating a user
 * @param {Object} payload
 */
export function* updateUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER, payload);
    yield put(profileActions.updateUserSucceeded(response));
  } catch (error) {
    yield put(profileActions.updateUserFailed(error?.message));
  }
}
/**
 * Generator function for getting user allocations
 * @param {Object} payload
 */
export function* getMyAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS_BY_USER_ID, payload);
    yield put(profileActions.getMyAllocationsSucceeded(response));
  } catch (error) {
    yield put(profileActions.getMyAllocationsFailed(error?.message));
  }
}
/**
 * Generator function for getting all skill groups
 * @param {Object} payload
 */
export function* getAllSkillGroupsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILL_GROUPS, payload);
    yield put(profileActions.getAllSkillGroupsSucceeded(response));
  } catch (error) {
    yield put(profileActions.getAllSkillGroupsFailed(error?.message));
  }
}
/**
 * Generator function for adding user skills
 * @param {Object} payload
 */
export function* addUserSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_USER_SKILL, payload);
    yield put(profileActions.addUserSkillsSucceeded(response));
  } catch (error) {
    yield put(profileActions.addUserSkillsFailed(error?.message));
  }
}
/**
 * Generator function for getting user skills
 * @param {Object} payload
 */
export function* getUserSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USER_SKILLS, payload);
    yield put(profileActions.getUserSkillsSucceeded(response));
  } catch (error) {
    yield put(profileActions.getUserSkillsFailed(error?.message));
  }
}
/**
 * Generator function for update user skills ratings
 * @param {Object} payload
 */
export function* updateUserSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_USER_SKILLS, payload);
    yield put(profileActions.updateUserSkillsSucceeded(response));
  } catch (error) {
    yield put(profileActions.updateUserSkillsFailed(error?.message));
  }
}
/**
 * Generator function for deleting skills
 * @param {Object} payload
 */
export function* deleteUserSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_USER_SKILL, payload);
    yield put(profileActions.deleteUserSkillsSucceeded(response));
  } catch (error) {
    yield put(profileActions.deleteUserSkillsFailed(error?.message));
  }
}
/**
 * Generator function for getting a client a single client by id
 * @param {Object} payload
 */
export function* getClientByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CLIENT_BY_ID, payload);
    yield put(profileActions.getClientByIdSucceeded(response));
  } catch (error) {
    yield put(profileActions.getClientByIdFailed(error?.message));
  }
}
/**
 * Saga function that utilizes the above defined generator functions
 */
export function* profileSaga() {
  yield takeLatest(profileActions.fetchUserById.type, workGetUserByIdGenerator);
  yield takeLatest(
    profileActions.getDepartmentDesignations.type,
    getDepartmentDesignationsGenerator
  );
  yield takeLatest(profileActions.updateUser.type, updateUserGenerator);
  yield takeLatest(profileActions.getMyAllocations.type, getMyAllocationsGenerator);
  yield takeLatest(profileActions.getAllSkillGroups.type, getAllSkillGroupsGenerator);
  yield takeLatest(profileActions.addUserSkills.type, addUserSkillsGenerator);
  yield takeLatest(profileActions.getUserSkills.type, getUserSkillsGenerator);
  yield takeLatest(profileActions.updateUserSkills.type, updateUserSkillsGenerator);
  yield takeLatest(profileActions.deleteUserSkills.type, deleteUserSkillsGenerator);
  yield takeLatest(profileActions.getClientById.type, getClientByIdGenerator);
}
//
export default profileSaga;
