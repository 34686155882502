/**
 * Defines the styling constants for the OAuth window
 * @default { OAUTH_WINDOW_WIDTH: 700, OAUTH_WINDOW_HEIGHT: 600, OAUTH_WINDOW_TOP: window.screenTop + window.innerHeight / 2 - OAUTH_WINDOW_HEIGHT / 2, OAUTH_WINDOW_LEFT: window.screenLeft + window.innerWidth / 2 - OAUTH_WINDOW_WIDTH / 2 }
 */
export const OAUTH_WINDOW_WIDTH = 700;
//
export const OAUTH_WINDOW_HEIGHT = 600;
//
export const OAUTH_WINDOW_TOP = window.screenTop + window.innerHeight / 2 - OAUTH_WINDOW_HEIGHT / 2;
//
export const OAUTH_WINDOW_LEFT = window.screenLeft + window.innerWidth / 2 - OAUTH_WINDOW_WIDTH / 2;
