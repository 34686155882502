import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import { getRefreshToken, getAccessToken } from 'features/base/helpers/token';
import { selectIsSessionTimedOut } from '../auth/selectors';
import { authActions } from '../auth/slice';
import TIME_OUTS from '../constants/time-outs';
import useIsAuthenticated from './use-is-authenticated';
import useIsTokenValid from './use-token-validity';

/**
 * Based on refresh token get the new access token
 */
const useUpdateToken = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { isValid } = useIsTokenValid();
  //
  const dispatch = useDispatch();
  //
  const refreshToken = getRefreshToken();
  const accessToken = getAccessToken();
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);
  //
  const tryUpdateToken = useCallback(() => {
    dispatch(authActions.refreshToken({ refreshToken }));
  }, [refreshToken]);
  //
  useEffect(() => {
    // Session observer
    const interval = setInterval(async () => {
      if (isAuthenticated(refreshToken)) {
        // Check whether access token need to be refreshed
        if (!isValid(accessToken)) {
          tryUpdateToken();
        }
        // Session has been timed out
      } else if (!isSessionTimedOut) {
        dispatch(authActions.setSessionTimeOut(true));
      }
    }, TIME_OUTS.SESSION_OBSERVER_TIME);
    //
    return () => {
      clearInterval(interval);
    };
  }, [accessToken]);
};
//
export default useUpdateToken;
