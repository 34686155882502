import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/tools'] || initialState;
/**
 * Function to get all Tools from the tools slice
 */
export const selectAllTools = createSelector([selectDomain], (toolState) => toolState.allTools);
/**
 * Function to retrieve load state from the tool slice
 */
export const selectLoader = createSelector([selectDomain], (toolState) => toolState.loading);
/**
 * Function to retrieve selectd logo state
 */
export const selectUploadedLogo = createSelector(
  [selectDomain],
  (toolState) => toolState.uploadedLogo
);
/**
 * Selector for getting loader state of uploading a logo
 */
export const selectUploadFileLoader = createSelector(
  [selectDomain],
  (userState) => userState.uploadFileLoading
);
/**
 * Function to retrieve all departments
 */
export const selectAllDepartment = createSelector(
  [selectDomain],
  (toolState) => toolState.allDepartmentList
);
/**
 * Selector to get the loading status of the add tool action
 */
export const selectAddToolsLoader = createSelector(
  [selectDomain],
  (userState) => userState?.addToolLoading
);
/**
 * Selector to get the loading status of the edit tool action
 */
export const selectEditToolsLoader = createSelector(
  [selectDomain],
  (userState) => userState?.editToolLoading
);
/**
 * Selector to get the loading status of the delete tool action
 */
export const selectDeleteToolsLoader = createSelector(
  [selectDomain],
  (userState) => userState?.deleteToolLoading
);
