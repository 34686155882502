import { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
//
import { Autocomplete } from 'features/base/components';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
/**
 * Function that returns a component that displays the set of filters for the allocation report
 * @param {List<Object>} userList - A ist of all avalable users
 * @param {List<Object>} projectList - A list of all avalable projects
 * @param {List<Object>} selectedUsers - A list of the selected users
 * @param {List<Object>} selectedProjects - A list of the selected projects
 * @param {Function} handleSelectedUsersChange - Function that triggers when users filter is updated
 * @param {Function} handleSelectedProjectsChange - Function that triggers when projects filter is updated
 * @param {Function} initilialize - Function to clear allocations and initialize
 * @param {Object} selectedProjectDetails - Object containing the details of the selected project, if a single project is seleted
 * @param {Function} generateViewPeriod - Function that generates a view period based on provided parameters
 * @param {Function} setViewPeriod - Function to set the current viewPeriod
 * @param {Boolean} loading - Boolean indicating the loading status
 * @returns {Grid}
 */
const Filters = ({
  userList,
  projectList,
  selectedUsers,
  selectedProjects,
  handleSelectedUsersChange,
  handleSelectedProjectsChange,
  thisYear,
  handleYearChange,
  selectedYear,
  loading,
  initilialize,
}) => {
  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = thisYear; i > 2014; i -= 1) {
      years.push({ label: `${i} - ${i + 1}`, value: i });
    }
    return years;
  });
  return (
    <Grid container spacing={2} sx={{ pr: '1rem', pl: '1rem', mt: '1.5rem' }}>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <MultiSelectAutoComplete
            id="user"
            name="user"
            options={[
              { id: 'All', label: 'All' },
              ...(userList?.map((userRecord) => ({
                id: userRecord?.id,
                label: userRecord?.email,
              })) ?? []),
            ]}
            selectedOptions={selectedUsers}
            setSelectedOptions={handleSelectedUsersChange}
            label="User"
            isOptionEqualToValue={(option, autoValue) =>
              option.label === autoValue.label || autoValue === null
            }
            displayAttribute="label"
            displayCards={false}
            placeholder="Select users"
            disabled={loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <MultiSelectAutoComplete
            id="project"
            name="project"
            options={[
              { id: 'All', label: 'All' },
              ...(projectList?.map((projectRecord) => ({
                id: projectRecord?.id,
                label: projectRecord?.name,
              })) ?? []),
            ]}
            selectedOptions={selectedProjects}
            setSelectedOptions={handleSelectedProjectsChange}
            label="Project"
            isOptionEqualToValue={(option, autoValue) =>
              option.label === autoValue.label || autoValue === null
            }
            displayAttribute="label"
            displayCards={false}
            placeholder="Select projects"
            disabled={loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={2} md={2}>
        <Box>
          <Autocomplete
            size="small"
            label="Year"
            value={{ label: `${selectedYear}-${selectedYear + 1}`, value: selectedYear }}
            onChange={(_, value) => {
              initilialize();
              handleYearChange(value);
            }}
            options={yearOptions}
            controlled
            disableClearable
          />
        </Box>
      </Grid>
    </Grid>
  );
}
//
export default Filters;
