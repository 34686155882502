import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';
import { withStyles } from '@material-ui/core';
/**
 * Styled data grid component
 */
const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal !important',
    },
    '& .MuiDataGrid-row': {
      maxHeight: '52px !important',
    },
  },
})(MUIDataGrid);
//
export default StyledDataGrid;
