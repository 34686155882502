/**
 * Available support ticket status
 * @type {{RESOLVED: string, OPEN: string}}
 */
export const SUPPORT_TICKET_STATUS = {
  RESOLVED: 'RESOLVED',
  OPEN: 'OPEN',
};
/**
 * Array for support ticket status
 */
export const SUPPORT_TICKET_STATUS_ARRAY = [
  { key: 1, value: SUPPORT_TICKET_STATUS.RESOLVED },
  { key: 2, value: SUPPORT_TICKET_STATUS.OPEN },
];
