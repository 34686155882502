import * as yup from 'yup';
//
import { AMOUNT_RANGES } from 'features/base/constants/amount-types';

/**
 * Function for tool form validation
 */
const toolFormValidation = yup.object().shape({
  name: yup.string().required('Please Enter valid tool name'),
  price: yup
    .number()
    .min(AMOUNT_RANGES.MIN_AMOUNT, `Minimum amount is $${AMOUNT_RANGES.MIN_AMOUNT}`)
    .max(AMOUNT_RANGES.MAX_AMOUNT, `Maximum amount is $${AMOUNT_RANGES.MAX_AMOUNT}`)
    .required('Please enter an amount')
    .typeError('Amount must be a number'),
  paymentType: yup.string().required('Please select a payment type'),
  description: yup
    .string()
    .required('Please Enter valid tool description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
  departments: yup.array().min(1, 'Please select at least one department'),
});
//
export default toolFormValidation;
