import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  allVendors: {},
  uploadedLogo: [],
  uploadFileLoading: false,
  addVendorLoading: false,
  editVendorLoading: false,
  deleteVendorLoading: false,
  verifyEmployeeLoading: false,
  verifyEmployeeData: {},
};
/**
 * Redux slice for vendors feature
 */
export const vendorSlice = createSlice({
  name: 'feature/vendors',
  initialState,
  reducers: {
    getAllVendors: (state) => ({
      ...state,
      loading: true,
    }),
    getAllVendorsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allVendors: action?.payload,
    }),
    getAllVendorsFailed: (state) => ({
      ...state,
      loading: false,
      allVendors: {
        ...state.allVendors,
        docs: [],
      },
    }),
    addVendor: (state) => ({
      ...state,
      addVendorLoading: true,
    }),
    addVendorSucceeded: (state, action) => ({
      ...state,
      addVendorLoading: false,
      allVendors: {
        ...state.allVendors,
        docs: [...state.allVendors.docs, action.payload],
        totalDocs: state.allVendors.totalDocs + 1,
      },
    }),
    addVendorFailed: (state) => ({
      ...state,
      addVendorLoading: false,
    }),
    editVendor: (state) => ({
      ...state,
      editVendorLoading: true,
    }),
    editVendorSucceeded: (state, action) => ({
      ...state,
      editVendorLoading: false,
      allVendors: {
        ...state.allVendors,
        docs: state.allVendors.docs.map((vendor) => {
          if (vendor?.id === action?.payload?.id) {
            return action.payload;
          }
          return vendor;
        }),
      },
    }),
    editVendorFailed: (state) => ({
      ...state,
      editVendorLoading: false,
    }),
    deleteVendor: (state) => ({
      ...state,
      deleteVendorLoading: true,
    }),
    deleteVendorSucceeded: (state, action) => ({
      ...state,
      deleteVendorLoading: false,
      allVendors: {
        ...state.allVendors,
        docs: state.allVendors.docs.filter((vendor) => vendor?.id !== action?.payload?.id),
        totalDocs: state.allVendors.totalDocs - 1,
      },
    }),
    deleteVendorFailed: (state) => ({
      ...state,
      deleteVendorLoading: false,
    }),
    uploadLogo: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadLogoSucceeded: (state, action) => ({
      ...state,
      uploadFileLoading: false,
      uploadedLogo: action?.payload,
    }),
    uploadLogoFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    clearLogo: (state) => ({
      ...state,
      uploadedLogo: [],
    }),
    verifyEmployee: (state) => ({
      ...state,
      verifyEmployeeLoading: true,
    }),
    verifyEmployeeSucceeded: (state, action) => ({
      ...state,
      verifyEmployeeLoading: false,
      verifyEmployeeData: action?.payload,
    }),
    verifyEmployeeFailed: (state) => ({
      ...state,
      verifyEmployeeLoading: false,
      verifyEmployeeData: {},
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: vendorActions } = vendorSlice;
