import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  selectAggregatedAllocations,
  selectAggregatedAllocationsLoading,
} from 'features/projects/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
//
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
//
const TableFilters = ({ handleUsersChange, selectedUsers }) => {
  const allocationsLoading = useSelector(selectAggregatedAllocationsLoading);
  //
  const allocations = useSelector(selectAggregatedAllocations);
  //
  const users = useMemo(
    () => allocations?.docs?.map((allocation) => allocation?.userId),
    [allocations]
  );
  //
  return (
    <FormControl sx={{ maxWidth: 300, mb: 4, mt: -3, width: '100%' }}>
      <Typography fontSize="16px" className="field-label">
        User
      </Typography>
      {allocationsLoading ? (
        <Skeleton
          data-testid="skeleton"
          sx={{ width: 300, height: 68, maxHeight: 68, mt: -2, mb: 0 }}
        />
      ) : (
        <Select
          size="small"
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          defaultValue="All"
          value={selectedUsers}
          onChange={handleUsersChange}
          renderValue={(selected) =>
            selected
              ?.map((id) => users?.find((user) => user?.id === id)?.firstName || 'All')
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          <MenuItem
            disabled={selectedUsers?.indexOf('All') === -1 && selectedUsers.length > 0}
            value={'All'}
          >
            <Checkbox checked={selectedUsers?.indexOf('All') > -1} />
            <ListItemText primary={`All`} />
          </MenuItem>
          {users?.map((user) => (
            <MenuItem
              disabled={selectedUsers?.indexOf('All') > -1}
              key={`${user.id}`}
              value={user?.id}
            >
              <Checkbox checked={selectedUsers?.indexOf(user?.id) > -1} />
              <ListItemText primary={`${user?.firstName} ${user?.lastName}`} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
//
export default TableFilters;
