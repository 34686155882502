/**
 * Defines the routes used in the application
 */
const ROUTES = {
  WELCOME: '/',
  LOGIN: '/login',
  REDIRECT: '/login/callback',
  LINK_HUB: '/link-hub',
  TOOL_HUB: '/tool-hub',
  DASHBOARD: '/dashboard',
  SUPPORT_TICKETS: '/support-tickets',
  SINGLE_SUPPORT_TICKET: '/support-tickets/:id',
  USERS: '/users',
  PROJECTS: '/projects',
  INTERNAL_ONBOARD: '/internal',
  PROJECT: '/projects/:projectId',
  PROJECT_WORK_ALLOCATION: '/projects/:projectId/work-allocation',
  PROJECT_EXTERNAL_MEMBERS: '/projects/:projectId/external-members',
  PROJECT_WORK_ALLOCATION_CREATE: '/projects/:projectId/work-allocation/create',
  PROJECT_WORK_ALLOCATION_UPDATE: '/projects/:projectId/work-allocation/update',
  PROJECT_WORK_ALLOCATION_REPORT: '/projects/:projectId/work-allocation/report',
  PROJECT_DOCUMENTS: '/projects/:projectId/documents',
  PROJECT_CLIENT_SUPPORT_TICKETS: '/projects/:projectId/support-tickets',
  PROJECT_REPOSITORIES: '/projects/:projectId/repositories',
  PROJECT_DEPLOYMENTS: '/projects/:projectId/deployments',
  PROJECT_GANTT_CHART: '/projects/:projectId/gantt-chart',
  PROJECT_BILLING: '/projects/:projectId/billing',
  PROJECT_LOGGED_HOURS: '/projects/:projectId/logged-hours',
  PROFILE: '/users/:id/*',
  CONFIGURATIONS: 'configurations',
  SKILLS: 'skills',
  ALLOCATIONS: 'allocations',
  TOOLS: 'tools',
  CLIENT_PROFILE_USERS: 'users',
  CLIENT_PROFILE_PROJECTS: 'projects',
  MANAGE_TOOLS: '/manage-tools',
  MANAGE_ROLES: '/manage-roles',
  MANAGE_SKILLS: '/manage-skills',
  MANAGE_SKILL_SKILLS: '/manage-skills/skills',
  MANAGE_SKILL_SKILL_GROUPS: '/manage-skills/skill-groups',
  MANAGE_DEPARTMENTS: '/manage-departments',
  MANAGE_OPEX: '/manage-opex',
  NOT_AUTHORIZED: '/unauthorized',
  MANAGE_DEPARTMENTS_DESIGNATION: '/manage-departments/designation',
  MANAGE_DEPARTMENTS_WORK_AREA: '/manage-departments/work-area',
  MANAGE_LINKS: '/manage-links',
  MANAGE_CLIENTS: '/manage-clients',
  MANAGE_CLIENT: '/manage-clients/:id',
  MANAGE_CLIENT_USERS: '/manage-clients/:id/users',
  MANAGE_CLIENT_PROJECTS: '/manage-clients/:id/projects',
  MANAGE_BENEFITS: '/manage-benefits',
  CREATE_BENEFIT: '/manage-benefits/create',
  MANAGE_VENDORS: '/manage-vendors',
  VENDOR_PORTAL: '/vendor-portal',
  UNDEFINED: '#',
  NOT_FOUND: '*',
  TEAM_REPORT: '/team-report',
  ALLOCATION_REPORT: '/allocation-report',
  ALLOCATED_VS_ACTUAL_HOURS: '/allocated-vs-actual-hours',
  SKILL_REPORT: '/skill-report',
  CAPACITY_REPORT: '/capacity-report',
  SALARY_REPORT: '/salary-report',
  ARSENAL: '/arsenal',
  BENEFITS: '/benefits',
};
//
export default ROUTES;
