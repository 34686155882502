import { Alert, CircularProgress, Grid, Typography, Stack } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
//
import { userActions } from 'features/users/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectRoles,
  selectUpdateRoleLoader,
  selectUsersPermissions,
  selectGetPermissionsLoader,
} from 'features/users/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import Select from 'features/base/components/select';
import ERROR_TYPES from 'features/base/constants/error-types';
import Matrix from 'features/base/components/matrix';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  buildPermissionMatrix,
  handleMatrixClick,
  onSubmitFormatter,
} from 'features/base/helpers/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';

/**
 * Function that defines the popup form for adding a new user
 * @prop {object} user - user object
 * @prop {boolean} editPermissionsModalOpen - boolean to show/hide the popup
 * @prop {function} setEditPermissionsModalOpen - function to set the popup state
 * @returns {Popup}
 */
const EditPermissionsPopup = ({ user, editPermissionsModalOpen, setEditPermissionsModalOpen }) => {
  const userPermissionData = useSelector(selectUsersPermissions);
  const roles = useSelector(selectRoles);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectUpdateRoleLoader);
  const userPermissionLoading = useSelector(selectGetPermissionsLoader);
  //
  const dispatch = useDispatch();
  //
  const [permissions, setPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  //
  const handleOnClose = () => {
    setEditPermissionsModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  // Updates the permission matrix based on the selected role
  const handleRoleChange = (selectedRoleId) => {
    const selectedRole = roles.find((r) => r.id === selectedRoleId);
    const matrix = buildPermissionMatrix(selectedRole?.permissions);
    setPermissions(matrix);
  };
  //
  const handleClick = (domainIndex, actionIndex) => {
    // Creating a deep copy of the permissions object, since it prevents referencial updates in the handle click
    const permissionsDeepCopy = JSON.parse(JSON.stringify(permissions));
    const newPermissions = handleMatrixClick(permissionsDeepCopy, domainIndex, actionIndex);
    //
    setPermissions(newPermissions);
  };
  //
  const notCustomPermissions = isEqual(userPermissionData?.permissions, user?.roleId?.permissions);
  //
  useEffect(() => {
    const matrix = buildPermissionMatrix(userPermissionData?.permissions);
    setPermissions(matrix);
    setInitialPermissions(matrix);
  }, [userPermissionData]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    role: user?.roleId?.name || '',
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.USER_PERMISSION,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editPermissionsModalOpen}
        onClose={handleOnClose}
        title="Edit role/permissions"
        mediumSize="720px"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(value) => {
            const result = onSubmitFormatter(permissions);
            //
            const data = {
              roleName: value?.role,
              permissions: result,
            };
            //
            dispatch(userActions.updateUserRole({ ...data, userId: user?.id }));
          }}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Select
                name="role"
                id="role"
                value={values.role}
                selectLabel="role"
                onChange={(event, key) => {
                  const id = key.key.replace('.$', '');
                  setFieldValue('role', event.target.value);
                  handleRoleChange(id);
                }}
                items={roles.map((role) => ({ key: role?.id, value: role?.name }))}
                textLabel="Role"
                stringFormat={capitalizeFirstLetter}
              />
              {errors?.type && touched?.type && <p className="error-feedback">{errors?.type}</p>}
              {userPermissionLoading ? (
                <Grid
                  container
                  sx={{ height: 400, justifyContent: 'center', alignContent: 'center' }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                (notCustomPermissions && (
                  <Matrix rows={permissions} handleClick={handleClick} />
                )) || (
                  <>
                    <Stack direction="row" alignItems="center" spacing={1} p={1}>
                      <InfoOutlined sx={{ height: 15, width: 15, color: 'red' }} />
                      <Typography variant="body2" color="red">
                        This user has custom permissions. Changing the role will reset the
                        permissions.
                      </Typography>
                    </Stack>
                    <Matrix rows={permissions} handleClick={handleClick} />
                  </>
                )
              )}
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={
                  loading ||
                  (JSON.stringify(initialPermissions) === JSON.stringify(permissions) &&
                    initialValues.role === values?.role)
                }
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditPermissionsPopup;
