import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  TableBody,
  Box,
  Typography,
  Link,
} from '@mui/material';
import lowestIcon from 'features/base/assets/images/svg/jiraticket-lowest-priority.svg';
import lowIcon from 'features/base/assets/images/svg/jiraticket-low-priority.svg';
import mediumIcon from 'features/base/assets/images/svg/jiraticket-medium-priority.svg';
import highIcon from 'features/base/assets/images/svg/jiraticket-high-priority.svg';
import highestIcon from 'features/base/assets/images/svg/jiraticket-highest-priority.svg';
import projectIcon from 'features/base/assets/images/svg/project-icon.svg';
import epicIcon from 'features/base/assets/images/svg/epic-icon.svg';
import taskIcon from 'features/base/assets/images/svg/task-icon.svg';
import bugIcon from 'features/base/assets/images/svg/bug-icon.svg';
import storyIcon from 'features/base/assets/images/svg/story-icon.svg';
import COLORS from 'features/base/constants/colors';
import JIRA_TICKET_SEVERITY_LEVELS from 'features/base/constants/jira-ticket-severity';
import JIRA_ISSUE_TYPES from 'features/base/constants/jira-ticket-types';
import JIRA_TICKET_URL_DOMAIN from 'features/base/constants/jira-ticket-urls';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import { getNumberOfWorkingDays } from 'features/base/helpers/date';
import moment from 'moment';
import './index.scss';

/**
 * Table component to render external users of a project
 * @returns {Box}
 */
const DetailsTable = ({ tickets, project }) => {
  //
  const ticketPriority = (priority) => {
    switch (priority) {
      case JIRA_TICKET_SEVERITY_LEVELS.LOWEST:
        return (
          <Box sx={{ display: 'flex' }}>
            <img src={lowestIcon} className="icon-handling" alt="Low Icon" />
            <Typography>Lowest</Typography>
          </Box>
        );
      case JIRA_TICKET_SEVERITY_LEVELS.LOW:
        return (
          <Box sx={{ display: 'flex' }}>
            <img src={lowIcon} className="icon-handling" alt="Low Icon" />
            <Typography>Low</Typography>
          </Box>
        );
      case JIRA_TICKET_SEVERITY_LEVELS.MEDIUM:
        return (
          <Box sx={{ display: 'flex' }}>
            <img src={mediumIcon} className="icon-handling" alt="Medium Icon" />
            <Typography>Medium</Typography>
          </Box>
        );
      case JIRA_TICKET_SEVERITY_LEVELS.HIGH:
        return (
          <Box sx={{ display: 'flex' }}>
            <img src={highIcon} className="icon-handling" alt="High Icon" />
            <Typography>High</Typography>
          </Box>
        );
      case JIRA_TICKET_SEVERITY_LEVELS.HIGHEST:
        return (
          <Box sx={{ display: 'flex' }}>
            <img src={highestIcon} className="icon-handling" alt="Low Icon" />
            <Typography>Highest</Typography>
          </Box>
        );
      default:
        return '-';
    }
  };
  //
  const ticketType = ({ type, summary, key }) => {
    switch (type) {
      case JIRA_ISSUE_TYPES.BUG:
        return (
          <Box
            sx={{
              display: 'flex',
              ':hover': {
                cursor: 'pointer',
                pl: 2,
                transition: 'ease',
                transitionDuration: '0.3s',
              },
            }}
          >
            <img src={bugIcon} className="icon-handling" alt="Bug Icon" />
            <Link
              href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues/${key}`}
              underline="hover"
              color="inherit"
              target="_blank"
            >
              <Typography>{summary}</Typography>
            </Link>
          </Box>
        );
      case JIRA_ISSUE_TYPES.TASK:
        return (
          <Box
            sx={{
              display: 'flex',
              ':hover': {
                cursor: 'pointer',
                pl: 2,
                transition: 'ease',
                transitionDuration: '0.3s',
              },
            }}
          >
            <img src={taskIcon} className="icon-handling" alt="Task Icon" />
            <Link
              href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues/${key}`}
              underline="hover"
              color="inherit"
              target="_blank"
            >
              <Typography>{summary}</Typography>
            </Link>
          </Box>
        );
      case JIRA_ISSUE_TYPES.STORY:
        return (
          <Box
            sx={{
              display: 'flex',
              ':hover': {
                cursor: 'pointer',
                pl: 2,
                transition: 'ease',
                transitionDuration: '0.3s',
              },
            }}
          >
            <img src={storyIcon} className="icon-handling" alt="Story Icon" />
            <Link
              href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues/${key}`}
              underline="hover"
              color="inherit"
              target="_blank"
            >
              <Typography>{summary}</Typography>
            </Link>
          </Box>
        );
      case JIRA_ISSUE_TYPES.EPIC:
        return (
          <Box
            sx={{
              display: 'flex',
              ':hover': {
                cursor: 'pointer',
                pl: 2,
                transition: 'ease',
                transitionDuration: '0.3s',
              },
            }}
          >
            <img src={epicIcon} className="icon-handling" alt="Epic Icon" />
            <Link
              href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues/${key}`}
              underline="hover"
              color="inherit"
              target="_blank"
            >
              <Typography>{summary}</Typography>
            </Link>
          </Box>
        );
      default:
        return (
          <Link
            href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues/${key}`}
            underline="hover"
            color="inherit"
            target="_blank"
          >
            <Typography>{summary}</Typography>
          </Link>
        );
    }
  };
  //
  return (
    //
    <TableContainer component={Paper}>
      <Table sx={{ width: 1200 }}>
        <TableHead>
          <TableRow>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={380}
            >
              Tasks
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={200}
            >
              Assignee
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={170}
            >
              Status
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={110}
            >
              Priority
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={120}
            >
              Start Date
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={120}
            >
              Due Date
            </TableCell>
            <TableCell
              size="small"
              rowSpan={2}
              sx={{
                height: 80,
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
              width={100}
            >
              Duration
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell size="small" sx={{ height: 52 }}>
              <Box
                sx={{
                  display: 'flex',
                  ':hover': {
                    cursor: 'pointer',
                    pl: 2,
                    transition: 'ease',
                    transitionDuration: '0.3s',
                  },
                }}
              >
                <img src={projectIcon} className="icon-handling" alt="Project Icon" />
                <Link
                  href={`${JIRA_TICKET_URL_DOMAIN}/${project?.jiraKey}/issues`}
                  underline="hover"
                  color="inherit"
                  target="_blank"
                >
                  <Typography>{project?.name}</Typography>
                </Link>
              </Box>
            </TableCell>
            <TableCell size="small" sx={{ height: 52 }}>
              {'-'}
            </TableCell>
            <TableCell size="small" sx={{ height: 52 }}>
              {'-'}
            </TableCell>
            <TableCell size="small" sx={{ height: 52 }}>
              {'-'}
            </TableCell>
            <TableCell size="small" sx={{ height: 52 }}>
              {project?.startDate ? moment(project?.startDate).format(DATE_FORMAT_SLASH) : '-'}
            </TableCell>
            <TableCell size="small" sx={{ height: 52 }}>
              {project?.endDate ? moment(project?.endDate).format(DATE_FORMAT_SLASH) : '-'}
            </TableCell>
            <TableCell
              size="small"
              sx={{
                height: 52,
              }}
            >
              {project?.startDate && project?.endDate
                ? `${getNumberOfWorkingDays(project?.startDate, project?.endDate)} days`
                : '-'}
            </TableCell>
          </TableRow>
          {tickets?.docs?.map(
            (ticket) =>
              ticket?.startDate &&
              ticket?.dueDate && (
                <TableRow key={ticket?.id}>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticketType({ type: ticket?.type, summary: ticket?.summary, key: ticket?.key })}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticket?.assignee?.firstName || '-'}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {capitalizeFirstLetter(ticket?.status)}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticketPriority(ticket?.priority)}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticket?.startDate ? moment(ticket?.startDate).format(DATE_FORMAT_SLASH) : '-'}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticket?.dueDate ? moment(ticket?.dueDate).format(DATE_FORMAT_SLASH) : '-'}
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      height: 52,
                    }}
                  >
                    {ticket?.startDate && ticket?.dueDate
                      ? `${getNumberOfWorkingDays(ticket?.startDate, ticket?.dueDate)} days`
                      : '-'}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
//
export default DetailsTable;
