import ROUTES from 'features/base/constants/routes';
import AuthWrapper from 'features/base/auth/components/auth-wrapper';
import DashboardLayout from 'features/base/layouts/dashboard';
import { PrivateTypeRoute } from 'features/base/auth/components';
import AuthLayout from 'features/base/layouts/auth';
import { SupportTicketView, SingleSupportTicketView } from 'features/support-tickets/components';
import LinkHub from 'features/linkhub/components';
import { UserView } from 'features/users/components';
import {
  ProjectDashboardLayout,
  ProjectView,
  ExternalUserTable,
  WorkAllocationTable,
  CreateTeam,
  WorkAllocationFixedBidTable,
  UpdateWorkAllocations,
  DocumentsTable,
  GanttChart,
  HandlingSupportTicketsTable,
} from 'features/projects/components';
import { WelcomePage } from 'features/sign-in/components';
import { InternalOnboardingForm } from 'features/internal-onboarding/components';
import { OpexView } from 'features/opex/components';
import { ClientView } from 'features/clients/components';
import { Page404, Page401 } from 'features/error-pages';
import { LinkView } from 'features/links/components';
import { ProfileView } from 'features/profile/components';
import { ToolsView } from 'features/tools/components';
import { RolesView } from 'features/roles/components';
import { SkillDashboardLayout, SkillsTable, SkillGroupsTable } from 'features/skills/components';
import { DepartmentsView, DepartmentTable, WorkArea } from 'features/departments/components';
import AuthWindow from 'features/sign-in/components/auth-popup-window';
import PrivatePermissionRoute from 'features/base/auth/components/private-permission-route';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { DesignationView } from 'features/designations/components';
import ClientDashboardLayout from 'features/clients/components/client-dashboard/client-dashboard-layout';
import ClientUsersTable from 'features/clients/components/client-dashboard/client-users-table';
import ClientProjectsTable from 'features/clients/components/client-dashboard/client-projects-table';
import LOGICAL_OPERATIONS from 'features/base/constants/logical-operations';
import LoggedHoursReport from 'features/projects/components/logged-hours-report';
import TeamReportView from 'features/reports/team-report';
import AllocationReport from 'features/reports/work-allocation-report';
import AllocatedActualReport from 'features/reports/allocated-actual-report';
import SkillReportView from 'features/reports/skill-report';
import ToolHub from 'features/toolhub/components';
import CapacityReportView from 'features/reports/capacity-report';
import SalaryReportView from 'features/reports/salary-report';
import CreateBenefitMultiStepForm from 'features/benefits/components/create-benefit-page';
import { ArsenalView } from 'features/arsenal/components';
import { AllBenefitsView, MyBenefitsView } from 'features/benefits/components';
import { VendorView } from 'features/vendors/components';
import VendorPortal from 'features/vendors/components/vendor-portal';

/**
 * Defines the routing structure using array including nested routing.
 * Defines the auth wrapper and private routers for helping to role based routes
 */
const routes = [
  {
    path: ROUTES.WELCOME,
    element: <WelcomePage />,
  },
  {
    path: ROUTES.LOGIN,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES.REDIRECT,
        element: <AuthWindow />,
      },
    ],
  },
  {
    path: ROUTES.INTERNAL_ONBOARD,
    children: [
      {
        path: '',
        element: (
          <AuthWrapper>
            <PrivateTypeRoute component={InternalOnboardingForm} />
          </AuthWrapper>
        ),
      },
    ],
  },
  {
    path: ROUTES.LINK_HUB,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateTypeRoute component={LinkHub} />,
      },
    ],
  },
  {
    path: ROUTES.ARSENAL,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={ArsenalView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.ARSENAL,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.MANAGE_BENEFITS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={AllBenefitsView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.BENEFIT,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
      {
        path: ROUTES.CREATE_BENEFIT,
        element: (
          <PrivatePermissionRoute
            component={CreateBenefitMultiStepForm}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.BENEFIT,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.VENDOR_PORTAL,

    children: [
      {
        path: '',
        element: <VendorPortal />,
      },
    ],
  },
  {
    path: ROUTES.BENEFITS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateTypeRoute component={MyBenefitsView} />,
      },
    ],
  },
  {
    path: ROUTES.TOOL_HUB,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateTypeRoute component={ToolHub} />,
      },
    ],
  },
  {
    path: ROUTES.SUPPORT_TICKETS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <SupportTicketView />,
      },
    ],
  },
  {
    path: ROUTES.SINGLE_SUPPORT_TICKET,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <SingleSupportTicketView />,
      },
    ],
  },
  {
    path: ROUTES.USERS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={UserView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.USER,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
      {
        path: ROUTES.PROFILE,
        element: <ProfileView />,
        children: [
          {
            path: ROUTES.CONFIGURATIONS,
            element: <ProfileView />,
          },
          {
            path: ROUTES.SKILLS,
            element: <ProfileView />,
          },
          {
            path: ROUTES.ALLOCATIONS,
            element: <ProfileView />,
          },
          {
            path: ROUTES.TOOLS,
            element: <ProfileView />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.PROJECTS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={ProjectView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
      {
        path: ROUTES.PROJECT,
        element: (
          <PrivatePermissionRoute
            component={ProjectDashboardLayout}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
        children: [
          {
            path: ROUTES.PROJECT_WORK_ALLOCATION,
            element: (
              <PrivatePermissionRoute
                component={WorkAllocationTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.PROJECT_GANTT_CHART,
            element: (
              <PrivatePermissionRoute
                component={GanttChart}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.PROJECT,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.PROJECT_REPOSITORIES,
            element: <h1>Repositories</h1>,
          },
          {
            path: ROUTES.PROJECT_EXTERNAL_MEMBERS,
            element: (
              <PrivatePermissionRoute
                component={ExternalUserTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.PROJECT_EXTERNAL_USERS,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.PROJECT_LOGGED_HOURS,
            element: <PrivatePermissionRoute component={LoggedHoursReport} />,
          },
          {
            path: ROUTES.PROJECT_DOCUMENTS,
            element: (
              <PrivatePermissionRoute
                component={DocumentsTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.PROJECT,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.PROJECT_CLIENT_SUPPORT_TICKETS,
            element: (
              <PrivatePermissionRoute
                component={HandlingSupportTicketsTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.TICKET_HANDLING,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.PROJECT_DEPLOYMENTS,
            element: <h1>Deployments</h1>,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.PROJECT_BILLING,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={WorkAllocationFixedBidTable}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT,
                action: PERMISSION_ACTIONS.CREATE,
              },
              {
                domain: PERMISSION_DOMAINS.PROJECT,
                action: PERMISSION_ACTIONS.UPDATE,
              },
            ]}
            operation={LOGICAL_OPERATIONS.AND}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.MANAGE_TOOLS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={ToolsView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.TOOL,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.MANAGE_SKILLS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={SkillDashboardLayout}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.SKILL,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
        children: [
          {
            path: ROUTES.MANAGE_SKILL_SKILLS,
            element: (
              <PrivatePermissionRoute
                component={SkillsTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.ORGANIZATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.MANAGE_SKILL_SKILL_GROUPS,
            element: (
              <PrivatePermissionRoute
                component={SkillGroupsTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.ORGANIZATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.MANAGE_OPEX,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={OpexView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.OPEX,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <Page404 />,
  },
  {
    path: ROUTES.NOT_AUTHORIZED,
    element: <Page401 />,
  },
  {
    path: ROUTES.MANAGE_ROLES,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={RolesView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.ROLE,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.MANAGE_DEPARTMENTS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={DepartmentsView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.ORGANIZATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
        children: [
          {
            path: '',
            element: (
              <PrivatePermissionRoute
                component={DepartmentTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.ORGANIZATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.MANAGE_DEPARTMENTS_DESIGNATION,
            element: (
              <PrivatePermissionRoute
                component={DesignationView}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.ORGANIZATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.MANAGE_DEPARTMENTS_WORK_AREA,
            element: (
              <PrivatePermissionRoute
                component={WorkArea}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.ORGANIZATION,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.MANAGE_VENDORS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={VendorView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.BENEFIT,
                action: PERMISSION_ACTIONS.READ,
              },
              {
                domain: PERMISSION_DOMAINS.BENEFIT,
                action: PERMISSION_ACTIONS.CREATE,
              },
              {
                domain: PERMISSION_DOMAINS.BENEFIT,
                action: PERMISSION_ACTIONS.UPDATE,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.PROJECT_WORK_ALLOCATION_UPDATE,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={UpdateWorkAllocations}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.CREATE,
              },
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.UPDATE,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.PROJECT_WORK_ALLOCATION_CREATE,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={CreateTeam}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.CREATE,
              },
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.UPDATE,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.MANAGE_CLIENTS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={ClientView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.CLIENT,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
      {
        path: ROUTES.MANAGE_CLIENT,
        element: (
          <PrivatePermissionRoute
            component={ClientDashboardLayout}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.CLIENT,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
        children: [
          {
            path: ROUTES.MANAGE_CLIENT_USERS,
            element: (
              <PrivatePermissionRoute
                component={ClientUsersTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.CLIENT,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
          {
            path: ROUTES.MANAGE_CLIENT_PROJECTS,
            element: (
              <PrivatePermissionRoute
                component={ClientProjectsTable}
                permissions={[
                  {
                    domain: PERMISSION_DOMAINS.CLIENT,
                    action: PERMISSION_ACTIONS.READ,
                  },
                ]}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.MANAGE_LINKS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={LinkView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.LINK,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.TEAM_REPORT,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={TeamReportView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.ALLOCATION_REPORT,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={AllocationReport}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.SKILL_REPORT,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={SkillReportView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.SKILL,
                action: PERMISSION_ACTIONS.READ,
              },
              {
                domain: PERMISSION_DOMAINS.USER,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.CAPACITY_REPORT,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={() => <CapacityReportView />}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.READ,
              },
              {
                domain: PERMISSION_DOMAINS.USER,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.ALLOCATED_VS_ACTUAL_HOURS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={AllocatedActualReport}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.SALARY_REPORT,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivatePermissionRoute
            component={SalaryReportView}
            permissions={[
              {
                domain: PERMISSION_DOMAINS.USER,
                action: PERMISSION_ACTIONS.READ,
              },
              {
                domain: PERMISSION_DOMAINS.SALARY,
                action: PERMISSION_ACTIONS.READ,
              },
              {
                domain: PERMISSION_DOMAINS.ORGANIZATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
          />
        ),
      },
    ],
  },
];
//
export default routes;
