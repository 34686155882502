import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
//
import { ENVIRONMENT } from 'config';
import { capitalizeFirstLetter } from '../../../helpers/strings';
/**
 * View tools table columns - basic details
 */
export const TOOLS_TABLE_COLUMNS_BASIC = [
  {
    field: 'tool',
    headerName: 'Tool name',
    width: 250,
    valueGetter: (params) => params.row.tool.toolName,
    renderCell: (params) => {
      const { toolId, toolLogo, toolName } = params.row.tool;
      return (
        <Grid container item flexDirection="row" alignItems="center">
          {!toolLogo?.length > 0 ? (
            <Avatar sx={{ height: '40px', width: '40px' }}>{toolName?.charAt(0)}</Avatar>
          ) : (
            <img
              src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${toolLogo[0].url}`}
              key={toolId}
              alt="logo"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          )}
          <Typography
            sx={{
              maxWidth: '15ch',
              wordWrap: 'break-word',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ml: 1,
            }}
          >
            {toolName}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    valueGetter: (params) => params.row.description.value,
    renderCell: (params) => {
      const { value } = params.row.description;
      return (
        <Typography
          noWrap
          sx={{
            maxWidth: '40ch',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'paymentType',
    headerName: 'Type',
    width: 200,
    valueGetter: (params) => params.row.paymentType.value,
    renderCell: (params) => {
      const { value } = params.row.paymentType;
      return (
        <Typography sx={{ maxWidth: '10ch', wordWrap: 'break-word' }}>
          {capitalizeFirstLetter(value)}
        </Typography>
      );
    },
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 200,
    valueGetter: (params) => params.row.price.value,
    renderCell: (params) => {
      const { value } = params.row.price;
      return <Typography sx={{ maxWidth: '12ch' }}>{value}</Typography>;
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created date',
    width: 200,
    type: 'date',
    valueGetter: (params) => new Date(params.row.createdDate.value),
    renderCell: (params) => {
      const { value } = params.row.createdDate;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'lastUpdate',
    headerName: 'Last update',
    width: 150,
    type: 'date',
    valueGetter: (params) => new Date(params.row.lastUpdate.value),
    renderCell: (params) => {
      const { value } = params.row.lastUpdate;
      return <Typography>{value}</Typography>;
    },
  },
];
/**
 * View tools table columns - action columns
 */
export const TOOLS_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: ({
      value: {
        toolsEditPermissionsVerified,
        toolsDeletePermissionsVerified,
        isLoading,
        handleToolEdit,
        handleToolDelete,
      },
    }) => (
      <Box display="flex">
        {toolsEditPermissionsVerified && (
          <IconButton
            sx={{ '&:hover': { color: 'blue' } }}
            onClick={handleToolEdit}
            readOnly={isLoading}
          >
            <DriveFileRenameOutline />
          </IconButton>
        )}
        {toolsDeletePermissionsVerified && (
          <IconButton
            sx={{ '&:hover': { color: 'red' } }}
            onClick={handleToolDelete}
            readOnly={isLoading}
          >
            <DeleteForever />
          </IconButton>
        )}
      </Box>
    ),
  },
];
