/**
 * Multiple file upload max count
 */
export const MULTIPLE_FILE_UPLOAD_MAXIMUM = 100;
/**
 * Project logo file type/extension data
 */
export const PROJECT_LOGO_ALLOWED_FILE_TYPES = 'image/*';
export const PROJECT_LOGO_ALLOWED_EXTENSIONS = ['png', 'jpg', 'webp'];
/**
 * Project documents type/extension/size data
 */
export const PROJECT_DOCUMENTS_ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'application/pdf',
  'application/msword',
  'text/csv',
  'application/vnd.ms-excel',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const PROJECT_DOCUMENTS_ALLOWED_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'txt',
  'xls',
  'xlsx',
];
export const PROJECT_DOCUMENTS_MAX_SIZE = 50;
/**
 * Support ticket attachments file type/extension data
 */

export const SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'video/mp4',
  'image/gif',
  'image/jpeg',
  'image/png',
  'application/x-rar-compressed',
  'application/x-zip-compressed',
  'application/zip',
  'video/webm',
  'application/x-7z-compressed',
];
export const SUPPORT_TICKET_ALLOWED_EXTENSIONS = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'mp4',
  'gif',
  'jpg',
  'jpeg',
  'png',
  'rar',
  'zip',
  '.webm',
  '.7z',
];
export const SUPPORT_TICKET_ATTACHMENT_MAX_SIZE = 50;
/**
 * Benefits attachments file type/extension data
 */
export const BENEFITS_ATTACHMENTS_ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/gif',
  'image/svg+xml',
  'image/jpg',
  'image/tiff',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const BENEFITS_ATTACHMENTS_ALLOWED_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'doc',
  'docx',
  'tiff',
  'gif',
  'svg',
];
export const BENEFITS_ATTACHMENTS_MAX_SIZE = 5;
/**
 * Tool logo file type/extension data
 */
export const TOOL_LOGO_ALLOWED_FILE_TYPES = 'image/*';
export const TOOL_LOGO_ALLOWED_EXTENSIONS = ['png', 'jpg', 'webp'];
/**
 * Client logo file type/extension data
 */
export const CLIENT_LOGO_ALLOWED_FILE_TYPES = 'image/*';
export const CLIENT_LOGO_ALLOWED_EXTENSIONS = ['png', 'jpg', 'webp'];
/**
 * Link logo file type/extension data
 */
export const LINK_LOGO_ALLOWED_FILE_TYPES = 'image/*';
export const LINK_LOGO_ALLOWED_EXTENSIONS = ['png', 'jpg', 'webp'];
/**
 * Bulk salary upload file type/extension data
 */
export const BULK_SALARY_UPLOAD_ALLOWED_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel'];
export const BULK_SALARY_UPLOAD_ALLOWED_EXTENSIONS = ['csv', 'xls', 'xlsx'];
export const BULK_SALARY_UPLOAD_MAX_FILE_SIZE = 50;
/**
 * Bulk user data upload file type/extension data
 */
export const BULK_USER_DATA_UPLOAD_ALLOWED_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel'];
export const BULK_USER_DATA_UPLOAD_ALLOWED_EXTENSIONS = ['csv', 'xls', 'xlsx'];
export const BULK_USER_DATA_UPLOAD_MAX_FILE_SIZE = 50;
/**
 * Vendor logo file type/extension data
 */
export const VENDOR_LOGO_ALLOWED_FILE_TYPES = 'image/*';
export const VENDOR_LOGO_ALLOWED_EXTENSIONS = ['png', 'jpg', 'webp'];
/**
 * Benefits promo codes attachments file type/extension data
 */
export const BENEFITS_PROMO_CODES_ALLOWED_FILE_TYPES = [
  'text/csv',
];
export const BENEFITS_PROMO_CODES_ALLOWED_EXTENSIONS = [
  'csv',
];