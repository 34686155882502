import { Navigate } from 'react-router-dom';
//
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import ROUTES from 'features/base/constants/routes';

/**
 * A Private Route component for protecting routes based on the users permissions
 * @param {component} - React component to render if user is authorized
 * @returns {component}
 */
const PrivatePermissionRoute = ({ component: Component, permissions, operation }) => {
  if (permissions) {
    const { permissionsVerified } = useIsPermissionsVerified();
    // If permissions verified, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return permissionsVerified(permissions, operation) ? (
      <Component />
    ) : (
      <Navigate to={ROUTES.WELCOME} />
    );
  }
  return <Component />;
};
//
export default PrivatePermissionRoute;
