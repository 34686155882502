import {
  ARSENAL_ITEM_TYPES,
  ARSENAL_TYPE_URL_DOMAINS,
} from 'features/base/constants/arsenal-item-types';
import { getKeyByValue } from 'features/base/helpers/object';
import * as yup from 'yup';
/**
 * Schema for arsenal form
 */
const arsenalFormValidation = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(50, ({ max }) => `Name name must be at maximum of ${max} characters`),
  description: yup
    .string()
    .required('Description is required')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(300, ({ max }) => `Description must be at maximum of ${max} characters`),
  teamMembers: yup.array().min(1, 'Please select at least one team member'),
  productOwners: yup.array().min(1, 'Please select at least one product owner'),
  techLeads: yup.array().min(1, 'Please select at least one tech lead'),
  linkInput: yup.string().url('Invalid URL format'),
  links: yup
    .array()
    .min(1, 'Please enter at least one link')
    .test({
      name: 'Category validation',
      exclusive: false,
      params: {},
      message: 'Please select a category for each link',
      test(value) {
        // Check if all documents have at least one tag
        return value?.every((link) => link?.category);
      },
    })
    .test({
      name: 'Url validation',
      exclusive: false,
      params: {},
      message: 'Entered URL does not belong to the chosen Category',
      test(value) {
        // Check if all url have valid category
        return value?.every((link) =>
          link?.url?.includes(ARSENAL_TYPE_URL_DOMAINS[getKeyByValue(ARSENAL_ITEM_TYPES, link.category)])
        );
      },
    }),
});
//
export default arsenalFormValidation;
