import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
//
import { opexActions } from 'features/opex/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectAddOpexLoader } from 'features/opex/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import DatePicker from 'features/base/components/date-picker';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { opexFormValidation } from 'features/opex/validations/opex-validation';
import './index.scss';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { NumericInputField } from 'features/base/components';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Function that defines the popup form for adding a new opex
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @prop {string} previousStartDate - previous start date
 * @returns {Popup}
 */
const AddNewOpexPopup = ({ addModalOpen, setAddModalOpen, previousStartDate }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddOpexLoader);
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.OPEX,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add Overhead"
        mediumSize="660px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            amount: '',
            startDate: null,
          }}
          validationSchema={opexFormValidation(previousStartDate)}
          onSubmit={(values) => {
            dispatch(opexActions.addOpex(values));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} className="add-opex-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Description"
                height={120}
                multiline
                rows={4}
              />
              <NumericInputField
                type="text"
                name="amount"
                label="Amount"
                value={values.amount}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
                onChange={(v) => setFieldValue('amount', v.floatValue)}
                onBlur={handleBlur}
                thousandSeparator=","
                prefix="$"
                placeholder="$"
              />
              <DatePicker
                label="Start date"
                value={values.startDate}
                error={Boolean(touched.startDate && errors.startDate)}
                errorMsg={touched?.startDate && errors?.startDate}
                views={['month', 'year']}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(event) => {
                  setFieldValue(
                    'startDate',
                    moment(event?.$d).startOf('month').format(ISO_WITHOUT_TIME)
                  );
                }}
                placeholder="Select date"
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddNewOpexPopup;
