import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Breadcrumbs,
  Link,
  Pagination,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DriveFileRenameOutline, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
//
import { Button, SearchBar, ShimmerCards } from 'features/base/components';
import { ARSENAL_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { arsenalActions } from 'features/arsenal/slice';
import { USER_TYPES } from 'features/base/constants/user-types';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { notificationActions } from 'features/base/notifications/slice';
import createFormattedString from 'features/base/helpers/param-formatter';
import { useDebouncedCallback } from 'use-debounce';
import TIME_OUTS from 'features/base/constants/time-outs';
import { selectArsenal, selectGetArsenalLoading } from 'features/arsenal/selector';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import ROUTES from 'features/base/constants/routes';
import AddArsenalItemPopup from '../add-arsenal-item/add-arsenal-item-popup';
import AddArsenalItemDrawer from '../add-arsenal-item/add-arsenal-item-drawer';
import ArsenalInfoPopup from '../arsenal-info/arsenal-info-popup';
import ArsenalInfoDrawer from '../arsenal-info/arsenal-info-drawer';
import EditArsenalItemPopup from '../edit-arsenal-item/edit-arsenal-item-popup';
import EditArsenalItemDrawer from '../edit-arsenal-item/edit-arsenal-item-drawer';
import './index.scss';

/**
 * Component that defines the arsenal card view
 * @returns {ArsenalView}
 */
const ArsenalView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  const arsenalPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ARSENAL,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectGetArsenalLoading);
  const arsenal = useSelector(selectArsenal);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy:
      'name:asc' /* As for now no sorting field is defined in the view, so added the sortBy key with a default value for future usage. */,
    rowsPerPage: ARSENAL_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [searchData, setSearchData] = useState();
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [selectedArsenalItem, setSelectedArsenalItem] = useState();
  //
  const handleAddPopup = () => {
    dispatch(
      arsenalActions.getUsers({
        query: `type=${USER_TYPES.INTERNAL}&pagination=false&sortBy=email:asc`,
      })
    );
    setAddModalOpen(true);
  };
  //
  const handleAddDrawer = () => {
    dispatch(
      arsenalActions.getUsers({
        query: `type=${USER_TYPES.INTERNAL}&pagination=false&sortBy=email:asc`,
      })
    );
    setAddDrawerOpen(true);
  };
  //
  const handleEditPopup = (arsenalItem) => {
    dispatch(
      arsenalActions.getUsers({
        query: `type=${USER_TYPES.INTERNAL}&pagination=false&sortBy=email:asc`,
      })
    );
    setSelectedArsenalItem(arsenalItem);
    setEditModalOpen(true);
  };
  //
  const handleEditDrawer = (arsenalItem) => {
    dispatch(
      arsenalActions.getUsers({
        query: `type=${USER_TYPES.INTERNAL}&pagination=false&sortBy=email:asc`,
      })
    );
    setSelectedArsenalItem(arsenalItem);
    setEditDrawerOpen(true);
  };
  //
  const handleViewMorePopup = (arsenalItem) => {
    setSelectedArsenalItem(arsenalItem);
    setInfoPopupOpen(true);
  };
  const handleViewMoreDrawer = (arsenalItem) => {
    setSelectedArsenalItem(arsenalItem);
    setInfoDrawerOpen(true);
  };
  //
  const search = () => {
    // When page controller gets updated, params gets updated, which is a dependency in the useEffect
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(search, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (event) => {
    setSearchData(event.target.value);
    debounced();
  };
  //
  const CARDS_PER_ROW = 3;
  //
  const params = useMemo(
    () => ({
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
      search: searchData,
    }),
    [pageController]
  );
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(arsenalActions.getArsenal({ query: formattedParamString }));
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  useEffect(() => () => dispatch(arsenalActions.resetState()), []);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <AddArsenalItemPopup open={addModalOpen} setOpen={setAddModalOpen} />
      <AddArsenalItemDrawer open={addDrawerOpen} setOpen={setAddDrawerOpen} />
      <EditArsenalItemPopup
        open={editModalOpen}
        setOpen={setEditModalOpen}
        arsenalItemToBeEdited={selectedArsenalItem}
      />
      <EditArsenalItemDrawer
        open={editDrawerOpen}
        setOpen={setEditDrawerOpen}
        arsenalItemToBeEdited={selectedArsenalItem}
      />
      <ArsenalInfoPopup
        open={infoPopupOpen}
        setOpen={setInfoPopupOpen}
        arsenalItem={selectedArsenalItem}
      />
      <ArsenalInfoDrawer
        open={infoDrawerOpen}
        setOpen={setInfoDrawerOpen}
        arsenalItem={selectedArsenalItem}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 8 }} px={{ xs: 0, lg: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }} data-testid="breadcrumb">
          <Link underline="hover" color="inherit" href={ROUTES.LINK_HUB}>
            Link Hub
          </Link>
          <Typography color="primary">Arsenal</Typography>
        </Breadcrumbs>
        <Grid container direction="row" mb={3} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', width: '60%' }}>
              Arsenal
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
            <SearchBar
              placeholder="Search by name or category"
              value={searchData}
              handleSearchInput={handleSearchInput}
              sx={{ width: 350, mr: 2 }}
              data-testid="search"
            />
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.ARSENAL,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              {/* This will open popup on desktop */}
              <Button
                onClick={() => handleAddPopup()}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
                data-testid="add-item-desktop"
              >
                Add item <AddIcon />
              </Button>
              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  backgroundColor: { xs: 'white', sm: 'unset' },
                  position: { xs: 'fixed', sm: 'unset' },
                  bottom: { xs: '0px', sm: 'unset' },
                  zIndex: { xs: 1, sm: 'unset' },
                  width: { xs: '100%', sm: 'unset' },
                  py: { xs: '10px', sm: 'unset' },
                  px: { xs: '20px', sm: 'unset' },
                  left: { xs: '0px', sm: 'unset' },
                }}
              >
                {/* This will open drawer on mobile */}
                <Button
                  onClick={() => handleAddDrawer()}
                  sx={{
                    width: { lg: 'auto', xs: '100%' },
                  }}
                >
                  Add item <AddIcon />
                </Button>
              </Box>
            </PermissionWrapper>
          </Grid>
        </Grid>
        {loading ? (
          <ShimmerCards />
        ) : (
          <>
            <Box className="root" data-testid="arsenal-card-view">
              <Box
                className="cardContainer"
                sx={
                  arsenal?.docs?.length < CARDS_PER_ROW
                    ? { justifyContent: 'flex-start' }
                    : { justifyContent: 'center' }
                }
              >
                {arsenal?.docs?.map((arsenalItem) => (
                  <Card
                    className="card"
                    elevation={5}
                    key={arsenalItem?.id}
                    sx={{ height: { xs: 200, md: 175 } }}
                  >
                    <CardContent sx={{ p: 0 }}>
                      <Box className="title">
                        <Typography variant="h5" component="div">
                          {arsenalItem?.name}
                        </Typography>
                        {arsenalPermissionsVerified && (
                          <>
                            {/* This renders on desktop */}
                            <IconButton
                              aria-label="Edit"
                              className="editButton"
                              sx={{ display: { xs: 'none', sm: 'flex' } }}
                              onClick={() => handleEditPopup(arsenalItem)}
                            >
                              <DriveFileRenameOutline />
                            </IconButton>
                            {/* This renders on mobile */}
                            <IconButton
                              aria-label="Edit"
                              className="editButton"
                              sx={{ display: { xs: 'block', sm: 'none' } }}
                              onClick={() => handleEditDrawer(arsenalItem)}
                            >
                              <DriveFileRenameOutline />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      <Box className="divider" />
                      <Tooltip title={arsenalItem?.description}>
                        <Typography variant="body2" color="text.secondary" className="content">
                          {arsenalItem?.description.length > 75
                            ? `${arsenalItem?.description?.slice(0, 75)}...`
                            : arsenalItem?.description}
                        </Typography>
                      </Tooltip>
                      {/* This renders on desktop */}
                      <Stack
                        spacing={1}
                        direction="row"
                        onClick={() => handleViewMorePopup(arsenalItem)}
                        sx={{ cursor: 'pointer', display: { xs: 'none', sm: 'flex' } }}
                        className="view-more"
                        data-testid="arsenal-view-more-desktop"
                      >
                        <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                          View more
                        </Typography>
                        <ArrowForwardIcon
                          sx={{ color: (theme) => theme.palette.primary.main }}
                          style={{ fontSize: '1.2rem' }}
                        />
                      </Stack>
                      {/* This renders on mobile */}
                      <Stack
                        spacing={1}
                        direction="row"
                        onClick={() => handleViewMoreDrawer(arsenalItem)}
                        sx={{ cursor: 'pointer', display: { xs: 'block', sm: 'none' } }}
                        className="view-more"
                      >
                        <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                          View more
                        </Typography>
                        <ArrowForwardIcon
                          sx={{ color: (theme) => theme.palette.primary.main }}
                          style={{ fontSize: '1.2rem' }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
            {arsenal?.docs?.length > 0 && (
              <Pagination
                count={Math.ceil((arsenal?.totalDocs ?? 0) / ARSENAL_PAGINATION_LIMIT)}
                page={pageController?.page}
                variant="outlined"
                shape="rounded"
                onChange={(_, page) => {
                  if (page === pageController?.page) return;
                  setPageController({ ...pageController, page });
                }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            )}
            {!arsenal?.docs?.length && (
              <Grid item sx={{ marginTop: 20 }} xs={12}>
                <CustomNoRowsOverlay message="Arsenal empty!" size />
              </Grid>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};
//
export default ArsenalView;
