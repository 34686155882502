import { Alert, useMediaQuery, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
//
import { roleActions } from 'features/roles/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectAddRoleLoader } from 'features/roles/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import { addRoleFormValidation } from 'features/roles/validation/role-validations';
import {
  getDefaultPermissionMatrix,
  handleMatrixClick,
  onSubmitFormatter,
} from 'features/base/helpers/permissions';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import Matrix from 'features/base/components/matrix';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';

/**
 * Function that defines the popup form for adding a new role
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddNewRolePopup = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  //
  const [permissions, setPermissions] = useState(getDefaultPermissionMatrix());
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddRoleLoader);
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    setPermissions(getDefaultPermissionMatrix());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  const handleClick = (domainIndex, actionIndex) => {
    const newPermissions = handleMatrixClick(permissions, domainIndex, actionIndex);
    //
    setPermissions(newPermissions);
  };
  //
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ROLE,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add New Role"
        mediumSize="720px"
        fullScreen={isXsOrSm}
        fullWidth={isXsOrSm}
      >
        <Formik
          initialValues={{
            name: '',
            permissions: {},
          }}
          validationSchema={addRoleFormValidation}
          onSubmit={(value) => {
            const result = onSubmitFormatter(permissions);
            //
            const data = {
              name: value.name,
              permissions: result,
            };
            //
            dispatch(roleActions.addRole({ ...data }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Name"
              />
              <Matrix rows={permissions} handleClick={handleClick} sx={{ fontWeight: 600 }} />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddNewRolePopup;
