/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
import { skillActions } from './slice';
import API from './constants';

/**
 * Generator function for adding skills
 * @param {Object} payload
 */
export function* addSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SKILLS, payload);
    yield put(skillActions.addSkillsSucceeded({
      successFullyAddedSkills: response,
      allSkills: payload.skills
    }));
  } catch (error) {
    yield put(skillActions.addSkillsFailed(error?.message));
  }
}
/**
 * Generator function for getting skills
 * @param {Object} payload
 */
export function* getSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILLS, payload);
    yield put(skillActions.getSkillsSucceeded(response));
  } catch (error) {
    yield put(skillActions.getSkillsFailed(error?.message));
  }
}
/**
 * Generator function for editing skills
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editSkillGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_SKILL, payload);
    yield put(skillActions.editSkillSucceeded(response));
  } catch (error) {
    yield put(skillActions.editSkillFailed(error?.message));
  }
}
/**
 * Generator function for deleting skills
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* deleteSkillGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_SKILL, payload);
    yield put(skillActions.deleteSkillSucceeded(response));
  } catch (error) {
    yield put(skillActions.deleteSkillFailed(error?.message));
  }
}
/**
 * Generator function for getting all skills
 * @param {Object} payload
 */
export function* getAllSkillsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILLS, payload);
    yield put(skillActions.getAllSkillsSucceeded(response));
  } catch (error) {
    yield put(skillActions.getAllSkillsFailed(error?.message));
  }
}
/**
 * Generator function for adding skill groups
 * @param {Object} payload
 */
export function* addSkillGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SKILL_GROUP, payload);
    yield put(skillActions.addSkillGroupSucceeded(response));
  } catch (error) {
    yield put(skillActions.addSkillGroupFailed(error?.message));
  }
}
/**
 * Generator function for getting skill groups
 * @param {Object} payload
 */
export function* getSkillGroupsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILL_GROUPS, payload);
    yield put(skillActions.getSkillGroupsSucceeded(response));
  } catch (error) {
    yield put(skillActions.getSkillGroupsFailed(error?.message));
  }
}
/**
 * Generator function for updating skill groups
 * @param {Object} payload
 */
export function* editSkillGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_SKILL_GROUP, payload);
    yield put(skillActions.editSkillGroupSucceeded(response));
  } catch (error) {
    yield put(skillActions.editSkillGroupFailed(error?.message));
  }
}
/**
 * Generator function for deleting skill groups
 * @param {Object} payload
 */
export function* deleteSkillGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_SKILL_GROUP, payload);
    yield put(skillActions.deleteSkillGroupSucceeded(response));
  } catch (error) {
    yield put(skillActions.deleteSkillGroupFailed(error?.message));
  }
}
/**
 * Generator function for getting all skill groups
 * @param {Object} payload
 */
export function* getAllSkillGroupsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILL_GROUPS, payload);
    yield put(skillActions.getAllSkillGroupsSucceeded(response));
  } catch (error) {
    yield put(skillActions.getAllSkillGroupsFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* skillsSaga() {
  yield takeLatest(skillActions.addSkills.type, addSkillsGenerator);
  yield takeLatest(skillActions.getSkills.type, getSkillsGenerator);
  yield takeLatest(skillActions.editSkill.type, editSkillGenerator);
  yield takeLatest(skillActions.deleteSkill.type, deleteSkillGenerator);
  yield takeLatest(skillActions.getAllSkills.type, getAllSkillsGenerator);
  yield takeLatest(skillActions.addSkillGroup.type, addSkillGroupGenerator);
  yield takeLatest(skillActions.getSkillGroups.type, getSkillGroupsGenerator);
  yield takeLatest(skillActions.editSkillGroup.type, editSkillGroupGenerator);
  yield takeLatest(skillActions.deleteSkillGroup.type, deleteSkillGroupGenerator);
  yield takeLatest(skillActions.getAllSkillGroups.type, getAllSkillGroupsGenerator);
}
//
export default skillsSaga;
