import { Box, Grid, Stack, Typography } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { useSelector } from 'react-redux';
//
import { Button, DataGrid } from 'features/base/components';
import bulkSelectIcon from 'features/base/assets/images/png/bulk-select.png';
import {
  selectCreateTeamIsAvailableUsersFetching,
  selectCreateTeamProject,
} from 'features/projects/selectors';
import AVAILABLE_TEAM_MEMBER_HEADERS from 'features/base/utils/tables/available-team-member-table';
import COLORS from 'features/base/constants/colors';
import { getDifferenceInMonths } from 'features/base/helpers/date';
/**
 * Defines the available user table section in the create team screen
 * @returns {Grid}
 */
const AvailableUserTableSection = ({
  onAvailableUserBulkIconClick,
  availableUsers,
  isAvailableUserMultipleSelectEnable,
  onAvailableUserCheckboxClick,
  isAvailableUserChecked,
  onPlusIconClick,
  multipleAvailableUsers,
  onAddUsersClick,
  handleChangePage,
  page,
  totalPages,
  totalDocs,
  limit,
}) => {
  const isAvailableUserLoading = useSelector(selectCreateTeamIsAvailableUsersFetching);
  const project = useSelector(selectCreateTeamProject);
  //
  const getProfilePictureBrightness = (disabled) => {
    if (disabled) {
      return { filter: 'brightness(75%)' };
    }
    return {};
  };
  //
  const isOverAllocated = (percentage) => percentage < 0;
  //
  return isAvailableUserLoading || !project?.id ? (
    <Grid item xs={12} lg={12}>
      <ShimmerTable row={3} col={2} />
    </Grid>
  ) : (
    <Grid item xs={12} lg={12}>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '16px',
          }}
        >
          <Typography variant="h2" sx={{ fontSize: '1.313rem', fontWeight: 600 }}>
            Available members
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onAvailableUserBulkIconClick()}
          >
            <img src={bulkSelectIcon} alt="bulk-select" height="20px" />
            <Typography variant="body1" sx={{ fontWeight: 600, color: COLORS.PRIMARY_MAIN }}>
              Multiple
            </Typography>
          </Box>
        </Box>
        <DataGrid
          rowHeight={availableUsers?.docs?.length ? 68 : 200}
          columns={AVAILABLE_TEAM_MEMBER_HEADERS}
          handleChangePage={({ page: newPage }) => handleChangePage(newPage)}
          page={page}
          totalPages={totalPages}
          totalDocs={totalDocs}
          limit={limit}
          pageSizeOptions={[limit]}
          getRowClassName={(p) => {
            if (p?.row?.isOverAllocated) {
              return 'available-user-over-allocated';
            }
            return '';
          }}
          rows={
            availableUsers?.docs?.map((user) => {
              const numOfMonths = getDifferenceInMonths(project?.startDate, project?.endDate)
              const availablePercentage = (
                100 -
                ((user?.utilization?.reduce((acc, item) => acc + item.totalHours ?? 0, 0) || 0) /
                  ((user?.capacity ?? 160) * numOfMonths)) *
                  100 +
                (user?.currentAllocation ? parseInt(user?.currentAllocation, 10) : 0)
              ).toFixed(2);
              //
              return {
                id: user?.id,
                isOverAllocated: isOverAllocated(availablePercentage),
                icon: {
                  user,
                  isAvailableUserMultipleSelectEnable,
                  onAvailableUserCheckboxClick,
                  isAvailableUserChecked,
                  onPlusIconClick,
                },
                profile: {
                  value: `${user?.firstName} ${user?.lastName}`,
                  userDisabled: user?.disabled,
                  profileImage: user?.profileImage,
                  designation: user?.currentUserDepartmentDesignation?.departmentDesignation
                    ?.designation?.name
                    ? user?.currentUserDepartmentDesignation?.departmentDesignation?.designation
                        ?.name
                    : '-',
                  getProfilePictureBrightness,
                },
                available: {
                  value: availablePercentage,
                  userDisabled: user?.disabled,
                  isOverAllocated,
                },
              };
            }) ?? []
          }
        />
      </Stack>
      {isAvailableUserMultipleSelectEnable && !!multipleAvailableUsers.length && (
        <Button onClick={() => onAddUsersClick()}>Add users</Button>
      )}
    </Grid>
  );
};
//
export default AvailableUserTableSection;
