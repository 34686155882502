import * as yup from 'yup';
import { BENEFIT_PROMOTION_TYPES, BENEFIT_PROMOTION_CODE_TYPES } from 'features/base/constants/benefit-types';
/**
 * Function for add benefit promo form validation
 */
const benefitPromoValidation = yup.object().shape({
  promotionType: yup.string().oneOf(Object.values(BENEFIT_PROMOTION_TYPES)).required('Please select a promotion type'),

  promotionCodeType: yup.string().oneOf(Object.values(BENEFIT_PROMOTION_CODE_TYPES)).required('Please select a promotion code type'),

  resources: yup.array().when('promotionCodeType', {
    is: BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME,
    then: yup.array().of(yup.string().required('Promo code is required')).min(1, 'At least one promo code is required'),
    otherwise: yup.array().nullable(true),
  }),
  resource: yup.string().when('promotionCodeType', {
    is: BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE,
    then: yup.string().required('Promo code is required'),
    otherwise: yup.string().nullable(true),
  }),

});
//
export default benefitPromoValidation;
