import { Alert, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
//
import departmentFormValidation from 'features/departments/validations/department-validation';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import { departmentActions } from 'features/departments/slice';
import { selectAddDepartmentsLoader } from 'features/departments/selectors';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import { selectAllDesignation } from 'features/designations/selectors';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';

/**
 * Function that defines the form for adding a new department
 * @prop {function} setAddDepartmentModalOpen - function to set the popup state
 * @returns {form}
 */
const AddDepartmentForm = ({ setAddDepartmentModalOpen }) => {
  const dispatch = useDispatch();
  //
  const designations = useSelector(selectAllDesignation);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddDepartmentsLoader);
  //
  const handleOnClose = () => {
    setAddDepartmentModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  return (
    <Grid item xs={12} md={6} lg mb={2}>
      <Formik
        initialValues={{
          name: '',
          designations: [],
        }}
        validationSchema={departmentFormValidation}
        onSubmit={(values) => {
          const dep = {
            name: values?.name,
            designations: values?.designations.map((designation) => designation?.id),
          };
          dispatch(departmentActions.addDepartment({ ...dep }));
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit} className="form">
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 2 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <Grid container>
              <Grid container item direction="column">
                <TextField
                  type="text"
                  name="name"
                  value={values?.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  my={2}
                  label="Department Name"
                />
                <Grid>
                  <MultiSelectAutoComplete
                    id="designations"
                    name="designations"
                    options={designations?.docs || []}
                    selectedOptions={values?.designations}
                    setSelectedOptions={setFieldValue}
                    label="Designations"
                    formikKey="designations"
                    placeholder="Select Designations"
                    error={Boolean(touched.designations && errors.designations)}
                    errorMsg={touched.designations && errors.designations}
                    onClose={() => setFieldTouched('designations', true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <ButtonGrid
              leftButtonText="Cancel"
              rightButtonText={loading ? 'Submitting' : 'Submit'}
              leftOnClick={handleOnClose}
              rightOnClick={null}
              rightIcon={loading ? loaderIcon : null}
              submitDisabled={loading}
            />
          </form>
        )}
      </Formik>
    </Grid>
  );
};
//
export default AddDepartmentForm;
