import { Typography } from '@mui/material';
/**
 * Defines the table header details for my allocation table in my profile section
 */
const MY_ALLOCATION_TABLE_HEADERS = [
  {
    field: 'projectName',
    headerName: 'Project name',
    flex: 0.75,
    renderCell: (params) => (
      <Typography
        sx={{
          maxWidth: '25ch',
          wordWrap: 'break-word',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.row.projectName}
      </Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.25,
    renderCell: (params) => (
      <Typography
        sx={{
          maxWidth: '35ch',
          wordWrap: 'break-word',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.row.description}
      </Typography>
    ),
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    flex: 0.5,
  },
  {
    field: 'endDate',
    headerName: 'End date',
    flex: 0.5,
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    flex: 0.5,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.allocation.value,
    renderCell: (params) => `${params.row.allocation.value} %`,
  },
];
//
export default MY_ALLOCATION_TABLE_HEADERS;
