import { Box, Grid } from '@mui/material';
//
import Button from '../button';
import './index.scss';
/**
 * A button grid component that consist of two buttons, usually a cancel and a submit button.
 * @param {String} leftButtonText Text on the left button
 * @param {String} rightButtonText Text on the right button
 * @param {String} leftOnClick Left button onClick function
 * @param {String} rightOnClick Right button onClick function
 * @param {String} leftIcon Left button icon
 * @param {String} rightIcon Right button icon
 * @param {Boolean} submitDisabled Specifies if the submit button is disabled
 * @param {String} buttonSpacing Spacing between the two buttons
 * @returns {Box}
 */
const ButtonGrid = ({
  leftButtonText,
  rightButtonText,
  leftOnClick,
  rightOnClick,
  leftIcon,
  rightIcon,
  submitDisabled,
  buttonSpacing,
  rightButtonType = 'submit',
}) => (
  <Box className="popup-box">
    <Grid
      container
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={buttonSpacing}
    >
      <Grid item xs={12} sm="auto" marginTop={2}>
        <Button
          fullWidth
          size="medium"
          sx={{
            width: {
              xs: '100%',
              sm: '130px',
            },
            textTransform: 'none',
          }}
          pattern="secondary"
          onClick={leftOnClick}
          icon={leftIcon}
        >
          {leftButtonText}
        </Button>
      </Grid>
      <Grid item xs={12} sm="auto" marginTop={2}>
        <Button
          type={rightButtonType}
          sx={{
            width: {
              xs: '100%',
              sm: '151px',
            },
            textTransform: 'none',
          }}
          size="medium"
          onClick={rightOnClick}
          icon={rightIcon}
          iconAlignment="right"
          disabled={submitDisabled}
        >
          {rightButtonText}
        </Button>
      </Grid>
    </Grid>
  </Box>
);
//
export default ButtonGrid;
