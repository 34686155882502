import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Container, Typography } from '@mui/material';
import { Download as DownloadIcon, Add as AddIcon } from '@mui/icons-material';
import { ShimmerTable } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
//
import { ENVIRONMENT } from 'config';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import { Autocomplete, DataGrid, SearchBar, Button, DatePicker } from 'features/base/components';
import { BENEFITS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { benefitsActions } from 'features/benefits/slice';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import { USER_LEVELS } from 'features/base/constants/user-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectDepartments,
  selectAllBenefits,
  selectLoader,
  selectDepartmentsLoader,
} from 'features/benefits/selectors';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { DATE_FORMAT_SLASH, ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { downloadCSVFile } from 'features/base/helpers/file';
import createFormattedString from 'features/base/helpers/param-formatter';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  ALL_BENEFITS_TABLE_COLUMNS,
  ALL_BENEFITS_TABLE_ACTION_COLUMNS,
} from 'features/base/utils/tables';
import EditBenefitsPopup from '../edit-benefit-modal';
import DeleteBenefitsPopup from '../delete-benefit-modal';
import SingleBenefitViewPopup from '../single-benefit-view-modal';

const AllBenefitsView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const benefitsUpdatePermissionVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.BENEFIT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const benefitsDeletePermissionVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.BENEFIT,
      action: PERMISSION_ACTIONS.DELETE,
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const departments = useSelector(selectDepartments);
  const benefits = useSelector(selectAllBenefits);
  const loading = useSelector(selectLoader);
  const departmentsLoading = useSelector(selectDepartmentsLoader);
  const notification = useSelector(selectNotification);
  //
  const [departmentFilter, setDepartmentFilter] = useState({ id: 'All', label: 'All' });
  const [dataStatusFilter, setDataStatusFilter] = useState({
    id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`,
    label: 'All',
  });
  const [levelFilter, setLevelFilter] = useState('All');
  const [searchData, setSearchData] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'title:asc',
    rowsPerPage: BENEFITS_PAGINATION_LIMIT,
  });
  const [benefitId, setBenefitId] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  //
  const isFirst = useIsInitialize(benefits);
  //
  const params = useMemo(
    () => ({
      aggregated: true,
      page: pageController.page,
      limit: pageController.rowsPerPage,
      sortBy: pageController.sortBy,
      departmentIds: departmentFilter.id,
      level: levelFilter,
      expiryDate,
      dataStatus: dataStatusFilter.id,
    }),
    [pageController, departmentFilter, dataStatusFilter, levelFilter]
  );
  //
  const handleEditModalOpen = (id) => {
    setEditModalOpen(true);
    setBenefitId(id);
  };
  //
  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(true);
    setBenefitId(id);
  };
  //
  const handleViewModalOpen = (id) => {
    setViewModalOpen(true);
    setBenefitId(id);
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleOnDepartmentChange = (e, autocompleteValue) => {
    setDepartmentFilter(autocompleteValue || { id: 'All', label: 'All' });
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleOnDataStatusChange = (e, autocompleteValue) => {
    setDataStatusFilter(
      autocompleteValue || {
        id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`,
        label: 'All',
      }
    );
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleOnLevelChange = (e, autocompleteValue) => {
    setLevelFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleOnExpiryDateChange = (e) => {
    setExpiryDate(moment(e?.$d).format(ISO_WITHOUT_TIME));
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleExport = () => {
    if (!benefits?.docs?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No benefits to export',
          type: ERROR_TYPES.INFO,
        })
      );
    }
    const data = benefits?.docs?.map((benefit) => ({
      Title: benefit?.title,
      Description: benefit?.description,
      Departments: benefit?.departments?.map((department) => department?.name).join(', '),
      'No of Users': benefit?.usersCount,
      'Employee Levels': benefit?.levels?.join(', '),
      'Created Date': moment(benefit?.createdAt).format(DATE_FORMAT_SLASH),
      'Expiry Date': benefit?.expiryDate
        ? moment(benefit?.expiryDate).format(DATE_FORMAT_SLASH)
        : '-',
      Link: benefit?.link || '-',
      Attachments: benefit?.attachments
        ?.map((attachment) => `${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${attachment?.url}`)
        .join(', '),
      Status: benefit?.dataStatus,
    }));
    const filename = `All_Benefits_Page_${pageController.page}.csv`;
    downloadCSVFile(Object.keys(data?.[0]), data, filename);
  };
  //
  const searchBenefits = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchBenefits, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  useEffect(() => {
    dispatch(benefitsActions.getDepartments());
  }, []);
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      benefitsActions.getBenefits({
        query: searchData.length
          ? `${formattedParamString}&search=${searchData}`
          : formattedParamString,
      })
    );
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  useEffect(
    () => () => {
      dispatch(benefitsActions.resetBenefits());
    },
    []
  );
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Grid
          item
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
          }}
          md={6}
          xs={12}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold !important' }}>
            Manage all benefits
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          columnSpacing={2}
          xs={12}
          sm={12}
          md={6}
          display={'flex'}
          justifyContent={{ md: 'flex-end' }}
        >
          <Box mt={1}>
            <Button
              sx={{
                width: { xs: '100%', md: '160px' },
                mb: { xs: '16px', md: '0px' },
                mr: { xs: 0, md: '24px' },
                whiteSpace: 'nowrap',
              }}
              onClick={handleExport}
            >
              <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
              Export{' '}
            </Button>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.BENEFIT,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              <Button
                onClick={() => {
                  navigate('/manage-benefits/create');
                }}
                sx={{
                  width: { xs: '100%', md: '160px' },
                  whiteSpace: 'nowrap',
                }}
              >
                Add Benefit <AddIcon />
              </Button>
            </PermissionWrapper>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        mt={{ xs: 1, sm: 1, md: 2, lg: 3 }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <Grid item xs={12} md={12} lg={3} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <Box
            sx={{
              height: '60px',
              width: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              mt: '10px',
            }}
          >
            <SearchBar
              placeholder="Search title"
              value={searchData}
              handleSearchInput={handleSearchInput}
              disabled={departmentsLoading || loading}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={2.25} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <Box sx={{ height: '60px', width: '100% !important' }}>
            <Autocomplete
              id="dataStatusFilter"
              name="dataStatusFilter"
              options={[
                { id: `${DATA_STATUS_TYPES.ACTIVE},${DATA_STATUS_TYPES.INACTIVE}`, label: 'All' },
                { id: DATA_STATUS_TYPES.ACTIVE, label: `${DATA_STATUS_TYPES.ACTIVE}` },
                { id: DATA_STATUS_TYPES.INACTIVE, label: `${DATA_STATUS_TYPES.INACTIVE}` },
              ]}
              label="Select status"
              controlled
              value={dataStatusFilter}
              onChange={handleOnDataStatusChange}
              getOptionLabel={(option) => capitalizeFirstLetter(option?.label)}
              disableClearable={dataStatusFilter.label === 'All'}
              disabled={departmentsLoading || loading}
            />
          </Box>
        </Grid>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.READ,
            },
          ]}
          hide
        >
          <Grid item xs={12} md={12} lg={2.25} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <Autocomplete
                id="departmentFilter"
                name="departmentFilter"
                options={[
                  { id: 'All', label: 'All' },
                  ...(departments.map((item) => ({ id: item?.id, label: item?.name })) ?? []),
                ]}
                label="Select department"
                controlled
                value={departmentFilter}
                onChange={handleOnDepartmentChange}
                disableClearable={departmentFilter === 'All'}
                disabled={departmentsLoading || loading}
              />
            </Box>
          </Grid>
        </PermissionWrapper>
        <Grid item xs={12} md={12} lg={2.25} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <Box sx={{ height: '60px', width: '100% !important' }}>
            <Autocomplete
              id="levelFilter"
              name="levelFilter"
              options={['All', ...Object.values(USER_LEVELS)]}
              label="Employee level"
              controlled
              value={levelFilter}
              onChange={handleOnLevelChange}
              disableClearable={levelFilter === 'All'}
              disabled={departmentsLoading || loading}
            />
          </Box>
        </Grid>
        <Grid item mb={{ xs: 2, sm: 2, md: 1, lg: 1 }} xs={12} lg={2.25} md={12}>
          <Box sx={{ height: '60px', width: '100% !important' }}>
            <Typography fontSize="16px" className="field-label">
              Expiry date
            </Typography>
            <DatePicker
              size="small"
              onChange={handleOnExpiryDateChange}
              value={expiryDate}
              placeholder={'Select date'}
              disabled={departmentsLoading || loading}
            />
          </Box>
        </Grid>
      </Grid>
      <EditBenefitsPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        benefitId={benefitId}
      />
      <DeleteBenefitsPopup
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        benefitId={benefitId}
      />
      <SingleBenefitViewPopup
        viewModalOpen={viewModalOpen}
        setViewModalOpen={setViewModalOpen}
        benefitId={benefitId}
        isMyBenefits={false}
      />
      <Grid container display="block">
        {isFirst ? (
          <ShimmerTable rows={10} cols={6} />
        ) : (
          <DataGrid
            columns={
              benefitsUpdatePermissionVerified || benefitsDeletePermissionVerified
                ? [...ALL_BENEFITS_TABLE_COLUMNS, ...ALL_BENEFITS_TABLE_ACTION_COLUMNS]
                : ALL_BENEFITS_TABLE_COLUMNS
            }
            rows={benefits?.docs?.map((benefit) => ({
              ...benefit,
              id: benefit?.id,
              title: {
                value: benefit?.title,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              departments: {
                value: benefit?.departments,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              noOfUsers: {
                value: benefit?.usersCount,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              empLevel: {
                value: benefit?.levels,
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              createdAt: {
                value: moment(benefit?.createdAt).format(DATE_FORMAT_SLASH),
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              expiryDate: {
                value: moment(benefit?.expiryDate).format(DATE_FORMAT_SLASH),
                onClick: (event) => {
                  event.stopPropagation();
                  handleViewModalOpen(benefit?.id);
                },
              },
              link: { value: benefit?.link },
              status: {
                checked: benefit?.dataStatus === DATA_STATUS_TYPES.ACTIVE,
                onChange: () => {
                  dispatch(
                    benefitsActions.editBenefits({
                      id: benefit?.id,
                      dataStatus:
                        benefit?.dataStatus === DATA_STATUS_TYPES.ACTIVE
                          ? DATA_STATUS_TYPES.INACTIVE
                          : DATA_STATUS_TYPES.ACTIVE,
                    })
                  );
                },
                isLoading: loading,
              },
              action: {
                benefitsUpdatePermissionVerified,
                benefitsDeletePermissionVerified,
                isLoading: loading,
                handleBenefitEdit: () => handleEditModalOpen(benefit?.id),
                handleBenefitDelete: () => handleDeleteModalOpen(benefit?.id),
              },
            }))}
            totalPages={benefits?.totalPages ?? 0}
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalDocs={benefits?.totalDocs ?? 0}
            limit={pageController.rowsPerPage ?? 0}
            loading={loading}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={benefits?.docs?.length > 0 ? 52 : 200}
            clickable
          />
        )}
      </Grid>
    </Container>
  );
};

export default AllBenefitsView;
