import { DriveFileRenameOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
//
import { ENVIRONMENT } from 'config';
import COLORS from 'features/base/constants/colors';

/**
 * Project documents table
 */
export const PROJECT_DOCUMENTS_TABLE = [
  {
    field: 'documentFile',
    headerName: 'Document',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    renderCell: (params) => {
      const { name, url } = params.row.documentFile;
      return (
        <a
          href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${url}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: COLORS.ELECTRIC_BLUE }}
        >
          {name}
        </a>
      );
    },
  },
  {
    field: 'documentLink',
    headerName: 'Link',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    renderCell: (params) => {
      const { name, url } = params.row.documentLink;
      return (
        <a href={url} target="_blank" rel="noreferrer" style={{ color: COLORS.ELECTRIC_BLUE }}>
          {name}
        </a>
      );
    },
  },
  {
    field: 'uploadedDate',
    headerName: 'Uploaded date',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded by',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    headerClassName: 'super-app-theme--header',
    flex: 1,
  },
];
/**
 * Project document table - action columns
 */
export const PROJECT_DOCUMENTS_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
    sortable: false,
    renderCell: ({ value: { handleClick } }) => (
      <IconButton onClick={handleClick}>
        <DriveFileRenameOutline />
      </IconButton>
    ),
  },
];
