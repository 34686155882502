import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/links'] || initialState;
/**
 * Selector for getting loading state
 */
export const selectLoader = createSelector([selectDomain], (linkState) => linkState.loading);
/**
 * Selector for getting file upload loading state
 */
export const selectFileUploadLoader = createSelector(
  [selectDomain],
  (linkState) => linkState.fileUploadLoading
);
/**
 * Selector for getting the uploaded logo
 */
export const selectUploadedLogo = createSelector(
  [selectDomain],
  (linkState) => linkState.uploadedLogo
);
/**
 * Function to retrieve all departments
 */
export const selectAllDepartment = createSelector(
  [selectDomain],
  (linkState) => linkState.allDepartmentList
);
/**
 * Function to retrieve all links from the link slice
 */
export const selectAllLinks = createSelector([selectDomain], (linkState) => linkState.linkList);
/**
 * Selector for getting a link by id
 */
export const selectLinkById = createSelector(
  [selectAllLinks, (_, linkId) => linkId],
  (linkList, linkId) => linkList.docs?.find((link) => link.id === linkId) || null
);
/**
 * Function to retrieve all departments
 */
export const selectAllLinksById = createSelector([selectDomain], (linkState) => linkState.allLink);
/**
 * Selector for getting loading state
 */
export const selectAddLinkLoader = createSelector(
  [selectDomain],
  (linkState) => linkState.addLinkLoading
);
/**
 * Selector for getting loading state
 */
export const selectEditLinkLoader = createSelector(
  [selectDomain],
  (linkState) => linkState.editLinkLoading
);
