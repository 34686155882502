import { Avatar, Box, Button, Grid, IconButton, Switch, Typography, Tooltip } from '@mui/material';
import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import COLORS from 'features/base/constants/colors';
import { getYearMonthHourMinuteFormat } from 'features/base/helpers/date';
import './index.scss';
/**
 * View client users table columns - basic details
 */
export const CLIENT_USERS_TABLE_COLUMNS_BASIC = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 350,
    valueGetter: (params) => params.row.profile.name,
    renderCell: (params) => {
      const { logo, name } = params.row.profile;
      return (
        <Grid container alignItems="center">
          <Grid item sm={3}>
            {logo ? (
              <img
                src={logo}
                alt="logo"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  height: '40px',
                  width: '40px',
                }}
              >
                {name?.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={name}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                style={{ wordWrap: 'break-word' }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    valueGetter: (params) => params.row.email.value,
    renderCell: (params) => {
      const { value } = params.row.email;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            style={{ wordWrap: 'break-word' }}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 250,
    valueGetter: (params) => params.row.phone.value,
    renderCell: (params) => {
      const { value } = params.row.phone;
      return <Typography variant="body1">{value}</Typography>;
    },
  },
  {
    field: 'onboardingStatus',
    headerName: 'Onboarding status',
    width: 180,
    valueGetter: (params) => params.row.onboardingStatus.value,
    renderCell: (params) => {
      const { value, bgColour, textColour } = params.row.onboardingStatus;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: bgColour,
              p: '7px 16px',
              mr: '16px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: textColour,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];
/**
 * View client users table columns - action columns
 */
export const CLIENT_USERS_TABLE_ACTION_COLUMNS = [
  {
    field: 'makePrimary',
    headerName: 'Make primary',
    headerClassName: 'super-app-theme--header',
    width: 150,
    sortable: false,
    renderCell: ({ value: { checked, handleClick, loading } }) => (
      <Switch
        checked={checked}
        onChange={handleClick}
        color="success"
        readOnly={loading}
        disabled={checked}
      />
    ),
  },
  {
    field: 'invitation',
    headerName: 'Invitation',
    headerClassName: 'super-app-theme--header',
    width: 150,
    sortable: false,
    renderCell: ({
      value: { bgColour, textColour, value, handleClick, loading, invitationTimeStamp },
    }) => (
      <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
        {invitationTimeStamp ? (
          <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
            <Box
              className="invite-box"
              sx={{
                bgcolor: bgColour,
              }}
            >
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: textColour,
                    }}
                  >
                    {value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '0.60rem',
                      color: textColour,
                    }}
                  >
                    {getYearMonthHourMinuteFormat(invitationTimeStamp)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Button
            onClick={handleClick}
            disabled={loading}
            className="invite-button"
            sx={{
              bgcolor: bgColour,
              '&:hover': {
                bgcolor: COLORS.CELESTE_BLUE,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: textColour,
              }}
            >
              {value}
            </Typography>
          </Button>
        )}
      </Box>
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerClassName: 'super-app-theme--header',
    width: 100,
    sortable: false,
    renderCell: ({ value: { handleClick, handleDelete, isLoading } }) => (
      <Box>
        <IconButton onClick={handleClick}>
          <DriveFileRenameOutline />
        </IconButton>
        <IconButton
          sx={{ '&:hover': { color: 'red' } }}
          onClick={handleDelete}
          readOnly={isLoading}
        >
          <DeleteForever />
        </IconButton>
      </Box>
    ),
  },
];
