/**
 * Defines the date formatting types used in the application
 * @default { LANGUAGE: 'en-UK' }
 */
export const DATE_LANGUAGE = {
  LANGUAGE: 'en-UK',
};
/**
 * Defines the date format displayed on the dashboards
 * @default { day: 'numeric', month: 'long', year: 'numeric' }
 * @example const date = new Date().toLocaleString(DATE_LANGUAGE.LANGUAGE, DATE_FORMAT);
 * console.log(date); // 1 January 2021
 */
export const DATE_FORMAT = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};
/**
 * Defines the date format displayed on the projects dashboard
 */
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY';
/**
 * ISO date format without time fragment YYYY-MM-DD
 */
export const ISO_WITHOUT_TIME = 'YYYY-MM-DD';
/**
 * Financial year start month and day MM-DD (Sri Lanka)
 * @default '04-01'
 */
export const FINANCIAL_START_MONTH_DAY = '04-01';
/**
 * Financial year end month and day MM-DD (Sri Lanka)
 * @default '03-31'
 */
export const FINANCIAL_END_MONTH_DAY = '03-31';
/**
 * Months
 */
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
