import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
//
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { selectSelectedTeamMembers } from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';

/**
 * Function that handles setting the start date for allocations
 */
const useSetStartDate = () => {
  const dispatch = useDispatch();
  //
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  //
  const setStartDate = (userId, date) => {
    const selectedUsersCopy = selectedUsers?.docs?.map((user) => {
      if (user?.userId?.id === userId) {
        return {
          ...user,
          startDate: moment(date).format(ISO_WITHOUT_TIME),
        };
      }
      return user;
    });
    dispatch(projectActions.setCreateTeamSelectedUsers(selectedUsersCopy));
  };
  //
  return { setStartDate };
};
//
export default useSetStartDate;
