import { Grid, Box, Typography } from '@mui/material';
import searchEmptyBox from 'features/base/assets/images/png/search-empty-box.png';
/**
 * This function is designed to display a custom message when there are no results in the data grid
 * Returns custom component when there are no results found
 */
const CustomNoResultsOverlay = () => (
  <Grid
    container
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    }}
    direction="column"
  >
    <Grid item>
      <Box sx={{ display: 'flex' }}>
        <img src={searchEmptyBox} alt="empty box" />
      </Box>
    </Grid>
    <Grid item>
      <Typography sx={{ fontWeight: 900, fontSize: 25 }}>No results found!</Typography>
    </Grid>
  </Grid>
);
//
export default CustomNoResultsOverlay;
