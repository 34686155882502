/**
 * Available project billing types for Client
 * @type {{RETAINER: string, FIXED_BID: string, SUPPORT_AND_MAINTENANCE: string, STAFF_AUGMENTATION: string, TIME_AND_MATERIAL: string}}
 */
export const PROJECT_BILLING_TYPES = {
  RETAINER: 'FIXED_MONTHLY_RETAINER',
  FIXED_BID: 'FIXED_BID',
  SUPPORT_AND_MAINTENANCE: 'FIXED_MONTHLY_SUPPORT_AND_MAINTENANCE',
  STAFF_AUGMENTATION: 'FIXED_MONTHLY_STAFF_AUGMENTATION',
  TIME_AND_MATERIAL: 'TIME_AND_MATERIAL',
};
/**
 * Array for project billing types
 */
export const PROJECT_BILLING_TYPE_ARRAY = [
  { key: 1, value: PROJECT_BILLING_TYPES.RETAINER },
  { key: 2, value: PROJECT_BILLING_TYPES.FIXED_BID },
  { key: 3, value: PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE },
  { key: 4, value: PROJECT_BILLING_TYPES.STAFF_AUGMENTATION },
  { key: 5, value: PROJECT_BILLING_TYPES.TIME_AND_MATERIAL },
];
