import { memo } from 'react';
//
import useAuthorization from 'features/base/hooks/use-authorization';
import { getRefreshToken } from 'features/base/helpers/token';
/**
 * A wrapper that checks whether a user is authenticated by checking the users tokens
 * @param {ReactNode} children
 */
const AuthWrapper = memo(({ children }) => {
  const { isAuthenticated } = useAuthorization();
  //
  const refreshToken = getRefreshToken();
  //
  return isAuthenticated(refreshToken) && children;
});
//
export default AuthWrapper;
