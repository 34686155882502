import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';
import { DeleteForever, FileDownload } from '@mui/icons-material';
import { Formik, Form } from 'formik';
//
import { Autocomplete, TextField, Button, DragAndDropZone } from 'features/base/components';
import {
  BENEFITS_PROMO_CODES_ALLOWED_EXTENSIONS,
  BENEFITS_PROMO_CODES_ALLOWED_FILE_TYPES,
  BENEFITS_ATTACHMENTS_MAX_SIZE,
} from 'features/base/constants/file-upload';
import {
  BENEFIT_PROMOTION_TYPES,
  BENEFIT_PROMOTION_CODE_TYPES,
  BENEFIT_CAMPAIGN_TYPES,
} from 'features/base/constants/benefit-types';
import benefitPromoValidation from 'features/benefits/validations/benefit-promo-validation';

const AddPromotionDetailsStep = ({
  stepProgress,
  setStepProgress,
  setActiveStep,
  formResults,
  setFormResults,
}) => {
  const [resourceFiles, setResourceFiles] = useState([]);
  //
  const csvContent = ['SDFSDF', 'SDFSDF', 'GHJGHJG', 'JKRTJHTED', 'ERGHH'].join('\n');

  const handleDownload = () => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `promos${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  //
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '70%', lg: '40%' },
        margin: 'auto',
      }}
    >
      <Formik
        initialValues={{
          promotionType: BENEFIT_PROMOTION_TYPES.PROMO_CODE,
          promotionCodeType: BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE,
          resources: [],
          resource: '',
        }}
        validationSchema={benefitPromoValidation}
        onSubmit={(values) => {
          const data = {
            ...values,
          };
          if (values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME) {
            delete data.resource;
          } else if (values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE) {
            delete data.resources;
          }
          //
          setStepProgress(
            stepProgress.map((step) => {
              if (step.step === 1) {
                return { ...step, completed: true, disabled: false };
              }
              return step;
            })
          );
          //
          setFormResults(
            formResults.map((result) => {
              if (result.step === 1) {
                return { ...result, data };
              }
              return result;
            })
          );

          setActiveStep(2);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormLabel id="promotionType">Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="promotionType"
              name="promotionType"
              sx={{ pb: 1 }}
              value={values.promotionType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={BENEFIT_PROMOTION_TYPES.PROMO_CODE}
                control={<Radio />}
                label="Promo Code"
              />
              <FormControlLabel
                value={BENEFIT_PROMOTION_TYPES.E_VOUCHER}
                control={<Radio />}
                disabled
                label="E-Voucher"
              />
            </RadioGroup>
            <Autocomplete
              id="promotionCodeType"
              name="promotionCodeType"
              options={(() => {
                if (
                  formResults[0]?.data?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN
                ) {
                  return Object.values(BENEFIT_PROMOTION_CODE_TYPES)
                    .map((type) => ({
                      id: type,
                      label: type,
                    }))
                    .filter((type) => type.id !== BENEFIT_PROMOTION_CODE_TYPES.NOT_APPLICABLE);
                }
                return Object.values(BENEFIT_PROMOTION_CODE_TYPES).map((type) => ({
                  id: type,
                  label: type,
                }));
              })()}
              label="Promotion Code Type"
              onChange={(_, newValue) => {
                if (newValue.id === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE) {
                  setResourceFiles([]);
                  setFieldValue('resources', []);
                } else if (newValue.id === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME) {
                  setFieldValue('resource', '');
                }
                setFieldValue('promotionCodeType', newValue.id);
              }}
              controlled
              multiple={false}
              Autocomplete
              disableClearable
              value={values?.promotionCodeType}
              error={Boolean(touched.promotionCodeType && errors.promotionCodeType)}
              errorMsg={touched?.promotionCodeType && errors?.promotionCodeType}
              disabledMsg="Please select a promotion type"
              onClose={() => setFieldTouched('promotionCodeType', true)}
              sx={{
                pb: 1,
                width: '15rem',
              }}
            />

            {values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE && (
              <TextField
                id="resource"
                name="resource"
                label="Promo Code"
                value={values.resource}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.resource && errors.resource)}
                helperText={touched?.resource && errors?.resource}
                disabled={values.promotionCodeType !== BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE}
                sx={{
                  pb: 1,
                  width: '15rem',
                }}
              />
            )}

            {values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pb: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    sx={{ width: '15rem', mb: 1 }}
                  >
                    Download Sample
                  </Button>
                  <IconButton onClick={handleDownload} sx={{ mb: 1 }}>
                    <FileDownload />
                  </IconButton>
                </Box>
                <DragAndDropZone
                  label="Bulk Upload Promo Codes"
                  singleFile
                  allowedExtensions={BENEFITS_PROMO_CODES_ALLOWED_EXTENSIONS}
                  allowedMimetypes={BENEFITS_PROMO_CODES_ALLOWED_FILE_TYPES}
                  maxSize={BENEFITS_ATTACHMENTS_MAX_SIZE}
                  onAdd={(files) => {
                    setResourceFiles([...resourceFiles, ...files]);
                    if (files.length) {
                      files[0].text().then((text) => {
                        setFieldValue(
                          'resources',
                          text.split('\n').filter((resource) => resource)
                        );
                        setFieldTouched('resources', true);
                      });
                    }
                  }}
                  onDelete={(file) => {
                    setResourceFiles(resourceFiles.filter((resource) => resource !== file));
                  }}
                  uploadedFiles={resourceFiles}
                />
              </>
            )}

            {Boolean(
              touched.resources &&
                errors.resources &&
                values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME
            ) && (
              <Typography color="error" variant="body2">
                {errors.resources}
              </Typography>
            )}

            {values.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME &&
              values.resources.length > 0 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    {'Loaded Promo Codes from the file'}
                  </Typography>
                  <Stack spacing={2}>
                    {values.resources.map((resource, index) => (
                      <Box sx={{ width: '15rem', display: 'flex', alignItems: 'center' }}>
                        <Chip
                          label={resource}
                          variant="outlined"
                          sx={{ flex: 1, marginRight: '0.5rem' }}
                          color="primary"
                        />
                        <IconButton
                          onClick={() => {
                            const newResources = [...values.resources];
                            newResources.splice(index, 1);
                            setFieldValue('resources', newResources);
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}

            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              mt={3}
            >
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  fullWidth
                  size="medium"
                  type="submit"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '15rempx',
                    },
                  }}
                  pattern="primary"
                >
                  Save & Continue
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  type="button"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '15rem',
                    },
                  }}
                  size="medium"
                  pattern="secondary"
                  disabled={
                    formResults[0]?.data?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN
                  }
                  onClick={() => {
                    setStepProgress(
                      stepProgress.map((step) => {
                        if (step.step === 1) {
                          return { ...step, completed: true };
                        }
                        return step;
                      })
                    );
                    //
                    setFormResults(
                      formResults.map((result) => {
                        if (result.step === 1) {
                          return {
                            ...result,
                            data: {
                              promotionType: BENEFIT_PROMOTION_TYPES.NOT_APPLICABLE,
                              promotionCodeType: BENEFIT_PROMOTION_CODE_TYPES.NOT_APPLICABLE,
                              resources: [],
                              resource: '',
                            },
                          };
                        }
                        return result;
                      })
                    );
                    //
                    setActiveStep(2);
                    // clear the form
                    setFieldValue('promotionType', BENEFIT_PROMOTION_TYPES.PROMO_CODE);
                    setFieldValue('promotionCodeType', BENEFIT_PROMOTION_CODE_TYPES.NOT_APPLICABLE);
                    setFieldValue('resources', []);
                    setFieldValue('resource', '');
                  }}
                >
                  Skip
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Box sx={{ height: 50 }} />
    </Box>
  );
};

export default AddPromotionDetailsStep;
