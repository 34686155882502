/* eslint-disable import/prefer-default-export */
import { isEqual } from 'lodash';
/**
 * Take in two object arrays and remove the items in the second array which are unchanged from the first array
 * @param {Array} initialArray - The initial list
 * @param {Array} updatedArray - The updated list
 * @returns {Object}
 */
export const removeUnchangedArray = (initialArray, updatedArray) =>
  updatedArray?.filter(
    (secondObj) =>
      // isEqual is used to implement expected functionality for objects as well
      !initialArray?.find((firstObj) => isEqual(firstObj, secondObj))
  );
/**
 * Function that takes in two object arrays and a key and returns the 
 * items in array2 that are not present in array1, based on the key
 * @param {Array<Object>} array1 
 * @param {Array<Object>} array2 
 * @param {String} key 
 * @returns {Array}
 */
export const getDisconnectionSet = (array1, array2, key) => {
  // Additional processing to work with nested keys
  const getNestedValue = (obj) => key?.split('.')?.reduce((acc, k) => acc[k], obj);
  // Create an object to store 'key' values from array1 using forEach
  const idMap = {};
  array1?.forEach((item) => {
    const formattedKey = getNestedValue(item)
    idMap[formattedKey] = true;
  });
  // Use filter to get objects from array2 with unique 'key' values
  return array2?.filter((item) => {
    const formattedKey = getNestedValue(item)
    return !idMap?.[formattedKey];
  });
};
/**
 * Function that converts a list of string to a comma separated string
 * @param {List<String>} arr - List of strings
 * @returns {String}
 * @example [1,2,3] => '1,2,3'
 */
export const getCommaSeparatedString = (arr) =>
  arr?.reduce((acc, currentItem) => `${acc}, ${currentItem}`);
