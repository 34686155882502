import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { PROJECT_DOCUMENT_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { selectIsProjectFetching, selectProject } from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { DataGrid } from 'features/base/components';
import {
  PROJECT_DOCUMENTS_TABLE,
  PROJECT_DOCUMENTS_TABLE_ACTION_COLUMNS,
} from 'features/base/utils/tables/documents-table';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import EditDocumentsModal from '../edit-documents-modal';

/**
 * Table component to render the documents of a project
 * @returns {Table}
 */
const DocumentsTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const projectEditPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const { projectId } = useParams();
  //
  const loading = useSelector(selectIsProjectFetching);
  const project = useSelector(selectProject);
  //
  const isFirst = useIsInitialize(project);
  //
  const [selectedDocument, setSelectedDocument] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  //
  const handleEditModalOpen = (userId) => {
    setSelectedDocument(userId);
    setEditModalOpen(true);
  };
  //
  useEffect(() => {
    dispatch(projectActions.getClient({ projectId }));
  }, []);
  //
  return (
    <Box>
      <EditDocumentsModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        selectedDocument={selectedDocument}
      />
      {isFirst ? (
        <ShimmerTable row={3} col={5} />
      ) : (
        <DataGrid
          columns={
            projectEditPermissionsVerified
              ? [...PROJECT_DOCUMENTS_TABLE, ...PROJECT_DOCUMENTS_TABLE_ACTION_COLUMNS]
              : PROJECT_DOCUMENTS_TABLE
          }
          rows={
            project?.documents?.map((document) => ({
              id: document?.id,
              documentFile: {
                name: document?.external ? null : document?.name,
                url: document?.url,
              },
              documentLink: { name: document?.external ? document?.url : null, url: document?.url },
              uploadedDate: moment(document?.createdAt).format(DATE_FORMAT_SLASH),
              uploadedBy: `${document?.uploadedBy?.firstName} ${document?.uploadedBy?.lastName}`,
              tags: document?.tags?.join(', '),
              action: {
                handleClick: () => {
                  handleEditModalOpen(document);
                },
              },
            })) ?? []
          }
          loading={loading && isFirst !== true}
          disableRowSelectionOnClick
          limit={PROJECT_DOCUMENT_PAGINATION_LIMIT}
          pageSizeOptions={[PROJECT_DOCUMENT_PAGINATION_LIMIT]}
          serverSidePagination={false}
          rowHeight={project?.documents?.length ? 52 : 200}
        />
      )}
    </Box>
  );
};
//
export default DocumentsTable;
