import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Container } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
//
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import { Autocomplete, DataGrid, SearchBar } from 'features/base/components';
import { USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { userActions } from 'features/users/slice';
import { skillActions } from 'features/skills/slice';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import { USER_TYPES } from 'features/base/constants/user-types';
import ROUTES from 'features/base/constants/routes';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectDepartments,
  selectDesignations,
  selectLoader,
  selectRoles,
  selectUserList,
} from 'features/users/selectors';
import { selectSkills, selectSkillGroups } from 'features/skills/selectors';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { USERS_TABLE_COLUMNS_BASIC, USERS_TABLE_STATUS_COLUMN } from 'features/base/utils/tables';
import USERS_TABLE_ROLE_COLUMN from 'features/base/utils/tables/user-table/user-role-table';
import USERS_TABLE_SALARY_COLUMN from 'features/base/utils/tables/user-table/user-salary-table';
import createFormattedString from 'features/base/helpers/param-formatter';
import AddSalaryPopup from '../add-salary';
import AddUsersPopup from '../add-users-modal';
import ButtonSet from '../button-set';
import EditPermissionsPopup from '../edit-role-permission-modal';
import ViewSalaryHistoryPopup from '../view-salary-history-modal';
import BulkUserDataProcessPopup from '../bulk-user-data-upload-modal';
import './index.scss';

/**
 * Entire user function view
 */
const UserView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const userUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const userRoleViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER_PERMISSION,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const userRoleUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER_PERMISSION,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const salaryViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SALARY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const salaryUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SALARY,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const users = useSelector(selectUserList);
  const loading = useSelector(selectLoader);
  const allDesignations = useSelector(selectDesignations);
  const allDepartments = useSelector(selectDepartments);
  const allRoles = useSelector(selectRoles);
  const allSkills = useSelector(selectSkills);
  const allSkillGroups = useSelector(selectSkillGroups);
  const notification = useSelector(selectNotification);
  //
  const isFirst = useIsInitialize(users);
  //
  const [searchData, setSearchData] = useState('');
  const [userRoleFilter, setUserRoleFilter] = useState('All');
  const [userTypeFilter, setUserTypeFilter] = useState('All');
  const [departmentFilter, setDeparmentFilter] = useState('All');
  const [designationFilter, setDesignationFilter] = useState('All');
  const [skillFilter, setSkillFilter] = useState('All');
  const [skillGroupFilter, setSkillGroupFilter] = useState('');
  const [dataStatusFilter, setDataStatusFilter] = useState('All');
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy:
      'firstName:asc ' /* As for now no sorting field is defined in the view, so added the sortBy key with a default value for future usage. */,
    rowsPerPage: USERS_PAGINATION_LIMIT,
  });
  const [selectedUser, setSelectedUser] = useState();
  const [editPermissionsModalOpen, setEditPermissionsModalOpen] = useState(false);
  const [viewSalaryModalOpen, setViewSalaryModalOpen] = useState(false);
  const [salaryHistoryUser, setSalaryHistoryUser] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addSalaryModalOpen, setAddSalaryModalOpen] = useState(false);
  const [bulkUserDataProcessModalOpen, setBulkUserDataProcessModalOpen] = useState(false);
  //
  const params = useMemo(
    () => ({
      role: userRoleFilter,
      type: userTypeFilter,
      designation: designationFilter,
      department: departmentFilter,
      dataStatus: dataStatusFilter,
      skillGroups: skillGroupFilter,
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
    }),
    [
      userRoleFilter,
      userTypeFilter,
      designationFilter,
      departmentFilter,
      skillFilter,
      dataStatusFilter,
      skillGroupFilter,
      pageController,
    ]
  );
  //
  const getColumns = () => {
    const columns = [
      ...USERS_TABLE_COLUMNS_BASIC,
      userUpdatePermissionsVerified ? USERS_TABLE_STATUS_COLUMN : null,
      userRoleViewPermissionsVerified ? USERS_TABLE_ROLE_COLUMN : null,
      salaryViewPermissionsVerified ? USERS_TABLE_SALARY_COLUMN : null,
    ];
    // Remove nulls and return
    return columns.filter((column) => column);
  };
  //
  const handleRoleOnChange = (e, autocompleteValue) => {
    setUserRoleFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleTypeOnChange = (e, autocompleteValue) => {
    setUserTypeFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleDesignationOnChange = (e, autocompleteValue) => {
    setDesignationFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleDepartmentOnChange = (e, autocompleteValue) => {
    setDeparmentFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleSkillOnChange = (e, autocompleteValue) => {
    if (autocompleteValue !== 'All' && autocompleteValue !== null) {
      const selectedSkill = allSkills?.docs?.find((skill) => skill.name === autocompleteValue);
      const skillGroupsWithSelectedSkill = allSkillGroups?.docs?.filter((skillGroup) =>
        skillGroup.skills?.find((skill) => skill.id === selectedSkill?.id)
      );
      setDataStatusFilter(DATA_STATUS_TYPES.ACTIVE);
      setSkillGroupFilter(skillGroupsWithSelectedSkill?.map((skillGroup) => skillGroup.id));
      setSkillFilter(autocompleteValue);
    } else {
      setDataStatusFilter('All');
      setSkillGroupFilter('');
      setSkillFilter('All');
    }
    setPageController({ ...pageController, page: 1 });
  };
  const handleOnStatusChange = (e, autocompleteValue) => {
    setDataStatusFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const navigateToProfile = (user) => {
    if (user.type === USER_TYPES.CLIENT) {
      navigate(
        ROUTES.PROFILE.replace(':id', user.id).replace('/*', `/${ROUTES.CLIENT_PROFILE_USERS}`)
      );
    } else {
      navigate(ROUTES.PROFILE.replace(':id', user.id).replace('/*', `/${ROUTES.ALLOCATIONS}`));
    }
  };
  //
  const handleUpdatePermissionsOnClick = (user) => {
    if (user?.onboarding) {
      setSelectedUser(user);
      dispatch(userActions.getSelectedUserPermissions({ userId: user.id }));
      setEditPermissionsModalOpen(true);
    } else {
      toast(
        'This user must be logged in to the system in order to update the respective permissions for the user',
        { type: TOAST_TYPES.INFO }
      );
    }
  };
  //
  const handleViewSalaryHistoryOnClick = (user) => {
    setSalaryHistoryUser(user);
    setViewSalaryModalOpen(true);
    dispatch(userActions.getSalaryHistory({ userId: user.id }));
  };
  //
  const searchUsers = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchUsers, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  useEffect(() => {
    dispatch(userActions.getDesignations({ query: 'pagination=false' }));
    dispatch(userActions.getDepartments());
    dispatch(userActions.getWorkAreas({ query: 'pagination=false' }));
    dispatch(userActions.getRoles());
    dispatch(skillActions.getSkills({ query: 'pagination=false' }));
    dispatch(skillActions.getSkillGroups({ query: 'pagination=false' }));
  }, []);
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      userActions.getUsers({
        query: searchData.length
          ? `${formattedParamString}&search=${searchData}`
          : formattedParamString,
      })
    );
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <ButtonSet
        setAddModalOpen={setAddModalOpen}
        setAddSalaryModalOpen={setAddSalaryModalOpen}
        setBulkUserDataProcessModalOpen={setBulkUserDataProcessModalOpen}
      />
      <AddUsersPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <AddSalaryPopup
        addSalaryModalOpen={addSalaryModalOpen}
        setAddSalaryModalOpen={setAddSalaryModalOpen}
      />
      <BulkUserDataProcessPopup
        bulkUserDataProcessModalOpen={bulkUserDataProcessModalOpen}
        setBulkUserDataProcessModalOpen={setBulkUserDataProcessModalOpen}
      />
      <ViewSalaryHistoryPopup
        viewSalaryModalOpen={viewSalaryModalOpen}
        setViewSalaryModalOpen={setViewSalaryModalOpen}
        salaryHistoryUser={salaryHistoryUser}
      />
      <EditPermissionsPopup
        user={selectedUser}
        editPermissionsModalOpen={editPermissionsModalOpen}
        setEditPermissionsModalOpen={setEditPermissionsModalOpen}
      />
      <Grid container alignItems="center">
        <Grid
          container
          spacing={1}
          mt={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '40px',
          }}
        >
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.ROLE,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
            hide
          >
            <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <Autocomplete
                  id="roleFilter"
                  name="roleFilter"
                  options={['All', ...(allRoles?.map((role) => role.name) || 'No options')]}
                  label="Select role"
                  controlled
                  value={userRoleFilter}
                  onChange={handleRoleOnChange}
                  getOptionLabel={(option) => formattedString(capitalizeFirstLetter(option))}
                  disableClearable={userRoleFilter === 'All'}
                />
              </Box>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <Autocomplete
                id="typeFilter"
                name="typeFilter"
                options={['All', ...Object.values(USER_TYPES)]}
                label="Select type"
                controlled
                value={userTypeFilter}
                onChange={handleTypeOnChange}
                getOptionLabel={(option) => capitalizeFirstLetter(option)}
                disableClearable={userTypeFilter === 'All'}
              />
            </Box>
          </Grid>
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.ORGANIZATION,
                action: PERMISSION_ACTIONS.READ,
              },
            ]}
            hide
          >
            <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <Autocomplete
                  id="designationFilter"
                  name="designationFilter"
                  options={[
                    'All',
                    ...(allDesignations?.docs?.map((designation) => designation.name) ||
                      'No options'),
                  ]}
                  label="Select designation"
                  controlled
                  value={designationFilter}
                  onChange={handleDesignationOnChange}
                  disableClearable={designationFilter === 'All'}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <Autocomplete
                  id="departmentFilter"
                  name="departmentFilter"
                  options={[
                    'All',
                    ...(allDepartments?.map((department) => department.name) || 'No options'),
                  ]}
                  label="Select department"
                  controlled
                  value={departmentFilter}
                  onChange={handleDepartmentOnChange}
                  disableClearable={departmentFilter === 'All'}
                />
              </Box>
            </Grid>
          </PermissionWrapper>
          <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <Autocomplete
                id="skillFilter"
                name="skillFilter"
                options={['All', ...(allSkills?.docs?.map((skill) => skill?.name) || 'No options')]}
                label="Select skill"
                controlled
                value={skillFilter}
                onChange={handleSkillOnChange}
                getOptionLabel={(option) => capitalizeFirstLetter(option)}
                disableClearable={skillFilter === 'All'}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <Autocomplete
                id="dataStatusFilter"
                name="dataStatusFilter"
                options={['All', DATA_STATUS_TYPES.ACTIVE, DATA_STATUS_TYPES.INACTIVE]}
                label="Select status"
                controlled
                value={dataStatusFilter}
                onChange={handleOnStatusChange}
                getOptionLabel={(option) => capitalizeFirstLetter(option)}
                disableClearable={dataStatusFilter === 'All'}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2.35} mb={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <Box
              sx={{
                height: '60px',
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                mt: '10px',
              }}
            >
              <SearchBar
                placeholder="Search username or email"
                value={searchData}
                handleSearchInput={handleSearchInput}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container display="block">
          {isFirst ? (
            <ShimmerTable rows={10} cols={6} />
          ) : (
            <DataGrid
              onRowClick={(data) => navigateToProfile(data.row)}
              rows={users?.docs?.map((user) => ({
                ...user,
                id: user?.id,
                profile: {
                  value: `${user?.firstName} ${user?.lastName}`,
                  departmentAndDesignation: `${
                    user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId
                      ?.name || '-'
                  }
                  ,
                  ${
                    user?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId
                      ?.name || '-'
                  }`,
                  profileImage: user?.profileImage,
                },
                email: {
                  value: user?.email || '-',
                },
                workArea: {
                  value: user?.workAreaId?.name || '-',
                },
                role: {
                  value: `${user?.roleId?.name ? formattedString(user.roleId.name) : '-'}`,
                  userRoleViewPermissionsVerified,
                  userRoleUpdatePermissionsVerified,
                  handleUpdatePermissionsOnClick: (event) => {
                    event.stopPropagation();
                    handleUpdatePermissionsOnClick(user);
                  },
                },
                capacity: { value: user?.type !== USER_TYPES.CLIENT ? user?.capacity : '-' },
                salary: {
                  value: getDollarFormattedValue(user?.currentSalary?.amount) || '-',
                  salaryViewPermissionsVerified,
                  salaryUpdatePermissionsVerified,
                  handleViewSalaryHistoryOnClick: (event) => {
                    event.stopPropagation();
                    handleViewSalaryHistoryOnClick(user);
                  },
                },
                dataStatus: {
                  onChange: (event) => {
                    event.stopPropagation();
                    dispatch(
                      userActions.patchUser({
                        userId: user.id,
                        dataStatus:
                          user.dataStatus === DATA_STATUS_TYPES.ACTIVE
                            ? DATA_STATUS_TYPES.INACTIVE
                            : DATA_STATUS_TYPES.ACTIVE,
                      })
                    );
                  },
                  checked: user.dataStatus === DATA_STATUS_TYPES.ACTIVE,
                  loading,
                },
                onboardingStatus: {
                  value: user?.onboarding,
                },
              }))}
              columns={getColumns()}
              totalPages={users.totalPages ?? 0}
              handleChangePage={handleChangePage}
              page={pageController.page - 1}
              totalDocs={users?.totalDocs ?? 0}
              limit={pageController.rowsPerPage ?? 0}
              loading={loading && isFirst !== true}
              pageSizeOptions={[pageController.rowsPerPage]}
              rowHeight={users?.docs?.length ? 52 : 200}
              clickable
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
//
export default UserView;
