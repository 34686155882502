import { useState } from 'react';
import {
  Checkbox,
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
//
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import Autocomplete from '../auto-complete-field';
import ConfirmationPopup from '../confirmation-popup';
/**
 * Defines an autocomplete component which supports mulitple option selects with checkboxes,
 * and renders the selected components below.
 * @param {String} id - Autocomplete component id
 * @param {String} name - Autocomplete component name
 * @param {Array} options - List of options to be rendered in the multi-select autocoplete component
 * @param {Array} selectedOptions - List of selected options
 * @param {Function} setSelectedOptions - Setter function to update the list of selected functions
 * @param {String} label - Label to be displayed
 * @param {formikKey} formikKey - Key of the formik component in case formik is used to track the options
 * @param {String} placeholder - Component placeholder
 * @param {Boolean} error - Indicates whether there is an error with the component
 * @param {String} errorMsg - Error message to be displayed to the user
 * @param {Function} onClose - Function that defines what happens onClose
 * @param {Boolean} removeConfirmationPopup - If true this will render a confirmation popup when trying to clear items
 * @param {String} confirmationMessage - Confirmation message to be displayed in the popup (required if removeConfirmationPopup is set to true)
 * @param {Function} isOptionEqualToValue - MUI autocomplete prop for customizing value comparison
 * @param {Boolean} displaySubmit - Displays a tick button on top of the autocomplete if true
 * @param {Function} onSubmit - Specifies the handler function for the the tick described above. 'displaySubmit' needs to be true
 * @param {String} displayAttribute - Specifies which attribute to display in the list
 * @param {Boolean} displayCards - Displays a card corresponding to each selected option if set to true
 * @param {Boolean} disabled - The component is disabled if true
 * @param {Boolean} formatOptions - The options are formatted only if true. Default is true
 * @param {Function} optionsFormatter - String formatter function to format the options rendered in the autocomplete. Default is capitalize first letter
 * @returns
 */
const MultiSelectAutoComplete = ({
  id,
  name,
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  formikKey,
  placeholder,
  error,
  errorMsg,
  onClose,
  removeConfirmationPopup,
  confirmationMessage,
  isOptionEqualToValue,
  displaySubmit,
  onSubmit,
  displayAttribute,
  displayCards = true,
  disabled,
  formatOptions = true,
  optionsFormatter = capitalizeFirstLetter,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState();
  const [optionToClear, setOptionToClear] = useState();
  // Handles the onchange of the autocomplete (updates the selected options)
  const handleOnChange = (_, newValue) => {
    if (formikKey) {
      setSelectedOptions(formikKey, newValue);
    } else {
      setSelectedOptions(newValue);
    }
  };
  // Handles the removal of a selected option
  const onClearClick = (option) => {
    const newOptions = selectedOptions?.filter((link) => link.id !== option?.id);
    if (formikKey) {
      setSelectedOptions(formikKey, newOptions);
    } else {
      setSelectedOptions(newOptions);
    }
  };
  // Handles the opening of a confirmation modal
  const handleOpenConfirmationModal = (option) => {
    setConfirmationModalOpen(true);
    setOptionToClear(option);
  };
  // Defines what happens when confirmation is provided on the confirmation popup
  const handleOnConfirmationSubmit = () => {
    onClearClick(optionToClear);
    setConfirmationModalOpen(false);
  };
  //
  const formatter = (value) => (formatOptions ? optionsFormatter(value) : value);
  //
  return (
    <>
      <ConfirmationPopup
        open={confirmationModalOpen}
        displayMessage={confirmationMessage}
        handleOnClose={() => setConfirmationModalOpen(false)}
        handleOnConfirmation={handleOnConfirmationSubmit}
      />
      <Autocomplete
        id={id}
        name={name}
        label={label}
        multiple
        controlled
        value={selectedOptions || []}
        disableCloseOnSelect
        options={options}
        onChange={handleOnChange}
        getOptionLabel={(option) => (displayAttribute ? option?.[displayAttribute] : option?.name)}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id}>
            <Checkbox checked={selected} />
            <Typography variant="body1">
              {displayAttribute
                ? formatter(option?.[displayAttribute])
                : formatter(option?.name)}
            </Typography>
          </li>
        )}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            InputProps={
              displaySubmit
                ? {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton onClick={onSubmit}>
                            <CheckIcon color="primary" />
                          </IconButton>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }
                : params.InputProps
            }
            sx={{
              fieldset: {
                borderRadius: '0px',
              },
            }}
          />
        )}
        isOptionEqualToValue={isOptionEqualToValue || ((option, value) => option?.id === value?.id)}
        clearOnBlur={false}
        inputValue={inputValue}
        error={error}
        errorMsg={errorMsg}
        onClose={onClose}
        disabled={disabled}
        {...rest}
      />
      {displayCards && (
        <Grid sx={{ mb: 2 }}>
          {selectedOptions &&
            selectedOptions.length > 0 &&
            selectedOptions?.map((option) => (
              <Button
                key={option?.id}
                variant="outlined"
                endIcon={<ClearIcon />}
                color="info"
                size="small"
                sx={{ marginRight: 1, marginTop: 1 }}
                onClick={() =>
                  removeConfirmationPopup
                    ? handleOpenConfirmationModal(option)
                    : onClearClick(option)
                }
                disabled={disabled}
              >
                {displayAttribute ? option?.[displayAttribute] : option?.name}
              </Button>
            ))}
        </Grid>
      )}
    </>
  );
};
//
export default MultiSelectAutoComplete;
