import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
//
import { Autocomplete, SearchBar } from 'features/base/components';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import {
  FINANCIAL_YEAR_END_MONTH,
  FINANCIAL_YEAR_START_MONTH,
} from 'features/base/constants/report-data';
import COLORS from 'features/base/constants/colors';
/**
 * Function that returns a component that displays the set of filters for the allocation report
 * @param {List<Object>} userList - A ist of all avalable users
 * @param {List<Object>} projectList - A list of all avalable projects
 * @param {List<Object>} selectedUsers - A list of the selected users
 * @param {List<Object>} selectedProjects - A list of the selected projects
 * @param {Function} handleSelectedUsersChange - Function that triggers when users filter is updated
 * @param {Function} handleSelectedProjectsChange - Function that triggers when projects filter is updated
 * @param {Function} initilialize - Function to clear allocations and initialize
 * @param {Object} selectedProjectDetails - Object containing the details of the selected project, if a single project is seleted
 * @param {Function} generateViewPeriod - Function that generates a view period based on provided parameters
 * @param {Function} setViewPeriod - Function to set the current viewPeriod
 * @param {Boolean} loading - Boolean indicating the loading status
 * @returns {Grid}
 */
const Filters = ({
  debouncedSearch,
  userList,
  departmentList,
  selectedUsers,
  departmentFilter,
  searchFilter,
  handleSelectedUsersChange,
  handleDepartmentOnChange,
  initilialize,
  generateViewPeriod,
  setViewPeriod,
  loading,
}) => {
  const [selectedYear, setSelectedYear] = useState({
    label: `${moment().year()} - ${moment().year() + 1}`,
    value: moment().year(),
  });
  //
  return (
    <Grid container spacing={2} sx={{ mt: '1.5rem' }} display="flex" flexDirection="row">
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <Typography color={COLORS.SILVER_LILAC}>Search</Typography>
          <SearchBar
            placeholder="Search users"
            value={searchFilter}
            handleSearchInput={(e) => debouncedSearch(e.target.value)}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <MultiSelectAutoComplete
            id="user"
            name="user"
            options={[
              { id: 'All', label: 'All' },
              ...userList.map((item) => ({
                id: item.id,
                label: `${item?.email}`,
              })),
            ]}
            value={selectedUsers}
            onChange={handleSelectedUsersChange}
            label="Users"
            isOptionEqualToValue={(option, autoValue) =>
              option.label === autoValue.label || autoValue === null
            }
            displayAttribute="label"
            displayCards={false}
            placeholder="Select users"
            disabled={loading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <Autocomplete
            id="departmentFilter"
            name="departmentFilter"
            options={[
              { id: 'All', label: 'All' },
              ...(departmentList?.map((item) => ({
                id: item?.department?.id,
                label: item?.department?.name,
              })) ?? []),
            ]}
            label="Departments"
            controlled
            value={departmentFilter}
            onChange={handleDepartmentOnChange}
            defaultOption={{ id: 'All', label: 'All' }}
            disableClearable={departmentFilter?.id === 'All'}
            disabled={loading}
            getOptionLabel={(option) => option?.label}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <Box>
          <Autocomplete
            id="year"
            options={(() => {
              const years = [];
              for (let i = 2020; i < moment().year() + 10; i += 1) {
                years.push({ label: `${i} - ${i + 1}`, value: i });
              }
              return years;
            })()}
            size="small"
            disableClearable
            onChange={(_, value, reason) => {
              if (JSON.stringify(selectedYear) === JSON.stringify(value)) return;
              setSelectedYear(value);
              if (reason === 'selectOption') {
                initilialize();
                const months = generateViewPeriod(
                  Number(value?.value),
                  FINANCIAL_YEAR_START_MONTH,
                  Number(value?.value) + 1,
                  FINANCIAL_YEAR_END_MONTH
                );
                setViewPeriod(months);
              }
            }}
            label="Year"
            disabled={loading}
            controlled
            value={selectedYear}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
//
export default Filters;
