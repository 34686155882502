import { Alert } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import TextField from 'features/base/components/text-field';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { MultiSelectAutoComplete, TextFieldDataList } from 'features/base/components';
import { ARSENAL_ITEM_TYPES } from 'features/base/constants/arsenal-item-types';
import {
  selectAddArsenalItemLoading,
  selectGetUsersLoading,
  selectUsers,
} from 'features/arsenal/selector';
import arsenalFormValidation from 'features/arsenal/validations/arsenal-validation';
import { arsenalActions } from 'features/arsenal/slice';

/**
 * Function that defines the form for adding new arsenal items
 * @prop {Function} handleOnClose - Handle for the onClose event of the popup/drawer containing this form
 * @returns {Form}
 * */
const AddArsenalItemForm = ({ handleOnClose }) => {
  const dispatch = useDispatch();
  //
  const users = useSelector(selectUsers);
  const getUsersLoading = useSelector(selectGetUsersLoading);
  const loading = useSelector(selectAddArsenalItemLoading);
  const notification = useSelector(selectNotification);
  //
  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        teamMembers: [],
        productOwners: [],
        techLeads: [],
        linkInput: '',
        links: [],
      }}
      validationSchema={arsenalFormValidation}
      onSubmit={(values) => {
        const formattedValues = {
          name: values?.name,
          description: values?.description,
          teamMembers: values?.teamMembers?.map((member) => member?.id),
          productOwners: values?.productOwners?.map((owner) => owner?.id),
          techLeads: values?.techLeads?.map((lead) => lead?.id),
          links: values?.links?.map((link) => ({
            ...link,
            url: encodeURI(link.url),
          })),
        };
        dispatch(arsenalActions.addArsenalItem(formattedValues));
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form noValidate onSubmit={handleSubmit} className="form">
          {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
            <Alert sx={{ mb: 3 }} severity={notification?.type}>
              {notification?.message}
            </Alert>
          )}
          <TextField
            type="text"
            name="name"
            value={values.name}
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            onBlur={handleBlur}
            onChange={handleChange}
            label="Name"
            placeholder="Boilerplate"
          />
          <TextField
            type="text"
            name="description"
            value={values.description}
            error={Boolean(touched?.description && errors?.description)}
            helperText={touched?.description && errors?.description}
            onBlur={handleBlur}
            onChange={handleChange}
            label="Description"
            fullWidth
            my={2}
            height={120}
            multiline
            rows={4}
            placeholder="Your description..."
          />
          <MultiSelectAutoComplete
            id="teamMembers"
            name="teamMembers"
            options={users?.docs ?? []}
            selectedOptions={values.teamMembers}
            setSelectedOptions={setFieldValue}
            label="Team members"
            formikKey="teamMembers"
            placeholder={getUsersLoading ? 'Loading...' : 'Select team members'}
            error={Boolean(touched.teamMembers && errors.teamMembers)}
            errorMsg={touched.teamMembers && errors.teamMembers}
            onClose={() => setFieldTouched('teamMembers', true)}
            displayAttribute="email"
            formatOptions={false}
            disabled={getUsersLoading}
          />
          <MultiSelectAutoComplete
            id="productOwners"
            name="productOwners"
            options={users?.docs ?? []}
            selectedOptions={values.productOwners}
            setSelectedOptions={setFieldValue}
            label="Product owners"
            formikKey="productOwners"
            placeholder={getUsersLoading ? 'Loading...' : 'Select product owners'}
            error={Boolean(touched.productOwners && errors.productOwners)}
            errorMsg={touched.productOwners && errors.productOwners}
            onClose={() => setFieldTouched('productOwners', true)}
            displayAttribute="email"
            formatOptions={false}
            disabled={getUsersLoading}
          />
          <MultiSelectAutoComplete
            id="techLeads"
            name="techLeads"
            options={users?.docs ?? []}
            selectedOptions={values.techLeads}
            setSelectedOptions={setFieldValue}
            label="Tech leads"
            formikKey="techLeads"
            placeholder={getUsersLoading ? 'Loading...' : 'Select tech leads'}
            error={Boolean(touched.techLeads && errors.techLeads)}
            errorMsg={touched.techLeads && errors.techLeads}
            onClose={() => setFieldTouched('techLeads', true)}
            displayAttribute="email"
            formatOptions={false}
            disabled={getUsersLoading}
          />
          <TextFieldDataList
            name="linkInput"
            text={values?.linkInput}
            setText={(data) => setFieldValue('linkInput', data)}
            textFormatter={(text) => ({ url: text, category: '' })}
            items={values?.links}
            setItems={(data) => {
              setFieldValue('links', data);
            }}
            textError={touched?.linkInput && errors.linkInput}
            displayError={touched?.links && errors?.links}
            // Setting touched to true on submit, display the error when submitting data through the textfield
            onAdd={() => {
              setFieldTouched('linkInput', true);
              if (values?.linkInput) {
                setFieldTouched('links', false);
              } else {
                setFieldTouched('links', true);
              }
            }}
            onRemove={() => {
              dispatch(notificationActions.resetNotification());
              setFieldTouched('links', true);
            }}
            listSx={{ maxHeight: 300 }}
            displayKey="url"
            renderInternalSelect
            internalSelectProps={{
              options: Object.values(ARSENAL_ITEM_TYPES),
              onChange: (arsenalLink, selectedCategory) => {
                const updatedDocumentLinks = values?.links?.map((link) =>
                  link?.url === arsenalLink?.url ? { ...link, category: selectedCategory } : link
                );
                setFieldTouched('links', true);
                setFieldValue('links', updatedDocumentLinks);
              },
              isOptionEqualToValue: (option, autoValue) => option.id === autoValue.id,
            }}
            internalSelectOptionsKey="category"
            internalOptionsPlaceholder="Select category"
            isInternalSelectMultiple={false}
            label="Link"
          />
          <ButtonGrid
            leftButtonText="Cancel"
            rightButtonText={loading ? 'Submitting' : 'Submit'}
            leftOnClick={handleOnClose}
            rightOnClick={null}
            submitDisabled={loading || getUsersLoading}
            rightIcon={loading ? loaderIcon : null}
          />
        </form>
      )}
    </Formik>
  );
};
//
export default AddArsenalItemForm;
