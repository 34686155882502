/**
 * Defines the API paths for department
 */
const API = {
  GET_DEPARTMENT: {
    path: '/departments/department-designations',
    method: 'GET',
  },
  GET_WORK_AREA: {
    path: '/work-areas?:query',
    method: 'GET',
  },
  ADD_DEPARTMENT: {
    path: '/departments',
    method: 'POST',
  },
  ADD_WORK_AREA: {
    path: '/work-areas',
    method: 'POST',
  },
  EDIT_DEPARTMENT: {
    path: '/departments/:id',
    method: 'PATCH',
  },
  GET_DESIGNATIONS: {
    path: '/designations',
    method: 'GET',
  },
  EDIT_WORK_AREA: {
    path: '/work-areas/:id',
    method: 'PATCH',
  },
};
//
export default API;
