import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
//
import COLORS from 'features/base/constants/colors';
import { selectAllocatedActualReportAllocations } from 'features/reports/selectors';

const ProjectProfileTable = () => {
  const allocations = useSelector(selectAllocatedActualReportAllocations);
  return (
    <Table sx={{ width: '50rem' }}>
      <TableHead>
        <TableRow>
          <TableCell
            size="small"
            rowSpan={2}
            sx={{
              height: 62,
              bgcolor: COLORS.SKY_BLUE,
              borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
              borderInline: `2px solid ${COLORS.WHITE}`,
            }}
          >
            <Typography>Project</Typography>
          </TableCell>
          <TableCell
            size="small"
            rowSpan={2}
            sx={{
              height: 62,
              bgcolor: COLORS.SKY_BLUE,
              borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
              borderInline: `2px solid ${COLORS.WHITE}`,
            }}
          >
            <Typography>Profile</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(allocations?.docs || []).map((currentAllocation, index) => (
          <TableRow key={`${currentAllocation.id}`}>
            <TableCell
              size="small"
              sx={{
                margin: 0,
                padding: 0,
                borderBottom: `2px solid ${COLORS.WHITE}`,
                bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                height: 68,
                justifyContent: 'center',
              }}
            >
              <Typography
                size="small"
                sx={{
                  fontSize: 16,
                }}
              >
                {currentAllocation.projectId.name}
              </Typography>
            </TableCell>
            <TableCell
              size="small"
              sx={{
                margin: 0,
                padding: 0,
                borderBottom: `2px solid ${COLORS.WHITE}`,
                bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                display: 'flex',
                flexDirection: 'column',
                height: 68,
                justifyContent: 'center',
              }}
            >
              <Typography
                size="small"
                sx={{
                  fontSize: 16,
                }}
              >
                {currentAllocation.userId.firstName} {currentAllocation.userId.lastName}
              </Typography>
              <Typography
                size="small"
                variant="body2"
                sx={{
                  fontSize: 14,
                  color: COLORS.SILVER_LILAC,
                }}
              >
                {
                  currentAllocation.userId?.currentUserDepartmentDesignationId
                    ?.departmentDesignationId?.designationId?.name
                }
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
//
export default ProjectProfileTable;
