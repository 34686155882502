import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
//
import COLORS from 'features/base/constants/colors';
import { selectProject, selectUpdateWorkAllocation } from 'features/projects/selectors';
/**
 * Function that renders the header in the update work allocations page
 */
const UpdateWorkAllocationsHeader = ({ selectedUser, allocatedPercentage }) => {
  const projectDetails = useSelector(selectProject);
  const { workAllocation: workAllocationByUser, loading: tableLoading } = useSelector(
    selectUpdateWorkAllocation
  );
  //
  return (
    <Grid container spacing={2} sx={{ itemAlign: 'center' }}>
      <Grid item lg={3}>
        <Box sx={{ p: '0.3rem' }}>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={2}>
        <Box sx={{ p: '0.3rem' }}>
          <Typography sx={{ fontSize: '1rem' }}>
            {/* get month and year in this format "June" "2022" */}
            {new Date(projectDetails.startDate).toLocaleString('en-us', {
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={2}>
        <Box sx={{ p: '0.3rem' }}>
          <Typography sx={{ fontSize: '1rem' }}>
            {new Date(projectDetails.endDate).toLocaleString('en-us', {
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        </Box>
      </Grid>
      {selectedUser && workAllocationByUser?.docs?.length > 0 && !tableLoading && (
        <>
          <Grid item lg={2}>
            <Box sx={{ p: '0.3rem' }}>
              {/* allocation percentage */}
              <Typography sx={{ fontSize: '1rem' }}> {`${allocatedPercentage?.toFixed(2)}% Allocated`}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ bgcolor: '#D3E6FF', p: '0.3rem' }}>
              <Typography sx={{ color: COLORS.DODGER_BLUE, fontWeight: 600 }} pr={2} pl={2}>
                {/*  available percentage */}
                {`${(100 - allocatedPercentage)?.toFixed(2)}% Available`}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default UpdateWorkAllocationsHeader;
