/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { arsenalActions } from './slice';
import API from './constants';

/**
 * Generator function for adding an arsenal item
 * @param {Object} payload
 */
export function* addArsenalItemGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_ARSENAL_ITEM, payload);
    yield put(arsenalActions.addArsenalItemSucceeded(response));
  } catch (error) {
    yield put(arsenalActions.addArsenalItemFailed(error?.message));
  }
}
/**
 * Generator function for getting the arsenal
 * @param {Object} payload
 */
export function* getArsenalGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ARSENAL, payload);
    yield put(arsenalActions.getArsenalSucceeded(response));
  } catch (error) {
    yield put(arsenalActions.getArsenalFailed(error?.message));
  }
}
/**
 * Generator function for editing an arsenal item
 * @param {Object} payload
 */
export function* editArsenalItemGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ARSENAL_ITEM, payload);
    yield put(arsenalActions.editArsenalItemSucceeded(response));
  } catch (error) {
    yield put(arsenalActions.editArsenalItemFailed(error?.message));
  }
}
/**
 * Generator function for getting users
 * @param {Object} payload
 */
export function* getUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(arsenalActions.getUsersSucceeded(response));
  } catch (error) {
    yield put(arsenalActions.getUsersFailed(error?.message));
  }
}
/**
 * Redux saga that triggers above generated functions
 */
export function* arsenalSaga() {
  yield takeLatest(arsenalActions.addArsenalItem.type, addArsenalItemGenerator);
  yield takeLatest(arsenalActions.getArsenal.type, getArsenalGenerator);
  yield takeLatest(arsenalActions.editArsenalItem.type, editArsenalItemGenerator);
  yield takeLatest(arsenalActions.getUsers.type, getUsersGenerator);
}
//
export default arsenalSaga;
