import { Typography, FormControl, Select as MuiSelect, MenuItem } from '@mui/material';
//
import './index.scss';

/**
 * Defines a function that returns a common MuiSelect element
 * @prop {String} id - MuiSelect id prop
 * @prop {String} value - MuiSelect value prop
 * @prop {Function} onChange - onChange prop function
 * @prop {String[]} items - The list of items shown in the select component
 * @prop {String} textLabel - Label of the select component
 * @prop {Boolean} hide - Boolean value that determines if the select component is hidden
 * @prop {Function} stringFormat - Function that format the menu items
 * @prop {Boolean} error - Boolean value that indicates whether a validation error is present
 * @prop {String} errorMsg - Error description to be displayed
 * @prop {Boolean} disabled - Boolean value that determines if the select component is disabled
 * @prop {String} disabledMsg - Description as to why the component is disabled
 * @prop {Function} onOpen - onOpen prop function
 * @prop {Function} onClose - onClose prop function
 * @prop {String} placeholder - Placeholder text
 * @prop {Function} renderValue - Function that renders the value
 */
const Select = ({
  id,
  value,
  onChange,
  items,
  textLabel,
  hide,
  stringFormat,
  error,
  errorMsg,
  disabled,
  disabledMsg,
  onOpen,
  onClose,
  placeholder,
  renderValue,
}) => {
  if (hide) return null;
  return (
    <>
      <Typography fontSize="16px" className="field-label">
        {textLabel}
      </Typography>
      <FormControl
        fullWidth
        sx={{
          pb: 2,
          fieldset: {
            borderRadius: '0px',
          },
        }}
      >
        <MuiSelect
          id={id}
          value={value}
          onChange={onChange}
          className="select-box"
          error={error}
          disabled={disabled}
          onOpen={onOpen}
          onClose={onClose}
          displayEmpty
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return placeholder;
            }
            return renderValue ? renderValue(selected) : selected;
          }}
        >
          {items?.map((item) => (
            <MenuItem
              key={item?.key}
              value={item?.value}
              disabled={item.key === '0'}
              className="capitalize-first"
            >
              {stringFormat ? stringFormat(item?.value) : item?.value}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && <p className="error-feedback">{errorMsg}</p>}
        {disabled && <p className="disabled-feedback">{disabledMsg}</p>}
      </FormControl>
    </>
  );
};
//
export default Select;
