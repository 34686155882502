import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Typography, Divider, Grid } from '@mui/material';
import uniqolor from 'uniqolor';
import moment from 'moment';
import { ShimmerTable, ShimmerThumbnail } from 'react-shimmer-effects';
//
import {
  selectIsMyAllocationsFetching,
  selectMyAllocations,
  selectUser,
  selectLoader as selectProfileLoader,
} from 'features/profile/selectors';
import { profileActions } from 'features/profile/slice';
import redWarningIcon from 'features/base/assets/images/png/red-warning-icon.png';
import { DataGrid } from 'features/base/components';
import MY_ALLOCATION_TABLE_HEADERS from 'features/base/utils/tables/my-allocation-table';
import {
  DATE_FORMAT,
  DATE_LANGUAGE,
  MONTHS,
} from 'features/base/constants/date-formatting';
import COLORS from 'features/base/constants/colors';
import { getMonth, getYear, isCurrentMonthYearInRange } from 'features/base/helpers/date';
/**
 * UI component that displays user allocations
 * @returns {Box}
 */
const MyAllocationView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  //
  const year = getYear(moment().toISOString());
  const month = getMonth(moment().toISOString());
  //
  const myAllocations = useSelector(selectMyAllocations);
  const isMyAllocationsLoading = useSelector(selectIsMyAllocationsFetching);
  const isUserLoading = useSelector(selectProfileLoader);
  const user = useSelector(selectUser);
  //
  const [tableRows, setTableRows] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [isOverAllocated, setIsOverAllocated] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPageNumber: 1,
    resultsPerPage: 10,
  });
  //
  useEffect(() => {
    dispatch(
      profileActions.getMyAllocations({
        query: `userIds=${id}&aggregated=${true}&pagination=false`,
      })
    );
  }, [id]);
  //
  useEffect(() => {
    const tempTableRows = myAllocations?.docs?.map((allocation) => {
      const startDate = new Date(allocation.allocationStartDate).toLocaleString(
        DATE_LANGUAGE.LANGUAGE,
        DATE_FORMAT
      );
      const endDate = new Date(allocation.allocationEndDate).toLocaleString(
        DATE_LANGUAGE.LANGUAGE,
        DATE_FORMAT
      );
      return {
        id: allocation?.id,
        projectName: allocation.projectId.name,
        description: allocation.projectId.description,
        startDate,
        endDate,
        /* entry.allocation have this structure [{hours: 8, month: 'January', year: 2021] */
        allocation: {
          value: parseFloat(allocation.allocatedPercentage)?.toFixed(2),
        },
      };
    });
    const tempPieChartData = myAllocations?.docs
      ?.filter((entry) =>
        isCurrentMonthYearInRange(entry?.allocationStartDate, entry?.allocationEndDate)
      )
      ?.map((entry) => ({
        name: entry?.projectId?.name,
        percentage: parseFloat(entry?.allocatedPercentage),
        color: uniqolor(`${entry?.projectId?.name} ${entry?.projectId?.description}`).color,
      }));
    /* if sum of all percentages is less than 100, add a new entry with the remaining percentage */
    const sumOfAllPercentages = tempPieChartData?.reduce((acc, cur) => acc + cur.percentage, 0);
    setIsOverAllocated(sumOfAllPercentages > 100);
    if (sumOfAllPercentages >= 0) {
      tempPieChartData.push({
        name: 'Available',
        percentage: 100 - sumOfAllPercentages,
        color: COLORS.LAVENDER_MIST,
      });
    }
    setTableRows(tempTableRows || []);
    setPieChartData(tempPieChartData || []);
  }, [myAllocations, user, month, year]);

  //
  return (
    <Box sx={{ m: '20px 0px' }}>
      {isMyAllocationsLoading ? (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <ShimmerThumbnail height={16} width={200} />
            <Box sx={{ mr: { xs: 0, sm: 1 } }} />
            <ShimmerThumbnail height={16} width={200} />
          </Box>
          <ShimmerThumbnail height={32} />
          <Box sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 1 } }} />
          <ShimmerThumbnail height={16} width={250} />
        </Box>
      ) : (
        <>
          <Typography variant="body1" sx={{ mb: '16px' }}>
            Allocation Percentage Breakdown for {MONTHS[month - 1]} {year}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: '2rem', mt: '2rem' }}>
            {pieChartData?.map((data) => (
              <Box key={data?.name} sx={{ mr: '48px', alignItems: 'center', display: 'flex' }}>
                <Box
                  sx={{
                    bgcolor: data.color,
                    width: '20px',
                    height: '20px',
                    mr: '12px',
                    borderRadius: '5px',
                  }}
                />
                <Typography>{data.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              mb: '16px',
            }}
          >
            {pieChartData?.map((data) => (
              <Box
                key={data?.name}
                sx={{
                  width: `${data.percentage}%`,
                  textAlign: 'center',
                  bgcolor: data.color,
                  p: '6px 0px',
                  display: `${data.percentage > 0 ? 'flex' : 'none'}`,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    ml: 'auto',
                    mr: 'auto',
                  }}
                >
                  {` ${data.percentage.toFixed(0)}%`}
                </Typography>
              </Box>
            ))}
          </Box>
          {isOverAllocated && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: '16px' }}>
              <img alt="red-icon" src={redWarningIcon} height="20px" width="20px" />
              <Typography variant="body1" sx={{ ml: '4px', color: COLORS.SECONDARY_MAIN }}>
                This user is over allocated for {MONTHS[month - 1]} {year}
              </Typography>
            </Box>
          )}
        </>
      )}
      <Divider sx={{ mb: '2rem', mt: '2rem' }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '2rem' }}>
        {isMyAllocationsLoading || isUserLoading ? (
          <Box sx={{ width: '100%' }}>
            <ShimmerTable row={3} col={4} />
          </Box>
        ) : (
          tableRows?.length > 0 && (
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body1" sx={{ mb: '16px' }}>
                  Total Allocations
                </Typography>
              </Grid>
              <Grid item>
                <DataGrid
                  columns={MY_ALLOCATION_TABLE_HEADERS}
                  rows={tableRows}
                  paginationMode="client"
                  handleChangePage={({ page }) => {
                    setPaginationData({ ...paginationData, currentPageNumber: page + 1 });
                  }}
                  page={paginationData.currentPageNumber - 1 ?? 0}
                  limit={paginationData.resultsPerPage ?? 10}
                  pageSizeOptions={[paginationData.resultsPerPage ?? 10]}
                  rowHeight={tableRows?.length ? 52 : 200}
                />
              </Grid>
            </Grid>
          )
        )}
        {!isMyAllocationsLoading && myAllocations?.allocations?.length < 1 && (
          <Typography variant="body1">No allocation data available</Typography>
        )}
      </Box>
    </Box>
  );
};
//
export default MyAllocationView;
