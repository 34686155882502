import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box, Stack, Divider, Container, Avatar } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import 'react-loading-skeleton/dist/skeleton.css';
import { ShimmerCategoryItem } from 'react-shimmer-effects';
//
import { TabBar } from 'features/base/components';
import { profileActions } from 'features/profile/slice';
import { selectUser, selectLoader } from 'features/profile/selectors';
import { selectAuthUser } from 'features/base/auth/selectors';
import { USER_TYPES } from 'features/base/constants/user-types';
import ROUTES from 'features/base/constants/routes';
import { phoneNumberFormatter } from 'features/base/helpers/phone-numbers';
import COLORS from 'features/base/constants/colors';
import {
  CLIENT_USERS_NESTED_ROUTE,
  CLIENT_PROJECTS_NESTED_ROUTE,
  PROFILE_ALLOCATIONS_NESTED_ROUTE,
  PROFILE_SKILLS_NESTED_ROUTE,
} from 'features/base/constants/dashboard-tab-routes';
import EditProfilePopup from '../edit-profile-modal';
import MyAllocationView from '../my-allocations-view';
import MySkillView from '../my-skill-view';
import MyUsersView from '../my-users-view';
import MyProjectsView from '../my-projects-view';
/**
 * Component that defines the profile view
 * @returns MUI Grid with the profile view
 */
const ProfileView = () => {
  const dispatch = useDispatch();
  //
  const location = useLocation();
  //
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectLoader);
  const authUser = useSelector(selectAuthUser);
  //
  const nestedRoutes = [PROFILE_ALLOCATIONS_NESTED_ROUTE, PROFILE_SKILLS_NESTED_ROUTE];
  const [pathName, setPathName] = useState(nestedRoutes[0].path);
  const [editModalOpen, setEditModalOpen] = useState(false);
  //
  const { id } = useParams();
  //
  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };
  useEffect(() => {
    dispatch(profileActions.fetchUserById({ id }));
    dispatch(profileActions.getDepartmentDesignations());
    return () => {
      dispatch(profileActions.clearUser());
    };
  }, [dispatch, id]);
  //
  useEffect(() => {
    const urlSections = location.pathname.split('/');
    const URLPath = urlSections[urlSections.length - 1];
    setPathName(URLPath);
  }, [location.pathname]);
  //
  const setUserRole = (role) => role[0] + role.replace('_', ' ').slice(1).toLowerCase();
  //
  return (
    <>
      <EditProfilePopup editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} />
      {isLoading && (
        <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
          <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={100}
            imageHeight={100}
            text
          />
        </Container>
      )}
      {!isLoading && (
        <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} lg={2}>
              {/* divide by two image and name */}
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                  {user?.profileImage ? (
                    <Avatar
                      alt={`${user?.firstName}`}
                      src={user?.profileImage}
                      imgProps={{ referrerPolicy: 'no-referrer' }}
                      sx={{
                        width: '4rem',
                        height: '4rem',
                        bgcolor: (theme) => theme.palette.success.light,
                        borderRadius: '50%',
                        margin: 'auto',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '4rem',
                        height: '4rem',
                        bgcolor: (theme) => theme.palette.success.light,
                        borderRadius: '50%',
                        margin: 'auto',
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Stack spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {user?.firstName && user?.lastName
                        ? `${user?.firstName} ${user?.lastName}`
                        : 'Name not found'}
                    </Typography>
                    {/* green admin tole tag */}
                    <Box
                      sx={{
                        width: 'auto',
                        paddingRight: '1rem',
                        paddingLeft: '1rem',
                        height: '1.8rem',
                        bgcolor: (theme) => theme.palette.success.light,
                        borderRadius: '2px',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: 'center',
                          color: (theme) => theme.palette.success.main,
                          marginTop: '0.2rem',
                        }}
                      >
                        {setUserRole(user.roleId?.name || '-')}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'none', lg: 'block' }, mt: '0.5rem', mb: '0.5rem' }}
            />
            <Divider flexItem sx={{ display: { xs: 'block', lg: 'none' } }} />
            <Grid item xs={6} lg={2}>
              <Stack
                spacing={1}
                direction="column"
                justifyContent="center"
                sx={{ color: 'lightgray' }}
              >
                <Typography
                  variant="body2"
                  style={{
                    backgroundColor: COLORS.LUMINOUS_COBALT,
                    color: COLORS.DODGER_BLUE,
                    padding: '5px',
                  }}
                >
                  {user?.type === USER_TYPES.CLIENT
                    ? user?.client?.company
                    : user?.email || 'Not Available'}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    backgroundColor: COLORS.LUMINOUS_COBALT,
                    color: COLORS.DODGER_BLUE,
                    padding: '5px',
                  }}
                >
                  {user?.type === USER_TYPES.CLIENT
                    ? `BRN: ${user?.client?.brNumber || 'BR number not found'}`
                    : phoneNumberFormatter(user?.phoneNumber) || 'Not Available'}
                </Typography>
              </Stack>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'none', lg: 'block' }, mt: '0.5rem', mb: '0.5rem' }}
            />
            {user?.type === USER_TYPES.CLIENT ? (
              <Grid item xs={6} lg={2}>
                <Stack
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  sx={{ color: 'lightgray' }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '25px',
                    }}
                  >
                    {user?.client?.address}
                  </Typography>
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={6} lg={2}>
                <Stack
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  sx={{ color: 'lightgray' }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '5px',
                    }}
                  >
                    {user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId
                      ?.name || 'Not Available'}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '5px',
                    }}
                  >
                    {user?.currentUserDepartmentDesignationId?.departmentDesignationId
                      ?.designationId?.name || 'Not Available'}
                  </Typography>
                </Stack>
              </Grid>
            )}
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'none', lg: 'block' }, mt: '0.5rem', mb: '0.5rem' }}
            />
            {user?.type === USER_TYPES.CLIENT ? (
              <Grid item xs={6} lg={2}>
                <Stack
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  sx={{ color: 'lightgray' }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '5px',
                    }}
                  >
                    {user?.email || 'Not Available'}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '5px',
                    }}
                  >
                    {user?.phoneNumber || 'Not Available'}
                  </Typography>
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={6} lg={2}>
                {/* Work area or Address */}
                <Stack
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ color: 'lightgray' }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: COLORS.LUMINOUS_COBALT,
                      color: COLORS.DODGER_BLUE,
                      padding: '5px',
                    }}
                  >
                    {user?.workAreaId?.name || 'Work area not available'}
                  </Typography>
                </Stack>
              </Grid>
            )}
            <Grid item xs={4} lg={2}>
              {/* edit pen icons and the edit bold text */}
              <Stack
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                onClick={handleOpenEditModal}
                sx={{ cursor: 'pointer' }}
              >
                <BorderColorIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                  style={{ fontSize: '1.2rem' }}
                />
                <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                  Edit
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}
      {(!(user?.type === USER_TYPES.CLIENT || authUser?.type === USER_TYPES.CLIENT) &&
        !isLoading && (
          <Container maxWidth="xl" sx={{ mt: '2rem' }}>
            <TabBar nestedRoutes={nestedRoutes} pathName={pathName} />
            {/* nested routes for the tabs array */}
            <Routes>
              {/*  redirect to the first tab */}
              <Route
                path="/"
                element={<Navigate to={`${ROUTES.USERS}/${id}/${ROUTES.ALLOCATIONS}`} />}
              />
              <Route
                path={`/${ROUTES.CONFIGURATIONS}`}
                exact
                element={<Typography variant="body2">Configurations</Typography>}
              />
              <Route path={`/${ROUTES.SKILLS}`} exact element={<MySkillView />} />
              <Route path={`/${ROUTES.ALLOCATIONS}`} exact element={<MyAllocationView />} />
              <Route
                path={`/${ROUTES.TOOLS}`}
                exact
                element={<Typography variant="body2">Tools</Typography>}
              />
            </Routes>
          </Container>
        )) ||
        (!isLoading && (
          <Container maxWidth="xl" sx={{ mt: '2rem' }}>
            <TabBar
              nestedRoutes={[CLIENT_USERS_NESTED_ROUTE, CLIENT_PROJECTS_NESTED_ROUTE]}
              pathName={pathName}
            />
            {/* nested routes for the tabs array */}
            <Routes>
              {/*  redirect to the first tab */}
              <Route
                path="/"
                element={<Navigate to={`${ROUTES.USERS}/${id}/${ROUTES.CLIENT_PROFILE_USERS}`} />}
              />
              <Route path={`/${ROUTES.CLIENT_PROFILE_USERS}`} exact element={<MyUsersView />} />
              <Route
                path={`/${ROUTES.CLIENT_PROFILE_PROJECTS}`}
                exact
                element={<MyProjectsView />}
              />
            </Routes>
          </Container>
        ))}
    </>
  );
};
//
export default ProfileView;
