import { Button as MuiButton, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
//
import { CommonBackground } from 'features/base/components';
import { authActions } from 'features/base/auth/slice';

/**
 * Component that defines the 401 error page
 * @returns 401 error
 */
const Page401 = () => {
  const dispatch = useDispatch();
  //
  return (
    <CommonBackground>
      <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
        <Typography sx={{ color: 'white' }} component="h2" variant="h3" align="center" gutterBottom>
          You have selected an invalid email address
        </Typography>
        <Typography sx={{ color: 'white' }} component="h2" variant="h5" align="center" gutterBottom>
          Please select a different email
        </Typography>
        <MuiButton
          sx={{ mt: '1rem' }}
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(authActions.keycloakLogout());
          }}
        >
          Return to welcome page
        </MuiButton>
      </Box>
    </CommonBackground>
  );
};
//
export default Page401;
