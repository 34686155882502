/* eslint-disable import/prefer-default-export */
/**
 * Defines the firebase firestore notification types
 */
export const FIREBASE_NOTIFICATION_TYPES = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};
