import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { isEmpty } from 'lodash';
//
import moment from 'moment';
import { CLIENTS_USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { profileActions } from 'features/profile/slice';
import { selectUser, selectClient, selectLoader } from 'features/profile/selectors';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import MY_PROJECTS_TABLE_HEADERS from 'features/base/utils/tables/my-projects-table';
import { DataGrid } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { ENVIRONMENT } from 'config';
/**
 * UI component that displays client projects
 * @returns {Box}
 */
const MyProjectsView = () => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);
  //
  const isFirst = useIsInitialize(client);
  //
  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(profileActions.getClientById({ id: user?.client?.clientId }));
    }
  }, []);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        {isFirst ? (
          <ShimmerTable row={4} col={6} />
        ) : (
          <DataGrid
            columns={MY_PROJECTS_TABLE_HEADERS}
            rows={
              client?.projects
                ?.toSorted((firstProject, secondProject) =>
                  firstProject?.projectId?.name?.localeCompare(secondProject?.projectId?.name)
                )
                ?.map((project) => ({
                  id: project.projectId?.id,
                  project: {
                    logo: `${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${project.projectId?.logo[0]?.url}`,
                    name: project.projectId?.name,
                  },
                  description: {
                    value: project.projectId?.description,
                  },
                  startDate: {
                    value: moment(project.projectId?.startDate).format(DATE_FORMAT_SLASH),
                  },
                  endDate: {
                    value: moment(project.projectId?.endDate).format(DATE_FORMAT_SLASH),
                  },
                  lastUpdated: {
                    value: moment(project.projectId?.updatedAt).format(DATE_FORMAT_SLASH),
                  },
                  status: {
                    value: project?.dataStatus === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                    bgColour:
                      project?.dataStatus === 'ACTIVE'
                        ? COLORS.PALE_GREEN
                        : COLORS.LAVENDER_MIST,
                    textColour:
                      project?.dataStatus === 'ACTIVE'
                        ? COLORS.SUCCESS_MAIN
                        : COLORS.NAVY_BLUE,
                  },
                })) ?? []
            }
            loading={loading && isFirst !== true}
            disableRowSelectionOnClick
            limit={CLIENTS_USERS_PAGINATION_LIMIT}
            pageSizeOptions={[CLIENTS_USERS_PAGINATION_LIMIT]}
            serverSidePagination={false}
            rowHeight={client?.projects?.length ? 52 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default MyProjectsView;
