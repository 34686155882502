import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/department'] || initialState;
/**
 * Function to retrieve all departments from the department slice
 */
export const selectAllDepartment = createSelector(
  [selectDomain],
  (departmentState) => departmentState.departmentList
);
/**
 * Function to retrieve all work areas from the department slice
 */
export const selectAllWorkArea = createSelector(
  [selectDomain],
  (workAreaState) => workAreaState.workAreaList
);
/**
 * Selector for getting a department by id
 */
export const selectDepartmentById = createSelector(
  [selectAllDepartment, (_, departmentId) => departmentId],
  (departmentList, departmentId) =>
    departmentList?.find((department) => department?.department?.id === departmentId) || null
);
/**
 * Selector for getting loader state for department
 */
export const selectDepartmentLoader = createSelector(
  [selectDomain],
  (departmentState) => departmentState.departmentLoading
);
/**
 * Selector for getting loader state of work area
 */
export const selectWorkAreaLoader = createSelector(
  [selectDomain],
  (workAreaState) => workAreaState.workAreaLoading
);
/**
 * Function to retrieve search word of workArea from the department slice
 */
export const selectSearch = createSelector([selectDomain], (workAreaState) => workAreaState.search);
/**
 * Function to retrieve search word of department from the department slice
 */
export const selectDepartmentSearch = createSelector(
  [selectDomain],
  (departmentState) => departmentState.search
);
/**
 * Selector to get the loading status of the add department action
 */
export const selectAddDepartmentsLoader = createSelector(
  [selectDomain],
  (userState) => userState?.addDepartmentLoading
);
/**
 * Selector to get the loading status of the edit departments action
 */
export const selectEditDepartmentsLoader = createSelector(
  [selectDomain],
  (userState) => userState?.editDepartmentLoading
);
/**
 * Selector to get the loading status of the add workArea action
 */
export const selectAddWorkAreaLoader = createSelector(
  [selectDomain],
  (userState) => userState?.addWorkAreaLoading
);
/**
 * Selector to get the loading status of the edit workArea action
 */
export const selectEditWorkAreaLoader = createSelector(
  [selectDomain],
  (userState) => userState?.editWorkAreaLoading
);
