import { Alert, Switch, FormControlLabel, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import moment from 'moment';
//
import { projectActions } from 'features/projects/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectAllProjects,
  selectUploadedLogo,
  selectUpdateProjectLoader,
  selectUploadFileLoader,
  selectInternalUsers,
  selectAllDepartments,
} from 'features/projects/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import FileUpload from 'features/base/components/file-upload';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import Select from 'features/base/components/select';
import Autocomplete from 'features/base/components/auto-complete-field';
import { PROJECT_TYPES, PROJECT_TYPE_ARRAY } from 'features/base/constants/project-types';
import {
  PROJECT_BILLING_TYPES,
  PROJECT_BILLING_TYPE_ARRAY,
} from 'features/base/constants/project-billing-types';
import DatePicker from 'features/base/components/date-picker';
import ERROR_TYPES from 'features/base/constants/error-types';
import { formattedString, capitalizeFirstLetter } from 'features/base/helpers/strings';
import { editProjectFormValidation } from 'features/projects/validation/project-validation';
import {
  PROJECT_LOGO_ALLOWED_FILE_TYPES,
  PROJECT_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import {
  getAllowedExtensionsString,
  processFiles,
  verifyExtensions,
} from 'features/base/helpers/file';
import { NumericInputField } from 'features/base/components';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import { selectAllTools } from 'features/tools/selectors';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { PROJECT_FILE_TYPES } from 'features/base/constants/project-file-types';
import './index.scss';

/**
 * Function that defines the popup form for editing a project
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} projectId - id of the project to be edited
 * @returns {Popup}
 */
const EditProjectsPopup = ({ editModalOpen, setEditModalOpen, projectId }) => {
  const dispatch = useDispatch();
  //
  const tools = useSelector(selectAllTools);
  const departments = useSelector(selectAllDepartments);
  const notification = useSelector(selectNotification);
  const usersList = useSelector(selectInternalUsers);
  const projectToEdit = useSelector(selectAllProjects)?.docs?.find(
    (project) => project?.id === projectId
  );
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectUpdateProjectLoader);
  const logoLoading = useSelector(selectUploadFileLoader);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(projectActions.clearEditProject());
    dispatch(projectActions.clearLogo());
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), PROJECT_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = processFiles(logo);
      formData.append('fileType', PROJECT_FILE_TYPES.LOGO)
      if (logo.length > 0) {
        dispatch(projectActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  const getInputLabel = () => {
    if (uploadedLogo?.length) {
      return uploadedLogo[0].name;
    }
    if (projectToEdit?.logo?.length) {
      return projectToEdit?.logo[0].name;
    }
    return 'Choose logo';
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit Project"
        mediumSize="1060px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={{
            id: projectToEdit?.id,
            name: projectToEdit?.name,
            description: projectToEdit?.description,
            type: projectToEdit?.type,
            billingType: projectToEdit?.billingType || '',
            startDate: projectToEdit?.startDate,
            endDate: projectToEdit?.endDate,
            projectValue: projectToEdit?.projectValue || 0,
            jiraKey: projectToEdit?.jiraKey || '',
            jiraAssignee: projectToEdit?.jiraAssignee?.email || '',
            jiraEnabled: !!projectToEdit?.jiraKey,
            tools: projectToEdit?.tools,
            departments: projectToEdit?.departments,
            itSupport: projectToEdit?.itSupport || false,
          }}
          validationSchema={editProjectFormValidation}
          onSubmit={(values) => {
            const project = {
              ...values,
              tools: values?.tools.map((tool) => tool.id),
              departments: values?.departments.map((department) => department.id),
              logo: uploadedLogo,
              jiraAssignee: values?.jiraAssignee,
            };
            if (!project?.jiraEnabled) {
              project.jiraKey = '';
              project.jiraAssignee = '';
            }
            if (uploadedLogo.length === 0) {
              project.logo = undefined;
            } else {
              project.logo = uploadedLogo?.map((lg) => ({ ...lg, url: encodeURI(lg?.url) }));
            }
            delete project.jiraEnabled;
            project.startDate = moment(project.startDate).format(ISO_WITHOUT_TIME);
            project.endDate = moment(project.endDate).format(ISO_WITHOUT_TIME);
            if (project.type !== PROJECT_TYPES.CLIENT) {
              project.billingType = null;
              project.projectValue = 0;
            }
            if (project?.billingType !== PROJECT_BILLING_TYPES.FIXED_BID) {
              project.projectValue = 0;
            }
            dispatch(projectActions.editProject(project));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid container item md={6} direction="column">
                  <FileUpload
                    setLogo={setLogo}
                    label="Logo"
                    acceptedTypes={PROJECT_LOGO_ALLOWED_FILE_TYPES}
                    error={fileTypeError}
                    errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                      PROJECT_LOGO_ALLOWED_EXTENSIONS
                    )}`}
                    inputLabel={getInputLabel()}
                    loading={logoLoading}
                  />
                  <TextField
                    type="text"
                    name="name"
                    value={values?.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Project Name"
                  />
                  <Select
                    id="type"
                    value={values.type}
                    selectLabel="type"
                    onChange={(event) => {
                      setFieldValue('type', event.target.value);
                      if (event.target.value === PROJECT_TYPES.CLIENT) {
                        setFieldValue('billingType', '');
                        setFieldValue('projectValue', 0);
                      }
                    }}
                    items={PROJECT_TYPE_ARRAY}
                    textLabel="Type"
                    renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
                    stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
                    error={Boolean(touched.type && errors.type)}
                    errorMsg={touched?.type && errors?.type}
                  />
                  {values.type && values.type === PROJECT_TYPES.CLIENT && (
                    <Select
                      id="billingType"
                      name="billingType"
                      value={values.billingType ? values.billingType : ''}
                      selectLabel="billingType"
                      onChange={(event) => {
                        setFieldValue('billingType', event.target.value);
                        if (event.target.value !== PROJECT_BILLING_TYPES.FIXED_BID) {
                          setFieldValue('projectValue', 0);
                        }
                      }}
                      items={PROJECT_BILLING_TYPE_ARRAY}
                      textLabel="Billing Type"
                      renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
                      stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
                      error={Boolean(touched.billingType && errors.billingType)}
                      errorMsg={touched?.billingType && errors?.billingType}
                    />
                  )}
                  {values.billingType &&
                    values.type === PROJECT_TYPES.CLIENT &&
                    values.billingType === PROJECT_BILLING_TYPES.FIXED_BID && (
                      <NumericInputField
                        type="text"
                        name="projectValue"
                        label="Project Value"
                        value={values?.projectValue}
                        error={Boolean(touched.projectValue && errors.projectValue)}
                        helperText={touched.projectValue && errors.projectValue}
                        onChange={(value) => {
                          setFieldValue('projectValue', value.floatValue ? value.floatValue : 0);
                        }}
                        onBlur={handleBlur}
                        thousandSeparator=","
                        prefix="$"
                        placeholder="$"
                      />
                    )}
                  <DatePicker
                    name="startDate"
                    label="Start date"
                    value={values?.startDate}
                    onChange={(event) => {
                      setFieldValue('startDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                    }}
                    error={Boolean(touched.startDate && errors.startDate)}
                    errorMsg={touched?.startDate && errors?.startDate}
                  />
                  <DatePicker
                    name="endDate"
                    label="End date"
                    value={values?.endDate}
                    onChange={(event) => {
                      setFieldValue('endDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                    }}
                    error={Boolean(touched.endDate && errors.endDate)}
                    errorMsg={touched?.endDate && errors?.endDate}
                  />
                </Grid>
                <Grid container item md={6} direction="column">
                  <TextField
                    type="text"
                    name="description"
                    value={values?.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Description"
                    height={120}
                    multiline
                    rows={4}
                  />
                  {values?.type === PROJECT_TYPES.INTERNAL && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="itSupport"
                          checked={values?.itSupport}
                          onChange={() => {
                            setFieldValue('itSupport', !values?.itSupport);
                          }}
                        />
                      }
                      label="Internal IT Support"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        name="jiraEnabled"
                        checked={values?.jiraEnabled}
                        onChange={() => {
                          setFieldValue('jiraEnabled', !values?.jiraEnabled);
                        }}
                      />
                    }
                    label="Enable Jira integration"
                  />
                  <TextField
                    type="text"
                    name="jiraKey"
                    value={values?.jiraKey}
                    error={Boolean(touched.jiraKey && errors.jiraKey)}
                    helperText={touched.jiraKey && errors.jiraKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Jira key"
                    hide={!values?.jiraEnabled}
                  />
                  <Autocomplete
                    id="jiraAssignee"
                    name="jiraAssignee"
                    options={
                      usersList?.docs?.map((user) => ({
                        id: user.id,
                        label: user.email,
                      })) ?? []
                    }
                    label="Jira Assignee"
                    onChange={(_, autoValue) =>
                      setFieldValue('jiraAssignee', autoValue?.label || null)
                    }
                    isOptionEqualToValue={(option, autoValue) =>
                      option.label === autoValue || autoValue === null
                    }
                    controlled
                    value={values?.jiraAssignee}
                    hide={!values?.jiraEnabled}
                    error={Boolean(touched.jiraAssignee && errors.jiraAssignee)}
                    errorMsg={touched?.jiraAssignee && errors?.jiraAssignee}
                    placeholder="Select Assignee"
                    sx={{
                      pb: 1,
                    }}
                  />
                  <MultiSelectAutoComplete
                    options={tools?.docs || []}
                    selectedOptions={values?.tools}
                    setSelectedOptions={setFieldValue}
                    label="Tools"
                    formikKey="tools"
                    placeholder="Select Tools"
                  />
                  <MultiSelectAutoComplete
                    options={departments || []}
                    selectedOptions={values?.departments}
                    setSelectedOptions={setFieldValue}
                    label="Departments"
                    formikKey="departments"
                    placeholder="Select Departments"
                    error={Boolean(touched.departments && errors.departments)}
                    errorMsg={touched.departments && errors.departments}
                  />
                </Grid>
              </Grid>
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditProjectsPopup;
