import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state) => state['feature/support-tickets'] || initialState;
/**
 * Selector to retrieve all support tickets from the project slice
 */
export const selectSupportTickets = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.supportTickets
);
/**
 * Selector to retrieve loading status from the support ticket slice
 */
export const selectLoader = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.loading
);
/**
 * Selector to retrieve individual support ticket from the support ticket slice
 * @returns {Object} support ticket
 */
export const selectIndividualSupportTicket = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.individualSupportTicket
);
/**
 * Selector to retrieve individual support ticket loading status from the support ticket slice
 * @returns {Boolean} loading status
 * */
export const selectIndividualSupportTicketLoader = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.individualSupportTicketLoading
);
/**
 * Selector to retrieve individual support ticket prossesing status from the support ticket slice
 * @returns {Boolean} processing status
 * */
export const selectIndividualSupportTicketProcessing = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.individualSupportTicketProcessing
);
/**
 * Selector to retrieve ticket status loading from the support ticket slice
 */
export const selectTicketStatusLoader = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.ticketStatusLoading
);
/**
 * Selector to retrieve loading status from the support ticket slice
 */
export const selectAttachmentLoader = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.attachmentLoading
);
/**
 * Selector to upload attachments from support ticket slice
 */
export const selectUploadedAttachments = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.uploadedAttachments
);
/**
 * Selector to retrieve all comments from the support ticket slice
 */
export const selectAllComments = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.allComments
);
/**
 * Selector to retrieve severity updated support ticket slice
 */
export const selectUpdatedSupportTicket = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.updatedSupportTicket
);
/**
 * Selector to retrieve comment updated support ticket slice
 */
export const selectCommentLoading = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.commentLoading
);
/**
 * Selector to retrieve relatedProjectsClient from the support ticket slice
 */
export const selectRelatedProjectsClient = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.relatedProjectsClient
);
/**
 * Selector to retrieve managingProjects from the support ticket slice
 */
export const selectManagingProjects = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.managingProjects
);
/**
 * Selector to retrieve iTSupportProjects from the support ticket slice
 */
export const selectItSupportProjects = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.iTSupportProjects
);
/**
 * Selector to get the loading status of the add support ticket action
 */
export const selectAddSupportTicketLoader = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.addSupportTicketLoading
);
/**
 * Selector to get the isInitialCommentFetching of the support ticket slice
 */
export const selectIsInitialCommentFetching = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.isInitialCommentFetching
);
/**
 * Selector to get the isCommentAdding of the support ticket slice
 */
export const selectIsCommentAdding = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.isCommentAdding
);
/**
 * Selector to get the isCommentAttachmentAdding of the support ticket slice
 */
export const selectIsCommentAttachmentAdding = createSelector(
  [selectDomain],
  (supportTicketState) => supportTicketState.isCommentAttachmentAdding
);
