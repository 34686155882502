import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { EXTERNAL_USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  selectClient,
  selectIsExternalUsersFetching,
  selectProject,
  selectExternalNotifierLoader,
  selectIsProjectFetching,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { DataGrid } from 'features/base/components';
import EXTERNAL_USER_TABLE_HEADERS from 'features/base/utils/tables/user-table/external-user-table';

/**
 * Table component to render external users of a project
 * @returns {Box}
 */
const ExternalUserTable = () => {
  const dispatch = useDispatch();
  const { projectId } = useOutletContext();
  //
  const client = useSelector(selectClient);
  const project = useSelector(selectProject);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const isExternalUsersLoading = useSelector(selectIsExternalUsersFetching);
  const isExternalNotifierLoading = useSelector(selectExternalNotifierLoader);
  //
  const isFirst = useIsInitialize(project);
  //
  useEffect(() => {
    dispatch(projectActions.getClient({ projectId }));
  }, []);
  //
  return (
    <Box>
      {isFirst ? (
        <ShimmerTable row={3} col={5} />
      ) : (
        <DataGrid
          columns={EXTERNAL_USER_TABLE_HEADERS}
          rows={
            (client &&
              project &&
              client?.users
                ?.toSorted((firstUser, secondUser) =>
                  firstUser?.userId?.firstName?.localeCompare(secondUser?.userId?.firstName)
                )
                ?.map((user) => ({
                  id: user?.userId?.id,
                  profile: {
                    name: `${user?.userId?.firstName} ${user?.userId?.lastName}`,
                    img: user?.userId?.profileImage,
                  },
                  email: `${user?.userId?.email}`,
                  companyName: client?.company ?? '-',
                  externalNotifiers: {
                    defaultChecked:
                      project?.externalNotifiers?.find(
                        (notifier) => notifier?.id === user?.userId?.id
                      ) !== undefined,
                    onChange: () => {
                      if (
                        project?.externalNotifiers?.find(
                          (notifier) => notifier?.id === user?.userId?.id
                        ) !== undefined
                      ) {
                        // if the user is already in the list, remove it
                        dispatch(
                          projectActions.changeExternalNotifiers({
                            id: projectId,
                            externalNotifiers: (() => {
                              const ids =
                                project.externalNotifiers?.map((notifier) => notifier?.id) || [];
                              const edited = ids?.filter((id) => id !== user?.userId?.id) || [];
                              return edited;
                            })(),
                          })
                        );
                      } else {
                        // if the user is not in the list, add it
                        dispatch(
                          projectActions.changeExternalNotifiers({
                            id: projectId,
                            externalNotifiers: (() => {
                              const ids =
                                project.externalNotifiers?.map((notifier) => notifier?.id) || [];
                              const edited = [...ids, user?.userId?.id];
                              return edited;
                            })(),
                          })
                        );
                      }
                    },
                    isExternalNotifierLoading,
                    disabled: isExternalNotifierLoading,
                  },
                }))) ||
            []
          }
          loading={isProjectFetching || isExternalUsersLoading || isExternalNotifierLoading}
          disableRowSelectionOnClick
          limit={EXTERNAL_USERS_PAGINATION_LIMIT}
          pageSizeOptions={[EXTERNAL_USERS_PAGINATION_LIMIT]}
          serverSidePagination={false}
          rowHeight={client?.users?.length ? 52 : 200}
        />
      )}
    </Box>
  );
};
//
export default ExternalUserTable;
