import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  designationList: {},
  designation: {},
  isDesignationFetching: false,
  search: '',
  editDesignationLoading: false,
  addDesignationLoading: false,
};
/**
 * Redux slice for the designation feature
 */
export const designationSlice = createSlice({
  name: 'feature/designation',
  initialState,
  reducers: {
    getAllDesignation: (state) => ({
      ...state,
      loading: true,
    }),
    getAllDesignationSucceeded: (state, action) => ({
      ...state,
      loading: false,
      designationList: action?.payload,
    }),
    getAllDesignationFailed: (state) => ({
      ...state,
      loading: false,
      designationList: {
        ...state.designationList,
        docs: []
      }
    }),
    addDesignation: (state) => ({
      ...state,
      addDesignationLoading: true,
    }),
    addDesignationSucceeded: (state, action) => ({
      ...state,
      addDesignationLoading: false,
      designationList: {
        ...state.designationList,
        docs: [...state.designationList.docs, ...(action?.payload?.results ?? [])],
        totalDocs: state.designationList.totalDocs + (action?.payload?.results?.length ?? 0),
      },
    }),
    addDesignationFailed: (state) => ({
      ...state,
      addDesignationLoading: false,
    }),
    editDesignation: (state) => ({
      ...state,
      editDesignationLoading: true,
    }),
    editDesignationSucceeded: (state, action) => ({
      ...state,
      editDesignationLoading: false,
      designationList: {
        ...state.designationList,
        docs: state.designationList.docs.map((designation) => {
          if (designation.id === action?.payload?.id) {
            return action?.payload;
          }
          return designation;
        }),
      },
    }),
    editDesignationFailed: (state) => ({
      ...state,
      editDesignationLoading: false,
    }),
    getOneDesignation: (state) => ({
      ...state,
      isDesignationFetching: true,
    }),
    getOneDesignationSucceeded: (state, action) => ({
      ...state,
      isDesignationFetching: false,
      designation: action?.payload,
    }),
    getOneDesignationFailed: (state) => ({
      ...state,
      isDesignationFetching: false,
    }),
    setSearch: (state, action) => ({
      ...state,
      search: action?.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: designationActions } = designationSlice;
