import * as yup from 'yup';
/**
 * Function for add skill group form validation
 */
export const addSkillGroupValidation = yup.object().shape({
  name: yup.string().required('Please enter a valid skill group name'),
  skills: yup.array().min(1, 'Please select at least one skill'),
});
/**
 * Function for edit skill group form validation
 */
export const editSkillGroupValidation = yup.object().shape({
  name: yup.string().required('Please enter a valid skill group name'),
  skills: yup.array().min(1, 'Please select at least one skill'),
});
