import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
//
import { clientActions } from 'features/clients/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectClientById,
  selectAllProjectsList,
  selectUploadedImage,
  selectEditClientLoader,
  selectFileUploadLoader,
} from 'features/clients/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { TextField, Popup, FileUpload } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  getAllowedExtensionsString,
  verifyExtensions,
} from 'features/base/helpers/file';
import {
  CLIENT_LOGO_ALLOWED_FILE_TYPES,
  CLIENT_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import PhoneInput from 'features/base/components/phone-input';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import editClientFormValidation from 'features/clients/validations/edit-client-validation';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import { removeUnchanged } from 'features/base/helpers/object';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} clientId - id of the client to edit
 * @returns {Popup}
 */
const EditClientsPopup = ({ editModalOpen, setEditModalOpen, clientId }) => {
  const clientToEditId = clientId;
  const clientToEdit = useSelector((state) => selectClientById(state, clientToEditId));
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedImage);
  const allProjects = useSelector(selectAllProjectsList);
  const loading = useSelector(selectEditClientLoader);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  //
  const dispatch = useDispatch();
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(clientActions.clearLogo());
    setFileTypeError('');
  };
  //
  const getInputLabel = () => {
    if (uploadedLogo) {
      return uploadedLogo.name;
    }
    if (clientToEdit?.logo) {
      return clientToEdit?.logo.name;
    }
    return 'Choose logo';
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), CLIENT_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      formData.append('file', logo[0]);
      if (logo.length > 0) {
        dispatch(clientActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    company: clientToEdit?.company,
    brNumber: clientToEdit?.brNumber,
    address: clientToEdit?.address,
    phoneNumber: clientToEdit?.phoneNumber,
    projectIds: clientToEdit?.projects
      ?.filter((record) => record?.dataStatus === DATA_STATUS_TYPES.ACTIVE)
      .map((record) => record?.projectId),
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.CLIENT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit client"
        mediumSize="660px"
        height="700px"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={editClientFormValidation}
          onSubmit={(values) => {
            let clientData = values;
            clientData = removeUnchanged(initialValues, clientData);
            if (clientData?.projectIds) {
              clientData.projectIds = clientData?.projectIds?.map((project) => project?.id);
            }
            if (uploadedLogo) {
              clientData.logo = { ...uploadedLogo, url: encodeURI(uploadedLogo?.url) };
            }
            dispatch(clientActions.editClient({ id: clientToEditId, ...clientData }));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Logo"
                acceptedTypes={CLIENT_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  CLIENT_LOGO_ALLOWED_EXTENSIONS
                )}`}
                inputLabel={getInputLabel()}
                loading={fileUploadLoading}
              />
              <TextField
                type="text"
                name="company"
                value={values.company}
                error={Boolean(touched?.company && errors?.company)}
                helperText={touched?.company && errors?.company}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Company"
              />
              <PhoneInput
                name="phoneNumber"
                id="phoneNumber"
                country="lk"
                inputStyle={{
                  fontSize: '16px',
                  width: '100%',
                  height: '40px',
                  borderRadius: '0px',
                  lineHeight: '20px',
                }}
                buttonStyle={{ backgroundColor: COLORS.WHITE }}
                fullWidth="true"
                containerStyle={{ width: '100%' }}
                onBlur={handleBlur}
                countryCodeEditable="true"
                placeholder=""
                label="Phone number"
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                errorMsg={touched?.phoneNumber && errors?.phoneNumber}
              />
              <TextField
                type="text"
                name="brNumber"
                value={values.brNumber}
                error={Boolean(touched?.brNumber && errors?.brNumber)}
                helperText={touched?.brNumber && errors?.brNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Business registration number"
              />
              <TextField
                type="text"
                name="address"
                value={values.address}
                error={Boolean(touched?.address && errors?.address)}
                helperText={touched?.address && errors?.address}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Address"
                fullWidth
                my={2}
                height={120}
                multiline
                rows={4}
              />
              <MultiSelectAutoComplete
                id="projectIds"
                name="projectIds"
                options={allProjects}
                selectedOptions={values.projectIds}
                setSelectedOptions={setFieldValue}
                label="Projects"
                formikKey="projectIds"
                placeholder="Select project"
                error={Boolean(touched.projectIds && errors.projectIds)}
                errorMsg={touched.projectIds && errors.projectIds}
                onClose={() => setFieldTouched('projectIds', true)}
                removeConfirmationPopup
                confirmationMessage="Do you really want to remove the project from the client?"
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={
                  loading ||
                  fileUploadLoading ||
                  (JSON.stringify(initialValues) === JSON.stringify(values) && !uploadedLogo)
                }
                rightIcon={loading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditClientsPopup;
