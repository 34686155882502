/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { designationActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a designation
 * @param {Object} payload
 */
export function* addDesignationGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_DESIGNATIONS, payload);
    yield put(
      designationActions.addDesignationSucceeded({
        results: response,
        designations: payload.designations,
      })
    );
  } catch (error) {
    yield put(designationActions.addDesignationFailed(error?.message));
  }
}
/**
 * Generator function for getting all designations
 * @param {Object} payload
 */
export function* getDesignationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DESIGNATIONS, payload);
    yield put(designationActions.getAllDesignationSucceeded(response));
  } catch (error) {
    yield put(designationActions.getAllDesignationFailed(error?.message));
  }
}
/**
 * Generator function for editing a designation
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editDesignationGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_DESIGNATION, payload);
    yield put(designationActions.editDesignationSucceeded(response));
  } catch (error) {
    yield put(designationActions.editDesignationFailed(error?.message));
  }
}
/**
 * Generator function for getting a designation by id
 * @param {Object} payload
 */
export function* getOneDesignationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ONE_DESIGNATION, { id: payload.designationId });
    yield put(designationActions.getOneDesignationSucceeded(response));
  } catch (error) {
    yield put(designationActions.getOneDesignationFailed(error?.message));
  }
}
/**
 * Redux saga that triggers above generated functions
 */
export function* designationSaga() {
  yield takeLatest(designationActions.addDesignation.type, addDesignationGenerator);
  yield takeLatest(designationActions.getAllDesignation.type, getDesignationsGenerator);
  yield takeLatest(designationActions.editDesignation.type, editDesignationGenerator);
  yield takeLatest(designationActions.getOneDesignation.type, getOneDesignationGenerator);
}
//
export default designationSaga;
