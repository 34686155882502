import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//
import { getIdToken, getRefreshToken } from 'features/base/helpers/token';
import useIsAuthenticated from 'features/base/hooks/use-is-authenticated';
import { authActions } from 'features/base/auth/slice';
import ROUTES from '../constants/routes';

/**
 * Custom hook to check the authentication status
 * @returns {isAuthenticated}
 */
const useAuthorization = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const idToken = getIdToken();
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  const refreshToken = getRefreshToken();
  useEffect(() => {
    if (!isAuthenticated(refreshToken)) {
      // If id token is present, do a keycloak logout which results in a navigate to the welcome page
      if (idToken) {
        dispatch(authActions.keycloakLogout());
        // Else, navigate to welcome page
      } else {
        navigate(ROUTES.WELCOME);
      }
    }
  }, [isAuthenticated, navigate, refreshToken]);
  //
  return { isAuthenticated };
};
//
export default useAuthorization;
