import { DriveFileRenameOutline } from '@mui/icons-material';
import { Avatar, Grid, IconButton, Switch, Typography } from '@mui/material';
//
import { ENVIRONMENT } from 'config';
import { capitalizeFirstLetter } from '../../../helpers/strings';
/**
 * View project table columns - basic details
 */
export const PROJECTS_TABLE_COLUMNS_BASIC = [
  {
    field: 'project',
    headerName: 'Project',
    width: 270,
    valueGetter: (params) => params.row.project.projectName,
    renderCell: (params) => {
      const { projectName, projectLogo } = params.row.project;
      return (
        <Grid container item flexDirection="row" alignItems="center">
          {!projectLogo?.length > 0 ? (
            <Avatar
              sx={{
                height: '40px',
                width: '40px',
              }}
            >
              {projectName?.charAt(0)}
            </Avatar>
          ) : (
            <img
              src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${projectLogo[0]?.url}`}
              alt="logo"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          )}
          <Typography
            noWrap
            sx={{
              maxWidth: '20ch',
              ml: 1,
            }}
          >
            {projectName}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    valueGetter: (params) => params.row.description.value,
    renderCell: (params) => {
      const { value } = params.row.description;
      return (
        <Typography
          sx={{
            maxWidth: '20ch',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 80,
    valueGetter: (params) => params.row.type.value,
    renderCell: (params) => {
      const { value } = params.row.type;
      return <Typography variant="body1">{capitalizeFirstLetter(value)}</Typography>;
    },
  },
  {
    field: 'billingType',
    headerName: 'Billing type',
    width: 260,
    valueGetter: (params) => params.row.billingType.value,
    renderCell: (params) => {
      const { value } = params.row.billingType;
      return (
        <Typography
          variant="body1"
          sx={{
            maxWidth: '25ch',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {capitalizeFirstLetter(value)}
        </Typography>
      );
    },
  },
  {
    field: 'projectValue',
    headerName: 'Project value',
    width: 120,
    valueGetter: (params) => params.row.projectValue.value,
    renderCell: (params) => {
      const { value } = params.row.projectValue;
      return (
        <Typography variant="body1" sx={{ width: '12ch' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    width: 100,
    type: 'date',
    valueGetter: (params) => new Date(params.row.startDate.date),
    renderCell: (params) => {
      const { value } = params.row.startDate;
      return (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    width: 100,
    type: 'date',
    valueGetter: (params) => new Date(params.row.endDate.date),
    renderCell: (params) => {
      const { value } = params.row.endDate;
      return (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
];
/**
 * View project table columns - action columns
 */
export const PROJECTS_TABLE_ACTION_COLUMNS = [
  {
    field: 'status',
    headerName: 'Status',
    width: 75,
    valueGetter: (params) => params.row.status.checked,
    renderCell: (params) => {
      const { onChange, checked, isLoading } = params.row.status;
      return <Switch checked={checked} color="success" readOnly={isLoading} onClick={onChange} />;
    },
  },
  {
    field: 'ongoing',
    headerName: 'Ongoing Status',
    width: 150,
    valueGetter: (params) => params.row.ongoing.checked,
    renderCell: (params) => {
      const { onChange, checked, isLoading } = params.row.ongoing;
      return (
        <Switch
          checked={checked}
          color="success"
          readOnly={isLoading}
          onClick={onChange}
          sx={{ ml: '30%' }}
        />
      );
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 75,
    sortable: false,
    renderCell: (params) => {
      const { handleEdit, isLoading } = params.row.action;
      return (
        <IconButton onClick={handleEdit} readOnly={isLoading}>
          <DriveFileRenameOutline />
        </IconButton>
      );
    },
  },
];
