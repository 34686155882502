import moment from 'moment';
import { useSelector } from 'react-redux';
//
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { selectCreateTeamProject } from 'features/projects/selectors';

/**
 * Function that handles the available users checkbox clicks
 */
const useOnAvailableUserCheckboxClick = () => {
  const project = useSelector(selectCreateTeamProject);
  //
  const onAvailableUserCheckboxClick = (
    user,
    multipleAvailableUsers,
    setMultipleAvailableUsers
  ) => {
    const found = multipleAvailableUsers?.find((mUser) => mUser?.userId?.id === user?.id);
    if (found) {
      setMultipleAvailableUsers(
        multipleAvailableUsers.filter((mUser) => mUser?.userId?.id !== user?.id)
      );
    } else {
      setMultipleAvailableUsers([
        ...multipleAvailableUsers,
        {
          id: user?.id,
          userId: { ...user },
          startDate: moment(project?.startDate).format(ISO_WITHOUT_TIME),
          endDate: moment(project?.endDate).format(ISO_WITHOUT_TIME),
          allocatedPercentage: '10',
        },
      ]);
    }
  };
  //
  return { onAvailableUserCheckboxClick };
};
//
export default useOnAvailableUserCheckboxClick;
