import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Sync as SyncIcon, Download as DownloadIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
//
import { Button } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import createFormattedString from 'features/base/helpers/param-formatter';
import { reportActions } from 'features/reports/slice';
import { downloadCSVFile } from 'features/base/helpers/file';
import { MONTHS } from 'features/base/constants/date-formatting';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import TOAST_TYPES from 'features/base/constants/toast-types';

/**
 * Function that returns a component that display the information of a selected project
 * @param {Object} selectedProjectDetails - A ist of all avalable users
 * @param {Function} initilialize - Function to clear allocations and initialize
 * @param {Object} params - Objecting containing the data to generate the query string
 * @param {Boolean} loading - Boolean indicating the loading status
 * @returns {Grid}
 */
const ProjectInfo = ({
  selectedProjectDetails,
  initilialize,
  params,
  loading,
  allocationsByViewPeriod,
  selectedUsers,
}) => {
  const notification = useSelector(selectNotification);
  //
  const dispatch = useDispatch();
  //
  const handleSync = () => {
    initilialize();
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getAllocationReportAllocations({ query: `${formattedParamString}&runAggregation=true` })
    );
  };
  //
  const handleExport = () => {
    if (!allocationsByViewPeriod?.length) {
      dispatch(notificationActions.setNotification({
        message: 'No data to export',
        type: ERROR_TYPES.INFO,
      }))
      return;
    }
    //
    const data = allocationsByViewPeriod?.map((item) => {
      const { projectId, userId, id, monthlyAllocations, ...rest } = item;
      // Generating the required structure for the CSV file
      const formatted = {};
      Object.keys(rest).forEach((key) => {
        const { allocatedHours, cogsoh, revenue } = rest?.[key] ?? '';
        const splitted = key?.split('-');
        const year = splitted?.[0];
        const monthNumber = parseInt(splitted?.[1], 10);
        const monthName = MONTHS[monthNumber - 1]?.slice(0, 3);
        const hoursKey = `Hours (${year} ${monthName})`;
        const cogsohKey = `COGS+OH (${year} ${monthName})`;
        const revenueKey = `Revenue (${year} ${monthName})`;
        formatted[hoursKey] = allocatedHours ?? '-';
        formatted[cogsohKey] = cogsoh ?? '-';
        formatted[revenueKey] = revenue ?? '-';
      });
      //
      return {
        Project: projectId?.name ?? '-',
        Profile:
          `${userId?.firstName} ${userId?.lastName} (${userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name})` ??
          '-',
        ...formatted,
      };
    });
    let filename = 'allocation_report';
    if (selectedUsers?.length === 1) {
      filename = `${filename}_${selectedUsers?.[0]?.label}`;
    }
    if (selectedProjectDetails) {
      filename = `${filename}_${selectedProjectDetails?.name}`;
    }
    downloadCSVFile(Object.keys(data?.[0]), data, `${filename}.csv`);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  return (
    <Grid container sx={{ pb: 1 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          mb: { xs: '15px', md: 0 },
          mr: '24px',
          ml: '10px',
        }}
      >
        {selectedProjectDetails?.name
          ? `${selectedProjectDetails?.name} - Project Allocation Report`
          : 'Project Allocation Report'}
      </Typography>
      {selectedProjectDetails?.billingType && (
        <Grid
          item
          sx={{
            bgcolor: COLORS.CELESTIAL_BLUE,
            display: 'flex',
            alignItems: 'center',
            p: '1px 10px',
            mr: '5px',
          }}
        >
          <Typography sx={{ color: COLORS.DODGER_BLUE, fontWeight: 600 }} variant="body2">
            {billingTypeFormatter(selectedProjectDetails?.billingType)}
          </Typography>
        </Grid>
      )}
      {selectedProjectDetails?.billingType === PROJECT_BILLING_TYPES.FIXED_BID && (
        <Grid
          item
          sx={{
            bgcolor: COLORS.LAVENDER_GRAY,
            minHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            p: '6px 10px',
          }}
        >
          <Typography sx={{ color: COLORS.BLUE_MAGENTA, fontWeight: 600 }} variant="body2">
            {`Project Value: ${getDollarFormattedValue(selectedProjectDetails?.projectValue)}`}
          </Typography>
        </Grid>
      )}
      <Grid item sx={{ ml: 'auto', pr: 2 }}>
        <Button onClick={handleExport} disabled={loading}>
          <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
          Export
        </Button>
      </Grid>
      <Grid item sx={{ pr: 2 }}>
        <Button onClick={handleSync} disabled={loading}>
          <SyncIcon sx={{ marginLeft: '0.25rem' }} />
          Sync
        </Button>
      </Grid>
    </Grid>
  );
};
//
export default ProjectInfo;
