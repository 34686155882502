/**
 * Available opex status
 * @type {{ACTIVE: string, INACTIVE: string}}
 */
export const OPEX_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'ACTIVE',
};
/**
 * Array for opex status
 */
export const OPEX_STATUS_ARRAY = [
  { key: 1, value: OPEX_STATUS.ACTIVE },
  { key: 2, value: OPEX_STATUS.INACTIVE },
];
