/**
 * Defines the opex pagination limit
 */
export const OPEX_PAGINATION_LIMIT = 10;
/**
 * Defines the skill pagination limit
 */
export const SKILL_PAGINATION_LIMIT = 10;
/**
 * Defines the support ticket pagination limit
 */
export const SUPPORT_TICKET_PAGINATION_LIMIT = 10;
/**
 * Defines the work area pagination limit
 */
export const WORK_AREA_PAGINATION_LIMIT = 10;
/**
 * Defines the project pagination limit
 */
export const PROJECT_PAGINATION_LIMIT = 10;
/**
 * Defines the create team pagination limit
 */
export const CREATE_TEAM_PAGINATION_LIMIT = 10;
/**
 * Defines the users pagination limit
 */
export const USERS_PAGINATION_LIMIT = 8;
/**
 * Defines the tools pagination limit
 */
export const TOOLS_PAGINATION_LIMIT = 10;
/**
 * Define the departments pagination limit
 */
export const DEPARTMENTS_PAGINATION_LIMIT = 10;
/**
 * Define the designation pagination limit
 */
export const DESIGNATION_PAGINATION_LIMIT = 10;
/**
 * Defines the clients pagination limit
 */
export const CLIENTS_PAGINATION_LIMIT = 10;
/**
 * Defines the clients users pagination limit
 */
export const CLIENTS_USERS_PAGINATION_LIMIT = 8;
/**
 * Defines the links pagination limit
 */
export const LINK_PAGINATION_LIMIT = 10;
/**
 * Defines the default data grid page size
 */
export const DEFAULT_DATA_GRID_PAGE_SIZE = 10;
/**
 * Defines the external users pagination limit
 */
export const EXTERNAL_USERS_PAGINATION_LIMIT = 10;
/**
 * Defines the work allocation pagination limit
 */
export const WORK_ALLOCATION_PAGINATION_LIMIT = 10;
/**
 * Defines the project documents pagination limit
 */
export const PROJECT_DOCUMENT_PAGINATION_LIMIT = 10;
/**
 * Defines the user pagination limit for salary report
 */
export const SALARY_REPORT_PAGINATION_LIMIT = 10;
/**
 * Defines the arsenal pagination limit
 */
export const ARSENAL_PAGINATION_LIMIT = 9;
/**
 * Defines the JIRA tickets pagination limit
 */
export const JIRA_TICKET_PAGINATION_LIMIT = 10;
/**
 * Defines the Benefits pagination limit
 */
export const BENEFITS_PAGINATION_LIMIT = 10;
/**
 * Defines the Vendors pagination limit
 */
export const VENDORS_PAGINATION_LIMIT = 10;
