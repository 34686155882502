/**
 * Defines the API paths for tools
 */
const API = {
  GET_TOOLS: {
    path: '/tools?:query',
    method: 'GET',
  },
  POST_TOOL: {
    path: '/tools',
    method: 'POST',
  },
  PATCH_TOOL: {
    path: '/tools/:id',
    method: 'PATCH',
  },
  DELETE_TOOL: {
    path: '/tools/:id',
    method: 'DELETE',
  },
  POST_LOGO: {
    path: '/tools/upload',
    method: 'POST',
  },
  GET_DEPARTMENTS: {
    path: '/departments',
    method: 'GET',
  },
};
//
export default API;
