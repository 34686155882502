import { FormHelperText } from '@mui/material';
//
import { NumericInputField } from 'features/base/components';
/**
 *  Function that defines the Amount Field for edit current salary
 * @returns {Popup}
 */
const AmountField = ({
  formikBag: { values, setFieldValue, handleBlur, touched, errors },
  setHide,
}) => (
  <div className="amountField">
    <NumericInputField
      type="text"
      name="amount"
      value={values.amount}
      error={Boolean(touched.amount && errors.amount)}
      helperText={
        <FormHelperText sx={{ fontSize: 10.5, pt: 0, mt: 0 }}>
          {' '}
          {touched.amount && errors.amount}{' '}
        </FormHelperText>
      }
      onChange={(v) => {
        setFieldValue('amount', v.floatValue);
        setHide(true);
      }}
      onBlur={handleBlur}
      thousandSeparator=","
      prefix="$"
      placeholder="$"
    />
  </div>
);
//
export default AmountField;
