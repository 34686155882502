/**
 * Defines the common error types used in the application
 */
const ERROR_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};
//
export default ERROR_TYPES;
