import { Alert, useTheme, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
//
import { roleActions } from 'features/roles/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectEditRoleLoader } from 'features/roles/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import {
  buildPermissionMatrix,
  handleMatrixClick,
  onSubmitFormatter,
} from 'features/base/helpers/permissions';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { editRoleFormValidation } from 'features/roles/validation/role-validations';
import Matrix from 'features/base/components/matrix';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Function that defines the popup form for adding a new role
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {object} roleDetails - object containing the role details
 * @returns {Popup}
 */
const EditRolePopup = ({ editModalOpen, setEditModalOpen, roleDetails }) => {
  const dispatch = useDispatch();
  //
  const [initialRolePermissions, setInitialRolePermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  //
  const roleToEdit = roleDetails;
  const loading = useSelector(selectEditRoleLoader);
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const handleClick = (domainIndex, actionIndex) => {
    // Creating a deep copy of the permissions object, since it prevents referencial updates in the handle click
    const rolePermissionsDeepCopy = JSON.parse(JSON.stringify(rolePermissions));
    const newPermissions = handleMatrixClick(rolePermissionsDeepCopy, domainIndex, actionIndex);
    //
    setRolePermissions(newPermissions);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    const matrix = buildPermissionMatrix(roleToEdit?.permissions);
    setInitialRolePermissions(matrix);
    setRolePermissions(matrix);
  }, [roleToEdit]);
  //
  const initialValues = {
    id: roleToEdit?.id,
    name: roleToEdit?.name,
  };
  //
  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ROLE,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Update Role"
        mediumSize="720px"
        fullWidth={isXsOrSm}
        fullScreen={isXsOrSm}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={editRoleFormValidation}
          onSubmit={(value) => {
            const result = onSubmitFormatter(rolePermissions);
            //
            const data = {
              id: value.id,
              name: value.name,
              permissions: result,
            };
            //
            dispatch(roleActions.editRole({ ...data }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Name"
              />
              <Matrix rows={rolePermissions} handleClick={handleClick} sx={{ fontWeight: 600 }} />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={
                  (JSON.stringify(initialRolePermissions) === JSON.stringify(rolePermissions) &&
                    initialValues.name === values.name) ||
                  loading
                }
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditRolePopup;
