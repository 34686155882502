import { Alert, Checkbox, FormControlLabel, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
//
import { benefitsActions } from 'features/benefits/slice';
import {
  selectAddBenefitsLoader,
  selectFileUploadLoader,
  selectUploadedAttachments,
  selectVendorsMultiStepForm,
} from 'features/benefits/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import {
  TextField,
  DragAndDropZone,
  DatePicker,
  Autocomplete,
  Select,
} from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import benefitValidation from 'features/benefits/validations/benefit-validation';
import {
  BENEFITS_ATTACHMENTS_ALLOWED_EXTENSIONS,
  BENEFITS_ATTACHMENTS_ALLOWED_FILE_TYPES,
  BENEFITS_ATTACHMENTS_MAX_SIZE,
} from 'features/base/constants/file-upload';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { processFiles } from 'features/base/helpers/file';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { BENEFIT_CAMPAIGN_TYPES } from 'features/base/constants/benefit-types';
import ROUTES from 'features/base/constants/routes';
/**
 * @returns {ReactNode} AddBenefits
 */
const AddBenefitsPopup = ({
  stepProgress,
  setStepProgress,
  setActiveStep,
  formResults,
  setFormResults,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddBenefitsLoader);
  const uploadedAttachments = useSelector(selectUploadedAttachments);
  const attachmentLoader = useSelector(selectFileUploadLoader);
  const vendors = useSelector(selectVendorsMultiStepForm);
  //
  const handleOnDeleteFile = (fileToRemove) => {
    dispatch(
      benefitsActions.setAttachments(
        uploadedAttachments?.filter(
          (uploadedAttachment) => uploadedAttachment?.name !== fileToRemove?.name
        )
      )
    );
  };
  //
  const handleOnAddFiles = (filesToAdd) => {
    if (filesToAdd?.length > 0) {
      const formData = processFiles(filesToAdd);
      dispatch(benefitsActions.uploadAttachments({ files: formData }));
    }
  };
  //
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '70%', lg: '40%' },
        margin: 'auto',
      }}
    >
      <Formik
        initialValues={{
          title: '',
          description: '',
          vendorId: '',
          expiryDate: null,
          showExpiryDate: false,
          link: '',
          isEmailEnabled: false,
          campaignType: '',
        }}
        validationSchema={benefitValidation}
        onSubmit={(values) => {
          const data = {
            ...values,
            attachments: uploadedAttachments || [],
          };
          if (values?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN) {
            setStepProgress(
              stepProgress.map((step) => {
                if (step.step === 0) {
                  return { ...step, completed: true };
                }
                return step;
              })
            );
          }
          //
          setFormResults(
            formResults.map((formResult) => {
              if (formResult.step === 0) {
                return { ...formResult, data };
              }
              if (formResult.step === 1) {
                return { ...formResult, data: {} };
              }
              return formResult;
            })
          );
          // if the campaign type is non-promotion campaign, skip the promotion step
          if (values?.campaignType === BENEFIT_CAMPAIGN_TYPES.NON_PROMOTION_CAMPAIGN) {
            setStepProgress(
              stepProgress.map((step) => {
                if (step.step === 1) {
                  return { ...step, completed: true, disabled: true };
                }
                if (step.step === 0) {
                  return { ...step, completed: true };
                }
                return step;
              })
            );
            setActiveStep(2);
            return;
          }
          setActiveStep(1);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}

            <Select
              id="campaignType"
              name="campaignType"
              value={values.campaignType}
              selectLabel="campaignType"
              onChange={(event) => {
                setFieldValue('campaignType', event.target.value);
              }}
              items={Object.keys(BENEFIT_CAMPAIGN_TYPES).map((key) => ({
                key,
                value: BENEFIT_CAMPAIGN_TYPES[key],
              }))}
              textLabel="Campaign Type"
              renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
              stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
              error={Boolean(touched.campaignType && errors.campaignType)}
              errorMsg={touched?.campaignType && errors?.campaignType}
            />

            <TextField
              type="text"
              name="title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Title"
            />
            <TextField
              type="text"
              name="description"
              value={values.description}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Description"
              height={50}
              multiline
              rows={4}
            />
            <DatePicker
              name="expiryDate"
              label="Expiry date (Optional)"
              value={values.expiryDate}
              onChange={(event) => {
                setFieldValue('expiryDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
              }}
              onKeyDown={(e) => e.preventDefault()}
              error={Boolean(touched.expiryDate && errors.expiryDate)}
              errorMsg={touched?.expiryDate && errors?.expiryDate}
              sx={{ pb: 1 }}
              placeholder="Select date"
              minDate={moment().toDate()}
            />
            {values?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN && (
              <Autocomplete
                id="vendorId"
                name="vendorId"
                disableClearable
                options={
                  vendors?.map((vendor) => ({
                    id: vendor.id,
                    label: vendor.name,
                  })) ?? []
                }
                label={`Vendor (Required)`}
                onChange={(_, newValue) => {
                  setFieldValue('vendorId', newValue.id);
                }}
                controlled
                multiple={false}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id && option.label === value.label
                }
                getOptionLabel={(option) => option?.label || ''}
                error={Boolean(touched.vendorId && errors.vendorId)}
                errorMsg={touched?.vendorId && errors?.vendorId}
                disabledMsg="Please select a vendor"
                onClose={() => setFieldTouched('vendorId', true)}
                value={(() => {
                  const vendor = vendors?.find((v) => v.id === values.vendorId);
                  return vendor ? { id: vendor.id, label: vendor.name } : null;
                })()}
                sx={{
                  pb: 1,
                }}
              />
            )}
            <FormControlLabel
              label="Show expiry date"
              control={
                <Checkbox
                  onChange={(event) => {
                    setFieldValue('showExpiryDate', event.target.checked);
                  }}
                  value={values.showExpiryDate}
                  name="showExpiryDate"
                />
              }
              sx={{
                mb: 1,
              }}
            />
            <DragAndDropZone
              label="Upload attachments"
              allowedExtensions={BENEFITS_ATTACHMENTS_ALLOWED_EXTENSIONS}
              allowedMimetypes={BENEFITS_ATTACHMENTS_ALLOWED_FILE_TYPES}
              maxSize={BENEFITS_ATTACHMENTS_MAX_SIZE}
              loading={attachmentLoader}
              disabled={loading}
              onAdd={handleOnAddFiles}
              onDelete={handleOnDeleteFile}
              uploadedFiles={uploadedAttachments}
            />
            <TextField
              type="text"
              name="link"
              value={values.link}
              error={Boolean(touched?.link && errors?.link)}
              helperText={touched?.link && errors?.link}
              onBlur={handleBlur}
              onChange={handleChange}
              label="Add link (Optional)"
              placeholder={'https://drive.goog.com'}
              fullWidth
            />
            <FormControlLabel
              label="Notify users via email"
              control={
                <Checkbox
                  onChange={(event) => {
                    setFieldValue('isEmailEnabled', event.target.checked);
                  }}
                  value={values.isEmailEnabled}
                  name="isEmailEnabled"
                />
              }
            />
            <ButtonGrid
              leftButtonText="Cancel"
              rightButtonText={'Continue'}
              leftOnClick={() => navigate(ROUTES.MANAGE_BENEFITS)}
              submitDisabled={loading || errors?.length > 0 || attachmentLoader}
              rightIcon={loading ? loaderIcon : null}
            />
            <Box sx={{ height: 50 }} />
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default AddBenefitsPopup;
