import * as yup from 'yup';
//
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import { SUPPORT_TICKET_TYPES } from 'features/base/constants/support-ticket-types';

/**
 * Validation for attaching a support ticket behalf of a client
 */
const attachSupportTicketValidation = yup.object().shape({
  project: yup.string().required('Please select a project'),
  client: yup.string().required('Please select a client'),
  clientUser: yup.string().nullable().required('Please select a client user'),
  jiraIssueId: yup.string().nullable().optional('Please enter a valid Jira Issue ID'),
  title: yup
    .string()
    .required('Please Enter valid title')
    .max(50, ({ max }) => `Title cannot exceed ${max} characters`),
  type: yup.string().oneOf([...Object.values(SUPPORT_TICKET_TYPES)], 'Please select a ticket type'),
  description: yup
    .string()
    .required('Please Enter valid description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(1500, ({ max }) => `Description cannot exceed ${max} characters`),
  severity: yup.string().oneOf([...Object.values(SEVERITY_TYPES)], 'Please select a severity type'),
});
//
export default attachSupportTicketValidation;
