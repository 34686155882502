import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
//
import {
  selectCreateTeamAvailableUsers,
  selectCreateTeamProject,
  selectSelectedTeamMembers,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import createFormattedString from 'features/base/helpers/param-formatter';

/**
 * Function that handles the removal of a single user from a project
 */
const useOnRemoveIconClick = () => {
  const dispatch = useDispatch();
  //
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  const availableUsers = useSelector(selectCreateTeamAvailableUsers);
  const project = useSelector(selectCreateTeamProject);
  //
  const onRemoveIconClick = (user) => {
    const updatedSelectedTeamUsers = selectedUsers?.docs?.filter(
      (selectedUser) => user?.userId?.id !== selectedUser?.userId?.id
    );
    dispatch(projectActions.setCreateTeamSelectedUsers(updatedSelectedTeamUsers));
    //
    const alreadyExist = availableUsers?.docs?.find((availableUser) => availableUser?.id === user?.userId?.id);
    if (!alreadyExist) {
      // Get the removed user from the backend
      const formattedParamString = createFormattedString({
        userIds: user?.userId?.id,
        aggregated: true,
        runAggregation: true,
        utilizationStartDate: moment(project?.startDate).format('YYYY-MM-DD'),
        utilizationEndDate: moment(project?.endDate).format('YYYY-MM-DD'),
      });
      dispatch(
        projectActions.getCreateTeamRemovedTeamMembers({
          data: {
            id: project?.id,
            query: formattedParamString,
          },
          currentAllocations: [
            { id: user?.userId?.id, allocatedPercentage: user?.allocatedPercentage },
          ],
        })
      );
    } else {
      const updatedAvailableUsers = availableUsers?.docs?.map((availableUser) => {
        if (availableUser?.id === user?.userId?.id) {
          return {
            ...availableUser,
            disabled: false,
          };
        }
        return availableUser;
      });
      dispatch(projectActions.setCreateTeamAvailableUsers(updatedAvailableUsers));
    }
  };
  //
  return { onRemoveIconClick };
};
//
export default useOnRemoveIconClick;
