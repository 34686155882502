import { useEffect } from 'react';
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  Typography,
  DialogTitle,
  DialogContent,
  Alert,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import uniqolor from 'uniqolor';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
//
import { selectVerifyEmployeeLoader, selectVerifyEmployeeData } from 'features/vendors/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { vendorActions } from 'features/vendors/slice';
import COLORS from 'features/base/constants/colors';
import vendorPortalFormValidation from 'features/vendors/validations/vendor-portal-validation';
import { TextField, Button } from 'features/base/components';
import { ENVIRONMENT } from 'config';
/**
 * Function that
 * @returns {FC}
 */
const VendorPortal = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectVerifyEmployeeLoader);
  const verifyEmployeeData = useSelector(selectVerifyEmployeeData);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [notification]);
  //
  const findCoverImage = (attachments) => {
    if (!attachments) {
      return null;
    }
    const fileExts = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
    // if the attachment.name has any of the file extensions, return the url
    const coverImage = attachments.find((attachment) =>
      fileExts.some((ext) => attachment.url.toLowerCase().includes(ext))
    );
    if (coverImage) {
      return `${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${coverImage.url}`;
    }
    return null;
  };

  if (!searchParams.get('vendorId') || !searchParams.get('hashId')) {
    // unauthorized access
    return (
      <Dialog open>
        <DialogTitle>
          <Typography variant="h6">Unauthorized Access</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You are not authorized to access this page. Please contact the administrator for more
            information.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.href = '/';
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  //
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '4rem' }}
        bgcolor={COLORS.DARK_NAVY_BLUE}
      >
        <img src="/images/logo.png" alt="delete" />
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} sx={{ height: 'calc(100vh - 4rem)' }}>
          <Grid item lg={3} md={4} xs={12} bgcolor={COLORS.SKY_BLUE}>
            <Box sx={{ justifyContent: 'left', alignItems: 'left', p: 4 }}>
              <Typography fontWeight={900} variant="h6" sx={{ mb: 2 }} color={COLORS.NAVY_BLUE}>
                Verify Employee
              </Typography>

              <Typography color={COLORS.GRAYISH_PURPLE} variant="subtitle1" sx={{ mb: 4 }}>
                Enter the employee information or scan the QR code to verify their identity.{' '}
              </Typography>

              <Formik
                initialValues={{
                  nic: '',
                  employeeId: '',
                }}
                validationSchema={vendorPortalFormValidation}
                onSubmit={(values) => {
                  dispatch(
                    vendorActions.verifyEmployee({
                      nicSuffix: values.nic,
                      employeeId: values.employeeId,
                      vendorHashId: searchParams.get('hashId'),
                      vendorId: searchParams.get('vendorId'),
                    })
                  );
                }}
              >
                {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit} className="form">
                    {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                      <Alert sx={{ mb: 3 }} severity={notification?.type}>
                        {notification?.message}
                      </Alert>
                    )}

                    <TextField
                      type="text"
                      name="employeeId"
                      value={values.employeeId}
                      error={Boolean(touched?.employeeId && errors?.employeeId)}
                      helperText={touched?.employeeId && errors?.employeeId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Employee ID"
                      placeholder="123456"
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      type="text"
                      name="nic"
                      value={values.nic}
                      error={Boolean(touched?.nic && errors?.nic)}
                      helperText={touched?.nic && errors?.nic}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="NIC (Last 4 digits)"
                      placeholder="1234"
                      sx={{ mb: 2 }}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={loading}
                      sx={{ mt: 3 }}
                    >
                      Verify Employee
                    </Button>
                  </form>
                )}
              </Formik>
              <Box sx={{ mt: 3 }} />
              {verifyEmployeeData?.user && (
                <>
                  <Typography fontWeight={900} variant="h6" sx={{ mb: 2 }} color={COLORS.NAVY_BLUE}>
                    Employee Details
                  </Typography>
                  <Avatar
                    alt="Profile Image"
                    src={verifyEmployeeData?.user?.profileImage}
                    sx={{ width: 56, height: 56, mb: 2 }}
                    imgProps={{ referrerPolicy: 'no-referrer' }}
                  />
                  <Typography
                    fontWeight={600}
                    variant="body1"
                    sx={{ mb: 2 }}
                    color={COLORS.NAVY_BLUE}
                  >
                    Full Name : {verifyEmployeeData?.user?.fullName}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item lg={9} md={8} xs={12}>
            <Box sx={{ justifyContent: 'left', alignItems: 'left', p: 4 }}>
              <Typography mb={3} mt={3} variant="h6">
                All Promotions for Vendor: {verifyEmployeeData?.vendor?.name}
              </Typography>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                  {!loading &&
                    verifyEmployeeData?.benefits?.map((benefit) => (
                      <Grid item xs={4} p={0} key={benefit?.id}>
                        <Card
                          sx={{
                            maxWidth: 300,
                            minHeight: 400,
                            padding: 2,
                            background: findCoverImage(benefit.attachments)
                              ? `url(${findCoverImage(benefit.attachments)})`
                              : uniqolor(`${benefit?.title}-${benefit?.description}`).color,
                            color: '#333',
                            overflow: 'hidden',
                          }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              color={COLORS.LIGHT_GRAY}
                            >
                              {benefit?.title}
                            </Typography>
                            <Typography variant="body2" color={COLORS.LIGHT_GRAY}>
                              {benefit?.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  {loading && (
                    <Grid item xs={12}>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        mt={4}
                      >
                        <CircularProgress />
                      </Box>
                    </Grid>
                  )}
                  {!loading && verifyEmployeeData?.benefits?.length === 0 && (
                    <Typography variant="body1" color={COLORS.NAVY_BLUE} mt={4}>
                      No promotions available for this employee.
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
//
export default VendorPortal;
