import { Box, Grid, Typography } from '@mui/material';
//
import { DatePicker } from 'features/base/components';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import { USER_TYPES } from 'features/base/constants/user-types';
/**
 * Function that returns a component that displays the set of filters for the allocation report
 * @param {List<Object>} userList - A ist of all avalable users
 * @param {List<Object>} projectList - A list of all avalable projects
 * @param {List<Object>} selectedUsers - A list of the selected users
 * @param {List<Object>} selectedProjects - A list of the selected projects
 * @param {Function} handleSelectedUsersChange - Function that triggers when users filter is updated
 * @param {Function} handleSelectedProjectsChange - Function that triggers when projects filter is updated
 * @param {String} startDate - The start date that is set
 * @param {Function} handleStartDateChange - Function that triggers when the start date is changed
 * @param {String} endDate - The end date that is set
 * @param {Function} handleEndDateChange - Function that triggers when the end date is changed
 * @param {Boolean} loading - Boolean indicating the loading status
 * @returns {Grid}
 */
const Filters = ({
  userList,
  projectList,
  selectedUsers,
  selectedProjects,
  handleSelectedUsersChange,
  handleSelectedProjectsChange,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  loading,
}) => (
  <Grid container spacing={2} sx={{ mt: '1.5rem' }}>
    <Grid item xs={12} lg={3} md={3}>
      <Box>
        <MultiSelectAutoComplete
          id="user"
          name="user"
          options={[
            { id: 'All', label: 'All' },
            ...(userList
              ?.filter((user) => user.type !== USER_TYPES.CLIENT)
              .map((userRecord) => ({
                id: userRecord?.id,
                label: userRecord?.email,
              })) ?? []),
          ]}
          selectedOptions={selectedUsers}
          setSelectedOptions={handleSelectedUsersChange}
          label="User"
          isOptionEqualToValue={(option, autoValue) =>
            option.label === autoValue.label || autoValue === null
          }
          displayAttribute="label"
          displayCards={false}
          placeholder="Select users"
          disabled={loading}
        />
      </Box>
    </Grid>
    <Grid item xs={12} lg={3} md={3}>
      <Box>
        <MultiSelectAutoComplete
          id="project"
          name="project"
          options={[
            { id: 'All', label: 'All' },
            ...(projectList?.map((projectRecord) => ({
              id: projectRecord?.id,
              label: projectRecord?.name,
            })) ?? []),
          ]}
          selectedOptions={selectedProjects}
          setSelectedOptions={handleSelectedProjectsChange}
          label="Project"
          isOptionEqualToValue={(option, autoValue) =>
            option.label === autoValue.label || autoValue === null
          }
          displayAttribute="label"
          displayCards={false}
          placeholder="Select projects"
          disabled={loading}
        />
      </Box>
    </Grid>
    <Grid
      item
      display={{ lg: 'flex', md: 'flex' }}
      flexDirection="row"
      xs={6}
      lg={3}
      md={3}
      justifyContent={{ lg: 'flex-end' }}
    >
      <Box>
        <Typography fontSize="16px" className="field-label">
          From
        </Typography>
        <DatePicker value={startDate} onChange={handleStartDateChange} size="small" />
      </Box>
    </Grid>
    <Grid
      item
      display={{ lg: 'flex', md: 'flex' }}
      flexDirection="row"
      xs={6}
      lg={3}
      md={3}
      justifyContent="flex-end"
    >
      <Box>
        <Typography fontSize="16px" className="field-label">
          To
        </Typography>
        <DatePicker value={endDate} onChange={handleEndDateChange} size="small" />
      </Box>
    </Grid>
  </Grid>
);
//
export default Filters;
