/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import moment from 'moment';
import { toast } from 'react-toastify';
//
import { projectActions } from 'features/projects/slice';
import {
  selectUpdateWorkAllocation,
  selectProject,
  selectIsProjectFetching,
  selectIsWorkAllocationFetching,
} from 'features/projects/selectors';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import { MONTHS } from 'features/base/constants/date-formatting';
import ProjectDetails from './components/project-details';
import UpdateWorkAllocationsHeader from './components/update-work-allocations-header';
import UpdateWorkAllocationsTable from './components/update-work-allocations-table';
import AllocationStepper from '../stepper';
import TeamMembers from './components/team-members';
import { getWorkingDaysByMonth } from './helpers/allocation-dates';

/**
 * Component that defines the update view of work allocations of users for a project
 * @returns MUI Grid
 */
const UpdateWorkAllocations = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  //
  const projectDetails = useSelector(selectProject);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const { workAllocation: workAllocationByUser, hasUnsavedChanges } = useSelector(
    selectUpdateWorkAllocation
  );
  const isWorkAllocationFetching = useSelector(selectIsWorkAllocationFetching);
  const notification = useSelector(selectNotification);
  //
  const [mainProjectPeriod, setMainProjectPeriod] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [rows, setRows] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [errors, setErrors] = useState([]);
  //
  useEffect(() => {
    dispatch(projectActions.getOneProject({ projectId }));
    dispatch(
      projectActions.getWorkAllocation({ query: `pagination=false&projectIds=${projectId}` })
    );
  }, [projectId]);
  //
  useEffect(() => {
    if (selectedUser && projectDetails?.startDate && projectDetails?.endDate) {
      dispatch(
        projectActions.getWorkAllocationByUserId({
          query: `userIds=${selectedUser.id}&pagination=false&aggregated=true&startDate=${moment(
            projectDetails?.startDate
          ).format('YYYY-MM-DD')}&endDate=${moment(projectDetails?.endDate).format('YYYY-MM-DD')}`,
          userId: selectedUser.id,
        })
      );
    }
  }, [selectedUser, projectDetails.startDate, projectDetails.endDate]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.resetProjectState());
    },
    []
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [notification]);
  //
  useEffect(() => {
    /* construct a new array of objects with the following structure
    using the start and end date of the project
    [{
      year: "2021",
      month: "January",
    }] */
    if (projectDetails?.startDate && projectDetails?.endDate) {
      const start = moment(projectDetails.startDate);
      const end = moment(projectDetails.endDate);
      const months = [];
      while (start.isSameOrBefore(end, 'month')) {
        months.push({
          year: start.format(`YYYY`),
          month: start.format(`MMMM`),
        });
        start.add(1, 'months');
      }
      setMainProjectPeriod(months);
    }
  }, [projectDetails]);
  //
  const computeTableRowsAndPercentage = () => {
    /* 
    returns an object with the following structure
    {
      rows: [
        { id: 1, ProjectName: 'Project 1', January: '30', February: '30', March: '30', April: '30', May: '30', June: '30' },
      ],
      percentage: 80, // percentage of total hours allocated
      available: 20 // percentage of total hours available
    }
    */
    const totalHours = {};
    const trows = workAllocationByUser?.docs?.map((entity) => {
      const monthlyAllocationLookup = {};
      entity?.monthlyAllocations?.forEach((monthlyAllocation) => {
        monthlyAllocationLookup[
          `${MONTHS[monthlyAllocation.month - 1]}${monthlyAllocation?.year}`
        ] = monthlyAllocation;
      });
      const row = {
        id: entity.id,
        // bold the project name if it is the main project
        ProjectName: {
          projectId: entity?.projectId?.id,
          value: entity?.projectId?.name,
        },
      };
      mainProjectPeriod?.forEach((period) => {
        const { year, month } = period;
        const monthlyAllocation = monthlyAllocationLookup[`${month}${year}`];
        totalHours[`${month}${year}`] = {
          id: 'totalPerMonth',
          allocationHours:
            (totalHours[`${month}${year}`]?.allocationHours ?? 0) +
            (monthlyAllocation?.allocatedHours ?? 0),
        };
        //
        if (monthlyAllocation) {
          if (entity?.projectId?.id === projectDetails.id) {
            const workingDaysByMonth = getWorkingDaysByMonth(
              entity?.allocationStartDate,
              entity?.allocationEndDate
            );
            row[`${month}${year}`] = {
              id: 'currentProject',
              allocationHours: monthlyAllocation?.allocatedHours,
              onChange: (e) => {
                const { value } = e.target;
                // Get entity.allocation object array and modify the hours value of the object that matches the year and month
                const updatedMonthlyAllocations = entity?.monthlyAllocations?.map(
                  (workAllocation) => {
                    /** If the project is a retainer, staff augmentation or support and maintenance project,
                     * hours entered for each month will be reflected to all months
                     * */
                    if (
                      projectDetails?.billingType === PROJECT_BILLING_TYPES.STAFF_AUGMENTATION ||
                      projectDetails?.billingType === PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE
                    ) {
                      return {
                        ...workAllocation,
                        allocatedHours: Number.isNaN(parseInt(value, 10))
                          ? ''
                          : parseInt(value, 10),
                      };
                    }
                    // If the project is a fixed bid or time and material project, allow the user to enter hours for each month
                    if (
                      workAllocation.year.toString() === year &&
                      MONTHS[workAllocation.month - 1] === month
                    ) {
                      return {
                        ...workAllocation,
                        allocatedHours: Number.isNaN(parseInt(value, 10))
                          ? ''
                          : parseInt(value, 10),
                      };
                    }
                    return workAllocation;
                  }
                );
                dispatch(projectActions.changeHasUnsavedChangesInUpdateWorkAllocation(true));
                // Update the entity.allocation object array in the store
                dispatch(
                  projectActions.patchProjectWorkAllocationInStore({
                    projectId,
                    userId: entity?.userId?.id,
                    updatedMonthlyAllocations,
                  })
                );
                // Ensure that the total hours entered divisible by 8 and at least one month has greater than 0 hours
                const isDivisibleByEight = updatedMonthlyAllocations?.some(
                  (workAllocation) =>
                    workAllocation.allocatedHours % 8 !== 0 || workAllocation.allocatedHours === ''
                );
                const everyMonthEqualToZero = updatedMonthlyAllocations?.every(
                  (workAllocation) => workAllocation.allocatedHours === 0
                );
                const hasNegatives = updatedMonthlyAllocations?.some(
                  (workAllocation) => workAllocation.allocatedHours < 0
                );
                const err = [];
                isDivisibleByEight && err.push('Hours must be divisible by 8');
                everyMonthEqualToZero &&
                  err.push('At least one month must have greater than 0 hours');
                hasNegatives &&
                  err.push('Hours must be greater than 0. Negative values are not allowed');
                setErrors(err);
              },
              availability: (
                workingDaysByMonth[`${month}${year}`] *
                // Divide by 4 since to get weekly capacity, then divide by 5 (working days per week) to get daily capacity
                ((selectedUser?.capacity ?? 160) / 4 / 5)
              ).toFixed(2),
            };
          } else {
            row[`${month}${year}`] = {
              allocationHours: monthlyAllocation?.allocatedHours,
            };
          }
        } else {
          row[`${month}${year}`] = {
            allocationHours: '-',
          };
        }
      });
      return row;
    });
    // Finally add the total hours row at the bottom
    trows.push({
      id: 'total',
      ProjectName: { value: 'Total Hours' },
      ...totalHours,
    });
    return {
      rows: trows,
      percentage:
        ((Object.values(totalHours)?.reduce(
          (acc, value) => acc + (value?.allocationHours ?? 0),
          0
        ) || 0) /
          ((selectedUser?.capacity || 160) * (mainProjectPeriod?.length || 1))) *
        100,
    };
  };
  //
  useEffect(() => {
    if (workAllocationByUser?.docs?.length > 0) {
      const obj = computeTableRowsAndPercentage();
      setRows(obj.rows);
      setPercentage(obj.percentage);
    }
  }, [workAllocationByUser, selectedUser, projectDetails, projectId, mainProjectPeriod, dispatch]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.onUpdateAllocationsUnmount());
    },
    []
  );
  //
  const handleSave = () => {
    /** Get the work allocation object array from the store that matches the selected user and project
     *  and call the PATCH API to update the work allocation
     * */
    const workAllocation = workAllocationByUser?.docs?.find(
      (doc) => doc?.userId?.id === selectedUser?.id && doc?.projectId?.id === projectDetails?.id
    );
    if (workAllocation?.monthlyAllocations?.length > 0 && hasUnsavedChanges && selectedUser) {
      dispatch(
        projectActions.patchProjectWorkAllocation({
          allocation: workAllocation?.monthlyAllocations?.map((monthlyAllocation) => ({
            year: monthlyAllocation.year.toString(),
            month: MONTHS[monthlyAllocation.month - 1],
            hourlyRate: monthlyAllocation?.hourlyRate,
            hours: monthlyAllocation.allocatedHours,
          })),
          isAllocatedPercentageLocked: true,
          allocationId: workAllocation?.id,
        })
      );
      dispatch(projectActions.changeHasUnsavedChangesInUpdateWorkAllocation(false));
    }
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <ProjectDetails />
      {isWorkAllocationFetching ? (
        <Grid
          item
          sx={{
            width: '100%',
            mb: 4,
            mt: 4,
          }}
        >
          <ShimmerThumbnail height={75} />
        </Grid>
      ) : (
        <AllocationStepper activeStep={1} />
      )}
      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {isProjectFetching ? <Skeleton width={600} /> : `Update team monthly allocations`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={4}>
        <TeamMembers
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          errors={errors}
          setErrors={setErrors}
          handleSave={handleSave}
        />
        <Grid item xs={12} md={9}>
          <UpdateWorkAllocationsHeader
            selectedUser={selectedUser}
            allocatedPercentage={percentage}
          />
          <UpdateWorkAllocationsTable
            selectedUser={selectedUser}
            mainProjectPeriod={mainProjectPeriod}
            rows={rows}
            errors={errors}
            handleSave={handleSave}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
//
export default UpdateWorkAllocations;
