/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { opexActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a opex
 * @param {Object} payload
 */
export function* addOpexGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_OPEX, payload);
    yield put(opexActions.addOpexSucceeded(response));
  } catch (error) {
    yield put(opexActions.addOpexFailed(error?.message));
  }
}
/**
 * Generator function for getting all opexes
 * @param {Object} payload
 */
export function* getOpexesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_OPEX, payload);
    yield put(opexActions.getAllOpexSucceeded(response));
  } catch (error) {
    yield put(opexActions.getAllOpexFailed(error?.message));
  }
}
/**
 * Generator function for editing a opex
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editOpexesGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_OPEX, payload);
    yield put(opexActions.editOpexSucceeded(response));
  } catch (error) {
    yield put(opexActions.editOpexFailed(error?.message));
  }
}
/**
 * Generator function for deleting a opex
 * @param {Object} payload
 * @param {Object} payload.id
 */
export function* deleteOpexesGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_OPEX, payload);
    yield put(opexActions.deleteOpexSucceeded(response));
  } catch (error) {
    yield put(opexActions.deleteOpexFailed(error?.message));
  }
}
/**
 * Generator function for getting a opex by id
 * @param {Object} payload
 */
export function* getOneOpexGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ONE_OPEX, { id: payload.opexId });
    yield put(opexActions.getOneOpexSucceeded(response));
  } catch (error) {
    yield put(opexActions.getOneOpexFailed(error?.message));
  }
}
/**
 * Redux saga that triggers above generated functions
 */
export function* opexSaga() {
  yield takeLatest(opexActions.addOpex.type, addOpexGenerator);
  yield takeLatest(opexActions.getAllOpex.type, getOpexesGenerator);
  yield takeLatest(opexActions.editOpex.type, editOpexesGenerator);
  yield takeLatest(opexActions.getOneOpex.type, getOneOpexGenerator);
}
//
export default opexSaga;
