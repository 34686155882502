import { Box, IconButton } from '@mui/material';
import keyIcon from 'features/base/assets/images/png/key.png';
/**
 * View user role table columns - basic details
 */
const USERS_TABLE_ROLE_COLUMN = {
  field: 'role',
  headerName: 'Role',
  headerClassName: 'super-app-theme--header',
  width: 120,
  valueGetter: (params) => params.row.role.value,
  renderCell: (params) => {
    const {
      value,
      userRoleViewPermissionsVerified,
      userRoleUpdatePermissionsVerified,
      handleUpdatePermissionsOnClick,
    } = params.row.role;
    return userRoleViewPermissionsVerified ? (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {userRoleUpdatePermissionsVerified && (
          <IconButton onClick={handleUpdatePermissionsOnClick}>
            <img
              style={{ paddingRight: '0.3rem', marginTop: '-0.3rem' }}
              src={keyIcon}
              alt="key-icon"
            />
          </IconButton>
        )}
        {value}
      </Box>
    ) : undefined;
  },
};
//
export default USERS_TABLE_ROLE_COLUMN;
