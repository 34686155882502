import * as yup from 'yup';
//
import { AMOUNT } from 'features/base/constants/amount-types';

/**
 * Function for update current Salary validation
 */
const updateCurrentSalaryValidation = yup.object().shape({
  amount: yup
    .number()
    .required('Please enter valid amount')
    .typeError('Amount must be a number')
    .min(AMOUNT, `Amount must be greater than or equal to ${AMOUNT}`),
  startDate: yup
    .date()
    .required('Please select a salary start date')
    .nullable()
    .typeError('Should be in format DD-MM-YYYY'),
  endDate: yup.date(),
});
//
export default updateCurrentSalaryValidation;
