import * as yup from 'yup';
//
import { isValidNumber } from 'features/base/helpers/phone-numbers';
/**
 * Function for edit client form validation
 */
const editClientFormValidation = yup.object().shape({
  company: yup
    .string()
    .required('Company is required')
    .max(50, ({ max }) => `Company name must be at maximum of ${max} characters`),
  brNumber: yup
    .string()
    .required('Business registration number is required')
    .max(15, ({ max }) => `Business registration number must be at maximum of ${max} characters`),
  address: yup
    .string()
    .required('Address is required')
    .min(10, ({ min }) => `Address must be at least ${min} characters`)
    .max(100, ({ max }) => `Address must be at maximum of ${max} characters`),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
});
//
export default editClientFormValidation;
