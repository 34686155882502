import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import moment from 'moment';
//
import { userActions } from 'features/users/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectWorkAreas, selectAddUserLoading } from 'features/users/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { Autocomplete, Select, TextField, Popup } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { USER_LEVELS, USER_TYPES } from 'features/base/constants/user-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import userFormValidation from 'features/users/validation/user-validation';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import DatePicker from 'features/base/components/date-picker';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddUsersPopup = ({ addModalOpen, setAddModalOpen }) => {
  const notification = useSelector(selectNotification);
  const allWorkAreas = useSelector(selectWorkAreas);
  const loading = useSelector(selectAddUserLoading);
  //
  const dispatch = useDispatch();
  //
  const [userType, setUserType] = useState('');
  const [workAreaId, setWorkAreaId] = useState('');
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    setUserType('');
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.USER,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add new user"
        mediumSize="660px"
        infoMessage="Only internal, contract and intern users can be added here"
      >
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            project: '',
            type: '',
            workArea: '',
            capacity: 160,
            company: '',
            businessRegistrationNumber: '',
            address: '',
            phoneNumber: '',
            joinedDate: '',
            level: '',
          }}
          validationSchema={userFormValidation}
          onSubmit={(values) => {
            const { project, ...dataObject } = values;
            delete dataObject.workArea;
            delete dataObject.capacity;
            // firstName, lastName, email and type is extracted from values and passed as payload if the type is not client
            dispatch(
              userActions.addUser({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                type: values.type,
                capacity: values.capacity,
                workAreaId,
                joinedDate: values.joinedDate,
                level:
                  userType === USER_TYPES.INTERNAL || userType === USER_TYPES.CONTRACT
                    ? values.level
                    : undefined,
              })
            );
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Select
                name="type"
                id="type"
                value={values.type}
                selectLabel="type"
                onChange={(event) => {
                  setFieldValue('type', event.target.value);
                  setUserType(event.target.value);
                  setFieldValue('level', '');
                }}
                items={[
                  { key: '1', value: USER_TYPES.CONTRACT },
                  { key: '2', value: USER_TYPES.INTERNAL },
                  { key: '3', value: USER_TYPES.INTERN },
                ].map((item) => ({ key: item?.key, value: item?.value }))}
                textLabel="Type"
                error={Boolean(touched.type && errors.type)}
                errorMsg={touched?.type && errors?.type}
                placeholder="Select type"
                renderValue={(value) => capitalizeFirstLetter(value)}
                stringFormat={(value) => capitalizeFirstLetter(value)}
              />
              <TextField
                type="name"
                name="firstName"
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="First name"
              />
              <TextField
                type="name"
                name="lastName"
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Last name"
              />
              <TextField
                type="email"
                name="email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Email"
              />
              <Select
                name="level"
                id="level"
                value={values.level}
                selectLabel="level"
                onChange={(event) => {
                  setFieldValue('level', event.target.value);
                }}
                items={
                  userType === USER_TYPES.INTERN
                    ? [{ key: 1, value: USER_LEVELS.LEVEL_0 }]
                    : Object.values(USER_LEVELS).map((item, index) => ({
                        key: index,
                        value: item,
                      }))
                }
                textLabel="Level"
                error={Boolean(touched.level && errors.level)}
                errorMsg={touched?.level && errors?.level}
                placeholder="Select User Level"
                hide={
                  userType !== USER_TYPES.INTERNAL &&
                  userType !== USER_TYPES.CONTRACT &&
                  userType !== USER_TYPES.INTERN
                }
              />
              <Autocomplete
                name="workArea"
                id="workArea"
                options={allWorkAreas.map((workArea) => ({
                  id: workArea?.id,
                  label: workArea?.name,
                }))}
                selectLabel="workArea"
                onChange={(_, newValue) => {
                  setFieldValue('workArea', newValue?.label || null);
                  setWorkAreaId(newValue?.id);
                }}
                label="Workarea"
                isOptionEqualToValue={(option, autoValue) =>
                  option.label === autoValue || autoValue === null
                }
                controlled
                value={values.workArea}
                stringFormat={capitalizeFirstLetter}
                error={Boolean(touched.workArea && errors.workArea)}
                errorMsg={touched?.workArea && errors?.workArea}
                sx={{ pb: 1 }}
                placeholder="Select workarea"
              />
              <TextField
                type="text"
                name="capacity"
                value={values.capacity}
                error={Boolean(touched?.capacity && errors?.capacity)}
                helperText={touched?.capacity && errors?.capacity}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Capacity(hrs)"
                hide={
                  userType !== USER_TYPES.CONTRACT &&
                  userType !== USER_TYPES.INTERN &&
                  userType !== USER_TYPES.INTERNAL
                }
              />
              <DatePicker
                name="joinedDate"
                label="Date of Appointment"
                value={values.joinedDate}
                onChange={(event) => {
                  setFieldValue('joinedDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                }}
                onKeyDown={(e) => e.preventDefault()}
                error={Boolean(touched.joinedDate && errors.joinedDate)}
                errorMsg={touched?.joinedDate && errors?.joinedDate}
                sx={{ pb: 2 }}
                placeholder="Select Date of Appointment"
                hide={
                  userType !== USER_TYPES.CONTRACT &&
                  userType !== USER_TYPES.INTERN &&
                  userType !== USER_TYPES.INTERNAL
                }
                maxDate={moment().toDate()}
                minDate={moment().year(2015)}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddUsersPopup;
