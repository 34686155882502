/**
 * Defines the API paths for profile
 */
const API = {
  GET_USER_BY_ID: {
    path: '/users/:id',
    method: 'GET',
  },
  GET_DEPARTMENT_DESIGNATIONS: {
    path: '/departments/department-designations',
    method: 'GET',
  },
  PATCH_USER: {
    path: '/users/:userId',
    method: 'PATCH',
  },
  GET_ALLOCATIONS_BY_USER_ID: {
    path: '/allocations?:query',
    method: 'GET',
  },
  POST_USER_SKILL: {
    path: '/users/:userId/skills',
    method: 'POST',
  },
  GET_USER_SKILLS: {
    path: '/users/:userId/skills',
    method: 'GET',
  },
  PATCH_USER_SKILLS: {
    path: '/users/:userId/skills',
    method: 'PATCH',
  },
  DELETE_USER_SKILL: {
    path: '/users/:userId/skills',
    method: 'DELETE',
  },
  GET_SKILL_GROUPS: {
    path: '/skill-groups?:query',
    method: 'GET',
  },
  GET_CLIENT_BY_ID: {
    path: '/clients/:id',
    method: 'GET',
  },
};
//
export default API;
