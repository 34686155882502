import { useSelector } from 'react-redux';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectIsProjectFetching,
  selectIsWorkAllocationFetching,
  selectUpdateWorkAllocation,
  selectWorkAllocations,
} from 'features/projects/selectors';
/**
 * Function that renders the team members section of the update allocations page
 */
const TeamMembers = ({ selectedUser, setSelectedUser, errors, handleSave, setErrors }) => {
  const isWorkAllocationFetching = useSelector(selectIsWorkAllocationFetching);
  const team = useSelector(selectWorkAllocations);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const { loading } = useSelector(selectUpdateWorkAllocation);
  //
  return (
    <Grid item xs={12} md={3}>
      <Stack spacing={2}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Team members</Typography>
        {isWorkAllocationFetching && <ShimmerTable row={4} col={1} size="sm" />}
        {/* profile card, left side profile pic and right name and role */}
        {!isWorkAllocationFetching &&
          team?.docs?.map((entity) => (
            <Box
              key={entity?.id}
              style={{
                border:
                  selectedUser?.id === entity?.userId?.id
                    ? '3px solid #3f51b5'
                    : '0px solid #e0e0e0',
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderRadius: 1,
                bgcolor: 'background.paper',
                boxShadow: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isWorkAllocationFetching || isProjectFetching || loading) {
                  return;
                }
                setSelectedUser((prev) => {
                  if (prev?.id === entity?.userId?.id) {
                    return null;
                  }
                  if (prev !== null && errors.length === 0) {
                    handleSave();
                  }
                  setErrors([]);
                  return entity?.userId;
                });
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  {entity?.userId?.profileImage ? (
                    <img
                      style={{
                        width: '2.5rem',
                        height: '2.5rem',
                        borderRadius: '50%',
                      }}
                      src={entity?.userId?.profileImage}
                      alt="profile pic"
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem' }}>
                      {entity?.userId?.firstName?.charAt(0)}
                    </Avatar>
                  )}
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {`${entity?.userId?.firstName} ${entity?.userId?.lastName}`}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
                      ?.designationId?.name
                      ? entity?.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
                          ?.designationId?.name
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Stack>
    </Grid>
  );
};
//
export default TeamMembers;
