import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
//
import { signInActions } from 'features/sign-in/slice';
import { internalOnboardingSliceActions } from 'features/internal-onboarding/slice';
import { profileActions } from 'features/profile/slice';
import { logoutUrl } from 'features/base/constants/authentication';
import { getFormattedPermissions } from '../helpers/permissions';

export const initialState = {
  tokens: {},
  user: {},
  loading: false,
  permissions: {},
  isSessionTimedOut: false,
};
/**
 * Redux slice for the authentication
 */
export const authSlice = createSlice({
  name: 'feature/base-auth',
  initialState,
  reducers: {
    keycloakLogout(state) {
      // Check that the id token is present before redirecting the user to the logout url
      if (state?.tokens?.idToken) {
        window.location.replace(`${logoutUrl}&id_token_hint=${state?.tokens?.idToken}`);
      }
    },
    logout: (state) => ({
      ...state,
      loading: true,
    }),
    logoutSucceeded: (state) => ({
      ...initialState,
        tokens: {
          idToken: state.tokens.idToken,
        }
    }),
    logoutFailed: (state) => ({
      ...initialState,
        tokens: {
          idToken: state.tokens.idToken,
        }
    }),
    refreshToken: (state) => ({
      ...state,
      loading: true,
    }),
    refreshTokenSucceeded: (state, action) => ({
      ...state,
      loading: false,
      tokens: {
        ...state.tokens,
        accessToken: action?.payload?.tokens?.accessToken,
        refreshToken: action?.payload?.tokens?.refreshToken,
        idToken: action?.payload?.tokens?.idToken
      },
      isSessionTimedOut: false,
      permissions: getFormattedPermissions(jwt_decode(action.payload.tokens.accessToken)),
    }),
    refreshTokenFailed: (state) => ({
      ...state,
      isSessionTimedOut: true,
    }),
    setSessionTimeOut: (state, action) => ({
      ...state,
      isSessionTimedOut: action?.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, () => initialState)
      .addCase(signInActions.signInSucceeded, (state, action) => ({
        ...state,
        tokens: action?.payload?.tokens,
        user: action?.payload?.user,
        permissions: action?.payload?.tokens?.accessToken
          ? getFormattedPermissions(jwt_decode(action.payload.tokens.accessToken))
          : {},
      }))
      .addCase(signInActions.signInFailed, (state) => ({
        ...state,
        tokens: {},
        user: {},
        permissions: {},
      }))
      .addCase(internalOnboardingSliceActions.internalOnboardingSuccess, (state) => ({
        ...state,
        user: {
          ...state.user,
          onboarding: true,
        },
      }))
      .addCase(profileActions.updateUserSucceeded, (state, action) =>
        state.user.id === action?.payload?.id
          ? {
              ...state,
              user: action?.payload,
            }
          : state
      )
      .addCase(signInActions.openOAuthWindow, () => initialState);
  },
});
//
export const { actions: authActions } = authSlice;
