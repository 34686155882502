import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
//
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import { Popup } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import ArsenalInfoPage from '../arsenal-info-page';

/**
 * Function that defines the popup for displaying the info of an arsenal item
 * @prop {Boolean} open - Boolean to show/hide the popup
 * @prop {Function} setOpen - Function to set the popup state
 * @prop {Object} arsenalItem - Arsenal item containing the data to be displayed in the popup
 * @returns {Popup}
 */
const ArsenalInfoPopup = ({ open, setOpen, arsenalItem }) => {
  const notification = useSelector(selectNotification);
  //
  const dispatch = useDispatch();
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (open && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    //
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ARSENAL,
          action: PERMISSION_ACTIONS.READ,
        },
      ]}
      hide
    >
      <Popup
        open={open}
        onClose={handleOnClose}
        title={arsenalItem?.name}
        mediumSize="660px"
        height="700px"
        data-testid="arsenal-info-popup"
      >
        <ArsenalInfoPage arsenalItem={arsenalItem} />
      </Popup>
    </PermissionWrapper>
  );
};
//
export default ArsenalInfoPopup;
