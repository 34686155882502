/**
 * Function that formats params to a formatted string
 * @param {Object} - Object containing key value pairs with the values to build the string
 * @param {String} - A search value to append
 * @param {String} - Starting string
 * @param {Boolean} - If true, doesnt include keys where the value is 'All' to the result
 * @returns URL formatted string
 */
const createFormattedString = (params, search = '', initial = '', removeAll = true) => {
  let formattedParamString = initial;
  //
  Object.entries(params).forEach(([entry, value], index) => {
    const shouldAdd = removeAll ? value !== 'All' && value : value;
    //
    if (shouldAdd) {
      formattedParamString += `${entry}=${value}`;
      if (index < Object.entries(params).length - 1) {
        formattedParamString += '&';
      }
    }
  });
  //
  if (search !== '') {
    formattedParamString += `&search=${search}`;
  }
  // Remove any empty spaces before sending the result
  return formattedParamString.trim();
};
//
export default createFormattedString;
