import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
//
import { Button } from 'features/base/components';
import { projectActions } from 'features/projects/slice';
import { userActions } from 'features/users/slice';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import INTERNAL_USER_TYPES from 'features/base/constants/internal-user-types';
import './index.scss';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';

/**
 * Button grid and title component
 * @prop {function} setAddModalOpen - function to set the popup state
 * @prop {function} setAddSalaryModalOpen - function to set the popup state
 * @prop {function} bulkUserDataProcessModalOpen - function to set the popup state
 * @prop {function} setBulkUserDataProcessModalOpen - function to set the popup state
 * @returns {Box}
 */
const ButtonSet = ({ setAddModalOpen, setAddSalaryModalOpen, setBulkUserDataProcessModalOpen }) => {
  const dispatch = useDispatch();
  //
  const handleOnClickOnAddUser = () => {
    dispatch(projectActions.getAllProjects({ query: 'pagination=false' }));
    setAddModalOpen(true);
  };
  //
  const handleOnClickOnAddSalary = () => {
    dispatch(userActions.getSalaryUsers({ query: 'pagination=false&sortBy=firstName:asc' }));
    setAddSalaryModalOpen(true);
  };
  //
  const handleOnClickOnBulkUserDataProcess = () => {
    dispatch(
      userActions.getBulkUserDataUsers({
        query: `pagination=false&sortBy=firstName:asc&dataStatus=${
          DATA_STATUS_TYPES.ACTIVE
        }&types=${INTERNAL_USER_TYPES.join(',')}`,
      })
    );
    setBulkUserDataProcessModalOpen(true);
  };
  //
  return (
    <div className="btn-set">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Grid
          item
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold !important' }}>
            Manage users
          </Typography>
        </Grid>
        <Grid item sx={{ flexDirection: { xs: 'column', sm: 'row' } }} columnSpacing={2}>
          <Box mt={1}>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.SALARY,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              <Button
                sx={{
                  width: { xs: '100%', md: '160px' },
                  mb: { xs: '16px', md: '0px' },
                  mr: { xs: 0, md: '24px' },
                  whiteSpace: 'nowrap',
                }}
                onClick={() => handleOnClickOnAddSalary()}
              >
                Add salary <AddIcon />
              </Button>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.USER,
                  action: PERMISSION_ACTIONS.UPDATE,
                },
              ]}
              hide
            >
              <Button
                sx={{
                  width: { xs: '100%', md: '160px' },
                  mb: { xs: '16px', md: '0px' },
                  mr: { xs: 0, md: '24px' },
                  whiteSpace: 'nowrap',
                }}
                onClick={() => handleOnClickOnBulkUserDataProcess()}
              >
                Bulk update <AddIcon />
              </Button>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.USER,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              <Button
                sx={{
                  width: { xs: '100%', md: '160px' },
                  whiteSpace: 'nowrap',
                }}
                onClick={() => handleOnClickOnAddUser()}
              >
                Add user <AddIcon />
              </Button>
            </PermissionWrapper>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
//
export default ButtonSet;
