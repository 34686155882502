import { Alert, Grid } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { Button, DataGrid, Popup } from 'features/base/components';
import { AMOUNT_TYPES } from 'features/base/constants/amount-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import SALARY_HISTORY_TABLE_HEADERS from 'features/base/utils/tables/salary-history-table';
import {
  selectGetSalaryHistoryLoading,
  selectSalaryHistory,
  selectUpdateCurrentSalaryLoading,
} from 'features/users/selectors';
import { userActions } from 'features/users/slice';
import updateCurrentSalaryValidation from 'features/users/validation/update-current-salary.validation';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import AddSalariesPopup from './components/add-salaries';
import './index.scss';

/**
 *  Function that defines the popup for view salary history and edit current active salary of a particular user
 * @prop {boolean} viewSalaryModalOpen - boolean to show/hide the popup
 * @prop {function} setViewSalaryModalOpen - function to set the popup state
 * @returns {Popup}
 */
const ViewSalaryHistoryPopup = ({
  viewSalaryModalOpen,
  setViewSalaryModalOpen,
  salaryHistoryUser,
}) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const salaryHistoryLoading = useSelector(selectGetSalaryHistoryLoading);
  const updateCurrentSalaryLoading = useSelector(selectUpdateCurrentSalaryLoading);
  const salaryHistory = useSelector(selectSalaryHistory);
  //
  const [pageController, setPageController] = useState({ page: 1, rowsPerPage: 4 });
  //
  const [hide, setHide] = useState(false);
  const [addSalariesModalOpen, setAddSalariesModalOpen] = useState(false);
  //
  const salaries = [...salaryHistory];
  //
  const isFirst = useIsInitialize(salaryHistoryUser);
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleOnClose = () => {
    setViewSalaryModalOpen(false);
    dispatch(notificationActions.resetNotification());
    setPageController({ ...pageController, page: 1 });
    setHide(false);
  };
  //
  const handleAddSalaryClick = () => {
    setAddSalariesModalOpen(true);
  };
  //
  const designationValues = (departmentDesignations) => {
    if (departmentDesignations.length > 0) {
      const validDesignations = departmentDesignations
        .filter((departmentDesignation) => departmentDesignation?.designation?.name)
        .map((departmentDesignation) => departmentDesignation?.designation?.name);
      if (validDesignations.length > 1) {
        return validDesignations.join(', ');
      }
      return validDesignations;
    }
    return '-';
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SALARY,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup open={viewSalaryModalOpen} onClose={handleOnClose} mediumSize="70rem">
        <Grid container alignItems="center" className="viewSalaryHistoryPopup">
          <div className="header">
            <p className="modalTitle">Salary history</p>
            <p className="username">{`${salaryHistoryUser?.firstName} ${salaryHistoryUser?.lastName}`}</p>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.SALARY,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              <Button onClick={() => handleAddSalaryClick()}>+Add Salary</Button>
            </PermissionWrapper>
          </div>
          <AddSalariesPopup
            addSalariesModalOpen={addSalariesModalOpen}
            setAddSalariesModalOpen={setAddSalariesModalOpen}
            salaryHistoryUser={salaryHistoryUser}
          />
          <Grid container>
            {isFirst ? (
              <ShimmerTable rows={5} cols={5} />
            ) : (
              <Formik
                initialValues={{
                  amount: Number(salaryHistoryUser?.currentSalary?.amount),
                  startDate: salaryHistoryUser?.currentSalary?.startDate,
                  endDate: salaryHistoryUser?.currentSalary?.endDate || '',
                }}
                validationSchema={updateCurrentSalaryValidation}
                onSubmit={(values) => {
                  dispatch(
                    userActions.updateCurrentSalary({
                      ...values,
                      amountType: salaryHistoryUser?.currentSalary?.amountType,
                      paymentType: salaryHistoryUser?.currentSalary?.paymentType,
                      userId: salaryHistoryUser?.id,
                      salaryId: salaryHistoryUser?.currentSalary?.id,
                    })
                  );
                  setHide(false);
                }}
              >
                {(formik) => (
                  <>
                    {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                      <Alert sx={{ mb: 3 }} severity={notification?.type}>
                        {notification?.message}
                      </Alert>
                    )}
                    <DataGrid
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'status', sort: 'asc' }],
                        },
                      }}
                      rows={salaries
                        .filter((salary) => salary.amountType === AMOUNT_TYPES.SALARY) // Pagination
                        .map((salary) => ({
                          ...salary,
                          id: salary?.startDate,
                          designation: {
                            value: designationValues(salary?.departmentDesignations),
                          },
                          amount: {
                            salary,
                            formik,
                            setHide,
                          },
                          startDate: {
                            salary,
                            formik,
                            setHide,
                            value: new Date(salary?.startDate),
                          },
                          endDate: {
                            salary,
                            formik,
                            value: new Date(salary?.endDate),
                          },
                          status: {
                            dataStatus: salary?.dataStatus,
                            hide,
                            updateCurrentSalaryLoading,
                            formik,
                          },
                        }))}
                      loading={salaryHistoryLoading && isFirst !== true}
                      paginationMode="client"
                      handleChangePage={handleChangePage}
                      page={pageController.page - 1 ?? 0}
                      limit={pageController.rowsPerPage ?? 10}
                      pageSizeOptions={[pageController.rowsPerPage ?? 10]}
                      columns={SALARY_HISTORY_TABLE_HEADERS}
                      rowHeight={salaries?.length ? 64 : 200}
                      getRowHeight={(row) => (row.type === 'header' ? 200 : 80)}
                    />
                  </>
                )}
              </Formik>
            )}
          </Grid>
        </Grid>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default ViewSalaryHistoryPopup;
