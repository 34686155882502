import { Alert, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
//
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import { editDesignationFormValidation } from 'features/designations/validations/designation-validations';
import { selectAllDesignation, selectEditDesignationLoader } from 'features/designations/selectors';
import { designationActions } from 'features/designations/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Function that defines the popup form for adding a designation
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} designationId - id of the designation to be edited
 * @returns {Popup}
 */
const EditDesignationPopup = ({ editModalOpen, setEditModalOpen, designationId }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const designations = useSelector(selectAllDesignation);
  const designationToEdit = designations?.docs?.find(
    (designation) => designation.id === designationId
  );
  const loading = useSelector(selectEditDesignationLoader);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const initialValues = {
    id: designationToEdit?.id,
    name: designationToEdit?.name,
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit Designation"
        mediumSize="860px"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={editDesignationFormValidation}
          onSubmit={(values) => {
            dispatch(designationActions.editDesignation({ ...values }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} className="edit-workArea-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid container item md={6} direction="column">
                  <TextField
                    type="text"
                    name="name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    my={2}
                    label="Designation"
                  />
                </Grid>
              </Grid>
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading || designationToEdit?.name === values?.name}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditDesignationPopup;
