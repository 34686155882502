import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/internal-onboarding'] || initialState;
/**
 * Function to retrieve the loader state of a internalOnboarding slice
 */
export const selectLoader = createSelector(
  [selectDomain],
  (internalOnboardingState) => internalOnboardingState.loading
);
/**
 * Function to retrieve the isSuccessful state from internalOnboarding state
 */
export const selectIsSuccessful = createSelector(
  [selectDomain],
  (internalOnboardingState) => internalOnboardingState.isSuccessful
);
/**
 * Function to retrieve the isSuccessful state from internalOnboarding state
 */
export const selectUserOnboarding = createSelector(
  [selectDomain],
  (internalOnboardingState) => internalOnboardingState.user
);
/**
 * Function to retrieve the department designation ids from the internalOnboarding slice
 */
export const selectDepartmentDesignations = createSelector(
  [selectDomain],
  (internalOnboardingState) => internalOnboardingState.departmentDesignations
);
/**
 * Selector for onBoarded Successfully from the internalOnboarding slice
 */
export const selectSuccessfully = createSelector(
  [selectDomain],
  (internalOnboardingState) => internalOnboardingState?.isSuccessful
);
