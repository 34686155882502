import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ShimmerThumbnail } from 'react-shimmer-effects';
//
import { Autocomplete, SearchBar } from 'features/base/components';
import { selectCreateTeamIsProjectFetching } from 'features/projects/selectors';
import bulkFilterIcon from 'features/base/assets/images/png/bulk-filter.png';
import COLORS from 'features/base/constants/colors';
import { selectDepartments, selectDesignations } from 'features/users/selectors';
/**
 * Function for rendering the search and the filter components in the create team page
 */
const SearchAndFilters = ({
  searchData,
  handleSearchInput,
  onFilterIconClick,
  filterContainerRef,
  designationFilter,
  setDesignationFilter,
  departmentFilter,
  setDepartmentFilter,
  pageController,
  setPageController,
}) => {
  const isProjectLoading = useSelector(selectCreateTeamIsProjectFetching);
  const allDesignations = useSelector(selectDesignations);
  const allDepartments = useSelector(selectDepartments);
  //
  return (
    <Grid item>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isProjectLoading ? (
          <ShimmerThumbnail width={300} height={40} />
        ) : (
          <>
            <SearchBar
              placeholder="Search username or email"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
            <Box
              onClick={onFilterIconClick}
              sx={{
                ml: '16px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <img
                src={bulkFilterIcon}
                alt="bulk-filter-icon"
                style={{ height: '24px', width: '24px' }}
              />
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <Box
          ref={filterContainerRef}
          sx={{
            display: 'none',
            flexDirection: 'column',
            position: 'absolute',
            bgcolor: COLORS.WHITE,
            width: '300px',
            border: `0.3px solid ${COLORS.LILAC_GRAY}`,
            boxShadow: `-4px 8px 32px ${COLORS.TRANSPARENT_DARK_GREEN}`,
            zIndex: 1,
            p: '10px',
          }}
        >
          <Autocomplete
            id="designationFilter"
            name="designationFilter"
            options={[
              'All',
              ...(allDesignations?.docs?.map((designation) => designation?.name) || 'No options'),
            ]}
            label="Select designation"
            controlled
            value={designationFilter}
            onChange={(e, autocompleteValue) => {
              setDesignationFilter(autocompleteValue || 'All');
              setPageController({ ...pageController, page: 1 });
            }}
          />
          <Autocomplete
            id="departmentFilter"
            name="departmentFilter"
            options={[
              'All',
              ...(allDepartments?.map((department) => department?.name) || 'No options'),
            ]}
            label="Select department"
            controlled
            value={departmentFilter}
            onChange={(e, autocompleteValue) => {
              setDepartmentFilter(autocompleteValue || 'All');
              setPageController({ ...pageController, page: 1 });
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};
//
export default SearchAndFilters;
