import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
//
import { TabBar } from 'features/base/components';
import {
  SKILLS_NESTED_ROUTE,
  SKILL_GROUPS_NESTED_ROUTE,
} from 'features/base/constants/dashboard-tab-routes';
import { selectNotification } from 'features/base/notifications/selectors';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import { skillActions } from 'features/skills/slice';
import AddSkillGroupsPopup from 'features/skills/components/add-skill-groups/add-skill-groups-modal';
import AddSkillsDrawer from 'features/skills/components/add-skills/add-skills-drawer';
import AddSkillGroupsDrawer from 'features/skills/components/add-skill-groups/add-skill-groups-drawer';
import SkillsHeader from './components/skills-header';
import SkillGroupsHeader from './components/skill-groups-header';

/**
 * Layout component for skill dashboard
 * @returns {Box}
 */
const SkillDashboardLayout = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const skillViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const getNestedRoutes = () => {
    let nestedRoutes = [];
    if (skillViewPermissionsVerified) {
      nestedRoutes = [SKILLS_NESTED_ROUTE, SKILL_GROUPS_NESTED_ROUTE];
    }
    return nestedRoutes;
  };
  const NESTED_ROUTES = getNestedRoutes();
  //
  const dispatch = useDispatch();
  const location = useLocation();
  //
  const notification = useSelector(selectNotification);
  //
  const [pathName, setPathName] = useState(NESTED_ROUTES[0].path);
  const [searchValue, setSearchValue] = useState('');
  const [addGroupsModalOpen, setAddGroupsModalOpen] = useState(false);
  const [addGroupsDrawerOpen, setAddGroupsDrawerOpen] = useState(false);
  const [addSkillsDrawerOpen, setAddSkillsDrawerOpen] = useState(false);
  //
  const searchSkillsAndGroups = () => {
    dispatch(skillActions.setSearch(searchValue));
  };
  const debounced = useDebouncedCallback(searchSkillsAndGroups, TIME_OUTS.DEBOUNCE);
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    debounced();
  };
  //
  const handleAddModalClick = () => {
    dispatch(
      skillActions.getAllSkills({
        query: `pagination=false`,
      })
    );
    setAddGroupsModalOpen(true);
  };
  //
  const handleAddDrawerClick = () => {
    dispatch(
      skillActions.getAllSkills({
        query: `pagination=false`,
      })
    );
    setAddGroupsDrawerOpen(true);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [notification]);
  //
  useEffect(() => {
    const urlSections = location.pathname.split('/');
    const URLPath = urlSections[urlSections.length - 1];
    setPathName(URLPath);
    return () => {
      dispatch(skillActions.setSearch(''));
      setSearchValue('');
    };
  }, [location.pathname]);
  //
  const renderHeaders = () => {
    switch (pathName) {
      case NESTED_ROUTES[0]?.path:
        return (
          <SkillsHeader
            setAddSkillsDrawerOpen={setAddSkillsDrawerOpen}
            searchValue={searchValue}
            handleSearch={handleSearch}
          />
        );
      case NESTED_ROUTES[1]?.path:
        return (
          <SkillGroupsHeader
            handleAddDrawerClick={handleAddDrawerClick}
            handleAddModalClick={handleAddModalClick}
            searchValue={searchValue}
            handleSearch={handleSearch}
          />
        );
      default:
        return null;
    }
  };
  //
  return (
    <>
      <AddSkillsDrawer
        addSkillsDrawerOpen={addSkillsDrawerOpen}
        setAddSkillsDrawerOpen={setAddSkillsDrawerOpen}
      />
      <AddSkillGroupsDrawer
        addGroupsDrawerOpen={addGroupsDrawerOpen}
        setAddGroupsDrawerOpen={setAddGroupsDrawerOpen}
      />
      <AddSkillGroupsPopup
        addGroupsModalOpen={addGroupsModalOpen}
        setAddGroupsModalOpen={setAddGroupsModalOpen}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        <Box>
          {renderHeaders()}
          <Box sx={{ mt: '12px', mb: '48px' }}>
            <TabBar nestedRoutes={NESTED_ROUTES} pathName={pathName} />
          </Box>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};
//
export default SkillDashboardLayout;
