/**
 * Defines the API paths for client
 */
const API = {
  ADD_CLIENT: {
    path: '/clients',
    method: 'POST',
  },
  EDIT_CLIENT: {
    path: '/clients/:id',
    method: 'PATCH',
  },
  GET_CLIENTS: {
    path: '/clients?:query',
    method: 'GET',
  },
  GET_CLIENT_BY_ID: {
    path: '/clients/:id',
    method: 'GET',
  },
  GET_PROJECT_ALLOCATIONS_LIST: {
    path: '/allocations?:query',
    method: 'GET',
  },
  ADD_CLIENT_USER: {
    path: '/clients/:id/users',
    method: 'POST',
  },
  EDIT_CLIENT_USER: {
    path: '/clients/:id/users/:userId',
    method: 'PATCH',
  },
  DELETE_CLIENT_USER: {
    path: '/clients/:id/users/:userId',
    method: 'DELETE',
  },
  GET_PROJECTS: {
    path: '/projects?:query',
    method: 'GET',
  },
  INVITE_CLIENT: {
    path: '/users/:id/invite',
    method: 'POST',
  },
  POST_LOGO: {
    path: '/clients/upload',
    method: 'POST',
  },
};
//
export default API;
