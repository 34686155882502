/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { FIREBASE } from 'config';
import { selectUserId } from 'features/base/auth/selectors';
import { getAuth } from 'firebase/auth';

/**
 * Custom hook to listen for real-time notifications
 * @returns {Array} notifications
 * @example
 * const notifications = useFirebaseNotifications();
 * console.log(notifications);
 */
export const useFirebaseNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    getAuth();
    const q = query(collection(FIREBASE, 'notifications'), where('userId', '==', userId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newNotifications = querySnapshot
        .docChanges()
        .filter((change) => change.type === 'added')
        .map((change) => change.doc.data());
      //
      if (
        newNotifications.length > 0 &&
        !notifications.find((n) => n.id === newNotifications[0].id)
      ) {
        setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  return notifications;
};
