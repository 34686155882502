import { Alert, Grid, AlertTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import { Button, DragAndDropZone } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import Popup from 'features/base/components/modal';
import { selectSalaryUserList, selectUploadFileLoader } from 'features/users/selectors';
import { userActions } from 'features/users/slice';
import { downloadCSVFile, processFiles } from 'features/base/helpers/file';
import {
  BULK_SALARY_UPLOAD_ALLOWED_FILE_TYPES,
  BULK_SALARY_UPLOAD_ALLOWED_EXTENSIONS,
  BULK_SALARY_UPLOAD_MAX_FILE_SIZE,
} from 'features/base/constants/file-upload';
import { FIREBASE_NOTIFICATION_TYPES } from 'features/base/constants/firebase-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { useFirebaseNotifications } from 'features/base/hooks/use-firebase-firestore';
/**
 * Function that defines the popup form for adding multiple salary to users
 * @prop {Boolean} open - Boolean to show/hide the popup
 * @prop {Function} setOpen - Function to set the popup state
 * @returns {Popup}
 */
const AddMultipleSalaryPopup = ({ open, setOpen }) => {
  //
  const notifications = useFirebaseNotifications();
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectUploadFileLoader);
  const users = useSelector(selectSalaryUserList);
  //
  const [bulkSalaryFile, setBulkSalaryFile] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  const [touched, setTouched] = useState(false);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  //
  const handleDownloadSalaryFile = () => {
    const data = users.map((user) => ({
      Email: user?.email || '',
      'First Name': user?.firstName || '',
      'Last Name': user?.lastName || '',
      'Salary (USD)': '',
      'From-Month-Year': '',
      'To-Month-Year': '',
    }));
    //
    downloadCSVFile(Object.keys(data?.[0]), data, 'salaries.csv');
  };
  //
  const handleSubmit = () => {
    setTouched(true);
    if (bulkSalaryFile.length > 0) {
      const formData = processFiles(bulkSalaryFile);
      dispatch(userActions.uploadBulkSalaryFile({ files: formData }));
    }
  };
  //
  const handleOnClose = () => {
    setOpen(false);
    setTouched(false);
    setFileTypeError('');
    setBulkSalaryFile([]);
  };
  //
  useEffect(() => {
    if (
      open &&
      notification?.isEnabled &&
      (notification?.type === ERROR_TYPES.SUCCESS || notification?.type === ERROR_TYPES.INFO)
    ) {
      setIsUploadSuccess(true);
      setReceivedNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          title: 'Bulk Salary File Processing Progress',
          message: "Processing will begin shortly. You will be notified of the progress...",
          type: FIREBASE_NOTIFICATION_TYPES.INFO,
          timestamp: new Date().toISOString(),
        },
      ]);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!open) {
      return;
    }
    if (notifications.length > receivedNotifications.length && isUploadSuccess) {
      setReceivedNotifications((prevNotifications) => [
        ...prevNotifications,
        notifications[notifications.length - 1],
      ]);
    }
  }, [notifications.length]);
  //
  useEffect(
    () => () => {
      setReceivedNotifications([]);
    },
    []
  );
  //
  return (
    <Popup open={open} title="Upload Bulk Salaries" onClose={handleOnClose} maxWidth="lg" fullWidth>
      <Grid container justifyContent="flex-end" marginBottom={2}>
        <Button disabled={users.length <= 0 || loading} onClick={handleDownloadSalaryFile}>
          Download Template
        </Button>
      </Grid>
      <form>
        {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
          <Alert sx={{ mb: 3 }} severity={notification?.type}>
            {notification?.message}
          </Alert>
        )}
        <Grid>
          {receivedNotifications.length > 0 &&
            receivedNotifications.map((content) => (
              <Alert
                sx={{ mb: 3 }}
                severity={content?.type?.toLowerCase() || 'info'}
                key={`${content?.title}-${content?.timestamp}`}
              >
                <AlertTitle>{`${content?.title} - ${new Date(
                  content?.timestamp
                ).toLocaleString()}`}</AlertTitle>
                <strong>{content?.message}</strong>
              </Alert>
            ))}
          <DragAndDropZone
            singleFile
            allowedExtensions={BULK_SALARY_UPLOAD_ALLOWED_EXTENSIONS}
            allowedMimetypes={BULK_SALARY_UPLOAD_ALLOWED_FILE_TYPES}
            maxSize={BULK_SALARY_UPLOAD_MAX_FILE_SIZE}
            error={fileTypeError}
            onAdd={(filesToAdd) => setBulkSalaryFile(filesToAdd)}
            onDelete={() => setBulkSalaryFile([])}
            uploadedFiles={bulkSalaryFile}
            disabled={loading}
            errorMsg={touched && bulkSalaryFile.length <= 0 && 'Please select a file'}
          />
        </Grid>
        <ButtonGrid
          leftButtonText="Cancel"
          rightButtonText={loading ? 'Submitting' : 'Submit'}
          leftOnClick={handleOnClose}
          rightOnClick={handleSubmit}
          rightIcon={loading ? loaderIcon : null}
          submitDisabled={loading}
          rightButtonType="button"
        />
      </form>
    </Popup>
  );
};
//
export default AddMultipleSalaryPopup;
