import { Box, Typography, Grid } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
//
import { Button, SearchBar } from 'features/base/components';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import addIcon from 'features/base/assets/images/svg/add-icon.svg';

/**
 * Renders the skill groups header
 */
const SkillGroupsHeader = ({
  handleAddDrawerClick,
  handleAddModalClick,
  searchValue,
  handleSearch,
}) => (
  <Box>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
        pb: 4,
      }}
    >
      <Grid
        item
        sx={{
          width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '24px', sm: '32px' },
            fontWeight: 600,
            mr: '48px',
          }}
        >
          Skill Groups
        </Typography>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <PlusIcon
            onClick={() => handleAddDrawerClick()}
            sx={{
              width: '32px',
              height: '32px',
              cursor: 'pointer',
              color: 'primary.main',
              display: { xs: 'block', sm: 'none' },
            }}
          />
        </PermissionWrapper>
      </Grid>
      <Grid
        item
        sx={{
          width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
          pt: { xs: '20px', lg: '0', md: '0', sm: '0' },
        }}
      >
        <SearchBar
          placeholder="Search skill groups & skills"
          value={searchValue}
          handleSearchInput={handleSearch}
        />
      </Grid>
      <Grid
        item
        sx={{
          pt: { xs: '20px', lg: '0', md: '20px', sm: '20px' },
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.ORGANIZATION,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <Button
            onClick={handleAddModalClick}
            icon={addIcon}
            sx={{ width: { lg: 'auto', xs: '100%' } }}
          >
            Add skill group
          </Button>
        </PermissionWrapper>
      </Grid>
    </Grid>
  </Box>
);
//
export default SkillGroupsHeader;
