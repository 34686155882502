import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/users'] || initialState;
/**
 * Selector for getting loader state
 */
export const selectLoader = createSelector([selectDomain], (userState) => userState.loading);
/**
 * Selector for getting loader state
 */
export const selectUpdateRoleLoader = createSelector(
  [selectDomain],
  (userState) => userState.updateRoleLoading
);
/**
 * Selector for getting loader state
 */
export const selectGetPermissionsLoader = createSelector(
  [selectDomain],
  (userState) => userState.permissionsLoading
);
/**
 * Selector for user list state
 */
export const selectUserList = createSelector([selectDomain], (userState) => userState.userList);
/**
 * Selector for salary user list state
 */
export const selectSalaryUserList = createSelector(
  [selectDomain],
  (userState) => userState.salaryUserList
);
/**
 * Selector for projects of a users
 */
export const selectProjectsOfUser = createSelector(
  [selectDomain],
  (userState) => userState.projectsOfUser
);
/**
 * Selector for view history
 */
export const selectViewSalaryModalOpen = createSelector(
  [selectDomain],
  (userState) => userState.viewSalaryModalOpen
);
/**
 * Selector for Designations
 */
export const selectDesignations = createSelector(
  [selectDomain],
  (userState) => userState?.designations
);
/**
 * Selector for Departments
 */
export const selectDepartments = createSelector(
  [selectDomain],
  (userState) => userState?.departments
);
/**
 * Selector for WorkAreas
 */
export const selectWorkAreas = createSelector([selectDomain], (userState) => userState?.workAreas);
/**
 * Selector for Roles
 */
export const selectRoles = createSelector([selectDomain], (userState) => userState?.roles);
/**
 * Selector to get Salary History Loading status
 */
export const selectGetSalaryHistoryLoading = createSelector(
  [selectDomain],
  (userState) => userState.getSalaryHistoryLoading
);
/**
 * Selector to get the Salary History of a user
 */
export const selectSalaryHistory = createSelector(
  [selectDomain],
  (userState) => userState.salaryHistory
);
/**
 * Selector for selected user's permissions
 */
export const selectUsersPermissions = createSelector(
  [selectDomain],
  (userState) => userState?.selectedUsersPermissions
);
/**
 * Selector to get the loading status of the update current salary action
 */
export const selectUpdateCurrentSalaryLoading = createSelector(
  [selectDomain],
  (userState) => userState?.updateCurrentSalaryLoading
);
/**
 * Selector to get the loading status of the add salary action
 */
export const selectAddSalaryLoading = createSelector(
  [selectDomain],
  (userState) => userState?.addSalaryLoading
);
/**
 * Selector to get the loading status of the add user action
 */
export const selectAddUserLoading = createSelector(
  [selectDomain],
  (userState) => userState?.addUserLoading
);
/**
 * Selector to get the loading status of the salary user list
 */
export const selectSalaryUserLoading = createSelector(
  [selectDomain],
  (userState) => userState?.salaryUserListLoading
);
/**
 * Selector to get the loading status of the roles action
 */
export const selectRolesLoading = createSelector(
  [selectDomain],
  (userState) => userState?.getRolesLoading
);
/**
 * Selector for getting loader state of uploading a bulk salary file
 */
export const selectUploadFileLoader = createSelector(
  [selectDomain],
  (userState) => userState.uploadFileLoading
);
/**
 * Selector for getting loader state of bulk user data users
 */
export const selectBulkUserDataUsersLoader = createSelector(
  [selectDomain],
  (userState) => userState.bulkUserDataUsersLoading
);
/**
 * Selector for getting bulk user data users
 * @returns {Array}
 */
export const selectBulkUserDataUsers = createSelector(
  [selectDomain],
  (userState) => userState.bulkUserDataUsers
);
/**
 * Selector for getting loader state of bulk user data file
 */
export const selectBulkUserDataFileLoader = createSelector(
  [selectDomain],
  (userState) => userState.bulkUserDataFileLoading
);
