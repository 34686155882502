import { useCallback } from 'react';
import { useSelector } from 'react-redux';
//
import { selectUserPermissions } from '../auth/selectors';
import { isEmpty } from '../helpers/strings';
import LOGICAL_OPERATIONS from '../constants/logical-operations';
/**
 * Custom hook to check if user has the required permissions
 * @returns {permissionsVerified}
 */
const useIsPermissionsVerified = () => {
  const userPermissions = useSelector(selectUserPermissions);
  //
  const permissionsVerified = useCallback(
    (requiredPermissions, operation) => {
      if (userPermissions && isEmpty(userPermissions)) {
        return false;
      }
      const includes = requiredPermissions?.map((permission) =>
        userPermissions[permission.domain]?.includes(permission.action)
      );
      //
      if (operation === LOGICAL_OPERATIONS.OR) {
        return includes.some((value) => value === true);
      }
      if (operation === LOGICAL_OPERATIONS.AND) {
        return includes.every((value) => value === true);
        // Default operation set to logical AND
      }
      return includes.every((value) => value === true);
    },
    [userPermissions]
  );
  //
  return { permissionsVerified };
};
//
export default useIsPermissionsVerified;
