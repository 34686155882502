/**
 * Defines the API paths for links
 */
const API = {
  ADD_LINK: {
    path: '/link-hub',
    method: 'POST',
  },
  POST_LOGO: {
    path: '/link-hub/upload',
    method: 'POST',
  },
  GET_DEPARTMENTS: {
    path: '/departments',
    method: 'GET',
  },
  GET_LINKS: {
    path: '/link-hub?:query',
    method: 'GET',
  },
  EDIT_LINK: {
    path: '/link-hub/:id',
    method: 'PATCH',
  },
};
//
export default API;
