import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AMOUNT_RANGES } from 'features/base/constants/amount-types';
import COLORS from 'features/base/constants/colors';
import { MONTHS } from 'features/base/constants/date-formatting';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
/**
 * Component that renders the salary table.
 */
const SalaryTable = ({ salariesByViewPeriod, viewPeriod }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            colSpan={12}
            sx={{
              bgcolor: COLORS.SKY_BLUE,
              borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
              borderInline: `2px solid ${COLORS.WHITE}`,
              p: 1,
              m: 0,
              textAlign: 'center',
            }}
          >
            <Typography variant="body1">{`${viewPeriod?.[0]?.year}/${
              viewPeriod?.[0]?.year && viewPeriod[0].year + 1
            }`}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          {viewPeriod.map((period) => (
            <TableCell
              sx={{
                bgcolor: COLORS.SKY_BLUE,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
                textAlign: 'center',
                minWidth: '10rem',
                p: 1,
                m: 0,
              }}
            >
              <Typography variant="body1">{`${
                period?.month && MONTHS[period.month - 1].slice(0, 3)
              }`}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {salariesByViewPeriod &&
          salariesByViewPeriod.map((department) => {
            const dep = department[0];
            const users = department?.slice(1, department?.length);
            return (
              <>
                <TableRow key={dep?.id}>
                  {viewPeriod &&
                    viewPeriod.map((period) => (
                      <TableCell
                        key={`${period?.year}-${period?.month}`}
                        sx={{
                          backgroundColor: COLORS.PALE_LILAC,
                          borderInline: `2px solid ${COLORS.WHITE}`,
                          textAlign: 'center',
                          minWidth: '10rem',
                          p: 2.5,
                          m: 0,
                        }}
                      />
                    ))}
                </TableRow>
                {users.map((user) => (
                  <TableRow key={user?.id}>
                    {viewPeriod &&
                      viewPeriod.map((period) => {
                        const salaryKey = `${period?.year}-${period?.month}`;
                        return (
                          <TableCell
                            key={salaryKey}
                            sx={{
                              textAlign: 'center',
                              borderInline: `2px solid ${COLORS.WHITE}`,
                              minWidth: '10rem',
                              p: 1,
                              m: 0,
                            }}
                          >
                            <Typography variant="body1">
                              {user[salaryKey]?.amount > AMOUNT_RANGES.MIN_AMOUNT
                                ? getDollarFormattedValue(user[salaryKey]?.amount)
                                : `$ ${user[salaryKey]?.amount}`}
                            </Typography>
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
              </>
            );
          })}
      </TableBody>
    </Table>
  </TableContainer>
);
//
export default SalaryTable;
