import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { generatePath, useNavigate } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import { toast } from 'react-toastify';
//
import { Button, DataGrid, SearchBar } from 'features/base/components';
import AddProjectsPopup from 'features/projects/components/add-projects-modal';
import EditProjectsPopup from 'features/projects/components/edit-project-modal';
import { projectActions } from 'features/projects/slice';
import { toolActions } from 'features/tools/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectAllProjects, selectLoader } from 'features/projects/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { PROJECT_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { USER_TYPES } from 'features/base/constants/user-types';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import ROUTES from 'features/base/constants/routes';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  PROJECTS_TABLE_ACTION_COLUMNS,
  PROJECTS_TABLE_COLUMNS_BASIC,
} from 'features/base/utils/tables';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import createFormattedString from 'features/base/helpers/param-formatter';
import { selectAuthUser } from 'features/base/auth/selectors';

/**
 * Component that defines the entire project view
 * @returns MUI Grid with the project view
 */
const ProjectView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const projectEditPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const allocationViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const repositoriesViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.REPOSITORY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const externalMembersViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT_EXTERNAL_USERS,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const deploymentsViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.DEPLOYMENT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const atLeastOneVerified =
    allocationViewPermissionsVerified ||
    externalMembersViewPermissionsVerified ||
    repositoriesViewPermissionsVerified ||
    deploymentsViewPermissionsVerified;
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const allProjects = useSelector(selectAllProjects);
  const isLoading = useSelector(selectLoader);
  const notification = useSelector(selectNotification);
  const authUser = useSelector(selectAuthUser);
  //
  const [searchData, setSearchData] = useState('');
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy:
      'name:asc' /* As for now no sorting field is defined in the view, so added the sortBy key with a default value for future usage. */,
    rowsPerPage: PROJECT_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [projectId, setProjectId] = useState('');
  //
  const params = useMemo(
    () => ({
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
    }),
    [pageController]
  );
  //
  const searchProjects = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchProjects, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (event) => {
    setSearchData(event.target.value);
    debounced();
  };
  //
  const handleAdd = () => {
    dispatch(
      projectActions.getInternalUsers({ query: `type=${USER_TYPES.INTERNAL}&pagination=false` })
    );
    dispatch(toolActions.getAllTools({ query: `pagination=false` }));
    dispatch(projectActions.getAllDepartments({ query: `pagination=false` }));
    setAddModalOpen(true);
  };
  //
  const handleEdit = (id) => {
    dispatch(
      projectActions.getInternalUsers({ query: `type=${USER_TYPES.INTERNAL}&pagination=false` })
    );
    dispatch(toolActions.getAllTools({ query: `pagination=false` }));
    dispatch(projectActions.getAllDepartments({ query: `pagination=false` }));
    setProjectId(id);
    setEditModalOpen(true);
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const isFirst = useIsInitialize(allProjects);
  //
  const onNameClick = (projId) => {
    // Navigate to the first tab that the user has permission to view
    if (allocationViewPermissionsVerified) {
      navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION, { projectId: projId }));
    } else if (externalMembersViewPermissionsVerified) {
      navigate(generatePath(ROUTES.PROJECT_EXTERNAL_MEMBERS, { projectId: projId }));
    }
  };
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params, searchData);
    if (authUser) {
      const departmentId =
        authUser?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.id;
      dispatch(
        projectActions.getAllProjects({
          query: `${formattedParamString}&departmentId=${departmentId}`,
        })
      );
    }
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.onProjectViewUnmount());
    },
    []
  );
  return (
    <>
      <AddProjectsPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditProjectsPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        projectId={projectId}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 4 }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', width: '40%' }}>
            Projects{' '}
          </Typography>
          <Box
            sx={{
              height: '60px',
              width: { xs: '100%', sm: '60%', lg: '40%', md: '60%' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SearchBar
              placeholder="Search project"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
          </Box>
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.PROJECT,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            <Button onClick={() => handleAdd()} sx={{ width: { xs: '100%', sm: 'auto' } }}>
              Add Project <AddIcon />
            </Button>
          </PermissionWrapper>
        </Grid>
        {isFirst ? (
          <ShimmerTable row={6} col={8} />
        ) : (
          <DataGrid
            columns={
              projectEditPermissionsVerified
                ? [...PROJECTS_TABLE_COLUMNS_BASIC, ...PROJECTS_TABLE_ACTION_COLUMNS]
                : PROJECTS_TABLE_COLUMNS_BASIC
            }
            onRowClick={(data) => onNameClick(data.id)}
            rows={
              allProjects?.docs?.map((project) => ({
                ...project,
                id: project?.id,
                project: {
                  projectName: project?.name,
                  projectLogo: project?.logo,
                },
                description: {
                  value: project?.description,
                },
                startDate: {
                  value: moment(project?.startDate).format(DATE_FORMAT_SLASH),
                  date: project?.startDate,
                },
                endDate: {
                  value: moment(project?.endDate).format(DATE_FORMAT_SLASH),
                  date: project?.endDate,
                },
                type: {
                  value: project?.type,
                },
                billingType: {
                  value: billingTypeFormatter(project?.billingType) || '-',
                },
                projectValue: {
                  value: getDollarFormattedValue(project.projectValue) || '-',
                },
                status: {
                  onChange: (event) => {
                    event.stopPropagation();
                    dispatch(
                      projectActions.editProject({
                        id: project?.id,
                        dataStatus:
                          project.dataStatus === DATA_STATUS_TYPES.ACTIVE
                            ? DATA_STATUS_TYPES.INACTIVE
                            : DATA_STATUS_TYPES.ACTIVE,
                      })
                    );
                  },
                  checked: project?.dataStatus === DATA_STATUS_TYPES.ACTIVE,
                  isLoading,
                },
                ongoing: {
                  onChange: (event) => {
                    event.stopPropagation();
                    dispatch(
                      projectActions.editProject({
                        id: project?.id,
                        ongoing: !project?.ongoing,
                      })
                    );
                  },
                  checked: project?.ongoing,
                  isLoading,
                },
                action: {
                  handleEdit: (event) => {
                    event.stopPropagation();
                    handleEdit(project?.id);
                  },
                  isLoading,
                },
              })) ?? []
            }
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalPages={allProjects?.totalPages ?? 0}
            totalDocs={allProjects?.totalDocs ?? 0}
            limit={allProjects?.limit ?? pageController.rowsPerPage}
            loading={isLoading && isFirst !== true}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={allProjects?.docs?.length ? 52 : 200}
            clickable={atLeastOneVerified}
          />
        )}
      </Container>
    </>
  );
};
//
export default ProjectView;
