/* eslint-disable import/no-cycle */
import createSagaMiddleware from '@redux-saga/core';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootSaga from 'root-saga';
//
import { ENVIRONMENT } from 'config';
import logger from 'features/base/utils/logger';
import { signInSlice } from 'features/sign-in/slice';
import { projectSlice } from 'features/projects/slice';
import { supportTicketSlice } from 'features/support-tickets/slice';
import { authSlice } from 'features/base/auth/slice';
import { userSlice } from 'features/users/slice';
import { notificationSlice } from 'features/base/notifications/slice';
import { internalOnboardingSlice } from 'features/internal-onboarding/slice';
import { opexSlice } from 'features/opex/slice';
import { profileSlice } from 'features/profile/slice';
import { toolSlice } from 'features/tools/slice';
import { roleSlice } from 'features/roles/slice';
import { skillsSlice } from 'features/skills/slice';
import { departmentSlice } from 'features/departments/slice';
import { designationSlice } from 'features/designations/slice';
import { clientSlice } from 'features/clients/slice';
import { linkSlice } from 'features/links/slice';
import { reportSlice } from 'features/reports/slice';
import { arsenalSlice } from 'features/arsenal/slice';
import { benefitsSlice } from 'features/benefits/slice';
import { vendorSlice } from 'features/vendors/slice';

const sagaMiddleware = createSagaMiddleware();
/**
 * Setup encryption using secret key
 */
const encryption = encryptTransform({
  secretKey: ENVIRONMENT.PERSIST_ENCRYPTED_SECRET_KEY,
  onError(error) {
    logger.error(error);
  },
});
/**
 * White listed slices which needs to be persist with encryption
 */
const persistConfig = {
  version: ENVIRONMENT.PERSIST_VERSION,
  key: ENVIRONMENT.PERSIST_CONFIG_KEY,
  storage,
  stateReconciler: hardSet,
  transforms: [encryption],
  whitelist: ['feature/base-auth'],
  writeFailHandler(error) {
    logger.error(error);
  },
};
/**
 * Combine all the persist and non persist reducers
 */
const reducers = combineReducers({
  'feature/base-auth': authSlice.reducer,
  'feature/support-tickets': supportTicketSlice.reducer,
  'feature/projects': projectSlice.reducer,
  'feature/sign-in': signInSlice.reducer,
  'feature/users': userSlice.reducer,
  'feature/notification': notificationSlice.reducer,
  'feature/internal-onboarding': internalOnboardingSlice.reducer,
  'feature/opex': opexSlice.reducer,
  'feature/profile': profileSlice.reducer,
  'feature/tools': toolSlice.reducer,
  'feature/role': roleSlice.reducer,
  'feature/skills': skillsSlice.reducer,
  'feature/department': departmentSlice.reducer,
  'feature/designation': designationSlice.reducer,
  'feature/clients': clientSlice.reducer,
  'feature/links': linkSlice.reducer,
  'feature/reports': reportSlice.reducer,
  'feature/arsenal': arsenalSlice.reducer,
  'feature/benefits': benefitsSlice.reducer,
  'feature/vendors': vendorSlice.reducer,
});
/**
 * Define what are the slice which needs to be persist
 */
const persistedReducer = persistReducer(persistConfig, reducers);
/**
 * Register all the slices into the main store
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
});
sagaMiddleware.run(rootSaga);
persistStore(store);
//
export default store;
