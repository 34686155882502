import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/role'] || initialState;
/**
 * Function to retrieve all roles from the role slice
 */
export const selectAllRoles = createSelector([selectDomain], (roleState) => roleState.roleList);
//
export const selectLoader = createSelector([selectDomain], (roleState) => roleState.loading);
/**
 * Selector to get the loading status of the add role action
 */
export const selectAddRoleLoader = createSelector(
  [selectDomain],
  (userState) => userState?.addRoleLoading
);
/**
 * Selector to get the loading status of the edit role action
 */
export const selectEditRoleLoader = createSelector(
  [selectDomain],
  (userState) => userState?.editRoleLoading
);
