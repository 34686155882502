/* eslint-disable import/no-cycle */
import { call, put, takeEvery as takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { supportTicketActions } from './slice';
import API from './constants';
/**
 * Generator function for getting support tickets
 * @param {Object} payload
 */
function* getSupportTicketsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SUPPORT_TICKETS, payload);
    yield put(supportTicketActions.getSupportTicketsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getSupportTicketsFailed(error?.message));
  }
}
/**
 * Generator function for getting support ticket by id
 * @param {Object} payload
 */
function* getSupportTicketByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SUPPORT_TICKET_BY_ID, payload);
    yield put(supportTicketActions.getSupportTicketByIdSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getSupportTicketByIdFailed(error?.message));
  }
}
/**
 * Generator function for updating individual support ticket
 * @param {Object} payload
 * @returns {Object} updated support ticket
 */
function* updateIndividualSupportTicketGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_SUPPORT_TICKET, payload);
    yield put(supportTicketActions.updateIndividualSupportTicketSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.updateIndividualSupportTicketFailed(error?.message));
  }
}
/**
 * Generator function for adding support tickets
 * @param {Object} payload
 */
function* addSupportTicketsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SUPPORT_TICKETS, payload);
    yield put(supportTicketActions.addSupportTicketsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.addSupportTicketsFailed(error?.message));
  }
}
/**
 * Generator function for uploading support ticket attachments
 * @param {Object} payload
 */
function* uploadAttachmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_ATTACHMENTS, payload, true);
    yield put(supportTicketActions.uploadAttachmentsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.uploadAttachmentsFailed(error?.message));
  }
}
/**
 * Generator function for getting support tickets comments
 * @param {Object} payload
 */
function* getCommentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_COMMENTS, payload);
    yield put(supportTicketActions.getCommentsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getCommentsFailed(error?.message));
  }
}
/**
 * Generator function for adding support tickets
 * @param {Object} payload
 */
function* addCommentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_COMMENTS, payload);
    yield put(supportTicketActions.addCommentsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.addCommentsFailed(error?.message));
  }
}
/**
 * Generator function for adding comment attachments
 * @param {Object} payload
 */
function* addCommentsAttachmentGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_COMMENTS_ATTACHMENT, payload, true);
    yield put(supportTicketActions.addCommentsAttachmentsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.addCommentsAttachmentsFailed(error?.message));
  }
}
/**
 * Generator function for updating support ticket severity
 * @param {Object} payload
 */
function* updateSeverityGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_SUPPORT_TICKET, payload);
    yield put(supportTicketActions.updateSeveritySucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.updateSeverityFailed(error?.message));
  }
}
/**
 * Generator function for updating support ticket resolved status
 * @param {Object} payload
 */
function* updateResolvedStatusGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_SUPPORT_TICKET, payload);
    yield put(supportTicketActions.updateResolvedStatusSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.updateResolvedStatusFailed(error?.message));
  }
}
/**
 * Generator function for getting related projects
 * @param {Object} payload
 */
function* getItSupportProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(supportTicketActions.getItSupportProjectsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getItSupportProjectsFailed(error?.message));
  }
}
/**
 * Generator function for getting projects of user
 * @param {Object} payload
 */
function* getProjectsOfClientGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CLIENTS, payload);
    yield put(supportTicketActions.getProjectsOfClientSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getProjectsOfClientFailed(error?.message));
  }
}
/**
 * Generator function for getting projects managed by user
 * @param {Object} payload
 */
function* getProjectsOfManagerGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(supportTicketActions.getManagingProjectsSucceeded(response));
  } catch (error) {
    yield put(supportTicketActions.getManagingProjectsFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* supportTicketSaga() {
  yield takeLatest(supportTicketActions.getSupportTickets.type, getSupportTicketsGenerator);
  yield takeLatest(supportTicketActions.getSupportTicketById.type, getSupportTicketByIdGenerator);
  yield takeLatest(
    supportTicketActions.updateIndividualSupportTicket.type,
    updateIndividualSupportTicketGenerator
  );
  yield takeLatest(supportTicketActions.addSupportTickets.type, addSupportTicketsGenerator);
  yield takeLatest(supportTicketActions.uploadAttachments.type, uploadAttachmentsGenerator);
  yield takeLatest(supportTicketActions.getComments.type, getCommentsGenerator);
  yield takeLatest(supportTicketActions.addComments.type, addCommentsGenerator);
  yield takeLatest(
    supportTicketActions.addCommentsAttachments.type,
    addCommentsAttachmentGenerator
  );
  yield takeLatest(supportTicketActions.updateSeverity.type, updateSeverityGenerator);
  yield takeLatest(supportTicketActions.updateResolvedStatus.type, updateResolvedStatusGenerator);
  yield takeLatest(supportTicketActions.getItSupportProjects.type, getItSupportProjectsGenerator);
  yield takeLatest(supportTicketActions.getProjectsOfClient.type, getProjectsOfClientGenerator);
  yield takeLatest(supportTicketActions.getManagingProjects.type, getProjectsOfManagerGenerator);
}
//
export default supportTicketSaga;
