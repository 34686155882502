import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: true,
  error: '',
  user: {},
  departmentDesignations: [],
  myAllocations: [],
  userSkills: [],
  allSkillGroups: [],
  deleteFlag: false,
  isMyAllocationsFetching: false,
  userSkillLoading: false,
  departmentsLoading: false,
  addUserSkillLoading: false,
  deleteUserSkillLoading: false,
  updateUserSkillLoading: false,
  updateUserLoading: false,
  getSkillGroupsLoading: false,
  client: {},
  isClientFetching: false,
};
/**
 * Redux slice for profile
 */
export const profileSlice = createSlice({
  name: 'feature/profile',
  initialState,
  reducers: {
    handleToggleLoader: (state) => ({ ...state, loading: !state.loading }),
    fetchUserById: (state) => ({
      ...state,
      loading: true,
    }),
    fetchUserByIdSuccess: (state, action) => ({
      ...state,
      loading: false,
      user: action.payload,
    }),
    fetchUserByIdFailure: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      user: initialState.user,
    }),
    clearUser: (state) => ({
      ...state,
      user: initialState.user,
    }),
    getDepartmentDesignations: (state) => ({
      ...state,
      departmentsLoading: true,
    }),
    getDepartmentDesignationsSucceeded: (state, action) => ({
      ...state,
      departmentsLoading: false,
      departmentDesignations: action?.payload,
    }),
    getDepartmentDesignationsFailed: (state) => ({
      ...state,
      departmentsLoading: false,
    }),
    updateUser: (state) => ({
      ...state,
      updateUserLoading: true,
    }),
    updateUserSucceeded: (state, action) => ({
      ...state,
      updateUserLoading: false,
      user: action?.payload,
    }),
    updateUserFailed: (state) => ({
      ...state,
      updateUserLoading: false,
    }),
    getMyAllocations: (state) => ({
      ...state,
      isMyAllocationsFetching: true,
    }),
    getMyAllocationsSucceeded: (state, action) => ({
      ...state,
      isMyAllocationsFetching: false,
      myAllocations: action?.payload,
    }),
    getMyAllocationsFailed: (state) => ({
      ...state,
      isMyAllocationsFetching: false,
    }),
    getAllSkillGroups: (state) => ({
      ...state,
      getSkillGroupsLoading: true,
    }),
    getAllSkillGroupsSucceeded: (state, action) => ({
      ...state,
      getSkillGroupsLoading: false,
      allSkillGroups: action?.payload?.docs,
    }),
    getAllSkillGroupsFailed: (state) => ({
      ...state,
      getSkillGroupsLoading: false,
    }),
    addUserSkills: (state) => ({
      ...state,
      addUserSkillLoading: true,
    }),
    addUserSkillsSucceeded: (state, action) => ({
      ...state,
      addUserSkillLoading: false,
      userSkills: [...state.userSkills, ...action.payload],
    }),
    addUserSkillsFailed: (state) => ({
      ...state,
      addUserSkillLoading: false,
    }),
    getUserSkills: (state) => ({
      ...state,
      userSkillLoading: true,
    }),
    getUserSkillsSucceeded: (state, action) => ({
      ...state,
      userSkillLoading: false,
      userSkills: action?.payload,
    }),
    getUserSkillsFailed: (state) => ({
      ...state,
      userSkillLoading: false,
    }),
    updateUserSkills: (state) => ({
      ...state,
      updateUserLoading: true,
    }),
    updateUserSkillsSucceeded: (state, action) => ({
      ...state,
      updateUserLoading: false,
      userSkills: state.userSkills.map((userSkill) => {
        const modifiedUserSkill = action.payload.find(
          (updatedUserSkill) => updatedUserSkill?.id === userSkill?.id
        );
        return modifiedUserSkill || userSkill;
      }),
    }),
    updateUserSkillsFailed: (state) => ({
      ...state,
      updateUserLoading: false,
    }),
    deleteUserSkills: (state) => ({
      ...state,
      deleteUserSkillLoading: true,
    }),
    deleteUserSkillsSucceeded: (state, action) => ({
      ...state,
      deleteUserSkillLoading: false,
      userSkills: state.userSkills.filter(
        (userSkill) =>
          !action.payload.some((deletedUserSkillId) => deletedUserSkillId === userSkill?.id)
      ),
    }),
    deleteUserSkillsFailed: (state) => ({
      ...state,
      deleteUserSkillLoading: false,
    }),
    getClientById: (state) => ({
      ...state,
      isClientFetching: true,
    }),
    getClientByIdSucceeded: (state, action) => ({
      ...state,
      client: action?.payload,
      isClientFetching: false,
    }),
    getClientByIdFailed: (state) => ({
      ...state,
      isClientFetching: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: profileActions, reducer: profileReducer } = profileSlice;
