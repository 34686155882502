import { Avatar, Grid, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
//
import COLORS from 'features/base/constants/colors';
import { selectIsProjectFetching, selectProject } from 'features/projects/selectors';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import { ENVIRONMENT } from 'config';

/**
 * Function that renders the project details in the update allocations page
 */
const ProjectDetails = () => {
  const projectDetails = useSelector(selectProject);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  //
  return (
    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {projectDetails.logo?.length > 0 ? (
          <img
            src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${projectDetails.logo[0].url}`}
            alt="project-logo"
            style={{ height: '48px', width: '48px', borderRadius: '50%', marginRight: '5px' }}
          />
        ) : (
          <Avatar sx={{ height: '48px', width: '48px' }}>{projectDetails.name?.charAt(0)}</Avatar>
        )}
        <Typography
          variant="h1"
          sx={{
            fontSize: '2.25rem',
            fontWeight: 600,
            mb: { xs: '15px', md: 0 },
            mr: '24px',
            ml: '10px',
          }}
        >
          {isProjectFetching ? <Skeleton width={600} /> : `${projectDetails.name}`}
        </Typography>
        {projectDetails?.billingType && (
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              bgcolor: COLORS.CELESTIAL_BLUE,
              p: '7px 16px',
              ml: { xs: 0, md: '16px' },
              mr: { xs: 0, sm: '16px' },
              mb: { xs: '16px', sm: 0 },
              textAlign: 'center',
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Typography sx={{ color: '#2684ff' }}>
              {billingTypeFormatter(projectDetails?.billingType)}
            </Typography>
          </Grid>
        )}
        {projectDetails?.projectValue && (
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              bgcolor: COLORS.LAVENDER_GRAY,
              minHeight: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: '6px 18px',
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Typography variant="body1" sx={{ color: COLORS.BLUE_MAGENTA, display: 'flex' }}>
              Project Value:&nbsp;
            </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {getDollarFormattedValue(projectDetails.projectValue)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
//
export default ProjectDetails;
