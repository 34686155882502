import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid } from '@mui/material';
import moment from 'moment';
//
import { userActions } from 'features/users/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectSalaryUserList, selectAddSalaryLoading } from 'features/users/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import Select from 'features/base/components/select';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import ValidationError from 'features/base/components/validation-error';
import Popup from 'features/base/components/modal';
import salaryFromValidation from 'features/users/validation/salary-validation';
import { DatePicker, NumericInputField } from 'features/base/components';
import { AMOUNT_TYPE_ARRAY, AMOUNT_TYPES } from 'features/base/constants/amount-types';
import { PAYMENT_TYPE } from 'features/base/constants/payment-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';

/**
 * Function that defines the popup form for adding a salary to users
 * @prop {boolean} addSalariesModalOpen - boolean to show/hide the popup
 * @prop {function} setAddSalariesModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddSalariesPopup = ({ addSalariesModalOpen, setAddSalariesModalOpen, salaryHistoryUser }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const usersList = useSelector(selectSalaryUserList);
  const loading = useSelector(selectAddSalaryLoading);
  //
  const [selectedUsers, setSelectedUsers] = useState([]);
  //
  const handleOnClose = () => {
    setAddSalariesModalOpen(false);
    dispatch(notificationActions.resetNotification());
    setSelectedUsers([]);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  const getStartDates = () => {
    const selectedUserDates = [];
    selectedUsers.forEach((sUser) => {
      const found = usersList.find((user) => user.id === sUser);
      if (found) {
        selectedUserDates.push(found.currentSalary.startDate);
      }
    });
    return selectedUserDates;
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SALARY,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup open={addSalariesModalOpen} onClose={handleOnClose} mediumSize="30rem">
        <div className="header">
          <p className="modalTitle">Add Salary</p>
          <p className="username">{`${salaryHistoryUser?.firstName} ${salaryHistoryUser?.lastName}`}</p>
        </div>
        <Grid>
          <Grid>
            <Formik
              initialValues={{
                amountType: AMOUNT_TYPES.SALARY,
                startDate: '',
                amount: '',
                paymentType: PAYMENT_TYPE.MONTHLY,
                userIds: [salaryHistoryUser?.id],
              }}
              validationSchema={salaryFromValidation(getStartDates())}
              onSubmit={(values) => {
                if (
                  values.startDate &&
                  salaryHistoryUser?.currentSalary &&
                  salaryHistoryUser?.currentSalary.startDate > values.startDate
                )
                  return;
                dispatch(userActions.addSalary({ ...values }));
              }}
            >
              {({ errors, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Select
                    disabled
                    name="amountType"
                    id="amountType"
                    value={values.amountType}
                    selectLabel="amountType"
                    onChange={(event) => {
                      setFieldValue('amountType', event.target.value);
                    }}
                    items={AMOUNT_TYPE_ARRAY}
                    fullWidth
                    textLabel="Type"
                    stringFormat={capitalizeFirstLetter}
                    error={Boolean(touched.amountType && errors.amountType)}
                    errorMsg={touched?.amountType && errors?.amountType}
                  />
                  <DatePicker
                    label="Start date"
                    value={values.startDate}
                    error={Boolean(touched.startDate && errors.startDate)}
                    errorMsg={touched?.startDate && errors?.startDate}
                    views={['month', 'year']}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(event) => {
                      setFieldValue(
                        'startDate',
                        moment(event?.$d).startOf('month').format(ISO_WITHOUT_TIME)
                      );
                    }}
                  />
                  <ValidationError
                    error={
                      values.startDate &&
                      salaryHistoryUser?.currentSalary &&
                      salaryHistoryUser?.currentSalary.startDate > values.startDate
                    }
                    errorMsg="System Does not allow to add salaries for a date before that active salary"
                  />
                  <NumericInputField
                    type="text"
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                    onChange={(v) => setFieldValue('amount', v.floatValue)}
                    onBlur={handleBlur}
                    thousandSeparator=","
                    prefix="$"
                    placeholder="$"
                  />
                  <ButtonGrid
                    leftButtonText="Cancel"
                    rightButtonText={loading ? 'Submitting' : 'Submit'}
                    leftOnClick={handleOnClose}
                    rightOnClick={null}
                    rightIcon={loading ? loaderIcon : null}
                    submitDisabled={loading}
                  />
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddSalariesPopup;
