import { DatePicker } from 'features/base/components';
import './index.scss';

/**
 *  Function that defines the Start Date Field for edit current salary
 * @returns {Popup}
 */
const StartDateField = ({ formikBag: { values, setFieldValue, touched, errors }, setHide }) => (
  <DatePicker
    value={values.startDate}
    onKeyDown={(e) => e.preventDefault()}
    views={['month', 'year']}
    error={Boolean(touched.startDate && errors.startDate)}
    errorMsg={touched?.startDate && errors?.startDate}
    onChange={(event) => {
      setFieldValue('startDate', new Date(event).toISOString());
      setHide(true);
    }}
  />
);
//
export default StartDateField;
