/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  isSuccessful: false,
  departmentDesignations: [],
};
/**
 * Redux slice for the client email verification
 */
export const internalOnboardingSlice = createSlice({
  name: 'feature/internal-onboarding',
  initialState,
  reducers: {
    internalOnboarding: (state) => ({
      ...state,
      loading: true,
    }),
    internalOnboardingSuccess: (state) => ({
      ...state,
      loading: false,
      isSuccessful: true,
    }),
    internalOnboardingFailure: (state) => ({
      ...state,
      loading: false,
      isSuccessful: false,
    }),
    getDepartmentDesignations: (state) => ({
      ...state,
      loading: true,
    }),
    getDepartmentDesignationsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      departmentDesignations: action?.payload,
    }),
    getDepartmentDesignationsFailed: (state) => ({
      ...state,
      loading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: internalOnboardingSliceActions } = internalOnboardingSlice;
