import * as yup from 'yup';

/**
 * Function for vendor form validation
 */
const vendorFormValidation = yup.object().shape({
  name: yup.string().required('Please enter valid company name'),
  description: yup
    .string()
    .required('Please enter valid vendor description')
    .min(15, ({ min }) => `Description must be at least ${min} characters`)
    .max(150, ({ max }) => `Description must be at maximum of ${max} characters`),
  email: yup.string().email('Please enter valid email').required('Please enter valid email'),
});
//
export default vendorFormValidation;
