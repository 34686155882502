/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
//
import request from 'features/base/utils/request';
import { reportActions } from './slice';
import API from './constants';
/**
 * Generator function for getting allocations for the allocation report
 * @param {Object} payload
 */
function* getAllocationReportAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS, payload);
    yield put(reportActions.getAllocationReportAllocationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocationReportAllocationsFailed(error?.message));
  }
}
/**
 * Generator function for getting allocations for the team report
 * @param {Object} payload
 */
function* getTeamReportAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS, payload);
    yield put(reportActions.getTeamReportAllocationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getTeamReportAllocationsFailed(error?.message));
  }
}
/**
 * Generator function for getting allocations for the allocated vs actual report
 * @param {Object} payload
 */
function* getAllocatedActualReportAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS, payload);
    yield put(reportActions.getAllocatedActualReportAllocationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocatedActualReportAllocationsFailed(error?.message));
  }
}

/**
 * Generator function for getting allocations for the allocated vs actual report
 * @param {Object} payload
 */
function* getAllocatedActualReportExportAllocationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS, payload);
    yield put(reportActions.getAllocatedActualReportExportAllocationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocatedActualReportExportAllocationsFailed(error?.message));
  }
}

/**
 * Generator function for patching allocations in the allocation report
 * @param {Object} payload
 */
function* patchAllocationReportAllocationGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_ALLOCATION, payload);
    yield put(reportActions.patchAllocationReportAllocationSucceeded(response));
  } catch (error) {
    yield put(reportActions.patchAllocationReportAllocationFailed(error?.message));
  }
}
/**
 * Generator function for getting users for the allocation report
 * @param {Object} payload
 */
function* getAllocationReportUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getAllocationReportUsersSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocationReportUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting users for the team report
 * @param {Object} payload
 */
function* getTeamReportUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getTeamReportUsersSucceeded(response));
  } catch (error) {
    yield put(reportActions.getTeamReportUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting users for the allocated vs actual report
 * @param {Object} payload
 */
function* getAllocatedActualReportUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getAllocatedActualReportUsersSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocatedActualReportUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting users for the capacity report
 * @param {Object} payload
 */
function* getCapacityReportUserListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getCapacityReportUsersListSucceeded(response));
  } catch (error) {
    yield put(reportActions.getCapacityReportUsersListFailed(error?.message));
  }
}
/**
 * Generator function for getting users for the salary report
 * @param {Object} payload
 */
function* getSalaryReportUserListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getSalaryReportUsersListSucceeded(response));
  } catch (error) {
    yield put(reportActions.getSalaryReportUsersListFailed(error?.message));
  }
}
/**
 * Generator function for getting projects for the allocation report
 * @param {Object} payload
 */
function* getAllocationReportProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(reportActions.getAllocationReportProjectsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocationReportProjectsFailed(error?.message));
  }
}
/**
 * Generator function for getting projects for the team report
 * @param {Object} payload
 */
function* getTeamReportProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(reportActions.getTeamReportProjectsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getTeamReportProjectsFailed(error?.message));
  }
}
/**
 * Generator function for getting projects for the team report
 * @param {Object} payload
 */
function* getAllocatedActualReportProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(reportActions.getAllocatedActuaReportProjectsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocatedActuaReportProjectsFailed(error?.message));
  }
}
/**
 * Generator function for getting skill stats
 * @param {Object} payload
 */
function* getSkillStatsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SKILL_STATS, payload);
    yield put(reportActions.getSkillStatsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getSkillStatsFailed(error?.message));
  }
}
/**
 * Generator function for getting designations for the capacity report
 * @param {Object} payload
 * @returns
 */
function* getCapacityReportDesignationsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DESIGNATIONS, payload);
    yield put(reportActions.getCapacityReportDesignationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getCapacityReportDesignationsFailed(error?.message));
  }
}
/**
 * Generator function for getting work areas for the capacity report
 * @param {Object} payload
 * @returns {Object} response
 */
function* getCapacityReportWorkAreasGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_WORKAREAS, payload);
    yield put(reportActions.getCapacityReportWorkAreasSucceeded(response));
  } catch (error) {
    yield put(reportActions.getCapacityReportWorkAreasFailed(error?.message));
  }
}
/**
 * Generator function for getting capacity report users
 * @param {Object} payload
 * @returns {Object} response
 * */
function* getCapacityReportUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getCapacityReportUsersSucceeded(response));
  } catch (error) {
    yield put(reportActions.getCapacityReportUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting departments
 * @param {Object} param0
 */
function* getSalaryReportDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS, payload);
    yield put(reportActions.getSalaryReportDepartmentsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getSalaryReportDepartmentsFailed(error?.message));
  }
}
/**
 * Generator function for getting salry report users
 * @param {Object} payload
 */
function* getSalaryReportUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(reportActions.getSalaryReportUsersSucceeded(response));
  } catch (error) {
    yield put(reportActions.getSalaryReportUsersFailed(error?.message));
  }
}
/**
 * Generator function for getting allocations to be reallocated
 * @param {Object} payload
 * @returns {Object} response
 * */
function* getAllocationToBeReallocated({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATION, payload);
    yield put(reportActions.getAllocationToBeReallocatedSucceeded(response));
  } catch (error) {
    yield put(reportActions.getAllocationToBeReallocatedFailed(error?.message));
  }
}
/**
 * Generator function for getting reallocation table allocations
 * @param {Object} payload
 * @returns {Object} response
 * */
function* getReallocationTableAllocations({ payload }) {
  try {
    const response = yield call(request, API.GET_ALLOCATIONS, payload);
    yield put(reportActions.getReallocationTableAllocationsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getReallocationTableAllocationsFailed(error?.message));
  }
}
/**
 * Generator function for getting capacity report projects
 * @param {Object} payload
 * @returns {Object} response
 */
function* getCapacityReportProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(reportActions.getCapacityReportProjectsSucceeded(response));
  } catch (error) {
    yield put(reportActions.getCapacityReportProjectsFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* reportSaga() {
  yield takeLatest(
    reportActions.getAllocationReportAllocations.type,
    getAllocationReportAllocationsGenerator
  );
  yield takeLatest(reportActions.getTeamReportAllocations.type, getTeamReportAllocationsGenerator);
  yield takeLatest(
    reportActions.getAllocatedActualReportAllocations.type,
    getAllocatedActualReportAllocationsGenerator
  );
  yield takeLatest(
    reportActions.getAllocatedActualReportExportAllocations.type,
    getAllocatedActualReportExportAllocationsGenerator
  );
  yield takeLatest(
    reportActions.patchAllocationReportAllocation.type,
    patchAllocationReportAllocationGenerator
  );
  yield takeLatest(reportActions.getAllocationReportUsers.type, getAllocationReportUsersGenerator);
  yield takeLatest(reportActions.getTeamReportUsers.type, getTeamReportUsersGenerator);
  yield takeLatest(
    reportActions.getAllocatedActualReportUsers.type,
    getAllocatedActualReportUsersGenerator
  );
  yield takeLatest(
    reportActions.getCapacityReportUsersList.type,
    getCapacityReportUserListGenerator
  );
  yield takeLatest(reportActions.getSalaryReportUsersList.type, getSalaryReportUserListGenerator);
  yield takeLatest(
    reportActions.getAllocationReportProjects.type,
    getAllocationReportProjectsGenerator
  );
  yield takeLatest(reportActions.getTeamReportProjects.type, getTeamReportProjectsGenerator);
  yield takeLatest(
    reportActions.getAllocatedActualReportProjects.type,
    getAllocatedActualReportProjectsGenerator
  );
  yield takeLatest(reportActions.getSkillStats.type, getSkillStatsGenerator);
  yield takeLatest(
    reportActions.getCapacityReportDesignations.type,
    getCapacityReportDesignationsGenerator
  );
  yield takeLatest(
    reportActions.getCapacityReportWorkAreas.type,
    getCapacityReportWorkAreasGenerator
  );
  yield takeLatest(reportActions.getCapacityReportUsers.type, getCapacityReportUsersGenerator);
  yield takeLatest(
    reportActions.getSalaryReportDepartments.type,
    getSalaryReportDepartmentsGenerator
  );
  yield takeLatest(reportActions.getSalaryReportUsers.type, getSalaryReportUsersGenerator);
  yield takeLatest(reportActions.getAllocationToBeReallocated.type, getAllocationToBeReallocated);
  yield takeLatest(
    
    reportActions.getReallocationTableAllocations.type,
   
    getReallocationTableAllocations
  
  );
  yield takeLatest(
    reportActions.getCapacityReportProjects.type,
    getCapacityReportProjectsGenerator
  );
}
//
export default reportSaga;
