/**
 * Regex for phone number validation
 */
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
/**
 * Regex for character validation
 */
export const specialCharsRegExp = /[0-9a-zA-Z_ -]/;
/**
 * Regex that only allows letters (no special characters or numbers)
 */
export const nameRegex = /^[a-zA-Z ]+$/;
/**
 * Regex for validating percentage values that doesn't have fractional digits and not equal to zero
 */
export const createTeamPercentagePattern = /^[1-9][0-9]*$/;
/**
 * Regex for validating surge email accounts that have surge.global domain
 */
export const surgeEmailRegExp = /^[^@]+@surge\.global$/;
