/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  benefits: {},
  loading: false,
  departments: [],
  uploadedAttachments: [],
  departmentsLoading: false,
  addBenefitLoading: false,
  addedBenefit: {},
  benefitAddSucceeded: false,
  promotionsAddSucceeded: false,
  addPromotionsLoading: false,
  redeemedBenefit: {},
  redeemBenefitLoading: false,
  editBenefitLoading: false,
  editBenefitPromotionsLoading: false,
  editBenefitPromotions: [],
  deleteBenefitLoading: false,
  uploadedFileLoading: false,
  multiStepForm: {
    activeStep: 0,
    vendors: [],
    vendorsLoading: false,
    departments: [],
    refineUsers: [],
    refineUsersLoading: false,
  },
  deletePromotionLoading: false,
  deletePromotionResponse: {},
  addPromotionLoading: false,
  addPromotionResponse: {},
};
/**
 * Redux slice for the benefits feature
 */
export const benefitsSlice = createSlice({
  name: 'feature/benefits',
  initialState,
  reducers: {
    setInitialState: (state) => ({
      ...state,
      benefits: {
        docs: [],
      },
      loading: false,
      departments: [],
      departmentsLoading: false,
    }),
    resetBenefits: (state) => ({
      ...state,
      benefits: {
        docs: [],
      },
    }),
    resetMultiStepForm: (state) => ({
      ...state,
      multiStepForm: {
        activeStep: 0,
        vendors: [],
        departments: [],
      },
    }),
    addBenefits: (state) => ({
      ...state,
      addBenefitLoading: true,
    }),
    addBenefitsSucceeded: (state, action) => ({
      ...state,
      addBenefitLoading: false,
      benefitAddSucceeded: true,
      addedBenefit: action?.payload,
      benefits: {
        ...state.benefits,
        docs: [action?.payload, ...(state.benefits.docs ?? [])],
        totalDocs: state.benefits.totalDocs + 1,
      },
    }),
    addBenefitsFailed: (state) => ({
      ...state,
      addBenefitLoading: false,
    }),
    addPromotions: (state) => ({
      ...state,
      addPromotionsLoading: true,
    }),
    addPromotionsSucceeded: (state) => ({
      ...state,
      addPromotionsLoading: false,
      promotionsAddSucceeded: true,
    }),
    addPromotionsFailed: (state) => ({
      ...state,
      addPromotionsLoading: false,
    }),
    editBenefits: (state) => ({
      ...state,
      editBenefitLoading: true,
    }),
    editBenefitsSucceeded: (state, action) => ({
      ...state,
      editBenefitLoading: false,
      benefits: {
        ...state.benefits,
        docs: state.benefits.docs.map((benefit) => {
          if (benefit.id === action?.payload?.id) {
            return action?.payload;
          }
          return benefit;
        }),
      },
    }),
    editBenefitsFailed: (state) => ({
      ...state,
      editBenefitLoading: false,
    }),
    getBenefits: (state) => ({
      ...state,
      loading: true,
    }),
    getBenefitsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      benefits: action?.payload,
    }),
    getBenefitsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getDepartments: (state) => ({
      ...state,
      departmentsLoading: true,
    }),
    getDepartmentsSucceeded: (state, action) => ({
      ...state,
      departments: action?.payload,
      departmentsLoading: false,
    }),
    getDepartmentsFailed: (state) => ({
      ...state,
      departmentsLoading: false,
    }),
    deleteBenefits: (state) => ({
      ...state,
      deleteBenefitLoading: true,
    }),
    deleteBenefitsSucceeded: (state, action) => ({
      ...state,
      deleteBenefitLoading: false,
      benefits: {
        ...state.benefits,
        docs: state.benefits?.docs?.filter((benefit) => benefit?.id !== action?.payload?.id),
        totalDocs: state.benefits.totalDocs - 1,
      },
    }),
    deleteBenefitsFailed: (state) => ({
      ...state,
      deleteBenefitLoading: false,
    }),
    uploadAttachments: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadAttachmentsSucceeded: (state, action) => ({
      ...state,
      uploadFileLoading: false,
      uploadedAttachments: [...state.uploadedAttachments, ...(action?.payload ?? [])],
    }),
    uploadAttachmentsFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
      uploadedAttachments: [],
    }),
    clearAttachments: (state) => ({
      ...state,
      uploadedAttachments: [],
    }),
    setAttachments: (state, action) => ({
      ...state,
      uploadedAttachments: action.payload,
    }),
    getVendorsMultiStepForm: (state) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        vendorsLoading: true,
      },
    }),
    getVendorsMultiStepFormSucceeded: (state, action) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        vendors: action?.payload?.docs,
        vendorsLoading: false,
      },
    }),
    getVendorsMultiStepFormFailed: (state) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        vendorsLoading: false,
      },
    }),
    getRefineUsers: (state) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        refineUsersLoading: true,
      },
    }),
    getRefineUsersSucceeded: (state, action) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        refineUsers: action?.payload?.docs,
        refineUsersLoading: false,
      },
    }),
    getRefineUsersFailed: (state) => ({
      ...state,
      multiStepForm: {
        ...state.multiStepForm,
        refineUsersLoading: false,
      },
    }),
    getPromotionsToEdit: (state) => ({
      ...state,
      editBenefitPromotionsLoading: true,
    }),
    getPromotionsToEditSucceeded: (state, action) => ({
      ...state,
      editBenefitPromotionsLoading: false,
      editBenefitPromotions: action?.payload,
    }),
    getPromotionsToEditFailed: (state) => ({
      ...state,
      editBenefitPromotionsLoading: false,
    }),
    redeemBenefit: (state) => ({
      ...state,
      redeemBenefitLoading: true,
    }),
    redeemBenefitSucceeded: (state, action) => ({
      ...state,
      redeemBenefitLoading: false,
      redeemedBenefit: action?.payload,
    }),
    redeemBenefitFailed: (state) => ({
      ...state,
      redeemBenefitLoading: false,
    }),
    clearRedeemedBenefit: (state) => ({
      ...state,
      redeemedBenefit: {},
      redeemBenefitLoading: false,
    }),
    deletePromotion: (state) => ({
      ...state,
      deletePromotionLoading: true,
    }),
    deletePromotionSucceeded: (state, action) => ({
      ...state,
      deletePromotionLoading: false,
      deletePromotionResponse: action?.payload,
    }),
    deletePromotionFailed: (state) => ({
      ...state,
      deletePromotionLoading: false,
    }),
    addPromotion: (state) => ({
      ...state,
      addPromotionLoading: true,
    }),
    addPromotionSucceeded: (state, action) => ({
      ...state,
      addPromotionLoading: false,
      addPromotionResponse: action?.payload,
    }),
    addPromotionFailed: (state) => ({
      ...state,
      addPromotionLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: benefitsActions } = benefitsSlice;
