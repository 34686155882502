import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//
import ROUTES from 'features/base/constants/routes';
import { selectUserEmail } from 'features/base/auth/selectors';
import EMAIL_SUFFIXES from 'features/base/constants/email-suffixes';

/**
 * A PrivateRoute component for protecting routes based on the users type (internal, external)
 * @param {component} - React component to render if user is authorized
 * @returns {component}
 */
const PrivateTypeRoute = ({ component: Component }) => {
  // Determine if user is internal
  const userEmail = useSelector(selectUserEmail);
  const isAuthorized = () => userEmail.endsWith(EMAIL_SUFFIXES.INTERNAL);
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthorized() ? <Component /> : <Navigate to={ROUTES.WELCOME} />;
};
//
export default PrivateTypeRoute;
