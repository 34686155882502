/**
 * Defines a function that converts a given string to title format
 * (first letter uppercase, rest lowercase)
 * @param {String} str Input string
 * @returns {String} Formatted string
 */
export const capitalizeFirstLetter = (str) => {
  if (typeof str === 'string') {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
};

/**
 * Defines a function that removes the underscores in a given string
 * @param {String} str Input string
 * @returns {String} Formatted string
 */
export const formattedString = (str) => {
  if (typeof str === 'string') {
    return str.replace(/_/g, ' ');
  }
  return str;
};
/**
 * Check object is empty or not
 * @param {*} obj
 * @returns
 */
export const isEmpty = (obj) => obj && Object.keys(obj).length === 0;
/**
 * Defines a function that add  underscores with uppercase in a given string
 * @param {String} str Input string
 * @returns {String} Formatted string
 */
export const formattedStringUnderscore = (str) => {
  if (typeof str === 'string') {
    return str.toUpperCase().replace(/\s+/g, '_');
  }
  return str;
};
/**
 * Compare two string ignoring case sensitive
 * @param {*} str1
 * @param {*} str2
 * @returns
 * @memberof String
 * @example
 * const str1 = 'abc';
 * const str2 = 'ABC';
 * const result = compareString(str1, str2);
 * console.log(result); // true
 */
export const compareString = (str1, str2) => {
  if (typeof str1 === 'string' && typeof str2 === 'string') {
    return str1.toUpperCase() === str2.toUpperCase();
  }
  return false;
};
/**
 * Defines a function that converts a given string to capitalize format
 * @param {String} str Input string
 * @returns {String} Formatted string
 */
export const toUpperCase = (str) => {
  if (typeof str === 'string') {
    return str.toUpperCase();
  }
  return str;
};
