import { memo, Children, cloneElement, isValidElement } from 'react';
//
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
/**
 * A wrapper that checks whether a user is authorized by checking the users permissions
 * @param {ReactNode} children - Children wrapped the wrapper
 * @param {Object} requiredPermissions - Required permissions for the user to be able to access the children components
 * @param {Boolean} hide - If true, the children will not be rendered if user doesn't have the required permissions, if false the children components will be rendered, but disabled
 * @param {Boolean} operation - Logical operation that will be performed on the provided permissions array
 */
const PermissionWrapper = memo(({ children, requiredPermissions, hide, operation }) => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  if (permissionsVerified(requiredPermissions, operation)) {
    return children;
  }
  if (!hide) {
    return Children?.map(children, (child) => {
      if (!isValidElement(child)) return null;
      return cloneElement(child, {
        ...child.props,
        disabled: true,
      });
    });
  }
  //
  return null;
});
//
export default PermissionWrapper;
