import { DriveFileRenameOutline } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';

/**
 * View opex table columns - basic details
 */
export const OPEXES_TABLE_COLUMNS_BASIC = [
  {
    field: 'name',
    headerName: 'Overhead name',
    width: 200,
    valueGetter: (params) => params.row.name.opexName,
    renderCell: (params) => {
      const { opexId, opexName } = params.row.name;
      return <Typography key={opexId}>{opexName}</Typography>;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    valueGetter: (params) => params.row.description.value,
    renderCell: (params) => {
      const { value } = params.row.description;
      return (
        <Box sx={{ maxWidth: '20ch', wordWrap: 'break-word' }}>
          {value.toString().substring(0, 30)}
        </Box>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    width: 200,
    valueGetter: (params) => params.row.startDate.value,
    renderCell: (params) => {
      const { value } = params.row.startDate;
      return (
        <Typography variant="body1" sx={{ whitespace: 'nowrap' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    width: 150,
    valueGetter: (params) => params.row.endDate.value,
    renderCell: (params) => {
      const { value } = params.row.endDate;
      return (
        <Typography variant="body1" sx={{ whitespace: 'nowrap' }}>
          {value !== null ? value : '-'}
        </Typography>
      );
    },
  },
  {
    field: 'lastUpdated',
    headerName: 'Last updated',
    width: 150,
    valueGetter: (params) => params.row.lastUpdated.value,
    renderCell: (params) => {
      const { value } = params.row.lastUpdated;
      return (
        <Typography variant="body1" sx={{ whitespace: 'nowrap' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 200,
    valueGetter: (params) => params.row.amount.value,
    renderCell: (params) => {
      const { value } = params.row.amount;
      return (
        <Typography variant="body1" sx={{ width: '12ch' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'dataStatus',
    headerName: 'Status',
    width: 150,
    valueGetter: (params) => params.row.dataStatus.value,
    renderCell: (params) => {
      const { value } = params.row.dataStatus;
      return (
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              bgcolor: value === 'Active' ? COLORS.PALE_GREEN : COLORS.LAVENDER_MIST,
              p: '7px 16px',
              mr: '16px',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: value === 'Active' ? COLORS.SUCCESS_MAIN : COLORS.DEEP_INDIGO,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];
/**
 * View opexes table columns - action column
 */
export const OPEXES_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: ({ value: { opexPermissionsVerified, isLoading, handleEdit } }) =>
      opexPermissionsVerified ? (
        <IconButton sx={{ '&:hover': { color: 'blue' } }} onClick={handleEdit} readOnly={isLoading}>
          <DriveFileRenameOutline />
        </IconButton>
      ) : undefined,
  },
];
