/* eslint-disable no-underscore-dangle */
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Button as MuiButton,
  Divider,
} from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import moment from 'moment';
//
import { benefitsActions } from 'features/benefits/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectBenefitById,
  selectEditBenefitsLoader,
  selectDepartments,
  selectFileUploadLoader,
  selectUploadedAttachments,
  selectRefineUsers,
} from 'features/benefits/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import {
  MultiSelectAutoComplete,
  TextField,
  Popup,
  DragAndDropZone,
  DatePicker,
  Select,
  Autocomplete,
} from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import benefitValidation from 'features/benefits/validations/benefit-validation';
import {
  BENEFITS_ATTACHMENTS_ALLOWED_EXTENSIONS,
  BENEFITS_ATTACHMENTS_ALLOWED_FILE_TYPES,
  BENEFITS_ATTACHMENTS_MAX_SIZE,
} from 'features/base/constants/file-upload';
import { BENEFIT_CAMPAIGN_TYPES } from 'features/base/constants/benefit-types';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { processFiles } from 'features/base/helpers/file';
import { USER_LEVELS } from 'features/base/constants/user-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { isEqual, removeUnchanged } from 'features/base/helpers/object';
import { PermissionWrapper } from 'features/base/auth/components';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import EditPromotionsPopup from 'features/benefits/components/edit-benefit-modal/edit-promotions-modal';

/**
 * Function that defines the popup form for adding a new project
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} benefitId - id of the benefit to be edited
 * @returns {Popup}
 */
const EditBenefitsPopup = ({ editModalOpen, setEditModalOpen, benefitId }) => {
  const dispatch = useDispatch();
  //
  const benefitToEdit = useSelector((state) => selectBenefitById(state, benefitId));
  const allDepartments = useSelector(selectDepartments);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectEditBenefitsLoader);
  const uploadedAttachments = useSelector(selectUploadedAttachments);
  const attachmentLoader = useSelector(selectFileUploadLoader);
  const refineUsers = useSelector(selectRefineUsers);
  //
  const [promotionModalOpen, setPromotionModalOpen] = useState(false);
  //
  const initialValues = {
    title: benefitToEdit?.title,
    description: benefitToEdit?.description,
    departments: benefitToEdit?.departments,
    levels: benefitToEdit?.levels?.map((level) => ({ id: level, name: level })),
    expiryDate: benefitToEdit?.expiryDate
      ? moment(benefitToEdit?.expiryDate).format(ISO_WITHOUT_TIME)
      : null,
    showExpiryDate: benefitToEdit?.showExpiryDate,
    link: benefitToEdit?.link,
    isEmailEnabled: benefitToEdit?.isEmailEnabled,
    attachments: benefitToEdit?.attachments,
    campaignType: benefitToEdit?.campaignType,
    vendorId: benefitToEdit?.vendorId?._id,
    excludedUsers:
      benefitToEdit?.excludedUsers?.map((user) => ({ id: user._id, name: user.email })) || [],
    additionalUsers:
      benefitToEdit?.additionalUsers?.map((user) => ({ id: user._id, name: user.email })) || [],
  };
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(benefitsActions.clearAttachments());
  };
  //
  const handleOnDeleteFile = (fileToRemove) => {
    dispatch(
      benefitsActions.setAttachments(
        uploadedAttachments?.filter(
          (uploadedAttachment) => uploadedAttachment?.name !== fileToRemove?.name
        )
      )
    );
  };
  //
  const handleOnAddFiles = (filesToAdd) => {
    if (filesToAdd?.length > 0) {
      const formData = processFiles(filesToAdd);
      dispatch(benefitsActions.uploadAttachments({ files: formData }));
    }
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    if (editModalOpen) {
      dispatch(benefitsActions.setAttachments(initialValues?.attachments || []));
    }
  }, [editModalOpen]);
  //
  useEffect(() => {
    dispatch(
      benefitsActions.getRefineUsers({ query: `pagination=false&sortBy=name:asc&type=INTERNAL` })
    );
  }, []);
  //
  useEffect(() => {
    if (benefitToEdit?.id && promotionModalOpen) {
      dispatch(benefitsActions.getPromotionsToEdit({ benefitId: benefitToEdit.id }));
    }
  }, [benefitToEdit, promotionModalOpen]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <EditPromotionsPopup
        promoEditModalOpen={promotionModalOpen}
        setPromoEditModalOpen={setPromotionModalOpen}
        benefitId={benefitId}
      />
      <Popup open={editModalOpen} onClose={handleOnClose} title="Edit benefit" mediumSize="888px">
        <Formik
          initialValues={initialValues}
          validationSchema={benefitValidation}
          onSubmit={(values) => {
            let benefitData = { ...values };
            benefitData.attachments = uploadedAttachments?.map((attachment) => ({
              ...attachment,
              url: initialValues?.attachments?.find((att) => isEqual(att, attachment))
                ? attachment?.url
                : encodeURI(attachment?.url),
            }));
            if (benefitData?.link) {
              benefitData.link = encodeURI(benefitData.link);
            }
            benefitData.excludedUsers = benefitData?.excludedUsers?.map((user) => user.id) || [];
            benefitData.additionalUsers =
              benefitData?.additionalUsers?.map((user) => user.id) || [];
            benefitData.departments = benefitData?.departments?.map((department) => department?.id);
            benefitData.departments = benefitData.departments.filter((dep) => dep !== 'all');
            benefitData = removeUnchanged(initialValues, benefitData);
            benefitData.levels = benefitData?.levels?.map((level) => level?.id);
            delete benefitData?.vendorId;
            delete benefitData?.campaignType;
            dispatch(benefitsActions.editBenefits({ id: benefitId, ...benefitData }));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Select
                id="campaignType"
                name="campaignType"
                value={values.campaignType}
                selectLabel="campaignType"
                disabled
                onChange={(event) => {
                  setFieldValue('campaignType', event.target.value);
                }}
                items={Object.keys(BENEFIT_CAMPAIGN_TYPES).map((key) => ({
                  key,
                  value: BENEFIT_CAMPAIGN_TYPES[key],
                }))}
                textLabel="Campaign Type"
                renderValue={(value) => formattedString(capitalizeFirstLetter(value))}
                stringFormat={(value) => formattedString(capitalizeFirstLetter(value))}
                error={Boolean(touched.campaignType && errors.campaignType)}
                errorMsg={touched?.campaignType && errors?.campaignType}
              />

              {values?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN && (
                <Autocomplete
                  id="vendorId"
                  name="vendorId"
                  disableClearable
                  options={[
                    { id: benefitToEdit?.vendorId?._id, label: benefitToEdit?.vendorId?.name },
                  ]}
                  label="Vendor"
                  onChange={(_, newValue) => {
                    setFieldValue('vendorId', newValue.id);
                  }}
                  controlled
                  multiple={false}
                  disabled
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id && option.label === value.label
                  }
                  getOptionLabel={(option) => option?.label || ''}
                  error={Boolean(touched.vendorId && errors.vendorId)}
                  errorMsg={touched?.vendorId && errors?.vendorId}
                  onClose={() => setFieldTouched('vendorId', true)}
                  value={(() => {
                    const vendor = benefitToEdit?.vendorId;
                    return vendor ? { id: vendor.id, label: vendor.name } : null;
                  })()}
                  sx={{
                    pb: 1,
                  }}
                />
              )}

              <TextField
                type="text"
                name="title"
                value={values.title}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Title"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Description"
                height={50}
                multiline
                rows={4}
              />

              <Typography fontSize="16px" className="field-label">
                Departments
              </Typography>
              <MultiSelectAutoComplete
                id="departments"
                name="departments"
                formikKey="departments"
                placeholder="Select department"
                onClose={() => setFieldTouched('departments', true)}
                errorMsg={touched.departments && errors.departments}
                error={Boolean(touched.departments && errors.departments)}
                options={[{ id: 'all', name: 'All Departments' }].concat(
                  allDepartments?.map((department) => ({
                    id: department.id,
                    name: department.name,
                  })) || []
                )}
                setSelectedOptions={(field, value) => {
                  //
                  if (value.find((val) => val.id === 'all')) {
                    if (value.length !== allDepartments.length) {
                      setFieldValue(
                        'departments',
                        allDepartments.concat({ id: 'all', name: 'All Departments' })
                      );
                    } else if (value.length === allDepartments.length) {
                      const missingDepartments = allDepartments.filter(
                        (department) => !value.find((val) => val.id === department.id)
                      );
                      setFieldValue(
                        'departments',
                        value.filter(
                          (val) =>
                            val.id !== 'all' && !missingDepartments.find((dep) => dep.id === val.id)
                        )
                      );
                    }
                  } else if (value.length === allDepartments.length) {
                    setFieldValue('departments', []);
                  } else {
                    setFieldValue('departments', value);
                  }
                  setFieldValue('additionalUsers', []);
                  setFieldValue('excludedUsers', []);
                }}
                selectedOptions={values.departments}
              />

              <Typography fontSize="16px" className="field-label">
                Employee levels
              </Typography>
              <MultiSelectAutoComplete
                id="levels"
                name="levels"
                options={[
                  ...Object.values(USER_LEVELS).map((level) => ({ id: level, name: level })),
                ]}
                selectedOptions={values.levels}
                setSelectedOptions={(field, value) => {
                  setFieldValue('levels', value);
                  setFieldValue('additionalUsers', []);
                  setFieldValue('excludedUsers', []);
                }}
                formikKey="levels"
                placeholder="Select level"
                error={Boolean(touched.levels && errors.levels)}
                errorMsg={touched.levels && errors.levels}
                onClose={() => setFieldTouched('levels', true)}
                optionsFormatter={(value) => value}
              />

              <Alert variant="outlined" severity="info" sx={{ mt: 2, mb: 2 }}>
                Changing the departments or levels will reset the excluded and additional users, You
                can reselect them after changing the departments or levels.
              </Alert>

              <Typography fontSize="16px" className="field-label">
                Excluded Users
              </Typography>
              <MultiSelectAutoComplete
                id="excludedUsers"
                name="excludedUsers"
                options={(() => {
                  // return users that are matched with the selected departments and levels
                  if (!values.departments.length || !values.levels.length) return [];
                  return (
                    refineUsers
                      ?.map((user) => {
                        const departmentId =
                          user?.currentUserDepartmentDesignationId?.departmentDesignationId
                            ?.departmentId?.id;
                        const level = user?.level;
                        if (!departmentId || !level) return null;
                        if (
                          values.departments.find((department) => department.id === departmentId) &&
                          values.levels.find((userLevel) => userLevel.id === level)
                        ) {
                          return { id: user.id, name: user.email };
                        }
                        return null;
                      })
                      .filter((user) => user) || []
                  );
                })()}
                selectedOptions={values.excludedUsers}
                setSelectedOptions={setFieldValue}
                formikKey="excludedUsers"
                placeholder="Select excluded users"
                error={Boolean(touched.excludedUsers && errors.excludedUsers)}
                errorMsg={touched.excludedUsers && errors.excludedUsers}
                onClose={() => setFieldTouched('excludedUsers', true)}
                disabled={!values.departments.length || !values.levels.length}
              />

              <Typography fontSize="16px" className="field-label">
                Additional Users
              </Typography>
              <MultiSelectAutoComplete
                id="additionalUsers"
                name="additionalUsers"
                options={(() => {
                  if (!values.departments.length || !values.levels.length) return [];
                  const includedUsers =
                    refineUsers
                      ?.map((user) => {
                        const departmentId =
                          user?.currentUserDepartmentDesignationId?.departmentDesignationId
                            ?.departmentId?.id;
                        const level = user?.level;
                        if (!departmentId || !level) return null;
                        if (
                          values.departments.find((department) => department.id === departmentId) &&
                          values.levels.find((userLevel) => userLevel.id === level)
                        ) {
                          return { id: user.id, name: user.email };
                        }
                        return null;
                      })
                      .filter((user) => user) || [];
                  // remove the included users from the refine users list and return
                  return (
                    refineUsers
                      ?.filter((user) => !includedUsers.find((incUser) => incUser.id === user.id))
                      ?.map((user) => ({ id: user.id, name: user.email })) || []
                  );
                })()}
                getOptionDisabled={(option) =>
                  values.excludedUsers.find((excludedUser) => excludedUser.id === option.id)
                }
                selectedOptions={values.additionalUsers}
                setSelectedOptions={setFieldValue}
                formikKey="additionalUsers"
                placeholder="Select additional users"
                error={Boolean(touched.additionalUsers && errors.additionalUsers)}
                errorMsg={touched.additionalUsers && errors.additionalUsers}
                onClose={() => setFieldTouched('additionalUsers', true)}
                disabled={!values.departments.length || !values.levels.length}
              />

              <DatePicker
                name="expiryDate"
                label="Expiry date (Optional)"
                value={values.expiryDate}
                onChange={(event) => {
                  setFieldValue('expiryDate', moment(event?.$d).format(ISO_WITHOUT_TIME));
                }}
                onKeyDown={(e) => e.preventDefault()}
                error={Boolean(touched.expiryDate && errors.expiryDate)}
                errorMsg={touched?.expiryDate && errors?.expiryDate}
                sx={{ pb: 1 }}
                placeholder="Select date"
                minDate={
                  moment(values?.expiryDate).isBefore(moment().toDate())
                    ? values?.expiryDate
                    : moment().toDate()
                }
              />
              <FormControlLabel
                label="Show expiry date"
                control={
                  <Checkbox
                    onChange={(event) => {
                      setFieldValue('showExpiryDate', event.target.checked);
                    }}
                    value={values.showExpiryDate}
                    name="showExpiryDate"
                    checked={values.showExpiryDate}
                  />
                }
                sx={{
                  mb: 1,
                }}
              />
              <DragAndDropZone
                label="Upload attachments"
                allowedExtensions={BENEFITS_ATTACHMENTS_ALLOWED_EXTENSIONS}
                allowedMimetypes={BENEFITS_ATTACHMENTS_ALLOWED_FILE_TYPES}
                maxSize={BENEFITS_ATTACHMENTS_MAX_SIZE}
                loading={attachmentLoader}
                disabled={loading}
                onAdd={handleOnAddFiles}
                onDelete={handleOnDeleteFile}
                uploadedFiles={uploadedAttachments}
              />
              <TextField
                type="text"
                name="link"
                value={values.link}
                error={Boolean(touched?.link && errors?.link)}
                helperText={touched?.link && errors?.link}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Add link (Optional)"
                placeholder={'https://drive.goog.com'}
                fullWidth
              />
              <FormControlLabel
                label="Notify users via email"
                control={
                  <Checkbox
                    onChange={(event) => {
                      setFieldValue('isEmailEnabled', event.target.checked);
                    }}
                    value={values.isEmailEnabled}
                    name="isEmailEnabled"
                    checked={values.isEmailEnabled}
                  />
                }
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }} />

              {benefitToEdit?.campaignType === BENEFIT_CAMPAIGN_TYPES.PROMOTION_CAMPAIGN && (
                <>
                  <Divider />
                  <MuiButton
                    variant="filled"
                    endIcon={<OpenInNew />}
                    onClick={() => setPromotionModalOpen(true)}
                  >
                    Edit Promo Codes
                  </MuiButton>
                  <Divider />
                </>
              )}

              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading || attachmentLoader}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditBenefitsPopup;
