import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/clients'] || initialState;
/**
 * Selector for getting loading state
 */
export const selectLoader = createSelector([selectDomain], (clientState) => clientState.loading);
/**
 * Selector for getting file upload loading state
 */
export const selectFileUploadLoader = createSelector(
  [selectDomain],
  (clientState) => clientState.fileUploadLoading
);
/**
 * Selector for getting client fetching loading state
 */
export const selectIsClientFetching = createSelector(
  [selectDomain],
  (clientState) => clientState.isClientFetching
);
/**
 * Selector for getting the uploaded logo
 */
export const selectUploadedImage = createSelector(
  [selectDomain],
  (clientState) => clientState.uploadedImage
);
/**
 * Selector for getting all projects list
 */
export const selectAllProjectsList = createSelector(
  [selectDomain],
  (clientState) => clientState.allProjectsList
);
/**
 * Selector for getting the clients list
 */
export const selectClientList = createSelector(
  [selectDomain],
  (clientState) => clientState.clientList
);
/**
 * Selector for getting the dashboard client
 */
export const selectClient = createSelector([selectDomain], (clientState) => clientState.client);
/**
 * Selector for getting a client by id
 */
export const selectClientById = createSelector(
  [selectClientList, (_, clientId) => clientId],
  (clientList, clientId) => clientList?.docs?.find((client) => client.id === clientId) || null
);
/**
 * Selector for getting the project allocations list
 */
export const selectProjectAllocationsList = createSelector(
  [selectDomain],
  (clientState) => clientState.projectAllocationsList
);
/**
 * Selector for getting loading state
 */
export const selectAddClientLoader = createSelector(
  [selectDomain],
  (clientState) => clientState.addClientLoading
);
/**
 * Selector for getting loading state
 */
export const selectEditClientLoader = createSelector(
  [selectDomain],
  (clientState) => clientState.editClientLoading
);
/**
 * Selector for getting invite client user loading state
 */
export const selectInviteClientUserLoading = createSelector(
  [selectDomain],
  (clientState) => clientState.inviteClientUserLoading
);
/**
 * Selector for getting a client user by id
 */
export const selectClientUserById = createSelector(
  [selectClient, (_, userId) => userId],
  (client, userId) => client?.users?.find((clientUser) => clientUser?.userId?.id === userId) || null
);
/**
 * Selector for getting loading state
 */
export const selectDeleteClientUserLoader = createSelector(
  [selectDomain],
  (clientState) => clientState.deleteClientUserLoading
);
