import { Alert, Typography, Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
//
import { notificationActions } from 'features/base/notifications/slice';
import TOAST_TYPES from 'features/base/constants/toast-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { popUpFormValidation } from 'features/projects/validation/project-validation';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { PROJECT_TYPES } from 'features/base/constants/project-types';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import COLORS from 'features/base/constants/colors';
import calculations from 'features/base/helpers/calculations';
import { MONTHS } from 'features/base/constants/date-formatting';
import { reportActions } from 'features/reports/slice';
import { selectAllocationReportPatchAllocationsLoading } from 'features/reports/selectors';

/**
 * Function that defines the popup form for editing hours and hourly rates of users allocation for a project
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @returns {Popup}
 */
const EditHoursPopup = ({ editModalOpen, setEditModalOpen, selectedAllocation }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAllocationReportPatchAllocationsLoading);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  const getRatePerHour = () => {
    // If project has an hourly rate, use the stored hourly rate
    if (calculations.hasHourlyRate(selectedAllocation?.projectId?.billingType)) {
      return selectedAllocation?.hourlyRate;
    }
    // If not, calculate it
    if (selectedAllocation?.revenue && selectedAllocation?.allocatedHours) {
      return selectedAllocation.revenue / selectedAllocation.allocatedHours;
    }
    return 0;
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Update allocation"
        mediumSize="660px"
      >
        <Box sx={{ textAlign: 'left' }}>
          <Box sx={{ alignItems: 'left' }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography sx={{ color: COLORS.GRAYISH_PURPLE, fontWeight: 'bold' }}>
                  {selectedAllocation?.projectId?.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ color: COLORS.GRAYISH_PURPLE, fontWeight: 'bold' }}
                >{`${selectedAllocation?.userId?.firstName} ${selectedAllocation?.userId?.lastName}`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: COLORS.GRAYISH_PURPLE, fontWeight: 'bold' }}>{`${
                  MONTHS[selectedAllocation.month - 1]
                }/${selectedAllocation?.year}`}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography sx={{ marginTop: 2, fontWeight: 'bold' }}>Current value</Typography>
          <Box sx={{ display: 'flex', alignItems: 'left', marginBottom: 2, marginTop: 2 }}>
            <Grid container spacing={2} marginLeft={0.5}>
              <Grid
                item
                xs={3}
                sx={{
                  border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                  backgroundColor: COLORS.WHITE,
                }}
              >
                <Typography sx={{ marginRight: 3, color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                  Hours
                </Typography>
                <Typography sx={{ marginRight: 3 }}>
                  {selectedAllocation?.allocatedHours}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  sx={{
                    marginRight: 3,
                    color: COLORS.SECONDARY_MAIN,
                    fontWeight: 'bold',
                    fontSize: 20,
                  }}
                >
                  *
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                  backgroundColor: COLORS.WHITE,
                }}
              >
                <Typography sx={{ color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                  Rate per hour
                </Typography>
                <Typography>{getRatePerHour()}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography sx={{ marginRight: 3, fontWeight: 'bold', fontSize: 20 }}>=</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                  backgroundColor: COLORS.WHITE,
                }}
              >
                <Typography sx={{ color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                  Revenue
                </Typography>
                <Typography sx={{}}>
                  {selectedAllocation?.projectId?.type === PROJECT_TYPES.CLIENT
                    ? selectedAllocation?.revenue
                    : 0}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography sx={{ marginTop: 2, marginBottom: 1, fontWeight: 'bold' }}>
            New Value
          </Typography>
          <Formik
            initialValues={{
              billingType: selectedAllocation?.projectId?.billingType,
              hours: selectedAllocation?.allocatedHours ? selectedAllocation.allocatedHours : 0,
              ratePerHour: getRatePerHour(),
            }}
            validationSchema={popUpFormValidation}
            onSubmit={(values) => {
              const newAllocation = selectedAllocation?.allAllocations?.map((monthlyAllocation) => {
                const type = selectedAllocation?.projectId?.type;
                const billingType = selectedAllocation?.projectId?.billingType;
                if (
                  billingType === PROJECT_BILLING_TYPES.FIXED_BID ||
                  type === PROJECT_TYPES.INTERNAL ||
                  type === PROJECT_TYPES.PRODUCT ||
                  type === PROJECT_TYPES.PRE_SALE
                ) {
                  return {
                    year: monthlyAllocation.year.toString(),
                    month: MONTHS[monthlyAllocation.month - 1],
                    hourlyRate: monthlyAllocation?.hourlyRate,
                    hours:
                      monthlyAllocation?.month === selectedAllocation?.month
                        ? parseInt(values.hours, 10)
                        : monthlyAllocation.allocatedHours,
                  };
                }
                if (
                  billingType === PROJECT_BILLING_TYPES.RETAINER ||
                  billingType === PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE ||
                  billingType === PROJECT_BILLING_TYPES.STAFF_AUGMENTATION
                ) {
                  return {
                    year: monthlyAllocation.year.toString(),
                    month: MONTHS[monthlyAllocation.month - 1],
                    hours: parseInt(values.hours, 10),
                    hourlyRate: parseInt(values.ratePerHour, 10),
                  };
                }
                // TIME & MATERIAL
                return {
                  year: monthlyAllocation.year.toString(),
                  month: MONTHS[monthlyAllocation.month - 1],
                  hours:
                    monthlyAllocation.month === selectedAllocation.month
                      ? parseInt(values.hours, 10)
                      : monthlyAllocation.allocatedHours,
                  hourlyRate: parseInt(values.ratePerHour, 10),
                };
              });
              dispatch(
                reportActions.patchAllocationReportAllocation({
                  allocation: newAllocation,
                  isAllocatedPercentageLocked: true,
                  allocationId: selectedAllocation?.id,
                })
              );
            }}
          >
            {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
              <form noValidate onSubmit={handleSubmit} className="form">
                {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                  <Alert sx={{ mb: 3 }} severity={notification?.type}>
                    {notification?.message}
                  </Alert>
                )}
                <Box sx={{ display: 'flex', alignItems: 'left', marginTop: 2 }}>
                  <Grid container spacing={2} marginLeft={0.5}>
                    <Grid
                      item
                      data-testid="hours-input"
                      xs={3}
                      sx={{
                        border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                        backgroundColor: COLORS.WHITE,
                        paddingRight: 2,
                      }}
                    >
                      <Typography sx={{ color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                        Hours
                      </Typography>
                      <TextField
                        type="text"
                        name="hours"
                        value={values.hours}
                        error={Boolean(touched.hours && errors.hours)}
                        helperText={touched.hours && errors.hours}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        sx={{
                          marginRight: 3,
                          color: COLORS.SECONDARY_MAIN,
                          fontWeight: 'bold',
                          fontSize: 20,
                        }}
                      >
                        *
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      data-testid="rate-per-hour-input"
                      sx={{
                        border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                        backgroundColor: COLORS.WHITE,
                        paddingRight: 2,
                      }}
                    >
                      <Typography sx={{ color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                        Rate per hour
                      </Typography>
                      {calculations.hasHourlyRate(selectedAllocation?.projectId.billingType) ? (
                        <TextField
                          type="text"
                          name="ratePerHour"
                          value={values.ratePerHour}
                          error={Boolean(touched.ratePerHour && errors.ratePerHour)}
                          helperText={touched.ratePerHour && errors.ratePerHour}
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{ marginRight: 3, paddingRight: 0 }}
                          my={2}
                          placeholder="$"
                        />
                      ) : (
                        <Typography>{values.ratePerHour}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ marginRight: 3, fontWeight: 'bold', fontSize: 20 }}>
                        =
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        border: `1px solid ${COLORS.TRANSPARENT_DARK_PURPLE}`,
                        backgroundColor: COLORS.WHITE,
                      }}
                    >
                      <Typography sx={{ color: COLORS.NAVY_BLUE, fontWeight: 'bold' }}>
                        Revenue
                      </Typography>
                      <Typography>{(values?.ratePerHour ?? 0) * (values?.hours ?? 0)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <ButtonGrid
                  leftButtonText="Cancel"
                  rightButtonText={loading ? 'Submitting' : 'Update'}
                  leftOnClick={handleOnClose}
                  rightOnClick={null}
                  rightIcon={loading ? loaderIcon : null}
                  submitDisabled={loading}
                />
              </form>
            )}
          </Formik>
        </Box>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditHoursPopup;
