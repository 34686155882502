import { createSlice } from '@reduxjs/toolkit';
import { ARSENAL_PAGINATION_LIMIT } from 'features/base/constants/pagination';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  arsenal: {},
  users: {},
  addArsenalItemLoading: false,
  getArsenalLoading: false,
  editArsenalItemLoading: false,
  getUsersLoading: false,
};
/**
 * Redux slice for the arsenal feature
 */
export const arsenalSlice = createSlice({
  name: 'feature/arsenal',
  initialState,
  reducers: {
    addArsenalItem: (state) => ({
      ...state,
      addArsenalItemLoading: true,
    }),
    addArsenalItemSucceeded: (state, action) => ({
      ...state,
      addArsenalItemLoading: false,
      arsenal: {
        ...state.arsenal,
        docs:
          // Custom logic to handle the display of the new item based on the pagination limit
          state.arsenal?.docs?.length >= ARSENAL_PAGINATION_LIMIT
            ? [action?.payload, ...(state.arsenal?.docs?.slice(0, -1) ?? [])]
            : [action?.payload, ...(state.arsenal?.docs ?? [])],
        totalDocs: (state.arsenal?.totalDocs ?? 0) + 1,
      },
    }),
    addArsenalItemFailed: (state) => ({
      ...state,
      addArsenalItemLoading: false,
    }),
    //
    getArsenal: (state) => ({
      ...state,
      getArsenalLoading: true,
    }),
    getArsenalSucceeded: (state, action) => ({
      ...state,
      getArsenalLoading: false,
      arsenal: action?.payload,
    }),
    getArsenalFailed: (state) => ({
      ...state,
      getArsenalLoading: false,
      arsenal: {},
    }),
    //
    editArsenalItem: (state) => ({
      ...state,
      editArsenalItemLoading: true,
    }),
    editArsenalItemSucceeded: (state, action) => ({
      ...state,
      editArsenalItemLoading: false,
      arsenal: {
        ...state.arsenal,
        docs: state.arsenal?.docs?.map((arsenalItem) =>
          arsenalItem.id === action?.payload?.id ? action?.payload : arsenalItem
        ),
      },
    }),
    editArsenalItemFailed: (state) => ({
      ...state,
      editArsenalItemLoading: false,
    }),
    //
    getUsers: (state) => ({
      ...state,
      getUsersLoading: true,
    }),
    getUsersSucceeded: (state, action) => ({
      ...state,
      getUsersLoading: false,
      users: action?.payload,
    }),
    getUsersFailed: (state) => ({
      ...state,
      getUsersLoading: false,
    }),
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: arsenalActions } = arsenalSlice;
