/**
 * Defines the API paths for users
 */
const API = {
  ADD_USER: {
    path: '/users',
    method: 'POST',
  },
  PATCH_USER: {
    path: '/users/:userId',
    method: 'PATCH',
  },
  GET_ROLES: {
    path: '/roles',
    method: 'GET',
  },
  GET_USERS: {
    path: '/users?:query',
    method: 'GET',
  },
  POST_SALARY: {
    path: '/users/salaries',
    method: 'POST',
  },
  POST_BULK_SALARY_FILE: {
    path: '/users/salaries/upload',
    method: 'POST',
  },
  POST_BULK_USER_DATA_FILE: {
    path: '/users/upload-bulk-data',
    method: 'POST',
  },
  GET_SALARY_HISTORY_OF_USER: {
    path: 'users/:userId/salaries',
    method: 'GET',
  },
  UPDATE_CURRENT_SALARY: {
    path: 'users/:userId/salaries/:salaryId',
    method: 'PATCH',
  },
  GET_DESIGNATIONS: {
    path: '/designations?:query',
    method: 'GET',
  },
  GET_WORK_AREA: {
    path: '/work-areas?:query',
    method: 'GET',
  },
  GET_DEPARTMENTS: {
    path: '/departments',
    method: 'GET',
  },
  UPDATE_ROLE: {
    path: '/users/:userId/role',
    method: 'PATCH',
  },
  GET_USERS_PERMISSIONS: {
    path: '/users/:userId/permissions',
    method: 'GET',
  },
  GET_USERS_SALARIES: {
    path: '/users/salaries?:query',
    method: 'GET',
  },
};
//
export default API;
