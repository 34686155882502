/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Checkbox,
  Stack,
  IconButton,
} from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { formattedString } from 'features/base/helpers/strings';
import { PERMISSION_DOMAIN_DESCRIPTIONS } from 'features/base/constants/permissions';
import COLORS from 'features/base/constants/colors';
/**
 * Returns a matrix of checkboxes
 * @prop {List} rows - The rows (vertical) to be rendered on the matrix
 * @prop {Function} handleClick - Handler function when clicking a checkbox in the matrix
 * @returns
 */
const Matrix = ({ rows, handleClick, sx = {} }) => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  //
  const handleOnClick = (key) => {
    setOpen(true);
    setAlertMessage(PERMISSION_DOMAIN_DESCRIPTIONS[key]);
  };
  return (
    <TableContainer component={Paper}>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: COLORS.ROYAL_BLUE }}>
          Permission Info
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alertMessage}</DialogContentText>
        </DialogContent>
      </Dialog>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={sx}>All</TableCell>
            <TableCell sx={sx}>View</TableCell>
            <TableCell sx={sx}>Create</TableCell>
            <TableCell sx={sx}>Update</TableCell>
            <TableCell sx={sx}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((val, i) => (
            <TableRow key={val.key}>
              <TableCell sx={sx}>
                <Stack direction="row" alignItems="center">
                  <IconButton size="small" onClick={() => handleOnClick(val.key)}>
                    <ContactSupportIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>
                  {'     '}
                  {formattedString(val.key)}
                </Stack>
              </TableCell>
              {val?.values?.map((checked, index) => (
                <TableCell key={index}>
                  <Checkbox
                    onChange={() => handleClick(i, index)}
                    name="permission"
                    checked={checked}
                    disabled={checked === null}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
//
export default Matrix;
