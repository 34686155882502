/**
 * Define entire application timeout intervals
 */
const TIME_OUTS = {
  REDIRECT_TO_WELCOME: 4,
  REDIRECT_TO_ONBOARD: 4,
  REDIRECT_TO_FORGOT_PASSWORD: 4,
  SESSION_OBSERVER_TIME: 60000,
  DEBOUNCE: 500,
  ALLOCATION_FETCH_DEBOUNCE: 1500,
  USER_FETCH_DEBOUNCE: 1500,
  COMMENT_REFETCH: 5000,
};
//
export default TIME_OUTS;
