/**
 * Available tool types
 * @type {{ONETIME: string, DAILY: string, MONTHLY: string, ANNUALLY: string}}
 */
const TOOL_TYPES = {
  ONETIME: 'ONETIME',
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY',
};
/**
 * Available tool types in array format
 * @type {{ key: number, value: string }[]}
 * @example
 * const toolType = TOOL_TYPE_ARRAY.find((toolType) => toolType.value === TOOL_TYPES.ONETIME);
 * console.log(toolType); // { key: 1, value: 'ONETIME' }
 * console.log(toolType.key); // 1
 * console.log(toolType.value); // 'ONETIME'
 */
export const TOOL_TYPE_ARRAY = [
  { key: 1, value: TOOL_TYPES.ONETIME },
  { key: 2, value: TOOL_TYPES.DAILY },
  { key: 3, value: TOOL_TYPES.MONTHLY },
  { key: 4, value: TOOL_TYPES.ANNUALLY },
];
//
export default TOOL_TYPES;
