/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
//
import request from 'features/base/utils/request';
import { getAuth, signOut } from 'firebase/auth';
import { authActions } from './slice';
import API from './constants';
/**
 * Generator function for getting refresh token
 * @param {Object} payload
 */
export function* renewTokens({ payload }) {
  try {
    const response = yield call(request, API.POST_REFRESH_TOKENS, payload);
    yield put(authActions.refreshTokenSucceeded(response));
  } catch (err) {
    yield put(authActions.refreshTokenFailed(err?.message));
  }
}
/**
 * Generator function for the logout process
 * @param {Object} payload
 */
export function* logoutGenerator() {
  try {
    // Perform the firebase logout
    const firebaseAuth = getAuth()
    yield call(signOut, firebaseAuth);
    yield put(authActions.logoutSucceeded());
  } catch (err) {
    yield put(authActions.logoutFailed(err?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* authSaga() {
  yield takeLatest(authActions.refreshToken.type, renewTokens);
  yield takeLatest(authActions.logout.type, logoutGenerator);
}
//
export default authSaga;
