import { DEFAULT_DATA_GRID_PAGE_SIZE } from 'features/base/constants/pagination';
import './index.scss';
import COLORS from 'features/base/constants/colors';
import StyledDataGrid from './styles';
import CustomNoRowsOverlay from '../no-rows';
import CustomNoResultsOverlay from '../no-results';

/**
 * @param {Array} columns - Columns of the data grid
 * @param {Array} rows - Rows of the data grid
 * @param {Boolean} loading -Boolean indicating the loading status of the data gird
 * @param {Number}  page - Page to be rendered
 * @param {Number} limit - Maximum number of items for the data grid
 * @param {Number} totalDocs - Total number of items
 * @param {Function} handleChangePage - Handler function for the page changing
 * @param {Boolean} serverSidePagination - If false, pagination is done directly in frontend (default is true)
 * @param {Boolean} rowHeight - Height of each row in the grid
 * @param {Function} getRowClassName - Function to provide a custom class name
 * @param {Boolean} clickable - Indicates if the rows in the data grid is clickable (updates the design accordingly)
 * @returns {DataGrid}
 */
const DataGrid = ({
  columns,
  rows,
  loading,
  page,
  limit,
  totalDocs,
  handleChangePage,
  sx,
  onRowClick,
  serverSidePagination = true,
  rowHeight,
  getRowClassName,
  clickable = false,
  ...rest
}) => {
  const getEvenRowClassName = (params) => {
    if (params.indexRelativeToCurrentPage % 2 === 0) {
      return clickable ? 'data-grid-even-row-clickable' : 'data-grid-even-row-unclickable';
    }
    return clickable ? 'data-grid-odd-row-clickable' : 'data-grid-odd-row-unclickable';
  };
  //
  const pageSizeValidator = (pageSize) => {
    if (pageSize > 100) return DEFAULT_DATA_GRID_PAGE_SIZE;
    return pageSize;
  };
  //
  const getSx = {
    width: '100%',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: COLORS.SKY_BLUE,
      color: COLORS.NAVY_BLUE,
      fontWeight: 600,
      paddingTop: '18px',
      paddingBottom: '18px',
      fontSize: '1rem',
      borderBottom: `1px solid ${COLORS.ELECTRIC_BLUE}`,
      borderTop: `1px solid ${COLORS.ELECTRIC_BLUE}`,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
    ...sx,
  };
  //
  return serverSidePagination ? (
    <StyledDataGrid
      sx={getSx}
      autoHeight
      rowHeight={rowHeight || 52}
      rows={rows}
      columns={columns}
      rowCount={totalDocs}
      onRowClick={onRowClick}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        noResultsOverlay: CustomNoResultsOverlay,
      }}
      loading={loading}
      paginationMode="server"
      paginationModel={{
        page,
        pageSize: pageSizeValidator(limit),
      }}
      onPaginationModelChange={handleChangePage}
      getRowClassName={(params) =>
        `${getEvenRowClassName(params)} ${getRowClassName ? getRowClassName(params) : ''}`
      }
      disableRowSelectionOnClick={!clickable}
      {...rest}
    />
  ) : (
    <StyledDataGrid
      sx={getSx}
      autoHeight
      rowHeight={rowHeight}
      rows={rows}
      columns={columns}
      loading={loading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: limit,
          },
        },
      }}
      getRowClassName={(params) =>
        `${getEvenRowClassName(params)} ${getRowClassName ? getRowClassName(params) : ''}`
      }
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      {...rest}
    />
  );
};
//
export default DataGrid;
