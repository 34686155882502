/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
import { vendorActions } from './slice';
import API from './constants';
/**
 * Generator function for getting vendors
 * @param {Object} payload
 */
export function* getVendorsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VENDORS, payload);
    yield put(vendorActions.getAllVendorsSucceeded(response));
  } catch (error) {
    yield put(vendorActions.getAllVendorsFailed(error?.message));
  }
}
/**
 * Generator function for adding a vendor
 * @param {Object} payload
 */
export function* addVendorsGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_VENDOR, payload);
    yield put(vendorActions.addVendorSucceeded(response));
  } catch (error) {
    yield put(vendorActions.addVendorFailed(error?.message));
  }
}
/**
 * Generator function for editing a vendor
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editVendorGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_VENDOR, payload);
    yield put(vendorActions.editVendorSucceeded(response));
  } catch (error) {
    yield put(vendorActions.editVendorFailed(error?.message));
  }
}
/**
 * Generator function for delete a vendor
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* deleteVendorGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_VENDOR, payload);
    yield put(vendorActions.deleteVendorSucceeded(response));
  } catch (error) {
    yield put(vendorActions.deleteVendorFailed(error?.message));
  }
}
/**
 * Generator function for uploading logo
 * @param {Object} payload
 */
function* uploadLogoGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_LOGO, payload, true);
    yield put(vendorActions.uploadLogoSucceeded(response));
  } catch (error) {
    yield put(vendorActions.uploadLogoFailed(error?.message));
  }
}
/**
 * Generator function for verifying employee
 * @param {Object} payload
 */
function* verifyEmployeeGenerator({ payload }) {
  try {
    const response = yield call(request, API.VERIFY_EMPLOYEE, payload);
    yield put(vendorActions.verifyEmployeeSucceeded(response));
  } catch (error) {
    yield put(vendorActions.verifyEmployeeFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* vendorSaga() {
  yield takeLatest(vendorActions.getAllVendors.type, getVendorsGenerator);
  yield takeLatest(vendorActions.addVendor.type, addVendorsGenerator);
  yield takeLatest(vendorActions.editVendor.type, editVendorGenerator);
  yield takeLatest(vendorActions.deleteVendor.type, deleteVendorGenerator);
  yield takeLatest(vendorActions.uploadLogo.type, uploadLogoGenerator);
  yield takeLatest(vendorActions.verifyEmployee.type, verifyEmployeeGenerator);
}
//
export default vendorSaga;
