/**
 * Defines the dollar formatting attributes used in the application
 * @default { LANGUAGE: 'en-US' }
 */
export const AMOUNT_LANGUAGE = {
  US_ENGLISH: 'en-US',
};
/**
 * Defines the dollar format displayed on the dashboards
 * @summary To be used with the MUI TextField component
 * @default { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
 * @example const amount = 1000;
 * const formattedAmount = amount.toLocaleString(AMOUNT_LANGUAGE.US_ENGLISH, DOLLAR_FORMAT);
 * console.log(formattedAmount); // $1,000
 */
export const DOLLAR_FORMAT = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
};
