import './index.scss';
/**
 * Defines a function that returns a common Form Validation Error Component
 * @prop {Boolean} error - Boolean value that indicates whether a validation error is present
 * @prop {String} errorMsg - Error description to be displayed
 */
const ValidationError = ({ error, errorMsg }) => {
  if (!error) return null;
  return <p className="error-feedback">{errorMsg}</p>;
};
//
export default ValidationError;
