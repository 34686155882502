import { useRef } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
//
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';
import COLORS from 'features/base/constants/colors';

/**
 * Upload box component
 * @prop {string} label - label for the upload box
 * @prop {string} inputLabel - label for the upload button
 * @prop {function} setLogo - function to set the logo
 * @prop {string} acceptedTypes - accepted file types
 * @prop {boolean} error - boolean to show/hide the error
 * @prop {string} errorMsg - error message
 * @prop {boolean} loading - boolean to show/hide the loading
 * @returns {UploadBox}
 */
const FileUpload = ({ label, inputLabel, setLogo, acceptedTypes, error, errorMsg, loading }) => {
  const fileInputRef = useRef(null);
  const onUploadClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography fontSize="16px" className="field-label">
            {label}
          </Typography>
        </Grid>
      </Grid>
      <Box
        className="uploadField"
        sx={{
          p: '4px 4px 4px 10px',
          border: '1px solid lightgrey',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1">{inputLabel}</Typography>
        <Button
          sx={{
            background: `transparent linear-gradient(256deg, ${COLORS.ELECTRIC_BLUE} 0%, ${COLORS.PRIMARY_MAIN} 100%) 0% 0% no-repeat padding-box`,
            color: COLORS.WHITE,
            padding: '6px 30px',
            cursor: 'pointer',
            fontSize: '11pt',
            fontFamily: 'Lexend, sans-serif',
            ':hover': {
              background: `transparent linear-gradient(254deg, ${COLORS.ROYAL_BLUE} 0%, ${COLORS.DEEP_PURPLE} 100%) 0% 0% no-repeat padding-box !important`,
            },
            ':disabled': {
              background: `transparent linear-gradient(251deg, ${COLORS.PALE_BLUE} 0%, ${COLORS.LILAC} 100%) 0% 0% no-repeat padding-box !important`,
              color: COLORS.WHITE,
            },
          }}
          onClick={() => onUploadClick()}
          endIcon={loading ? <img src={loaderIcon} alt="button-icon" /> : null}
          disabled={loading}
        >
          {loading ? 'Uploading' : 'Upload'}
        </Button>
      </Box>
      {error && <p className="error-feedback">{errorMsg}</p>}
      <input
        ref={fileInputRef}
        accept={acceptedTypes}
        style={{ display: 'none' }}
        id="upload-input"
        type="file"
        onChange={(e) => setLogo(e.target.files)}
      />
    </Box>
  );
};
//
export default FileUpload;
