import { Grid, Box, Typography } from '@mui/material';
import emptyBox from 'features/base/assets/images/png/empty-box.png';

/**
 * This function is designed to display a custom message when there are no rows in the data grid.
 * Returns a custom component when there are no rows found.
 *
 * @param {string} message - The custom message to display when there are no records found.
 * @param {boolean} size - Controls the size of the image. When true, the image will have a fixed size of 250px x 200px. When false, the image will have its default size.
 */
const CustomNoRowsOverlay = ({ message, size }) => (
  <Grid
    container
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    }}
    direction="column"
  >
    <Grid item>
      <Box sx={{ display: 'flex' }}>
        <img
          src={emptyBox}
          alt="empty box"
          style={size ? { width: '250px', height: '200px' } : { width: 'auto', height: 'auto' }}
        />
      </Box>
    </Grid>
    <Grid item>
      <Typography sx={{ fontWeight: 900, fontSize: 25 }}>
        {message || 'No records found!'}
      </Typography>
    </Grid>
  </Grid>
);
//
export default CustomNoRowsOverlay;
