import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'features/base/constants/toast-types';
//
import { ENVIRONMENT } from 'config';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import CopyLinkIcon from 'features/base/assets/images/svg/copy-link-icon.svg';
/**
 * View vendors table columns - basic details
 */
export const VENDORS_TABLE_COLUMNS_BASIC = [
  {
    field: 'vendor',
    headerName: 'Name',
    width: 360,
    valueGetter: (params) => params.row.vendor.vendorName,
    renderCell: (params) => {
      const { vendorId, vendorLogo, vendorName } = params.row.vendor;
      return (
        <Grid container item flexDirection="row" alignItems="center">
          {!vendorLogo?.length > 0 ? (
            <Avatar sx={{ height: '40px', width: '40px' }}>{vendorName?.charAt(0)}</Avatar>
          ) : (
            <img
              src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${vendorLogo[0]?.url}`}
              key={vendorId}
              alt="logo"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          )}
          <Typography
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ml: 1,
            }}
          >
            {capitalizeFirstLetter(vendorName)}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 460,
    valueGetter: (params) => params.row.description.value,
    renderCell: (params) => {
      const { value } = params.row.description;
      return (
        <Typography
          noWrap
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {capitalizeFirstLetter(value)}
        </Typography>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    valueGetter: (params) => params.row.email,
    renderCell: (params) => {
      const { value } = params.row.email;
      return <Typography sx={{ wordWrap: 'break-word' }}>{value}</Typography>;
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created date',
    width: 130,
    type: 'date',
    valueGetter: (params) => new Date(params.row.createdDate.value),
    renderCell: (params) => {
      const { value } = params.row.createdDate;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'lastUpdate',
    headerName: 'Last updated',
    width: 130,
    type: 'date',
    valueGetter: (params) => new Date(params.row.lastUpdate.value),
    renderCell: (params) => {
      const { value } = params.row.lastUpdate;
      return <Typography>{value}</Typography>;
    },
  },
];
/**
 * View vendors table columns - action columns
 */
export const VENDORS_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    width: 150,
    renderCell: ({
      value: { isLoading, handleVendorEdit, handleVendorDelete, hashId, vendorId },
    }) => (
      <Box display="flex">
        <CopyToClipboard
          text={`${window.location.origin}/vendor-portal/?vendorId=${vendorId}&hashId=${hashId}`}
          onCopy={() => toast('Link copied to clipboard!', { type: TOAST_TYPES.INFO })}
        >
          <IconButton>
            <img src={CopyLinkIcon} alt="copy link" />
          </IconButton>
        </CopyToClipboard>
        <IconButton
          sx={{ '&:hover': { color: 'blue' } }}
          onClick={handleVendorEdit}
          readOnly={isLoading}
        >
          <DriveFileRenameOutline />
        </IconButton>
        <IconButton
          sx={{ '&:hover': { color: 'red' } }}
          onClick={handleVendorDelete}
          readOnly={isLoading}
        >
          <DeleteForever />
        </IconButton>
      </Box>
    ),
  },
];
