import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
//
import { SearchBar } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import {
  selectAddUserSkillLoader,
  selectAllSkillGroups,
  selectDeleteUserSkillLoader,
  selectGetSkillGroupsLoading,
  selectUpdateUserLoader,
  selectUser,
} from 'features/profile/selectors';
import { profileActions } from 'features/profile/slice';
import COLORS from 'features/base/constants/colors';

/**
 * Function that renders the add skill group component on the profile skill page
 * @returns {<Grid>}
 */
const AddSkillGroup = ({ userId, searchData, handleSearchInput, setEditMode }) => {
  const dispatch = useDispatch();
  //
  const user = useSelector(selectUser);
  const allSkillGroups = useSelector(selectAllSkillGroups);
  const addUserSkillLoading = useSelector(selectAddUserSkillLoader);
  const deleteUserSkillLoading = useSelector(selectDeleteUserSkillLoader);
  const updateUserLoading = useSelector(selectUpdateUserLoader);
  const getSkillGroupsLoading = useSelector(selectGetSkillGroupsLoading);
  const loading = addUserSkillLoading || deleteUserSkillLoading || updateUserLoading;
  const notification = useSelector(selectNotification);
  //
  const handleDeleteSkillGroup = (groupId) => {
    const groupToDelete = allSkillGroups.find((skillGroup) => skillGroup?.id === groupId);
    dispatch(
      profileActions.deleteUserSkills({
        userId,
        userSkillIds: groupToDelete?.skills?.map((skill) => skill?.id),
        skillGroupId: groupId,
      })
    );
    dispatch(
      profileActions.updateUser({
        skillGroupIds:
          user?.skillGroups
            ?.filter((skillGroup) => skillGroup?.id !== groupId)
            ?.map((skillGroup) => skillGroup?.id) ?? [],
        userId: user?.id,
      })
    );
  };
  //
  const handleAddSkillGroupToUser = (skillGroupId) => {
    const selectedSkillGroup = allSkillGroups.find((skillGroup) => skillGroup?.id === skillGroupId);
    const selectedUserSkills = selectedSkillGroup?.skills?.map((skill) => ({
      skillId: skill?.id,
      rating: 0,
    }));
    dispatch(
      profileActions.addUserSkills({
        userId,
        userSkills: selectedUserSkills,
      })
    );
    dispatch(
      profileActions.updateUser({
        skillGroupIds: [
          ...(user?.skillGroups?.map((skillGroup) => skillGroup?.id) ?? []),
          skillGroupId,
        ],
        userId: user?.id,
      })
    );
    setEditMode(true);
  };
  //
  return (
    <Grid item xs={12} sm={12} lg={5} md={12} className="leftGrid">
      <Box sx={{ bgcolor: COLORS.SKY_BLUE, pb: { lg: '100px' }, pt: '15px', pl: '15px', pr: '15px' }}>
        <Typography variant="h5" className="typographyTitle">
          Add skill group
          <Divider sx={{ pt: '10px' }} />
        </Typography>
        {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
          <Alert sx={{ mb: 3 }} severity={notification?.type}>
            {notification?.message}
          </Alert>
        )}
        <Box sx={{ pb: '10px' }}>
          <SearchBar
            placeholder="Enter a skill group"
            value={searchData}
            handleSearchInput={handleSearchInput}
          />
        </Box>
        {user?.skillGroups?.map((skillGroup) => (
          <Button
            key={skillGroup?.id}
            variant="contained"
            endIcon={<ClearIcon fontSize="small" />}
            onClick={() => handleDeleteSkillGroup(skillGroup.id)}
            disabled={loading}
            size="small"
            sx={{
              marginRight: 1,
              marginTop: 1,
              borderRadius: 0,
              backgroundColor: COLORS.DODGER_BLUE,
              ':hover': { bgcolor: COLORS.DODGER_BLUE, color: COLORS.WHITE },
            }}
          >
            {skillGroup?.name}
          </Button>
        ))}
        <Box>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', mb: '10px', fontSize: '14px', mt: '40px' }}
          >
            Suggested skill groups
          </Typography>
          {getSkillGroupsLoading && <CircularProgress size={25} />}
          {!getSkillGroupsLoading &&
            (allSkillGroups.length > 0 ? (
              allSkillGroups
                .filter(
                  (skillGroup) =>
                    !user?.skillGroups.some(
                      (selectedSkillGroup) => selectedSkillGroup?.id === skillGroup?.id
                    )
                )
                .slice(0, 5)
                .map((skillGroup) => (
                  <Button
                    key={skillGroup?.id}
                    variant="outlined"
                    endIcon={<AddIcon fontSize="small" />}
                    onClick={() => handleAddSkillGroupToUser(skillGroup?.id)}
                    disabled={loading}
                    color="info"
                    size="small"
                    sx={{ marginRight: 1, marginTop: 1, borderRadius: 0 }}
                  >
                    {skillGroup?.name}
                  </Button>
                ))
            ) : (
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', mb: '20px', color: '#0F072C99' }}
              >
                No suggested skill groups
              </Typography>
            ))}
        </Box>
      </Box>
    </Grid>
  );
};
//
export default AddSkillGroup;
