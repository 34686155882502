import { Typography, Box, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
//
import { benefitsActions } from 'features/benefits/slice';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { capitalizeFirstLetter, formattedString } from 'features/base/helpers/strings';
import {
  selectAddBenefitsLoader,
  selectVendorsMultiStepForm,
  selectAddBenefitsSucceeded,
  selectAddedBenefit,
  selectAddPromotionsLoader,
  selectPromotionsAddSucceeded,
} from 'features/benefits/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { Button } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import COLORS from 'features/base/constants/colors';
import {
  BENEFIT_PROMOTION_TYPES,
  BENEFIT_PROMOTION_CODE_TYPES,
} from 'features/base/constants/benefit-types';
import { notificationActions } from 'features/base/notifications/slice';
import ROUTES from 'features/base/constants/routes';
/**
 * Component that defines the grid item
 * @returns MUI Grid with the grid item
 * @param {*} children
 * @returns MUI Grid
 */
const GridItem = ({ children, sx }) => (
  <Grid item xs={12} sm={12} md={6}>
    <Paper
      sx={{
        ...sx,
        backgroundColor: COLORS.SKY_BLUE,
        m: 0.5,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'flex-start',
        padding: '8px',
      }}
      elevation={0}
    >
      {children}
    </Paper>
  </Grid>
);

/**
 * Component that defines the grid block
 * @returns MUI Grid with the grid block
 * @param {*} label
 * @param {*} value
 * @returns MUI Grid
 */
const GridBlock = ({ label, value }) => (
  <>
    <GridItem>
      <Typography sx={{ paddingLeft: 1 }}> {label}</Typography>
    </GridItem>
    <GridItem>
      <Typography sx={{ paddingLeft: 1, wordBreak: 'break-word' }}> {value}</Typography>
    </GridItem>
  </>
);

/**
 * @returns {ReactNode} AddEmployeesStep
 */
const SummeryViewStep = ({ formResults }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const notification = useSelector(selectNotification);
  const vendors = useSelector(selectVendorsMultiStepForm);
  const addBenefitsSucceeded = useSelector(selectAddBenefitsSucceeded);
  const addPromotionsSucceeded = useSelector(selectPromotionsAddSucceeded);
  const addedBenefit = useSelector(selectAddedBenefit);
  const addPromotionsLoader = useSelector(selectAddPromotionsLoader);
  const addBenefitsLoader = useSelector(selectAddBenefitsLoader);
  //
  const addBenefit = () => {
    //
    const benefitData = {
      title: formResults[0]?.data?.title,
      description: formResults[0]?.data?.description,
      campaignType: formResults[0]?.data?.campaignType,
      ...(formResults[0]?.data?.expiryDate && {
        expiryDate: moment(formResults[0]?.data?.expiryDate).format(ISO_WITHOUT_TIME),
      }),
      ...(formResults[0]?.data?.vendorId && { vendorId: formResults[0]?.data?.vendorId }),
      showExpiryDate: formResults[0]?.data?.showExpiryDate,
      attachments:
        formResults[0]?.data?.attachments?.map((attachment) => ({
          ...attachment,
          url: encodeURI(attachment?.url),
        })) || [],
      ...(formResults[0]?.data?.link && { link: formResults[0]?.data?.link }),
      isEmailEnabled: formResults[0]?.data?.isEmailEnabled,
      //
      promotionType: formResults[1]?.data?.promotionType,
      promotionCodeType: formResults[1]?.data?.promotionCodeType,
      //
      departments: formResults[2]?.data?.departments?.map((department) => department?.id) || [],
      levels: formResults[2]?.data?.levels?.map((level) => level?.id) || [],
      excludedUsers: formResults[2]?.data?.excludedUsers?.map((user) => user?.id) || [],
      additionalUsers: formResults[2]?.data?.additionalUsers?.map((user) => user?.id) || [],
    };
    //
    dispatch(benefitsActions.addBenefits({ ...benefitData }));
  };
  //
  useEffect(() => {
    if (
      addBenefitsSucceeded &&
      addedBenefit?.id &&
      (formResults[1]?.data?.resources?.length > 0 || formResults[1]?.data?.resource)
    ) {
      const promoData = [];
      if (formResults[1]?.data?.promotionType !== BENEFIT_PROMOTION_TYPES.NOT_APPLICABLE) {
        if (formResults[1].data.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE) {
          promoData.push({ resource: formResults[1].data.resource, benefitId: addedBenefit.id });
        } else if (
          formResults[1].data.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME
        ) {
          const codes = formResults[1].data.resources.map((resource) => ({
            resource,
            benefitId: addedBenefit?.id,
          }));
          promoData.push(...codes);
        }
      }
      dispatch(benefitsActions.addPromotions({ body: [...promoData], benefitId: addedBenefit.id }));
    } else if (
      addBenefitsSucceeded &&
      addedBenefit?.id &&
      !formResults[1]?.data?.resources?.length
    ) {
      navigate(ROUTES.MANAGE_BENEFITS);
    }
  }, [addBenefitsSucceeded, addedBenefit]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  useEffect(() => {
    if (addPromotionsSucceeded) {
      navigate(ROUTES.MANAGE_BENEFITS);
    }
  }, [addPromotionsSucceeded]);
  //
  useEffect(
    () => () => {
      dispatch(benefitsActions.resetMultiStepForm());
      dispatch(notificationActions.resetNotification());
    },
    []
  );
  //
  //
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '70%', lg: '60%' },
        margin: 'auto',
      }}
    >
      <Typography variant="h5" color="textPrimary" mb={3}>
        Summary
      </Typography>

      <Typography variant="h6" color="textPrimary" fontWeight={800} mb={3}>
        General Details
      </Typography>

      <Grid container rowSpacing={0} mt={3}>
        <GridBlock
          label="Campaign Type"
          value={formattedString(capitalizeFirstLetter(formResults[0]?.data?.campaignType))}
        />
        <GridBlock label="Title" value={formResults[0]?.data?.title} />

        <GridBlock
          label="Vendor"
          value={vendors.find((vendor) => vendor.id === formResults[0]?.data?.vendorId)?.name}
        />

        <GridItem sx={{ height: '2rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}> Description</Typography>
        </GridItem>
        <GridItem sx={{ height: 'auto', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}> {formResults[0]?.data?.description}</Typography>
        </GridItem>

        <GridBlock
          label="Expiry Date"
          value={
            formResults[0]?.data?.expiryDate
              ? moment(formResults[0]?.data?.expiryDate).format('LL')
              : '-'
          }
        />

        <GridBlock
          label="Show Expiry Date"
          value={formResults[0]?.data?.showExpiryDate ? 'Yes' : 'No'}
        />

        <GridItem sx={{ height: '2rem', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingLeft: 1 }}> Link</Typography>
        </GridItem>
        <GridItem sx={{ height: 'auto', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography
            sx={{ paddingLeft: 1 }}
            component={'a'}
            href={formResults[0]?.data?.link}
            target="_blank"
          >
            {' '}
            {formResults[0]?.data?.link || '-'}
          </Typography>
        </GridItem>

        <GridItem
          sx={{
            height: {
              md:
                formResults[0]?.data?.attachments?.length > 0
                  ? `${2 * (formResults[0]?.data?.attachments?.length ?? 1)}rem`
                  : '2rem',
              sm: '2rem',
            },
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ paddingLeft: 1 }}>Attachments</Typography>
        </GridItem>
        <GridItem
          sx={{
            flexDirection: 'column',
            height:
              formResults[0]?.data?.attachments?.length > 0
                ? `${2 * (formResults[0]?.data?.attachments?.length ?? 1)}rem`
                : '2rem',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {/*  show blue colour chip hyper links */}
          {formResults[0]?.data?.attachments?.length ? (
            formResults[0]?.data?.attachments?.map((attachment) => (
              <a
                href={`${attachment?.url}`}
                style={{
                  fontSize: '0.875rem',
                  ml: 1,
                  marginBottom: 4,
                  whiteSpace: 'nowrap',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '95%',
                }}
                target="_blank"
                rel="noreferrer"
              >
                {attachment?.name}
              </a>
            ))
          ) : (
            <Typography sx={{ paddingLeft: 1 }}>{'-'}</Typography>
          )}
        </GridItem>

        <GridBlock
          label="Email Enabled"
          value={formResults[0]?.data?.isEmailEnabled ? 'Yes' : 'No'}
        />

        <Box sx={{ mt: 3 }} />
      </Grid>

      {formResults[1]?.data?.promotionType !== BENEFIT_PROMOTION_TYPES.NOT_APPLICABLE && (
        <>
          <Box sx={{ mt: 3 }} />
          <Typography variant="h6" color="textPrimary" fontWeight={800} mb={3}>
            Promotion Details
          </Typography>
          <Grid container rowSpacing={0} mt={3}>
            <GridBlock
              label="Promotion Type"
              value={
                formResults[1]?.data?.promotionType
                  ? formResults[1].data.promotionType.replace(/_/g, ' ')
                  : '-'
              }
            />
            {formResults[1]?.data?.promotionCodeType ===
              BENEFIT_PROMOTION_CODE_TYPES.MULTIPLE_USE && (
              <GridBlock label="Promotion Code" value={formResults[1]?.data?.resource || '-'} />
            )}
            {formResults[1]?.data?.promotionCodeType === BENEFIT_PROMOTION_CODE_TYPES.ONE_TIME && (
              <>
                <GridItem
                  sx={{ height: '2rem', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }}> Promo Codes</Typography>
                </GridItem>
                <GridItem
                  sx={{ height: 'auto', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography sx={{ paddingLeft: 1 }}>
                    {' '}
                    {formResults[1]?.data?.resources?.join(', ') || '-'}
                  </Typography>
                </GridItem>
              </>
            )}
          </Grid>
        </>
      )}

      <Box sx={{ mt: 3 }} />
      <Typography variant="h6" color="textPrimary" fontWeight={800} mb={3}>
        Employee Details
      </Typography>

      <Grid container rowSpacing={0} mt={3}>
        <GridBlock
          label="Departments"
          value={
            formResults[2]?.data?.departments
              ? formResults[2]?.data?.departments.map((department) => department.name).join(', ')
              : '-'
          }
        />

        <GridBlock
          label="Employee Levels"
          value={
            formResults[2]?.data?.levels
              ? formResults[2]?.data?.levels.map((level) => level.name).join(', ')
              : '-'
          }
        />

        <GridBlock
          label="Excluded Users"
          value={
            formResults[2]?.data?.excludedUsers
              ? formResults[2]?.data?.excludedUsers.map((user) => user.name).join(', ')
              : '-'
          }
        />

        <GridBlock
          label="Additional Users"
          value={
            formResults[2]?.data?.additionalUsers
              ? formResults[2]?.data?.additionalUsers.map((user) => user.name).join(', ')
              : '-'
          }
        />
      </Grid>

      <Box sx={{ mt: 3 }} />

      <Button
        variant="contained"
        color="primary"
        onClick={addBenefit}
        disabled={addBenefitsLoader || addPromotionsLoader}
      >
        Verify & Create Benefit
      </Button>
      <Box sx={{ mt: 6 }} />
    </Box>
  );
};
//
export default SummeryViewStep;
