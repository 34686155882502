import { AMOUNT_LANGUAGE, DOLLAR_FORMAT } from 'features/base/constants/dollar-formatting';
import { AMOUNT_RANGES } from '../constants/amount-types';
/**
 * Convert a given value to a dollar format - 1000 -> $1,000
 * @param {Number} value
 * @returns {String}
 */
export const getDollarFormattedValue = (value) => {
  if (!value || value < AMOUNT_RANGES.MIN_AMOUNT || value > AMOUNT_RANGES.MAX_AMOUNT) return '';
  return new Intl.NumberFormat(AMOUNT_LANGUAGE.US_ENGLISH, DOLLAR_FORMAT).format(value);
};
/**
 * @param {Number} value
 * @returns {String}
 */
export const getDollarFormattedValueBilling = (value) =>
  new Intl.NumberFormat(AMOUNT_LANGUAGE.US_ENGLISH, DOLLAR_FORMAT).format(value);
