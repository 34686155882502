import { useEffect, useMemo, useState } from 'react';
import { Typography, Box, Grid, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable, ShimmerButton } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
//
import AddClientsPopup from 'features/clients/components/add-clients-popup';
import EditClientsPopup from 'features/clients/components/edit-clients-popup';
import { clientActions } from 'features/clients/slice';
import { Button, DataGrid, SearchBar } from 'features/base/components';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { notificationActions } from 'features/base/notifications/slice';
import { CLIENTS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import createFormattedString from 'features/base/helpers/param-formatter';
import { selectClientList, selectLoader } from 'features/clients/selectors';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  CLIENTS_TABLE_ACTION_COLUMNS,
  CLIENT_TABLE_COLUMNS_BASIC,
} from 'features/base/utils/tables';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import ROUTES from 'features/base/constants/routes';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import { PROJECT_TYPES } from 'features/base/constants/project-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';

/**
 * Component that defines the client view
 * @returns MUI Grid with the client view
 */
const ClientView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const userReadPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const projectReadPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const clientUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.CLIENT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const atLeastOneVerified = userReadPermissionsVerified || projectReadPermissionsVerified;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const loading = useSelector(selectLoader);
  const notification = useSelector(selectNotification);
  const clients = useSelector(selectClientList);
  //
  const isFirst = useIsInitialize(clients);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    rowsPerPage: CLIENTS_PAGINATION_LIMIT,
  });
  const [searchData, setSearchData] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [clientId, setClientId] = useState('');
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleAddModalOpen = () => {
    dispatch(
      clientActions.getAllProjects({ query: `pagination=false&type=${PROJECT_TYPES.CLIENT}` })
    );
    setAddModalOpen(true);
  };
  //
  const handleEditModalOpen = (id) => {
    dispatch(
      clientActions.getAllProjects({ query: `pagination=false&type=${PROJECT_TYPES.CLIENT}` })
    );
    setClientId(id);
    setEditModalOpen(true);
  };
  //
  const handleOnRowClick = (id) => {
    // Navigate to the first tab that the user has permission to view
    if (userReadPermissionsVerified) {
      navigate(generatePath(ROUTES.MANAGE_CLIENT_USERS, { id }));
    } else if (projectReadPermissionsVerified) {
      navigate(generatePath(ROUTES.MANAGE_CLIENT_PROJECTS, { id }));
    }
  };
  //
  const searchClients = () => {
    // When page controller gets updated, params gets updated, which is a dependency in the useEffect
    setPageController({ ...pageController, page: 1 });
  };
  //
  const debounced = useDebouncedCallback(searchClients, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (event) => {
    setSearchData(event.target.value);
    debounced();
  };
  //
  const params = useMemo(
    () => ({
      limit: pageController.rowsPerPage,
      page: pageController.page,
      search: searchData,
    }),
    [pageController]
  );
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(clientActions.getClients({ query: formattedParamString }));
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <AddClientsPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditClientsPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        clientId={clientId}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        <Grid container direction="row" justifyContent="space-between" sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', width: '40%' }}>
            Manage clients
          </Typography>
          <Box
            sx={{
              height: '60px',
              width: '40%',
            }}
          >
            <SearchBar
              placeholder="Search by company, br-number or primary user"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
          </Box>
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.CLIENT,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            {isFirst ? (
              <ShimmerButton size="lg" />
            ) : (
              <Button onClick={handleAddModalOpen}>
                Add client <AddIcon />
              </Button>
            )}
          </PermissionWrapper>
        </Grid>
        {isFirst ? (
          <ShimmerTable row={6} col={8} />
        ) : (
          <DataGrid
            columns={
              clientUpdatePermissionsVerified
                ? [...CLIENT_TABLE_COLUMNS_BASIC, ...CLIENTS_TABLE_ACTION_COLUMNS]
                : CLIENT_TABLE_COLUMNS_BASIC
            }
            onRowClick={(data) => handleOnRowClick(data.id)}
            rows={
              clients?.docs
                ?.toSorted((firstClient, secondClient) =>
                  firstClient?.primaryUser?.firstName?.localeCompare(secondClient?.primaryUser?.firstName)
                )
                ?.map((client) => ({
                  id: client?.id,
                  profile: {
                    logo: client?.logo,
                    name: `${client?.primaryUser?.firstName} ${client?.primaryUser?.lastName}`,
                  },
                  company: {
                    value: client?.company,
                  },
                  brn: {
                    value: client?.brNumber,
                  },
                  projects: {
                    value: client?.projects?.length,
                  },
                  revenue: {
                    value:
                      getDollarFormattedValue(
                        client?.projects?.reduce(
                          (acc, project) =>
                            project?.projectId?.projectValue
                              ? acc + parseInt(project?.projectId?.projectValue, 10)
                              : acc,
                          0
                        )
                      ) || '-',
                  },
                  cost: {
                    value: 'To be implemented',
                  },
                  margin: {
                    value: 'To be implemented',
                  },
                  action: {
                    handleClick: (event) => {
                      event.stopPropagation();
                      handleEditModalOpen(client?.id);
                    },
                    loading,
                  },
                })) ?? []
            }
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalPages={clients?.totalPages ?? 0}
            totalDocs={clients?.totalDocs ?? 0}
            limit={clients?.limit ?? pageController.rowsPerPage}
            loading={loading && isFirst !== true}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={clients?.docs?.length ? 52 : 200}
            clickable={atLeastOneVerified}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default ClientView;
