import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
//
import { selectAllOpex, selectEditOpexLoader } from 'features/opex/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import Popup from 'features/base/components/modal';
import TextField from 'features/base/components/text-field';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { opexActions } from 'features/opex/slice';
import DatePicker from 'features/base/components/date-picker';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { editOpexFormValidation } from 'features/opex/validations/opex-validation';
import { OPEX_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import './index.scss';
import { NumericInputField } from 'features/base/components';
import { removeUnchanged } from 'features/base/helpers/object';

/**
 * Function that defines the popup form for editing a opex
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} opexId - opex id
 * @prop {string} previousStartDate - previous start date
 * @returns {Popup}
 */
const EditOpexPopup = ({ editModalOpen, setEditModalOpen, opexId, previousStartDate }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const opexes = useSelector(selectAllOpex);
  const opexToEdit = opexes?.docs?.find((opex) => opex.id === opexId);
  const loading = useSelector(selectEditOpexLoader);
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(opexActions.clearEditOpex());
  };
  //
  useEffect(() => {
    if (editModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      dispatch(
        opexActions.getAllOpex({
          query: `limit=${OPEX_PAGINATION_LIMIT}&sortBy=createdAt:desc&page=1`,
        })
      );
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      handleOnClose();
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  const initialValues = {
    id: opexToEdit?.id,
    name: opexToEdit?.name,
    description: opexToEdit?.description,
    amount: opexToEdit?.amount,
    startDate: opexToEdit?.startDate,
    endDate: opexToEdit?.endDate,
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.OPEX,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit Overhead"
        mediumSize="660px"
        titleSx={{ pl: '0.8rem' }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={editOpexFormValidation(previousStartDate, opexToEdit?.dataStatus)}
          onSubmit={(values) => {
            let opexData = { ...values };
            opexData = removeUnchanged(initialValues, opexData);
            if (opexData.startDate) {
              opexData.startDate = moment(values.startDate.$d).format(ISO_WITHOUT_TIME);
            }
            if (opexData.endDate) {
              opexData.endDate = moment(values.endDate.$d).format(ISO_WITHOUT_TIME);
            }
            dispatch(opexActions.editOpex({ ...opexData, id: values?.id }));
          }}
        >
          {({ errors, handleChange, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit} className="edit-opex-form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                my={2}
                label="Description"
                height={120}
                multiline
                rows={4}
              />
              <NumericInputField
                type="text"
                name="amount"
                label="Amount"
                value={values.amount}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
                onChange={(v) => setFieldValue('amount', v.floatValue)}
                onBlur={handleBlur}
                thousandSeparator=","
                prefix="$"
                placeholder="$"
              />
              <DatePicker
                disabled={opexToEdit?.dataStatus === DATA_STATUS_TYPES.INACTIVE}
                views={['month', 'year']}
                onKeyDown={(e) => e.preventDefault()}
                sx={{ caretColor: 'transparent' }}
                label="Start date"
                value={values.startDate}
                onChange={(event) => {
                  setFieldValue(
                    'startDate',
                    moment(event?.$d).startOf('month').format(ISO_WITHOUT_TIME)
                  );
                }}
                error={Boolean(touched.startDate && errors.startDate)}
                errorMsg={touched?.startDate && errors?.startDate}
              />
              {opexToEdit?.dataStatus === DATA_STATUS_TYPES.INACTIVE && (
                <DatePicker
                  disabled={opexToEdit?.dataStatus === DATA_STATUS_TYPES.INACTIVE}
                  label="End date"
                  value={values.endDate}
                  onChange={(event) => {
                    setFieldValue('endDate', event);
                  }}
                  error={Boolean(touched.endDate && errors.endDate)}
                  errorMsg={touched?.endDate && errors?.endDate}
                />
              )}
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                rightIcon={loading ? loaderIcon : null}
                submitDisabled={loading}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditOpexPopup;
