import { useRef } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
//
import searchIcon from 'features/base/assets/images/svg/search-icon.svg';
import './index.scss';
/**
 * Defines a common search component
 * @prop {String} placeholder - Placeholder of the search bar
 * @prop {String} value - Text being typed
 * @prop {Function} onKeyDown - Handler function for the onKeyDown event
 * @prop {Function} handleSearchInput - Handler function for search
 * @prop {Object} sx - Custom MUI sx object
 */
const SearchBar = ({ placeholder, value, onKeyDown, handleSearchInput, sx, ...rest }) => {
  const inputDivRef = useRef(null);
  //
  const setFocusStyles = () => {
    inputDivRef.current.classList.add('search-input-focus');
  };
  //
  const removeFocusStyles = () => {
    inputDivRef.current.classList.remove('search-input-focus');
  };
  //
  return (
    <Box className="search-bar-container" ref={inputDivRef} sx={sx}>
      <img src={searchIcon} alt="search-icon" className="search-icon" />
      <input
        type="text"
        className="search-input"
        onFocus={setFocusStyles}
        onBlur={removeFocusStyles}
        placeholder={placeholder}
        value={value}
        onChange={handleSearchInput}
        onKeyDown={onKeyDown}
        {...rest}
      />
    </Box>
  );
};
//
SearchBar.defaultProps = {
  placeholder: '',
};
//
SearchBar.propTypes = {
  placeholder: PropTypes.string,
};
//
export default SearchBar;
