import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Drawer, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
//
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Autocomplete, Button, DataGrid, SearchBar } from 'features/base/components';
import { toolActions } from 'features/tools/slice';
import { selectLoader, selectAllTools } from 'features/tools/selectors';
import { TOOLS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { DATE_FORMAT_SLASH, DATE_LANGUAGE } from 'features/base/constants/date-formatting';
import TOOL_TYPES from 'features/base/constants/tool-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { TOOLS_TABLE_COLUMNS_BASIC, TOOLS_TABLE_ACTION_COLUMNS } from 'features/base/utils/tables';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import createFormattedString from 'features/base/helpers/param-formatter';
import AddToolPopup from '../add-tool-modal';
import EditToolsPopup from '../edit-tool-modal';
import DeleteToolPopup from '../delete-tool-popup';

/**
 * Function that defines the tool view
 * @returns {Grid}
 */
const ToolView = () => {
  const [isSelectTypeDrawerOpen, setIsSelectTypeDrawerOpen] = useState(false);
  //
  const handleSelectTypeDrawerOpen = () => {
    setIsSelectTypeDrawerOpen(true);
  };
  //
  const handleSelectTypeDrawerClose = () => {
    setIsSelectTypeDrawerOpen(false);
  };
  //
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const toolsEditPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TOOL,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const toolsDeletePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TOOL,
      action: PERMISSION_ACTIONS.DELETE,
    },
  ]);
  const dispatch = useDispatch();
  //
  const [searchData, setSearchData] = useState('');
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('All');
  //
  const isLoading = useSelector(selectLoader);
  const allTools = useSelector(selectAllTools);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'name:asc',
    rowsPerPage: TOOLS_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [toolId, setToolId] = useState('');
  //
  const params = useMemo(
    () => ({
      paymentType: paymentTypeFilter,
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
    }),
    [paymentTypeFilter, pageController]
  );
  //
  const isFirst = useIsInitialize(allTools);
  //
  const handleTypeOnChange = (e, autocompleteValue) => {
    setPaymentTypeFilter(autocompleteValue || 'All');
    setPageController({ ...pageController, page: 1 });
    handleSelectTypeDrawerClose();
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  const handleToolDelete = (id) => {
    setDeleteModalOpen(true);
    setToolId(id);
  };
  //
  const searchTools = () => {
    setPageController({ ...pageController, page: 1 });
  };
  //
  const handleOnClick = () => {
    setAddModalOpen(true);
    dispatch(toolActions.getAllDepartment());
  };
  //
  const debounced = useDebouncedCallback(searchTools, TIME_OUTS.DEBOUNCE);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    debounced();
  };
  //
  const handleToolEdit = (id) => {
    setEditModalOpen(true);
    setToolId(id);
    dispatch(toolActions.getAllDepartment());
  };
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params, searchData);
    dispatch(
      toolActions.getAllTools({
        query: formattedParamString,
      })
    );
  }, [params]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 8 }} px={{ xs: 0, lg: 2 }}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { lg: 'row', xs: 'column', md: 'row', sm: 'row' },
        }}
      >
        <Grid item sx={{ width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' } }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: '1.938rem',
              fontWeight: 600,
              mr: '48px',
            }}
          >
            Tools
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            backgroundColor: { xs: 'white', sm: 'unset' },
            position: { xs: 'fixed', sm: 'unset' },
            bottom: { xs: '0px', sm: 'unset' },
            zIndex: { xs: 1, sm: 'unset' },
            width: { xs: '100%', sm: 'unset' },
            py: { xs: '10px', sm: 'unset' },
            px: { xs: '20px', sm: 'unset' },
            left: { xs: '0px', sm: 'unset' },
          }}
        >
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.TOOL,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            <Button onClick={() => handleOnClick()} sx={{ width: { lg: 'auto', xs: '100%' } }}>
              Add Tools <AddIcon />
            </Button>
          </PermissionWrapper>
        </Grid>
      </Grid>
      {/* Search Bar */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: {
            xs: 'space-between',
            sm: 'unset',
          },
          gap: { xs: 'unset', sm: '10px' },
          alignItems: 'center',
          my: '20px',
        }}
      >
        <Grid
          item
          sx={{
            width: { lg: '40%', xs: '100%', md: '50%', sm: '50%' },
            gap: '10px',
          }}
        >
          <SearchBar
            placeholder="Search Tools"
            value={searchData}
            handleSearchInput={handleSearchInput}
          />
        </Grid>
        <IconButton
          onClick={handleSelectTypeDrawerOpen}
          sx={{
            display: { xs: 'flex', sm: 'none' },
          }}
        >
          <TuneIcon />
        </IconButton>
        {/* Drawer with filter */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Drawer
            anchor="bottom"
            open={isSelectTypeDrawerOpen}
            onClose={handleSelectTypeDrawerClose}
            sx={{
              height: '70vh',
              padding: '16px',
            }}
          >
            {/* Drawer content goes here */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: '16px',
              }}
            >
              <IconButton
                onClick={handleSelectTypeDrawerClose}
                sx={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                }}
              >
                <CloseIcon />
              </IconButton>

              <Box
                sx={{
                  width: '100%',
                  mx: '16px',
                }}
              >
                <Autocomplete
                  id="typeFilter"
                  name="typeFilter"
                  options={['All', ...Object.values(TOOL_TYPES)]}
                  label="Select type"
                  controlled
                  value={paymentTypeFilter}
                  onChange={handleTypeOnChange}
                  getOptionLabel={(option) => capitalizeFirstLetter(option)}
                  disableClearable={paymentTypeFilter === 'All'}
                />
              </Box>
            </Box>
          </Drawer>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'unset' },
            pb: 3,
            width: { sm: '100%', md: '25%' },
          }}
        >
          <Autocomplete
            id="typeFilter"
            name="typeFilter"
            options={['All', ...Object.values(TOOL_TYPES)]}
            label="Select type"
            controlled
            value={paymentTypeFilter}
            onChange={handleTypeOnChange}
            getOptionLabel={(option) => capitalizeFirstLetter(option)}
            disableClearable={paymentTypeFilter === 'All'}
          />
        </Box>
      </Box>
      {isFirst ? (
        <ShimmerTable row={5} col={7} />
      ) : (
        <DataGrid
          columns={
            toolsEditPermissionsVerified || toolsDeletePermissionsVerified
              ? [...TOOLS_TABLE_COLUMNS_BASIC, ...TOOLS_TABLE_ACTION_COLUMNS]
              : TOOLS_TABLE_COLUMNS_BASIC
          }
          rows={
            allTools?.docs?.map((tool) => ({
              ...tool,
              tool: {
                toolId: tool?.id,
                toolLogo: tool?.logo,
                toolName: tool?.name,
              },
              description: {
                value: tool?.description,
              },
              paymentType: {
                value: tool?.paymentType,
              },
              price: {
                value: getDollarFormattedValue(tool?.price || '-'),
              },
              createdDate: {
                value: new Date(tool?.createdAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              lastUpdate: {
                value: new Date(tool?.updatedAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              action: {
                toolsEditPermissionsVerified,
                toolsDeletePermissionsVerified,
                isLoading,
                handleToolEdit: () => handleToolEdit(tool?.id),
                handleToolDelete: () => handleToolDelete(tool?.id),
              },
            })) ?? []
          }
          handleChangePage={handleChangePage}
          page={pageController.page - 1}
          totalPages={allTools?.totalPages ?? 0}
          totalDocs={allTools?.totalDocs ?? 0}
          limit={allTools?.limit ?? pageController.rowsPerPage}
          loading={isLoading && isFirst !== true}
          pageSizeOptions={[pageController.rowsPerPage]}
          rowHeight={allTools?.docs?.length ? 52 : 200}
        />
      )}
      <AddToolPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditToolsPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        toolId={toolId}
      />
      <DeleteToolPopup
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        toolId={toolId}
      />
    </Container>
  );
};
//
export default ToolView;
