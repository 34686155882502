import { Avatar, Box, Typography } from '@mui/material';
import { DatePicker, TextField } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
/**
 * Defines the table header details for selected table
 */
export const REALLOCATION_TEAM_MEMBER_HEADERS = [
  {
    field: 'profile',
    headerName: 'Profile',
    flex: 1.25,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { value, profileImage, designation } = params.row.profile;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '30ch' }}>
          {profileImage ? (
            <img
              alt="profile"
              src={profileImage}
              style={{ height: '40px', width: '40px', borderRadius: '50%' }}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Avatar sx={{ height: '40px', width: '40px' }}>{value.charAt(0)}</Avatar>
          )}
          <Box sx={{ ml: '8px' }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: '16px',
                maxWidth: '25ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '14px',
                color: COLORS.SILVER_LILAC,
                maxWidth: '25ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {designation}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    flex: 1,
    type: 'date',
    valueGetter: (params) => new Date(params.row.startDate.startDate),
    renderCell: (params) => {
      const { startDate } = params.row.startDate;
      return (
        <DatePicker
          disabled
          value={startDate}
          sx={{
            width: '18ch',
          }}
        />
      );
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    flex: 1,
    type: 'date',
    valueGetter: (params) => new Date(params.row.endDate.endDate),
    renderCell: (params) => {
      const { onChange, value, minDate, maxDate } = params.row.endDate;
      return (
        <DatePicker
          onChange={onChange}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          sx={{
            width: '18ch',
          }}
        />
      );
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    flex: 1,
    type: 'number',
    headerAlign: 'left',
    valueGetter: (params) => params.row.allocation.value,
    renderCell: (params) => {
      const { onChange, value, isDisabled, helperText, error } = params.row.allocation;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            value={value}
            onChange={onChange}
            type="number"
            helperText={helperText}
            error={error}
            disabled={isDisabled}
          />
        </Box>
      );
    },
  },
];
//
export default REALLOCATION_TEAM_MEMBER_HEADERS;
