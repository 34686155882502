import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';
import { Download as DownloadIcon, Sync as SyncIcon } from '@mui/icons-material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ShimmerTable } from 'react-shimmer-effects';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import { TextField, Autocomplete, Button } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import { downloadCSVFile } from 'features/base/helpers/file';
import { notificationActions } from 'features/base/notifications/slice';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import TIME_OUTS from 'features/base/constants/time-outs';
import createFormattedString from 'features/base/helpers/param-formatter';
import {
  selectCapacityReportUsers,
  selectCapacityReportUsersLoading,
  selectCapacityReportIsInitialCall,
  selectCapacityReportUserList,
  selectCapacityReportDesignationsList,
  selectCapacityReportWorkAreasList,
  selectCapacityReportProjectsLoading,
  selectCapacityReportProjectList,
} from 'features/reports/selectors';
import { reportActions } from 'features/reports/slice';
import COLORS from 'features/base/constants/colors';
/**
 * Component that defines the entire capacity report view
 * @returns MUI Container with the capacity report view
 */
const CapacityReportView = () => {
  const dispatch = useDispatch();
  //
  const reportUsers = useSelector(selectCapacityReportUsers);
  const reportUsersLoading = useSelector(selectCapacityReportUsersLoading);
  const userList = useSelector(selectCapacityReportUserList);
  const designationsList = useSelector(selectCapacityReportDesignationsList);
  const workAreasList = useSelector(selectCapacityReportWorkAreasList);
  const projectList = useSelector(selectCapacityReportProjectList);
  const projectListLoading = useSelector(selectCapacityReportProjectsLoading);
  const isInitialCall = useSelector(selectCapacityReportIsInitialCall);
  //
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    id: moment().year(),
    label: `${moment().year()}-${moment().year() + 1}`,
  });
  const [workAreaFilter, setWorkAreaFilter] = useState({ id: 'All', label: 'All' });
  const [designationFilter, setDesignationFilter] = useState({ id: 'All', label: 'All' });
  const [projectFilter, setProjectFilter] = useState({ id: 'All', label: 'All' });
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  // generate years from 2021 to current year + 4 [{ id: '2022', label : '2022-2023' },...] using moment
  const years = useMemo(() => {
    const startYear = 2021;
    const currentYear = moment().year();
    const yearsArray = [];
    for (let i = startYear; i <= currentYear + 4; i += 1) {
      yearsArray.push({ id: i, label: `${i}-${i + 1}` });
    }
    return yearsArray;
  }, []);
  //
  const months = useMemo(() => {
    const monthsArray = Array.from({ length: 12 }, (_, i) => moment().month(i).format('MMMM'));
    return monthsArray;
  }, []);
  // Fetch users and projects
  useEffect(() => {
    dispatch(reportActions.setCapacityReportIsInitial());
    dispatch(
      reportActions.getCapacityReportUsersList({
        query: 'pagination=false&sortBy=email:asc&type=INTERNAL',
      })
    );
    dispatch(
      reportActions.getCapacityReportDesignations({ query: 'pagination=false&sortBy=name:asc' })
    );
    dispatch(
      reportActions.getCapacityReportWorkAreas({ query: 'pagination=false&sortBy=name:asc' })
    );
    dispatch(
      reportActions.getCapacityReportProjects({ query: 'pagination=false&sortBy=name:asc' })
    );
  }, []);
  //
  const params = useMemo(
    () => ({
      // Add the ids attribute to the params object if the selectedUsers array does not contain 'All' as an element. set the value of ids to the selectedUsers array as comma separated string
      userIds: selectedUsers?.find((item) => item.id === 'All')
        ? undefined
        : selectedUsers
            .map((item) => item.id)
            .filter((item) => item !== 'All')
            .join(','),
      aggregated: true,
      limit: WORK_ALLOCATION_PAGINATION_LIMIT,
      sortBy: 'firstName:asc',
      page,
      search: searchFilter === '' ? undefined : searchFilter,
      designation: designationFilter?.id === 'All' ? undefined : designationFilter?.label,
      workAreaIds: workAreaFilter?.id === 'All' ? undefined : workAreaFilter?.id,
      projectId: projectFilter?.id === 'All' ? undefined : projectFilter?.id,
      // only append excluded: 'false' if the selectedProjects array does not contain 'All' as an element
      excluded: projectFilter?.id === 'All' ? undefined : 'false',
      utilizationStartDate: moment(dateFilter.id, 'YYYY').startOf('year').format(ISO_WITHOUT_TIME),
      utilizationEndDate: moment(dateFilter.id, 'YYYY').endOf('year').format(ISO_WITHOUT_TIME),
    }),
    [
      dateFilter,
      page,
      designationFilter,
      workAreaFilter,
      searchFilter,
      selectedUsers,
      projectFilter,
    ]
  );
  //
  const debouncedFetch = useDebouncedCallback((param) => {
    const formattedParamString = createFormattedString(param);
    dispatch(reportActions.getCapacityReportUsers({ query: formattedParamString }));
  }, TIME_OUTS.DEBOUNCE);
  // Fetch allocations
  useEffect(() => {
    debouncedFetch(params);
  }, [params]);
  //
  const fetchNextPage = () => {
    setPage(reportUsers?.page ? reportUsers.page + 1 : 0);
  };
  //
  const initialize = () => {
    setPage(1);
    dispatch(reportActions.setCapacityReportIsInitial());
  };
  // debounce the search filter
  const debouncedSearch = useDebouncedCallback((search) => {
    setSelectedUsers([{ id: 'All', label: 'All' }]);
    setSearchFilter(search);
    initialize();
  }, TIME_OUTS.DEBOUNCE);
  // sync button handler
  const handleSync = () => {
    setSelectedUsers([{ id: 'All', label: 'All' }]);
    setDesignationFilter({ id: 'All', label: 'All' });
    setWorkAreaFilter({ id: 'All', label: 'All' });
    setProjectFilter({ id: 'All', label: 'All' });
    setSearchFilter('');
    setDateFilter({
      id: moment().year(),
      label: `${moment().year()}-${moment().year() + 1}`,
    });
    initialize();
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getCapacityReportUsers({ query: `${formattedParamString}&runAggregation=true` })
    );
  };
  // Cleanup function to improve performance
  useEffect(
    () => () => {
      dispatch(reportActions.resetCapacityReport());
    },
    []
  );
  //
  const handleExport = () => {
    if (!reportUsers?.docs?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No data to export',
          type: ERROR_TYPES.INFO,
        })
      );
    }
    const data = reportUsers?.docs?.map((item) => {
      const { utilization, ...rest } = item;
      // Generating the required structure for the CSV file
      const formatted = {};
      months.forEach((month, index) => {
        const totalHours = utilization?.find(
          (util) => util.month === index + 1 && util.year === dateFilter.id
        )?.totalHours;
        const totalHoursKey = `Utilization (${month}) %`;
        formatted[totalHoursKey] = `${((totalHours ?? 0) / (rest?.capacity || 160)) * 100}`.concat(
          ' %'
        );
      });
      //
      return {
        'Team Member': `${rest?.firstName} ${rest?.lastName}`,
        Designation:
          rest?.currentUserDepartmentDesignation?.departmentDesignation?.designation?.name,
        'Work Area': rest?.workAreaId?.name,
        Availability: (
          100 -
          ((utilization?.reduce((acc, utl) => acc + utl.totalHours ?? 0, 0) || 0) /
            ((rest?.capacity || 160) * 12)) *
            100
        )
          .toFixed(2)
          .concat(' %'),
        ...formatted,
      };
    });
    const filename = `capacity_report.csv`;
    downloadCSVFile(Object.keys(data?.[0]), data, filename);
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 5 }} px={{ xs: 0, lg: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={5}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
            Capacity Report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box sx={{ display: 'flex', alignItems: 'right', paddingTop: '0.5rem' }}>
            <Box sx={{ width: 20, height: 20, backgroundColor: COLORS.CRIMSON_BLUSH }} />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              Above 100%
            </Typography>
            <Box
              sx={{ width: 20, height: 20, backgroundColor: COLORS.SUCCESS_LIGHT, marginLeft: 2 }}
            />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              80% - 100%
            </Typography>
            <Box
              sx={{ width: 20, height: 20, backgroundColor: COLORS.WARNING_LIGHT, marginLeft: 2 }}
            />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              50% - 80%
            </Typography>
            <Box sx={{ width: 20, height: 20, backgroundColor: COLORS.SOFT_ROSE, marginLeft: 2 }} />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              0% - 50%
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          display="flex"
          flexDirection="row"
          justifyContent={{ lg: 'flex-end', md: 'flex-end' }}
        >
          <Button
            onClick={handleExport}
            disabled={reportUsersLoading}
            sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '85%' } }}
          >
            <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
            Export
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={handleSync}
            disabled={reportUsersLoading}
            sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '85%' } }}
          >
            <SyncIcon sx={{ marginLeft: '0.25rem' }} />
            Sync
          </Button>
        </Grid>
      </Grid>
      {/* header */}
      <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
        {/* search */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            id="search"
            name="search"
            label="Search"
            placeholder="Search Member"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </Grid>

        {/* projects */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Autocomplete
            id="projects"
            name="projects"
            label="Projects"
            placeholder="Select projects"
            disableClearable
            disabled={projectListLoading}
            options={[
              { id: 'All', label: 'All' },
              ...(projectList.map((item) => ({ id: item.id, label: item.name })) ?? []),
            ]}
            onChange={(_, newValue) => {
              setProjectFilter(newValue);
              initialize();
            }}
            value={projectFilter}
            getOptionLabel={(option) => option.label}
            controlled
          />
        </Grid>

        {/* users */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MultiSelectAutoComplete
            id="users"
            name="users"
            label="Users"
            displayAttribute="label"
            placeholder="Select users"
            isOptionEqualToValue={(option, autoValue) => option.id === autoValue.id}
            options={[
              { id: 'All', label: 'All' },
              ...userList.map((item) => ({
                id: item.id,
                label: `${item.firstName} ${item.lastName}`,
              })),
            ]}
            value={selectedUsers}
            onChange={(_, newValue) => {
              initialize();
              // if only the 'All' option is selected, set the tick to all the options
              if (newValue?.length === 1 && newValue[0]?.id === 'All') {
                setSelectedUsers([
                  { id: 'All', label: 'All' },
                  ...(userList?.map(
                    (item) =>
                      ({
                        id: item.id,
                        label: `${item.firstName} ${item.lastName}`,
                      } ?? [])
                  ) ?? []),
                ]);
                return;
              }
              // if the 'All' option is not selected, but the all other options are selected, unselect all the options
              if (newValue.length === userList.length && newValue[0].id !== 'All') {
                setSelectedUsers([]);
                return;
              }
              setSelectedUsers(newValue);
            }}
          />
        </Grid>

        {/* designation */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Autocomplete
            id="designation"
            name="designation"
            label="Designation"
            placeholder="Select designation"
            value={designationFilter}
            disableClearable
            options={[
              { id: 'All', label: 'All' },
              ...(designationsList.map((item) => ({ id: item.id, label: item.name })) ?? []),
            ]}
            onChange={(_, newValue) => {
              setDesignationFilter(newValue);
              initialize();
            }}
            controlled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Autocomplete
            id="work-area"
            name="work-area"
            label="Work Area"
            placeholder="Select work area"
            disableClearable
            options={[
              { id: 'All', label: 'All' },
              ...(workAreasList.map((item) => ({ id: item.id, label: item.name })) ?? []),
            ]}
            onChange={(_, newValue) => {
              setWorkAreaFilter(newValue);
              initialize();
            }}
            value={workAreaFilter}
            getOptionLabel={(option) => option.label}
            controlled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Autocomplete
            id="year"
            name="year"
            label="Year"
            placeholder="Select year"
            disableClearable
            options={years}
            controlled
            value={dateFilter}
            onChange={(_, newValue) => {
              setDateFilter(newValue);
              initialize();
            }}
            getOptionLabel={(option) => option.label}
          />
        </Grid>
      </Grid>
      {isInitialCall && reportUsersLoading ? (
        <ShimmerTable row={4} col={8} />
      ) : (
        reportUsers?.docs?.length > 0 && (
          <InfiniteScroll
            dataLength={reportUsers?.docs?.length ?? 0}
            next={fetchNextPage}
            hasMore={reportUsers?.hasNextPage}
            loader={
              <Box textAlign="center" margin="1.125rem">
                <CircularProgress size={30} />
              </Box>
            }
          >
            <Box sx={{ display: 'flex', gap: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      size="small"
                      rowSpan={2}
                      sx={{
                        bgcolor: COLORS.SKY_BLUE,
                        borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                        borderInline: `2px solid ${COLORS.WHITE}`,
                        minWidth: 250,
                      }}
                    >
                      <Typography>Team Member</Typography>
                    </TableCell>
                    <TableCell
                      size="small"
                      rowSpan={2}
                      sx={{
                        bgcolor: COLORS.SKY_BLUE,
                        borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                        borderInline: `2px solid ${COLORS.WHITE}`,
                        minWidth: 250,
                      }}
                    >
                      <Typography>Designation</Typography>
                    </TableCell>
                    <TableCell
                      size="small"
                      rowSpan={2}
                      sx={{
                        bgcolor: COLORS.SKY_BLUE,
                        borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                        borderInline: `2px solid ${COLORS.WHITE}`,
                      }}
                    >
                      <Typography>Work Area</Typography>
                    </TableCell>
                    <TableCell
                      size="small"
                      rowSpan={2}
                      sx={{
                        bgcolor: COLORS.SKY_BLUE,
                        borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                        borderInline: `2px solid ${COLORS.WHITE}`,
                      }}
                    >
                      <Typography>Availability</Typography>
                    </TableCell>
                    {/* 
                    ------------------------------------------------------------------------|
                    |---------------------------utilization---------------------------------|
                    | Jan | Feb | Mar | Apr | May | Jun | Jul | Aug | Sep | Oct | Nov | Dec |
                    ------------------------------------------------------------------------|
                   */}

                    <TableCell
                      colSpan={12}
                      sx={{
                        bgcolor: COLORS.SKY_BLUE,
                        borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                        borderInline: `2px solid ${COLORS.WHITE}`,
                        textAlign: 'center',
                        padding: 1,
                      }}
                    >
                      <Typography>Utilization</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {months.map((month) => (
                      <TableCell
                        sx={{
                          bgcolor: COLORS.SKY_BLUE,
                          borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                          borderInline: `2px solid ${COLORS.WHITE}`,
                          padding: 0,
                          minWidth: 20,
                          textAlign: 'center',
                        }}
                      >
                        <Typography>{month.slice(0, 3)}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(reportUsers?.docs || []).map((user, index) => (
                    <TableRow key={`${user?.id}`}>
                      <TableCell
                        size="small"
                        sx={{
                          borderBottom: `2px solid ${COLORS.WHITE}`,
                          bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                        }}
                      >
                        <Typography textAlign={'left'} size="small">
                          {user?.firstName} {user?.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{
                          borderBottom: `2px solid ${COLORS.WHITE}`,
                          bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                          display: 'flex',
                        }}
                      >
                        <Box
                          sx={{
                            height: 68,
                            maxHeight: 68,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography textAlign={'left'} variant="subtitle2">
                            {user?.currentUserDepartmentDesignation?.departmentDesignation
                              ?.designation?.name ?? 'N/A'}
                          </Typography>
                          <Typography
                            textAlign={'left'}
                            size="small"
                            variant="body2"
                            sx={{
                              fontSize: 14,
                              color: COLORS.SILVER_LILAC,
                            }}
                          >
                            {user?.currentUserDepartmentDesignation?.departmentDesignation
                              ?.department?.name ?? 'N/A'}
                          </Typography>
                        </Box>
                      </TableCell>
                      {/*  work area */}
                      <TableCell
                        size="small"
                        sx={{
                          borderBottom: `2px solid ${COLORS.WHITE}`,
                          bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                        }}
                      >
                        <Typography textAlign={'left'} size="small">
                          {user?.workAreaId?.name ?? 'N/A'}
                        </Typography>
                      </TableCell>
                      {/*  availability */}
                      <TableCell
                        size="small"
                        sx={{
                          borderBottom: `2px solid ${COLORS.WHITE}`,
                          bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
                          textAlign: 'center',
                        }}
                      >
                        <Typography size="small">
                          {(
                            100 -
                            ((user?.utilization?.reduce(
                              (acc, item) => acc + item.totalHours ?? 0,
                              0
                            ) || 0) /
                              ((user?.capacity || 160) * 12)) *
                              100
                          ).toFixed(2)}
                          %
                        </Typography>
                      </TableCell>
                      {/*  utilization */}
                      {months.map((_, indx) => (
                        <TableCell
                          size="small"
                          sx={{
                            borderBottom: `2px solid ${COLORS.WHITE}`,
                            bgcolor: (() => {
                              const totalHours =
                                ((user.utilization.find(
                                  (item) => item.month === indx + 1 && item.year === dateFilter.id
                                )?.totalHours ?? 0) /
                                  (user?.capacity || 160)) *
                                100;
                              if (totalHours > 100) {
                                return COLORS.CRIMSON_BLUSH;
                              }
                              if (totalHours > 80 && totalHours < 100) {
                                return COLORS.SUCCESS_LIGHT;
                              }
                              if (totalHours > 50 && totalHours < 80) {
                                return COLORS.WARNING_LIGHT;
                              }
                              if (totalHours > 0 && totalHours < 50) {
                                return COLORS.SOFT_ROSE;
                              }
                              return COLORS.WHITE;
                            })(),
                            textAlign: 'center',
                          }}
                        >
                          <Typography size="small">
                            {(
                              ((user.utilization.find(
                                (item) => item.month === indx + 1 && item.year === dateFilter.id
                              )?.totalHours ?? 0) /
                                (user?.capacity || 160)) *
                              100
                            ).toFixed(0)}
                            %
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </InfiniteScroll>
        )
      )}
      {!reportUsersLoading && reportUsers?.docs?.length === 0 && (
        <Grid item sx={{ marginTop: 20 }} xs={12}>
          <CustomNoRowsOverlay message="No Records Found" />
        </Grid>
      )}
    </Container>
  );
};
//
export default CapacityReportView;
