/**
 * Define department tab routes
 */
const NESTED_ROUTES = [
  {
    name: 'Designation',
    path: 'designation',
  },
  {
    name: 'Department',
    path: '',
  },
  {
    name: 'Work Area',
    path: 'work-area',
  },
];
//
export default NESTED_ROUTES;
