/**
 * Main colors
 */
const COLORS = {
  PRIMARY_MAIN: '#B04AFF',
  PRIMARY_LIGHT: '#EDE9FF',
  SECONDARY_MAIN: '#FF3841',
  SECONDARY_LIGHT: '#FFD9D9',
  SUCCESS_MAIN: '#4BB543',
  SUCCESS_LIGHT: '#DBF0D9',
  ERROR_MAIN: '#FF3841',
  ERROR_LIGHT: '#FFD9D9',
  INFO_MAIN: '#24A0ED',
  INFO_LIGHT: '#D9F0FF',
  WARNING_MAIN: '#FFB301',
  WARNING_LIGHT: '#FFF3D9',
  // White
  WHITE: '#FFFFFF',
  // Black
  BLACK: '#000000',
  FADED_BLACK: '#00000042',
  // Shades of Blue
  CELESTE_BLUE: '#d4e6ff',
  DODGER_BLUE: '#2684FF',
  LIGHT_DODGER_BLUE: '#2684FF33',
  CELESTIAL_BLUE: '#dfedff',
  ELECTRIC_BLUE: '#7D8EF7',
  NAVY_BLUE: '#0F072C',
  DEEP_NAVY_BLUE: '#0F072C99',
  DARK_NAVY_BLUE: '#0F072C',
  ROYAL_BLUE: '#5763AD',
  ALICE_BLUE: '#F1F3F4',
  LUMINOUS_COBALT: '#2684FF26',
  SKY_BLUE: '#F8F9FF',
  PALE_BLUE: '#BDC6FA',
  LIGHT_BLUE: '#edfaff',
  SILVER_BLUE: '#E7E6EA',
  DARK_ALICE_BLUE: '#E3F1FF',
  SHADOW_BLUE: '#778DA9',
  LITTLE_BOY_BLUE: '#6F9FD9',
  // Shades of purple
  PURPLE_SHADE: '#9F78BE',
  DEEP_PURPLE: '#692C99',
  GRAYISH_PURPLE: '#6F6A80',
  LILAC_MIST: '#f0e9f5',
  LAVENDER: '#d9a7ff',
  LAVENDER_GRAY: '#E7E6E9',
  LAVENDER_SHADE: '#F7ECFF',
  LAVENDER_MIST: '#E6E5E9',
  SILVER_LILAC: '#CFCDD5',
  DEEP_INDIGO: '#453F5C',
  TRANSPARENT_DARK_PURPLE: '#692C9933',
  LILAC_GRAY: '#9F9CAB',
  PALE_LILAC: '#B04AFF29',
  LILAC: '#D7A3FF',
  BLUE_MAGENTA: '#656078',
  MAGNOLIA_WHITE: '#F8EFFF',
  // Shades of green
  IGUANA_GREEN: '#69CC7E',
  PALE_GREEN: '#dbf0d9',
  TRANSPARENT_DARK_GREEN: '#0F072C14',
  // Shades of pink
  LIGHT_PINK: '#F9D2D9',
  // Shades of yellow
  GOLDEN_YELLOW: '#F9A825',
  // Shades of gray
  MEDIUM_GRAY: '#808080',
  LIGHT_GRAY: '#f5f5f5',
  DARK_SAPPHIRE: '#9EAAF9',
  LIGHT_RED: '#F8DFDD',
  CORAL_RED: '#FF3841',
  BEGONIA: '#F57177',
  CRIMSON_BLUSH: '#E98182',
  SOFT_ROSE: '##F4C7C3',
  GREY_SUIT: '#8f8e96',
};
//
export default COLORS;
