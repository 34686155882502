import * as yup from 'yup';
/**
 * Function for department form validation
 */
const departmentFormValidation = yup.object().shape({
  name: yup.string().required('Please enter valid department name'),
  designations: yup.array().min(1, 'Please select at least one designation'),
});
//
export default departmentFormValidation;
