/**
 * Available amount types
 * @type {{SALARY: string, ALLOWANCE: string }}
 */
export const AMOUNT_TYPES = {
  SALARY: 'SALARY',
  ALLOWANCE: 'ALLOWANCE',
};
/**
 * Array for amount type
 */
export const AMOUNT_TYPE_ARRAY = [
  { key: '1', value: AMOUNT_TYPES.SALARY },
  { key: '2', value: AMOUNT_TYPES.ALLOWANCE },
];
/**
 * Minimum amount salary
 * @type {number}
 */
export const AMOUNT = 100;
/**
 * Amount ranges
 * @type {number}
 */
export const AMOUNT_RANGES = {
  MIN_AMOUNT: 100,
  MAX_AMOUNT: 999999999,
};
/**
 * Rate per hour ranges
 */
export const RATE_PER_HOUR_RANGES = {
  MIN_RATE_PER_HOUR: 0,
  MAX_RATE_PER_HOUR: 500,
};
