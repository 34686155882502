import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import COLORS from 'features/base/constants/colors';
/**
 * Styles object for data table component styles
 */
export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.SKY_BLUE,
    color: COLORS.NAVY_BLUE,
    fontWeight: 600,
    opacity: 1,
    paddingTop: '18px',
    paddingBottom: '18px',
    fontSize: '1rem',
    borderBottom: `2px solid ${COLORS.ELECTRIC_BLUE}`,
    borderTop: `1px solid ${COLORS.ELECTRIC_BLUE}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1rem',
    color: COLORS.NAVY_BLUE,
  },
}));
//
export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },
  '&:nth-of-type(even)': {
    backgroundColor: COLORS.SKY_BLUE,
  },
  '&:nth-of-type(odd) td, &:nth-of-type(odd) th': {
    border: 0,
    borderBottom: 0,
  },
  '&:nth-of-type(even) td, &:nth-of-type(even) th': {
    border: 0,
    borderBottom: 0,
  },
}));
