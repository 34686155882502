import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import COLORS from 'features/base/constants/colors';
/**
 * UI for common tab component
 * @param {array} nestedRoutes - Array of objects containing name and path values
 * @returns {Tabs}
 */
const TabBar = ({ nestedRoutes, pathName }) => {
  const navigate = useNavigate();
  //
  const [routeIndex, setRouteIndex] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  //
  const getVariant = () => {
    if (viewportWidth < 700) {
      return 'scrollable';
    }
    return 'fullWidth';
  };
  //
  useEffect(() => {
    const index = nestedRoutes.findIndex((route) => route.path === pathName);
    setRouteIndex(index);
  }, [pathName]);
  //
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [viewportWidth]);
  //
  return (
    <Tabs
      value={routeIndex}
      onChange={(_, newValue) => setRouteIndex(newValue)}
      aria-label="wrapped label tabs example"
      variant={getVariant()}
      scrollButtons="auto"
      allowScrollButtonsMobile
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: (theme) => theme.palette.info.main,
        },
        '& .MuiTabScrollButton-root.Mui-disabled': {
          opacity: 0.3,
        },
      }}
    >
      {nestedRoutes.map((tab, index) => (
        <Tab
          key={tab.name}
          label={tab.name}
          onClick={() => {
            navigate(tab.path);
          }}
          sx={{
            '&.Mui-selected': {
              backgroundColor: (theme) => theme.palette.info.main,
              color: (theme) => theme.palette.common.white,
              transitionDuration: '0.5s',
            },
            '&.MuiTab-root': {
              fontWeight: '600',
              fontSize: '1rem',
              textTransform: 'none',
            },
            '&:hover:not(.Mui-selected)': {
              backgroundColor: COLORS.SILVER_LILAC,
            },
            '&:not(.Mui-selected)': {
              borderRight: index === nestedRoutes.length - 1 ? COLORS.SILVER_LILAC : `1px solid ${COLORS.FADED_BLACK}`,
              borderLeft: index === 0 ? COLORS.SILVER_LILAC : `1px solid ${COLORS.FADED_BLACK}`,
            },
            bgcolor: COLORS.TRANSPARENT_DARK_GREEN,
          }}
        />
      ))}
    </Tabs>
  );
};
//
export default TabBar;
