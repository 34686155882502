// Mobile view of the add skills groups form
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import AddSkillGroupsForm from 'features/skills/components/add-skill-groups/add-skill-groups-form';
import { Drawer } from 'features/base/components';
/**
 * Function that defines the popup form for adding new skill groups
 * @returns {Popup}
 */
const AddSkillGroupsDrawer = ({ addGroupsDrawerOpen, setAddGroupsDrawerOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setAddGroupsDrawerOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (
      addGroupsDrawerOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SKILL,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Drawer open={addGroupsDrawerOpen} onClose={handleOnClose} title="Add Skill Groups">
        <AddSkillGroupsForm openSetter={setAddGroupsDrawerOpen} />
      </Drawer>
    </PermissionWrapper>
  );
};
//
export default AddSkillGroupsDrawer;
