import { Box, Typography } from '@mui/material';
//
import noProfileImage from 'features/base/assets/images/png/no-profile.png';
import { TextField } from 'features/base/components';
/**
 * Fixed bid table columns
 */
export const FIXED_BID_TABLE_COLUMNS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 350,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { img, value } = params.row.profile;
      if (params.row.id !== 'totalRow') {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
              src={img || noProfileImage}
              alt="profile-pic"
              referrerPolicy="no-referrer"
            />
            {value}
          </Box>
        );
      }
      return <Typography fontWeight="bold">{value}</Typography>;
    },
  },
  {
    field: 'department',
    headerName: 'Department & Designation',
    width: 350,
    renderCell: (params) => {
      const value = params.row.department;
      if (params.row.id !== 'totalRow') {
        return value;
      }
      return '-';
    },
  },
  {
    field: 'totalHours',
    headerName: 'Total hours',
    width: 275,
    renderCell: (params) => {
      const value = params.row.totalHours;
      if (params.row.id !== 'totalRow') {
        return value;
      }
      return <Typography fontWeight="bold">{value}</Typography>;
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    width: 275,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.allocation,
    renderCell: (params) => {
      const value = params.row.allocation;
      if (params.row.id !== 'totalRow') {
        return value;
      }
      return '-';
    },
  },
  {
    field: 'revenue',
    headerName: 'Revenue $',
    width: 275,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.revenue.value,
    renderCell: (params) => {
      const { valueWithDollarSymbol } = params.row.revenue;
      if (params.row.id !== 'totalRow') {
        return valueWithDollarSymbol;
      }
      return <Typography fontWeight="bold">{valueWithDollarSymbol}</Typography>;
    },
  },
];
/**
 * Monthly fixed table columns
 */
export const MONTHLY_FIXED_TABLE_COLUMNS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 300,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { img, value } = params.row.profile;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
            src={img || noProfileImage}
            alt="profile-pic"
            referrerPolicy="no-referrer"
          />
          {value}
        </Box>
      );
    },
  },
  { field: 'department', headerName: 'Department & Designation', width: 310 },
  {
    field: 'hourlyRate',
    headerName: 'Rate per hour',
    width: 200,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.hourlyRate.value,
    renderCell: (params) => {
      const { value, onChange, helperText, error, ratePerHourRef } = params.row.hourlyRate;
      return (
        <Box
          sx={{
            display: 'flex',
            mt: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            type="number"
            size="small"
            defaultValue={value}
            onChange={onChange}
            helperText={helperText}
            error={error}
            inputRef={ratePerHourRef}
            showErrorAfterBlur
            sx={{ pb: 2, pt: 2 }}
          />
        </Box>
      );
    },
  },
  {
    field: 'hoursPerMonth',
    headerName: 'Hours per month',
    width: 175,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    width: 175,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'revenuePerMonth',
    headerName: 'Revenue per month $',
    width: 190,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.revenuePerMonth.value,
    renderCell: (params) => params.row.revenuePerMonth.valueWithDollarSymbol,
  },
  {
    field: 'totalRevenue',
    headerName: 'Total revenue $',
    width: 175,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.totalRevenue.value,
    renderCell: (params) => params.row.totalRevenue.valueWithDollarSymbol,
  },
];
/**
 * Time & material table columns
 */
export const TIME_AND_MATERIAL_TABLE_COLUMNS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 350,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { img, value } = params.row.profile;
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
            src={img || noProfileImage}
            alt="profile-pic"
            referrerPolicy="no-referrer"
          />
          {value}
        </Box>
      );
    },
  },
  { field: 'department', headerName: 'Department & Designation', width: 350 },
  {
    field: 'hourlyRate',
    headerName: 'Rate per hour',
    width: 225,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.hourlyRate.value,
    renderCell: (params) => {
      const { value, onChange, helperText, error } = params.row.hourlyRate;
      return (
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center', alignItems: 'center' }}>
          <TextField
            type="number"
            size="small"
            defaultValue={value}
            onChange={onChange}
            helperText={helperText}
            error={error}
          />
        </Box>
      );
    },
  },
  {
    field: 'totalHours',
    headerName: 'Hours',
    width: 200,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    width: 200,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'revenue',
    headerName: 'Revenue $',
    width: 200,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.revenue.value,
    renderCell: (params) => params.row.revenue.valueWithDollarSymbol,
  },
];
/**
 * Not client projects table columns
 */
export const INTERNAL_TABLE_COLUMNS = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 400,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { img, value } = params.row.profile;
      if (params.row.id !== 'totalRow') {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '16px' }}
              src={img || noProfileImage}
              alt="profile-pic"
              referrerPolicy="no-referrer"
            />
            {value}
          </Box>
        );
      }
      return <Typography fontWeight="bold">{value}</Typography>;
    },
  },
  { field: 'department', headerName: 'Department & Designation', width: 420 },
  {
    field: 'totalHours',
    headerName: 'Hours',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 350,
    valueGetter: (params) => params.row.totalHours,
    renderCell: (params) => {
      const value = params.row.totalHours;
      if (params.row.id !== 'totalRow') {
        return value;
      }
      return <Typography fontWeight="bold">{value}</Typography>;
    },
  },
  {
    field: 'allocation',
    headerName: 'Allocation %',
    width: 350,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
];
