/**
 * Available project types
 * @type {{CLIENT: string, INTERNAL: string, PRODUCT: string, PRE_SALE: string}}
 */
export const PROJECT_TYPES = {
  CLIENT: 'CLIENT',
  INTERNAL: 'INTERNAL',
  PRODUCT: 'PRODUCT',
  PRE_SALE: 'PRE-SALE',
};
/**
 * Array for project types
 */
export const PROJECT_TYPE_ARRAY = [
  { key: 1, value: PROJECT_TYPES.CLIENT },
  { key: 2, value: PROJECT_TYPES.INTERNAL },
  { key: 3, value: PROJECT_TYPES.PRODUCT },
  { key: 4, value: PROJECT_TYPES.PRE_SALE },
];
