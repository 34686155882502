import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { Download as DownloadIcon, Sync as SyncIcon } from '@mui/icons-material';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
//
import { useDebouncedCallback } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import createFormattedString from 'features/base/helpers/param-formatter';
import {
  selectTeamReportAllocations,
  selectTeamReportAllocationsLoading,
  selectTeamReportIsInitialCall,
  selectTeamReportProjectList,
  selectTeamReportProjectsLoading,
  selectTeamReportUserList,
  selectTeamReportUsersLoading,
} from 'features/reports/selectors';
import { reportActions } from 'features/reports/slice';
import { downloadCSVFile } from 'features/base/helpers/file';
import { generateLookup, getIdList } from 'features/base/helpers/object';
import { Button } from 'features/base/components';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import ROUTES from 'features/base/constants/routes';
import TIME_OUTS from 'features/base/constants/time-outs';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { DailyWorklogTable, Filters, UserProjectTable } from './components';

/**
 * Component that defines the entire team report view
 * @returns MUI Container with the team report view
 */
const TeamReportView = () => {
  const dispatch = useDispatch();
  //
  const location = useLocation();
  const navigate = useNavigate();
  //
  const allocations = useSelector(selectTeamReportAllocations);
  const userList = useSelector(selectTeamReportUserList);
  const projectList = useSelector(selectTeamReportProjectList);
  const allocationsLoading = useSelector(selectTeamReportAllocationsLoading);
  const usersLoading = useSelector(selectTeamReportUsersLoading);
  const projectsLoading = useSelector(selectTeamReportProjectsLoading);
  const isInitialCall = useSelector(selectTeamReportIsInitialCall);
  const notification = useSelector(selectNotification);
  //
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedProjects, setSelectedProjects] = useState();
  const [startDate, setStartDate] = useState(() => moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(() => moment().format('YYYY-MM-DD'));
  const [debounceLoading, setDebounceLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [lastParams, setLastParams] = useState(null);
  //
  const loading = allocationsLoading || usersLoading || projectsLoading;
  //
  const userMap = useMemo(() => generateLookup(userList, 'id', 'email'), [userList]);
  const projectMap = useMemo(() => generateLookup(projectList, 'id', 'name'), [projectList]);
  //
  const dates = useMemo(() => {
    const datesArray = [];
    let currentDate = moment(startDate);
    //
    while (currentDate.isSameOrBefore(endDate, 'day')) {
      datesArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
    //
    return datesArray;
  }, [startDate, endDate]);
  //
  const groupedDates = useMemo(
    () => groupBy(dates, (date) => moment(date).format('YYYY-MM')),
    [dates]
  );
  // Function to parse query parameters from the URL
  const getQueryParameter = (name) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(name);
  };
  // Function to set page to first page and initialize the allocation slice
  const initilialize = () => {
    setPage(1);
    dispatch(reportActions.setTeamReportIsInitial());
  };
  //
  const handleStartDateChange = (e) => {
    setStartDate(moment(e?.$d).format(ISO_WITHOUT_TIME));
    initilialize()
  };
  //
  const handleEndDateChange = (e) => {
    setEndDate(moment(e?.$d).format(ISO_WITHOUT_TIME));
    initilialize()
  };
  /**
   * This is used as a central point of logged hours calculation, used for both rendering the report and generating the data for the excel file
   * Data structure (lookup structure that optimizes the code by reducing loops):
   * [{ projectId: 'id1', userId: 'id1', '01-01-2023': 24, '01-02-2023': 40 }, { projectId: 'id2', userId: 'id2', '01-01-2023': 43, '01-02-2023': 10 }]
   */
  const loggedHoursPerAllocation = useMemo(
    () =>
      allocations?.docs?.map((allocation) => {
        const logsByYearMonth = {};
        allocation?.monthlyAllocations?.forEach((monthlyAllocation) => {
          const key = `${monthlyAllocation.year}-${monthlyAllocation.month}`;
          logsByYearMonth[key] = monthlyAllocation.loggedDays;
        });
        const datesData = {};
        dates.forEach((date) => {
          const isWeekend = moment(date).day() === 6 || moment(date).day() === 0;
          const [year, month, day] = date.split('-');
          const dailyLogs = logsByYearMonth[`${year}-${parseInt(month, 10)}`];
          const hoursLogged = (dailyLogs && dailyLogs[day]) || 0;
          datesData[date] = isWeekend ? null : hoursLogged;
        });
        return {
          projectId: allocation?.projectId,
          userId: allocation?.userId,
          ...datesData,
        };
      }),
    [allocations]
  );
  //
  const params = useMemo(
    () => ({
      userIds:
        selectedUsers?.length >= userList?.length && userList?.length
          ? ''
          : getIdList(selectedUsers),
      projectIds:
        selectedProjects?.length >= projectList?.length && projectList?.length
          ? ''
          : getIdList(selectedProjects),
      aggregated: 'true',
      limit: WORK_ALLOCATION_PAGINATION_LIMIT,
      sortBy: 'projectId.name:asc',
      page,
      startDate,
      endDate,
    }),
    [selectedUsers, selectedProjects, startDate, endDate, page, userList, projectList]
  );
  // This function is for fetching allocations with the current query params
  const fetchAllocations = () => {
    const formattedParamString = createFormattedString(params);
    dispatch(reportActions.getTeamReportAllocations({ query: formattedParamString }));
    setDebounceLoading(false);
  };
  // This callback function is for fetching allocations with a specified debounce
  const debouncedFetch = useDebouncedCallback(
    fetchAllocations,
    TIME_OUTS.ALLOCATION_FETCH_DEBOUNCE
  );
  // This function handles the onChange event on the users filter
  const handleSelectedUsersChange = (newUsers) => {
    let formattedParams = '';
    const projectIdsFromUrl = getQueryParameter('project');
    const allSelected = newUsers.find((newUser) => newUser?.id === 'All');
    const allPreviouslySelected = selectedUsers?.find((user) => user?.id === 'All');
    let updatedUsers;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedUsers = getIdList(newUsers.filter((newUser) => newUser?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show 'All' for users
        updatedUsers = getIdList(userList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other users
      updatedUsers = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for users
      updatedUsers = getIdList(newUsers);
    }
    if (
      JSON.stringify({
        ...params,
        userIds:
          updatedUsers?.split(',')?.length === userList?.length
            ? ''
            : updatedUsers ?? params?.userIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if userIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: updatedUsers,
      project: projectIdsFromUrl,
    });
    navigate(`${ROUTES.TEAM_REPORT}?${formattedParams}`);
  };
  // This function handles the onChange event on the projects filter
  const handleSelectedProjectsChange = (newProjects) => {
    let formattedParams = '';
    const userIdsFromUrl = getQueryParameter('user');
    const allSelected = newProjects.find((newProject) => newProject?.id === 'All');
    const allPreviouslySelected = selectedProjects?.find((project) => project?.id === 'All');
    let updatedProjects;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedProjects = getIdList(newProjects.filter((newProject) => newProject?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show all project ids
        updatedProjects = getIdList(projectList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other projects
      updatedProjects = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for projects
      updatedProjects = getIdList(newProjects);
    }
    if (
      JSON.stringify({
        ...params,
        projectIds:
          updatedProjects?.split(',')?.length === projectList?.length
            ? ''
            : updatedProjects ?? params?.projectIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if projectIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: userIdsFromUrl,
      project: updatedProjects,
    });
    navigate(`${ROUTES.TEAM_REPORT}?${formattedParams}`);
  };
  //
  const handleExport = () => {
    if (!loggedHoursPerAllocation?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No data to export',
          type: ERROR_TYPES.INFO,
        })
      );
      return;
    }
    //
    const data = loggedHoursPerAllocation?.map((item) => {
      const { projectId, userId, ...rest } = item;
      // Sets '-' as the value for weekends
      Object.keys(rest).forEach((key) => {
        if (typeof rest[key] !== 'number') rest[key] = '-';
      });
      //
      return {
        Project: projectId?.name ?? '-',
        Profile:
          `${userId?.firstName} ${userId?.lastName} (${userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name})` ??
          '-',
        ...rest,
      };
    });
    let filename = 'team_report';
    if (selectedUsers?.length === 1) {
      const selectedUser = userList?.find((user) => user?.id === selectedUsers?.[0]);
      if (selectedUser?.email) {
        filename = `${filename}_${selectedUser?.email}`;
      }
    }
    if (selectedProjects?.length === 1) {
      const selectedProject = projectList?.find((project) => project?.id === selectedProjects?.[0]);
      if (selectedProject?.name) {
        filename = `${filename}_${selectedProject?.name}`;
      }
    }
    downloadCSVFile(Object.keys(data?.[0]), data, `${filename}.csv`);
  };
  //
  const handleSync = () => {
    initilialize();
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getTeamReportAllocations({ query: `${formattedParamString}&runAggregation=true` })
    );
  };
  // Fetch users and projects
  useEffect(() => {
    dispatch(reportActions.getTeamReportUsers({ query: 'pagination=false&sortBy=email:asc' }));
    dispatch(reportActions.getTeamReportProjects({ query: 'pagination=false&sortBy=name:asc' }));
  }, []);
  //
  useEffect(() => {
    // This check is used to prevent redundant api calls being sent due to the complex side effects in this file
    if (JSON.stringify(params) !== JSON.stringify(lastParams)) {
      if (!lastParams) {
        // In the initial call, send a debounced fetch after all the required processing has been completed
        debouncedFetch();
        setLastParams(params);
        return;
      }
      if (
        JSON.stringify({ userIds: params?.userIds, projectIds: params?.projectIds }) ===
        JSON.stringify({ userIds: lastParams?.userIds, projectIds: lastParams?.projectIds })
      ) {
        // If anything other than the filters has changed (ie: page), call the fetch api directly without a debounce
        fetchAllocations();
      } else {
        // If the filters has been updated, instead of sending an api call for each click,
        // use a debounced fetch to send a single request with multiple filters
        debouncedFetch();
      }
      setLastParams(params);
    }
  }, [params]);
  // This useEffect handles the changes for the 'project' query param in the url
  useEffect(() => {
    const usersFromUrl = getQueryParameter('user')?.split(',');
    const usersFromUrlFormatted = usersFromUrl?.map((userId) => ({
      id: userId,
      label: userMap?.[userId],
    }));
    if (usersFromUrl?.length === userList?.length) {
      setSelectedUsers(usersFromUrl ? [{ id: 'All', label: 'All' }, ...usersFromUrlFormatted] : []);
    } else {
      setSelectedUsers(usersFromUrl ? usersFromUrlFormatted : []);
    }
  }, [location.search, userList, userMap]);
  // This useEffect handles the changes for the 'user' query param in the url
  useEffect(() => {
    const projectsFromUrl = getQueryParameter('project')?.split(',');
    const projectsFromUrlFormatted = projectsFromUrl?.map((projectId) => ({
      id: projectId,
      label: projectMap?.[projectId],
    }));
    if (projectsFromUrl?.length === projectList?.length) {
      setSelectedProjects(
        projectsFromUrl ? [{ id: 'All', label: 'All' }, ...projectsFromUrlFormatted] : []
      );
    } else {
      setSelectedProjects(projectsFromUrl ? projectsFromUrlFormatted : []);
    }
  }, [location.search, projectList, projectMap]);
  // Cleanup function to improve performance
  useEffect(
    () => () => {
      dispatch(reportActions.resetTeamReport());
    },
    []
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  const fetchNextPage = () => {
    setPage(allocations?.page ? allocations.page + 1 : 0);
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 5 }} px={{ xs: 0, lg: 2 }}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: 3,
          mb: 2,
          py: 1,
        }}
      >
        <Grid item xs={12} lg={6} sm={12} md={6} sx={{ mb: { xs: 2 } }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
            Team report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} display="flex" justifyContent={{ lg: 'flex-end', md: 'flex-end' }}>
          <Button
            onClick={handleExport}
            disabled={loading}
            sx={{ width: 'auto', marginRight: '0.75rem' }}
          >
            <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
            Export
          </Button>
          <Button onClick={handleSync} disabled={loading} sx={{ width: 'auto' }}>
            <SyncIcon sx={{ marginLeft: '0.25rem' }} />
            Sync
          </Button>
        </Grid>
      </Grid>
      <Filters
        userList={userList}
        projectList={projectList}
        selectedUsers={selectedUsers}
        selectedProjects={selectedProjects}
        handleSelectedUsersChange={handleSelectedUsersChange}
        handleSelectedProjectsChange={handleSelectedProjectsChange}
        startDate={startDate}
        handleStartDateChange={handleStartDateChange}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        loading={loading}
      />
      {isInitialCall && (loading || debounceLoading) ? (
        <Grid container display="flex" flexDirection="row" spacing={3}>
          <Grid item xs={4}>
            <ShimmerTable row={5} col={2} />
          </Grid>
          <Grid item xs={8}>
            <ShimmerTable row={5} col={12} />
          </Grid>
        </Grid>
      ) : (
        allocations?.docs?.length > 0 && (
          <InfiniteScroll
            dataLength={allocations?.docs?.length ?? 0}
            next={fetchNextPage}
            hasMore={allocations?.hasNextPage}
            loader={
              <Box textAlign="center" margin="1.125rem">
                <CircularProgress size={30} />
              </Box>
            }
          >
            <Box sx={{ mt: 5, display: 'flex', gap: 4 }}>
              <UserProjectTable />
              <DailyWorklogTable
                dates={dates}
                groupedDates={groupedDates}
                loggedHoursPerAllocation={loggedHoursPerAllocation}
              />
            </Box>
          </InfiniteScroll>
        )
      )}
      {!allocations?.docs?.length && !loading && !debounceLoading && (
        <Grid item sx={{ marginTop: 20 }} xs={12}>
          <CustomNoRowsOverlay message="No allocations found!" size />
        </Grid>
      )}
    </Container>
  );
};
//
export default TeamReportView;
