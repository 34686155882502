/**
 * Defines the API paths for vendors
 */
const API = {
  ADD_VENDOR: {
    path: '/vendors',
    method: 'POST',
  },
  PATCH_VENDOR: {
    path: '/vendors/:vendorId',
    method: 'PATCH',
  },
  GET_VENDORS: {
    path: '/vendors?:query',
    method: 'GET',
  },
  GET_VENDOR: {
    path: '/vendors/:vendorId',
    method: 'GET',
  },
  DELETE_VENDOR: {
    path: '/vendors/:vendorId',
    method: 'DELETE',
  },
  VERIFY_EMPLOYEE: {
    path: '/vendors/:vendorId/verify-employee',
    method: 'POST',
  },
  POST_LOGO: {
    path: '/vendors/upload-logo',
    method: 'POST',
  },
};
//
export default API;
