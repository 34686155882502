import * as yup from 'yup';
//
import { nameRegex } from 'features/base/constants/regex';
import { isValidNumber } from 'features/base/helpers/phone-numbers';
/**
 * Function for add client form validation
 */
const addClientUserFormValidation = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed'),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(nameRegex, 'Numbers and special characters not allowed'),
  email: yup.string().required('Email is required').email('Please enter a valid email address'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
});
//
export default addClientUserFormValidation;
