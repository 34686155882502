import { Box, IconButton, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import AmountField from 'features/users/components/view-salary-history-modal/components/amount-field';
import StartDateField from 'features/users/components/view-salary-history-modal/components/start-date-field';
import GrayCalender from 'features/base/assets/images/svg/gray-calender.svg';
import EndDateField from 'features/users/components/view-salary-history-modal/components/end-date-field';
import TickCircle from 'features/base/assets/images/svg/purple-tick-circle.svg';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import { DATE_FORMAT_SLASH } from '../../../constants/date-formatting';
/**
 * Defines the table header details for salary history table
 */
const SALARY_HISTORY_TABLE_HEADERS = [
  {
    field: 'designation',
    headerName: 'Designation',
    headerClassName: 'super-app-theme--header',
    flex: 2,
    valueGetter: (params) => params.row.designation.value,
    renderCell: (params) => {
      const { value } = params.row.designation;
      return (
        <Box
          sx={{
            wordWrap: 'break-word',
          }}
        >
          {value}
        </Box>
      );
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerClassName: 'super-app-theme--header',
    flex: 2,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row.amount.salary.amount,
    renderCell: (params) => {
      const { salary, formik, setHide } = params.row.amount;
      return salary?.dataStatus === DATA_STATUS_TYPES.ACTIVE ? (
        <Box
          sx={{
            mb: '0px',
            '& .MuiTextField-root': {
              mb: '0px !important',
            },
            maxHeight: '1px',
            marginBottom: '2.5rem',
          }}
        >
          <AmountField salary={salary} formikBag={formik} setHide={setHide} />
        </Box>
      ) : (
        <Box
          sx={{
            opacity: 0.8,
          }}
        >
          {salary?.amount ? getDollarFormattedValue(salary?.amount) : '-'}
        </Box>
      );
    },
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    headerClassName: 'super-app-theme--header',
    flex: 1.5,
    type: 'date',
    valueGetter: (params) => params.row.startDate.value,
    renderCell: (params) => {
      const { salary, formik, setHide } = params.row.startDate;
      return salary?.dataStatus === DATA_STATUS_TYPES.ACTIVE ? (
        <StartDateField salary={salary} formikBag={formik} setHide={setHide} />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ opacity: 0.8 }}>
            {dayjs(salary?.startDate).format(DATE_FORMAT_SLASH) || '-'}
          </Box>
          <img src={GrayCalender} alt="Update Icon" />
        </Box>
      );
    },
  },
  {
    field: 'endDate',
    headerName: 'End date',
    headerClassName: 'super-app-theme--header',
    flex: 1.5,
    type: 'date',
    valueGetter: (params) => params.row.endDate.value,
    renderCell: (params) => {
      const { salary, formik } = params.row.endDate;
      return salary?.dataStatus === DATA_STATUS_TYPES.ACTIVE ? (
        <EndDateField salary={salary} formikBag={formik} />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ opacity: 0.8 }}>{dayjs(salary?.endDate).format(DATE_FORMAT_SLASH) || '-'}</Box>
          <img src={GrayCalender} alt="Update Icon" />
        </Box>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    valueGetter: (params) => params.row.status.dataStatus,
    renderCell: (params) => {
      const { dataStatus, updateCurrentSalaryLoading, formik, hide } = params.row.status;
      return dataStatus === DATA_STATUS_TYPES.ACTIVE ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              color: 'rgba(75, 181, 67, 1) !important',
              backgroundColor: 'rgba(75, 181, 67, 0.3) !important',
              p: '5px 21px',
              height: '28px',
            }}
          >
            {dataStatus}
          </Box>
          {hide && (
            <IconButton
              disabled={updateCurrentSalaryLoading}
              size="medium"
              onClick={formik.handleSubmit}
              color="inherit"
            >
              {updateCurrentSalaryLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <img src={TickCircle} alt="Update Icon" />
              )}
            </IconButton>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            color: 'rgba(207, 205, 213, 1) !important',
            backgroundColor: 'rgba(230, 229, 233, 1) !important',
            padding: '4px 9px',
            height: '28px',
          }}
        >
          {dataStatus || '-'}
        </Box>
      );
    },
  },
];
//
export default SALARY_HISTORY_TABLE_HEADERS;
