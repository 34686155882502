import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  allocationReport: {
    allocations: {},
    userList: [],
    projectList: [],
    isInitial: true,
    allocationsLoading: false,
    usersLoading: false,
    projectsLoading: false,
    patchAllocationsLoading: false,
    toBeReallocatedAllocation: {},
    toBeReallocatedAllocationLoading: false,
    reallocationTableAllocations: {},
    reallocationTableAllocationsLoading: false,
  },
  teamReport: {
    allocations: {},
    userList: [],
    projectList: [],
    isInitial: true,
    allocationsLoading: false,
    usersLoading: false,
    projectsLoading: false,
  },
  allocatedActualReport: {
    allocations: {},
    userList: [],
    projectList: [],
    isInitial: true,
    allocationsLoading: false,
    usersLoading: false,
    projectsLoading: false,
  },
  allocatedActualReportExport: {
    allocations: {},
    userList: [],
    projectList: [],
    isInitial: true,
    allocationsLoading: false,
    usersLoading: false,
    projectsLoading: false,
  },
  skillReport: {
    skillStats: {},
    isInitial: true,
    skillStatsLoading: false,
  },
  capacityReport: {
    capacityReportUsers: {},
    userList: [],
    designationsList: [],
    workAreasList: [],
    projectList: [],
    isInitial: true,
    capacityReportUsersLoading: false,
    usersLoading: false,
    projectsLoading: false,
    designationsLoading: false,
    workAreasLoading: false,
  },
  salaryReport: {
    salaryReportUsers: {},
    userList: [],
    isInitial: true,
    salaryReportUsersLoading: false,
    usersLoading: false,
    departments: [],
    departmentsLoading: false,
  },
};
/**
 * Redux slice for the reports feature
 */
export const reportSlice = createSlice({
  name: 'feature/reports',
  initialState,
  reducers: {
    resetAllocationReport: (state) => ({
      ...state,
      allocationReport: initialState.allocationReport,
    }),
    resetTeamReport: (state) => ({
      ...state,
      teamReport: initialState.teamReport,
    }),
    resetAllocatedActualReport: (state) => ({
      ...state,
      allocatedActualReport: initialState.allocatedActualReport,
    }),
    resetAllocatedActualReportExport: (state) => ({
      ...state,
      allocatedActualReportExport: initialState.allocatedActualReportExport,
    }),
    resetSkillReport: (state) => ({
      ...state,
      skillReport: initialState.skillReport,
    }),
    resetCapacityReport: (state) => ({
      ...state,
      capacityReport: initialState.capacityReport,
    }),
    resetSalaryReport: (state) => ({
      ...state,
      salaryReport: initialState.salaryReport,
    }),
    setAllocationReportIsInitial: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        allocations: {},
        isInitial: true,
      },
    }),
    setTeamReportIsInitial: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        allocations: {},
        isInitial: true,
      },
    }),
    setAllocatedActualReportIsInitial: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        allocations: {},
        isInitial: true,
      },
    }),
    setAllocatedActualReportExportIsInitial: (state) => ({
      ...state,
      allocatedActualReportExport: {
        ...state.allocatedActualReportExport,
        allocations: {},
        isInitial: true,
      },
    }),
    setSkillReportIsInitial: (state) => ({
      ...state,
      skillReport: {
        ...state.skillReport,
        skillStats: {},
        isInitial: true,
      },
    }),
    setCapacityReportIsInitial: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        capacityReportUsers: {},
        isInitial: true,
      },
    }),
    setSalaryReportIsInitial: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        salaryReportUsers: {},
        isInitial: true,
      },
    }),
    getAllocationReportAllocations: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        allocationsLoading: true,
      },
    }),
    getAllocationReportAllocationsSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        allocationsLoading: false,
        isInitial: false,
        allocations:
          state.allocationReport?.allocations?.docs?.length === 0
            ? action.payload
            : {
                ...action.payload,
                docs: [
                  ...(state.allocationReport?.allocations?.docs ?? []),
                  ...(action.payload.docs ?? []),
                ],
              },
      },
    }),
    getAllocationReportAllocationsFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        allocationsLoading: false,
      },
    }),
    getAllocationToBeReallocated: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        toBeReallocatedAllocationLoading: true,
      },
    }),
    resetAllocationToBeReallocated: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        toBeReallocatedAllocation: {},
        toBeReallocatedAllocationLoading: false,
      },
    }),
    getAllocationToBeReallocatedSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        toBeReallocatedAllocationLoading: false,
        toBeReallocatedAllocation: action.payload,
      },
    }),
    getAllocationToBeReallocatedFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        toBeReallocatedAllocationLoading: false,
      },
    }),
    getReallocationTableAllocations: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        reallocationTableAllocationsLoading: true,
      },
    }),
    resetReallocationTableAllocations: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        reallocationTableAllocations: {},
        reallocationTableAllocationsLoading: false,
      },
    }),
    getReallocationTableAllocationsSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        reallocationTableAllocationsLoading: false,
        reallocationTableAllocations: action.payload,
      },
    }),
    getReallocationTableAllocationsFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        reallocationTableAllocationsLoading: false,
      },
    }),
    patchReallocationTableAllocationsInStore: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        reallocationTableAllocations: {
          ...state.allocationReport?.reallocationTableAllocations,
          docs: state.allocationReport?.reallocationTableAllocations?.docs.map((allocation) =>
            allocation.projectId.id === action.payload.projectId
              ? {
                  ...allocation,
                  monthlyAllocations: action.payload.updatedMonthlyAllocations,
                }
              : allocation
          ),
        },
      },
    }),
    getTeamReportAllocations: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        allocationsLoading: true,
      },
    }),
    getTeamReportAllocationsSucceeded: (state, action) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        allocationsLoading: false,
        isInitial: false,
        allocations:
          state.teamReport?.allocations?.docs?.length === 0
            ? action.payload
            : {
                ...action.payload,
                docs: [
                  ...(state.teamReport?.allocations?.docs ?? []),
                  ...(action.payload.docs ?? []),
                ],
              },
      },
    }),
    getTeamReportAllocationsFailed: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        allocationsLoading: false,
      },
    }),
    getAllocatedActualReportAllocations: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        allocationsLoading: true,
      },
    }),
    getAllocatedActualReportAllocationsSucceeded: (state, action) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        allocationsLoading: false,
        isInitial: false,
        allocations:
          state.allocatedActualReport?.allocations?.docs?.length === 0
            ? action.payload
            : {
                ...action.payload,
                docs: [
                  ...(state.allocatedActualReport?.allocations?.docs ?? []),
                  ...(action.payload.docs ?? []),
                ],
              },
      },
    }),
    getAllocatedActualReportAllocationsFailed: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        allocationsLoading: false,
      },
    }),
    getAllocatedActualReportExportAllocations: (state) => ({
      ...state,
      allocatedActualReportExport: {
        ...state.allocatedActualReportExport,
        allocationsLoading: true,
      },
    }),
    getAllocatedActualReportExportAllocationsSucceeded: (state, action) => ({
      ...state,
      allocatedActualReportExport: {
        ...state.allocatedActualReportExport,
        allocationsLoading: false,
        isInitial: false,
        allocations:
          state.allocatedActualReportExport?.allocations?.docs?.length === 0
            ? action.payload
            : {
                ...action.payload,
                docs: [
                  ...(state.allocatedActualReportExport?.allocations?.docs ?? []),
                  ...(action.payload.docs ?? []),
                ],
              },
      },
    }),
    getAllocatedActualReportExportAllocationsFailed: (state) => ({
      ...state,
      allocatedActualReportExport: {
        ...state.allocatedActualReportExport,
        allocationsLoading: false,
      },
    }),
    patchAllocationReportAllocation: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        patchAllocationsLoading: true,
      },
    }),
    patchAllocationReportAllocationSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        patchAllocationsLoading: false,
        allocations: {
          ...state.allocationReport?.allocations,
          docs: state.allocationReport?.allocations.docs.map((allocation) =>
            allocation.id === action?.payload?.id
              ? { ...allocation, monthlyAllocations: action?.payload?.monthlyAllocations }
              : allocation
          ),
        },
      },
    }),
    patchAllocationReportAllocationFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        patchAllocationsLoading: false,
      },
    }),
    getAllocationReportUsers: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        usersLoading: true,
      },
    }),
    getAllocationReportUsersSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        usersLoading: false,
        userList: action.payload.docs,
      },
    }),
    getAllocationReportUsersFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        usersLoading: false,
      },
    }),
    getTeamReportUsers: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        usersLoading: true,
      },
    }),
    getTeamReportUsersSucceeded: (state, action) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        usersLoading: false,
        userList: action.payload.docs,
      },
    }),
    getTeamReportUsersFailed: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        usersLoading: false,
      },
    }),
    getAllocatedActualReportUsers: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        usersLoading: true,
      },
    }),
    getAllocatedActualReportUsersSucceeded: (state, action) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        usersLoading: false,
        userList: action.payload.docs,
      },
    }),
    getAllocatedActualReportUsersFailed: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        usersLoading: false,
      },
    }),
    getCapacityReportUsersList: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        usersLoading: true,
      },
    }),
    getCapacityReportUsersListSucceeded: (state, action) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        usersLoading: false,
        userList: action.payload.docs,
      },
    }),
    getCapacityReportUsersListFailed: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        usersLoading: false,
      },
    }),
    getCapacityReportProjects: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        projectsLoading: true,
      },
    }),
    getCapacityReportProjectsSucceeded: (state, action) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        projectsLoading: false,
        projectList: action.payload.docs,
      },
    }),
    getCapacityReportProjectsFailed: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        projectsLoading: false,
      },
    }),
    getSalaryReportUsersList: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        usersLoading: true,
      },
    }),
    getSalaryReportUsersListSucceeded: (state, action) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        usersLoading: false,
        userList: action.payload.docs,
      },
    }),
    getSalaryReportUsersListFailed: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        usersLoading: false,
      },
    }),
    getAllocationReportProjects: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        projectsLoading: true,
      },
    }),
    getAllocationReportProjectsSucceeded: (state, action) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        projectsLoading: false,
        projectList: action.payload.docs,
      },
    }),
    getAllocationReportProjectsFailed: (state) => ({
      ...state,
      allocationReport: {
        ...state.allocationReport,
        projectsLoading: false,
      },
    }),
    getTeamReportProjects: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        projectsLoading: true,
      },
    }),
    getTeamReportProjectsSucceeded: (state, action) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        projectsLoading: false,
        projectList: action.payload.docs,
      },
    }),
    getTeamReportProjectsFailed: (state) => ({
      ...state,
      teamReport: {
        ...state.teamReport,
        projectsLoading: false,
      },
    }),
    getAllocatedActualReportProjects: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        projectsLoading: true,
      },
    }),
    getAllocatedActuaReportProjectsSucceeded: (state, action) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        projectsLoading: false,
        projectList: action.payload.docs,
      },
    }),
    getAllocatedActuaReportProjectsFailed: (state) => ({
      ...state,
      allocatedActualReport: {
        ...state.allocatedActualReport,
        projectsLoading: false,
      },
    }),
    getSkillStats: (state) => ({
      ...state,
      skillReport: {
        ...state.skillReport,
        skillStatsLoading: true,
      },
    }),
    getSkillStatsSucceeded: (state, action) => ({
      ...state,
      skillReport: {
        ...state.skillReport,
        skillStatsLoading: false,
        skillStats: action.payload,
      },
    }),
    getSkillStatsFailed: (state) => ({
      ...state,
      skillReport: {
        ...state.skillReport,
        skillStatsLoading: false,
      },
    }),
    getCapacityReportDesignations: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        designationsLoading: true,
      },
    }),
    getCapacityReportDesignationsSucceeded: (state, action) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        designationsLoading: false,
        designationsList: action.payload.docs,
      },
    }),
    getCapacityReportDesignationsFailed: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        designationsLoading: false,
      },
    }),
    getCapacityReportWorkAreas: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        workAreasLoading: true,
      },
    }),
    getCapacityReportWorkAreasSucceeded: (state, action) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        workAreasLoading: false,
        workAreasList: action.payload.docs,
      },
    }),
    getCapacityReportWorkAreasFailed: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        workAreasLoading: false,
      },
    }),
    getCapacityReportUsers: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        capacityReportUsersLoading: true,
      },
    }),
    getCapacityReportUsersSucceeded: (state, action) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        capacityReportUsersLoading: false,
        isInitial: false,
        capacityReportUsers:
          state.capacityReport?.capacityReportUsers?.docs?.length === 0
            ? action.payload
            : {
                ...action.payload,
                docs: [
                  ...(state.capacityReport?.capacityReportUsers?.docs ?? []),
                  ...(action.payload.docs ?? []),
                ],
              },
      },
    }),
    getCapacityReportUsersFailed: (state) => ({
      ...state,
      capacityReport: {
        ...state.capacityReport,
        capacityReportUsersLoading: false,
      },
    }),
    getSalaryReportDepartments: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        departmentsLoading: true,
      },
    }),
    getSalaryReportDepartmentsSucceeded: (state, action) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        departmentsLoading: false,
        departments: action.payload,
      },
    }),
    getSalaryReportDepartmentsFailed: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        departmentsLoading: false,
      },
    }),
    getSalaryReportUsers: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        salaryReportUsersLoading: true,
      },
    }),
    getSalaryReportUsersSucceeded: (state, action) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        salaryReportUsersLoading: false,
        isInitial: false,
        salaryReportUsers:
          state.salaryReport?.salaryReportUsers?.docs?.length === 0
            ? action?.payload
            : {
                ...action?.payload,
                docs: [
                  ...(state.salaryReport?.salaryReportUsers?.docs ?? []),
                  ...(action?.payload?.docs ?? []),
                ],
              },
      },
    }),
    getSalaryReportUsersFailed: (state) => ({
      ...state,
      salaryReport: {
        ...state.salaryReport,
        salaryReportUsersLoading: false,
      },
    }),
  },
});
//
export const { actions: reportActions } = reportSlice;
