import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
//
import bulkSelectIcon from 'features/base/assets/images/png/bulk-select.png';
import deleteIcon from 'features/base/assets/images/png/delete-icon.png';
import { selectIsSelectedTeamMembersFetching } from 'features/projects/selectors';
import { createTeamPercentagePattern } from 'features/base/constants/regex';
import SELECTED_TEAM_MEMBER_HEADERS from 'features/base/utils/tables/selected-team-member-table';
import { DataGrid } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
/**
 * Defines the selected user section in the create team screen
 * @returns {Grid}
 */
const SelectedUserTableSection = ({
  isSelectedUserMultipleSelectEnable,
  isSelectAllClicked,
  onRemoveClick,
  onSelectUserBulkIconClick,
  selectedUsers,
  onSelectedUserCheckboxClick,
  multipleSelectedUsers,
  onRemoveIconClick,
  handleSelectAll,
  setStartDate,
  setEndDate,
  setPercentage,
  projectStartDate,
  projectEndDate,
  setHasErrors,
}) => {
  //
  const isSelectedUserLoading = useSelector(selectIsSelectedTeamMembersFetching);
  //
  const getDateErrorMessage = (sDate, eDate, dateType, projectDate) => {
    const date1 = moment(sDate);
    const date2 = moment(eDate);
    const pDate = moment(projectDate);
    if (dateType === 'START_DATE') {
      if (!date1.isValid()) {
        return 'Start date should be a valid date';
      }
      if (date1.isAfter(date2, 'days')) {
        return 'Cannot exceed end date';
      }
      if (date1.isBefore(pDate, 'days')) {
        return 'Cannot be prior to project start date';
      }
    }
    if (dateType === 'END_DATE') {
      if (!date2.isValid()) {
        return 'End date should be a valid date';
      }
      if (date2.isBefore(date1, 'days')) {
        return 'Cannot be prior to start date';
      }
      if (date2.isAfter(pDate, 'days')) {
        return 'Cannot exceed project end date';
      }
    }
    return '';
  };
  //
  const getIsDateErrorExist = (sDate, eDate, dateType, projectDate) => {
    const date1 = moment(sDate);
    const date2 = moment(eDate);
    const pDate = moment(projectDate);
    if (dateType === 'START_DATE') {
      if (!date1.isValid()) {
        return true;
      }
      if (date1.isAfter(date2, 'days')) {
        return true;
      }
      if (date1.isBefore(pDate, 'days')) {
        return true;
      }
    }
    if (dateType === 'END_DATE') {
      if (!date2.isValid()) {
        return true;
      }
      if (date2.isBefore(date1, 'days')) {
        return true;
      }
      if (date2.isAfter(pDate, 'days')) {
        return true;
      }
    }
    return false;
  };
  //
  const getPercentageError = (input) => {
    if (!createTeamPercentagePattern.test(input) || input % 5 !== 0) {
      return 'Should be a multiple of 5';
    }
    return '';
  };
  //
  const getIsPercentageErrorExist = (input) =>
    !createTeamPercentagePattern.test(input) || input % 5 !== 0;
  //
  useEffect(() => {
    setHasErrors(false);
    let errorCount = 0;
    selectedUsers?.docs?.forEach((user) => {
      if (errorCount) return;
      if (!user?.isAllocatedPercentageLocked) {
        const startDateError = getIsDateErrorExist(
          user?.startDate,
          user?.endDate,
          'START_DATE',
          projectStartDate
        );
        const endDateError = getIsDateErrorExist(
          user?.startDate,
          user?.endDate,
          'END_DATE',
          projectEndDate
        );
        const percentageError = getIsPercentageErrorExist(user?.allocatedPercentage);
        if (startDateError || endDateError || percentageError) {
          errorCount += 1;
        }
      }
    });
    if (errorCount) {
      setHasErrors(true);
    }
  }, [selectedUsers?.docs]);
  //
  return isSelectedUserLoading ? (
    <Grid item xs={12} lg={12}>
      <ShimmerTable row={3} col={4} />
    </Grid>
  ) : (
    <Grid item xs={12} lg={12}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'center' },
          mb: '16px',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Typography variant="h2" sx={{ fontSize: '1.313rem', fontWeight: 600 }}>
          Selected team members
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {isSelectedUserMultipleSelectEnable && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: '32px',
                }}
              >
                <Checkbox
                  sx={{
                    p: 0,
                  }}
                  onChange={() => handleSelectAll(!isSelectAllClicked)}
                  value={isSelectAllClicked}
                />
                <Typography variant="body1" sx={{ fontWeight: 600, color: COLORS.NAVY_BLUE }}>
                  Select all
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: '32px',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => onRemoveClick()}
              >
                <img src={deleteIcon} alt="bulk-select" height="20px" />
                <Typography variant="body1" sx={{ fontWeight: 600, color: COLORS.SECONDARY_MAIN }}>
                  Remove
                </Typography>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onSelectUserBulkIconClick()}
          >
            <img src={bulkSelectIcon} alt="bulk-select" height="20px" />
            <Typography variant="body1" sx={{ fontWeight: 600, color: COLORS.PRIMARY_MAIN }}>
              Multiple
            </Typography>
          </Box>
        </Box>
      </Box>
      <DataGrid
        rowHeight={selectedUsers?.docs?.length ? 80 : 200}
        columns={SELECTED_TEAM_MEMBER_HEADERS}
        limit={10}
        rows={
          selectedUsers?.docs?.map((user) => ({
            id: user.id,
            icon: {
              user,
              onSelectedUserCheckboxClick,
              multipleSelectedUsers,
              onRemoveIconClick,
              isSelectedUserMultipleSelectEnable,
            },
            profile: {
              value: `${user.userId?.firstName} ${user.userId?.lastName}`,
              profileImage: user?.userId?.profileImage,
              designation: user.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
                ?.designationId?.name
                ? user.userId?.currentUserDepartmentDesignationId?.departmentDesignationId
                    ?.designationId?.name
                : '-',
            },
            startDate: {
              onChange: (event) => {
                setStartDate(user?.userId?.id, event?.$d);
              },
              startDate: user?.startDate,
              projectStartDate,
              projectEndDate,
              getDateErrorMessage: getDateErrorMessage(
                user?.startDate,
                user?.endDate,
                'START_DATE',
                projectStartDate
              ),
              getIsDateErrorExist: getIsDateErrorExist(
                user?.startDate,
                user?.endDate,
                'START_DATE',
                projectStartDate
              ),
            },
            endDate: {
              onChange: (event) => {
                setEndDate(user?.userId?.id, event?.$d);
              },
              endDate: user?.endDate,
              projectEndDate,
              projectStartDate,
              getDateErrorMessage: getDateErrorMessage(
                user?.startDate,
                user?.endDate,
                'END_DATE',
                projectEndDate
              ),
              getIsDateErrorExist: getIsDateErrorExist(
                user?.startDate,
                user?.endDate,
                'END_DATE',
                projectEndDate
              ),
            },
            allocation: {
              onChange: (event) => {
                setPercentage(user?.userId?.id, event?.target?.value);
              },
              allocatedPercentage: user?.allocatedPercentage,
              isAllocatedPercentageLocked: user?.isAllocatedPercentageLocked,
              helperText: user?.isAllocatedPercentageLocked
                ? ''
                : getPercentageError(user?.allocatedPercentage),
              error: user?.isAllocatedPercentageLocked
                ? false
                : getIsPercentageErrorExist(user?.allocatedPercentage),
            },
          })) ?? []
        }
        cellSx={{
          pt: 1,
          pb: 1,
        }}
        serverSidePagination={false}
      />
    </Grid>
  );
};
//
export default SelectedUserTableSection;
