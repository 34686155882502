import React, { useState } from 'react';
import { TextField as MuiTextField, Typography, Box } from '@mui/material';
import useHelperTextStyles from './style';
//
import './index.scss';
/**
 * @prop {String} type MUI Textfield type prop
 * @prop {String} name MUI Textfield name prop
 * @prop {String} value MUI Textfield value prop
 * @prop {String} error MUI Textfield error prop
 * @prop {String} helperText MUI Textfield helper text prop
 * @prop {Function} onBlur MUI Textfield onBlur function
 * @prop {Function} onChange MUI Textfield onChange function
 * @prop {String} label Textfield label
 * @prop {String} multiline MUI multiline boolean helper text prop
 * @prop {Function} rows Number of rows of the text field
 * @prop {Boolean} hide Boolean value that hides the component if true
 * @prop {Boolean} disabled Boolean value that disables the component if true
 * @prop {Object} inputRef Ref object for the input
 * @prop {Boolean} showErrorAfterBlur Boolean value that shows the error after blur if true
 * @prop {Object} sx Custom styles for the component
 * @returns
 */
const TextField = ({
  type,
  name,
  value,
  error,
  helperText,
  onBlur,
  onChange,
  onKeyDown,
  label,
  multiline,
  rows,
  hide,
  disabled,
  direction,
  placeholder,
  defaultValue,
  InputProps,
  inputRef,
  showErrorAfterBlur,
  sx,
}) => {
  //
  const helperTextStyles = useHelperTextStyles();
  //
  const [hasBlurred, setHasBlurred] = useState(false);
  // Modify the existing onBlur handler
  const handleBlur = (e) => {
    setHasBlurred(true);
    if (onBlur) {
      onBlur(e);
    }
  };

  //
  const getTextBoxStyle = () => {
    if (multiline) {
      return {
        '& .MuiOutlinedInput-root input': {
          padding: 1,
        },
      };
    }
    return {
      '& .MuiOutlinedInput-root input': {
        padding: 1,
        pl: 2,
      },
    };
  };
  if (hide) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        width: '100%',
      }}
    >
      <Typography fontSize="16px" className="field-label">
        {label}
      </Typography>
      <MuiTextField
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        error={showErrorAfterBlur ? hasBlurred && error : error}
        helperText={
          ((showErrorAfterBlur && hasBlurred) || !showErrorAfterBlur) && error ? helperText : ''
        }
        onBlur={handleBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        sx={{
          fieldset: {
            borderRadius: '0px',
          },
          width: '100%',
          mb: 2,
          ...getTextBoxStyle(),
          ...sx,
        }}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.root,
          },
        }}
        defaultValue={defaultValue}
        InputProps={InputProps}
        inputRef={inputRef}
      />
    </Box>
  );
};
//
export default TextField;
