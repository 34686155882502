import * as yup from 'yup';
/**
 * Function for work area form validation
 */
const workAreaFormValidation = yup.object().shape({
  workArea: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Please Enter a valid Work Area'),
      })
    )
    .min(1),
});
/**
 * Edit work area form validation
 */
export const editWorkAreaFormValidation = yup.object().shape({
  name: yup
    .string()
    .required('Please Enter valid work area name')
    .max(150, ({ max }) => `Work area must be at maximum of ${max} characters`),
});
//
export default workAreaFormValidation;
