import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
//
import { skillActions } from 'features/skills/slice';
import { selectSkills, selectSearch, selectGetSkillLoader, selectDeleteSkillFlag } from 'features/skills/selectors';
import { DataGrid } from 'features/base/components';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { SKILL_TABLE_BASIC_COLUMNS, SKILL_TABLE_ACTION_COLUMNS } from 'features/base/utils/tables';
import { SKILL_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import AddSkillsForm from 'features/skills/components/add-skills/add-skills-form';
import DeleteSkillPopup from 'features/skills/components/delete-skills/delete-skills-modal';
import EditSkillsPopup from 'features/skills/components/edit-skills/edit-skills-modal';
/**
 * Defines the skill table
 * @returns {DataGrid}
 */
const SkillsTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const skillUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const skillDeletePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.DELETE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const skills = useSelector(selectSkills);
  const isLoading = useSelector(selectGetSkillLoader);
  const search = useSelector(selectSearch);
  const deleteSkillFlag = useSelector(selectDeleteSkillFlag);
  //
  const isFirst = useIsInitialize(skills);
  //
  const [editSkillsModalOpen, setEditSkillsOpen] = useState(false);
  const [skillIdToEdit, setSkillIdToEdit] = useState();
  const [deleteSkillsModalOpen, setDeleteSkillsModalOpen] = useState(false);
  const [skillIdToDelete, setSkillIdToDelete] = useState();
  //
  const handleEditClick = (skillId) => {
    setSkillIdToEdit(skillId);
    setEditSkillsOpen(true);
  };
  //
  const handleDeleteClick = (skillId) => {
    setSkillIdToDelete(skillId);
    setDeleteSkillsModalOpen(true);
  };
  //
  useEffect(() => {
    dispatch(
      skillActions.getSkills({
        query: `limit=${SKILL_PAGINATION_LIMIT}&sortBy=name:asc&page=1&pagination=true&search=${search}`,
      })
    );
  }, [search, deleteSkillFlag]);
  //
  return (
    <>
      <EditSkillsPopup
        editSkillsModalOpen={editSkillsModalOpen}
        setEditSkillsOpen={setEditSkillsOpen}
        skillIdToEdit={skillIdToEdit}
      />
      <DeleteSkillPopup
        deleteSkillsModalOpen={deleteSkillsModalOpen}
        setDeleteSkillsModalOpen={setDeleteSkillsModalOpen}
        skillIdToDelete={skillIdToDelete}
      />
      <Grid
        container
        spacing={6}
        rowSpacing={3}
        display="flex"
        justifyContent="space-around"
        flexDirection="row"
      >
        <Grid item xs={12} md={6} lg mb={2}>
          <Box>
            {isFirst ? (
              <ShimmerTable row={5} col={2} />
            ) : (
              <DataGrid
                columns={
                  skillUpdatePermissionsVerified || skillDeletePermissionsVerified
                    ? [...SKILL_TABLE_BASIC_COLUMNS, ...SKILL_TABLE_ACTION_COLUMNS]
                    : SKILL_TABLE_BASIC_COLUMNS
                }
                rows={
                  skills?.docs?.length &&
                  skills?.docs?.map((skill) => ({
                    id: skill?.id,
                    name: skill?.name,
                    action: {
                      skillUpdatePermissionsVerified,
                      skillDeletePermissionsVerified,
                      handleSkillEdit: (event) => {
                        event.stopPropagation();
                        handleEditClick(skill?.id);
                      },
                      handleSkillDelete: (event) => {
                        event.stopPropagation();
                        handleDeleteClick(skill?.id);
                      },
                    },
                  }))
                }
                handleChangePage={({ page }) => {
                  dispatch(
                    skillActions.getSkills({
                      query: `limit=${SKILL_PAGINATION_LIMIT}&sortBy=name:asc&page=${
                        page + 1
                      }&pagination=true&search=${search}`,
                    })
                  );
                }}
                page={skills?.page ? skills.page - 1 : 0}
                totalDocs={skills?.totalDocs ?? 0}
                limit={skills?.limit ?? 10}
                loading={isLoading && isFirst !== true}
                pageSizeOptions={[10]}
                headSx={{ pt: 1, pb: 1, pr: 0 }}
                cellSx={{ pt: 1, pb: 1, pr: 0 }}
                rowHeight={skills?.docs?.length ? 52 : 200}
              />
            )}
          </Box>
        </Grid>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.SKILL,
              action: PERMISSION_ACTIONS.CREATE,
            },
          ]}
          hide
        >
          <Grid
            item
            xs={12}
            md={6}
            lg
            mb={2}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <AddSkillsForm />
          </Grid>
        </PermissionWrapper>
      </Grid>
    </>
  );
};
//
export default SkillsTable;
