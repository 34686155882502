/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//
import ROUTES from 'features/base/constants/routes';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { selectUserType } from 'features/base/auth/selectors';
import { USER_TYPES } from 'features/base/constants/user-types';
import COLORS from 'features/base/constants/colors';
import PAGES from 'features/base/constants/page-names';
/**
 * MenuItem list component
 */
const AppMenu = ({ handleCloseNavMenu, anchorElNav }) => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const manageToolsPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TOOL,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageOpexPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.OPEX,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageRolesPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ROLE,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageSkillsPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageDepartmentsPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ORGANIZATION,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageUsersPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const projectPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageClientPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.CLIENT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageLinkPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.LINK,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageAllocationPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageUserSalaryPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SALARY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const manageBenefitsPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.BENEFIT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const userTypeVerified = () => {
    const userType = useSelector(selectUserType);
    return (
      userType === USER_TYPES.INTERNAL ||
      userType === USER_TYPES.INTERN ||
      userType === USER_TYPES.CONTRACT
    );
  };
  //
  const navigate = useNavigate();
  const location = useLocation();
  //
  const [managesAnchorEl, setManagesAnchorEl] = useState(null);
  const [reportsAnchorEl, setReportsAnchorEl] = useState(null);
  const [hrAnchorEl, setHrAnchorEl] = useState(null);
  //
  const managesOpen = Boolean(managesAnchorEl);
  const reportsOpen = Boolean(reportsAnchorEl);
  const hrOpen = Boolean(hrAnchorEl);
  //
  const handleManagesMenuItemClick = (event) => {
    setManagesAnchorEl(event.currentTarget);
  };
  const handleReportsMenuItemClick = (event) => {
    setReportsAnchorEl(event.currentTarget);
  };
  const handleHrMenuItemClick = (event) => {
    setHrAnchorEl(event.currentTarget);
  };
  //
  const handleReportsMenuItemClose = () => {
    setReportsAnchorEl(null);
    if (anchorElNav) {
      handleCloseNavMenu();
    }
  };
  const handleHrMenuItemClose = () => {
    setHrAnchorEl(null);
    if (anchorElNav) {
      handleCloseNavMenu();
    }
  };
  //
  const handleClose = () => {
    setManagesAnchorEl(null);
    if (anchorElNav) {
      handleCloseNavMenu();
    }
  };
  //
  const pages = [
    userTypeVerified() ? { text: PAGES.LINK_HUB, route: ROUTES.LINK_HUB } : null,
    userTypeVerified() && manageToolsPermissionsVerified
      ? { text: PAGES.TOOL_HUB, route: ROUTES.TOOL_HUB }
      : null,
    projectPermissionsVerified ? { text: PAGES.PROJECTS, route: ROUTES.PROJECTS } : null,
    { text: PAGES.SUPPORT_TICKETS, route: ROUTES.SUPPORT_TICKETS },
    userTypeVerified() ? { text: PAGES.BENEFITS, route: ROUTES.BENEFITS } : null,
  ];
  //
  const manages = [
    manageToolsPermissionsVerified
      ? { text: PAGES.MANAGE_TOOLS, route: ROUTES.MANAGE_TOOLS }
      : null,
    manageOpexPermissionsVerified ? { text: PAGES.MANAGE_OPEX, route: ROUTES.MANAGE_OPEX } : null,
    manageRolesPermissionsVerified
      ? { text: PAGES.MANAGE_ROLES, route: ROUTES.MANAGE_ROLES }
      : null,
    manageSkillsPermissionsVerified
      ? { text: PAGES.MANAGE_SKILLS, route: ROUTES.MANAGE_SKILL_SKILLS }
      : null,
    manageDepartmentsPermissionsVerified
      ? { text: PAGES.MANAGE_ORGANIZATION, route: ROUTES.MANAGE_DEPARTMENTS }
      : null,
    manageClientPermissionsVerified
      ? { text: PAGES.MANAGE_CLIENTS, route: ROUTES.MANAGE_CLIENTS }
      : null,
    manageUsersPermissionsVerified ? { text: PAGES.MANAGE_USERS, route: ROUTES.USERS } : null,
    manageLinkPermissionsVerified ? { text: PAGES.MANAGE_LINKS, route: ROUTES.MANAGE_LINKS } : null,
    manageBenefitsPermissionsVerified
      ? { text: PAGES.MANAGE_BENEFITS, route: ROUTES.MANAGE_BENEFITS }
      : null,
    manageBenefitsPermissionsVerified
      ? { text: PAGES.MANAGE_VENDORS, route: ROUTES.MANAGE_VENDORS }
      : null,
  ];
  //
  const reports = [
    manageAllocationPermissionsVerified
      ? { text: PAGES.TEAM_REPORT, route: ROUTES.TEAM_REPORT }
      : null,
    manageAllocationPermissionsVerified
      ? { text: PAGES.ALLOCATION_REPORT, route: ROUTES.ALLOCATION_REPORT }
      : null,
    manageAllocationPermissionsVerified
      ? { text: PAGES.ALLOCATED_VS_ACTUAL_HOURS, route: ROUTES.ALLOCATED_VS_ACTUAL_HOURS }
      : null,
    manageUsersPermissionsVerified && manageSkillsPermissionsVerified
      ? { text: PAGES.SKILL_REPORT, route: ROUTES.SKILL_REPORT }
      : null,
    manageAllocationPermissionsVerified && manageUsersPermissionsVerified
      ? { text: PAGES.CAPACITY_REPORT, route: ROUTES.CAPACITY_REPORT }
      : null,
    manageUsersPermissionsVerified &&
    manageDepartmentsPermissionsVerified &&
    manageUserSalaryPermissionsVerified
      ? { text: PAGES.SALARY_REPORT, route: ROUTES.SALARY_REPORT }
      : null,
  ];
  const hr = [
    manageBenefitsPermissionsVerified
      ? { text: PAGES.MANAGE_BENEFITS, route: ROUTES.MANAGE_BENEFITS }
      : null,
    manageSkillsPermissionsVerified
      ? { text: PAGES.MANAGE_SKILLS, route: ROUTES.MANAGE_SKILLS }
      : null,
    manageRolesPermissionsVerified
      ? { text: PAGES.MANAGE_ROLES, route: ROUTES.MANAGE_ROLES }
      : null,
    manageUserSalaryPermissionsVerified
      ? { text: PAGES.SALARY_REPORT, route: ROUTES.SALARY_REPORT }
      : null,
    { text: 'Rooster HR', route: 'https://app.rooster.org/dashboard' },
  ];
  //
  const pagesFiltered = pages.filter((pageOption) => pageOption);
  const managesFiltered = manages.filter((manageOption) => manageOption);
  const reportsFiltered = reports.filter((reportOption) => reportOption);
  const hrFiltered = hr.filter((hrOption) => hrOption);
  //
  const getColor = (route) => {
    if (route === location.pathname) {
      return COLORS.ELECTRIC_BLUE;
    }
    return COLORS.WHITE;
  };
  //
  const getMenuDropDownColor = () => {
    if (managesFiltered.find((manageOption) => manageOption.route === location.pathname)) {
      return COLORS.ELECTRIC_BLUE;
    }
    return COLORS.WHITE;
  };
  //
  const getManageSubMenuColor = (route) => {
    if (route === location.pathname) {
      return COLORS.ELECTRIC_BLUE;
    }
    return COLORS.WHITE;
  };
  //
  const getReportSubMenuColor = (route) => {
    if (route === location.pathname) {
      return COLORS.ELECTRIC_BLUE;
    }
    return COLORS.WHITE;
  };
  //
  const getReportDropDownColor = () => {
    if (reportsFiltered.find((reportOption) => reportOption.route === location.pathname)) {
      return COLORS.ELECTRIC_BLUE;
    }
    return COLORS.WHITE;
  };
  //
  return (
    <>
      {pagesFiltered.map((page, text) => (
        <MenuItem
          key={text}
          onClick={() => {
            navigate(page.route);
            handleClose();
          }}
          sx={{
            bgcolor: COLORS.NAVY_BLUE,
            color: getColor(page.route),
            transition: {
              xs: 'padding-left 0.5s ease',
              md: 'none',
            },
            padding: {
              xs: 3,
              md: 2,
            },
            fontSize: {
              xs: '1.5rem',
              md: '1rem',
            },
            '&.MuiMenuItem-root:hover': {
              color: COLORS.ELECTRIC_BLUE,
              bgcolor: COLORS.NAVY_BLUE,
              pl: {
                xs: 6,
                md: 2,
              },
            },
          }}
        >
          {page.text}
        </MenuItem>
      ))}
      {managesFiltered.length > 0 && (
        <MenuItem
          onClick={handleManagesMenuItemClick}
          sx={{
            bgcolor: COLORS.NAVY_BLUE,
            color: getMenuDropDownColor(),
            transition: {
              xs: 'padding-left 0.5s ease',
              md: 'none',
            },
            padding: {
              xs: 3,
              md: 2,
            },
            fontSize: {
              xs: '1.5rem',
              md: '1rem',
            },
            '&.MuiMenuItem-root:hover': {
              color: COLORS.ELECTRIC_BLUE,
              bgcolor: COLORS.NAVY_BLUE,
              pl: {
                xs: 6,
                md: 2,
              },
            },
          }}
        >
          {' '}
          Manage <ArrowDropDownIcon />{' '}
        </MenuItem>
      )}
      <Menu
        id="basic-menu"
        anchorEl={managesAnchorEl}
        open={managesOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            p: 0,
          },
        }}
      >
        {managesFiltered.map((manage, text) => (
          <MenuItem
            sx={{
              bgcolor: COLORS.NAVY_BLUE,
              color: getManageSubMenuColor(manage.route),
              transition: 'padding-left 0.5s ease',
              padding: {
                xs: 3,
                md: 2,
              },
              display: {
                xs: 'flex',
              },
              fontSize: {
                xs: '1.2rem',
                md: '1rem',
              },
              '&.MuiMenuItem-root:hover': {
                color: COLORS.ELECTRIC_BLUE,
                bgcolor: COLORS.NAVY_BLUE,
                pl: {
                  xs: 6,
                  md: 3,
                },
              },
            }}
            key={text}
            onClick={() => {
              navigate(manage.route);
              handleClose();
            }}
          >
            {manage.text}
          </MenuItem>
        ))}
      </Menu>

      {hrFiltered.length > 0 && (
        <MenuItem
          onClick={handleHrMenuItemClick}
          sx={{
            bgcolor: COLORS.NAVY_BLUE,
            color: COLORS.WHITE,
            transition: {
              xs: 'padding-left 0.5s ease',
              md: 'none',
            },
            padding: {
              xs: 3,
              md: 2,
            },
            fontSize: {
              xs: '1.5rem',
              md: '1rem',
            },
            '&.MuiMenuItem-root:hover': {
              color: COLORS.ELECTRIC_BLUE,
              bgcolor: COLORS.NAVY_BLUE,
              pl: {
                xs: 6,
                md: 2,
              },
            },
          }}
        >
          HR <ArrowDropDownIcon />{' '}
        </MenuItem>
      )}
      <Menu
        id="basic-menu"
        anchorEl={hrAnchorEl}
        open={hrOpen}
        onClose={handleHrMenuItemClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            p: 0,
          },
        }}
      >
        {hrFiltered.map((hrOption, text) => (
          <MenuItem
            sx={{
              bgcolor: COLORS.NAVY_BLUE,
              color: getManageSubMenuColor(hrOption.route),
              transition: 'padding-left 0.5s ease',
              padding: {
                xs: 3,
                md: 2,
              },
              display: {
                xs: 'flex',
              },
              fontSize: {
                xs: '1.2rem',
                md: '1rem',
              },
              '&.MuiMenuItem-root:hover': {
                color: COLORS.ELECTRIC_BLUE,
                bgcolor: COLORS.NAVY_BLUE,
                pl: {
                  xs: 6,
                  md: 3,
                },
              },
            }}
            key={text}
            onClick={() => {
              // If the URL has a valid protocol (like "http:" or "https:")
              if (URL.canParse(hrOption.route)) {
                window.open(hrOption.route, '_blank');
              } else {
                navigate(hrOption.route);
              }
              handleHrMenuItemClose();
            }}
          >
            {hrOption.text}
          </MenuItem>
        ))}
      </Menu>
      {reportsFiltered.length > 0 && (
        <MenuItem
          onClick={handleReportsMenuItemClick}
          sx={{
            bgcolor: COLORS.NAVY_BLUE,
            color: getReportDropDownColor(),
            transition: {
              xs: 'padding-left 0.5s ease',
              md: 'none',
            },
            padding: {
              xs: 3,
              md: 2,
            },
            fontSize: {
              xs: '1.5rem',
              md: '1rem',
            },
            '&.MuiMenuItem-root:hover': {
              color: COLORS.ELECTRIC_BLUE,
              bgcolor: COLORS.NAVY_BLUE,
              pl: {
                xs: 6,
                md: 2,
              },
            },
          }}
        >
          Reports <ArrowDropDownIcon />{' '}
        </MenuItem>
      )}
      <Menu
        id="basic-menu"
        anchorEl={reportsAnchorEl}
        open={reportsOpen}
        onClose={handleReportsMenuItemClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            p: 0,
          },
        }}
      >
        {reportsFiltered.map((report) => (
          <MenuItem
            key={report.route}
            sx={{
              bgcolor: COLORS.NAVY_BLUE,
              color: getReportSubMenuColor(report.route),
              transition: 'padding-left 0.5s ease',
              padding: {
                xs: 3,
                md: 2,
              },
              display: {
                xs: 'flex',
              },
              fontSize: {
                xs: '1.2rem',
                md: '1rem',
              },
              '&.MuiMenuItem-root:hover': {
                color: COLORS.ELECTRIC_BLUE,
                bgcolor: COLORS.NAVY_BLUE,
                pl: {
                  xs: 6,
                  md: 3,
                },
              },
            }}
            onClick={() => {
              navigate(report.route);
              handleReportsMenuItemClose();
            }}
          >
            {report.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
//
export default AppMenu;
