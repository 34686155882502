import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
//
import { FIREBASE } from 'config';
import { selectUserId } from 'features/base/auth/selectors';
import { getAuth } from 'firebase/auth';

/**
 * Component for setting up a listener for real time notifications
 */
const Notifications = () => {
  const userId = useSelector(selectUserId);
  //
  useEffect(() => {
    getAuth();
    const q = query(collection(FIREBASE, 'notifications'), where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notifications = [];
      querySnapshot.forEach((doc) => {
        notifications.push(doc.data().message);
      });
    });
    return () => unsubscribe();
  }, []);
};
//
export default Notifications;
