import { makeStyles } from '@mui/styles';
/**
 * Custom styles for the helper text component.
 */
const useHelperTextStyles = makeStyles(() => ({
  root: {
    marginLeft: '0px',
  },
}));
//
export default useHelperTextStyles;
