/**
 * Logical operations
 * @property {String} OR - Logical OR operation
 * @property {String} AND - Logical AND operation
 */
const LOGICAL_OPERATIONS = {
  OR: 'OR',
  AND: 'AND',
};
//
export default LOGICAL_OPERATIONS;
