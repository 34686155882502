import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';
//
export const initialState = {
  userList: {},
  projectsOfUser: [],
  designations: [],
  departments: [],
  workAreas: [],
  roles: [],
  salary: [],
  salaryUserList: [],
  selectedUsersPermissions: {},
  loading: false,
  addSalaryLoading: false,
  permissionsLoading: false,
  updateRoleLoading: false,
  addUserLoading: false,
  salaryHistory: [],
  getSalaryHistoryLoading: false,
  updateCurrentSalaryLoading: false,
  salaryUserListLoading: false,
  getRolesLoading: false,
  uploadFileLoading: false,
  bulkUserDataUsers: [],
  bulkUserDataUsersLoading: false,
  bulkUserDataFileLoading: false,
};
/**
 * Redux slice for the user feature
 */
export const userSlice = createSlice({
  name: 'feature/users',
  initialState,
  reducers: {
    getUsers: (state) => ({
      ...state,
      loading: true,
    }),
    getUsersSucceeded: (state, action) => ({
      ...state,
      loading: false,
      userList: action?.payload,
    }),
    getUsersFailed: (state) => ({
      ...state,
      loading: false,
      userList: {
        ...state.userList,
        docs: [],
      },
    }),
    addUser: (state) => ({
      ...state,
      addUserLoading: true,
    }),
    addUserSucceeded: (state, action) => ({
      ...state,
      addUserLoading: false,
      userList: {
        ...state.userList,
        docs: [action?.payload, ...state.userList.docs],
        totalDocs: state.userList.totalDocs + 1,
      },
    }),
    addUserFailed: (state) => ({
      ...state,
      addUserLoading: false,
    }),
    patchUser: (state) => ({
      ...state,
    }),
    patchUserSucceeded: (state, action) => ({
      ...state,
      userList: {
        ...state.userList,
        docs: state.userList?.docs?.map((user) =>
          user.id === action?.payload?.id ? action?.payload : user
        ),
      },
    }),
    patchUserFailed: (state) => ({
      ...state,
    }),
    getSalaryUsers: (state) => ({
      ...state,
      salaryUserListLoading: true,
    }),
    getSalaryUsersSucceeded: (state, action) => ({
      ...state,
      salaryUserList: action?.payload?.docs,
      salaryUserListLoading: false,
    }),
    getSalaryUsersFailed: (state) => ({
      ...state,
      salaryUserListLoading: false,
    }),
    getBulkUserDataUsers: (state) => ({
      ...state,
      bulkUserDataUsersLoading: true,
    }),
    getBulkUserDataUsersSucceeded: (state, action) => ({
      ...state,
      bulkUserDataUsers: action?.payload?.docs,
      bulkUserDataUsersLoading: false,
    }),
    getBulkUserDataUsersFailed: (state) => ({
      ...state,
      bulkUserDataUsersLoading: false,
    }),
    addSalary: (state) => ({
      ...state,
      addSalaryLoading: true,
    }),
    addSalarySucceeded: (state, action) => ({
      ...state,
      addSalaryLoading: false,
      salary: [...state.salary, action?.payload],
      userList: {
        ...state.userList,
        docs: state.userList?.docs?.map((user) => {
          const salary = action?.payload.find((newSalary) => newSalary?.userId === user?.id);
          return salary ? { ...user, currentSalary: salary } : user;
        }),
      },
    }),
    addSalaryFailed: (state) => ({
      ...state,
      addSalaryLoading: false,
    }),
    getDesignations: (state) => ({
      ...state,
      loading: false,
    }),
    getDesignationsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      designations: action?.payload,
    }),
    getDesignationsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getDepartments: (state) => ({
      ...state,
      loading: false,
    }),
    getDepartmentsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      departments: action?.payload,
    }),
    getDepartmentsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getWorkAreas: (state) => ({
      ...state,
      loading: false,
    }),
    getWorkAreasSucceeded: (state, action) => ({
      ...state,
      loading: false,
      workAreas: action?.payload?.docs,
    }),
    getWorkAreasFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getRoles: (state) => ({
      ...state,
      getRolesLoading: true,
      roles: [],
    }),
    getRolesSucceeded: (state, action) => ({
      ...state,
      getRolesLoading: false,
      roles: action?.payload,
    }),
    getRolesFailed: (state) => ({
      ...state,
      getRolesLoading: false,
    }),
    updateUserRole: (state) => ({
      ...state,
      updateRoleLoading: true,
    }),
    updateUserRoleSucceeded: (state, action) => ({
      ...state,
      updateRoleLoading: false,
      userList: {
        ...state.userList,
        docs: state.userList?.docs?.map((user) =>
          user.id === action?.payload?.id ? action?.payload : user
        ),
      },
    }),
    updateUserRoleFailed: (state) => ({
      ...state,
      updateRoleLoading: false,
    }),
    getSelectedUserPermissions: (state) => ({
      ...state,
      selectedUsersPermissions: {},
      permissionsLoading: true,
    }),
    getSelectedUserPermissionsSucceeded: (state, action) => ({
      ...state,
      permissionsLoading: false,
      selectedUsersPermissions: action?.payload,
    }),
    getSelectedUserPermissionsFailed: (state) => ({
      ...state,
      permissionsLoading: false,
    }),
    getSalaryHistory: (state) => ({
      ...state,
      salaryHistory: [],
      getSalaryHistoryLoading: true,
    }),
    getSalaryHistorySucceed: (state, action) => ({
      ...state,
      getSalaryHistoryLoading: false,
      salaryHistory: action?.payload?.docs,
    }),
    getSalaryHistoryFailed: (state) => ({
      ...state,
      getSalaryHistoryLoading: false,
    }),
    updateCurrentSalary: (state) => ({
      ...state,
      updateCurrentSalaryLoading: true,
    }),
    updateCurrentSalarySucceed: (state, action) => ({
      ...state,
      updateCurrentSalaryLoading: false,
      userList: {
        ...state.userList,
        docs: state.userList?.docs?.map((user) =>
          user?.id === action?.payload?.userId ? { ...user, currentSalary: action?.payload } : user
        ),
      },
    }),
    updateCurrentSalaryFailed: (state) => ({
      ...state,
      updateCurrentSalaryLoading: false,
    }),
    uploadBulkSalaryFile: (state) => ({
      ...state,
      uploadFileLoading: true,
    }),
    uploadBulkSalaryFileSucceeded: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    uploadBulkSalaryFileFailed: (state) => ({
      ...state,
      uploadFileLoading: false,
    }),
    uploadBulkUserDataFile: (state) => ({
      ...state,
      bulkUserDataFileLoading: true,
    }),
    uploadBulkUserDataFileSucceeded: (state) => ({
      ...state,
      bulkUserDataFileLoading: false,
    }),
    uploadBulkUserDataFileFailed: (state) => ({
      ...state,
      bulkUserDataFileLoading: false,
    }),
    //
    onCreateTeamUnmount: (state) => ({
      ...state,
      designations: [],
      departments: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: userActions } = userSlice;
