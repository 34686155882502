import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from 'features/base/components/navbar';
import useUpdateToken from 'features/base/hooks/use-update-token';
import SessionTimeOutPopup from 'features/base/components/timeout-popup';
import Notifications from 'features/base/components/navbar/realtime-notifications';
import Root from './style';
/**
 * Dashboard layout component.
 * Renders the common layout for the dashboard pages.
 */
const DashboardLayout = () => {
  useUpdateToken();
  //
  return (
    <Root>
      <ResponsiveAppBar />
      <Outlet />
      <SessionTimeOutPopup />
      <Notifications />
    </Root>
  );
};
//
export default DashboardLayout;
