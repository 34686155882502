import { useEffect, useState, useMemo } from 'react';
import { Typography, Box, Grid, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import moment from 'moment';
//
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { selectAllLinks, selectLoader } from 'features/links/selectors';
import { LINK_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { LINKS_TABLE_COLUMNS_BASIC, LINKS_TABLE_ACTION_COLUMNS } from 'features/base/utils/tables';
import { linkActions } from 'features/links/slice';
import { Button, DataGrid } from 'features/base/components';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { notificationActions } from 'features/base/notifications/slice';
import { DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import createFormattedString from 'features/base/helpers/param-formatter';
import AddLinksPopup from '../add-link-modal';
import EditLinksPopup from '../edit-link-popup';

/**
 * Component that defines the link view
 * @returns MUI Grid with the link view
 */
const LinkView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const linkUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.LINK,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const dispatch = useDispatch();
  //
  const isLoading = useSelector(selectLoader);
  const allLinks = useSelector(selectAllLinks);
  const notification = useSelector(selectNotification);
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'name:asc',
    rowsPerPage: LINK_PAGINATION_LIMIT,
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [linkId, setLinkId] = useState('');
  //
  const params = useMemo(
    () => ({
      limit: pageController.rowsPerPage,
      page: pageController.page,
      sortBy: pageController.sortBy,
    }),
    [pageController]
  );
  //
  const isFirst = useIsInitialize(allLinks);
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      linkActions.getAllLinks({
        query: formattedParamString,
      })
    );
  }, [params]);
  //
  const handleAddModalOpen = () => {
    setAddModalOpen(true);
    dispatch(linkActions.getAllDepartment());
  };
  //
  const handleEditModalOpen = (id) => {
    setEditModalOpen(true);
    setLinkId(id);
    dispatch(linkActions.getAllDepartment());
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <AddLinksPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <EditLinksPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        linkId={linkId}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Link Hub - Links
          </Typography>
          <PermissionWrapper
            requiredPermissions={[
              {
                domain: PERMISSION_DOMAINS.LINK,
                action: PERMISSION_ACTIONS.CREATE,
              },
            ]}
            hide
          >
            <Button onClick={handleAddModalOpen}>
              Add Link <AddIcon />
            </Button>
          </PermissionWrapper>
        </Grid>
        {isFirst ? (
          <ShimmerTable row={6} cols={9} />
        ) : (
          <DataGrid
            columns={
              linkUpdatePermissionsVerified
                ? [...LINKS_TABLE_COLUMNS_BASIC, ...LINKS_TABLE_ACTION_COLUMNS]
                : LINKS_TABLE_COLUMNS_BASIC
            }
            rows={
              allLinks?.docs?.map((link) => ({
                ...link,
                id: link?.id,
                name: {
                  value: link?.name,
                },
                linkName: {
                  linkName: link?.name,
                  linkLogo: link?.logo,
                },
                departments: {
                  linkDepartment: link?.departments,
                },
                description: {
                  description: link?.description,
                },
                link: {
                  link: link?.link,
                },
                startDate: {
                  linkStartDate: moment(link?.createdAt).format(DATE_FORMAT_SLASH),
                },
                endDate: {
                  linkEndDate: moment(link?.updatedAt).format(DATE_FORMAT_SLASH),
                },
                status: {
                  checked: link?.dataStatus === DATA_STATUS_TYPES.ACTIVE,
                  onChange: () => {
                    dispatch(
                      linkActions.patchLink({
                        id: link?.id,
                        dataStatus:
                          link?.dataStatus === DATA_STATUS_TYPES.ACTIVE
                            ? DATA_STATUS_TYPES.INACTIVE
                            : DATA_STATUS_TYPES.ACTIVE,
                      })
                    );
                  },
                  isLoading,
                },
                action: {
                  handleEdit: (event) => {
                    event.stopPropagation();
                    handleEditModalOpen(link.id);
                  },
                  isLoading,
                },
              })) ?? []
            }
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalPages={allLinks?.totalPages ?? 0}
            totalDocs={allLinks?.totalDocs ?? 0}
            limit={allLinks?.limit ?? pageController?.rowsPerPage}
            loading={isLoading}
            pageSizeOptions={[pageController?.rowsPerPage]}
            rowHeight={allLinks?.docs?.length ? 52 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default LinkView;
