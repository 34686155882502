import * as yup from 'yup';
//
import { nameRegex } from 'features/base/constants/regex';
import { USER_LEVELS, USER_TYPES } from 'features/base/constants/user-types';
import { isValidNumber } from 'features/base/helpers/phone-numbers';
import USER_CAPACITY from 'features/base/constants/capacity';
/**
 * Edit project form validation
 */
const editProfileFormValidation = yup.object().shape({
  firstName: yup
    .string()
    .required('Please enter a first name')
    .matches(nameRegex, 'Numbers and special characters not allowed'),
  lastName: yup
    .string()
    .required('Please enter a last name')
    .matches(nameRegex, 'Numbers and special characters not allowed'),
  type: yup.string().required('Please select a type'),
  phoneNumber: yup
    .string()
    .required('Please enter a phone number')
    .test(
      'is-valid',
      'Please enter a valid phone number',
      (value) => value && isValidNumber(value)
    ),
  department: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().nullable(),
    otherwise: yup.string().required('Please select a department').nullable(),
  }),
  designation: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().nullable(),
    otherwise: yup.string().required('Please select a designation').nullable(),
  }),
  workArea: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().nullable(),
    otherwise: yup.string().required('Please select a workArea').nullable(),
  }),
  company: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().required('Please enter a Company'),
  }),
  businessRegistrationNumber: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().required('Please enter a Business registration'),
  }),
  address: yup.string().when('type', {
    is: USER_TYPES.CLIENT,
    then: yup.string().required('Please enter an Address'),
  }),
  capacity: yup
    .number()
    .typeError('Capacity must be a number')
    .required('Capacity is required')
    .min(
      USER_CAPACITY.MIN_CAPACITY,
      `Capacity must be greater than or equal to ${USER_CAPACITY.MIN_CAPACITY} `
    )
    .max(
      USER_CAPACITY.MAX_CAPACITY,
      `Capacity must be less than or equal to ${USER_CAPACITY.MAX_CAPACITY} `
    ),
  joinedDate: yup.date().when('type', {
    is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT, USER_TYPES.INTERN].includes(val),
    then: yup.date().required('Please enter date of appointment'),
  }),
  level: yup
    .string()
    .when('type', {
      is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT].includes(val),
      then: yup.string().required('Please assign a user level'),
    })
    .when('type', {
      is: USER_TYPES.INTERN,
      then: yup
        .string()
        .required('Please assign a user level')
        .test(
          'is-valid',
          `Interns can only be assigned ${USER_LEVELS.LEVEL_0}`,
          (value) => value === USER_LEVELS.LEVEL_0
        ),
    }),
  nic: yup.string().when('type', {
    is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT, USER_TYPES.INTERN].includes(val),
    then: yup.string().required('Please enter a NIC'),
  }),
  employeeId: yup.string().when('type', {
    is: (val) => [USER_TYPES.INTERNAL, USER_TYPES.CONTRACT, USER_TYPES.INTERN].includes(val),
    then: yup.string().required('Please enter an employee ID'),
  }),
});
//
export default editProfileFormValidation;
