import { Avatar, Box, Grid, Typography } from '@mui/material';
/**
 * Defines the table header details for my projects table in my profile section
 */
const MY_PROJECTS_TABLE_HEADERS = [
  {
    field: 'project',
    headerName: 'Project',
    flex: 0.6,
    sortComparator: (firstValue, secondValue) => firstValue.name.localeCompare(secondValue.name),
    renderCell: ({ value: { logo, name } }) => (
      <Grid container alignItems="center">
        <Grid item sm={3}>
          {logo ? (
            <img
              src={logo}
              alt="logo"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Avatar
              sx={{
                height: '40px',
                width: '40px',
              }}
            >
              {name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 0.5,
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    flex: 0.5,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'endDate',
    headerName: 'End date',
    flex: 0.5,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last updated',
    flex: 0.5,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: ({ value: { value } }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    sortComparator: (firstValue, secondValue) => firstValue.value.localeCompare(secondValue.value),
    renderCell: (params) => {
      const { value, bgColour, textColour } = params.row.status;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: bgColour,
              p: '7px 16px',
              mr: '16px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: textColour,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];

export default MY_PROJECTS_TABLE_HEADERS;
