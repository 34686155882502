import {
  Box,
  Divider,
  IconButton,
  Stack,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
//
import COLORS from 'features/base/constants/colors';
import { MONTHS } from 'features/base/constants/date-formatting';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import './index.scss';

/**
 * @component Defining the table header for the work allocation report table
 * @param {Array} viewPeriod - Array of objects containing the month and year of the period
 * @returns {JSX.Element}
 * @example
 * <TableHeader
 * viewPeriod={[{ month: 'Jan', year: '2021' }]}
 * />
 */
const TableHeader = ({ viewPeriod } = { viewPeriod: [] }) => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const salaryViewPermission = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SALARY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const opexViewPermission = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.OPEX,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: COLORS.SKY_BLUE,
        }}
      >
        <TableCell
          sx={{
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor: `${COLORS.WHITE}`,
            borderTop: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
            borderBottom: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
          }}
        >
          <Box sx={{ width: '9rem', backgroundColor: 'transparent' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Project</Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            position: 'sticky',
            left: '11.8rem',
            zIndex: 1,
            backgroundColor: `${COLORS.WHITE}`,
            borderTop: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
            borderBottom: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
          }}
        >
          <Box sx={{ width: '12rem', backgroundColor: 'transparent' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Profile</Typography>
          </Box>
        </TableCell>
        {viewPeriod.map((period) => (
          <TableCell
            key={`${MONTHS[period.month - 1]} - ${period.year}`}
            align="right"
            sx={{
              padding: '0 0 0 0',
              zIndex: 1,
              borderTop: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
              borderBottom: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
              borderRight: `3px solid ${COLORS.WHITE}`, // Add a border to the right
              '&:last-child': {
                borderRight: 'none', // Remove the border from the last cell
              },
            }}
          >
            <Stack spacing={2}>
              <Typography variant="body1" className="time-period">
                {`${MONTHS[period.month - 1]} - ${period.year}`}
              </Typography>
              <Stack
                sx={{
                  borderTop: `0.1125rem solid ${COLORS.DODGER_BLUE}`,
                }}
                direction="row"
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Hours
                </Typography>
                {opexViewPermission && salaryViewPermission && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      COGS + OH
                    </Typography>
                  </>
                )}
                <Divider orientation="vertical" flexItem />
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Revenue
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  <Tooltip title="Info">
                    <span>
                      <IconButton size="small" disabled sx={{ opacity: 0.5 }}>
                        <InfoIcon sx={{ color: COLORS.LILAC_GRAY }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
//
export default TableHeader;
