import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  departmentList: [],
  workAreaList: {},
  search: '',
  departmentLoading: false,
  workAreaLoading: false,
  addDepartmentLoading: false,
  editDepartmentLoading: false,
  addWorkAreaLoading: false,
  editWorkAreaLoading: false,
};
/**
 * Redux slice for the department feature
 */
export const departmentSlice = createSlice({
  name: 'feature/department',
  initialState,
  reducers: {
    getAllDepartment: (state) => ({
      ...state,
      departmentLoading: true,
    }),
    getAllWorkArea: (state) => ({
      ...state,
      workAreaLoading: true,
    }),
    getAllDepartmentSucceeded: (state, action) => ({
      ...state,
      departmentLoading: false,
      departmentList: action?.payload,
    }),
    getAllWorkAreaSucceeded: (state, action) => ({
      ...state,
      workAreaLoading: false,
      workAreaList: action?.payload,
    }),
    getAllDepartmentFailed: (state) => ({
      ...state,
      departmentLoading: false,
    }),
    getAllWorkAreaFailed: (state) => ({
      ...state,
      workAreaLoading: false,
      workAreaList: {
        ...state.workAreaList,
        docs: []
      }
    }),
    addDepartment: (state) => ({
      ...state,
      addDepartmentLoading: true,
    }),
    addWorkArea: (state) => ({
      ...state,
      addWorkAreaLoading: true,
    }),
    addDepartmentSucceeded(state, action) {
      const newDepartment = {
        department: {
          id: action?.payload?.department?.id,
          name: action?.payload?.department?.name,
        },
        designations: action?.payload?.designations,
        tools: action?.payload?.department?.tools,
      };
      return {
        ...state,
        addDepartmentLoading: false,
        departmentList: [newDepartment, ...state.departmentList],
      };
    },
    addWorkAreaSucceeded: (state, action) => ({
      ...state,
      addWorkAreaLoading: false,
      workAreaList: {
        ...state.workAreaList,
        docs: [...state.workAreaList.docs, ...(action?.payload?.results ?? [])],
        totalDocs: state.workAreaList.totalDocs + (action?.payload?.results?.length ?? 0),
      },
    }),
    addDepartmentFailed: (state) => ({
      ...state,
      addDepartmentLoading: false,
    }),
    addWorkAreaFailed: (state) => ({
      ...state,
      addWorkAreaLoading: false,
    }),
    editDepartment: (state) => ({
      ...state,
      editDepartmentLoading: true,
    }),
    editWorkArea: (state) => ({
      ...state,
      editWorkAreaLoading: true,
    }),
    editDepartmentSucceeded: (state, action) => {
      const updatedDepartment = {
        department: {
          id: action?.payload?.department?.id,
          name: action?.payload?.department?.name,
        },
        designations: action?.payload?.designations,
        tools: action?.payload?.department?.tools,
      };
      return {
        ...state,
        editDepartmentLoading: false,
        departmentList: state.departmentList?.map((department) => {
          if (department.department.id === action?.payload?.department.id) {
            return updatedDepartment;
          }
          return department;
        }),
      };
    },
    editWorkAreaSucceeded: (state, action) => ({
      ...state,
      editWorkAreaLoading: false,
      workAreaList: {
        ...state.workAreaList,
        docs: state.workAreaList?.docs?.map((workArea) => {
          if (workArea.id === action?.payload?.id) {
            return action?.payload;
          }
          return workArea;
        }),
      },
    }),
    editDepartmentFailed: (state) => ({
      ...state,
      editDepartmentLoading: false,
    }),
    editWorkAreaFailed: (state) => ({
      ...state,
      editWorkAreaLoading: false,
    }),
    setSearch: (state, action) => ({
      ...state,
      search: action?.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: departmentActions } = departmentSlice;
