import {
  TableBody as MUITableBody,
  TableCell,
  TableRow,
  Stack,
  Typography,
  Tooltip,
  Input,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
//
import COLORS from 'features/base/constants/colors';
import calculations from 'features/base/helpers/calculations';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import InfoToolTip, { computeTooltipColor } from '../info-tool-tip';

/**
 * @component Defining the table body for the work allocation report table
 * @param {Function} handleEdit - Function to handle the edit of the allocation
 * @param {Function} handleDelete - Function to handle the delete of the allocation
 * @param {Function} handleReallocate - Function to handle the reallocation of the allocation
 * @param {List<Object>} allocationsByViewPeriod - Array of objects containing all the required data to render the report with minimal operations
 * @param {List<Object>} viewPeriod - Contains the time dureation in which the report is rendered
 * @returns {JSX.Element} JSX.Element
 */
const TableBody = ({
  handleEdit,
  handleReallocate,
  handleDelete,
  allocationsByViewPeriod,
  viewPeriod,
}) => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const salaryViewPermission = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SALARY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const opexViewPermission = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.OPEX,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  return (
    <MUITableBody id="test">
      {allocationsByViewPeriod?.map((allocationForViewPeriod) => (
        <TableRow
          key={allocationForViewPeriod?.id}
          sx={{
            display: 'table-row',
            '&:hover': {
              backgroundColor: COLORS.TRANSPARENT_DARK_GREEN,
            },
          }}
        >
          <TableCell
            component="th"
            scope="row"
            sx={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: `${COLORS.WHITE}` }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {allocationForViewPeriod?.projectId?.name}
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={{
              position: 'sticky',
              zIndex: 1,
              backgroundColor: `${COLORS.WHITE}`,
              left: '11.8rem',
            }}
          >
            <Stack spacing={0}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {`${allocationForViewPeriod?.userId?.firstName} ${allocationForViewPeriod?.userId?.lastName}`}
              </Typography>
              <Typography variant="caption">
                {allocationForViewPeriod?.userId?.currentUserDepartmentDesignationId
                  ?.departmentDesignationId?.designationId?.name
                  ? allocationForViewPeriod?.userId.currentUserDepartmentDesignationId
                      ?.departmentDesignationId?.designationId?.name
                  : '-'}
              </Typography>
            </Stack>
          </TableCell>
          {viewPeriod?.map((period) => {
            const key = `${period.year}-${period.month}`;
            const allocationForViewPeriodMonth = allocationForViewPeriod[key];
            return (
              <TableCell
                key={`${period.year} - ${period.month}`}
                align="right"
                sx={{
                  borderLeft: allocationForViewPeriodMonth?.disabled
                    ? '0.5rem solid rgba(159, 156, 171, 0.56)'
                    : '0.5rem solid rgba(75, 181, 67, 0.56)',
                  borderRadius: '3px',
                  // pointerEvents: allocationForViewPeriodMonth?.disabled ? 'none' : 'auto',
                  backgroundColor: allocationForViewPeriodMonth?.disabled
                    ? `rgba(159, 156, 171, 0.2)`
                    : `rgba(75, 181, 67, 0.05)`,
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Input
                    data-testid="hours-input"
                    label="Hours"
                    size="small"
                    type="number"
                    value={allocationForViewPeriodMonth?.allocatedHours ?? 0}
                    sx={{ width: '80px' }}
                    onClick={() => {
                      if (allocationForViewPeriodMonth?.disabled) return;

                      handleEdit({
                        ...allocationForViewPeriodMonth,
                        id: allocationForViewPeriod?.id,
                        userId: allocationForViewPeriod?.userId,
                        projectId: allocationForViewPeriod?.projectId,
                        allAllocations: allocationForViewPeriod?.monthlyAllocations,
                      });
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      if (allocationForViewPeriodMonth?.disabled) {
                        handleReallocate({
                          ...allocationForViewPeriodMonth,
                          id: allocationForViewPeriod?.id,
                          userId: allocationForViewPeriod?.userId,
                          projectId: allocationForViewPeriod?.projectId,
                          allAllocations: allocationForViewPeriod?.monthlyAllocations,
                          allocatedPercentage: allocationForViewPeriod?.allocatedPercentage,
                          isAllocatedPercentageLocked: allocationForViewPeriod?.isAllocatedPercentageLocked,
                          startDate: allocationForViewPeriod?.startDate,
                          endDate: allocationForViewPeriod?.endDate,
                        });
                      } else {
                        handleDelete({
                          ...allocationForViewPeriodMonth,
                          id: allocationForViewPeriod?.id,
                          userId: allocationForViewPeriod?.userId,
                          projectId: allocationForViewPeriod?.projectId,
                          allAllocations: allocationForViewPeriod?.monthlyAllocations,
                        });
                      }
                    }}
                  />
                  {opexViewPermission && salaryViewPermission && (
                    <Input
                      label="COGSOH"
                      data-testid="cogsoh-input"
                      size="small"
                      type="number"
                      readOnly
                      sx={{ width: '110px' }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      value={allocationForViewPeriodMonth?.cogsoh ?? 0}
                      disableUnderline
                    />
                  )}
                  <Input
                    label="Revenue"
                    data-testid="revenue-input"
                    size="small"
                    type="number"
                    sx={{ width: '100px' }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    value={allocationForViewPeriodMonth?.revenue ?? 0}
                    // client -> not fix bid
                    onClick={() => {
                      if (calculations.isRevenueEditable(allocationForViewPeriod?.projectId)) {
                        handleEdit({
                          ...allocationForViewPeriodMonth,
                          id: allocationForViewPeriod?.id,
                          userId: allocationForViewPeriod?.userId,
                          projectId: allocationForViewPeriod?.projectId,
                          allAllocations: allocationForViewPeriod?.monthlyAllocations,
                        });
                      }
                    }}
                    readOnly
                  />
                  <Tooltip
                    title={
                      <InfoToolTip
                        monthlyAllocation={allocationForViewPeriodMonth}
                        project={allocationForViewPeriod?.projectId}
                        opexViewPermission={opexViewPermission}
                        salaryViewPermission={salaryViewPermission}
                      />
                    }
                  >
                    <IconButton size="small" data-testid="tooltip-icon-button">
                      <InfoIcon
                        sx={{
                          color: (() =>
                            computeTooltipColor(
                              allocationForViewPeriodMonth,
                              allocationForViewPeriod?.projectId,
                              opexViewPermission,
                              salaryViewPermission
                            ))(),
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </MUITableBody>
  );
};
//
export default TableBody;
