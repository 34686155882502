import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//
import { supportTicketActions } from 'features/support-tickets/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { projectActions } from 'features/projects/slice';
import {
  selectAddSupportTicketLoader,
  selectUploadedAttachments,
  selectAttachmentLoader,
} from 'features/support-tickets/selectors';
import { selectProject, selectClient } from 'features/projects/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { Autocomplete, Select, TextField, Popup, DragAndDropZone } from 'features/base/components';
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import attachSupportTicketValidation from 'features/support-tickets/validation/attach-support-ticket-validation';
import {
  SUPPORT_TICKET_ALLOWED_EXTENSIONS,
  SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES,
  SUPPORT_TICKET_ATTACHMENT_MAX_SIZE,
} from 'features/base/constants/file-upload';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { processFiles } from 'features/base/helpers/file';
import { SUPPORT_TICKET_TYPES } from 'features/base/constants/support-ticket-types';
/**
 * Function that defines the popup form for attaching a support ticket
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AttachSupportTicketsPopup = ({ addModalOpen, setAddModalOpen }) => {
  //
  const [clientUser, setClientUser] = useState(null);
  //
  const dispatch = useDispatch();
  const { projectId } = useParams();
  //
  const projectDetails = useSelector(selectProject);
  const client = useSelector(selectClient);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddSupportTicketLoader);
  const uploadedAttachments = useSelector(selectUploadedAttachments);
  const attachmentLoader = useSelector(selectAttachmentLoader);
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(supportTicketActions.clearAttachments());
  };
  //
  const handleOnDeleteFile = (fileToRemove) => {
    dispatch(
      supportTicketActions.setAttachments(
        uploadedAttachments?.filter(
          (uploadedAttachment) => uploadedAttachment?.name !== fileToRemove?.name
        )
      )
    );
  };
  //
  const handleOnAddFiles = (filesToAdd) => {
    if (filesToAdd?.length > 0) {
      const formData = processFiles(filesToAdd);
      dispatch(supportTicketActions.uploadAttachments({ files: formData }));
    }
  };
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    dispatch(projectActions.getClient({ projectId }));
  }, []);
  //
  return (
    <Popup
      open={addModalOpen}
      onClose={handleOnClose}
      title="Attach a support ticket"
      subTitle="Please fill in the form below to attach a support ticket. The ticket will be submitted behalf of the project and client selected."
      mediumSize="888px"
    >
      <Formik
        initialValues={{
          title: '',
          description: '',
          project: projectDetails?.name,
          client: client?.company,
          clientUser: null,
          jiraIssueId: '',
          severity: 'Select',
          type: 'Select',
        }}
        validationSchema={attachSupportTicketValidation}
        onSubmit={(values) => {
          const payload = {
            projectId,
            raisedBy: clientUser,
            title: values.title,
            description: values.description,
            severity: values.severity,
            ...(values.jiraIssueId && { jiraIssueId: values?.jiraIssueId }),
            type: values?.type,
            attachments: uploadedAttachments.map((attachment) => ({
              ...attachment,
              url: encodeURI(attachment.url),
            })),
          };
          //
          dispatch(supportTicketActions.addSupportTickets(payload));
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <Autocomplete
              id="project"
              name="project"
              placeholder="Select"
              options={[{ id: projectDetails?.id, label: projectDetails?.name }]}
              label="Project or scope [Locked]"
              onChange={(_, newValue) => {
                setFieldValue('project', newValue?.label || null);
              }}
              isOptionEqualToValue={(option, autoValue) =>
                option.label === autoValue || autoValue === null
              }
              controlled
              readOnly
              value={values.project}
              error={Boolean(touched.project && errors.project)}
              errorMsg={touched?.project && errors?.project}
              onClose={() => setFieldTouched('project', false)}
              sx={{
                pb: 2,
              }}
            />
            <Autocomplete
              id="client"
              name="client"
              placeholder="Select"
              options={[{ id: client?.id, label: client?.company }] ?? []}
              label="Client (Company) [Locked]"
              onChange={(_, newValue) => {
                setFieldValue('client', newValue?.label || null);
              }}
              controlled
              readOnly
              value={values.client}
              isOptionEqualToValue={(option, autoValue) =>
                option.label === autoValue || autoValue === null
              }
              error={Boolean(touched.client && errors.client)}
              errorMsg={touched?.client && errors?.client}
              onClose={() => setFieldTouched('client', false)}
              sx={{
                pb: 2,
              }}
            />

            <Autocomplete
              id="clientUser"
              name="clientUser"
              placeholder="Select Client User"
              options={
                client?.users?.map((record) => ({
                  id: record?.userId?.id,
                  label: `${record?.userId?.firstName} ${record?.userId?.lastName} [${record?.userId?.email}]`,
                })) ?? []
              }
              label="Client user (Behalf of)"
              onChange={(_, newValue) => {
                setFieldValue('clientUser', newValue?.label || null);
                setClientUser(newValue?.id || null);
              }}
              controlled
              value={values?.clientUser}
              isOptionEqualToValue={(option, autoValue) =>
                option.label === autoValue || autoValue === null
              }
              error={Boolean(touched?.clientUser && errors?.clientUser)}
              errorMsg={touched?.clientUser && errors?.clientUser}
              onClose={() => setFieldTouched('clientUser', false)}
              sx={{
                pb: 2,
              }}
            />

            <TextField
              type="text"
              name="jiraIssueId"
              value={values.jiraIssueId}
              error={Boolean(touched.jiraIssueId && errors.jiraIssueId)}
              helperText={touched.jiraIssueId && errors.jiraIssueId}
              placeholder={'PRJ-1235'}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Jira Issue ID"
            />

            <TextField
              type="text"
              name="title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Title"
            />
            <TextField
              type="text"
              name="description"
              value={values.description}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Description"
              height={120}
              multiline
              rows={4}
            />
            <Select
              id="severity"
              name="severity"
              value={values.severity}
              onChange={(event) => {
                setFieldValue('severity', event.target.value);
              }}
              items={[
                { key: '0', value: 'Select' },
                { key: '1', value: SEVERITY_TYPES.LOW },
                { key: '2', value: SEVERITY_TYPES.MEDIUM },
                { key: '3', value: SEVERITY_TYPES.HIGH },
              ]}
              textLabel="Severity"
              stringFormat={capitalizeFirstLetter}
              error={Boolean(touched.severity && errors.severity)}
              errorMsg={touched.severity && errors.severity}
            />
            <Select
              id="type"
              name="type"
              value={values.type}
              onChange={(event) => {
                setFieldValue('type', event.target.value);
              }}
              items={[
                { key: '0', value: 'Select' },
                { key: '1', value: SUPPORT_TICKET_TYPES.BUG },
                { key: '2', value: SUPPORT_TICKET_TYPES.TASK },
              ]}
              textLabel="Type"
              stringFormat={capitalizeFirstLetter}
              error={Boolean(touched.type && errors.type)}
              errorMsg={touched.type && errors.type}
            />
            <DragAndDropZone
              label="Upload attachments"
              allowedExtensions={SUPPORT_TICKET_ALLOWED_EXTENSIONS}
              allowedMimetypes={SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES}
              maxSize={SUPPORT_TICKET_ATTACHMENT_MAX_SIZE}
              loading={attachmentLoader}
              disabled={loading}
              onAdd={handleOnAddFiles}
              onDelete={handleOnDeleteFile}
              uploadedFiles={uploadedAttachments}
            />
            <ButtonGrid
              leftButtonText="Cancel"
              rightButtonText={loading ? 'Submitting' : 'Submit'}
              leftOnClick={handleOnClose}
              rightOnClick={null}
              rightIcon={loading ? loaderIcon : null}
              submitDisabled={loading || attachmentLoader}
            />
          </form>
        )}
      </Formik>
    </Popup>
  );
};
//
export default AttachSupportTicketsPopup;
