import { DriveFileRenameOutline } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
/**
 * Work area table columns - basic columns
 */
export const WORK_AREA_TABLE_BASIC_COLUMNS = [
  {
    field: 'name',
    headerName: 'Work area',
    width: 600,
    valueGetter: (params) => params.row.name.value,
    renderCell: (params) => {
      const { value } = params.row.name;
      return (
        <Box
          sx={{
            width: '60%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            overflowWrap: 'anywhere',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {value}
        </Box>
      );
    },
  },
];
/**
 * Work area table columns - action column
 */
export const WORK_AREA_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: (params) => {
      const { organizationUpdatePermissionsVerified, isLoading, handleEdit } = params.row.action;
      return organizationUpdatePermissionsVerified ? (
        <IconButton readOnly={isLoading} sx={{ '&:hover': { color: 'blue' } }} onClick={handleEdit}>
          <DriveFileRenameOutline />
        </IconButton>
      ) : undefined;
    },
  },
];
