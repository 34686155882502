/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { clientActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a client
 * @param {Object} payload
 */
export function* addClientGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_CLIENT, payload);
    yield put(clientActions.addClientSucceeded(response));
  } catch (error) {
    yield put(clientActions.addClientFailed(error?.message));
  }
}
/**
 * Generator function for editing a client
 * @param {Object} payload
 */
export function* editClientGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_CLIENT, payload);
    yield put(clientActions.editClientSucceeded(response));
  } catch (error) {
    yield put(clientActions.editClientFailed(error?.message));
  }
}
/**
 * Generator function for getting clients
 * @param {Object} payload
 */
export function* getClientsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CLIENTS, payload);
    yield put(clientActions.getClientsSucceeded(response));
  } catch (error) {
    yield put(clientActions.getClientsFailed(error?.message));
  }
}
/**
 * Generator function for getting a client a single client by id
 * @param {Object} payload
 */
export function* getClientByIdGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_CLIENT_BY_ID, payload);
    yield put(clientActions.getClientByIdSucceeded(response));
  } catch (error) {
    yield put(clientActions.getClientByIdFailed(error?.message));
  }
}
/**
 * Generator function for adding a user to a client
 * @param {Object} payload
 */
export function* addClientUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_CLIENT_USER, payload);
    yield put(clientActions.addClientUserSucceeded(response));
  } catch (error) {
    yield put(clientActions.addClientUserFailed(error?.message));
  }
}
/**
 * Generator function for editing a user of a client
 * @param {Object} payload
 */
export function* editClientUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_CLIENT_USER, payload);
    yield put(clientActions.editClientUserSucceeded(response));
  } catch (error) {
    yield put(clientActions.editClientUserFailed(error?.message));
  }
}
/**
 * Generator function for editing a user of a client
 * @param {Object} payload
 */
export function* deleteClientUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_CLIENT_USER, payload);
    yield put(clientActions.deleteClientUserSucceeded(response));
  } catch (error) {
    yield put(clientActions.deleteClientUserFailed(error?.message));
  }
}
/**
 * Generator function for getting projects
 * @param {Object} payload
 */
export function* getAllProjectsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECTS, payload);
    yield put(clientActions.getAllProjectsSucceeded(response));
  } catch (error) {
    yield put(clientActions.getAllProjectsFailed(error?.message));
  }
}
/**
 * Generator function for getting a client a single client by id
 * @param {Object} payload
 */
export function* getProjectAllocationsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROJECT_ALLOCATIONS_LIST, payload);
    yield put(clientActions.getProjectAllocationsListSucceeded(response));
  } catch (error) {
    yield put(clientActions.getProjectAllocationsListSucceeded(error?.message));
  }
}
/**
 * Generator function for inviting a client user
 * @param {Object} payload
 */
function* inviteClientUserGenerator({ payload }) {
  try {
    const response = yield call(request, API.INVITE_CLIENT, payload);
    yield put(clientActions.inviteClientUserSucceeded(response));
  } catch (error) {
    yield put(clientActions.inviteClientUserFailed(error?.message));
  }
}
/**
 * Generator function for uploading logo
 * @param {Object} payload
 */
function* uploadLogoGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_LOGO, payload, true);
    yield put(clientActions.uploadLogoSucceeded(response));
  } catch (error) {
    yield put(clientActions.uploadLogoFailed(error?.message));
  }
}
/**
 * Redux saga that triggers above generated functions
 */
export function* clientSaga() {
  yield takeLatest(clientActions.addClient.type, addClientGenerator);
  yield takeLatest(clientActions.editClient.type, editClientGenerator);
  yield takeLatest(clientActions.getClients.type, getClientsGenerator);
  yield takeLatest(clientActions.getClientById.type, getClientByIdGenerator);
  yield takeLatest(clientActions.addClientUser.type, addClientUserGenerator);
  yield takeLatest(clientActions.editClientUser.type, editClientUserGenerator);
  yield takeLatest(clientActions.deleteClientUser.type, deleteClientUserGenerator);
  yield takeLatest(clientActions.getAllProjects.type, getAllProjectsGenerator);
  yield takeLatest(
    clientActions.getProjectAllocationsList.type,
    getProjectAllocationsListGenerator
  );
  yield takeLatest(clientActions.inviteClientUser.type, inviteClientUserGenerator);
  yield takeLatest(clientActions.uploadLogo.type, uploadLogoGenerator);
}
//
export default clientSaga;
