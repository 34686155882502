/**
 * Available types for Arsenal items
 * @type {{ BITBUCKET: string, GOOGLE_DRIVE: string, CONFLUENCE: string }}
 */
export const ARSENAL_ITEM_TYPES = {
  BITBUCKET: 'Bitbucket',
  GOOGLE_DRIVE: 'Google Drive',
  CONFLUENCE: 'Confluence',
};
/**
 * Arsenal type domains for each category
 * @type {{ BITBUCKET: string, GOOGLE_DRIVE: string, CONFLUENCE: string }}
 */
export const ARSENAL_TYPE_URL_DOMAINS = {
  BITBUCKET: 'bitbucket.org',
  GOOGLE_DRIVE: 'drive.google.com',
  CONFLUENCE: 'surgeglobal.atlassian.net/wiki/spaces',
};
