import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Box,
  Avatar,
  Typography,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
//
import Popup from 'features/base/components/modal';
import { userActions } from 'features/users/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectSalaryUserList,
  selectAddSalaryLoading,
  selectSalaryUserLoading,
} from 'features/users/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import salaryFromValidation from 'features/users/validation/salary-validation';
import { DatePicker, NumericInputField, SearchBar, Select, Button } from 'features/base/components';
import { AMOUNT_TYPE_ARRAY } from 'features/base/constants/amount-types';
import { PAYMENT_TYPE_ARRAY } from 'features/base/constants/payment-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import './index.scss';
import ValidationError from 'features/base/components/validation-error';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { USER_TYPES } from 'features/base/constants/user-types';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import AddMultipleSalaryPopup from './add-multiple-salary';
/**
 * Function that defines the popup form for adding a salary to users
 * @prop {boolean} addSalaryModalOpen - boolean to show/hide the popup
 * @prop {function} setAddSalaryModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddSalaryPopup = ({ addSalaryModalOpen, setAddSalaryModalOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const usersList = useSelector(selectSalaryUserList);
  const loading = useSelector(selectAddSalaryLoading);
  const salaryUsersLoading = useSelector(selectSalaryUserLoading);
  //
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [selectUsersValidationError, setSelectUsersValidationError] = useState(false);
  const [addMultipleSalaryModalOpen, setAddMultipleSalaryModalOpen] = useState(false);
  //
  const handleOnClose = () => {
    setAddSalaryModalOpen(false);
    dispatch(notificationActions.resetNotification());
    setSelectedUsers([]);
    setSearchData('');
    setSelectUsersValidationError(false);
  };
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
  };
  //
  useEffect(() => {
    if (addSalaryModalOpen) {
      dispatch(
        userActions.getSalaryUsers({
          query: `search=${searchData}&pagination=false&sortBy=firstName:asc`,
        })
      );
    }
  }, [searchData]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      // handleOnClose();
    }
  }, [notification]);
  //
  const getStartDates = () => {
    const selectedUserDates = [];
    selectedUsers.forEach((sUser) => {
      const found = usersList.find((user) => user.id === sUser);
      if (found && found?.currentSalary?.startDate) {
        selectedUserDates.push(found?.currentSalary?.startDate);
      }
    });
    return selectedUserDates;
  };
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.SALARY,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addSalaryModalOpen}
        onClose={handleOnClose}
        title="Add Salary"
        mediumSize="70rem"
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <SearchBar
              placeholder="Search username or email"
              value={searchData}
              handleSearchInput={handleSearchInput}
            />
            <ValidationError
              errorMsg="Please choose atleast one user to continue"
              error={selectUsersValidationError}
            />
            {salaryUsersLoading ? (
              <Grid
                container
                sx={{ height: 400, justifyContent: 'center', alignContent: 'center' }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Box sx={{ height: 400, overflow: 'auto', mt: 1 }}>
                {[...usersList]
                  .sort((fisrtUser, secondUser) => {
                    if (selectedUsers.includes(fisrtUser.id)) {
                      return -1;
                    }
                    if (selectedUsers.includes(secondUser.id)) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(
                    (user) =>
                      (user.type === USER_TYPES.INTERNAL ||
                        user.type === USER_TYPES.CONTRACT ||
                        user.type === USER_TYPES.INTERN) && (
                        <Box key={user.id} sx={{ display: 'flex' }}>
                          <Checkbox
                            checked={selectedUsers.includes(user.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedUsers([...selectedUsers, user?.id]);
                              } else {
                                setSelectedUsers([
                                  ...selectedUsers.filter((userId) => userId !== user.id),
                                ]);
                              }
                            }}
                            value={user.id}
                          />
                          <Card
                            sx={{
                              pl: 2,
                              m: 1,
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            elevation={4}
                          >
                            {user && user.profileImage && (
                              <Avatar
                                alt={`${user?.firstName}`}
                                src={user?.profileImage}
                                imgProps={{ referrerPolicy: 'no-referrer' }}
                              />
                            )}
                            {user && !user.profileImage && (
                              <Avatar
                                alt={`${user?.firstName}`}
                                src="/static/images/avatar/2.jpg"
                              />
                            )}
                            <CardContent sx={{ pb: 0, p: 1, alignItems: 'center' }}>
                              <Typography>
                                {`${user?.firstName} ${user?.lastName}` || 'Name Not Found'}
                              </Typography>
                              <Typography sx={{ color: 'lightgray' }}>
                                {`${
                                  user?.currentUserDepartmentDesignationId?.departmentDesignationId
                                    ?.designationId?.name || 'Not available'
                                }`}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Box>
                      )
                  )}
              </Box>
            )}
          </Grid>
          <Grid item xs>
            <Button
              sx={{
                width: { xs: '100%', md: '100%' },
                mb: { xs: '16px', md: '5px' },
                mr: { xs: 0, md: '24px' },
                whiteSpace: 'nowrap',
              }}
              onClick={() => setAddMultipleSalaryModalOpen(true)}
            >
            
              Upload Bulk Salaries
            </Button>
            {addMultipleSalaryModalOpen && (
              <AddMultipleSalaryPopup
                open={addMultipleSalaryModalOpen}
                setOpen={setAddMultipleSalaryModalOpen}
              />
            )}
            <Formik
              initialValues={{
                amountType: '',
                paymentType: '',
                startDate: '',
                amount: '',
              }}
              validationSchema={salaryFromValidation(getStartDates())}
              onSubmit={(values) => {
                setSelectUsersValidationError(false);
                if (selectedUsers?.length < 1) {
                  setSelectUsersValidationError(true);
                }
                dispatch(
                  userActions.addSalary({
                    amountType: values.amountType?.toLocaleUpperCase(),
                    paymentType: values.paymentType?.toLocaleUpperCase(),
                    startDate: values.startDate,
                    amount: values.amount,
                    userIds: selectedUsers,
                  })
                );
              }}
            >
              {({ errors, handleSubmit, handleBlur, touched, values, setFieldValue }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Select
                    name="amountType"
                    id="amountType"
                    value={values.amountType}
                    selectLabel="amountType"
                    onChange={(event) => {
                      setFieldValue('amountType', event.target.value);
                    }}
                    items={AMOUNT_TYPE_ARRAY.map((item) => ({
                      key: item.key,
                      value: capitalizeFirstLetter(item.value),
                    }))}
                    textLabel="Type"
                    stringFormat={capitalizeFirstLetter}
                    error={Boolean(touched.amountType && errors.amountType)}
                    errorMsg={touched?.amountType && errors?.amountType}
                    placeholder="Select type"
                  />
                  <Select
                    name="paymentType"
                    id="paymentType"
                    value={values.paymentType}
                    selectLabel="paymentType"
                    onChange={(event) => {
                      setFieldValue('paymentType', event.target.value);
                    }}
                    items={PAYMENT_TYPE_ARRAY.map((item) => ({
                      key: item.key,
                      value: capitalizeFirstLetter(item.value),
                    }))}
                    textLabel="Payment Type"
                    stringFormat={capitalizeFirstLetter}
                    error={Boolean(touched.paymentType && errors.paymentType)}
                    errorMsg={touched?.paymentType && errors?.paymentType}
                    placeholder="Select payment type"
                  />
                  <DatePicker
                    label="Start date"
                    value={values.startDate}
                    error={Boolean(touched.startDate && errors.startDate)}
                    errorMsg={touched?.startDate && errors?.startDate}
                    views={['month', 'year']}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(event) => {
                      setFieldValue(
                        'startDate',
                        moment(event?.$d).startOf('month').format(ISO_WITHOUT_TIME)
                      );
                    }}
                    placeholder="Select date"
                  />
                  <NumericInputField
                    type="text"
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                    onChange={(v) => setFieldValue('amount', v.floatValue)}
                    onBlur={handleBlur}
                    thousandSeparator=","
                    prefix="$"
                    placeholder="$"
                  />
                  <ButtonGrid
                    leftButtonText="Cancel"
                    rightButtonText={loading ? 'Submitting' : 'Submit'}
                    leftOnClick={handleOnClose}
                    rightOnClick={null}
                    rightIcon={loading ? loaderIcon : null}
                    submitDisabled={loading}
                  />
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddSalaryPopup;
