import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { MULTIPLE_FILE_UPLOAD_MAXIMUM } from 'features/base/constants/file-upload';
import { getDisconnectionSet } from 'features/base/helpers/array';
import { notificationActions } from 'features/base/notifications/slice';
import DataList from '../data-list';
import './index.scss';

/**
 * Function that renders an upload box component with drag and drop functionality which also displays a list
 * of uploaded files as removable cards via the DataList component
 * @prop {String} label - Label for the upload box
 * @prop {List} allowedExtensions - List of allowed extensions (only for display)
 * @prop {List} allowedMimetypes - List of allowed file mimetypes (affects functionality)
 * @prop {Number} maxSize - Max file size in MB
 * @prop {Boolean} loading - Indicates the loading state
 * @prop {Boolean} singleFile - If true, file limit will be set to 1
 * @prop {Function} onAdd - Callback function to run when files are submitted
 * @prop {Function} onDelete - Callback function to run when files are removed
 * @prop {List<Object>} uploadedFiles - List of uplaoded files to display
 * @prop {Boolean} disabled - If true, upload will be disabled
 * @prop {Object} listSx - Custom SX for the list of files displayed
 * @prop {Object} uploadBoxSx - Custom SX for the uplaod box
 * @prop {Boolean} renderInternalSelect - DataList prop that renders a multi select autocomplete complete within each list item if true
 * @prop {Object} internalSelectProps - DataList prop that defines the props for the internal multi select component (renderInternalSelect must be true and options, setSelectedItems is required)
 * @prop {String} internalSelectOptionsKey - DataList prop that indicates the key in each item to use as the selectedOptions of the multi select component. The key must be present in each item
 * @returns {DragAndDropZone}
 */
const DragAndDropZone = ({
  label,
  allowedExtensions,
  allowedMimetypes,
  maxSize,
  loading,
  singleFile,
  onAdd,
  onDelete,
  uploadedFiles,
  disabled,
  listSx,
  uploadBoxSx,
  renderInternalSelect,
  internalSelectProps,
  internalSelectOptionsKey,
  errorMsg,
  ...rest
}) => {
  const dispatch = useDispatch();
  //
  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const [hover, setHover] = useState(false);
  //
  const displayError = errorMsg || error;
  //
  const { getRootProps, getInputProps } = useDropzone({
    // This function allows the 'allowedMimeTypes' prop to be an array which is easier to use, and then converts it to the required format
    accept: allowedMimetypes?.reduce((acc, fileType) => {
      acc[fileType] = [];
      return acc;
    }, {}),
    maxSize: maxSize * 1024 * 1024,
    maxFiles: singleFile ? 1 : MULTIPLE_FILE_UPLOAD_MAXIMUM,
    onDrop: (acceptedFiles, fileRejections) => {
      setHover(false);
      // If all files were rejected, display error
      if (acceptedFiles?.length <= 0 && fileRejections?.length) {
        setError(`Please submit valid files ${singleFile && 'and ensure only 1 file is selected'}`);
        return;
      }
      // If 'singleFile' is set to true, ensure that the total number of files submitted is 1
      if (singleFile) {
        if ((files?.length ?? 0) + (acceptedFiles?.length ?? 0) > 1) {
          setError('Only 1 file is allowed. Please remove the selected file and try again');
          return;
        }
        setFiles(acceptedFiles);
      }
      setError('');
      // If a single file is selected, and its already added, display an error
      if (
        acceptedFiles?.length === 1 &&
        files.find((file) => file?.name === acceptedFiles?.[0]?.name)
      ) {
        setError('File already added');
        return;
      }
      const newFiles = getDisconnectionSet(files, acceptedFiles, 'name');
      // If some of the newly added files are already added, convey that information to the user through a notification
      if (newFiles?.length !== acceptedFiles?.length) {
        dispatch(
          notificationActions.setNotification({
            message: 'Already added files were ommitted',
            type: ERROR_TYPES.INFO,
          })
        );
      }
      setFiles([...files, ...newFiles]);
      onAdd(acceptedFiles);
    },
    disabled,
    onDragOver: () => setHover(true),
    onDragLeave: () => setHover(false),
    ...rest,
  });
  const handleRemoveFile = (fileToRemove) => {
    setHover(false);
    setError('');
    setFiles(files.filter((file) => file?.name !== fileToRemove?.name));
    onDelete(fileToRemove);
  };
  //
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography fontSize="16px" className="field-label">
            {label}
          </Typography>
        </Grid>
      </Grid>
      <Box {...getRootProps({ className: hover ? 'dropzone-hover' : 'dropzone' })} sx={uploadBoxSx}>
        <input {...getInputProps()} />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h5">Drag & Drop or Choose file to upload</Typography>
            <p className="accepted-filetypes">
              Supported formats:{' '}
              {Array.isArray(allowedExtensions) ? allowedExtensions?.join(', ') : allowedExtensions}
            </p>
            <p className="allowed-filesize">Max size: {maxSize} MB</p>
            <CloudUploadIcon className="dropzone-icon" />
          </>
        )}
      </Box>
      <DataList
        items={uploadedFiles}
        handleRemove={handleRemoveFile}
        displayKey={'name'}
        sx={listSx}
        disabled={disabled}
        renderInternalSelect={renderInternalSelect}
        internalSelectProps={internalSelectProps}
        internalSelectOptionsKey={internalSelectOptionsKey}
      />
      {displayError && <p className="error-feedback">{displayError}</p>}
    </Box>
  );
};
//
export default DragAndDropZone;
