import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ShimmerTitle, ShimmerThumbnail } from 'react-shimmer-effects';
//
import { profileActions } from 'features/profile/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectUser, selectUserSkillLoading, selectUserSkills } from 'features/profile/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import AddSkillGroup from './components/add-skill-group';
import CurrentSkillGroups from './components/current-skill-groups';
import './index.scss';

/**
 * Function that defines the manage user profile skills
 * @returns {Popup}
 */
const MySkillView = () => {
  const dispatch = useDispatch();
  //
  const { id: userId } = useParams();
  //
  const user = useSelector(selectUser);
  const userSkills = useSelector(selectUserSkills);
  const notification = useSelector(selectNotification);
  const isLoading = useSelector(selectUserSkillLoading);
  //
  const [customizedSkillGroups, setCustomizedSkillGroups] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [sliderUpdated, setSliderUpdated] = useState(false);
  //
  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
    dispatch(notificationActions.resetNotification());
  };
  const getCustomizedSkillGroups = () =>
    user?.skillGroups?.map((skillGroup) => ({
      name: skillGroup?.name,
      userSkills: skillGroup?.skills?.map((skillId) => {
        const userSkill = userSkills.find(
          (userSkillRecord) => userSkillRecord?.skillId?.id === skillId
        );
        return {
          id: userSkill?.id,
          skillId,
          name: userSkill?.skillId?.name,
          rating: userSkill?.rating,
          learningType: userSkill?.learningType,
        };
      }),
    }));
  //
  useEffect(() => {
    setCustomizedSkillGroups(getCustomizedSkillGroups());
    dispatch(notificationActions.resetNotification());
  }, [userSkills, user]);
  //
  useEffect(() => {
    dispatch(profileActions.getAllSkillGroups({ query: `pagination=false&search=${searchData}` }));
  }, [searchData]);
  //
  useEffect(() => {
    dispatch(profileActions.getUserSkills({ userId }));
  }, []);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [notification]);
  //
  return (
    <div className="root">
      <Grid container rowSpacing={2}>
        {isLoading ? (
          <Grid container item md={5} direction="column" mt={2} px={2}>
            <ShimmerTitle line={1} gap={10} variant="primary" />
            <ShimmerThumbnail height={5} />
            <ShimmerThumbnail height={30} width={400} />
            <ShimmerTitle line={1} gap={10} variant="secondary" />
            <ShimmerTitle line={5} gap={10} variant="secondary" />
          </Grid>
        ) : (
          <AddSkillGroup
            userId={userId}
            searchData={searchData}
            handleSearchInput={handleSearchInput}
            setEditMode={setEditMode}
          />
        )}
        {isLoading ? (
          <Grid container item md={7} direction="column" mt={2} px={2}>
            <ShimmerTitle line={1} gap={10} variant="primary" />
            <ShimmerThumbnail height={5} />
            <ShimmerTitle line={5} gap={10} variant="secondary" />
          </Grid>
        ) : (
          <CurrentSkillGroups
            userId={userId}
            customizedSkillGroups={customizedSkillGroups}
            setCustomizedSkillGroups={setCustomizedSkillGroups}
            getCustomizedSkillGroups={getCustomizedSkillGroups}
            editMode={editMode}
            sliderUpdated={sliderUpdated}
            setSliderUpdated={setSliderUpdated}
            setEditMode={setEditMode}
          />
        )}
      </Grid>
    </div>
  );
};
//
export default MySkillView;
