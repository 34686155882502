import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ShimmerTable } from 'react-shimmer-effects';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Download as DownloadIcon, Sync as SyncIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
//
import { Button } from 'features/base/components';
import { WORK_ALLOCATION_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { MONTHS } from 'features/base/constants/date-formatting';
import createFormattedString from 'features/base/helpers/param-formatter';
import { downloadCSVFile } from 'features/base/helpers/file';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import ROUTES from 'features/base/constants/routes';
import { generateLookup, getIdList } from 'features/base/helpers/object';
import TIME_OUTS from 'features/base/constants/time-outs';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { selectNotification } from 'features/base/notifications/selectors';
import {
  selectAllocatedActualReportAllocations,
  selectAllocatedActualReportAllocationsLoading,
  selectAllocatedActualReportExportAllocations,
  selectAllocatedActualReportIsInitialCall,
  selectAllocatedActualReportProjectList,
  selectAllocatedActualReportProjectsLoading,
  selectAllocatedActualReportUserList,
  selectAllocatedActualReportUsersLoading,
} from '../selectors';
import { reportActions } from '../slice';
import { AllocatedActualHoursTable, Filters, ProjectProfileTable } from './components';

//
const AllocatedActualReport = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //
  const thisYear = moment().year();
  // Function to parse query parameters from the URL
  const getQueryParameter = (name) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(name);
  };
  //
  const allocations = useSelector(selectAllocatedActualReportAllocations);
  const userList = useSelector(selectAllocatedActualReportUserList);
  const projectList = useSelector(selectAllocatedActualReportProjectList);
  const allocationsLoading = useSelector(selectAllocatedActualReportAllocationsLoading);
  const usersLoading = useSelector(selectAllocatedActualReportUsersLoading);
  const projectsLoading = useSelector(selectAllocatedActualReportProjectsLoading);
  const isInitialCall = useSelector(selectAllocatedActualReportIsInitialCall);
  const notification = useSelector(selectNotification);
  const allocationsToExport = useSelector(selectAllocatedActualReportExportAllocations);
  //
  const [selectedProjects, setSelectedProjects] = useState(['All']);
  const [selectedUsers, setSelectedUsers] = useState(['All']);
  const [debounceLoading, setDebounceLoading] = useState(true);
  const [lastParams, setLastParams] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState(thisYear);
  //
  const loading = allocationsLoading || usersLoading || projectsLoading;
  //
  const userMap = useMemo(() => generateLookup(userList, 'id', 'email'), [userList]);
  const projectMap = useMemo(() => generateLookup(projectList, 'id', 'name'), [projectList]);
  //
  const params = useMemo(
    () => ({
      aggregated: 'true',
      // For userIds and projectIds, if all are selected, a small optimization is done by sending null instead of all ids
      userIds:
        selectedUsers?.length >= userList?.length && userList?.length
          ? ''
          : getIdList(selectedUsers),
      projectIds:
        selectedProjects?.length >= projectList?.length && projectList?.length
          ? ''
          : getIdList(selectedProjects),
      limit: WORK_ALLOCATION_PAGINATION_LIMIT,
      sortBy: 'projectId.name:asc',
      page,
      startDate: `${selectedYear}-01-01`,
      endDate: `${selectedYear}-12-31`,
    }),
    [selectedUsers, selectedProjects, selectedYear, userList, projectList, page]
  );
  //
  const monthsOfSelectedYear = useMemo(() => {
    const year = moment(selectedYear, 'YYYY');
    const months = [];
    //
    for (let month = 0; month < 12; month += 1) {
      months.push(year.format('YYYY-M'));
      year.add(1, 'month');
    }
    //
    return months;
  }, [selectedYear]);
  //
  const getAllocationData = (allocation) => {
    const allocationsDateLookup = {};
    allocation?.monthlyAllocations?.forEach((monthlyAllocation) => {
      const key = `${monthlyAllocation.year}-${monthlyAllocation.month}`;
      allocationsDateLookup[key] = monthlyAllocation;
    });

    const viewPeriodData = {};
    monthsOfSelectedYear.forEach((yearMonth) => {
      const record = allocationsDateLookup[yearMonth];
      viewPeriodData[yearMonth] = record;
    });

    return {
      id: allocation?.id,
      projectId: allocation?.projectId,
      userId: allocation?.userId,
      ...viewPeriodData,
    };
  };
  /**
   * This is used as a central point of logged hours calculation, used for both rendering the report and generating the data for the excel file
   * Data structure (lookup structure that optimizes the code by reducing loops):
   * [{ projectId: 'id1', userId: 'id1', '01-2023': { allocatedHours: 20, cogsoh: 30, revenue: 1000, ...rest } },]
   */
  const allocationsByViewPeriod = useMemo(
    () => allocations?.docs?.map((allocation) => getAllocationData(allocation)),
    [allocations, monthsOfSelectedYear]
  );
  //
  const fetchNextPage = () => {
    setPage(allocations?.page ? allocations.page + 1 : 0);
  };
  // Function to set page to first page and initialize the allocation slice
  const initilialize = () => {
    setPage(1);
    dispatch(reportActions.setAllocatedActualReportIsInitial());
  };
  //
  const handleYearChange = (value) => {
    setSelectedYear(value?.value);
  };
  //
  const handleExport = async () => {
    //
    const allocationByViewPeriodExport = allocationsToExport?.docs?.map((allocation) =>
      getAllocationData(allocation)
    );
    //
    if (!allocationByViewPeriodExport?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No data to export',
          type: ERROR_TYPES.INFO,
        })
      );
      return;
    }
    //
    const data = allocationByViewPeriodExport?.map((item) => {
      const { projectId, userId, id, ...rest } = item;
      // Generating the required structure for the CSV file
      const formatted = {};
      Object.keys(rest).forEach((key) => {
        const { allocatedHours, loggedHours } = rest?.[key] ?? '';
        const splitted = key?.split('-');
        const year = splitted?.[0];
        const monthNumber = parseInt(splitted?.[1], 10);
        const monthName = MONTHS[monthNumber - 1]?.slice(0, 3);
        const allocatedHoursKey = `Allocated Hours (${year} ${monthName})`;
        const actualHoursKey = `Actual Hours (${year} ${monthName})`;
        formatted[allocatedHoursKey] = allocatedHours ?? '-';
        formatted[actualHoursKey] = loggedHours ?? '-';
      });
      //
      return {
        Project: projectId?.name ?? '-',
        Profile:
          `${userId?.firstName} ${userId?.lastName} (${userId?.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId?.name})` ??
          '-',
        ...formatted,
      };
    });
    let filename = 'allocated_vs_actual_hours';
    if (selectedUsers?.length === 1) {
      const selectedUser = userList?.find((user) => user?.id === selectedUsers?.[0]);
      if (selectedUser?.email) {
        filename = `${filename}_${selectedUser?.email}`;
      }
    }
    if (selectedProjects?.length === 1) {
      const selectedProject = projectList?.find((project) => project?.id === selectedProjects?.[0]);
      if (selectedProject?.name) {
        filename = `${filename}_${selectedProject?.name}`;
      }
    }
    downloadCSVFile(Object.keys(data?.[0]), data, `${filename}.csv`);
  };
  //
  const handleSync = () => {
    initilialize();
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getAllocatedActualReportAllocations({
        query: `${formattedParamString}&runAggregation=true`,
      })
    );
  };
  // This function is for fetching allocations with the current query params
  const fetchAllocations = () => {
    const formattedParamString = createFormattedString(params);
    dispatch(reportActions.getAllocatedActualReportAllocations({ query: formattedParamString }));
    dispatch(
      reportActions.getAllocatedActualReportExportAllocations({
        query: `pagination=false&${formattedParamString}`,
      })
    );
    setDebounceLoading(false);
  };
  // This callback function is for fetching allocations with a specified debounce
  const debouncedFetch = useDebouncedCallback(
    fetchAllocations,
    TIME_OUTS.ALLOCATION_FETCH_DEBOUNCE
  );
  // This function handles the onChange event on the users filter
  const handleSelectedUsersChange = (newUsers) => {
    let formattedParams = '';
    const projectIdsFromUrl = getQueryParameter('project');
    const allSelected = newUsers.find((newUser) => newUser?.id === 'All');
    const allPreviouslySelected = selectedUsers.find((user) => user?.id === 'All');
    let updatedUsers;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedUsers = getIdList(newUsers.filter((newUser) => newUser?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show 'All' for users
        updatedUsers = getIdList(userList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other users
      updatedUsers = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for users
      updatedUsers = getIdList(newUsers);
    }
    if (
      JSON.stringify({
        ...params,
        userIds:
          updatedUsers?.split(',')?.length === userList?.length
            ? ''
            : updatedUsers ?? params?.userIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if userIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: updatedUsers,
      project: projectIdsFromUrl,
    });
    navigate(`${ROUTES.ALLOCATED_VS_ACTUAL_HOURS}?${formattedParams}`);
  };
  // This function handles the onChange event on the projects filter
  const handleSelectedProjectsChange = (newProjects) => {
    let formattedParams = '';
    const userIdsFromUrl = getQueryParameter('user');
    const allSelected = newProjects.find((newProject) => newProject?.id === 'All');
    const allPreviouslySelected = selectedProjects.find((project) => project?.id === 'All');
    let updatedProjects;
    if (allSelected) {
      if (allPreviouslySelected) {
        // If 'All' was previously selected, and its still selected in the new options, it indicates another option was deselected
        // Therefore, deselect the 'All' option as well
        updatedProjects = getIdList(newProjects.filter((newProject) => newProject?.id !== 'All'));
      } else {
        // If 'All' option is selected newly, update url to show all project ids
        updatedProjects = getIdList(projectList);
      }
    } else if (allPreviouslySelected) {
      // If 'All' was deselected newly, deselect all other projects
      updatedProjects = '';
    } else {
      // Otherwise, create a string of ids and update the url with the list of ids for projects
      updatedProjects = getIdList(newProjects);
    }
    if (
      JSON.stringify({
        ...params,
        projectIds:
          updatedProjects?.split(',')?.length === projectList?.length
            ? ''
            : updatedProjects ?? params?.projectIds,
      }) !== JSON.stringify(lastParams)
    ) {
      // Initialize allocations and set debounce loading to true, only if projectIds filter has changed
      // Without this check, the data will be cleaned but a new request will not be sent as the query parameters havent changed
      initilialize();
      setDebounceLoading(true);
    }
    // Generate the updated query parameters and update the browser url by navigating
    formattedParams = createFormattedString({
      user: userIdsFromUrl,
      project: updatedProjects,
    });
    navigate(`${ROUTES.ALLOCATED_VS_ACTUAL_HOURS}?${formattedParams}`);
  };
  //
  useEffect(() => {
    dispatch(reportActions.setAllocatedActualReportExportIsInitial());
    dispatch(reportActions.setAllocatedActualReportIsInitial());
    dispatch(
      reportActions.getAllocatedActualReportUsers({ query: 'pagination=false&sortBy=email:asc' })
    );
    dispatch(
      reportActions.getAllocatedActualReportProjects({ query: 'pagination=false&sortBy=name:asc' })
    );
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getAllocatedActualReportExportAllocations({
        query: `pagination=false&${formattedParamString}`,
      })
    );
  }, []);
  //
  useEffect(() => {
    dispatch(reportActions.resetAllocatedActualReportExport());
    // This check is used to prevent redundant api calls being sent due to the complex side effects in this file
    if (JSON.stringify(params) !== JSON.stringify(lastParams)) {
      if (!lastParams) {
        // In the initial call, send a debounced fetch after all the required processing has been completed
        debouncedFetch();
        setLastParams(params);
        return;
      }
      if (
        JSON.stringify({ userIds: params?.userIds, projectIds: params?.projectIds }) ===
        JSON.stringify({ userIds: lastParams?.userIds, projectIds: lastParams?.projectIds })
      ) {
        // If anything other than the filters has changed (ie: page), call the fetch api directly without a debounce
        fetchAllocations();
      } else {
        // If the filters has been updated, instead of sending an api call for each click,
        // use a debounced fetch to send a single request with multiple filters
        debouncedFetch();
      }
      setLastParams(params);
    }
  }, [params]);
  // This useEffect handles the changes for the 'project' query param in the url
  useEffect(() => {
    const usersFromUrl = getQueryParameter('user')?.split(',');
    const usersFromUrlFormatted = usersFromUrl?.map((userId) => ({
      id: userId,
      label: userMap?.[userId],
    }));
    if (usersFromUrl?.length === userList?.length) {
      setSelectedUsers(usersFromUrl ? [{ id: 'All', label: 'All' }, ...usersFromUrlFormatted] : []);
    } else {
      setSelectedUsers(usersFromUrl ? usersFromUrlFormatted : []);
    }
  }, [location.search, userList, userMap]);
  // This useEffect handles the changes for the 'user' query param in the url
  useEffect(() => {
    const projectsFromUrl = getQueryParameter('project')?.split(',');
    const projectsFromUrlFormatted = projectsFromUrl?.map((projectId) => ({
      id: projectId,
      label: projectMap?.[projectId],
    }));
    if (projectsFromUrl?.length === projectList?.length) {
      setSelectedProjects(
        projectsFromUrl ? [{ id: 'All', label: 'All' }, ...projectsFromUrlFormatted] : []
      );
    } else {
      setSelectedProjects(projectsFromUrl ? projectsFromUrlFormatted : []);
    }
  }, [location.search, projectList, projectMap]);
  // Cleanup function to reduce lag
  useEffect(
    () => () => {
      dispatch(reportActions.resetAllocatedActualReport());
      dispatch(reportActions.resetAllocatedActualReportExport());
    },
    []
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 5 }} px={{ xs: 0, lg: 2 }}>
      <Grid container>
        <Grid item xs={12} lg={6} sm={12} md={6} sx={{ mb: { xs: 2 } }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
            Resources - Allocated vs Actual hours
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display="flex"
          justifyContent={{ lg: 'flex-end', md: 'flex-end' }}
        >
          <Button
            onClick={handleExport}
            disabled={loading}
            sx={{ width: 'auto', marginRight: '0.75rem' }}
          >
            <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
            Export
          </Button>
          <Button onClick={handleSync} disabled={loading} sx={{ width: 'auto' }}>
            <SyncIcon sx={{ marginLeft: '0.25rem' }} />
            Sync
          </Button>
        </Grid>
      </Grid>
      <Filters
        userList={userList}
        projectList={projectList}
        selectedUsers={selectedUsers}
        selectedProjects={selectedProjects}
        thisYear={thisYear}
        selectedYear={selectedYear}
        handleSelectedUsersChange={handleSelectedUsersChange}
        handleSelectedProjectsChange={handleSelectedProjectsChange}
        handleYearChange={handleYearChange}
        initilialize={initilialize}
      />
      {isInitialCall && (loading || debounceLoading) ? (
        <Grid container display="flex" flexDirection="row" spacing={5}>
          <Grid item xs={4}>
            <ShimmerTable row={6} col={2} />
          </Grid>
          <Grid item xs={8}>
            <ShimmerTable row={6} col={8} />
          </Grid>
        </Grid>
      ) : (
        allocations?.docs?.length > 0 && (
          <InfiniteScroll
            dataLength={allocations?.docs?.length ?? 0}
            next={fetchNextPage}
            hasMore={allocations?.hasNextPage}
            loader={
              <Box textAlign="center" margin="1.125rem">
                <CircularProgress size={30} />
              </Box>
            }
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mt: 4,
              }}
            >
              <ProjectProfileTable />
              <AllocatedActualHoursTable
                allocationsByViewPeriod={allocationsByViewPeriod}
                monthsOfSelectedYear={monthsOfSelectedYear}
              />
            </Box>
          </InfiniteScroll>
        )
      )}
      {!allocations?.docs?.length && !loading && !debounceLoading && (
        <Grid item sx={{ marginTop: 20 }} xs={12}>
          <CustomNoRowsOverlay message="No allocations found!" size />
        </Grid>
      )}
    </Container>
  );
};
//
export default AllocatedActualReport;
