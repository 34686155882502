import { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Container, Typography, Box, Avatar } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { ShimmerThumbnail, ShimmerTable } from 'react-shimmer-effects';
import { useDebouncedCallback } from 'use-debounce';
//
import {
  selectIsProjectFetching,
  selectProject,
  selectWorkAllocations,
  selectIsWorkAllocationFetching,
  selectUpdateWorkAllocationBilling,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';
import { Button, DataGrid } from 'features/base/components';
import {
  FIXED_BID_TABLE_COLUMNS,
  MONTHLY_FIXED_TABLE_COLUMNS,
  TIME_AND_MATERIAL_TABLE_COLUMNS,
  INTERNAL_TABLE_COLUMNS,
} from 'features/base/utils/tables';
import TIME_OUTS from 'features/base/constants/time-outs';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import { DATE_LANGUAGE, DATE_FORMAT, MONTHS } from 'features/base/constants/date-formatting';
import greenCalenderIcon from 'features/base/assets/images/svg/green-calender.svg';
import redCalenderIcon from 'features/base/assets/images/svg/red-calender.svg';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import ROUTES from 'features/base/constants/routes';
import { getDollarFormattedValueBilling } from 'features/base/helpers/dollar-formatter';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import { PROJECT_TYPES } from 'features/base/constants/project-types';
import { RATE_PER_HOUR_RANGES } from 'features/base/constants/amount-types';
import COLORS from 'features/base/constants/colors';
import { notificationActions } from 'features/base/notifications/slice';
import { ENVIRONMENT } from 'config';
import calculations from 'features/base/helpers/calculations';
import AllocationStepper from '../stepper';
import {
  tableRowsFixedBid,
  tableRowsMonthlyFixed,
  tableRowsNonClient,
  tableRowsTimeAndMaterial,
} from './helpers/billing';

/**
 * Table component to render work allocations fixed of a project
 * @returns {Box}
 */
const WorkAllocationFixedBidTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  //
  const getOneProject = useSelector(selectProject);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const workAllocations = useSelector(selectWorkAllocations);
  const isWorkAllocationFetching = useSelector(selectIsWorkAllocationFetching);
  const { workAllocation: workAllocationsBilling, loading: tableLoading } = useSelector(
    selectUpdateWorkAllocationBilling
  );
  const notification = useSelector(selectNotification);
  //
  const [ratePerHourRefs, setRatePerHourRefs] = useState([]);
  const [hasErrorHourlyRate, setHasErrorHourlyRate] = useState(false);
  //
  useEffect(() => {
    if (
      getOneProject?.billingType === PROJECT_BILLING_TYPES.FIXED_BID ||
      getOneProject?.type !== PROJECT_TYPES.CLIENT
    ) {
      dispatch(
        projectActions.getWorkAllocation({
          query: `pagination=false&projectIds=${projectId}&aggregated=true`,
        })
      );
    } else {
      dispatch(
        projectActions.getWorkAllocationBilling({
          query: `pagination=false&projectIds=${projectId}&aggregated=true`,
        })
      );
    }
  }, [getOneProject]);
  //
  useEffect(() => {
    dispatch(projectActions.getOneProject({ projectId }));
  }, [projectId]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.onBillingPageUnmount());
    },
    []
  );
  //
  const hasHourlyRateError = (allocations) =>
    allocations?.some(
      (allocation) =>
        allocation?.hourlyRate < RATE_PER_HOUR_RANGES.MIN_RATE_PER_HOUR ||
        allocation?.hourlyRate >= RATE_PER_HOUR_RANGES.MAX_RATE_PER_HOUR
    );
  //
  useEffect(() => {
    if (workAllocationsBilling?.docs?.length > 0) {
      const refs = [];
      const errors = workAllocationsBilling?.docs?.map((doc) => {
        refs.push(createRef());
        // check if allocation[] any.hourlyRate is below 0
        return hasHourlyRateError(doc?.monthlyAllocations);
      });
      setHasErrorHourlyRate(errors.some((error) => error));
      setRatePerHourRefs(refs);
    }
  }, [workAllocationsBilling]);
  //
  const updateHourlyRateDebounced = useDebouncedCallback((allocation, id) => {
    if (hasHourlyRateError(allocation.allocation)) {
      return;
    }
    dispatch(
      projectActions.patchProjectWorkAllocationBilling({
        ...allocation,
        allocationId: id,
      })
    );
    dispatch(
      projectActions.editProject({
        id: projectId,
        projectValue: calculations.totalRevenuePerProject(workAllocationsBilling),
      })
    );
  }, TIME_OUTS.ALLOCATION_FETCH_DEBOUNCE);
  //
  const handleUpdate = (allocationValue, entity) => {
    const newAllocation = entity?.monthlyAllocations?.map((allocationItem) =>
      //
      ({
        ...allocationItem,
        hourlyRate: Number.isNaN(Number(allocationValue)) ? 0 : Number(allocationValue),
      })
    );
    // update the entity.allocation object array in the store
    dispatch(
      projectActions.patchProjectWorkAllocationBillingInStore({
        projectId,
        userId: entity.userId.id,
        updatedAllocation: newAllocation,
      })
    );
    updateHourlyRateDebounced(
      {
        allocation: newAllocation?.map((monthlyAllocation) => ({
          year: monthlyAllocation?.year?.toString(),
          month: MONTHS[monthlyAllocation.month - 1],
          hours: monthlyAllocation?.allocatedHours,
          hourlyRate: monthlyAllocation?.hourlyRate,
        })),
      },
      entity.id
    );
  };
  //
  return (
    (workAllocations || workAllocationsBilling) && (
      <Box sx={{ width: '100%' }}>
        <Container
          disableGutters
          maxWidth="xl"
          sx={{ p: 0, height: 'fit-content', mt: 2 }}
          px={{ xs: 0, lg: 4 }}
        >
          {isProjectFetching ? (
            <Skeleton height="31px" width="200px" />
          ) : (
            <Grid container sx={{ pb: 5 }}>
              {getOneProject?.logo?.length > 0 ? (
                getOneProject.logo?.map((img) => (
                  <img
                    src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${img.url}`}
                    alt="project-logo"
                    key={img.url}
                    style={{ height: '48px', width: '48px', borderRadius: '50%' }}
                  />
                ))
              ) : (
                <Avatar sx={{ height: '48px', width: '48px' }}>
                  {getOneProject?.name?.charAt(0)}
                </Avatar>
              )}
              ,
              <Typography
                variant="h1"
                sx={{
                  fontSize: '2.25rem',
                  fontWeight: 600,
                  mb: { xs: '15px', md: 0 },
                  mr: '24px',
                  ml: '10px',
                }}
              >
                {' '}
                {getOneProject?.name}{' '}
              </Typography>
              {getOneProject.billingType && (
                <Box
                  sx={{
                    bgcolor: COLORS.CELESTIAL_BLUE,
                    minHeight: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    p: '6px 10px',
                    mr: '5px',
                  }}
                >
                  <Typography variant="body1" sx={{ color: COLORS.DODGER_BLUE }}>
                    {billingTypeFormatter(getOneProject?.billingType)}
                  </Typography>
                </Box>
              )}
              {getOneProject.billingType === PROJECT_BILLING_TYPES.FIXED_BID && (
                <Box
                  sx={{
                    bgcolor: COLORS.LAVENDER_GRAY,
                    minHeight: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    p: '6px 10px',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: COLORS.DEEP_PURPLE }}
                  >{`Project Value: ${getDollarFormattedValueBilling(
                    getOneProject.projectValue
                  )}`}</Typography>
                </Box>
              )}
              {getOneProject?.billingType === PROJECT_BILLING_TYPES.TIME_AND_MATERIAL && (
                <Box
                  sx={{
                    bgcolor: COLORS.LAVENDER_GRAY,
                    minHeight: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    p: '6px 10px',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: COLORS.DEEP_PURPLE }}
                  >{`Project Value: $${calculations.totalRevenuePerProject(
                    workAllocationsBilling
                  )}`}</Typography>
                </Box>
              )}
              {(getOneProject.billingType === PROJECT_BILLING_TYPES.RETAINER ||
                getOneProject.billingType === PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE ||
                getOneProject.billingType === PROJECT_BILLING_TYPES.STAFF_AUGMENTATION) && (
                <>
                  <Box
                    sx={{
                      bgcolor: '#e7f6e6',
                      minHeight: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      p: '6px 10px',
                      mr: '5px',
                    }}
                  >
                    <Typography variant="body1" sx={{ color: COLORS.SUCCESS_MAIN }}>
                      {`Project value of a month : $${calculations.totalRevenuePerMonth(
                        workAllocationsBilling
                      )}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: COLORS.LAVENDER_GRAY,
                      minHeight: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      p: '6px 10px',
                    }}
                  >
                    <Typography variant="body1" sx={{ color: COLORS.DEEP_PURPLE }}>
                      {`Total value of the project : $${calculations.totalRevenuePerProject(
                        workAllocationsBilling
                      )}`}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          )}
          {isWorkAllocationFetching ? (
            <Grid
              item
              sx={{
                width: '100%',
                mb: 4,
              }}
            >
              <ShimmerThumbnail height={75} />
            </Grid>
          ) : (
            <AllocationStepper activeStep={2} />
          )}
          {isProjectFetching ? (
            <Skeleton height="31px" width="200px" />
          ) : (
            <Grid container sx={{ p: 3 }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1.75rem',
                  fontWeight: 600,
                  mr: '32px',
                  ml: { xs: 0, sm: '16px' },
                  mt: { xs: '15px', md: 0 },
                }}
              >
                Billing
              </Typography>
              <Grid container justifyContent="flex-end" alignItems="center" sx={{ m: '28px 0' }}>
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontSize: '0.875rem',
                        color: COLORS.SUCCESS_MAIN,
                        fontWeight: 400,
                        mr: '32px',
                      }}
                    >
                      Start <img src={greenCalenderIcon} alt="Calender-Icon" />{' '}
                      {new Date(getOneProject.startDate).toLocaleString(
                        DATE_LANGUAGE.LANGUAGE,
                        DATE_FORMAT
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.875rem',
                        color: COLORS.SECONDARY_MAIN,
                        fontWeight: 400,
                        ml: '10px',
                      }}
                    >
                      End <img src={redCalenderIcon} alt="Calender-Icon" />{' '}
                      {new Date(getOneProject.endDate).toLocaleString(
                        DATE_LANGUAGE.LANGUAGE,
                        DATE_FORMAT
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {getOneProject.type !== PROJECT_TYPES.CLIENT &&
            (isWorkAllocationFetching ? (
              <ShimmerTable rows={3} cols={4} />
            ) : (
              <DataGrid
                serverSidePagination={false}
                rowHeight={workAllocations?.docs?.length ? 56 : 200}
                columns={INTERNAL_TABLE_COLUMNS}
                pageSizeOptions={[
                  workAllocations?.docs?.length ? workAllocations?.docs?.length : 10,
                ]}
                limit={workAllocations?.docs?.length ? workAllocations.docs.length + 1 : 10}
                rows={tableRowsNonClient(workAllocations) ?? []}
              />
            ))}
          {getOneProject.billingType === PROJECT_BILLING_TYPES.FIXED_BID &&
            (isWorkAllocationFetching ? (
              <ShimmerTable rows={3} cols={4} />
            ) : (
              <DataGrid
                serverSidePagination={false}
                rowHeight={workAllocations?.docs?.length ? 56 : 200}
                columns={FIXED_BID_TABLE_COLUMNS}
                pageSizeOptions={[
                  workAllocations?.docs?.length ? workAllocations?.docs?.length : 10,
                ]}
                limit={workAllocations?.docs?.length ? workAllocations.docs.length + 1 : 10}
                rows={tableRowsFixedBid(workAllocations, getOneProject) ?? []}
              />
            ))}
          {getOneProject.billingType === PROJECT_BILLING_TYPES.TIME_AND_MATERIAL &&
            workAllocationsBilling?.docs?.length > 0 && (
              <DataGrid
                serverSidePagination={false}
                columns={TIME_AND_MATERIAL_TABLE_COLUMNS}
                rowHeight={workAllocationsBilling?.docs?.length ? 56 : 200}
                pageSizeOptions={[
                  workAllocationsBilling?.docs?.length ? workAllocationsBilling?.docs?.length : 10,
                ]}
                limit={
                  workAllocationsBilling?.docs?.length ? workAllocationsBilling?.docs?.length : 10
                }
                rows={tableRowsTimeAndMaterial(workAllocationsBilling, handleUpdate) || []}
                loading={tableLoading}
              />
            )}
          {workAllocationsBilling?.docs?.length > 0 &&
            ratePerHourRefs?.length > 0 &&
            (getOneProject.billingType === PROJECT_BILLING_TYPES.RETAINER ||
              getOneProject.billingType === PROJECT_BILLING_TYPES.SUPPORT_AND_MAINTENANCE ||
              getOneProject.billingType === PROJECT_BILLING_TYPES.STAFF_AUGMENTATION) && (
              <DataGrid
                serverSidePagination={false}
                rowHeight={workAllocationsBilling?.docs?.length ? 56 : 200}
                columns={MONTHLY_FIXED_TABLE_COLUMNS}
                rows={
                  tableRowsMonthlyFixed(workAllocationsBilling, handleUpdate, ratePerHourRefs) || []
                }
                pageSizeOptions={[
                  workAllocationsBilling?.docs?.length ? workAllocationsBilling?.docs?.length : 10,
                ]}
                limit={
                  workAllocationsBilling?.docs?.length ? workAllocationsBilling?.docs?.length : 10
                }
                loading={tableLoading}
              />
            )}
          <Grid container direction="row-reverse" justifyContent="space-between" sx={{ p: 4 }}>
            <Button
              onClick={() => {
                navigate(ROUTES.PROJECT_WORK_ALLOCATION.replace(':projectId', projectId));
              }}
              disabled={hasErrorHourlyRate || tableLoading}
            >
              Done
            </Button>
          </Grid>
        </Container>
      </Box>
    )
  );
};
//
export default WorkAllocationFixedBidTable;
