import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectAllDepartment,
  selectDepartmentSearch,
  selectDepartmentLoader,
} from 'features/departments/selectors';
import { departmentActions } from 'features/departments/slice';
import { DataGrid } from 'features/base/components';
import EditDepartmentPopup from 'features/departments/components/edit-department';
import { notificationActions } from 'features/base/notifications/slice';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { toolActions } from 'features/tools/slice';
import { selectAllTools } from 'features/tools/selectors';
import { designationActions } from 'features/designations/slice';
import {
  DEPARTMENT_TABLE_ACTION_COLUMNS,
  DEPARTMENT_TABLE_BASIC_COLUMNS,
} from 'features/base/utils/tables';

/**
 * Table component to render departments
 * @returns {Box}
 */
const DepartmentTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  const organizationUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ORGANIZATION,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const isLoading = useSelector(selectDepartmentLoader);
  const search = useSelector(selectDepartmentSearch);
  const allDepartments = useSelector(selectAllDepartment);
  const allTools = useSelector(selectAllTools);
  //
  const [paginationData, setPaginationData] = useState({
    currentPageNumber: 1,
    resultsPerPage: 10,
    totalResults: 0,
    totalPages: 0,
  });
  const [paginatedDepartments, setPaginatedDepartments] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState('');
  //
  const isFirst = useIsInitialize(allDepartments);
  //
  useEffect(() => {
    dispatch(departmentActions.getAllDepartment());
    dispatch(toolActions.getAllTools({ query: 'pagination=false&sortBy=name:asc' }));
  }, []);
  //
  const handleEdit = (id) => {
    dispatch(
      designationActions.getAllDesignation({
        query: `pagination=false&sortBy=name:asc`,
      })
    );
    setDepartmentId(id);
    setEditModalOpen(true);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    const filteredDepartments = search
      ? allDepartments.filter((department) => {
          const isDepartmentMatching = department?.department?.name
            ?.toLowerCase()
            .includes(search.toLowerCase());
          const isDesignationMatching = department?.designations?.some((designation) =>
            designation?.name?.toLowerCase().includes(search.toLowerCase())
          );
          return isDepartmentMatching || isDesignationMatching;
        })
      : allDepartments;
    setPaginatedDepartments(filteredDepartments);
  }, [allDepartments, search]);
  //
  return (
    <>
      <EditDepartmentPopup
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        departmentId={departmentId}
      />
      <Box>
        {isFirst ? (
          <ShimmerTable row={5} col={4} />
        ) : (
          <DataGrid
            columns={
              organizationUpdatePermissionsVerified
                ? [...DEPARTMENT_TABLE_BASIC_COLUMNS, ...DEPARTMENT_TABLE_ACTION_COLUMNS]
                : DEPARTMENT_TABLE_BASIC_COLUMNS
            }
            rows={
              paginatedDepartments &&
              paginatedDepartments.map((department) => ({
                ...department?.department,
                name: {
                  value: department?.department?.name,
                },
                designation: {
                  value: [
                    ...new Set(department?.designations?.map((designation) => designation.name)),
                  ].join(', '),
                },
                tools: {
                  value: [
                    ...new Set(
                      allTools?.docs
                        ?.filter((tool) =>
                          tool.departments?.some(
                            (dep) => dep?.departmentId?.id === department?.department?.id
                          )
                        )
                        .map((tool) => tool.name)
                    ),
                  ].join(', '),
                },
                action: {
                  organizationUpdatePermissionsVerified,
                  isLoading,
                  handleEdit: (event) => {
                    event.stopPropagation();
                    handleEdit(department?.department?.id);
                  },
                },
              }))
            }
            paginationMode="client"
            handleChangePage={({ page }) => {
              setPaginationData({ ...paginationData, currentPageNumber: page + 1 });
            }}
            page={paginationData.currentPageNumber - 1 ?? 0}
            limit={paginationData.resultsPerPage ?? 10}
            loading={isLoading && isFirst !== true}
            pageSizeOptions={[paginationData.resultsPerPage ?? 10]}
            cellSx={{ p: 1 }}
            rowHeight={paginatedDepartments?.length ? 80 : 200}
          />
        )}
      </Box>
    </>
  );
};
//
export default DepartmentTable;
