import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import COLORS from 'features/base/constants/colors';
/**
 * Component that renders the daily worklog table.
 * @param {Object<{[key:string]:[value:string]}>} groupedDates; key is in YYYY-MM format value is an array of dates
 * @param {Array<Date>} dates
 * @param {Array<Object>} loggedHoursPerAllocation - An object array containing all required data to render the hours logged table
 * @returns MUI Table with the daily worklog data
 */
const DailyWorklogTable = ({ groupedDates, dates, loggedHoursPerAllocation }) => (
  <TableContainer component={Paper} className="wrapper">
    <Table>
      <TableHead sx={{ height: 105 }}>
        <TableRow>
          {Object.keys(groupedDates)?.map((month) => (
            <TableCell
              size="small"
              key={month}
              colSpan={groupedDates[month].length}
              sx={{
                bgcolor: COLORS.SKY_BLUE,
                border: `2px solid ${COLORS.DARK_SAPPHIRE}`,
                height: 30,
              }}
            >
              <Typography fontWeight="bold" textAlign="center">
                {moment(month).format('MMMM YYYY')}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {dates?.map((date) => {
            const dayName = moment(date).format('dd');
            const dayNumber = moment(date).format('D');
            return (
              <TableCell
                size="small"
                key={date}
                sx={{
                  bgcolor:
                    dayName === 'Sa' || dayName === 'Su'
                      ? COLORS.SKY_BLUE
                      : `${COLORS.SUCCESS_MAIN}06`,
                  border: 'none',
                }}
              >
                <Typography textAlign="center">{dayNumber}</Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  textAlign="center"
                  color={COLORS.SILVER_LILAC}
                >
                  {dayName}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {loggedHoursPerAllocation?.map((item) => (
          <TableRow key={`${item.projectId.name}-${item.userId.email}`}>
            {dates?.map((currentDate) => {
              const hoursLogged = item[currentDate];
              //
              const emptyColor = hoursLogged > 0 ? `${COLORS.SUCCESS_MAIN}06` : COLORS.LIGHT_RED;
              const isWeekend = moment(currentDate).day() === 6 || moment(currentDate).day() === 0;
              return (
                <TableCell
                  size="small"
                  key={currentDate}
                  sx={{
                    bgcolor: isWeekend ? COLORS.SKY_BLUE : emptyColor,
                    height: 68,
                    borderBlock: `2px solid ${COLORS.WHITE}`,
                  }}
                >
                  {!isWeekend && <Typography textAlign="center">{hoursLogged}h</Typography>}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
        <TableRow>
          {dates?.map((currentDate) => {
            const momentDate = moment(currentDate);
            const isWeekend = momentDate.day() === 6 || momentDate.day() === 0;
            const total = loggedHoursPerAllocation
              ?.reduce((accumulator, item) => accumulator + (item?.[currentDate] ?? 0), 0)
              .toFixed(2);
            return (
              <TableCell
                size="small"
                key={currentDate}
                sx={{
                  bgcolor: COLORS.SKY_BLUE,
                  height: 68,
                  borderBlock: `4px solid ${COLORS.WHITE}`,
                }}
              >
                {!isWeekend && <Typography textAlign="center">{total}h</Typography>}
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
//
export default DailyWorklogTable;
