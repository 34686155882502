import { DeleteForever, DriveFileRenameOutline } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
/**
 * View skill groups table columns - basic details
 */
export const SKILLS_GROUP_TABLE_BASIC_COLUMNS = [
  {
    field: 'name',
    headerName: 'Group name',
    width: 300,
    valueGetter: (params) => params?.row?.name,
    renderCell: (params) => <Box>{params?.row?.name}</Box>,
  },
  {
    field: 'skills',
    headerName: 'Skills',
    width: 1000,
    valueGetter: (params) => params?.row?.skills?.map((skill) => skill?.name).join(', '),
    renderCell: (params) => (
      <Box>{params?.row?.skills?.map((skill) => skill?.name).join(', ')}</Box>
    ),
  },
];
/**
 * View skill groups table columns - action columns
 */
export const SKILL_GROUP_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: ({
      value: {
        skillUpdatePermissionsVerified,
        skillDeletePermissionsVerified,
        isLoading,
        handleSkillGroupEdit,
        handleSkillGroupDelete,
      },
    }) => (
      <Grid container marginLeft={-1}>
        <Grid sx={{ display: 'flex' }}>
          {skillUpdatePermissionsVerified && (
            <IconButton onClick={handleSkillGroupEdit} disabled={isLoading} size="small">
              <DriveFileRenameOutline size="small" />
            </IconButton>
          )}
          {skillDeletePermissionsVerified && (
            <IconButton
              onClick={handleSkillGroupDelete}
              disabled={isLoading}
              size="small"
              style={{ marginLeft: '-10px' }}
            >
              <DeleteForever size="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    ),
  },
];
