import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';
//
const ProfileTable = ({ filteredAllocations }) => (
  <Table sx={{ maxWidth: 280, width: 280 }}>
    <TableHead>
      <TableRow>
        <TableCell
          size="small"
          rowSpan={2}
          sx={{
            height: 62,
            bgcolor: COLORS.SKY_BLUE,
            borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
            borderInline: `2px solid ${COLORS.WHITE}`,
          }}
        >
          <Typography>Profile</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredAllocations?.map((currentAllocation, index) => (
        <TableRow key={`${currentAllocation?.id}`}>
          <TableCell
            size="small"
            sx={{
              borderBottom: `2px solid ${COLORS.WHITE}`,
              bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
              display: 'flex',
            }}
          >
            <Box
              sx={{
                height: 68,
                maxHeight: 68,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                textAlign={'left'}
                size="small"
                sx={{
                  fontSize: 16,
                }}
              >
                {currentAllocation?.userId?.firstName} {currentAllocation?.userId?.lastName}
              </Typography>
              <Typography
                textAlign={'left'}
                size="small"
                variant="body2"
                sx={{
                  fontSize: 14,
                  color: COLORS.SILVER_LILAC,
                }}
              >
                {
                  currentAllocation.userId?.currentUserDepartmentDesignationId
                    ?.departmentDesignationId?.designationId?.name
                }
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
//
export default ProfileTable;
