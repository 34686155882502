/**
 * Defines the API paths for skills
 */
const API = {
  POST_SKILLS: {
    path: '/skills',
    method: 'POST',
  },
  GET_SKILLS: {
    path: '/skills?:query',
    method: 'GET',
  },
  PATCH_SKILL: {
    path: '/skills/:id',
    method: 'PATCH',
  },
  DELETE_SKILL: {
    path: '/skills/:id',
    method: 'DELETE',
  },
  POST_SKILL_GROUP: {
    path: '/skill-groups',
    method: 'POST',
  },
  GET_SKILL_GROUPS: {
    path: '/skill-groups?:query',
    method: 'GET',
  },
  PATCH_SKILL_GROUP: {
    path: '/skill-groups/:id',
    method: 'PATCH',
  },
  DELETE_SKILL_GROUP: {
    path: '/skill-groups/:id',
    method: 'DELETE',
  },
};
//
export default API;
