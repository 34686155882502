import { useEffect, useState } from 'react';
import { generatePath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Typography, Avatar, Grid } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { ShimmerThumbnail } from 'react-shimmer-effects';
//
import { Button, TabBar } from 'features/base/components';
import { projectActions } from 'features/projects/slice';
import { selectIsProjectFetching, selectProject } from 'features/projects/selectors';
import {
  PROJECT_ALLOCATION_NESTED_ROUTE,
  PROJECT_REPOSITORIES_NESTED_ROUTE,
  PROJECT_EXTERNAL_MEMBERS_NESTED_ROUTE,
  PROJECT_DEPLOYMENTS_NESTED_ROUTE,
  PROJECT_LOGGED_HOURS_NESTED_ROUTE,
  PROJECT_DOCUMENTS_NESTED_ROUTE,
  PROJECT_CLIENT_SUPPORT_TICKETS_NESTED_ROUTE,
  PROJECT_GANTT_CHART_NESTED_ROUTE,
} from 'features/base/constants/dashboard-tab-routes';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import { PROJECT_TYPES } from 'features/base/constants/project-types';
import { PROJECT_BILLING_TYPES } from 'features/base/constants/project-billing-types';
import greenCalenderIcon from 'features/base/assets/images/svg/green-calender.svg';
import redCalenderIcon from 'features/base/assets/images/svg/red-calender.svg';
import ROUTES from 'features/base/constants/routes';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { getDollarFormattedValue } from 'features/base/helpers/dollar-formatter';
import billingTypeFormatter from 'features/base/helpers/billing-type-formatter';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import COLORS from 'features/base/constants/colors';
import { ENVIRONMENT } from 'config';
import createFormattedString from 'features/base/helpers/param-formatter';
import AddDocumentsPopup from '../documents/add-documents-modal';
import './index.scss';

/**
 * Layout component for project dashboard
 * @returns {Box}
 */
const ProjectDashboardLayout = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const allocationViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const repositoriesViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.REPOSITORY,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const externalMembersViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT_EXTERNAL_USERS,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const deploymentsViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.DEPLOYMENT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const projectViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const supportTicketsViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TICKET_HANDLING,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  //
  const projectDetails = useSelector(selectProject);
  const isProjectFetching = useSelector(selectIsProjectFetching);
  const notification = useSelector(selectNotification);
  //
  const getNestedRoutes = () => {
    const nestedRoutes = [];
    if (allocationViewPermissionsVerified) {
      nestedRoutes.push(PROJECT_ALLOCATION_NESTED_ROUTE);
    }
    if (externalMembersViewPermissionsVerified && projectDetails?.type === PROJECT_TYPES.CLIENT) {
      nestedRoutes.push(PROJECT_EXTERNAL_MEMBERS_NESTED_ROUTE);
    }
    if (repositoriesViewPermissionsVerified) {
      nestedRoutes.push(PROJECT_REPOSITORIES_NESTED_ROUTE);
    }
    if (deploymentsViewPermissionsVerified) {
      nestedRoutes.push(PROJECT_DEPLOYMENTS_NESTED_ROUTE);
    }
    if (projectViewPermissionsVerified) {
      nestedRoutes.push(PROJECT_GANTT_CHART_NESTED_ROUTE);
    }
    if (supportTicketsViewPermissionsVerified) {
      nestedRoutes.push(PROJECT_CLIENT_SUPPORT_TICKETS_NESTED_ROUTE);
    }
    nestedRoutes.push(PROJECT_LOGGED_HOURS_NESTED_ROUTE);
    nestedRoutes.push(PROJECT_DOCUMENTS_NESTED_ROUTE);
    return nestedRoutes;
  };
  const NESTED_ROUTES = getNestedRoutes().filter(
    (route) => route.name !== 'Deployments' && route.name !== 'Repositories'
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      dispatch(notificationActions.resetNotification());
    }
    if (
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.ERROR &&
      !notification?.fixed
    ) {
      toast(notification?.message, { type: TOAST_TYPES.ERROR });
      dispatch(notificationActions.resetNotification());
    }
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  //
  useEffect(() => {
    dispatch(projectActions.getOneProject({ projectId }));
    return () => {
      dispatch(notificationActions.resetNotification());
    };
  }, []);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.onProjectViewUnmount());
    },
    []
  );
  //
  const [pathName, setPathName] = useState(NESTED_ROUTES[0].path);
  const [addDocumentsPopupOpen, setAddDocumentsPopupOpen] = useState(false);
  //
  useEffect(() => {
    const URLPath = location.pathname.split('/')[3];
    setPathName(URLPath);
  }, [location.pathname]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.onProjectViewUnmount());
    },
    []
  );
  //
  const renderButtons = () => {
    switch (pathName) {
      case PROJECT_ALLOCATION_NESTED_ROUTE?.path:
        return (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
                  action: PERMISSION_ACTIONS.READ,
                },
              ]}
              hide
            >
              <Button
                sx={{ mr: { sm: '12px' } }}
                onClick={() =>
                  navigate(
                    `${ROUTES.ALLOCATION_REPORT}?${createFormattedString({ project: projectId })}`
                  )
                }
              >
                Allocation report
              </Button>
            </PermissionWrapper>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.PROJECT_ALLOCATION,
                  action: PERMISSION_ACTIONS.CREATE,
                },
              ]}
              hide
            >
              <Button
                sx={{ width: { xs: '100%', md: 'auto' }, whiteSpace: 'nowrap', mr: '0.5rem' }}
                onClick={() =>
                  navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION_UPDATE, { projectId }))
                }
              >
                Update Allocations <EditIcon />
              </Button>
              <Button
                sx={{ width: { xs: '100%', md: 'auto' }, whiteSpace: 'nowrap' }}
                onClick={() =>
                  navigate(generatePath(ROUTES.PROJECT_WORK_ALLOCATION_CREATE, { projectId }))
                }
              >
                Add Members <AddIcon />
              </Button>
            </PermissionWrapper>
          </Box>
        );
      case PROJECT_DOCUMENTS_NESTED_ROUTE?.path:
        return (
          <>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
              <Button sx={{ mr: { sm: '12px' } }} onClick={() => setAddDocumentsPopupOpen(true)}>
                Add documents
              </Button>
            </Box>
            <AddDocumentsPopup open={addDocumentsPopupOpen} setOpen={setAddDocumentsPopupOpen} />
          </>
        );
      case PROJECT_CLIENT_SUPPORT_TICKETS_NESTED_ROUTE.path:
        return (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button sx={{ mr: { sm: '12px' } }} onClick={() => navigate(ROUTES.SUPPORT_TICKETS)}>
              Manage Support Tickets
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'flex-start', mt: 'center' },
            }}
          >
            {projectDetails?.logo?.length > 0 ? (
              <img
                src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${projectDetails.logo[0].url}`}
                alt="project-logo"
                style={{ height: '48px', width: '48px', borderRadius: '50%', marginRight: '16px' }}
              />
            ) : (
              <Avatar sx={{ height: '48px', width: '48px', marginRight: '16px' }}>
                {projectDetails?.name?.charAt(0)}
              </Avatar>
            )}
            {isProjectFetching ? (
              <Skeleton height="31px" width="200px" />
            ) : (
              <Typography
                variant="h1"
                sx={{
                  maxWidth: '30ch',
                  fontSize: '1.938rem',
                  fontWeight: 600,
                  mr: '10px',
                  textTransform: 'uppercase',
                  wordWrap: 'break-word',
                }}
              >
                {projectDetails?.name}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                mt: { xs: '16px', sm: '0px' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {projectDetails?.type && (
                <Box
                  sx={{
                    bgcolor: COLORS.LUMINOUS_COBALT,
                    p: '7px 7px',
                    mr: { xs: 0, sm: '16px' },
                    mb: { xs: '16px', sm: 0 },
                  }}
                >
                  <Typography
                    sx={{ fontSize: '0.875rem', color: COLORS.DODGER_BLUE, fontWeight: 300 }}
                  >
                    {projectDetails?.type}
                  </Typography>
                </Box>
              )}
              {projectDetails?.type === PROJECT_TYPES.CLIENT && (
                <Box
                  sx={{
                    bgcolor: COLORS.LAVENDER_GRAY,
                    p: '7px 7px',
                    mr: { xs: 0, sm: '16px' },
                    mb: { xs: '16px', sm: 0 },
                  }}
                >
                  <Typography
                    sx={{ fontSize: '0.875rem', color: COLORS.DODGER_BLUE, fontWeight: 300 }}
                  >
                    {billingTypeFormatter(projectDetails?.billingType)}
                  </Typography>
                </Box>
              )}
              {projectDetails?.billingType === PROJECT_BILLING_TYPES.FIXED_BID && (
                <Box display="flex" sx={{ bgcolor: COLORS.LILAC_MIST, p: '7px 7px' }}>
                  <Typography
                    sx={{ fontSize: '0.875rem', color: COLORS.PURPLE_SHADE, fontWeight: 300 }}
                  >
                    Project Value:&nbsp;
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem', color: COLORS.PURPLE_SHADE, fontWeight: 600 }}
                  >
                    {getDollarFormattedValue(projectDetails?.projectValue)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            {isProjectFetching ? (
              <Skeleton height="70px" width="300px" style={{ margin: '21px 0px' }} />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: COLORS.GRAYISH_PURPLE,
                  m: '21px 0px',
                }}
              >
                {`${projectDetails?.description}\n`}
              </Typography>
            )}
          </Box>
        </Box>
        {renderButtons()}
      </Box>
      <Grid container mt={2} spacing={2} sx={{ display: 'flex', alignContent: 'center' }}>
        {projectDetails?.tools?.length > 0 && (
          <Grid item xs={2} lg={2} sm sx={{ display: 'flex' }}>
            {projectDetails.tools?.map((tool) => {
              if (tool?.logo?.length > 0) {
                return (
                  <img
                    src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${tool.logo[0].url}`}
                    alt="project-logo"
                    style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      marginRight: '5px',
                    }}
                  />
                );
              }
              return (
                <Avatar sx={{ height: '20px', width: '20px', marginRight: '5px' }} key={tool?.id}>
                  {tool?.name?.charAt(0)}
                </Avatar>
              );
            })}
          </Grid>
        )}
        {projectDetails?.jiraKey && (
          <Grid
            item
            xs={1}
            lg={1}
            sm
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mb: { xs: '16px', sm: 0 },
            }}
          >
            <Box
              sx={{
                bgcolor: COLORS.PALE_GREEN,
                display: 'block',
                justifyContent: 'flex-end',
                p: '7px 7px',
                mb: { xs: '16px', sm: 0 },
                mr: '15px',
              }}
            >
              <Typography
                sx={{
                  width: '80px',
                  fontSize: '0.875rem',
                  color: COLORS.SUCCESS_MAIN,
                  fontWeight: 300,
                }}
              >
                Jira Enable
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: COLORS.LILAC_MIST,
                display: 'block',
                justifyContent: 'flex-end',
                p: '7px 7px',
                mb: { xs: '16px', sm: 0 },
                mr: '15px',
              }}
            >
              <Typography
                sx={{
                  paddingLeft: '3px',
                  minWidth: '200px',
                  fontSize: '0.875rem',
                  color: COLORS.DEEP_PURPLE,
                  fontWeight: 300,
                }}
              >
                {`${projectDetails?.jiraAssignee?.firstName} `}
                {`${projectDetails?.jiraAssignee?.lastName}`}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: '#ffebec',
                display: 'block',
                justifyContent: 'flex-end',
                p: '7px 7px',
                mb: { xs: '16px', sm: 0 },
                mr: '15px',
              }}
            >
              <Typography
                sx={{
                  maxWidth: '1000px',
                  fontSize: '0.875rem',
                  color: COLORS.SECONDARY_MAIN,
                  fontWeight: 300,
                }}
              >
                {`${projectDetails?.jiraKey}`}
              </Typography>
            </Box>
          </Grid>
        )}
        {isProjectFetching ? (
          <Grid
            item
            xs={9}
            lg={9}
            sm
            sx={{
              width: '100%',
              maxWidth: '350px',
            }}
          >
            <ShimmerThumbnail height={15} className="shimmer-thumbnail-no-margin-bottom" />
          </Grid>
        ) : (
          <Grid
            item
            xs={9}
            lg
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.15rem',
                color: COLORS.SUCCESS_MAIN,
                fontWeight: 400,
                ml: 1,
                px: 2.5,
              }}
            >
              Start <img src={greenCalenderIcon} alt="Calender-Icon" />{' '}
              {new Date(projectDetails?.startDate).toLocaleString('en-UK', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.15rem',
                color: COLORS.SECONDARY_MAIN,
                fontWeight: 400,
                ml: '10px',
                pl: 2.5,
              }}
            >
              End <img src={redCalenderIcon} alt="Calender-Icon" />{' '}
              {new Date(projectDetails?.endDate).toLocaleString('en-UK', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box sx={{ mt: '24px', mb: '48px' }}>
        {isProjectFetching ? (
          <ShimmerThumbnail height={60} className="shimmer-thumbnail-no-margin-bottom" />
        ) : (
          <TabBar nestedRoutes={NESTED_ROUTES} pathName={pathName} />
        )}
      </Box>
      <Outlet context={{ projectId }} />
    </Container>
  );
};
//
export default ProjectDashboardLayout;
