import { Alert, Grid, AlertTitle, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import { Button, DragAndDropZone } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import Popup from 'features/base/components/modal';
import {
  selectBulkUserDataUsers,
  selectBulkUserDataFileLoader,
  selectBulkUserDataUsersLoader,
} from 'features/users/selectors';
import { userActions } from 'features/users/slice';
import { downloadCSVFile, processFiles } from 'features/base/helpers/file';
import {
  BULK_USER_DATA_UPLOAD_ALLOWED_FILE_TYPES,
  BULK_USER_DATA_UPLOAD_ALLOWED_EXTENSIONS,
  BULK_USER_DATA_UPLOAD_MAX_FILE_SIZE,
} from 'features/base/constants/file-upload';
import { FIREBASE_NOTIFICATION_TYPES } from 'features/base/constants/firebase-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { useFirebaseNotifications } from 'features/base/hooks/use-firebase-firestore';
/**
 * Function that defines the popup form for bulk user data processing
 * @prop {Boolean} bulkUserDataProcessModalOpen - Boolean to show/hide the popup
 * @prop {Function} setBulkUserDataProcessModalOpen - Function to set the popup state
 * @returns {Popup}
 */
const BulkUserDataProcessPopup = ({
  bulkUserDataProcessModalOpen,
  setBulkUserDataProcessModalOpen,
}) => {
  //
  const notifications = useFirebaseNotifications();
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const isUploading = useSelector(selectBulkUserDataFileLoader);
  const users = useSelector(selectBulkUserDataUsers);
  const isUsersLoading = useSelector(selectBulkUserDataUsersLoader);
  //
  const [bulkUserDataFile, setBulkUserDataFile] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  const [touched, setTouched] = useState(false);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  //
  const handleDownloadUserDataFile = (isExistingUsers) => {
    if (isExistingUsers) {
      const data = users.map((user) => ({
        Email: user?.email || '',
        Level: user?.level || '',
        'Date of Appointment': user?.joinedDate?.split('T')?.[0] || '',
        'Work Area': user?.workAreaId?.name || '',
      }));
      downloadCSVFile(Object.keys(data?.[0]), data, 'bulk-user-data.csv');
    } else {
      downloadCSVFile(
        ['Email', 'Level', 'Date of Appointment', 'Work Area'],
        [],
        'bulk-user-data-template.csv'
      );
    }
  };
  //
  const handleSubmit = () => {
    setTouched(true);
    if (bulkUserDataFile?.length > 0) {
      const formData = processFiles(bulkUserDataFile);
      dispatch(userActions.uploadBulkUserDataFile({ files: formData }));
    }
  };
  //
  const handleOnClose = () => {
    setBulkUserDataProcessModalOpen(false);
    setTouched(false);
    setFileTypeError('');
    setBulkUserDataFile([]);
    setIsUploadSuccess(false);
    setReceivedNotifications([]);
  };
  //
  useEffect(() => {
    if (
      setBulkUserDataProcessModalOpen &&
      receivedNotifications?.length === 0 &&
      notification?.isEnabled &&
      (notification?.type === ERROR_TYPES.SUCCESS || notification?.type === ERROR_TYPES.INFO)
    ) {
      setIsUploadSuccess(true);
      setReceivedNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          title: 'Bulk User Data File Processing Progress',
          message: 'Processing will begin shortly. You will be notified of the progress...',
          type: FIREBASE_NOTIFICATION_TYPES.INFO,
          timestamp: new Date().toISOString(),
        },
      ]);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!bulkUserDataProcessModalOpen) {
      return;
    }
    if (notifications?.length > receivedNotifications?.length && isUploadSuccess) {
      setReceivedNotifications((prevNotifications) => [
        ...prevNotifications,
        notifications[notifications.length - 1],
      ]);
    }
  }, [notifications?.length]);
  //
  useEffect(
    () => () => {
      setReceivedNotifications([]);
    },
    []
  );
  //
  return (
    <Popup
      open={bulkUserDataProcessModalOpen}
      title="Upload Bulk User Data"
      onClose={handleOnClose}
      maxWidth="lg"
      fullWidth
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 2, flexDirection: { xs: 'column', sm: 'row' } }}
        columnSpacing={2}
      >
        <Box mt={1}>
          <Button
            sx={{
              width: { xs: '100%', md: '260px' },
              mb: { xs: '16px', md: '0px' },
              mr: { xs: 0, md: '24px' },
              whiteSpace: 'nowrap',
            }}
            onClick={() => handleDownloadUserDataFile(false)}
          >
            Download Empty Template
          </Button>
          <Button
            sx={{
              width: { xs: '100%', md: '260px' },
              mb: { xs: '16px', md: '0px' },
              mr: { xs: 0, md: '24px' },
              whiteSpace: 'nowrap',
            }}
            disabled={users?.length <= 0 || isUploading}
            onClick={() => handleDownloadUserDataFile(true)}
            loading={isUsersLoading}
          >
            Download Existing Users
          </Button>
        </Box>
      </Grid>

      <form>
        {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
          <Alert sx={{ mb: 3 }} severity={notification?.type}>
            {notification?.message}
          </Alert>
        )}
        <Grid>
          {receivedNotifications?.length > 0 &&
            receivedNotifications.map((content) => (
              <Alert
                sx={{ mb: 3 }}
                severity={content?.type?.toLowerCase() || 'info'}
                key={`${content?.title}-${content?.timestamp}`}
              >
                <AlertTitle>{`${content?.title} - ${new Date(
                  content?.timestamp
                ).toLocaleString()}`}</AlertTitle>
                <strong>{content?.message}</strong>
              </Alert>
            ))}
          <DragAndDropZone
            singleFile
            allowedExtensions={BULK_USER_DATA_UPLOAD_ALLOWED_EXTENSIONS}
            allowedMimetypes={BULK_USER_DATA_UPLOAD_ALLOWED_FILE_TYPES}
            maxSize={BULK_USER_DATA_UPLOAD_MAX_FILE_SIZE}
            error={fileTypeError}
            onAdd={(filesToAdd) => {
              const renamedFiles = filesToAdd.map((file) => {
                const newName = file.name.replace(/\s+/g, '_');
                // Create a new file with the updated name and same content
                return new File([file], newName, {
                  type: file.type,
                  lastModified: file.lastModified,
                });
              });
              setReceivedNotifications([]);
              setBulkUserDataFile(renamedFiles);
            }}
            onDelete={() => setBulkUserDataFile([])}
            uploadedFiles={bulkUserDataFile}
            disabled={isUploading}
            errorMsg={touched && bulkUserDataFile?.length <= 0 && 'Please select a file'}
          />
        </Grid>
        <ButtonGrid
          leftButtonText="Cancel"
          rightButtonText={isUploading ? 'Submitting' : 'Submit'}
          leftOnClick={handleOnClose}
          rightOnClick={handleSubmit}
          rightIcon={isUploading ? loaderIcon : null}
          submitDisabled={isUploading}
          rightButtonType="button"
        />
      </form>
    </Popup>
  );
};
//
export default BulkUserDataProcessPopup;
