import { useEffect } from 'react';
import { Alert, Button } from '@mui/material';
import { FieldArray, Formik } from 'formik';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
//
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import TextField from 'features/base/components/text-field';
import CloseIcon from 'features/base/assets/images/svg/close-icon.svg';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { skillActions } from 'features/skills/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectAddSkillLoader } from 'features/skills/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { addSkillValidation } from 'features/skills/validation/skills-validation';
import './index.scss';

/**
 * Function that defines the form for adding new skills
 * @returns {Form}
 * */
const AddSkillsForm = () => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddSkillLoader);
  //
  const handleOnClose = () => {
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <Formik
      initialValues={{
        skills: [{ name: '' }],
      }}
      validationSchema={addSkillValidation}
      onSubmit={(values, { resetForm }) => {
        dispatch(skillActions.addSkills(values));
        resetForm();
      }}
    >
      {({ errors, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} className="add-skills-form">
          {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
            <Alert sx={{ mb: 3 }} severity={notification?.type}>
              {notification?.message}
            </Alert>
          )}
          <FieldArray name="skills">
            {({ remove, push }) => (
              <div>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Button type="button" onClick={() => push({ name: '' })}>
                    + Add
                  </Button>
                </Box>
                {errors.skills &&
                  values.skills?.length === 1 &&
                  touched.skills?.some((skill) => skill.name) && (
                    <Alert severity="error">Add at least one skill to save the skill group</Alert>
                  )}
                {errors.skills &&
                  values.skills?.some((skill) => !skill.name) &&
                  values.skills?.length > 1 &&
                  values.skills?.length === touched.skills?.length && (
                    <Alert severity="error">
                      Please fill all the skill fields or remove unnecessary ones
                    </Alert>
                  )}
                {notification?.isEnabled && notification?.type === ERROR_TYPES.WARNING && (
                  <Alert severity={notification?.type}>{notification?.message}</Alert>
                )}
                {values?.skills?.length > 0 &&
                  values?.skills?.map((_, index) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      key={`key${index.toString()}`}
                    >
                      <TextField
                        type="text"
                        name={`skills.${index}.name`}
                        value={values.skills[index].name}
                        onChange={handleChange}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        placeholder={`Skill ${index + 1}`}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              disabled={index < 1}
                              onClick={() => remove(index)}
                              sx={{
                                padding: '20px',
                                width: '22px',
                                height: '20px',
                                margin: '4px',
                                opacity: index < 1 ? 0.3 : 1,
                              }}
                            >
                              <img src={CloseIcon} alt="close-icon" />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                  ))}
              </div>
            )}
          </FieldArray>
          <ButtonGrid
            leftButtonText="Cancel"
            rightButtonText={loading ? 'Submitting' : 'Submit'}
            leftOnClick={handleOnClose}
            rightOnClick={null}
            rightIcon={loading ? loaderIcon : null}
            submitDisabled={loading}
          />
        </form>
      )}
    </Formik>
  );
};
//
export default AddSkillsForm;
