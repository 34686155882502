/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAuth, signInWithCustomToken } from "firebase/auth";
//
import request from 'features/base/utils/request';
import { signInActions } from 'features/sign-in/slice';
import API from './constants';
/**
 * Generator function for sending the sign in user details
 * @param {Object} payload
 */
export function* signInGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SIGN_IN, payload);
    const firebaseAuth = getAuth();
    yield call(signInWithCustomToken, firebaseAuth, response?.tokens?.firebaseAuthToken)
    yield put(signInActions.signInSucceeded(response));
  } catch (error) {
    yield put(signInActions.signInFailed(error?.message));
  }
}
/**
 * Redux saga that triggers and signIn functions
 */
export function* signInSaga() {
  yield takeLatest(signInActions.signIn.type, signInGenerator);
}
//
export default signInSaga;
