/**
 * Define route for project work allocation
 */
export const PROJECT_ALLOCATION_NESTED_ROUTE = {
  name: 'Work allocation',
  path: 'work-allocation',
};
/**
 * Define route for project work allocation
 */
export const PROJECT_GANTT_CHART_NESTED_ROUTE = {
  name: 'Gantt Chart',
  path: 'gantt-chart',
};
/**
 * Define route for project repositories
 */
export const PROJECT_REPOSITORIES_NESTED_ROUTE = {
  name: 'Repositories',
  path: 'repositories',
};
/**
 * Define route for project external members
 */
export const PROJECT_EXTERNAL_MEMBERS_NESTED_ROUTE = {
  name: 'External Members',
  path: 'external-members',
};
/**
 * Define route for project logged hours
 */
export const PROJECT_LOGGED_HOURS_NESTED_ROUTE = {
  name: 'Logged Hours',
  path: 'logged-hours',
};
/**
 * Define route for project logged hours
 */
export const PROJECT_DOCUMENTS_NESTED_ROUTE = {
  name: 'Documents',
  path: 'documents',
};
/**
 * Define route for project client support tickets
 */
export const PROJECT_CLIENT_SUPPORT_TICKETS_NESTED_ROUTE = {
  name: 'Support Tickets',
  path: 'support-tickets',
};
/**
 * Define route for project deployments
 */
export const PROJECT_DEPLOYMENTS_NESTED_ROUTE = {
  name: 'Deployments',
  path: 'deployments',
};
/**
 * Define route for client dashboard
 */
export const CLIENT_USERS_NESTED_ROUTE = {
  name: 'Users',
  path: 'users',
};
/**
 * Define route for client projects
 */
export const CLIENT_PROJECTS_NESTED_ROUTE = {
  name: 'Projects',
  path: 'projects',
};
/**
 * Define route for skill dashboard
 */
export const SKILLS_NESTED_ROUTE = {
  name: 'Skills',
  path: 'skills',
};
/**
 * Define route for skill group dashboard
 */
export const SKILL_GROUPS_NESTED_ROUTE = {
  name: 'Skill Groups',
  path: 'skill-groups',
};
/**
 * Define routes for profile allcoation
 */
export const PROFILE_ALLOCATIONS_NESTED_ROUTE = {
  name: 'Allocation',
  path: 'allocations',
};
/**
 * Define routes for profile skills
 */
export const PROFILE_SKILLS_NESTED_ROUTE = {
  name: 'Skills',
  path: 'skills',
};
