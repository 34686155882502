import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';

const DepartmentTable = ({ salariesByViewPeriod }) => (
  <Table sx={{ maxWidth: 432, width: 432 }}>
    <TableHead>
      <TableRow>
        <TableCell
          size="small"
          rowSpan={2}
          sx={{
            height: 80,
            bgcolor: COLORS.SKY_BLUE,
            borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
            borderInline: `2px solid ${COLORS.WHITE}`,
          }}
        >
          <Typography>Department</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {salariesByViewPeriod?.map((department) => {
        const dep = department[0];
        const users = department?.slice(1, department?.length);
        return (
          <>
            <TableRow key={dep?.id}>
              <TableCell
                size="small"
                sx={{
                  borderBottom: `2px solid ${COLORS.WHITE}`,
                  bgcolor: COLORS.PALE_LILAC,
                  display: 'flex',
                  p: 1,
                  m: 0,
                }}
              >
                <Typography size="small">{dep?.id}</Typography>
              </TableCell>
            </TableRow>
            {users?.map((user) => (
              <TableRow key={user?.id}>
                <TableCell
                  size="small"
                  sx={{
                    borderInline: `2px solid ${COLORS.WHITE}`,
                    bgcolor: COLORS.WHITE,
                    display: 'flex',
                    textAlign: 'left',
                    p: 1,
                    m: 0,
                  }}
                >
                  <Typography size="small">
                    {user?.firstName} {user?.lastName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        );
      })}
    </TableBody>
  </Table>
);

export default DepartmentTable;
