/**
 * Available sign in error types
 * @type {{INTERNAL: string, CLIENT: string, INTERN: string, CONTRACT: string}}
 */
export const ERROR_CODES = {
  UNAUTHORIZED: '401',
};
/**
 * Available sign in error messages
 * @default { NO_ACCESS_LONG: "Unfortunately, you don't have permission to access this resource :(", NO_ACCESS_SHORT: 'No access', PASSWORD_NOT_CREATED: 'Password not created' }
 */
export const ERROR_MESSAGES = {
  NO_ACCESS_LONG: "Unfortunately, you don't have permission to access this resource :(",
  NO_ACCESS_SHORT: 'No access',
  PASSWORD_NOT_CREATED: 'Password not created',
};
//
