/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
import { toolActions } from './slice';
import API from './constants';
/**
 * Generator function for getting tools
 * @param {Object} payload
 */
export function* getToolsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_TOOLS, payload);
    yield put(toolActions.getAllToolsSucceeded(response));
  } catch (error) {
    yield put(toolActions.getAllToolsFailed(error?.message));
  }
}
/**
 * Generator function for adding a tool
 * @param {Object} payload
 */
export function* addToolsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_TOOL, payload);
    yield put(toolActions.addToolSucceeded(response));
  } catch (error) {
    yield put(toolActions.addToolFailed(error?.message));
  }
}
/**
 * Generator function for editing a tool
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* editToolGenerator({ payload }) {
  try {
    const response = yield call(request, API.PATCH_TOOL, payload);
    yield put(toolActions.editToolSucceeded(response));
  } catch (error) {
    yield put(toolActions.editToolFailed(error?.message));
  }
}
/**
 * Generator function for delete a tool
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {Object} payload.body
 */
export function* deleteToolGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_TOOL, payload);
    yield put(toolActions.deleteToolSucceeded(response));
  } catch (error) {
    yield put(toolActions.deleteToolFailed(error?.message));
  }
}
/**
 * Generator function for uploading logo
 * @param {Object} payload
 */
function* uploadLogoGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_LOGO, payload, true);
    yield put(toolActions.uploadLogoSucceeded(response));
  } catch (error) {
    yield put(toolActions.uploadLogoFailed(error?.message));
  }
}
/**
 * Generator function for getting all departments
 * @param {Object} payload
 */
export function* getAllDepartmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS, payload);
    yield put(toolActions.getAllDepartmentSucceeded(response));
  } catch (error) {
    yield put(toolActions.getAllDepartmentFailed(error?.message));
  }
}
/**
 * Redux saga that triggers the above defined functions
 */
export function* toolSaga() {
  yield takeLatest(toolActions.getAllTools.type, getToolsGenerator);
  yield takeLatest(toolActions.getAllDepartment.type, getAllDepartmentsGenerator);
  yield takeLatest(toolActions.addTool.type, addToolsGenerator);
  yield takeLatest(toolActions.editTool.type, editToolGenerator);
  yield takeLatest(toolActions.deleteTool.type, deleteToolGenerator);
  yield takeLatest(toolActions.uploadLogo.type, uploadLogoGenerator);
}
//
export default toolSaga;
