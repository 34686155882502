import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isInteger } from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';
//
import COLORS from 'features/base/constants/colors';

/**
 * Function that renders the Allocated Vs Actual hours report
 * @param {Array<Object>} allocationsByViewPeriod - Array of objects containing all the required data to render the report with minimal operations
 * @param {Array<String>} monthsOfSelectedYear - Array containing the months of the selected year (['2022-1', '2022-2'])
 * @returns {Table}
 */
const AllocatedActualHoursTable = ({ allocationsByViewPeriod, monthsOfSelectedYear }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {monthsOfSelectedYear?.map((yearMonth) => (
            <TableCell
              size="small"
              colSpan={2}
              key={`${yearMonth}-month-titles`}
              sx={{
                bgcolor: COLORS.SKY_BLUE,
                margin: 0,
                padding: 0,
                borderBlock: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                borderInline: `2px solid ${COLORS.WHITE}`,
              }}
            >
              <Typography
                textAlign={'center'}
                sx={{
                  width: 284,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {moment(yearMonth.split('-')[1], 'MM').format('MMMM')} - {yearMonth.split('-')[0]}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {monthsOfSelectedYear?.map((yearMonth) => (
            <Fragment key={`${yearMonth}-hour-titles`}>
              <TableCell
                size="small"
                key={`${yearMonth}-hour-allocation-title`}
                sx={{
                  margin: 0,
                  padding: 0,
                  bgcolor: COLORS.SKY_BLUE,
                  borderBottom: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                  borderInline: `2px solid ${COLORS.WHITE}`,
                }}
              >
                <Typography
                  sx={{
                    width: 142,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  textAlign={'center'}
                  fontWeight={600}
                  fontSize={16}
                >
                  Allocated hrs
                </Typography>
              </TableCell>
              <TableCell
                size="small"
                key={`${yearMonth}-hour-logged-title`}
                sx={{
                  margin: 0,
                  padding: 0,
                  bgcolor: COLORS.SKY_BLUE,
                  borderBottom: `1px solid ${COLORS.ELECTRIC_BLUE}`,
                }}
              >
                <Typography
                  sx={{
                    width: 142,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  textAlign={'center'}
                  fontWeight={600}
                  fontSize={16}
                >
                  Actual hrs
                </Typography>
              </TableCell>
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {allocationsByViewPeriod?.map((allocationForViewPeriod) => (
          <TableRow key={`${allocationForViewPeriod?.id}`} sx={{ minHeight: 68 }}>
            {monthsOfSelectedYear.map((yearMonth) => {
              const allocationForViewPeriodMonth = allocationForViewPeriod[yearMonth];
              const cells = [
                allocationForViewPeriodMonth?.allocatedHours,
                allocationForViewPeriodMonth?.loggedHours,
              ];
              return (
                <Fragment key={`${yearMonth}-time-info`}>
                  {cells?.map((cellValue, index) => (
                    <TableCell
                      size="small"
                      key={`${yearMonth}-time-hours-${index + 1}`}
                      sx={{
                        bgcolor: index === 0 ? COLORS.MAGNOLIA_WHITE : COLORS.DARK_ALICE_BLUE,
                        borderLeft: `2px solid ${COLORS.WHITE}`,
                        borderBottom: `2px solid ${COLORS.WHITE}`,
                        height: 68,
                      }}
                    >
                      <Typography
                        textAlign={'center'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {(cellValue && !isInteger(cellValue) ? cellValue?.toFixed(2) : cellValue) ||
                          0}
                      </Typography>
                    </TableCell>
                  ))}
                </Fragment>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
//
export default AllocatedActualHoursTable;
