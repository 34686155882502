import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Box, Container, Typography, Avatar, Grid, Divider, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ShimmerThumbnail, ShimmerButton } from 'react-shimmer-effects';
import AddIcon from '@mui/icons-material/Add';
//
import AddClientUsersPopup from 'features/clients/components/client-dashboard/add-client-users-modal';
import { Button, TabBar } from 'features/base/components';
import { selectClient, selectIsClientFetching } from 'features/clients/selectors';
import {
  CLIENT_USERS_NESTED_ROUTE,
  CLIENT_PROJECTS_NESTED_ROUTE,
} from 'features/base/constants/dashboard-tab-routes';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { clientActions } from 'features/clients/slice';
import { phoneNumberFormatter } from 'features/base/helpers/phone-numbers';
import COLORS from 'features/base/constants/colors';
import createFormattedString from 'features/base/helpers/param-formatter';
import { ENVIRONMENT } from 'config';
/**
 * Layout component for project dashboard
 * @returns {Box}
 */
const ClientDashboardLayout = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const userViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.USER,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  const projectViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const getNestedRoutes = () => {
    const nestedRoutes = [];
    if (userViewPermissionsVerified) {
      nestedRoutes.push(CLIENT_USERS_NESTED_ROUTE);
    }
    if (projectViewPermissionsVerified) {
      nestedRoutes.push(CLIENT_PROJECTS_NESTED_ROUTE);
    }
    return nestedRoutes;
  };
  const NESTED_ROUTES = getNestedRoutes();
  //
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  //
  const client = useSelector(selectClient);
  const isClientFetching = useSelector(selectIsClientFetching);
  const notification = useSelector(selectNotification);
  //
  const [pathName, setPathName] = useState(NESTED_ROUTES[0].path);
  const [addModalOpen, setAddModalOpen] = useState(false);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
    }
    return () =>
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS &&
      dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  useEffect(() => {
    dispatch(clientActions.getClientById({ id }));
    const formattedParamString = createFormattedString({
      clientIds: id,
      pagination: 'false',
      aggregated: true,
    });
    dispatch(clientActions.getProjectAllocationsList({ query: formattedParamString }));
  }, []);
  //
  useEffect(() => {
    const urlSections = location.pathname.split('/');
    const URLPath = urlSections[urlSections.length - 1];
    setPathName(URLPath);
  }, [location.pathname]);
  //
  const renderButtons = () => {
    switch (pathName) {
      case NESTED_ROUTES[0]?.path:
        return (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <PermissionWrapper
              requiredPermissions={[
                {
                  domain: PERMISSION_DOMAINS.CLIENT,
                  action: PERMISSION_ACTIONS.UPDATE,
                },
              ]}
              hide
            >
              {isClientFetching ? (
                <ShimmerButton size="lg" />
              ) : (
                <Button
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    mr: { sm: '12px' },
                    ml: { sm: '12px' },
                    mb: { xs: '16px', sm: '0px' },
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setAddModalOpen(true)}
                >
                  Add user <AddIcon />
                </Button>
              )}
            </PermissionWrapper>
          </Box>
        );
      default:
        return null;
    }
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <AddClientUsersPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item>
          {client?.logo ? (
            <img
              src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${client?.logo?.url}`}
              alt="client-logo"
              style={{
                height: '98px',
                width: '98px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <Avatar sx={{ height: '98px', width: '98px', marginRight: '16px' }}>
              {client?.primaryUser?.firstName?.charAt(0)}
            </Avatar>
          )}
        </Grid>
        <Grid item xs>
          <Grid container spacing={10} direction="row">
            <Grid item>
              <Typography
                sx={{
                  maxWidth: '30ch',
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  mr: '5px',
                  textTransform: 'uppercase',
                  wordWrap: 'break-word',
                }}
              >
                {`${client?.primaryUser?.firstName || '-'} ${client?.primaryUser?.lastName || '-'}`}
              </Typography>
              <Tooltip title={client?.address} enterDelay={500}>
                <Typography
                  variant="body1"
                  sx={{
                    color: COLORS.GRAYISH_PURPLE,
                    m: '5px 0px',
                    maxWidth: '20ch',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {client?.address || 'Address not found'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{ height: '100%' }} />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                sx={{
                  color: COLORS.GRAYISH_PURPLE,
                  m: '5px 0px',
                }}
              >
                {client?.company || 'Company not found'}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: COLORS.GRAYISH_PURPLE,
                  m: '5px 0px',
                }}
              >
                {`BRN: ${client?.brNumber || 'BR number not found'}`}
              </Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" sx={{ height: '100%' }} />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                sx={{
                  color: COLORS.GRAYISH_PURPLE,
                  m: '5px 0px',
                }}
              >
                {client?.primaryUser?.email || 'Email not found'}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: COLORS.GRAYISH_PURPLE,
                  m: '5px 0px',
                }}
              >
                {`Company phone: ${
                  client?.phoneNumber
                    ? phoneNumberFormatter(client?.phoneNumber)
                    : 'Phone number not found'
                }`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {renderButtons()}
      </Grid>
      <Box sx={{ mt: '24px', mb: '48px' }}>
        {isClientFetching ? (
          <ShimmerThumbnail height={60} className="shimmer-thumbnail-no-margin-bottom" />
        ) : (
          <TabBar nestedRoutes={NESTED_ROUTES} pathName={pathName} />
        )}
      </Box>
      <Outlet context={{ id }} />
    </Container>
  );
};
//
export default ClientDashboardLayout;
