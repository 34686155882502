import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
//
import { clientActions } from 'features/clients/slice';
import { notificationActions } from 'features/base/notifications/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import { Popup } from 'features/base/components';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import EditArsenalForm from '../edit-arsenal-item-form';

/**
 * Function that defines the popup for adding a new arsenal item
 * @prop {Boolean} open - Boolean to show/hide the popup
 * @prop {Function} setOpen - Function to set the popup state
 * @prop {Object} arsenalItemToBeEdited - Arsenal item to be edited
 * @returns {Popup}
 */
const EditArsenalItemPopup = ({ open, setOpen, arsenalItemToBeEdited }) => {
  const notification = useSelector(selectNotification);
  //
  const dispatch = useDispatch();
  //
  const handleOnClose = () => {
    setOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(clientActions.clearLogo());
  };
  //
  useEffect(() => {
    if (open && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ARSENAL,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Popup open={open} onClose={handleOnClose} title="Edit item" mediumSize="660px" height="700px">
        <EditArsenalForm handleOnClose={handleOnClose} arsenalItemToBeEdited={arsenalItemToBeEdited} />
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditArsenalItemPopup;
