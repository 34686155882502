/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { roleActions } from './slice';
import API from './constants';
/**
 * Generator function for adding a role
 * @param {Object} payload
 */
export function* addRoleGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_ROLE, payload);
    yield put(roleActions.addRoleSucceeded(response));
  } catch (error) {
    yield put(roleActions.addRoleFailed(error?.message));
  }
}
/**
 * Generator function for getting all roles
 * @param {Object} payload
 */
export function* getRolesGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ROLE, payload);
    yield put(roleActions.getAllRoleSucceeded(response));
  } catch (error) {
    yield put(roleActions.getAllRoleFailed(error?.message));
  }
}
/**
 * Generator function for editing a role
 */
export function* editRolesGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_ROLE, payload);
    yield put(roleActions.editRoleSucceeded(response));
  } catch (error) {
    yield put(roleActions.editRoleFailed(error?.message));
  }
}
/**
 * Redux saga that triggers addRole function
 */
export function* roleSaga() {
  yield takeLatest(roleActions.addRole.type, addRoleGenerator);
  yield takeLatest(roleActions.getAllRoles.type, getRolesGenerator);
  yield takeLatest(roleActions.editRole.type, editRolesGenerator);
}
//
export default roleSaga;
