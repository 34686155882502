import { Box, Typography, Select, MenuItem, Link, Tooltip } from '@mui/material';
import commentTableIcon from 'features/base/assets/images/svg/comment-table-header.svg';
import lowIcon from 'features/base/assets/images/svg/low.svg';
import mediumIcon from 'features/base/assets/images/svg/medium.svg';
import highIcon from 'features/base/assets/images/svg/high.svg';
import commentIcon from 'features/base/assets/images/svg/message.svg';
import openInNewTabIcon from 'features/base/assets/images/svg/open-in-new-tab.svg';
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
//
import COLORS from 'features/base/constants/colors';
import { ENVIRONMENT } from 'config';
/**
 * View Support tickets table columns - basic details
 */
export const SUPPORT_TICKET_RAISED_TABLE_COLUMNS = [
  {
    field: 'project',
    headerName: 'Project',
    width: 220,
    valueGetter: (params) => params.row.project.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.project;
      return (
        <Typography
          onClick={onClick}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    valueGetter: (params) => params.row.title.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.title;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 150,
    valueGetter: (params) => params.row.severity.severity,
    renderCell: (params) => {
      const { severity, onChange } = params.row.severity;
      return (
        <Select value={severity} onChange={onChange} className="select-box">
          <MenuItem value={SEVERITY_TYPES.LOW}>
            <Box>
              <img src={lowIcon} className="severity-icon" alt="Low Icon" />
              Low
            </Box>
          </MenuItem>
          <MenuItem value={SEVERITY_TYPES.MEDIUM}>
            <Box>
              <img src={mediumIcon} className="severity-icon" alt="Medium Icon" />
              Medium
            </Box>
          </MenuItem>
          <MenuItem value={SEVERITY_TYPES.HIGH}>
            <Box>
              <img src={highIcon} className="severity-icon" alt="High Icon" />
              High
            </Box>
          </MenuItem>
        </Select>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 120,
    valueGetter: (params) => params.row.createdAt.value,
    renderCell: (params) => {
      const { value } = params.row.createdAt;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    width: 120,
    valueGetter: (params) => params.row.updatedAt.value,
    renderCell: (params) => {
      const { value } = params.row.updatedAt;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'attachments',
    headerName: 'Attachments',
    width: 200,
    sortable: false,
    renderCell: ({ value: { value } }) =>
      value?.length > 0 &&
      value?.map((attachment) => (
        <Link
          href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${attachment?.url}`}
          underline="hover"
          sx={{
            fontSize: '0.875rem',
            maxWidth: '15ch',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          target="_blank"
          rel="noreferrer"
        >
          {attachment?.name}
        </Link>
      )),
  },
  {
    field: 'dataStatus',
    headerName: 'Status',
    width: 110,
    valueGetter: (params) => params.row.dataStatus.value,
    renderCell: (params) => {
      const { value } = params.row.dataStatus;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: value ? COLORS.PALE_GREEN : COLORS.LIGHT_PINK,
              p: '7px 16px',
              mr: '16px',
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: value ? COLORS.SUCCESS_MAIN : COLORS.SECONDARY_MAIN,
              }}
            >
              {value ? 'Resolved' : 'Open'}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'jiraIssueId',
    headerName: 'Jira Issue',
    width: 120,
    valueGetter: (params) => params.row?.jiraIssueId?.value,
    renderCell: (params) => {
      const { value } = params.row.jiraIssueId;
      return <Typography variant="body2">{value || '-'}</Typography>;
    },
  },
  {
    field: 'comments',
    headerName: 'Comments',
    sortable: false,
    renderHeader: () => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={commentTableIcon} alt="High Icon" />
      </Box>
    ),
    width: 60,
    renderCell: ({ value: { handleCommentOnClick } }) => (
      <Box onClick={handleCommentOnClick}>
        <img src={commentIcon} alt="commentIcon" className="commentIcon" />
      </Box>
    ),
  },
  {
    field: 'openInNewTab',
    headerName: 'Open in new tab',
    sortable: false,
    renderHeader: () => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={openInNewTabIcon} alt="High Icon" />
      </Box>
    ),
    width: 60,
    renderCell: ({ value: { handleOpenInNewTabOnClick } }) => (
      <Box onClick={handleOpenInNewTabOnClick}>
        <img src={openInNewTabIcon} alt="commentIcon" className="commentIcon" />
      </Box>
    ),
  },
];
/**
 * View support ticket table columns - action details
 */
export const SUPPORT_TICKET_HANDLING_TABLE_COLUMNS = [
  {
    field: 'project',
    headerName: 'Project',
    width: 170,
    valueGetter: (params) => params.row.project.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.project;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'raisedBy',
    headerName: 'Client',
    width: 150,
    valueGetter: (params) => params.row.raisedBy.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.raisedBy;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    valueGetter: (params) => params.row.title.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.title;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onClick}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 110,
    valueGetter: (params) => params.row.severity.severity,
    renderCell: (params) => {
      const { severity } = params.row.severity;
      switch (severity) {
        case SEVERITY_TYPES.LOW:
          return (
            <Box sx={{ display: 'flex' }}>
              <img src={lowIcon} className="severity-icon-handling" alt="Low Icon" />
              <Typography>Low</Typography>
            </Box>
          );
        case SEVERITY_TYPES.MEDIUM:
          return (
            <Box sx={{ display: 'flex' }}>
              <img src={mediumIcon} className="severity-icon-handling" alt="Medium Icon" />
              <Typography>Medium</Typography>
            </Box>
          );
        case SEVERITY_TYPES.HIGH:
          return (
            <Box sx={{ display: 'flex' }}>
              <img src={highIcon} className="severity-icon-handling" alt="High Icon" />
              <Typography>High</Typography>
            </Box>
          );
        default:
          return null;
      }
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 110,
    valueGetter: (params) => params.row.createdAt.value,
    renderCell: (params) => {
      const { value } = params.row.createdAt;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    width: 110,
    valueGetter: (params) => params.row.updatedAt.value,
    renderCell: (params) => {
      const { value } = params.row.updatedAt;
      return <Typography>{value}</Typography>;
    },
  },
  {
    field: 'attachments',
    headerName: 'Attachments',
    width: 150,
    sortable: true,
    renderCell: ({ value: { value } }) =>
      (value?.length > 0 &&
        value?.map((attachment) => (
          <Link
            href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${attachment?.url}`}
            underline="hover"
            sx={{
              fontSize: '0.875rem',
              maxWidth: '15ch',
              wordWrap: 'break-word',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            target="_blank"
            rel="noreferrer"
          >
            {attachment?.name}
          </Link>
        ))) ||
      '-',
  },
  {
    field: 'dataStatus',
    headerName: 'Status',
    width: 100,
    valueGetter: (params) => params.row.dataStatus.value,
    renderCell: (params) => {
      const { value, onClick } = params.row.dataStatus;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: value ? COLORS.PALE_GREEN : COLORS.LIGHT_PINK,
              p: '7px 16px',
              mr: '16px',
              cursor: `${value ? 'default' : 'pointer'}`,
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={onClick}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: value ? COLORS.SUCCESS_MAIN : COLORS.SECONDARY_MAIN,
              }}
            >
              {value ? 'Resolved' : 'Open'}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'jiraIssueId',
    headerName: 'Jira Issue',
    width: 110,
    valueGetter: (params) => params.row?.jiraIssueId?.value,
    renderCell: (params) => {
      const { value } = params.row.jiraIssueId;
      return value ? (
        <Link
          href={`https://${ENVIRONMENT.JIRA_ORG_NAME}.atlassian.net/browse/${value}`}
          underline="hover"
          sx={{
            maxWidth: '15ch',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </Link>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'loggedTime',
    headerName: 'Hours',
    width: 60,
    valueGetter: (params) => params.row.loggedTime.value,
    renderCell: (params) => {
      const { value } = params.row.loggedTime;
      return (
        <Tooltip title={value}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'comments',
    headerName: 'Comments',
    sortable: false,
    renderHeader: () => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={commentTableIcon} alt="High Icon" />
      </Box>
    ),
    width: 40,
    renderCell: ({ value: { handleCommentOnClick } }) => (
      <Box onClick={handleCommentOnClick}>
        <img src={commentIcon} alt="commentIcon" className="commentIcon" />
      </Box>
    ),
  },
  {
    field: 'openInNewTab',
    headerName: 'Open in new tab',
    sortable: false,
    renderHeader: () => (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={openInNewTabIcon} alt="High Icon" />
      </Box>
    ),
    width: 50,
    renderCell: ({ value: { handleOpenInNewTabOnClick } }) => (
      <Box onClick={handleOpenInNewTabOnClick}>
        <img src={openInNewTabIcon} alt="commentIcon" className="commentIcon" />
      </Box>
    ),
  },
];
