import { Avatar, Box, Switch, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';

/**
 * View user table columns - basic details
 */
export const USERS_TABLE_COLUMNS_BASIC = [
  {
    field: 'profile',
    headerName: 'Profile',
    headerClassName: 'super-app-theme--header',
    width: 350,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { value, departmentAndDesignation, profileImage } = params.row.profile;
      return (
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          // onClick={profileOnClick}
        >
          {profileImage ? (
            <img
              referrerPolicy="no-referrer"
              src={profileImage}
              alt="profile"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
          ) : (
            <Avatar sx={{ height: '40px', width: '40px', mr: 1 }}>{value.charAt(0)}</Avatar>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                maxWidth: '30ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.775rem',
                color: COLORS.GREY_SUIT,
                maxWidth: '45ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {departmentAndDesignation}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    headerClassName: 'super-app-theme--header',
    width: 270,
    renderCell: ({ value: { value } }) => <Typography>{value}</Typography>,
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'super-app-theme--header',
    width: 100,
  },
  {
    field: 'workArea',
    headerName: 'Workarea',
    headerClassName: 'super-app-theme--header',
    width: 150,
    valueGetter: (params) => params.row.workArea.value,
    renderCell: (params) => {
      const { value } = params.row.workArea;
      return (
        <Box
          sx={{
            maxWidth: '20ch',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
  },
  {
    field: 'capacity',
    headerName: 'Capacity',
    headerClassName: 'super-app-theme--header',
    width: 100,
    valueGetter: (params) => params.row.capacity.value,
    renderCell: (params) => {
      const { value } = params.row.capacity;
      return <Box sx={{ maxWidth: '20ch', wordWrap: 'break-word' }}>{value}</Box>;
    },
  },
  {
    field: 'onboardingStatus',
    headerName: 'Onboarding status',
    headerClassName: 'super-app-theme--header',
    width: 180,
    valueGetter: (params) => params.row.onboardingStatus.value,
    renderCell: (params) => {
      const { value } = params.row.onboardingStatus;
      return (
        <Box sx={{ display: 'flex', mt: { xs: '16px', sm: '0px' } }}>
          <Box
            sx={{
              bgcolor: value ? COLORS.PALE_GREEN : COLORS.LAVENDER_MIST,
              p: '7px 16px',
              mr: '16px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: value ? COLORS.SUCCESS_MAIN : COLORS.DEEP_INDIGO,
              }}
            >
              {value ? 'Onboarded' : 'Pending'}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];
/**
 * View users table columns - role column
 */
export const USERS_TABLE_STATUS_COLUMN = {
  field: 'dataStatus',
  headerName: 'Status',
  headerClassName: 'super-app-theme--header',
  width: 75,
  valueGetter: (params) => params.row.dataStatus.checked,
  renderCell: (params) => {
    const { checked, onChange, loading } = params.row.dataStatus;
    return <Switch checked={checked} onClick={onChange} color="success" readOnly={loading} />;
  },
};
