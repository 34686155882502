import { Alert, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
//
import { linkActions } from 'features/links/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectAllDepartment,
  selectUploadedLogo,
  selectAddLinkLoader,
  selectFileUploadLoader,
} from 'features/links/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { TextField, Popup, FileUpload } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  getAllowedExtensionsString,
  verifyExtensions,
} from 'features/base/helpers/file';
import {
  LINK_LOGO_ALLOWED_FILE_TYPES,
  LINK_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import linkFormValidation from 'features/links/validations/link-validation';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddLinksPopup = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectAddLinkLoader);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  const allDepartments = useSelector(selectAllDepartment);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(linkActions.clearLogo());
    setFileTypeError('');
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), LINK_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      formData.append('file', logo[0]);
      if (logo.length > 0) {
        dispatch(linkActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.LINK,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Popup
        open={addModalOpen}
        onClose={handleOnClose}
        title="Add Link"
        mediumSize="660px"
        height="700px"
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            link: '',
            departments: [],
          }}
          validationSchema={linkFormValidation}
          onSubmit={(values) => {
            const { departments, ...data } = values;
            data.departments = departments.map((department) => department?.id);
            data.link = encodeURI(data.link);
            if (uploadedLogo) {
              data.logo = { ...uploadedLogo, url: encodeURI(uploadedLogo?.url) };
            }
            dispatch(linkActions.addLink(data));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled &&
                notification?.type === ERROR_TYPES.ERROR &&
                !uploadedLogo && (
                  <Alert sx={{ mb: 3 }} severity={notification?.type}>
                    {notification?.message}
                  </Alert>
                )}
              <FileUpload
                setLogo={setLogo}
                label="Logo"
                value={values.logo}
                acceptedTypes={LINK_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  LINK_LOGO_ALLOWED_EXTENSIONS
                )}`}
                inputLabel={uploadedLogo ? `${uploadedLogo?.name}` : `Choose logo`}
                loading={fileUploadLoading}
              />
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched?.name && errors?.name)}
                helperText={touched?.name && errors?.name}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched?.description && errors?.description)}
                helperText={touched?.description && errors?.description}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Description"
                my={2}
                height={120}
                multiline
                rows={4}
              />
              <TextField
                type="text"
                name="link"
                value={values.link}
                error={Boolean(touched?.link && errors?.link)}
                helperText={touched?.link && errors?.link}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Link"
                fullWidth
              />
              <Typography fontSize="16px" className="field-label">
                Departments
              </Typography>
              <MultiSelectAutoComplete
                id="departments"
                name="departments"
                options={allDepartments}
                selectedOptions={values.departments}
                setSelectedOptions={setFieldValue}
                formikKey="departments"
                placeholder="Select department"
                error={Boolean(touched.departments && errors.departments)}
                errorMsg={touched.departments && errors.departments}
                onClose={() => setFieldTouched('departments', true)}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={loading || fileUploadLoading}
                rightIcon={loading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default AddLinksPopup;
