import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
//
import { ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import {
  selectCreateTeamAvailableUsers,
  selectCreateTeamProject,
  selectSelectedTeamMembers,
} from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';

/**
 * Function that handles the adding of a single user to the project
 */
const useOnPlusIconClick = () => {
  const dispatch = useDispatch();
  //
  const project = useSelector(selectCreateTeamProject);
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  const availableUsers = useSelector(selectCreateTeamAvailableUsers);
  //
  const onPlusIconClick = (user) => {
    const alreadyExist = selectedUsers?.docs?.find((sUser) => sUser?.userId?.id === user?.id);
    if (!alreadyExist) {
      const availableUsersToBeAdded = [
        ...selectedUsers.docs,
        {
          id: user?.id,
          userId: { ...user },
          startDate: moment(project?.startDate).format(ISO_WITHOUT_TIME),
          endDate: moment(project?.endDate).format(ISO_WITHOUT_TIME),
          allocatedPercentage: '10',
        },
      ];
      dispatch(projectActions.setCreateTeamSelectedUsers(availableUsersToBeAdded));
      //
      const availableUsersCopy = availableUsers?.docs?.map((availableUser) => {
        if (user?.id !== availableUser?.id) {
          return availableUser;
        }
        return {
          ...availableUser,
          disabled: true,
        };
      });
      dispatch(projectActions.setCreateTeamAvailableUsers(availableUsersCopy));
    }
  };
  //
  return { onPlusIconClick };
};
//
export default useOnPlusIconClick;
