import { ENVIRONMENT } from 'config';
/**
 * Keycloak domain
 */
const domain = ENVIRONMENT.KC_DOMAIN;
/**
 * Keycloak realm
 */
const realm = ENVIRONMENT.KC_REALM;
/**
 * Keycloak authentication URL path
 */
const authUrlPath = ENVIRONMENT.KC_AUTH_URL;
/**
 * Keycloak client id
 */
const clientId = ENVIRONMENT.KC_CLIENT_ID;
/**
 * Keycloak auth response type
 */
const responseType = ENVIRONMENT.KC_RESPONSE_TYPE;
/**
 * Kecyloak scopes
 */
const scope = ENVIRONMENT.KC_SCOPES;
/**
 * Keycloak login redirect URL
 */
const redirectURL = ENVIRONMENT.KC_REDIRECT_URI;
/**
 * Keycloak PKCE challenge method
 */
const pkceChallengeMethod = ENVIRONMENT.KC_PKCE_CHALLENGE_METHOD;
/**
 * Keycloak logout URL path
 */
const logoutUrlPath = ENVIRONMENT.KC_LOGOUT_URL;
/**
 * Keycloak logout redirect URL
 */
const logoutRedirectUrl = ENVIRONMENT.KC_LOGOUT_REDIRECT_URI;
/**
 * Built authentication URL
 */
export const authenticationUrl = `${domain}/${realm}/${authUrlPath}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectURL}&scope=${scope}&code_challenge_method=${pkceChallengeMethod}`;
/**
 * Built logout  URL
 */
export const logoutUrl = `${domain}/${realm}/${logoutUrlPath}?post_logout_redirect_uri=${logoutRedirectUrl}`;
