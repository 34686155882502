/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'features/base/utils/request';
//
import { benefitsActions } from './slice';
import API from './constants';
/**
 * Generator function for getting benefits
 * @param {Object} payload
 */
export function* getBenefitsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_BENEFITS, payload);
    yield put(benefitsActions.getBenefitsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.getBenefitsFailed(error?.message));
  }
}
/**
 * Generator function for adding a benefit
 * @param {Object} payload
 */
export function* addBenefitsGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_BENEFITS, payload);
    yield put(benefitsActions.addBenefitsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.addBenefitsFailed(error?.message));
  }
}
/**
 * Generator function for updating a benefit
 * @param {Object} payload
 */
export function* editBenefitsGenerator({ payload }) {
  try {
    const response = yield call(request, API.EDIT_BENEFITS, payload);
    yield put(benefitsActions.editBenefitsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.editBenefitsFailed(error?.message));
  }
}
/**
 * Generator function for deleting a benefit
 * @param {Object} payload
 */
export function* deleteBenefitsGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_BENEFITS, payload);
    yield put(benefitsActions.deleteBenefitsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.deleteBenefitsFailed(error?.message));
  }
}
/**
 * Generator function for getting departments
 */
export function* getDepartmentsGenerator() {
  try {
    const response = yield call(request, API.GET_DEPARTMENTS);
    yield put(benefitsActions.getDepartmentsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.getDepartmentsFailed(error?.message));
  }
}
/**
 * Generator function for uploading benefit attachments
 * @param {Object} payload
 */
function* uploadAttachmentsGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_ATTACHMENTS, payload, true);
    yield put(benefitsActions.uploadAttachmentsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.uploadAttachmentsFailed(error?.message));
  }
}
/**
 * Generator function for getting vendors for multi step form
 * @param {Object} payload
 */
function* getVendorsMultiStepFormGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VENDORS, payload);
    yield put(benefitsActions.getVendorsMultiStepFormSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.getVendorsMultiStepFormFailed(error?.message));
  }
}
/**
 * Generator function for getting refine users
 * @param {Object} payload
 */
function* getRefineUsersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_USERS, payload);
    yield put(benefitsActions.getRefineUsersSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.getRefineUsersFailed(error?.message));
  }
}
/**
 * Generator function for adding promotions in bulk
 * @param {Object} payload
 */
function* addPromotionsGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_PROMOTIONS_BULK, payload);
    yield put(benefitsActions.addPromotionsSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.addPromotionsFailed(error?.message));
  }
}
/**
 * Generator function for redeeming a benefit
 * @param {Object} payload
 */
function* redeemBenefitGenerator({ payload }) {
  try {
    const response = yield call(request, API.REDEEM_BENEFIT, payload);
    yield put(benefitsActions.redeemBenefitSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.redeemBenefitFailed(error?.message));
  }
}
/**
 * Generator function for getting promotions to edit
 * @param {Object} payload
 */
function* getPromotionsToEditGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_PROMOTIONS, payload);
    yield put(benefitsActions.getPromotionsToEditSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.getPromotionsToEditFailed(error?.message));
  }
}
/**
 * Generator function for deleting a promotion
 * @param {Object} payload
 * @returns
 */
function* deletePromotionGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_PROMOTION, payload);
    yield put(benefitsActions.deletePromotionSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.deletePromotionFailed(error?.message));
  }
}
/**
 * Generator function for adding a promotion
 * @param {Object} payload
 * @returns
 */
function* addPromotionGenerator({ payload }) {
  try {
    const response = yield call(request, API.ADD_PROMOTIONS_BULK, payload);
    yield put(benefitsActions.addPromotionSucceeded(response));
  } catch (error) {
    yield put(benefitsActions.addPromotionFailed(error?.message));
  }
}

/**
 * Redux saga that triggers the above defined generator function
 */
export function* benefitsSaga() {
  yield takeLatest(benefitsActions.getBenefits.type, getBenefitsGenerator);
  yield takeLatest(benefitsActions.addBenefits.type, addBenefitsGenerator);
  yield takeLatest(benefitsActions.addPromotions.type, addPromotionsGenerator);
  yield takeLatest(benefitsActions.editBenefits.type, editBenefitsGenerator);
  yield takeLatest(benefitsActions.deleteBenefits.type, deleteBenefitsGenerator);
  yield takeLatest(benefitsActions.getDepartments.type, getDepartmentsGenerator);
  yield takeLatest(benefitsActions.uploadAttachments.type, uploadAttachmentsGenerator);
  yield takeLatest(benefitsActions.getVendorsMultiStepForm.type, getVendorsMultiStepFormGenerator);
  yield takeLatest(benefitsActions.getRefineUsers.type, getRefineUsersGenerator);
  yield takeLatest(benefitsActions.redeemBenefit.type, redeemBenefitGenerator);
  yield takeLatest(benefitsActions.getPromotionsToEdit.type, getPromotionsToEditGenerator);
  yield takeLatest(benefitsActions.deletePromotion.type, deletePromotionGenerator);
  yield takeLatest(benefitsActions.addPromotion.type, addPromotionGenerator);
}

export default benefitsSaga;
