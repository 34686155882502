import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ShimmerTable } from 'react-shimmer-effects';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'features/base/constants/routes';
//
import { Button, DataGrid } from 'features/base/components';
import COLORS from 'features/base/constants/colors';
import { MONTHS } from 'features/base/constants/date-formatting';
import {
  selectReallocationTableAllocations,
  selectReallocationTableAllocationsLoading,
  selectAllocationReportPatchAllocationsLoading,
} from 'features/reports/selectors';
import { reportActions } from 'features/reports/slice';
/**
 * Function that renders the update work allocations table
 */
const UpdateWorkAllocationsTable = ({
  selectedUser,
  mainProjectPeriod,
  rows,
  errors,
  projectDetails,
  isWorkAllocationFetching,
  tableLoading,
  allocationId,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  setReallocateModalOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const reallocationTableAllocations = useSelector(selectReallocationTableAllocations);
  const reallocationTableAllocationsLoading = useSelector(
    selectReallocationTableAllocationsLoading
  );
  const isAllocationReportPatchAllocationsLoading = useSelector(
    selectAllocationReportPatchAllocationsLoading
  );
  //
  useEffect(() => {
    if (!selectedUser?.id) {
      return;
    }
    dispatch(
      reportActions.getReallocationTableAllocations({
        query: `userIds=${selectedUser?.id}&pagination=false&aggregated=true`,
      })
    );
  }, [selectedUser]);
  //
  const computeTableHeader = () => {
    const columns = [
      {
        field: 'ProjectName',
        width: 300,
        headerName: <Box sx={{ minWidth: '12rem' }}>Project name</Box>,
        valueGetter: (params) => params.row.ProjectName.value,
        renderCell: (params) => {
          const { projectId: currentProjectId, value } = params.row.ProjectName;
          return (
            <Typography
              fontWeight={currentProjectId === projectDetails?.id ? 600 : 300}
              variant="body2"
            >
              {value}
            </Typography>
          );
        },
      },
    ];
    mainProjectPeriod.forEach((period) => {
      columns.push({
        field: `${period.month}${period.year}`,
        width: 225,
        headerName: (
          <Box sx={{ minWidth: '6rem', maxWidth: '6rem' }}>
            <Stack spacing={0}>
              <Typography variant="body1" fontWeight="bold">
                {period.month}
              </Typography>
              <Typography variant="caption">{period.year}</Typography>
            </Stack>
          </Box>
        ),
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        valueGetter: (params) => params.row[`${period.month}${period.year}`].allocationHours,
        renderCell: (params) => {
          const { id, allocationHours, onChange, availability } =
            params.row[`${period.month}${period.year}`];
          if (id === 'currentProject') {
            // current project input fields
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', width: '150px' }}>
                <div style={{ display: 'flex', gap: 0 }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        type="number"
                        size="small"
                        variant="outlined"
                        required
                        error={allocationHours > selectedUser?.capacity || allocationHours < 0}
                        value={allocationHours}
                        // Only increment or decrement by 8 multipliers (0, 8, 16, 24, 32, 40, 48, 56, 64)
                        inputProps={{
                          step: 8,
                          min: 0,
                          max: 720,
                        }}
                        sx={{ flexShrink: 0, width: '100%' }}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography
                        variant="body2"
                        color={COLORS.SUCCESS_MAIN}
                        sx={{
                          fontSize: '0.879rem',
                          fontWeight: 'bold',
                          border: '1px solid',
                          borderColor: (theme) => theme.palette.grey[400],
                          borderRadius: '4px',
                          backgroundColor: COLORS.TRANSPARENT_DARK_GREEN,
                          px: 1,
                          py: '9px',
                          textAlign: 'left',
                          flexShrink: 0,
                        }}
                      >
                        {availability}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            );
          }
          if (id === 'totalPerMonth') {
            return (
              <Box
                display="flex"
                alignItems="center"
                fontWeight="bold"
                color={allocationHours > selectedUser?.capacity ? 'error.main' : 'text.primary'}
              >
                {allocationHours}
                {allocationHours > selectedUser?.capacity && (
                  <Tooltip title="Allocation exceeds capacity">
                    <IconButton>
                      <ReportProblemIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          }
          return <Typography variant="subtitle2">{allocationHours}</Typography>;
        },
      });
    });
    return columns;
  };
  //
  const handleSave = () => {
    if (errors?.length > 0) {
      return;
    }
    const editedAllocation = reallocationTableAllocations?.docs
      ?.find(
        (entity) =>
          entity?.projectId.id === projectDetails?.id && entity?.userId.id === selectedUser?.id
      )
      .monthlyAllocations.map((allocation) => ({
        year: allocation.year.toString(),
        month: MONTHS[allocation.month - 1],
        hours: allocation.allocatedHours,
        hourlyRate: allocation.hourlyRate,
      }));
    dispatch(
      reportActions.patchAllocationReportAllocation({
        allocation: editedAllocation,
        isAllocatedPercentageLocked: true,
        allocationId,
      })
    );
    setHasUnsavedChanges(false);
  };
  //
  return (
    <Container sx={{ mt: 2 }} disableGutters>
      {!tableLoading && !reallocationTableAllocationsLoading && selectedUser && rows.length > 0 && (
        <>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
            {' '}
          </Typography>
          <DataGrid
            serverSidePagination={false}
            limit={rows?.length ? rows.length + 1 : 0}
            columns={computeTableHeader()}
            rows={rows}
            rowHeight={73}
            pageSizeOptions={[rows?.length ? rows.length + 1 : 0]}
          />
          {errors?.length > 0 && (
            <Alert severity="warning" variant="filled" sx={{ mt: 2 }}>
              <AlertTitle> Your configuration has errors </AlertTitle>
              {errors.map((error) => (
                <Typography key={error} sx={{ fontSize: '0.8rem' }}>
                  {error}
                </Typography>
              ))}
            </Alert>
          )}
          <Box
            sx={{ justifyContent: 'flex-end', mt: 2, pr: 3 }}
            display={hasUnsavedChanges ? 'flex' : 'none'}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={tableLoading || errors?.length > 0 || !hasUnsavedChanges || isAllocationReportPatchAllocationsLoading}
              startIcon={isAllocationReportPatchAllocationsLoading && <CircularProgress size="1rem" />}
              onClick={() => {
                if (isWorkAllocationFetching) {
                  return;
                }
                handleSave();
              }}
            >
              {'Save Changes'}
            </Button>
          </Box>
        </>
      )}
      {tableLoading && <ShimmerTable row={3} col={8} size="sm" />}
      {!tableLoading && !hasUnsavedChanges && (
        <Box sx={{ mt: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              navigate(`${ROUTES.ALLOCATION_REPORT}?projectId=${projectDetails?.id}`);
              setReallocateModalOpen(false);
            }}
          >
            Next
          </Button>
        </Box>
      )}
    </Container>
  );
};
//
export default UpdateWorkAllocationsTable;
