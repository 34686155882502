import { DatePicker } from 'features/base/components';

/**
 *  Function that defines the End Date Field for edit current salary
 * @returns {Popup}
 */
const EndDateField = ({ formikBag: { values, setFieldValue } }) => (
  <DatePicker
    disabled
    value={values.endDate}
    onChange={(event) => {
      setFieldValue('endDate', new Date(event).toISOString());
    }}
  />
);
//
export default EndDateField;
