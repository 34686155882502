import { useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import COLORS from 'features/base/constants/colors';
//
import TextField from '../text-field';
import DataList from '../data-list';

/**
 * Function that renders a component that contains a text box and
 * a list of items (data entered through the text field) rendered as removable cards via the DataList component
 * @prop {String} id - Id of the component
 * @prop {String} name - Name of the component
 * @prop {String} text - The displayed text on the text field
 * @prop {Function} setText - Function to update the displayed text (the first prop of the callback function is the new text entered)
 * @prop {Function} textFormatter - Function to format the text of the data list
 * @prop {List} items - List of items to display
 * @prop {Function} setItems - Function to update the items list
 * @prop {String} textError - Error message on the text field component
 * @prop {String} displayError - Any other error messages to display
 * @prop {String} onBlur - Function to run during the onBlur event of the text field component
 * @prop {String} onAdd - Function that runs when adding new items through the textfield component
 * @prop {String} onRemove - Function that runs when removing items from the data list
 * @prop {Object} listSx - Custom SX for the list of files displayed
 * @prop {Boolean} disabled - If true, upload will be disabled
 * @prop {Boolean} renderInternalSelect - DataList prop that renders a multi select autocomplete complete within each list item if true
 * @prop {Object} internalSelectProps - DataList prop that defines the props for the internal multi select component (renderInternalSelect must be true and options, setSelectedItems is required)
 * @prop {String} internalSelectOptionsKey - DataList prop that indicates the key in each item to use as the selectedOptions of the multi select component. The key must be present in each item
 * @prop {Boolean} isInternalSelectMultiple - If true, the internal select is multiple, if false its a single select
 * @prop {String} internalOptionsPlaceholder - Placeholder for the internal options list
 * @prop {String} label - Display label
 * @prop {String} displayKey - Display key
 * @returns
 */
const TextFieldDataList = ({
  id,
  name,
  text,
  setText,
  textFormatter,
  items,
  setItems,
  textError,
  displayError = '',
  onBlur,
  onAdd,
  onRemove,
  listSx,
  disabled,
  renderInternalSelect,
  internalSelectProps,
  internalSelectOptionsKey,
  isInternalSelectMultiple,
  internalOptionsPlaceholder,
  label,
  displayKey,
}) => {
  const [error, setError] = useState();
  //
  const handleTextChange = (e) => {
    setError('');
    setText(e.target.value);
  };
  //
  const handleAddItem = () => {
    onAdd();
    if (textError) return;
    const trimmed = text.trim();
    if (!trimmed) {
      return;
    }
    if (items?.includes(trimmed)) {
      setError('Already added');
      return;
    }
    setItems([...items, textFormatter ? textFormatter(text) : text]);
    setText('');
    setError('');
  };
  //
  const handleRemoveItem = (itemToRemove) => {
    onRemove();
    setItems(items.filter((item) => item !== itemToRemove));
  };
  //
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddItem();
    }
  };
  //
  return (
    <Box sx={{ mb: 2 }}>
      <Typography fontSize="16px" className="field-label">
        {label}
      </Typography>
      <TextField
        name={name}
        id={id}
        placeholder="https://website.com"
        value={text}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleAddItem}>
              <AddIcon
                color={COLORS.PRIMARY_MAIN}
                sx={{
                  color: COLORS.PRIMARY_MAIN,
                }}
              />
            </IconButton>
          ),
        }}
        sx={{
          mb: 0.5,
        }}
      />
      {textError && <p className="error-feedback">{textError}</p>}
      {displayError && <p className="error-feedback">{displayError}</p>}
      <DataList
        items={items}
        handleRemove={handleRemoveItem}
        displayKey={displayKey ?? 'name'}
        sx={listSx}
        disabled={disabled}
        renderInternalSelect={renderInternalSelect}
        internalSelectProps={internalSelectProps}
        internalSelectOptionsKey={internalSelectOptionsKey}
        isInternalSelectMultiple={isInternalSelectMultiple}
        internalOptionsPlaceholder={internalOptionsPlaceholder}
      />
      {error && <p className="error-feedback">{error}</p>}
    </Box>
  );
};
//
export default TextFieldDataList;
