import { ReportProblem } from '@mui/icons-material';
import { Avatar, Box, IconButton, Typography, Checkbox, Tooltip } from '@mui/material';
import addIcon from 'features/base/assets/images/png/add-icon.png';
import COLORS from 'features/base/constants/colors';

/**
 * Defines the table header details for available users table
 */
const AVAILABLE_TEAM_MEMBER_HEADERS = [
  {
    field: 'icon',
    headerName: '',
    headerClassName: 'super-app-theme--header',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const {
        user,
        isAvailableUserMultipleSelectEnable,
        onAvailableUserCheckboxClick,
        isAvailableUserChecked,
        onPlusIconClick,
      } = params.row.icon;
      return isAvailableUserMultipleSelectEnable ? (
        <Checkbox
          sx={{
            p: 0,
          }}
          onChange={() => onAvailableUserCheckboxClick(user)}
          value={user.id}
          checked={isAvailableUserChecked(user.id)}
          disabled={user.disabled}
        />
      ) : (
        <Box
          onClick={() => (user.disabled ? () => {} : onPlusIconClick(user))}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            bgcolor: user.disabled ? COLORS.SILVER_LILAC : COLORS.SUCCESS_MAIN,
            '&:hover': {
              cursor: user.disabled ? 'auto' : 'pointer',
            },
          }}
        >
          <img src={addIcon} alt="add-icon" />
        </Box>
      );
    },
  },
  {
    field: 'profile',
    headerName: 'Profile',
    headerClassName: 'super-app-theme--header',
    flex: 2,
    valueGetter: (params) => params.row.profile.value,
    renderCell: (params) => {
      const { value, userDisabled, profileImage, designation, getProfilePictureBrightness } =
        params.row.profile;
      return (
        <Box sx={{ display: 'flex', width: '30ch' }}>
          {profileImage ? (
            <img
              alt="profile"
              src={profileImage}
              style={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                ...getProfilePictureBrightness(userDisabled),
              }}
              referrerPolicy="no-referrer"
            />
          ) : (
            <Avatar sx={{ height: '40px', width: '40px' }}>{value?.charAt(0)}</Avatar>
          )}
          <Box sx={{ ml: '8px' }}>
            <Typography
              variant="body1"
              sx={{
                color: userDisabled ? COLORS.SILVER_LILAC : 'black',
                maxWidth: '15ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '14px',
                color: COLORS.SILVER_LILAC,
                maxWidth: '15ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {designation}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'available',
    headerName: 'Available %',
    headerClassName: 'super-app-theme--header',
    flex: 0.75,
    type: 'number',
    minWidth: 120,
    valueGetter: (params) => params.row.available.value,
    renderCell: (params) => {
      const { value, userDisabled, isOverAllocated } = params.row.available;
      return (
        <Box
          sx={{
            color: userDisabled ? COLORS.SILVER_LILAC : 'black',
            width: '10ch',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {`${value}%`}
          {isOverAllocated(value) ? (
            <Tooltip title="Over allocated during the project duration">
              <IconButton>
                <ReportProblem color="error" />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Box>
      );
    },
  },
];
//
export default AVAILABLE_TEAM_MEMBER_HEADERS;
