import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { ShimmerTable } from 'react-shimmer-effects';
//
import {
  selectDeleteSkillGroupFlag,
  selectGetSkillGroupLoader,
  selectSearch,
  selectSkillGroups,
} from 'features/skills/selectors';
import { DataGrid } from 'features/base/components';
import { SKILL_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { skillActions } from 'features/skills/slice';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import {
  SKILLS_GROUP_TABLE_BASIC_COLUMNS,
  SKILL_GROUP_TABLE_ACTION_COLUMNS,
} from 'features/base/utils/tables';
import EditSkillGroupsPopup from 'features/skills/components/edit-skill-groups/edit-skill-groups-modal';
import DeleteSkillGroupsPopup from 'features/skills/components/delete-skill-groups/delete-skill-groups-modal';

/**
 * Defines the skill group table
 * @returns {DataGrid}
 */
const SkillGroupsTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const skillUpdatePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  const skillDeletePermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.SKILL,
      action: PERMISSION_ACTIONS.DELETE,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const [pageController, setPageController] = useState({
    page: 1,
    sortBy: 'name:asc',
    rowsPerPage: SKILL_PAGINATION_LIMIT,
  });
  const [editGroupsModalOpen, setEditGroupsOpen] = useState(false);
  const [skillGroupIdToEdit, setSkillGroupIdToEdit] = useState();
  const [deleteGroupsModalOpen, setDeleteGroupsModalOpen] = useState(false);
  const [skillGroupIdToDelete, setSkillGroupIdToDelete] = useState();
  //
  const search = useSelector(selectSearch);
  const isLoading = useSelector(selectGetSkillGroupLoader);
  const skillGroups = useSelector(selectSkillGroups);
  const deleteSkillGroupFlag = useSelector(selectDeleteSkillGroupFlag);
  //
  const isFirst = useIsInitialize(skillGroups);
  //
  const handleEditClick = (skillGroupId) => {
    dispatch(
      skillActions.getSkills({
        query: `pagination=false`,
      })
    );
    setSkillGroupIdToEdit(skillGroupId);
    setEditGroupsOpen(true);
  };
  //
  const handleDeleteClick = (skillGroupId) => {
    setSkillGroupIdToDelete(skillGroupId);
    setDeleteGroupsModalOpen(true);
  };
  //
  const handleChangePage = ({ page }) => {
    setPageController({ ...pageController, page: page + 1 });
  };
  //
  useEffect(() => {
    dispatch(
      skillActions.getSkillGroups({
        query: `search=${search}&dataStatus=${DATA_STATUS_TYPES.ACTIVE}&limit=${SKILL_PAGINATION_LIMIT}&sortBy=${pageController.sortBy}`,
      })
    );
  }, [search, deleteSkillGroupFlag]);
  //
  return (
    <>
      <EditSkillGroupsPopup
        editGroupsModalOpen={editGroupsModalOpen}
        setEditGroupsOpen={setEditGroupsOpen}
        skillGroupIdToEdit={skillGroupIdToEdit}
      />
      <DeleteSkillGroupsPopup
        deleteGroupsModalOpen={deleteGroupsModalOpen}
        setDeleteGroupsModalOpen={setDeleteGroupsModalOpen}
        skillGroupIdToDelete={skillGroupIdToDelete}
      />
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }}>
        {isFirst ? (
          <ShimmerTable row={6} col={3} />
        ) : (
          <DataGrid
            columns={
              skillUpdatePermissionsVerified || skillDeletePermissionsVerified
                ? [...SKILLS_GROUP_TABLE_BASIC_COLUMNS, ...SKILL_GROUP_TABLE_ACTION_COLUMNS]
                : SKILL_GROUP_TABLE_ACTION_COLUMNS
            }
            rows={
              skillGroups?.docs?.length &&
              skillGroups?.docs?.map((skillGroup) => ({
                id: skillGroup?.id,
                name: skillGroup?.name,
                skills: skillGroup?.skills,
                action: {
                  skillUpdatePermissionsVerified,
                  skillDeletePermissionsVerified,
                  isLoading,
                  handleSkillGroupEdit: (event) => {
                    event.stopPropagation();
                    handleEditClick(skillGroup?.id);
                  },
                  handleSkillGroupDelete: (event) => {
                    event.stopPropagation();
                    handleDeleteClick(skillGroup?.id);
                  },
                },
              }))
            }
            handleChangePage={handleChangePage}
            page={pageController.page - 1}
            totalPages={skillGroups?.totalPages ?? 0}
            totalDocs={skillGroups?.totalDocs ?? 0}
            limit={skillGroups?.limit ?? pageController?.rowsPerPage}
            loading={isLoading && isFirst !== true}
            pageSizeOptions={[pageController.rowsPerPage]}
            rowHeight={skillGroups?.docs?.length ? 52 : 200}
          />
        )}
      </Container>
    </>
  );
};
//
export default SkillGroupsTable;
