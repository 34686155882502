import { DriveFileRenameOutline } from '@mui/icons-material';
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
//
import { ENVIRONMENT } from 'config';
/**
 * View client table columns - basic details
 */
export const CLIENT_TABLE_COLUMNS_BASIC = [
  {
    field: 'profile',
    headerName: 'Profile',
    width: 300,
    valueGetter: (params) => params.row.profile.name,
    renderCell: (params) => {
      const { logo, name } = params.row.profile;
      return (
        <Grid container alignItems="center">
          <Grid item sm={3}>
            {logo?.url ? (
              <img
                src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${logo?.url}`}
                alt="logo"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  height: '40px',
                  width: '40px',
                }}
              >
                {name?.charAt(0)}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                whiteSpace: 'nowrap !important',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis !important',
              }}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 300,
    valueGetter: (params) => params.row.company.value,
    renderCell: (params) => {
      const { value } = params.row.company;
      return (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'brn',
    headerName: 'BRN',
    width: 200,
    valueGetter: (params) => params.row.brn.value,
    renderCell: (params) => {
      const { value } = params.row.brn;
      return (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'projects',
    headerName: 'Projects',
    width: 100,
    valueGetter: (params) => params.row.projects.value,
    renderCell: (params) => {
      const { value } = params.row.projects;
      return <Typography variant="body1">{value}</Typography>;
    },
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    width: 160,
    valueGetter: (params) => params.row.revenue.value,
    renderCell: (params) => {
      const { value } = params.row.revenue;
      return (
        <Typography variant="body1" sx={{ width: '12ch' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'cost',
    headerName: 'Cost',
    width: 160,
    valueGetter: (params) => params.row.cost.value,
    renderCell: (params) => {
      const { value } = params.row.cost;
      return (
        <Typography variant="body1" sx={{ width: '12ch' }}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: 'margin',
    headerName: 'Margin',
    width: 160,
    valueGetter: (params) => params.row.margin.value,
    renderCell: (params) => {
      const { value } = params.row.margin;
      return (
        <Typography variant="body1" sx={{ width: '12ch' }}>
          {value}
        </Typography>
      );
    },
  },
];
/**
 * View client table columns - action columns
 */
export const CLIENTS_TABLE_ACTION_COLUMNS = [
  {
    field: 'action',
    headerName: 'Action',
    width: 100,
    sortable: false,
    renderCell: ({ value: { handleClick, isLoading } }) => (
      <IconButton onClick={handleClick} readOnly={isLoading}>
        <DriveFileRenameOutline />
      </IconButton>
    ),
  },
];
