import { useEffect } from 'react';
import { Button, Grid, Box, Dialog, DialogActions, Typography, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
//
import { selectDeleteBenefitsLoader } from 'features/benefits/selectors';
import deleteIcon from 'features/base/assets/images/svg/error-mark.svg';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { benefitsActions } from 'features/benefits/slice';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { PermissionWrapper } from 'features/base/auth/components';

/**
 * Function that defines the popup form for delete tool
 * @prop {boolean} deleteModalOpen - boolean to show/hide the popup
 * @prop {function} setDeleteModalOpen - function to set the popup state
 * @prop {string} benefitId - id of the benefit to be deleted
 * @returns {Popup}
 */
const DeleteBenefitsPopup = ({ deleteModalOpen, setDeleteModalOpen, benefitId }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectDeleteBenefitsLoader);
  //
  const handleDeletePopupOnClose = () => {
    setDeleteModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (deleteModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleDeletePopupOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.BENEFIT,
          action: PERMISSION_ACTIONS.DELETE,
        },
      ]}
      hide
    >
      <Dialog
        open={deleteModalOpen}
        onClose={handleDeletePopupOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: 50 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '20px' }}>
          <img src={deleteIcon} alt="delete" width="71.97px" />
        </Box>
        <DialogTitle
          id="alert-dialog-title"
          variant="h5"
          sx={{ textAlign: 'center', fontWeight: 'bold' }}
        >
          Are you sure?
        </DialogTitle>
        <Typography
          variant="body2"
          fontWeight="600"
          sx={{ textAlign: 'center', mx: 13, color: COLORS.GRAYISH_PURPLE }}
        >
          Do you really want to delete this item?
        </Typography>
        <DialogActions
          sx={{
            justifyContent: 'space-around',
            pb: 5,
          }}
        >
          <Box className="delete-popup-box">
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnSpacing={3}
            >
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  sx={{
                    borderRadius: 0,
                    border: 1,
                    textTransform: 'none',
                    width: {
                      xs: '100%',
                      sm: '130px',
                    },
                  }}
                  onClick={handleDeletePopupOnClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  sx={{
                    borderRadius: 0,
                    textTransform: 'none',
                    width: {
                      xs: '100%',
                      sm: '151px',
                    },
                  }}
                  onClick={() =>
                    dispatch(
                      benefitsActions.deleteBenefits({
                        id: benefitId,
                      })
                    )
                  }
                  endIcon={loading ? <img src={loaderIcon} alt="button-icon" /> : null}
                >
                  {loading ? 'Deleting' : 'Delete'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </PermissionWrapper>
  );
};
//
export default DeleteBenefitsPopup;
