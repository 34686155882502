import { Alert, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
//
import { linkActions } from 'features/links/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectLinkById,
  selectAllDepartment,
  selectUploadedLogo,
  selectEditLinkLoader,
  selectFileUploadLoader,
} from 'features/links/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import { TextField, Popup, FileUpload } from 'features/base/components';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import ERROR_TYPES from 'features/base/constants/error-types';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import {
  getAllowedExtensionsString,
  verifyExtensions,
} from 'features/base/helpers/file';
import {
  LINK_LOGO_ALLOWED_FILE_TYPES,
  LINK_LOGO_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import MultiSelectAutoComplete from 'features/base/components/multi-select-auto-complete';
import linkFormValidation from 'features/links/validations/link-validation';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { removeUnchanged } from 'features/base/helpers/object';

/**
 * Function that defines the popup form for adding a new user
 * @prop {boolean} editModalOpen - boolean to show/hide the popup
 * @prop {function} setEditModalOpen - function to set the popup state
 * @prop {string} linkId - id of the link to be edited
 * @returns {Popup}
 */
const EditLinksPopup = ({ editModalOpen, setEditModalOpen, linkId }) => {
  const dispatch = useDispatch();
  //
  const linkToEditId = linkId;
  //
  const linkToEdit = useSelector((state) => selectLinkById(state, linkToEditId));
  const notification = useSelector(selectNotification);
  const uploadedLogo = useSelector(selectUploadedLogo);
  const loading = useSelector(selectEditLinkLoader);
  const fileUploadLoading = useSelector(selectFileUploadLoader);
  const allDepartments = useSelector(selectAllDepartment);
  //
  const [logo, setLogo] = useState([]);
  const [fileTypeError, setFileTypeError] = useState('');
  //
  const handleOnClose = () => {
    setEditModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(linkActions.clearLogo());
    setFileTypeError('');
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(Object.values(logo), LINK_LOGO_ALLOWED_EXTENSIONS);
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      formData.append('file', logo[0]);
      if (logo.length > 0) {
        dispatch(linkActions.uploadLogo({ files: formData }));
      }
    } else {
      setFileTypeError(true);
    }
  }, [logo]);
  //
  const initialValues = {
    name: linkToEdit?.name,
    description: linkToEdit?.description,
    link: linkToEdit?.link,
    departments: linkToEdit?.departments
      .filter((dep) => dep.dataStatus === DATA_STATUS_TYPES.ACTIVE)
      .map((dep) => dep.departmentId),
  };
  //
  const getInputLabel = () => {
    if (uploadedLogo) {
      return uploadedLogo.name;
    }
    if (linkToEdit?.logo) {
      return linkToEdit?.logo.name;
    }
    return 'Choose logo';
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.LINK,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
    >
      <Popup
        open={editModalOpen}
        onClose={handleOnClose}
        title="Edit Link"
        mediumSize="660px"
        height="700px"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={linkFormValidation}
          onSubmit={(values) => {
            let linkData = { ...values };
            linkData.link = encodeURI(linkData.link);
            linkData = removeUnchanged(initialValues, linkData);
            if (linkData?.departments) {
              linkData.departments = linkData?.departments?.map((department) => department?.id);
            }
            if (uploadedLogo) {
              linkData.logo = { ...uploadedLogo, url: encodeURI(uploadedLogo?.url) };
            }
            dispatch(linkActions.editLink({ id: linkToEditId, ...linkData }));
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="form">
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert sx={{ mb: 3 }} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <FileUpload
                setLogo={setLogo}
                label="Logo"
                value={values.logo}
                acceptedTypes={LINK_LOGO_ALLOWED_FILE_TYPES}
                error={fileTypeError}
                errorMsg={`Allowed extensions are ${getAllowedExtensionsString(
                  LINK_LOGO_ALLOWED_EXTENSIONS
                )}`}
                loading={fileUploadLoading}
                inputLabel={getInputLabel()}
              />
              <TextField
                type="text"
                name="name"
                value={values.name}
                error={Boolean(touched?.name && errors?.name)}
                helperText={touched?.name && errors?.name}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Name"
              />
              <TextField
                type="text"
                name="description"
                value={values.description}
                error={Boolean(touched?.description && errors?.description)}
                helperText={touched?.description && errors?.description}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Description"
                my={2}
                height={120}
                multiline
                rows={4}
              />
              <TextField
                type="text"
                name="link"
                value={values.link}
                error={Boolean(touched?.link && errors?.link)}
                helperText={touched?.link && errors?.link}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Link"
                fullWidth
              />
              <Typography fontSize="16px" className="field-label">
                Departments
              </Typography>
              <MultiSelectAutoComplete
                id="departments"
                name="departments"
                options={allDepartments}
                selectedOptions={values.departments}
                setSelectedOptions={setFieldValue}
                formikKey="departments"
                placeholder="Select department"
                error={Boolean(touched.departments && errors.departments)}
                errorMsg={touched.departments && errors.departments}
                onClose={() => setFieldTouched('departments', true)}
              />
              <ButtonGrid
                leftButtonText="Cancel"
                rightButtonText={loading ? 'Submitting' : 'Submit'}
                leftOnClick={handleOnClose}
                rightOnClick={null}
                submitDisabled={loading || fileUploadLoading}
                rightIcon={loading ? loaderIcon : null}
              />
            </form>
          )}
        </Formik>
      </Popup>
    </PermissionWrapper>
  );
};
//
export default EditLinksPopup;
