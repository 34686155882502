import * as yup from 'yup';
/**
 * Function for desigantion form validation
 */
export const editDesignationFormValidation = yup.object().shape({
  name: yup.string().required('Please enter valid designation name'),
});
/**
 * Function for designation form validation
 */
const designationFormValidations = yup.object().shape({
  designations: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Please Enter a valid Designation'),
      })
    )
    .min(1),
});
//
export default designationFormValidations;
