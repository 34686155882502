import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { ShimmerContentBlock } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
//
import { supportTicketActions } from 'features/support-tickets/slice';
import { COMMENT_TYPES } from 'features/base/constants/comment-type';
import { selectNotification } from 'features/base/notifications/selectors';
import {
  selectAllComments,
  selectCommentLoading,
  selectIsCommentAdding,
  selectIsCommentAttachmentAdding,
  selectIsInitialCommentFetching,
} from 'features/support-tickets/selectors';
import { selectUserId } from 'features/base/auth/selectors';
import commentSendIcon from 'features/base/assets/images/svg/comment-send.svg';
import commentAttachmentIcon from 'features/base/assets/images/svg/comment-attachment.svg';
import Popup from 'features/base/components/modal';
import './index.scss';
import COLORS from 'features/base/constants/colors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import TIME_OUTS from 'features/base/constants/time-outs';
import {
  SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES,
  SUPPORT_TICKET_ALLOWED_EXTENSIONS,
} from 'features/base/constants/file-upload';
import { getAllowedExtensionsString, verifyExtensions } from 'features/base/helpers/file';
import { removeUUIDv4Prefix } from 'features/base/helpers/url-parser';
import { ENVIRONMENT } from 'config';
/**
 * Function that defines the popup form for adding a new project
 * @prop {boolean} commentSectionModelOpen - boolean to show/hide the popup
 * @prop {function} setCommentSectionModelOpen - function to set the popup state
 * @prop {string} ticketId - ticket id
 * @returns {Popup}
 */
const CommentSectionPopup = ({
  commentSectionModelOpen,
  setCommentSectionModelOpen,
  ticketId,
  setTicketId,
}) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const allComments = useSelector(selectAllComments);
  const addedBy = useSelector(selectUserId);
  const loading = useSelector(selectCommentLoading);
  const isInitialCommentFetching = useSelector(selectIsInitialCommentFetching);
  const isCommentAdding = useSelector(selectIsCommentAdding);
  const isCommentAttachmentAdding = useSelector(selectIsCommentAttachmentAdding);
  //
  const [attachment, setAttachment] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(true);
  const [message, setMessage] = useState('');
  const scrollElement = useRef(null);
  const commentInterval = useRef(null);
  //
  const handleOnClose = () => {
    setCommentSectionModelOpen(false);
    dispatch(supportTicketActions.clearComment());
    dispatch(supportTicketActions.setIsInitialCommentFetching(false));
    setTicketId('');
  };
  //
  const scrollToElement = () => {
    scrollElement?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  //
  const submitCommentPress = (e) => {
    e.preventDefault();
    dispatch(
      supportTicketActions.addComments({
        id: ticketId,
        message,
      })
    );
    setMessage('');
  };
  //
  const getComment = (comment) => {
    if (comment.type === COMMENT_TYPES.ATTACHMENT) {
      return (
        <a
          href={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${comment.message}`}
          target="_blank"
          rel="noreferrer"
        >
          {removeUUIDv4Prefix(comment?.message?.split('/comments/')[1]) || 'Attachment'}
        </a>
      );
    }
    if (comment.type === COMMENT_TYPES.TEXT) {
      return comment?.message;
    }
    return comment.message;
  };
  //
  const getComments = () => {
    if (commentSectionModelOpen) {
      dispatch(supportTicketActions.getComments({ id: ticketId }));
    }
  };
  //
  useEffect(() => {
    const validated = verifyExtensions(
      Object.values(attachment),
      SUPPORT_TICKET_ALLOWED_EXTENSIONS
    );
    if (validated) {
      setFileTypeError(false);
      const formData = new FormData();
      Object.keys(attachment).forEach((key) => formData.append('file', attachment[key]));
      if (attachment.length > 0) {
        dispatch(
          supportTicketActions.addCommentsAttachments({
            id: ticketId,
            files: formData,
          })
        );
      }
    } else {
      setFileTypeError(true);
    }
  }, [attachment]);
  //
  useEffect(() => {
    if (!loading && allComments.length) {
      scrollToElement();
    }
  }, [isInitialCommentFetching, allComments.length]);
  //
  useEffect(() => {
    scrollToElement();
  }, [allComments.length]);
  //
  useEffect(() => {
    commentInterval.current = setInterval(getComments, TIME_OUTS.COMMENT_REFETCH);
    return () => clearInterval(commentInterval.current);
  }, [commentSectionModelOpen]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR) {
      toast(notification?.message, { type: TOAST_TYPES.ERROR });
      if (commentSectionModelOpen) {
        setCommentSectionModelOpen(false);
      }
    }
  }, [notification]);
  //
  return (
    <Popup
      open={commentSectionModelOpen}
      onClose={handleOnClose}
      title="Comments"
      mediumSize="500px"
    >
      {loading && isInitialCommentFetching ? (
        <Box className="comment-body">
          <ShimmerContentBlock card={false} thumbnailWidth={300} thumbnailHeight={10} />
          <br />
          <ShimmerContentBlock card={false} thumbnailWidth={400} thumbnailHeight={30} reverse />
          <br />
          <ShimmerContentBlock card={false} thumbnailWidth={250} thumbnailHeight={20} />
          <br />
          <ShimmerContentBlock card={false} thumbnailWidth={300} thumbnailHeight={5} reverse />
        </Box>
      ) : (
        <>
          <Box className="comment-body">
            <div className="modal-card-body comment-model-container">
              {(allComments?.length &&
                allComments?.map((comment) => (
                  <div
                    key={`${comment?.addedBy?.id}${comment?.updatedAt}`}
                    className={[
                      'comment-container',
                      comment.addedBy.id === addedBy && 'comment-sender',
                    ].join(' ')}
                  >
                    <Typography className="comment-message-author" variant="body2">
                      {comment.addedBy.firstName.concat(' ', comment.addedBy.lastName)}
                    </Typography>
                    <div
                      className={[
                        'card comment-card',
                        comment.addedBy.id === addedBy && 'sender-card',
                      ].join(' ')}
                    >
                      <Typography className="comment-message" variant="body2">
                        {getComment(comment)}
                      </Typography>
                      <Typography className="comment-message-time" variant="body2">
                        {new Date(comment.timestamp).toLocaleString()}
                      </Typography>
                    </div>
                  </div>
                ))) || (
                <Box sx={{ justifyContent: 'center', alignContent: 'center', py: '23%' }}>
                  <Typography
                    variant="h5"
                    sx={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      textAlign: 'center',
                      color: COLORS.LAVENDER_GRAY,
                    }}
                  >
                    No Comments
                  </Typography>
                </Box>
              )}
              <Box ref={scrollElement} />
            </div>
          </Box>
          <Box className="comment-form">
            <TextField
              id="outlined-search"
              label="Your Comment"
              type="text"
              size="small"
              value={message}
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
            />
            <input
              accept={SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES.join(', ')}
              style={{ display: 'none' }}
              id="upload-input"
              multiple
              type="file"
              onChange={(e) => setAttachment(e.target.files)}
            />
            {isCommentAttachmentAdding ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pr: 1,
                  pl: 2,
                }}
              >
                <CircularProgress size={25} />
              </Box>
            ) : (
              <label htmlFor="upload-input">
                <Box margin="auto" sx={{ pr: 1, pl: 2 }}>
                  <img src={commentAttachmentIcon} alt="commentIcon" className="commentIcon" />
                </Box>
              </label>
            )}
            <Box>
              {isCommentAdding ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={25} />
                </Box>
              ) : (
                <Box onClick={submitCommentPress}>
                  <img src={commentSendIcon} alt="commentIcon" className="commentIcon" />
                </Box>
              )}
            </Box>
          </Box>
          <Typography variant="subtitle2" color="error" mt={1}>
            {fileTypeError &&
              `Only ${getAllowedExtensionsString(
                SUPPORT_TICKET_ALLOWED_EXTENSIONS
              )} files are allowed`}
          </Typography>
        </>
      )}
    </Popup>
  );
};
//
export default CommentSectionPopup;
