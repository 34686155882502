import { createSelector } from '@reduxjs/toolkit';
//
import { initialState } from './slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/benefits'] || initialState;
/**
 * Selector for getting loading state
 */
export const selectLoader = createSelector([selectDomain], (benefitState) => benefitState.loading);
/**
 * Selector for getting file upload loading state
 */
export const selectFileUploadLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.uploadFileLoading
);
/**
 * Selector for getting the uploaded logo
 */
export const selectUploadedAttachments = createSelector(
  [selectDomain],
  (benefitState) => benefitState.uploadedAttachments
);
/**
 * Function to retrieve all departments
 */
export const selectDepartments = createSelector(
  [selectDomain],
  (benefitState) => benefitState.departments
);
/**
 * Function to retrieve all benefits from the benefit slice
 */
export const selectAllBenefits = createSelector(
  [selectDomain],
  (benefitState) => benefitState.benefits
);
/**
 * Selector for getting a benefit by id
 */
export const selectBenefitById = createSelector(
  [selectAllBenefits, (_, benefitId) => benefitId],
  (benefitList, benefitId) => benefitList.docs?.find((benefit) => benefit.id === benefitId) || null
);
/**
 * Function to retrieve departments loading state
 */
export const selectDepartmentsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.departmentsLoading
);
/**
 * Function to retrieve add benefit laoding state
 */
export const selectAddBenefitsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.addBenefitLoading
);
/**
 * Function to retrieve add benefit succeeded state
 */
export const selectAddBenefitsSucceeded = createSelector(
  [selectDomain],
  (benefitState) => benefitState.benefitAddSucceeded
);
/**
 * Function to retrieve added benefit from the benefit slice
 */
export const selectAddedBenefit = createSelector(
  [selectDomain],
  (benefitState) => benefitState.addedBenefit
);
/**
 * Function to retrieve edit benefit laoding state
 */
export const selectEditBenefitsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.editBenefitLoading
);
/**
 * Function to retrieve delete benefit laoding state
 */
export const selectDeleteBenefitsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.deleteBenefitLoading
);
/**
 * Function to retrieve vendors in multi step form
 */
export const selectVendorsMultiStepForm = createSelector(
  [selectDomain],
  (benefitState) => benefitState?.multiStepForm?.vendors
);
/**
 * Function to retrieve vendors loading state in multi step form
 */
export const selectVendorsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState?.multiStepForm?.vendorsLoading
);
/**
 * Function to retrieve refine users in multi step form
 * @param {Object} state
 */
export const selectRefineUsers = createSelector(
  [selectDomain],
  (benefitState) => benefitState?.multiStepForm?.refineUsers
);
/**
 * Function to retrieve refine users loading state in multi step form
 */
export const selectRefineUsersLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState?.multiStepForm?.refineUsersLoading
);
/**
 * Function to retrieve
 */
export const selectAddPromotionsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.addPromotionsLoading
);
/**
 * Function to retrieve promotions add succeeded state
 */
export const selectPromotionsAddSucceeded = createSelector(
  [selectDomain],
  (benefitState) => benefitState.promotionsAddSucceeded
);
/**
 * Function to retrieve redeemed benefit
 */
export const selectRedeemedBenefit = createSelector(
  [selectDomain],
  (benefitState) => benefitState.redeemedBenefit
);
/**
 * Function to retrieve redeem benefit loading state
 */
export const selectRedeemBenefitLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.redeemBenefitLoading
);
/**
 * Function to retrieve edit benefit promotions
 */
export const selectEditBenefitPromotions = createSelector(
  [selectDomain],
  (benefitState) => benefitState.editBenefitPromotions
);
/**
 * Function to retrieve edit benefit promotions loading state
 */
export const selectEditBenefitPromotionsLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.editBenefitPromotionsLoading
);
/**
 * Function to retrieve delete promotion loading state
 */
export const selectDeletePromotionLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.deletePromotionLoading
);
/**
 * Function to retrieve delete promotion response
 */
export const selectDeletePromotionResponse = createSelector(
  [selectDomain],
  (benefitState) => benefitState.deletePromotionResponse
);
/**
 * Function to retrieve add promotion loading state
 */
export const selectAddPromotionLoader = createSelector(
  [selectDomain],
  (benefitState) => benefitState.addPromotionLoading
);
/**
 * Function to retrieve add promotion response
 */
export const selectAddPromotionResponse = createSelector(
  [selectDomain],
  (benefitState) => benefitState.addPromotionResponse
);
