import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
//
import { supportTicketActions } from 'features/support-tickets/slice';
import { notificationActions } from 'features/base/notifications/slice';
import {
  selectAddSupportTicketLoader,
  selectUploadedAttachments,
  selectRelatedProjectsClient,
  selectAttachmentLoader,
  selectItSupportProjects,
} from 'features/support-tickets/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import { Autocomplete, Select, TextField, Popup, DragAndDropZone } from 'features/base/components';
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import { USER_TYPES } from 'features/base/constants/user-types';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import { selectAuthUser } from 'features/base/auth/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import { capitalizeFirstLetter } from 'features/base/helpers/strings';
import supportTicketValidation from 'features/support-tickets/validation/support-ticket-validation';
import {
  SUPPORT_TICKET_ALLOWED_EXTENSIONS,
  SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES,
  SUPPORT_TICKET_ATTACHMENT_MAX_SIZE,
} from 'features/base/constants/file-upload';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import { processFiles } from 'features/base/helpers/file';
import { SUPPORT_TICKET_TYPES } from 'features/base/constants/support-ticket-types';

/**
 * Function that defines the popup form for adding a new project
 * @prop {boolean} addModalOpen - boolean to show/hide the popup
 * @prop {function} setAddModalOpen - function to set the popup state
 * @returns {Popup}
 */
const AddSupportTicketsPopup = ({ addModalOpen, setAddModalOpen }) => {
  const dispatch = useDispatch();
  //
  const relatedProjectsClient = useSelector(selectRelatedProjectsClient);
  const itSupportProjects = useSelector(selectItSupportProjects);
  const authUser = useSelector(selectAuthUser);
  const notification = useSelector(selectNotification);
  const loading = useSelector(selectAddSupportTicketLoader);
  const uploadedAttachments = useSelector(selectUploadedAttachments);
  const attachmentLoader = useSelector(selectAttachmentLoader);
  //
  const [projectId, setProjectId] = useState('');
  //
  const handleOnClose = () => {
    setAddModalOpen(false);
    dispatch(notificationActions.resetNotification());
    dispatch(supportTicketActions.clearAttachments());
    setProjectId('');
  };
  //
  const handleOnDeleteFile = (fileToRemove) => {
    dispatch(
      supportTicketActions.setAttachments(
        uploadedAttachments?.filter(
          (uploadedAttachment) => uploadedAttachment?.name !== fileToRemove?.name
        )
      )
    );
  };
  //
  const handleOnAddFiles = (filesToAdd) => {
    if (filesToAdd?.length > 0) {
      const formData = processFiles(filesToAdd);
      dispatch(supportTicketActions.uploadAttachments({ files: formData }));
    }
  };
  //
  useEffect(() => {
    if (addModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <Popup
      open={addModalOpen}
      onClose={handleOnClose}
      title="Submit a support ticket"
      subTitle="Please fill in the form below to submit a support ticket. The project manager will be notified regarding your support ticket."
      mediumSize="888px"
    >
      <Formik
        initialValues={{
          title: '',
          description: '',
          project: '',
          severity: 'Select',
          type: 'Select',
        }}
        validationSchema={supportTicketValidation}
        onSubmit={(values) => {
          const { project, ...rest } = values;
          dispatch(
            supportTicketActions.addSupportTickets({
              ...rest,
              projectId,
              attachments: uploadedAttachments.map((attachment) => ({
                ...attachment,
                url: encodeURI(attachment.url),
              })),
            })
          );
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert sx={{ mb: 3 }} severity={notification?.type}>
                {notification?.message}
              </Alert>
            )}
            <Autocomplete
              id="project"
              name="project"
              placeholder="Select"
              options={(() => {
                if (
                  authUser?.type === USER_TYPES.INTERNAL ||
                  authUser?.type === USER_TYPES.INTERN ||
                  authUser?.type === USER_TYPES.CONTRACT
                ) {
                  return (
                    itSupportProjects?.map((project) => ({
                      id: project?.id,
                      label: project?.name,
                    })) ?? []
                  );
                }
                return (
                  relatedProjectsClient?.map((record) => ({
                    id: record?.projectId?.id,
                    label: record?.projectId?.name,
                  })) ?? []
                );
              })()}
              label="Select project or scope"
              onChange={(_, newValue) => {
                setFieldValue('project', newValue?.label || null);
                setProjectId(newValue?.id);
              }}
              isOptionEqualToValue={(option, autoValue) =>
                option.label === autoValue || autoValue === null
              }
              controlled
              value={values.project}
              error={Boolean(touched.project && errors.project)}
              errorMsg={touched?.project && errors?.project}
              onClose={() => setFieldTouched('project', false)}
              sx={{
                pb: 2,
              }}
              getOptionDisabled={(option) => {
                if (
                  authUser?.type === USER_TYPES.INTERNAL ||
                  authUser?.type === USER_TYPES.INTERN ||
                  authUser?.type === USER_TYPES.CONTRACT
                ) {
                  return (
                    itSupportProjects?.find((project) => project?.id === option?.id)?.dataStatus !==
                    DATA_STATUS_TYPES.ACTIVE
                  );
                }
                return (
                  relatedProjectsClient?.find((record) => record?.projectId?.id === option?.id)
                    ?.dataStatus !== DATA_STATUS_TYPES.ACTIVE ||
                  relatedProjectsClient?.find((record) => record?.projectId?.id === option?.id)
                    ?.projectId?.dataStatus !== DATA_STATUS_TYPES.ACTIVE ||
                  relatedProjectsClient
                    ?.find((record) => record?.projectId?.id === option?.id)
                    ?.projectId?.externalNotifiers?.find((user) => user === authUser?.id) ===
                    undefined
                );
              }}
            />
            <TextField
              type="text"
              name="title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Title"
            />
            <TextField
              type="text"
              name="description"
              value={values.description}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              my={2}
              label="Description"
              height={120}
              multiline
              rows={4}
            />
            <Select
              id="severity"
              name="severity"
              value={values.severity}
              onChange={(event) => {
                setFieldValue('severity', event.target.value);
              }}
              items={[
                { key: '0', value: 'Select' },
                { key: '1', value: SEVERITY_TYPES.LOW },
                { key: '2', value: SEVERITY_TYPES.MEDIUM },
                { key: '3', value: SEVERITY_TYPES.HIGH },
              ]}
              textLabel="Severity"
              stringFormat={capitalizeFirstLetter}
              error={Boolean(touched.severity && errors.severity)}
              errorMsg={touched.severity && errors.severity}
            />
            <Select
              id="type"
              name="type"
              value={values.type}
              onChange={(event) => {
                setFieldValue('type', event.target.value);
              }}
              items={[
                { key: '0', value: 'Select' },
                { key: '1', value: SUPPORT_TICKET_TYPES.BUG },
                { key: '2', value: SUPPORT_TICKET_TYPES.TASK },
              ]}
              textLabel="Type"
              stringFormat={capitalizeFirstLetter}
              error={Boolean(touched.type && errors.type)}
              errorMsg={touched.type && errors.type}
            />
            <DragAndDropZone
              label="Upload attachments"
              allowedExtensions={SUPPORT_TICKET_ALLOWED_EXTENSIONS}
              allowedMimetypes={SUPPORT_TICKET_ATTACHMENTS_ALLOWED_FILE_TYPES}
              maxSize={SUPPORT_TICKET_ATTACHMENT_MAX_SIZE}
              loading={attachmentLoader}
              disabled={loading}
              onAdd={handleOnAddFiles}
              onDelete={handleOnDeleteFile}
              uploadedFiles={uploadedAttachments}
            />
            <ButtonGrid
              leftButtonText="Cancel"
              rightButtonText={loading ? 'Submitting' : 'Submit'}
              leftOnClick={handleOnClose}
              rightOnClick={null}
              rightIcon={loading ? loaderIcon : null}
              submitDisabled={loading || attachmentLoader}
            />
          </form>
        )}
      </Formik>
    </Popup>
  );
};
//
export default AddSupportTicketsPopup;
