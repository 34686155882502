import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'features/profile/slice';
/**
 * Function to select the slice domain
 * @param {Object} state
 * @returns {Object}
 */
const selectDomain = (state) => state['feature/profile'] || initialState;
/**
 * Function to retrieve the user state of the profile slice
 */
export const selectUser = createSelector([selectDomain], (profileState) => profileState.user);
/**
 * Function to retrieve the loader state of the profile slice
 */
export const selectLoader = createSelector([selectDomain], (profileState) => profileState.loading);
/**
 * Function to retrieve the department designation ids from the profile slice
 */
export const selectDepartmentDesignations = createSelector(
  [selectDomain],
  (profileState) => profileState.departmentDesignations
);
/**
 * Function to retrieve the my allocation from the profile slice
 */
export const selectMyAllocations = createSelector(
  [selectDomain],
  (profileState) => profileState.myAllocations
);
/**
 * Function to retrieve the is my allocation fetching from the profile slice
 */
export const selectIsMyAllocationsFetching = createSelector(
  [selectDomain],
  (profileState) => profileState.isMyAllocationsFetching
);
/**
 * Function to retrieve the user skills from the profile slice
 */
export const selectUserSkills = createSelector(
  [selectDomain],
  (profileState) => profileState.userSkills
);
/**
 * Function to retrieve the skill groups from the profile slice
 */
export const selectAllSkillGroups = createSelector(
  [selectDomain],
  (profileState) => profileState.allSkillGroups
);
/**
 * Selector for getting add skill group loader state
 */
export const selectUserSkillLoading = createSelector(
  [selectDomain],
  (profileState) => profileState.userSkillLoading
);
/**
 * Selector for getting add skill group loader state
 */
export const selectDepartmentsLoader = createSelector(
  [selectDomain],
  (profileState) => profileState.departmentsLoading
);
/**
 * Selector for getting add skill group loader state
 */
export const selectAddUserSkillLoader = createSelector(
  [selectDomain],
  (profileState) => profileState.addUserSkillLoading
);
/**
 * Selector for getting add skill group loader state
 */
export const selectUpdateUserSkillLoader = createSelector(
  [selectDomain],
  (profileState) => profileState.updateUserSkillLoading
);
/**
 * Selector for getting add skill group loader state
 */
export const selectDeleteUserSkillLoader = createSelector(
  [selectDomain],
  (profileState) => profileState.deleteUserSkillLoading
);
/**
 * Selector for updating user loader state
 */
export const selectUpdateUserLoader = createSelector(
  [selectDomain],
  (profileState) => profileState.updateUserLoading
);
/**
 * Selector for updating user loader state
 */
export const selectGetSkillGroupsLoading = createSelector(
  [selectDomain],
  (profileState) => profileState.getSkillGroupsLoading
);
/**
 * Selector for getting the user client
 */
export const selectClient = createSelector([selectDomain], (clientState) => clientState.client);
/**
 * Selector for getting client fetching loading state
 */
export const selectIsClientFetching = createSelector(
  [selectDomain],
  (clientState) => clientState.isClientFetching
);