import { Formik } from 'formik';
import { useEffect } from 'react';
import { Box, Typography, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//
import { selectSuccessfully, selectLoader } from 'features/internal-onboarding/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import ButtonGrid from 'features/base/components/left-right-btn-grid';
import PhoneInput from 'features/base/components/phone-input';
import { CommonBackground } from 'features/base/components';
import { internalOnboardingSliceActions } from 'features/internal-onboarding/slice';
import { notificationActions } from 'features/base/notifications/slice';
import ROUTES from 'features/base/constants/routes';
import ERROR_TYPES from 'features/base/constants/error-types';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import 'react-phone-input-2/lib/style.css';
import internalOnboardValidation from 'features/internal-onboarding/validation/internal-onboard-validation';
import { authActions } from 'features/base/auth/slice';
import COLORS from 'features/base/constants/colors';
import { selectUserId } from 'features/base/auth/selectors';
import './index.scss';

/**
 * Function that defines the popup form for Internal user onboarding
 * @returns {Popup}
 */
const InternalOnboardingForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const loading = useSelector(selectLoader);
  const successfully = useSelector(selectSuccessfully);
  const notification = useSelector(selectNotification);
  const authUserId = useSelector(selectUserId);
  //
  const initialValues = {
    phoneNumber: '',
  };
  //
  const handleOnClose = () => {
    dispatch(authActions.logout());
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (successfully) {
      dispatch(notificationActions.resetNotification());
      navigate(ROUTES.LINK_HUB);
    }
  }, [successfully]);
  //
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={internalOnboardValidation}
      onSubmit={async (values) => {
        dispatch(
          internalOnboardingSliceActions.internalOnboarding({
            ...values,
            onboarding: true,
            userId: authUserId,
          })
        );
      }}
    >
      {({ errors, handleSubmit, handleBlur, touched }) => (
        <CommonBackground>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '660px',
                },
                backgroundColor: COLORS.WHITE,
              }}
            >
              <form noValidate onSubmit={handleSubmit} className="form2">
                <Box>
                  <Typography variant="h4" className="form-title">
                    Onboarding
                  </Typography>
                </Box>
                {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                  <Alert sx={{ mb: 3 }} severity={notification?.type}>
                    {notification?.message}
                  </Alert>
                )}
                <PhoneInput
                  name="phoneNumber"
                  id="phoneNumber"
                  country="lk"
                  inputStyle={{
                    fontSize: '16px',
                    width: '100%',
                    height: '40px',
                    borderRadius: '0px',
                    lineHeight: '20px',
                  }}
                  buttonStyle={{ backgroundColor: COLORS.WHITE }}
                  fullWidth="true"
                  containerStyle={{ width: '100%' }}
                  onBlur={handleBlur}
                  countryCodeEditable="true"
                  placeholder=""
                  label="Phone number"
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  errorMsg={touched?.phoneNumber && errors?.phoneNumber}
                />
                <ButtonGrid
                  leftButtonText="Cancel"
                  leftOnClick={handleOnClose}
                  rightButtonText={loading ? 'Submitting' : 'Submit'}
                  rightIcon={loading ? loaderIcon : null}
                  submitDisabled={loading}
                />
              </form>
            </Box>
          </Box>
        </CommonBackground>
      )}
    </Formik>
  );
};
//
export default InternalOnboardingForm;
