import { useEffect } from 'react';
import { Button, Grid, Box, Dialog, DialogActions, Typography, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
//
import {
  selectToBeReallocatedAllocation,
  selectToBeReallocatedAllocationLoading,
} from 'features/reports/selectors';
import deleteIcon from 'features/base/assets/images/svg/error-mark.svg';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { reportActions } from 'features/reports/slice';
import { MONTHS, ISO_WITHOUT_TIME } from 'features/base/constants/date-formatting';
import loaderIcon from 'features/base/assets/images/gif/loader.gif';
import COLORS from 'features/base/constants/colors';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
/**
 * Function that defines the popup form for delete hours
 * @prop {boolean} removeModalOpen - boolean to show/hide the popup
 * @prop {function} setRemoveModalOpen - function to set the popup state
 * @prop {Object} selectedAllocation - allocation object to be deleted
 * @returns {Popup}
 */
const DeleteHoursPopup = ({ removeModalOpen, setRemoveModalOpen, selectedAllocation }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const toBeReallocatedAllocationLoading = useSelector(selectToBeReallocatedAllocationLoading);
  const toBeReallocatedAllocation = useSelector(selectToBeReallocatedAllocation);
  //
  const handleDeletePopupOnClose = () => {
    setRemoveModalOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (removeModalOpen && notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      handleDeletePopupOnClose();
    }
  }, [notification]);
  //
  useEffect(() => {
    if (removeModalOpen) {
      dispatch(reportActions.getAllocationToBeReallocated({ allocationId: selectedAllocation.id }));
    } else {
      dispatch(reportActions.resetAllocationToBeReallocated());
    }
  }, [removeModalOpen]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.PROJECT_REVENUE_REPORT,
          action: PERMISSION_ACTIONS.UPDATE,
        },
      ]}
      hide
    >
      <Dialog open={removeModalOpen} onClose={handleDeletePopupOnClose} sx={{ borderRadius: 50 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '20px' }}>
          <img src={deleteIcon} alt="delete" width="71.97px" />
        </Box>
        <DialogTitle
          id="alert-dialog-title"
          variant="h5"
          sx={{ textAlign: 'center', fontWeight: 'bold' }}
        >
          Are you sure?
        </DialogTitle>
        <Typography
          variant="body2"
          fontWeight="600"
          sx={{ textAlign: 'center', mx: 13, color: COLORS.GRAYISH_PURPLE }}
        >
          {`Should ${selectedAllocation?.userId?.email} be removed from the ${
            selectedAllocation?.projectId?.name
          } project from
          ${MONTHS[selectedAllocation.month - 1]} ${selectedAllocation?.year} 
          onwards? `}
        </Typography>
        <DialogActions
          sx={{
            justifyContent: 'space-around',
            pb: 5,
          }}
        >
          <Box className="delete-popup-box">
            <Grid
              container
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              columnSpacing={3}
            >
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  sx={{
                    borderRadius: 0,
                    border: 1,
                    textTransform: 'none',
                    width: {
                      xs: '100%',
                      sm: '130px',
                    },
                  }}
                  onClick={handleDeletePopupOnClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto" marginTop={2}>
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  sx={{
                    borderRadius: 0,
                    textTransform: 'none',
                    width: {
                      xs: '100%',
                      sm: '151px',
                    },
                  }}
                  onClick={() => {
                    const { month, year } = selectedAllocation;
                    const newAllocation = toBeReallocatedAllocation?.allocation
                      ?.map((allocation) => {
                        // allocation in the previous year should be deleted
                        if (parseInt(allocation.year, 10) < year) {
                          return allocation;
                        }
                        // allocation in the same year and previous month should be deleted
                        if (
                          parseInt(allocation.year, 10) === year &&
                          MONTHS.indexOf(allocation.month) + 1 < month
                        ) {
                          return allocation;
                        }
                        return undefined;
                      })
                      .filter((record) => record !== undefined);
                    dispatch(
                      reportActions.patchAllocationReportAllocation({
                        allocation: newAllocation,
                        isAllocatedPercentageLocked: true,
                        allocationId: selectedAllocation?.id,
                        startDate: toBeReallocatedAllocation?.startDate,
                        // craft end date based on 'month' and 'year' of the selected allocation but with the last day of the month, use moment
                        endDate: moment(`${MONTHS[month - 1]} ${year}`, 'MMMM YYYY')
                          .endOf('month')
                          .format(ISO_WITHOUT_TIME),
                      })
                    );
                  }}
                  endIcon={
                    toBeReallocatedAllocationLoading ? (
                      <img src={loaderIcon} alt="button-icon" />
                    ) : null
                  }
                  disabled={toBeReallocatedAllocationLoading}
                >
                  {'Delete'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </PermissionWrapper>
  );
};
//
export default DeleteHoursPopup;
