import { Box, CircularProgress, Container, Typography, Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { Download as DownloadIcon, Sync as SyncIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
//
import { useDebouncedCallback } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SALARY_REPORT_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import createFormattedString from 'features/base/helpers/param-formatter';
import {
  FINANCIAL_YEAR_START_MONTH,
  FINANCIAL_YEAR_END_MONTH,
} from 'features/base/constants/report-data';
import { USER_TYPES } from 'features/base/constants/user-types';
import { Button } from 'features/base/components';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import {
  selectSalaryReportIsInitialCall,
  selectSalaryReportUserList,
  selectSalaryReportUsers,
  selectSalaryReportUsersListLoading,
  selectSalaryReportUsersLoading,
  selectSalaryReportDepartments,
  selectSalaryReportDepartmentsLoading,
} from 'features/reports/selectors';
import { reportActions } from 'features/reports/slice';
import TIME_OUTS from 'features/base/constants/time-outs';
import { notificationActions } from 'features/base/notifications/slice';
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { downloadCSVFile } from 'features/base/helpers/file';
import { DepartmentTable, Filters, SalaryTable } from './components';
/**
 * Component that defines the entire capacity report view
 * @returns MUI Container with the capacity report view
 */
const SalaryReportView = () => {
  const dispatch = useDispatch();
  //
  const salaryReportUsers = useSelector(selectSalaryReportUsers);
  const allUserList = useSelector(selectSalaryReportUserList);
  const departments = useSelector(selectSalaryReportDepartments);
  const usersLoading = useSelector(selectSalaryReportUsersListLoading);
  const departmentsLoading = useSelector(selectSalaryReportDepartmentsLoading);
  const salaryReportUsersLoading = useSelector(selectSalaryReportUsersLoading);
  const isInitialCall = useSelector(selectSalaryReportIsInitialCall);
  const notification = useSelector(selectNotification);
  //
  const generateViewPeriod = (startYear, startMonth, endYear, endMonth) => {
    const months = [];
    for (let year = startYear; year <= endYear; year += 1) {
      const startMonthOfYear = year === startYear ? startMonth : 1;
      const endMonthOfYear = year === endYear ? endMonth : 12;
      for (let month = startMonthOfYear; month <= endMonthOfYear; month += 1) {
        months.push({
          month,
          year,
        });
      }
    }
    return months;
  };
  // Note: viewPeriod is maintained instead of selectedYear for the implementation of the project duration toggle feature
  const [viewPeriod, setViewPeriod] = useState(
    generateViewPeriod(
      moment().year(),
      FINANCIAL_YEAR_START_MONTH,
      moment().year() + 1,
      FINANCIAL_YEAR_END_MONTH
    )
  );
  //
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState({ id: 'All', label: 'All' });
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(1);
  //
  const loading = usersLoading || departmentsLoading;
  //
  /**
   * This is used as a central point of logged hours calculation, used for both rendering the report and generating the data for the excel file
   * Data structure (lookup structure that optimizes the code by reducing loops):
   * [{ id: 'department1'}, { id: 'id1', firstName: 'name1', lastName: 'name2', email: 'email', designation: 'designation', '2023-01': { year: 2023, month: 1, amount: 400 } },]
   */
  const salariesByViewPeriod = useMemo(() => {
    let departmentUsers = {};
    departments?.forEach((department) => {
      if (department?.department?.name) departmentUsers[department?.department?.name] = [];
    });
    const unassigned = 'Unassigned';
    departmentUsers[unassigned] = [];
    salaryReportUsers?.docs?.forEach((user) => {
      const depName =
        user?.currentUserDepartmentDesignation?.departmentDesignation?.department?.name;
      if (depName) {
        departmentUsers[depName]?.push(user);
      } else {
        departmentUsers[unassigned]?.push(user);
      }
    });
    departmentUsers = Object.keys(departmentUsers)
      ?.filter((key) => departmentUsers[key]?.length > 0)
      .map((item) => ({ department: item, users: departmentUsers[item] }));

    const finalResult = departmentUsers?.map((departmentUser) => {
      const result = departmentUser?.users?.map((user) => {
        const salaryDataLookUp = {};
        user?.salaries?.forEach((salary) => {
          if (salary?.year && salary?.month) {
            const key = `${salary?.year}-${salary?.month}`;
            salaryDataLookUp[key] = salary;
          }
        });
        const viewPeriodData = {};
        viewPeriod.forEach((period) => {
          const { year, month } = period;
          const key = `${year}-${month}`;
          const record = salaryDataLookUp[key];
          viewPeriodData[key] = record || {
            year,
            month,
            amount: 0,
          };
        });
        return {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          designation:
            user?.currentUserDepartmentDesignation?.departmentDesignation?.designation?.name,
          ...viewPeriodData,
        };
      });
      return [{ id: departmentUser?.department }, ...result];
    });
    return finalResult;
  }, [salaryReportUsers, viewPeriod]);
  //
  const params = useMemo(
    () => ({
      aggregated: 'true',
      types: `${USER_TYPES.INTERNAL},${USER_TYPES.CONTRACT},${USER_TYPES.INTERN}`,
      // Add the ids attribute to the params object if the selectedUsers array does not contain 'All' as an element. set the value of ids to the selectedUsers array as comma separated string
      userIds: selectedUsers?.find((item) => item.id === 'All')
        ? undefined
        : selectedUsers
            .map((item) => item.id)
            .filter((item) => item !== 'All')
            .join(','),
      department: departmentFilter?.id === 'All' ? undefined : departmentFilter?.label,
      search: searchFilter === '' ? undefined : searchFilter,
      limit: SALARY_REPORT_PAGINATION_LIMIT,
      sortBy: 'currentUserDepartmentDesignation.departmentDesignation.department.name:asc',
      page,
    }),
    [selectedUsers, departmentFilter, searchFilter, page, viewPeriod]
  );
  //
  const debouncedFetch = useDebouncedCallback((param) => {
    const formattedParamString = createFormattedString(param);
    dispatch(reportActions.getSalaryReportUsers({ query: formattedParamString }));
  }, TIME_OUTS.USER_FETCH_DEBOUNCE);
  // Function to set page to first page and initialize the allocation slice
  const initilialize = () => {
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
  };
  //
  const fetchNextPage = () => {
    setPage(salaryReportUsers?.page ? salaryReportUsers.page + 1 : 0);
  };
  // debounce the search filter
  const debouncedSearch = useDebouncedCallback((search) => {
    setSelectedUsers([{ id: 'All', label: 'All' }]);
    setSearchFilter(search);
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
  }, TIME_OUTS.DEBOUNCE);
  // sync button handler
  const handleSync = () => {
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
    setSelectedUsers([{ id: 'All', label: 'All' }]);
    setDepartmentFilter({ id: 'All', label: 'All' });
    setSearchFilter('');
    const formattedParamString = createFormattedString(params);
    dispatch(
      reportActions.getSalaryReportUsers({ query: `${formattedParamString}&runAggregation=true` })
    );
  };
  // This function handles the onChange event on the users filter
  const handleSelectedUsersChange = (_, newUsers) => {
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
    // if only the 'All' option is selected, set the tick to all the options
    if (newUsers?.length === 1 && newUsers[0]?.id === 'All') {
      setSelectedUsers([
        { id: 'All', label: 'All' },
        ...(allUserList?.map((item) => ({
          id: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        })) ?? []),
      ]);
      return;
    }
    // if the 'All' option is not selected, but the all other options are selected, unselect all the options
    if (newUsers?.length === allUserList?.length && newUsers?.[0]?.id !== 'All') {
      setSelectedUsers([]);
      return;
    }
    setSelectedUsers(newUsers);
  };
  //
  const handleExport = () => {
    if (!salariesByViewPeriod?.length) {
      dispatch(
        notificationActions.setNotification({
          message: 'No data to export',
          type: ERROR_TYPES.INFO,
        })
      );
      return;
    }
    const data = salariesByViewPeriod?.map((item) => {
      const dep = item?.[0];
      const users = item?.slice(1, item?.length);
      const userData = users?.map((user) => {
        const { id, firstName, lastName, email, designation, ...rest } = user;
        Object.keys(rest).forEach((key) => {
          rest[key] = rest[key]?.amount === 0 ? '-' : rest[key]?.amount;
        });
        return {
          name: `${firstName} ${lastName}`,
          email,
          designation,
          ...rest,
        };
      });
      //
      return [dep, ...userData];
    });
    let filename = 'salary_report';
    data?.forEach((item) => {
      filename = `${filename}_${item?.[0]?.id}`;
      const userData = item.slice(1, item?.length);
      downloadCSVFile(Object.keys(userData?.[0]), userData, `${filename}.csv`);
      filename = 'salary_report';
    });
  };
  // This function handles the onChange event on the department filter
  const handleDepartmentOnChange = (_, newValue) => {
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
    if (!newValue || newValue?.id === 'All') {
      setDepartmentFilter({ id: 'All', label: 'All' });
    } else {
      setDepartmentFilter(newValue);
    }
  };
  // Fetch users and departments
  useEffect(() => {
    setPage(1);
    dispatch(reportActions.setSalaryReportIsInitial());
    dispatch(
      reportActions.getSalaryReportUsersList({
        query: `sortBy=name:asc&types=${USER_TYPES.INTERNAL},${USER_TYPES.CONTRACT},${USER_TYPES.INTERN}&pagination=false`,
      })
    );
    dispatch(
      reportActions.getSalaryReportDepartments({ query: 'sortBy=name:asc&pagination=false' })
    );
  }, []);
  // Fetch salary report users
  useEffect(() => {
    debouncedFetch(params);
  }, [params]);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.INFO) {
      toast(notification?.message, { type: TOAST_TYPES.INFO });
      dispatch(notificationActions.resetNotification());
    }
  }, [notification]);
  // Cleanup function to improve performance
  useEffect(
    () => () => {
      dispatch(reportActions.resetSalaryReport());
    },
    []
  );
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 5 }} px={{ xs: 0, lg: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={8} sm={12} md={8} sx={{ mb: { xs: 2 } }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
            Salary report
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={2}
          display="flex"
          flexDirection="row"
          justifyContent={{ lg: 'flex-end', md: 'flex-end' }}
        >
          <Button
            onClick={handleExport}
            disabled={loading}
            sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '85%' } }}
          >
            <DownloadIcon sx={{ marginLeft: '0.25rem' }} />
            Export
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={2}
          display="flex"
          flexDirection="row"
          justifyContent={{ lg: 'flex-end', md: 'flex-end' }}
        >
          <Button
            onClick={handleSync}
            disabled={loading}
            sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '85%' } }}
          >
            <SyncIcon sx={{ marginLeft: '0.25rem' }} />
            Sync
          </Button>
        </Grid>
      </Grid>
      <Filters
        userList={allUserList}
        departmentList={departments}
        debouncedSearch={debouncedSearch}
        selectedUsers={selectedUsers}
        departmentFilter={departmentFilter}
        searchFilter={searchFilter}
        handleSelectedUsersChange={handleSelectedUsersChange}
        handleDepartmentOnChange={handleDepartmentOnChange}
        initilialize={initilialize}
        generateViewPeriod={generateViewPeriod}
        setViewPeriod={setViewPeriod}
        loading={loading}
      />
      {isInitialCall && salaryReportUsersLoading ? (
        <Grid container display="flex" flexDirection="row" spacing={3}>
          <Grid item xs={4}>
            <ShimmerTable row={5} col={2} />
          </Grid>
          <Grid item xs={8}>
            <ShimmerTable row={5} col={12} />
          </Grid>
        </Grid>
      ) : (
        salaryReportUsers?.docs?.length > 0 && (
          <InfiniteScroll
            dataLength={salaryReportUsers?.docs?.length ?? 0}
            next={fetchNextPage}
            hasMore={salaryReportUsers?.hasNextPage}
            loader={
              <Box textAlign="center" margin="1.125rem">
                <CircularProgress size={30} />
              </Box>
            }
          >
            <Box sx={{ mt: 5, display: 'flex', gap: 1 }}>
              <DepartmentTable salariesByViewPeriod={salariesByViewPeriod} />
              <SalaryTable viewPeriod={viewPeriod} salariesByViewPeriod={salariesByViewPeriod} />
            </Box>
          </InfiniteScroll>
        )
      )}
      {!salaryReportUsersLoading && salaryReportUsers?.docs?.length === 0 && (
        <Grid item sx={{ marginTop: 20 }} xs={12}>
          <CustomNoRowsOverlay message="No users found!" size />
        </Grid>
      )}
    </Container>
  );
};
//
export default SalaryReportView;
