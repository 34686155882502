import * as yup from 'yup';

/**
 * Function for vendor portal form validation
 */
const vendorPortalFormValidation = yup.object().shape({
  nic: yup
    .string()
    .length(4, 'Please enter valid last 4 digits of NIC')
    .required('Please enter valid last 4 digits of NIC'),
  employeeId: yup
    .string('Please enter valid employee ID')
    .required('Please enter valid employee ID'),
});
//
export default vendorPortalFormValidation;
