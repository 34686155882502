import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerTable } from 'react-shimmer-effects';
import { isEmpty } from 'lodash';
//
import { profileActions } from 'features/profile/slice';
import { CLIENTS_USERS_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { selectUser, selectClient, selectLoader } from 'features/profile/selectors';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import MY_USERS_TABLE_HEADERS from 'features/base/utils/tables/my-users-table';
import { DataGrid } from 'features/base/components';
import { phoneNumberFormatter } from 'features/base/helpers/phone-numbers';
import COLORS from 'features/base/constants/colors';

/**
 * UI component that displays client users
 * @returns {Box}
 */
const MyUsersView = () => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);
  //
  const isFirst = useIsInitialize(client);
  //
  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(profileActions.getClientById({ id: user?.client?.clientId }));
    }
  }, []);
  //
  return (
    <Box sx={{ width: '100%' }}>
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        {isFirst ? (
          <ShimmerTable row={4} col={6} />
        ) : (
          <DataGrid
            columns={MY_USERS_TABLE_HEADERS}
            rows={
              client?.users
                ?.toSorted((firstUser, secondUser) =>
                  firstUser?.userId?.firstName?.localeCompare(secondUser?.userId?.firstName)
                )
                .map((clientUser) => ({
                  id: clientUser.userId?.id,
                  profile: {
                    logo: clientUser.userId?.profileImage,
                    name: `${clientUser.userId?.firstName} ${clientUser.userId?.lastName}`,
                  },
                  email: {
                    value: clientUser.userId?.email || '-',
                  },
                  phone: {
                    value: clientUser.userId?.phoneNumber
                      ? phoneNumberFormatter(clientUser.userId?.phoneNumber)
                      : '-',
                  },
                  onboardingStatus: {
                    value: clientUser?.userId?.onboarding ? 'Onboarded' : 'Pending',
                    bgColour: clientUser?.userId?.onboarding
                      ? COLORS.PALE_GREEN
                      : COLORS.LAVENDER_MIST,
                    textColour: clientUser?.userId?.onboarding
                      ? COLORS.SUCCESS_MAIN
                      : COLORS.NAVY_BLUE,
                  },
                })) ?? []
            }
            loading={loading && isFirst !== true}
            disableRowSelectionOnClick
            limit={CLIENTS_USERS_PAGINATION_LIMIT}
            pageSizeOptions={[CLIENTS_USERS_PAGINATION_LIMIT]}
            serverSidePagination={false}
            rowHeight={client?.users?.length ? 54 : 200}
          />
        )}
      </Container>
    </Box>
  );
};
//
export default MyUsersView;
