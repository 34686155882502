import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  loading: false,
  fileUploadLoading: false,
  isClientFetching: false,
  clientList: {},
  client: {},
  projectAllocationsList: [],
  uploadedImage: '',
  allProjectsList: [],
  addClientLoading: false,
  editClientLoading: false,
  deleteClientUserLoading: false,
  inviteClientUserLoading: false,
};
/**
 * Redux slice for the client feature
 */
export const clientSlice = createSlice({
  name: 'feature/clients',
  initialState,
  reducers: {
    addClient: (state) => ({
      ...state,
      addClientLoading: true,
    }),
    addClientSucceeded: (state, action) => ({
      ...state,
      addClientLoading: false,
      clientList: {
        ...state.clientList,
        docs: [action?.payload, ...state.clientList.docs],
        totalDocs: state.clientList.totalDocs + 1,
      },
    }),
    addClientFailed: (state) => ({
      ...state,
      addClientLoading: false,
    }),
    editClient: (state) => ({
      ...state,
      editClientLoading: true,
    }),
    editClientSucceeded: (state, action) => ({
      ...state,
      editClientLoading: false,
      clientList: {
        ...state.clientList,
        docs: state.clientList?.docs?.map((client) =>
          client.id === action?.payload?.id ? action?.payload : client
        ),
      },
      client: action?.payload?.id === state.client?.id ? action?.payload : state.client,
    }),
    editClientFailed: (state) => ({
      ...state,
      editClientLoading: false,
    }),
    getClientById: (state) => ({
      ...state,
      isClientFetching: true,
    }),
    getClientByIdSucceeded: (state, action) => ({
      ...state,
      client: action?.payload,
      isClientFetching: false,
    }),
    getClientByIdFailed: (state) => ({
      ...state,
      isClientFetching: false,
    }),
    getClients: (state) => ({
      ...state,
      loading: true,
    }),
    getClientsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      clientList: action?.payload,
    }),
    getClientsFailed: (state) => ({
      ...state,
      loading: false,
      clientList: {
        ...state.clientList,
        docs: [],
      },
    }),
    getProjectAllocationsList: (state) => ({
      ...state,
      loading: true,
    }),
    getProjectAllocationsListSucceeded: (state, action) => ({
      ...state,
      loading: false,
      projectAllocationsList: action?.payload?.docs,
    }),
    getProjectAllocationsListFailed: (state) => ({
      ...state,
      loading: false,
    }),
    addClientUser: (state) => ({
      ...state,
      loading: true,
    }),
    addClientUserSucceeded: (state, action) => ({
      ...state,
      client: action?.payload,
      loading: false,
    }),
    addClientUserFailed: (state) => ({
      ...state,
      loading: false,
    }),
    editClientUser: (state) => ({
      ...state,
      loading: true,
    }),
    editClientUserSucceeded: (state, action) => ({
      ...state,
      client: action?.payload,
      loading: false,
    }),
    editClientUserFailed: (state) => ({
      ...state,
      loading: false,
    }),
    deleteClientUser: (state) => ({
      ...state,
      deleteClientUserLoading: true,
    }),
    deleteClientUserSucceeded: (state, action) => ({
      ...state,
      deleteClientUserLoading: false,
      client: action?.payload?.id === state.client?.id ? action?.payload : state.client,
    }),
    deleteClientUserFailed: (state) => ({
      ...state,
      deleteClientUserLoading: false,
    }),
    getAllProjects: (state) => ({
      ...state,
      loading: true,
    }),
    getAllProjectsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      allProjectsList: action?.payload?.docs,
    }),
    getAllProjectsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    inviteClientUser: (state) => ({
      ...state,
      inviteClientUserLoading: true,
    }),
    inviteClientUserSucceeded: (state, action) => ({
      ...state,
      inviteClientUserLoading: false,
      client: {
        ...state.client,
        users: state.client?.users?.map((user) =>
          user?.userId?.id === action?.payload?.id ? { userId: action?.payload } : user
        ),
      },
    }),
    inviteClientUserFailed: (state) => ({
      ...state,
      inviteClientUserLoading: false,
    }),
    uploadLogo: (state) => ({
      ...state,
      fileUploadLoading: true,
    }),
    uploadLogoSucceeded: (state, action) => ({
      ...state,
      fileUploadLoading: false,
      uploadedImage: action?.payload,
    }),
    uploadLogoFailed: (state) => ({
      ...state,
      fileUploadLoading: false,
    }),
    clearLogo: (state) => ({
      ...state,
      uploadedImage: '',
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: clientActions } = clientSlice;
