import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTeamReportAllocations } from 'features/reports/selectors';
import COLORS from 'features/base/constants/colors';
/**
 * Component renders the user name, project name and user's designation in a table.
 * @returns MUI Table with the user-project allocation data
 */
const UserProjectTable = () => {
  const allocations = useSelector(selectTeamReportAllocations);
  return (
    <Table sx={{ width: '50rem' }}>
      <TableHead>
        <TableRow>
          <TableCell
            size="small"
            rowSpan={2}
            sx={{
              bgcolor: COLORS.SKY_BLUE,
              borderBlock: '2px solid rgb(158,170,249)',
              height: 105,
            }}
          >
            <Typography fontWeight="bold" variant="body2">
              Project
            </Typography>
          </TableCell>
          <TableCell
            size="small"
            rowSpan={2}
            sx={{
              bgcolor: COLORS.SKY_BLUE,
              borderBlock: '2px solid rgb(158,170,249)',
              height: 105,
            }}
          >
            <Typography fontWeight="bold" variant="body2">
              Profile
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allocations?.docs?.map((item, index) => {
          const { projectId: project, userId: user } = item;
          return (
            <TableRow
              key={`${project.name}-${user.email}`}
              sx={{
                bgcolor: index % 2 === 1 ? COLORS.SKY_BLUE : COLORS.WHITE,
              }}
            >
              <TableCell
                size="small"
                sx={{
                  borderBottom: `4px solid ${COLORS.WHITE}`,
                  height: 68,
                  margin: 0,
                  padding: 0,
                }}
              >
                <Typography variant="body2">{project?.name}</Typography>
              </TableCell>
              <TableCell
                size="small"
                sx={{
                  borderBottom: `4px solid ${COLORS.WHITE}`,
                  height: 68,
                  margin: 0,
                  padding: 0,
                }}
              >
                <Typography variant="body2">
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="body2" color={COLORS.SILVER_LILAC}>
                  {
                    user.currentUserDepartmentDesignationId?.departmentDesignationId?.designationId
                      ?.name
                  }
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
        <TableRow
          sx={{
            height: 74,
            bgcolor: (allocations?.docs?.length || 0) % 2 === 0 ? COLORS.WHITE : COLORS.SKY_BLUE,
          }}
        >
          <TableCell size="small" colSpan={2}>
            <Typography textAlign="right" sx={{ width: '100%' }}>
              Total
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
//
export default UserProjectTable;
