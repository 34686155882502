import { TableHead, TableRow, TableCell, Box, Typography } from '@mui/material';
import COLORS from 'features/base/constants/colors';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          width: '40%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Skill Group</Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: '35%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Skill Name</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>L3</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>L2</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>L1</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Intern</Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '5%',
          backgroundColor: `${COLORS.WHITE}`,
          borderTop: `2px solid ${COLORS.DODGER_BLUE}`,
          borderBottom: `2px solid ${COLORS.DODGER_BLUE}`,
        }}
      >
        <Box sx={{ backgroundColor: 'transparent' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Unassigned</Typography>
        </Box>
      </TableCell>
    </TableRow>
  </TableHead>
);

export default TableHeader;
