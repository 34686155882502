import { useDispatch, useSelector } from 'react-redux';
//
import { selectSelectedTeamMembers } from 'features/projects/selectors';
import { projectActions } from 'features/projects/slice';

/**
 * Function that handles setting the percentage for allocations
 */
const useSetPercentage = () => {
  const dispatch = useDispatch();
  //
  const selectedUsers = useSelector(selectSelectedTeamMembers);
  //
  const setPercentage = (userId, percentage) => {
    const selectedUsersCopy = selectedUsers?.docs?.map((user) => {
      if (user?.userId?.id === userId) {
        return {
          ...user,
          allocatedPercentage: percentage,
        };
      }
      return user;
    });
    dispatch(projectActions.setCreateTeamSelectedUsers(selectedUsersCopy));
  };
  //
  return { setPercentage };
};
//
export default useSetPercentage;
