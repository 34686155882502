/**
 * Available comment types
 * @type {{TEXT: string, ATTACHMENT: string }}
 */
export const COMMENT_TYPES = {
  TEXT: 'TEXT',
  ATTACHMENT: 'ATTACHMENT',
};
/**
 * Array comment type
 */
export const COMMENT_TYPES_ARRAY = [
  { key: '1', value: COMMENT_TYPES.TEXT },
  { key: '2', value: COMMENT_TYPES.ATTACHMENT },
];
/**
 * Comment line length
 */
export const MAX_LINE_LENGTH = 35;
