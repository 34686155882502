import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Container, Grid, CircularProgress, Box } from '@mui/material';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ShimmerTable } from 'react-shimmer-effects';
import { useOutletContext } from 'react-router-dom';
import ERROR_TYPES from 'features/base/constants/error-types';
import COLORS from 'features/base/constants/colors';
import JIRA_ISSUE_TYPES from 'features/base/constants/jira-ticket-types';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import createFormattedString from 'features/base/helpers/param-formatter';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { projectActions } from 'features/projects/slice';
import { selectNotification } from 'features/base/notifications/selectors';
import {
  selectProject,
  selectProjectJiraTickets,
  selectProjectTicketsLoader,
} from 'features/projects/selectors';
import { JIRA_TICKET_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import { DetailsTable, TimelineTable } from './components';

const GanttChart = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const projectEditPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.PROJECT,
      action: PERMISSION_ACTIONS.UPDATE,
    },
  ]);
  //
  const dispatch = useDispatch();
  const { projectId } = useOutletContext();
  //
  const project = useSelector(selectProject);
  const tickets = useSelector(selectProjectJiraTickets);
  const getJiraTicketsLoading = useSelector(selectProjectTicketsLoader);
  const notification = useSelector(selectNotification);
  //
  const isInitialize = useIsInitialize(tickets);
  //
  const [missingDates, setMissingDates] = useState(false);
  const [page, setPage] = useState(1);
  // This key is maintained to re-render the gantt chart to roll-back a change that caused a backend failure, since the Gantt Chart component doesnt offer a controlled state
  const [key, setKey] = useState(1);
  //
  const params = useMemo(
    () => ({
      project: projectId,
      sortBy: 'startDate:asc',
      limit: JIRA_TICKET_PAGINATION_LIMIT,
      page,
    }),
    [page, projectId]
  );
  //
  const barColor = (type) => {
    switch (type) {
      case JIRA_ISSUE_TYPES.BUG:
        return COLORS.BEGONIA;
      case JIRA_ISSUE_TYPES.TASK:
        return COLORS.LITTLE_BOY_BLUE;
      case JIRA_ISSUE_TYPES.STORY:
        return COLORS.IGUANA_GREEN;
      case JIRA_ISSUE_TYPES.EPIC:
        return COLORS.PRIMARY_MAIN;
      default:
        return COLORS.BUG;
    }
  };
  //
  const preparingTasks = useMemo(() => {
    const tasks = [];
    if (project?.startDate && project?.endDate) {
      const projectTask = {
        id: project?.id,
        name: project?.name,
        start: new Date(project?.startDate),
        end: new Date(project?.endDate),
        progress: 100,
        type: 'project',
        styles: {
          backgroundColor: COLORS.SHADOW_BLUE,
          progressColor: COLORS.SHADOW_BLUE,
          backgroundSelectedColor: COLORS.SHADOW_BLUE,
          progressSelectedColor: COLORS.SHADOW_BLUE,
        },
      };
      tasks.push(projectTask);
    }
    const filteredTickets = tickets?.docs?.filter((ticket) => ticket?.startDate && ticket?.dueDate);
    if (filteredTickets?.length > 0) {
      filteredTickets?.forEach((ticket) => {
        const task = {
          id: ticket?.id,
          name: ticket?.summary,
          start: new Date(ticket?.startDate),
          end: new Date(ticket?.dueDate),
          progress: 0,
          type: 'task',
          project: ticket?.project?.id,
          styles: {
            backgroundColor: barColor(ticket?.type),
            backgroundSelectedColor: barColor(ticket?.type),
          },
        };
        tasks.push(task);
      });
    }
    setMissingDates(
      filteredTickets?.length !== tickets?.docs?.length || !project?.startDate || !project?.endDate
    );
    return tasks;
  }, [tickets, project]);
  //
  const handleChange = (task) => {
    if (
      moment(task?.start).isBefore(project?.startDate) ||
      moment(task?.end).isAfter(project?.endDate)
    ) {
      return false;
    }
    const updatedTicket = {
      id: task?.id,
      startDate: task?.start,
      dueDate: task?.end,
    };
    dispatch(projectActions.patchJiraTicket({ id: task?.id, ...updatedTicket }));
    return true;
  };
  //
  const fetchNextPage = () => {
    setPage(tickets?.page ? tickets.page + 1 : 0);
  };
  //
  useEffect(() => {
    const formattedParamString = createFormattedString(params);
    dispatch(
      projectActions.getJiraTickets({
        query: `${formattedParamString}`,
      })
    );
  }, [params]);
  //
  useEffect(
    () => () => {
      dispatch(projectActions.resetJiraTickets());
    },
    []
  );
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR) {
      setKey(key + 1);
    }
  }, [notification]);
  //
  return (
    <Container maxWidth="xl">
      {isInitialize && getJiraTicketsLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ShimmerTable row={5} col={3} />
          </Grid>
          <Grid item xs={6}>
            <ShimmerTable row={5} col={8} />
          </Grid>
        </Grid>
      ) : (
        tickets?.docs?.length > 0 && (
          <InfiniteScroll
            dataLength={tickets?.docs?.length ?? 0}
            next={fetchNextPage}
            hasMore={tickets?.hasNextPage}
            loader={
              <Box textAlign="center" margin="1.125rem">
                <CircularProgress size={30} />
              </Box>
            }
          >
            <Grid container spacing={2}>
              {missingDates && !getJiraTicketsLoading && (
                <Alert
                  severity={ERROR_TYPES.ERROR}
                  sx={{ color: COLORS.CORAL_RED, mb: 2, width: '100%' }}
                >
                  Some Jira tickets are missing start or due dates. Therefore they are not displayed
                  in the chart.
                </Alert>
              )}
              <Grid item xs={6}>
                <DetailsTable tickets={tickets} project={project} />
              </Grid>
              <Grid item xs={6}>
                <TimelineTable
                  tasks={preparingTasks}
                  handleChange={handleChange}
                  key={key}
                  editPermissions={projectEditPermissionsVerified}
                />
              </Grid>
            </Grid>
          </InfiniteScroll>
        )
      )}
      {!tickets?.docs?.length && !getJiraTicketsLoading && !isInitialize && (
        <CustomNoRowsOverlay message={'No Jira tickets created for this project!'} />
      )}
    </Container>
  );
};

export default GanttChart;
