import Box from '@mui/material/Box';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Card, CardContent, CardMedia, Container, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
//
import { selectAuthUser } from 'features/base/auth/selectors';
import { linkActions } from 'features/links/slice';
import { selectLoader, selectAllLinks } from 'features/links/selectors';
import DATA_STATUS_TYPES from 'features/base/constants/data-status-types';
import COLORS from 'features/base/constants/colors';
import { ENVIRONMENT } from 'config';
import ROUTES from 'features/base/constants/routes';
import { ShimmerCards } from 'features/base/components';
import CustomNoRowsOverlay from 'features/base/components/no-rows';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import arsenalIcon from 'features/base/assets/images/svg/arsenal.svg';

const LinkHub = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  const arsenalPermissionVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.ARSENAL,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const allLinks = useSelector(selectAllLinks);
  const user = useSelector(selectAuthUser);
  const isLoading = useSelector(selectLoader);
  //
  useEffect(() => {
    if (user?.id) {
      const userDepartmentId =
        user?.currentUserDepartmentDesignationId?.departmentDesignationId?.departmentId?.id;
      dispatch(
        linkActions.getAllLinks({
          query: `departmentId=${userDepartmentId}&pagination=false`,
        })
      );
    }
  }, []);
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <Box>
        <Typography
          sx={{
            fontSize: 31,
            fontWeight: 'bold',
            marginBottom: 3,
          }}
        >
          Link hub
        </Typography>
        {isLoading ? (
          <ShimmerCards />
        ) : (
          <Grid container>
            {user?.id && arsenalPermissionVerified && (
              <Grid
                item
                sx={{
                  width: { xs: '100%', md: '50%' },
                  height: 'auto',
                }}
              >
                <Link component={RouterLink} to={ROUTES.ARSENAL} underline="none">
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      m: 1,
                    }}
                    elevation={14}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: 64, sm: 128 },
                        height: { xs: 64, sm: 128 },
                        bgcolor: COLORS.LIGHT_BLUE,
                        m: 1,
                        p: { xs: 1, sm: 4 },
                        objectFit: 'contain',
                      }}
                      src={arsenalIcon}
                    />
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontWeight: 600, color: COLORS.NAVY_BLUE }}>
                        Arsenal
                      </Typography>
                      <Typography
                        sx={{
                          color: COLORS.MEDIUM_GRAY,
                          marginTop: '5px',
                          textAlign: 'left',
                          wordBreak: 'break-word',
                        }}
                      >
                        Find tools to aid development
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            )}
            {allLinks?.docs
              ?.filter((link) => link.dataStatus === DATA_STATUS_TYPES.ACTIVE)
              .map((link) => (
                <Grid
                  item
                  sx={{
                    width: { xs: '100%', md: '50%' },
                    height: 'auto',
                  }}
                  key={link?.id}
                >
                  <Link href={link?.link} underline="none" target="_blank">
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        m: 1,
                      }}
                      elevation={14}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          width: { xs: 64, sm: 128 },
                          height: { xs: 64, sm: 128 },
                          bgcolor: COLORS.LIGHT_BLUE,
                          m: 1,
                          p: { xs: 1, sm: 4 },
                          objectFit: 'contain',
                        }}
                        src={`${ENVIRONMENT.STORAGE_BUCKET_PREFIX}/${link?.logo?.url}`}
                        alt={link.name}
                      />
                      <CardContent>
                        <Typography sx={{ fontSize: 20, fontWeight: 600, color: COLORS.NAVY_BLUE }}>
                          {link.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: COLORS.MEDIUM_GRAY,
                            marginTop: '5px',
                            textAlign: 'left',
                            wordBreak: 'break-word',
                          }}
                        >
                          {link.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
              {(!allLinks?.docs?.filter((link) => link.dataStatus === DATA_STATUS_TYPES.ACTIVE)
              .length && !arsenalPermissionVerified) && (
              <Grid item sx={{ marginTop: 20 }} xs={12}>
                <CustomNoRowsOverlay message="No links found!" size />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Container>
  );
};
//
export default LinkHub;
