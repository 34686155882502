import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import ERROR_TYPES from 'features/base/constants/error-types';
import { selectNotification } from 'features/base/notifications/selectors';
import { notificationActions } from 'features/base/notifications/slice';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { Drawer } from 'features/base/components';
import AddDesignationForm from '../add-designation-form';

/**
 * Function that defines the drawer form for adding new designation
 * @prop {boolean} addDesignationDrawerOpen - boolean to show/hide the drawer
 * @prop {function} setAddDesignationDrawerOpen - function to set the drawer state
 * @returns {Drawer}
 */
const AddNewDesignationDrawer = ({ addDesignationDrawerOpen, setAddDesignationDrawerOpen }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  //
  const handleOnClose = () => {
    setAddDesignationDrawerOpen(false);
    dispatch(notificationActions.resetNotification());
  };
  //
  useEffect(() => {
    if (
      addDesignationDrawerOpen &&
      notification?.isEnabled &&
      notification?.type === ERROR_TYPES.SUCCESS
    ) {
      handleOnClose();
    }
  }, [notification]);
  //
  return (
    <PermissionWrapper
      requiredPermissions={[
        {
          domain: PERMISSION_DOMAINS.ORGANIZATION,
          action: PERMISSION_ACTIONS.CREATE,
        },
      ]}
      hide
    >
      <Drawer open={addDesignationDrawerOpen} onClose={handleOnClose} title="Add designation">
        <AddDesignationForm />
      </Drawer>
    </PermissionWrapper>
  );
};
//
export default AddNewDesignationDrawer;
